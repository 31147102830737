import React, {useState} from 'react';
import OrdoModal from '../common/OrdoModal';
import OrdoSpinner from '../OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import ContactModalViewModel from '../../../application-models/order-entry/ContactModalViewModel';
import {ContactForm} from '../contacts/ContactForm';
import {useAPI} from '../../../context/OrdoApiContext';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import Contact from '../../../models/order-entry/Contact';
import OrdoDropdown from '../common/OrdoDropdown';
import Account from '../../../models/Account';
import {ContactWithAccountName} from '../../contacts/ContactsPage';

export type ContactFormData = {
  name: string
  email: string
  phone: string
  title: string
}

type EditContactModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: () => Promise<void>,
  orgId: string,
  contact: Contact,
  displayLabel?: boolean,
  updateContact?: (contact: Contact) => void,
  accounts?: Account[]
  updateContactWithAccount?: (contactWithAccountName: ContactWithAccountName) => void
}

const EditContactModal = ({isOpen, onClose, onSubmit, orgId, contact, updateContact, accounts, updateContactWithAccount, displayLabel = true}: EditContactModalProps) => {
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const api = useAPI();
  const [editContactViewModel, setEditContactModalViewModel] = useState(new ContactModalViewModel(
    api, contact.name, contact.email, contact.title || '', contact.phone));
  const toasts = useOrdoToasts();

  const findAccount = accounts ? (accounts.find(a => a.id === contact.accountId)) : null;
  const [selectedAccount, setAccount] = useState<Account | null>(findAccount || null);
  const editContact = async () => {
    showSpinner();
    editContactViewModel.updateContact(contact, orgId, selectedAccount?.id)
      .then((updatedContact: Contact)=> {
        toasts.successToast('Contact updated successfully');
        onSubmit();
        if(updateContact) {
          updateContact(updatedContact);
        }
        if(updateContactWithAccount && selectedAccount) {
          updateContactWithAccount({contact: updatedContact, accountName: selectedAccount.name});
        }
      })
      .catch(() => toasts.errorToast('Failed to update contact'))
      .finally(() => {
        hideSpinner();
        onClose();
      });
  };

  return (<div>
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoModal show={isOpen}
        title='edit contact'
        onClose={() => onClose()}
        onSubmit={() => {}}
        showFooter={false}
        actionText='save'
        disableCTA={!editContactViewModel.canSubmit()}
      >
        {accounts &&
            <div className='contact-form-account-selection'>
              {displayLabel && <span>account</span>}
              <OrdoDropdown
                height="short"
                selectedOption={selectedAccount}
                placeholder='select an account'
                onSelect={(account: Account) => setAccount(account)}
                options={accounts}
                displayName={(account: Account) => account.name}
                forceLowerCase={false}
                disabled={!accounts}
              />
            </div>
        }
        <ContactForm
          viewModel={editContactViewModel}
          updateViewModel={setEditContactModalViewModel}
          onCancel={() => onClose()}
          onSubmit={editContact}
        />
      </OrdoModal>
    </OrdoSpinner>
  </div>);
};

export default  EditContactModal;
