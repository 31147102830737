import React from 'react';
import {ProductWithAvailability} from '../../../../models/productWithAvailability';
import '../../../../scss/ordo/mobileOrderEntry/mobile-order-entry.scss';
import {CartItemType} from '../../../../models/order-entry/CartItem';
import {AddToCart} from '../../../components/order-entry/AddToCart';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';

type MobileOrderEntryListItemProps = {
  product: ProductWithAvailability,
  onChange: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void,
  orderEntryViewModel: OrderEntryViewModel,
  landscapeMode: boolean;
}
export const MobileOrderEntryListItem = ({product, onChange, orderEntryViewModel, landscapeMode =  false} : MobileOrderEntryListItemProps) => {
  const amountOfCases =  orderEntryViewModel.amountOfProductFor(product.id, CartItemType.CASE);
  const amountUnits = orderEntryViewModel.amountOfProductFor(product.id, CartItemType.UNIT);
  const currentAvailableUnitsQuantity = product.availability - amountUnits - (amountOfCases * product.unitsPerCase);
  const currentAvailableCasesQuantity = Math.floor((product.availability - amountUnits)/product.unitsPerCase) - amountOfCases;

  const updateUnitOrder = (newAmount: number) => {
    if(currentAvailableUnitsQuantity > 0 || amountUnits - newAmount >= 0) {
      onChange(product, newAmount, CartItemType.UNIT);
    }
  };

  const updateCaseOrder = (newAmount: number) => {
    if(currentAvailableCasesQuantity > 0 || amountOfCases - newAmount >= 0) {
      onChange(product, newAmount, CartItemType.CASE);
    }
  };

  const removeUnitOrder = () => {
    onChange(product, 0, CartItemType.UNIT);
  };

  const removeProductOrder = () => {
    onChange(product, 0, CartItemType.CASE);
  };

  return(
    <div className="product-line">
      <div className={`product-line__info product-line__info-${product.unitSaleOnly||product.caseSaleOnly ? 'unit-only-':''}width`}>
        <img src={product.imageUrl} alt={product.name} className="product-image"/>
        <div className="product-info">
          <div title={product.name} className="info name">{product.name}</div>
          <span className="info">{product.brand.name}</span>
          <span className="info">{product.categoryToString()}</span>
          <span className="info">{product.geneticsToString()}</span>
        </div>
      </div>
      <div className={`product-pricing product-pricing-${product.unitSaleOnly||product.caseSaleOnly ? 'unit-only-':''}width`}>
        {!product.caseSaleOnly && <div className={`${product.unitSaleOnly ? 'unit-only-':''}unit-pricing`}>
          <div className="unit-pricing__text">
            {!product.unitSaleOnly && <span className='price'>{`$${(product.price/product.unitsPerCase).toFixed(2)}`}</span>}
            {product.unitSaleOnly && <span className='price'>{`$${(product.price).toFixed(2)}`}</span>}
            <span className="size">{product.size ? product.sizeToString() : 'per unit'}</span>
          </div>
          <div className={`mobile-add-to-cart ${landscapeMode ? 'landscape' : ''}`}>
            <AddToCart
              dataTestIdSuffix='order-entry-list-add-unit-to-cart-button'
              productId={product.id}
              productType={CartItemType.UNIT}
              page='list'
              productIsDeleted={product.isDeleted()}
              buttonText='add unit'
              inputLabel='unit'
              displayArrows={false}
              displayInvalidMessage={false}
              updateOrder={updateUnitOrder}
              removeOrder={removeUnitOrder}
              emptyOrders={amountUnits === 0}
              ordersAmount={amountUnits}
              maxAmount={product.availability - amountOfCases * product.unitsPerCase}
              stylesForButton={{padding: '0.3em 0.5em', minWidth: '5.5em !important', maxWidth: '5.5em !important'}}
            />
          </div>
          <div className="product-info">
            <span className={!product.unitSaleOnly ? 'upper-info' : ''}>{`${Math.max(0, currentAvailableUnitsQuantity)} available`}</span>
          </div>
        </div>}
        {!product.unitSaleOnly && <div className={`${product.caseSaleOnly ? 'case-only-':''}case-pricing`}>
          <div className="case-pricing__text">
            <span className="price">{`$${(product.price).toFixed(2)}`}</span>
            <span className="size">{`${product.unitsPerCaseToStringMobile()}${product.isAccessories() ? ' per case' : ''}`}</span>
          </div>
          <div className={`mobile-add-to-cart ${landscapeMode ? 'landscape' : ''}`}>
            <AddToCart
              dataTestIdSuffix='order-entry-list-add-case-to-cart-button'
              productId={product.id}
              productType={CartItemType.CASE}
              productIsDeleted={product.isDeleted()}
              page='list'
              buttonText='add case'
              inputLabel='case'
              displayArrows={false}
              displayInvalidMessage={false}
              updateOrder={updateCaseOrder}
              removeOrder={removeProductOrder}
              emptyOrders={amountOfCases === 0}
              ordersAmount={amountOfCases}
              maxAmount={(product.availability - amountUnits)/product.unitsPerCase}
              stylesForButton={{padding: '0.3em 0.5em'}}
            />
          </div>
          <div className="product-info">
            <span className='bottom-info'>{`${Math.max(0, currentAvailableCasesQuantity)} available`}</span>
          </div>
        </div>}
      </div>
    </div>
  );
};

