import React from 'react';
import OrderWithCurrentVersion from '../../../models/order-entry/OrderWithCurrentVersion';
import OrderHistoryOrderItem from './OrderHistoryOrderItem';
import OrderHistoryOrderListHeaders from './OrderHistoryOrderListHeaders';

type OrderHistoryOrderListProps = {
  orders: OrderWithCurrentVersion[],
  noOrdersMessage: string,
  onUpdateOrder: Function,
}

const OrderHistoryOrderList = ({orders, noOrdersMessage, onUpdateOrder} : OrderHistoryOrderListProps) => {

  const headers = [{
    title: 'customer',
    isWide: true
  },
  {
    title: 'sales rep',
    isWide: true
  },
  {
    title: 'contact',
    isWide: true
  },
  {
    title: 'status',
    isWide: false
  },
  {
    title: 'delivery date',
    isWide: false
  },
  {
    title: 'sale value',
    isWide: false
  }];

  return (
    <div className='order-history-orders-list'>
      <OrderHistoryOrderListHeaders headers={headers}/>
      {orders.length > 0 ?
        orders.map(order => <OrderHistoryOrderItem key={order.id} order={order} onUpdateOrder={onUpdateOrder}/>) :
        <div className='no-orders-content'>{noOrdersMessage}</div>
      }
    </div>);
};

export default OrderHistoryOrderList;
