import { UserSession } from '../models/UserSession';
import ROUTE_NAMES from './ROUTE_NAMES';
import {
  FeatureFlagChecker,
  FeatureFlags,
  InventoryFlagEnabledChecker,
  OrderEntryFlagEnabledChecker,
} from '../lib/featureFlags';
import { Role } from '../models/Roles';
import {ORGANIZATION_STATUS} from '../models/Organization';

/**
 * A SessionChecker determines if the UserSession satisfies a particular condition
 */
interface SessionChecker {
  allow(session: UserSession): boolean
}

export class AdminChecker {
  allow(session: UserSession) {
    return session.role === Role.Admin;
  }
}

export class CurrentOrganizationChecker {
  allow(session: UserSession) {
    return !!session.currentOrganization;
  }
}

export class VerifiedOrgChecker {
  allow(session: UserSession) {
    const {currentOrganization} = session;
    return !!currentOrganization && currentOrganization.status === ORGANIZATION_STATUS.VERIFIED;
  }
}



type RouteCheckerData = {
  route: string
  sessionCheckers: SessionChecker[]
  featureFlagCheckers?: FeatureFlagChecker[]
};

const routeCheckers: RouteCheckerData[]= [
  {
    route: ROUTE_NAMES.ORGANIZATION,
    sessionCheckers: [
      new CurrentOrganizationChecker(),
      new AdminChecker()
    ]
  },
  {
    route: ROUTE_NAMES.ORDER_HISTORY,
    sessionCheckers: [
      new VerifiedOrgChecker()
    ]
  },
  {
    route: ROUTE_NAMES.INVENTORY,
    sessionCheckers: [
      new VerifiedOrgChecker(),
      new AdminChecker()
    ],
    featureFlagCheckers: [
      new InventoryFlagEnabledChecker()
    ]
  },
  {
    route: ROUTE_NAMES.ORDER_ENTRY,
    sessionCheckers: [
      new VerifiedOrgChecker()
    ],
    featureFlagCheckers: [
      new OrderEntryFlagEnabledChecker(),
    ]
  },
  {
    route: ROUTE_NAMES.ACCOUNTS,
    sessionCheckers: [
      new VerifiedOrgChecker(),
    ]
  },
  {
    route: ROUTE_NAMES.SALES_ACTIVITY,
    sessionCheckers: [
      new VerifiedOrgChecker()
    ],
  },
  {
    route: ROUTE_NAMES.ACTIVITY_MANAGER,
    sessionCheckers: [
      new VerifiedOrgChecker()
    ]
  },
  {
    route: ROUTE_NAMES.TIMELINE,
    sessionCheckers: [
      new VerifiedOrgChecker(),
    ]
  },
];

export class RoutePermissionChecker {
  private sessionCheckers: Map<string, SessionChecker[]>;
  private featureFlagCheckers: Map<string, FeatureFlagChecker[]>;

  constructor() {
    this.sessionCheckers = new Map();
    this.featureFlagCheckers = new Map();
    for (const rc of routeCheckers) {
      this.sessionCheckers.set(rc.route, rc.sessionCheckers);
      this.featureFlagCheckers.set(rc.route, rc.featureFlagCheckers || []);
    }
  }

  allow(session: UserSession, flags: FeatureFlags, route: string): boolean {
    const sessionCheckers = this.sessionCheckers.get(route) || [];
    const featureFlagChecker = this.featureFlagCheckers.get(route) || [];
    return sessionCheckers.every(checker => checker.allow(session)) &&
      featureFlagChecker.every(checker => checker.allow(flags, session));
  }
}
