import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faFileAlt, faTrashAlt, faUser} from '@fortawesome/free-solid-svg-icons';
import { Note } from '../../../../models/Note';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import useOrdoToasts from '../../../../hooks/useOrdoToasts';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import {useAPI} from '../../../../context/OrdoApiContext';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {NoteForm} from '../../../components/notes/NoteForm';
import NoteViewModel from '../../../../application-models/sales-activity/NoteViewModel';
import Contact from '../../../../models/order-entry/Contact';
import OrdoModal from '../../../components/common/OrdoModal';
import {AccountActivityType} from '../../../../models/AccountActivity';

type AccountActivityNoteProps = {
  note: Note,
  index: number,
  organizationId: string,
  accountId: string,
  onDelete: Function,
  onEdit: Function,
  accountContacts: Contact[],
  disableExpand?: boolean
}

export const AccountActivityNote = ({note, index, organizationId, accountId, onDelete, onEdit, accountContacts, disableExpand = false}: AccountActivityNoteProps) => {
  const [expandedCard, setExpandedCard] = useState(false);
  const [displayDeleteConfirmationModal, setDisplayDeleteConfirmationModal] = useState(false);
  const [displayEditNoteModal, setDisplayEditNoteModal] = useState(false);
  const {successToast, errorToast} = useOrdoToasts();
  const api = useAPI();
  const [noteViewModel, setNoteViewModel] = useState(new NoteViewModel(api, note.title, note.description, note.contacts));
  const {spinnerVisibility, showSpinner, hideSpinner} = useSpinnerToggle();

  const deleteNote = () => {
    showSpinner();
    api.deleteNote(organizationId, accountId, note.id)
      .then(() => {
        onDelete(index);
        successToast('Note deleted');
      })
      .catch(() => errorToast('Could not delete note'))
      .finally(() => {
        setDisplayDeleteConfirmationModal(!displayDeleteConfirmationModal);
        hideSpinner();
      });
  };

  const updateNote = () => {
    showSpinner();
    noteViewModel.updateNote(organizationId, accountId, note.id)
      .then((updatedNote: Note) => {
        hideSpinner();
        successToast('Note successfully updated');
        setDisplayEditNoteModal(!displayEditNoteModal);
        onEdit(index, updatedNote, AccountActivityType.NOTE, updatedNote.lastEdited);
      })
      .catch(() => {
        errorToast('Could not update the note');
      })
      .finally(hideSpinner);
  };

  const renderContacts = () => {
    if(note.contacts.length > 0) {
      return (
        <div>
          <FontAwesomeIcon className="contacts-icon" onClick={() => {}} size="sm" icon={faUser}/>
          <span>{`${note.contacts[0].name} ${note.contacts.length > 1 ? `+${note.contacts.length - 1}` : ''}`}</span>
        </div>);
    }
    return <></>;
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="account-activity-card" onClick={() => setExpandedCard(!expandedCard)} role="presentation">
        <div className='content'>
          <div className="icon-container notes">
            <FontAwesomeIcon icon={faFileAlt} size="2x" />
          </div>
          <div className="information">
            <div className="collapsed">
              <div>
                <div className="activity-title">
                  <span >{note.title}</span>
                </div>
                <div className="note-information">
                  <span>{`${note.lastEdited} • ${note.createdBy.firstName} ${note.createdBy.lastName} `}</span>
                  {renderContacts()}
                </div>
              </div>
              <div className="actions">
                <FontAwesomeIcon className="icon" onClick={(event) => {
                  event.stopPropagation();
                  setDisplayEditNoteModal(!displayEditNoteModal);
                }} size="lg" icon={faEdit}/>

                <FontAwesomeIcon className="icon" onClick={(event) => {
                  event.stopPropagation();
                  setDisplayDeleteConfirmationModal(!displayDeleteConfirmationModal);
                }} size="lg" icon={faTrashAlt}/>
              </div>
            </div>
            {!disableExpand && expandedCard &&
          <div className="description">
            <span>{note.description}</span>
          </div>}
          </div>
          <ConfirmationModal show={displayDeleteConfirmationModal}
            onClose={() => setDisplayDeleteConfirmationModal(!displayDeleteConfirmationModal)}
            onSubmit={deleteNote}
            confirmationText="are you sure you want to delete the note?"/>
          <OrdoModal title="edit note" show={displayEditNoteModal} onClose={() => setDisplayEditNoteModal(!displayEditNoteModal)} showFooter={false}>
            <NoteForm
              viewModel={noteViewModel}
              updateViewModel={setNoteViewModel}
              onSubmit={() => updateNote()}
              onCancel={() => setDisplayEditNoteModal(!displayEditNoteModal)}
              accountContacts={accountContacts}
            />
          </OrdoModal>
        </div>
      </div>
    </OrdoSpinner>);
};
