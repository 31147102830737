import {Role} from '../Roles';
import {AllocationCollection} from '../allocation/allocationCollection';
import { Member } from './Member';

export type NotMemberInput = {
  role: Role,
  email: string,
  id: string, // refers to invitationTokenId
}

// In the backend, an organization member is created after accepting the invitation.
// Before that, we have pending invitations, and right now we are modeling those
// as members with the alreadyMember attribute in false.
export class NotMember extends Member {
  constructor(role: Role, email: string, public readonly id: string) {
    super(role, email, id);
  }

  public hasAllocations(_allocations: AllocationCollection): boolean {
    return false;
  }

  public isSalesRep(): boolean {
    return false;
  };

  public hasEmail(email: string): boolean {
    return this.email === email;
  }

  public getPhone(): undefined {
    return undefined;
  }

  public getName(): string {
    return 'Pending Invite';
  }

  public getUserId(): undefined {
    return undefined;
  }

  public get alreadyMember() {
    return false;
  }
}
