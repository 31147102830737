import React, {useState} from 'react';
import {OrganizationSubscriptionPlan, SUBSCRIPTION_PLANS} from '../registration/steps/SubscriptionPlan';
import { SubscriptionPlanCard } from '../registration/steps/SubscriptionPlanCard';
import {Organization} from '../../models/Organization';
import OrdoModal from '../components/common/OrdoModal';
import {useAPI} from '../../context/OrdoApiContext';
import {Subscription} from '../../models/Subscription';
import EditSubscriptionViewModel from '../../application-models/EditSubscriptionViewModel';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import OrdoSpinner from '../components/OrdoSpinner';

const EditSubscriptionFormModal = ({organization, isOpen, onSubmit, onClose}: {organization: Organization, isOpen: boolean, onSubmit: Function, onClose: Function}) => {
  const api = useAPI();
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const toasts = useOrdoToasts();
  const [viewModel, setViewModel] = useState(EditSubscriptionViewModel.initialize(api, organization.subscription, organization.id));

  const updatePlan = async (subscription: Partial<Subscription>) => {
    const updatedViewModel = viewModel.updatePlan(subscription);
    setViewModel(updatedViewModel);
  };

  const closeModalWithoutUpdate = () => {
    onClose();
    setViewModel(EditSubscriptionViewModel.initialize(api, organization.subscription, organization.id));
  };

  const onSubmitDo = async () => {
    showSpinner();
    viewModel.updateSubscription()
      .then(() => {
        onClose();
        onSubmit();
        toasts.successToast('Subscription edited successfully');
      })
      .catch((error) => toasts.errorToast(error.message))
      .finally(() => {
        hideSpinner();
      });
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <OrdoModal
      onClose={closeModalWithoutUpdate}
      show={isOpen}
      onSubmit={onSubmitDo}
      title={<div>
        <div>Change plan information</div>
      </div>}
      actionText='update subscription settings'
      dialogClassName='wide-modal-dialog'
      className='edit-subscription-modal'>
      <div className='subscription-plans'>
        {
          [SUBSCRIPTION_PLANS.starter, SUBSCRIPTION_PLANS.lite, SUBSCRIPTION_PLANS.pro, SUBSCRIPTION_PLANS.enterprise]
            .map((plan: OrganizationSubscriptionPlan) =>
              <SubscriptionPlanCard
                key={plan.type}
                plan={plan}
                isSelected={plan.type === viewModel.subscription.type}
                onSelectPlan={() => updatePlan(plan)}
              />)
        }
      </div>
    </OrdoModal>
  </OrdoSpinner>;
};

export default EditSubscriptionFormModal;
