export enum ActivityTypeName {
  CALL = 'Call',
  EMAIL = 'Email',
  TEXT = 'Text',
  IN_PERSON = 'In-person',
  CONFERENCE = 'Conference',
  EXTERNAL_EMAIL_ACTIVITY = '%Email%',
  OTHER = 'Other',
}

export const ACTIVITY_TYPES = Object.values(ActivityTypeName);

export function convertToBackendActivityType(activityType: ActivityTypeName): number {
  return ACTIVITY_TYPES.indexOf(activityType);
}

export function convertFromBackendActivityType(activityTypeIndex: number): ActivityTypeName {
  return ACTIVITY_TYPES[activityTypeIndex];
}
