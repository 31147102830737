import React, {ReactNode, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt, faUser} from '@fortawesome/free-solid-svg-icons';
import '../../scss/ordo/timeline-page/popup-info.scss';
import Contact from '../../models/order-entry/Contact';

type PopupItemInfoProps = {
  title: string,
  description?: ReactNode,
  date: string,
  createdBy: string,
  icon: ReactNode,
  contacts: Contact[],
  itemStyle: string
  checkbox?: ReactNode
  footer?: ReactNode
  extraText?: string,
  uniqueKey: string,
  onUpdate: Function,
  onDelete: Function,
};

export const PopupItemInfo = ({uniqueKey, title, description, date, createdBy, icon, contacts, itemStyle, footer, checkbox, extraText, onUpdate, onDelete}: PopupItemInfoProps) => {
  const [expandedCard, setExpandedCard] = useState(false);
  const renderContacts = () => {
    if(contacts.length > 0) {
      return (
        <div>
          <FontAwesomeIcon className="contacts-icon" onClick={() => {}} size="sm" icon={faUser}/>
          <span>{`${contacts[0].name} ${contacts.length > 1 ? `+${contacts.length - 1}` : ''}`}</span>
        </div>);
    }
    return <></>;
  };

  return(<div id={uniqueKey} className={`account-activity-card ${itemStyle}`} onClick={() => setExpandedCard(!expandedCard)} role="presentation">
    <div className={`icon-container ${itemStyle}`}>
      {icon}
    </div>
    <div className="information">
      <div className="collapsed">
        <div>
          <div className="activity-title" title={title}>
            {checkbox}
            <span>{title}</span>
          </div>
          <div className="note-information">
            <span>{`${date} • ${createdBy}`}</span>
            {renderContacts()}
          </div>
        </div>
        {extraText !== undefined
          ? <div className="extra-text">
            {extraText}
          </div>
          : <div className="actions">
            <FontAwesomeIcon className="icon" onClick={()=>onUpdate()} size="lg" icon={faEdit}/>
            <FontAwesomeIcon className="icon" onClick={()=>onDelete()} size="lg" icon={faTrashAlt}/>
          </div>}
      </div>
      <div className={`description ${expandedCard ? '' : 'partial-display'}`}>
        {description}
      </div>
      {footer}
    </div>
  </div>);
};
