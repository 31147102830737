import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {faArrowUp} from '@fortawesome/free-solid-svg-icons/faArrowUp';
import {faArrowDown} from '@fortawesome/free-solid-svg-icons/faArrowDown';
import {OrdoCheckbox} from '../../../components/OrdoCheckbox';
import {
  ActivityQuestion,
  ActivityQuestionType,
  isStaticQuestionType,
  parseActivityType,
  questionTypes
} from '../../../../models/sales-activity/ActivityQuestion';
import OrdoDropdown from '../../../components/common/OrdoDropdown';
import {StaticQuestionTypePreview} from './StaticQuestionTypePreview';
import {DynamicQuestionTypePreview} from './DynamicQuestionTypePreview';

export enum SortingDirection {
  UP,
  DOWN
}

type ActivityTemplateQuestionProps = {
  index: number,
  question: ActivityQuestion,
  onQuestionUpdate: (questionIndex: number, updatedQuestion: ActivityQuestion) => void,
  onDeleteQuestion: (questionIndex: number) => void,
  onCloneQuestion: (question: ActivityQuestion) => void,
  onMovingQuestion: (direction: SortingDirection, index: number) => void
}

export const ActivityTemplateQuestion = ({index, question, onQuestionUpdate, onDeleteQuestion, onCloneQuestion, onMovingQuestion}: ActivityTemplateQuestionProps) => {
  const [questionTitle, setQuestionTitle] = useState(question.questionTitle);
  const [isRequired, setIsRequired] = useState(question.isRequired);
  const [questionType, setQuestionType] = useState(question.questionType || ActivityQuestionType.NoTypeSelectedYet);
  const [options, setOptions] = useState(question.options || []);

  useEffect(() => {
    onQuestionUpdate(index, {id: question.id, index: index, isRequired: isRequired, questionType: questionType, questionTitle: questionTitle, options: options});
  }, [questionTitle, questionType, isRequired, options]);

  const updateQuestion = () => onQuestionUpdate(index, {
    id: question.id,
    index: question.index,
    questionTitle: questionTitle,
    isRequired: isRequired,
    questionType: questionType,
    options: options
  });

  const optionsUpdate = (updatedOptions: string[]) => {
    setOptions(updatedOptions);
    onQuestionUpdate(index, {id: question.id, index: question.index, questionTitle: questionTitle, isRequired: isRequired, questionType: questionType, options: updatedOptions});
  };

  return(
    <div className='activity-template-question-container'>
      <div className='sorting-arrows'>
        <FontAwesomeIcon className="arrow-up" onClick={() => onMovingQuestion(SortingDirection.UP, index)} size="2x" icon={faArrowUp} key={`arrow-up-${index}`}/>
        <FontAwesomeIcon onClick={() => onMovingQuestion(SortingDirection.DOWN, index)} size="2x" icon={faArrowDown} key={`arrow-down-${index}`}/>
      </div>
      <div className='question-container'>
        <div className='question-header'>
          <input className="question-name" value={questionTitle} key={`question-number-${index}-name`}
            onChange={(event) => {
              setQuestionTitle(event.target.value);
            }}
            style={{paddingLeft: 0}}
            onBlur={updateQuestion}
          />
          <div className='question-type-selector-container'>
            <OrdoDropdown
              placeholder='select an activity type'
              selectedOption={questionType}
              options={questionTypes}
              onSelect={(type: string) => {
                setQuestionType(parseActivityType(type));
                updateQuestion();
              }}
              height='short'
            />
          </div>
        </div>
        <div className='question-bottom'>
          {isStaticQuestionType(questionType) && <StaticQuestionTypePreview questionType={questionType}/>}
          {!isStaticQuestionType(questionType) && <DynamicQuestionTypePreview questionType={questionType} onOptionsUpdate={optionsUpdate} options={options}/>}
          <div className='question-buttons'>
            <FontAwesomeIcon className="delete-icon" onClick={() => onCloneQuestion(question)} size="1x" icon={faCopy}/>
            <FontAwesomeIcon className="delete-icon" onClick={() => onDeleteQuestion(index)} size="1x" icon={faTrashAlt}/>
            <OrdoCheckbox checked={isRequired}
              onChange={() => {
                setIsRequired(!isRequired);
                updateQuestion();
              }}
              style={{marginBottom: '0'}}
              label='required'
              id={`required-question-number-${question.id}`}/>
          </div>
        </div>
      </div>
    </div>
  );
};