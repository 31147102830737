import React, {useContext, useState} from 'react';
import {Spinner} from 'react-bootstrap';
import '../../../../scss/ordo/metrc-inventory-sync.scss';
import useModalToggle from '../../../../hooks/useModalToggle';
import metrcLogo from '../../../profile/metrc.png';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import {OrganizationLicense, organizationLicenseAddress} from '../../../../models/OrganizationLicense';
import {MetrcItemsSyncViewModel} from '../../../../application-models/metrc-integration/MetrcItemsSyncViewModel';
import {MetrcItemSyncStep} from '../../../../application-models/metrc-integration/MetrcItemSyncStep';
import {useAPI} from '../../../../context/OrdoApiContext';
import {MetrcInventorySyncWizard} from './MetrcInventorySyncWizard';

const renderFirstStep = (viewModel: MetrcItemsSyncViewModel, organizationLicenses: OrganizationLicense[], toggleLicense: Function) => {
  return ( <div>
    {organizationLicenses.map((organizationLicense) => {
      return (
        <div className="sync-license" key={organizationLicense.license.licenseNumber}>
          <input style={{visibility: 'visible'}}className='checkbox' type="checkbox" checked={viewModel.isSelected(organizationLicense)} onChange={()=> toggleLicense(organizationLicense)}/>
          <div className="license-and-address">
            <span className="license">{organizationLicense.license.licenseNumber}</span>
            <span className="address">{organizationLicenseAddress(organizationLicense)}</span>
          </div>
        </div>
      );
    })}
  </div>);};

const renderSecondStep = (viewModel: MetrcItemsSyncViewModel) => {
  return (
    <div className="step2-container">
      <span className="title">selected locations</span>
      <span>{viewModel.selectedLicenses.map(orgLicense => orgLicense.license.licenseNumber).join(', ')}</span>
    </div>
  );
};

const renderThirdStep = () => {
  return (
    <div className='step3-container'>
      <Spinner animation="border" className="ordo-spinner"/>
    </div>
  );
};

const renderErrors = (errors: string[]) => {
  if (errors.length === 0) {
    return (<div/>);
  }

  if (errors.length > 3) {
    /* eslint-disable-next-line no-param-reassign */
    errors = errors.slice(0, 3);
  }

  const renderedErrors = errors.map((err) => {
    return (
      <li className="error" key={err}> { err } </li>
    );
  });

  return (
    <div className="error-container">
      <div> While some inventory may have been synced, there were some errors </div>
      <ul>
        { renderedErrors }
      </ul>
    </div>
  );
};

const renderFourthStep = (viewModel: MetrcItemsSyncViewModel) => {
  const total = viewModel.resultTotal();
  const result = viewModel.syncResult;
  return (
    <div className="metrc-sync-results-container row">
      <div className="result">
        <span className="title">products found</span>
        <span>{result.productsFound}/{total}</span>
      </div>
      <div className="result">
        <span className="title">total quantity</span>
        <span>{result.totalQuantity}</span>
      </div>
      <div className="result">
        <span className="title">unlinked products</span>
        <span>{result.unlinkedProducts}/{total}</span>
      </div>
      <div className="result">
        <span className="title">out of stock</span>
        <span>{result.outOfStock}/{total}</span>
      </div>
      <div className="errors">
        { renderErrors(result.errors) }
      </div>
    </div>
  );
};

export const MetrcItemsSync = () => {
  const {showModal, closeModal, openModal} = useModalToggle();
  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const organizationLicenses : OrganizationLicense[] = organization.licenses;
  const [viewModel, setViewModel] = useState(MetrcItemsSyncViewModel.initialize(api));

  const toggleLicense = (orgLicense: OrganizationLicense) => {
    setViewModel(viewModel.toggleLicense(orgLicense));
  };

  const closeMetrcSyncModal = () => {
    setViewModel(MetrcItemsSyncViewModel.initialize(api));
    closeModal();
  };

  const goStepBack = () => {
    if(viewModel.isStep(MetrcItemSyncStep.FIRST)) {
      closeMetrcSyncModal();
    } else {
      setViewModel(viewModel.goStepBack());
    }
  };

  const submitStep = () => {
    if(viewModel.isStep(MetrcItemSyncStep.FOURTH))  {
      closeMetrcSyncModal();
    } else {
      setViewModel(viewModel.submitStep(setViewModel, organization.id));
    }
  };

  const steps = new Map([
    [MetrcItemSyncStep.FIRST, ()=> renderFirstStep(viewModel, organizationLicenses, toggleLicense)],
    [MetrcItemSyncStep.SECOND, ()=> renderSecondStep(viewModel)],
    [MetrcItemSyncStep.THIRD, ()=> renderThirdStep()],
    [MetrcItemSyncStep.FOURTH, ()=> renderFourthStep(viewModel)],
  ]);

  return <>
    <div role="presentation" className="metrc-sync-button" onClick={openModal}>
      <div className="metrc-sync-button__logo">
        <img role="presentation" className="metrc-icon" alt="metrc-icon" src={metrcLogo}/>
      </div>
      <div className='metrc-sync-button__text'>metrc sync</div>
    </div>
    <MetrcInventorySyncWizard viewModel={viewModel} show={showModal} onClose={closeMetrcSyncModal}
      onSubmit={submitStep} steps={steps} onSecondaryAction={goStepBack} />
  </>;
};
