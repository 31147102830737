import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { DiscountType } from '../../models/order-entry/Order';
import OrdoButton from '../components/common/OrdoButton';
import {PRIMARY_COLOR} from '../../constants';

import { AddDiscountButton, AddDiscountButtonProps } from './AddDiscountButton';


export const WholeOrderDiscountButton = ({
  item,
  onChange,
  onBlur,
  onSwitch,
  iconsStyle={},
  invalidWithoutMessage= false,
  discountButtonStyle
}: AddDiscountButtonProps) => {
  const showDiscountInput = item.getDiscountType() !== DiscountType.None;
  const label = 'whole order discount';

  if (showDiscountInput) {
    return (
      <AddDiscountButton
        item={item}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        onSwitch={onSwitch}
        discountButtonStyle={discountButtonStyle || {width: '160px', marginBottom: '15px'}}
        iconsStyle={iconsStyle}
        invalidWithoutMessage={invalidWithoutMessage}
      />
    );
  }

  const renderAddDiscountButtonContents = () => {
    return (
      <div style={{fontWeight: 'bolder', marginBottom: '15px'}}>
        <FontAwesomeIcon color={`${PRIMARY_COLOR}`} size="lg" icon={faPlusCircle} className="add-discount-button__icon"/>
        <span> { label } </span>
      </div>
    );
  };

  return (
    <OrdoButton
      additionalClassName="add-discount-button"
      firstChild={renderAddDiscountButtonContents()}
      onClick={() => onSwitch(DiscountType.Dollar)}
      text=''
      disabled={false}
      category='link'
      dataTestId='whole-order-discount-button'
    />
  );
};
