import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons/faEdit';
import '../../scss/ordo/contactsPage/contacts-page.scss';
import {faCheck} from '@fortawesome/free-solid-svg-icons/faCheck';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import {faClone} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router';
import {ContactWithAccountName} from './ContactsPage';
import OrdoInput from '../components/common/inputs/OrdoInput';
import Contact from '../../models/order-entry/Contact';
import {useAPI} from '../../context/OrdoApiContext';
import {ContactRequestInput} from '../../lib/api/request-types';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import OrdoSpinner from '../components/OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoDropdown from '../components/common/OrdoDropdown';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';

type ContactItemRowProps = {contactWithAccountName: ContactWithAccountName, onUpdateContact: (contact: ContactWithAccountName) => void, organizationId: string, displayableAccounts: AccountIdAndName[]}
export type AccountIdAndName = {accountName: string, accountId: string}
export const ContactItemRow = ({contactWithAccountName, onUpdateContact, organizationId, displayableAccounts} : ContactItemRowProps) => {
  const [editingContact, setEditingContact] = useState(false);
  const [contactName, setContactName] = useState<string>(contactWithAccountName.contact.name);
  const [contactTitle, setContactTitle] = useState(contactWithAccountName.contact.title);
  const [contactPhone, setContactPhone] = useState(contactWithAccountName.contact.phone);
  const [contactEmail, setContactEmail] = useState(contactWithAccountName.contact.email);
  const [contactAccount, setContactAccount] = useState<AccountIdAndName>({accountId: contactWithAccountName.contact.accountId, accountName: contactWithAccountName.accountName});
  const api = useAPI();
  const {successToast, errorToast} = useOrdoToasts();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const history = useHistory();

  const resetContact = () => {
    setContactName(contactWithAccountName.contact.name);
    setContactEmail(contactWithAccountName.contact.email);
    setContactPhone(contactWithAccountName.contact.phone);
    setContactTitle(contactWithAccountName.contact.title);
  };

  const editContact = async () => {
    const contactRequest : ContactRequestInput = {
      name: contactName,
      title: contactTitle || '',
      phone: contactPhone,
      email: contactEmail,
      accountId: contactAccount.accountId,
    };

    showSpinner();
    api.updateContact(contactRequest, contactWithAccountName.contact.id, contactWithAccountName.contact.accountId, organizationId)
      .then((updatedContact: Contact) => {
        onUpdateContact({contact: updatedContact, accountName: contactAccount.accountName});
        successToast('Contact updated');
      })
      .catch(() => {
        errorToast('Could not update contact');
        resetContact();
      })
      .finally(() => {
        setEditingContact(false);
        hideSpinner();
      });
  };

  const goToAccountPage = () => {
    history.push(ROUTE_NAMES.ACCOUNT_PAGE.replace(':accountId', contactWithAccountName.contact.accountId));
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="contact-item">
        <div className='contact-name'>
          {!editingContact && <div className='contact-item-line'>{contactName}</div>}
          {editingContact && <OrdoInput inputName={`contact-name-${contactWithAccountName.contact.id}`} onChange={(newName) => setContactName(newName)}
            value={contactName} placeholder='' inputSize='large' bottomMargin={false}/>}
        </div>
        <div className='contact-title'>
          {!editingContact && <div className='contact-item-line'>{contactTitle}</div>}
          {editingContact && <OrdoInput inputName={`contact-title-${contactWithAccountName.contact.id}`} onChange={(newTitle: string) => setContactTitle(newTitle)}
            value={contactTitle || ''} placeholder='' inputSize='large' bottomMargin={false}/>}
        </div>
        <div className='contact-account-name'>
          {!editingContact && <span role='presentation' onClick={()=> goToAccountPage()} className='contact-item-line'>{contactWithAccountName.accountName}</span>}
          {editingContact && <OrdoDropdown
            height="short"
            selectedOption={contactAccount}
            placeholder='select an account'
            onSelect={(account: AccountIdAndName) => setContactAccount(account)}
            options={displayableAccounts}
            displayName={(account: AccountIdAndName) => account.accountName}
            forceLowerCase={false}
            disabled={!displayableAccounts}
          />}
        </div>
        <div className='contact-phone'>
          {!editingContact && <>
            {contactPhone && <FontAwesomeIcon className='copy-icon' size="1x" icon={faClone} onClick={() => navigator.clipboard.writeText(contactPhone)}/>}
            <div className='contact-item-line'>{contactPhone}</div>
          </>
          }
          {editingContact && <OrdoInput inputName={`contact-phone-${contactWithAccountName.contact.id}`} onChange={(newPhone: string) => setContactPhone(newPhone)}
            value={contactPhone || ''} placeholder='' inputSize='large' bottomMargin={false}/>}
        </div>
        <div className='contact-email'>
          {!editingContact && <>
            {contactEmail && <FontAwesomeIcon className='copy-icon' size="1x" icon={faClone} onClick={() => navigator.clipboard.writeText(contactEmail)}/>}
            <div className='contact-item-line'>{contactEmail}</div>
          </>}
          {editingContact && <OrdoInput inputName={`contact-email-${contactWithAccountName.contact.id}`} onChange={(newEmail: string) => setContactEmail(newEmail)}
            value={contactEmail || ''} placeholder='' inputSize='large' bottomMargin={false}/>}
        </div>
        {!editingContact && <div className='contact-edit-button'>
          <FontAwesomeIcon icon={faEdit} onClick={() => setEditingContact(true)}/>
        </div>}
        {editingContact && <div className='contact-edit-button'>
          <FontAwesomeIcon icon={faCheck} onClick={() => editContact()}/>
        </div>}
        {editingContact && <div className='contact-edit-button'>
          <FontAwesomeIcon className='cancel-contact-edition' icon={faTimes} onClick={() => {
            resetContact();
            setEditingContact(false);
          }}/>
        </div>}
      </div>
    </OrdoSpinner>
  );
};