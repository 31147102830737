import React from 'react';
import OrdoModal from '../../common/OrdoModal';
import flourishLogo from '../../../profile/flourishLogo.png';

type FlourishSyncIntegrationModalProps = {
    isOpen: boolean,
    onClose: Function,
    onSubmit: () => any,
}

const FlourishSyncIntegrationModal = ({isOpen, onClose, onSubmit} : FlourishSyncIntegrationModalProps) => {

  const modalHeader = (<>
    <div className="flourish-sync-button__logo">
      <img role="presentation" className="flourish-icon" alt="flourish-icon" src={flourishLogo}/>
    </div>
    <div className='flourish-sync-button__text'>Flourish sync</div>
  </>);

  const flourishIntegrationModalHeader = (
    <div>
      <div className="flourish-integration-header">
        {modalHeader}
      </div>
    </div>
  );

  return (
    <OrdoModal show={isOpen}
      onClose={onClose}
      title={flourishIntegrationModalHeader}
      actionText='sync'
      disableCTA={false}
      onSubmit={() => onSubmit()}>
      <div className="flourish-integration-sync-container">
        <span>Click <b>sync</b> to begin syncing your Ordo inventory with Flourish.</span>
      </div>
    </OrdoModal>
  );
};

export default FlourishSyncIntegrationModal;