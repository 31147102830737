import React, {useEffect, useState} from 'react';
import {
  ActivityItem,
  ActivityTemplate,
  ActivityTemplateWithCount
} from '../../../../../models/sales-activity/Activity';
import {useAPI} from '../../../../../context/OrdoApiContext';
import useOrdoToasts from '../../../../../hooks/useOrdoToasts';
import useSpinnerToggle from '../../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../../components/OrdoSpinner';
import {ActivityModal} from '../../../../components/modals/ActivityModal';
import {ActivitiesFilter} from '../../../filter/ActivitiesFilter';
import {ActivityDetailsRow} from '../../../../sales-activity/activities/ActivityDetailsRow';
import {OrdoDate} from '../../../../../models/OrdoDate';
import {AccountWithContactsAndSalesReps} from '../../../../../models/AccountWithContactsAndSalesReps';

type ActivitiesListProps = {
  organizationId: string,
  filter: ActivitiesFilter,
  addActivityModalOpen: boolean,
  closeAddActivityModal: Function,
  accounts: AccountWithContactsAndSalesReps[]
}

type ActivitiesSectionProps = {
  organizationId: string,
  sectionName: string,
  activities: ActivityItem[],
  activityTemplates: ActivityTemplate[],
  editActivity: Function,
  removeActivity: Function,
  accounts: AccountWithContactsAndSalesReps[]
};

function ActivitiesSection({organizationId, activities, activityTemplates, sectionName, editActivity, removeActivity, accounts}: ActivitiesSectionProps) {
  const filteredActivities = activities;
  return (filteredActivities.length > 0) ? <div className={`activities-section ${sectionName}`}>
    <div className={`activities-section__header ${sectionName}`}>
      <span className="name">{sectionName}</span>
      <span>{filteredActivities.length}</span>
    </div>
    {filteredActivities.map((activity: ActivityItem) => <ActivityDetailsRow
      key={activity.id}
      displayName={activity.accountName}
      activitySubtitle={activity.activityTemplate.name}
      activity={activity}
      accountId={activity.accountId}
      accountName={activity.accountName}
      activityTemplates={activityTemplates}
      orgId={organizationId}
      accounts={accounts}
      onActivityUpdate={(updatedActivity) => editActivity(updatedActivity as ActivityItem)}
      onActivityDelete={(deletedActivity) => removeActivity(deletedActivity.id)}/>)
    }
  </div> : <></>;
}

export const ActivitiesMobileList = ({organizationId, filter, addActivityModalOpen, closeAddActivityModal, accounts} : ActivitiesListProps) => {
  const [activities, setActivities] = useState<ActivityItem[]>([]);
  const [activityTemplates, setActivityTemplates] = useState<ActivityTemplate[]>([]);
  const [filteredActivities, setFilteredActivities] = useState<ActivityItem[]>([]);
  const api = useAPI();
  const {errorToast} = useOrdoToasts();
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();

  useEffect(() => {
    showSpinner();
    api.getOrganizationActivities(organizationId)
      .then((activitiesResponse: ActivityItem[]) => {
        setActivities(activitiesResponse);
        setFilteredActivities(filter.filterActivityItems(activitiesResponse));
      })
      .catch(() => errorToast('Could not fetch organization activities'))
      .finally(hideSpinner);

    api.getActivitiesTemplatesForOrg(organizationId)
      .then((templates) => setActivityTemplates(templates.map((a: ActivityTemplateWithCount) => {
        return new ActivityTemplate(a.activityTemplate.id, a.activityTemplate.name, a.activityTemplate.questions, a.activityTemplate.iconName);
      })))
      .catch(() => errorToast('Could not fetch activity types'));
  }, [organizationId]);

  useEffect(() => {
    setFilteredActivities(filter.filterActivityItems(activities));
  }, [filter, activities]);

  const removeActivity = (activityId: string) => {
    const updatedActivities = activities.filter(activity => activity.id !== activityId);
    setActivities(updatedActivities);
  };

  const editActivity = (updatedActivity: ActivityItem) => {
    const index = activities.findIndex((activity) => activity.id === updatedActivity.id);
    activities.splice(index, 1, updatedActivity);
    setActivities([...activities]);
  };

  const addActivity = (activity: ActivityItem) => {
    setActivities(activities.concat(activity));
  };

  const overdueActivities = () => {
    return filteredActivities.filter(activityItem => (!activityItem.completed && OrdoDate.isDueDate(activityItem.date)));
  };

  const scheduledActivities = () => {
    return filteredActivities.filter(activityItem => (!activityItem.completed && !OrdoDate.isDueDate(activityItem.date)));
  };

  const completedActivities = () => {
    return filteredActivities.filter(activityItem => activityItem.completed);
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <ActivitiesSection
        activities={overdueActivities()}
        accounts={accounts}
        activityTemplates={activityTemplates}
        organizationId={organizationId}
        sectionName='overdue'
        editActivity={editActivity}
        removeActivity={removeActivity}
      />
      <ActivitiesSection
        activities={scheduledActivities()}
        accounts={accounts}
        activityTemplates={activityTemplates}
        organizationId={organizationId}
        sectionName='scheduled'
        editActivity={editActivity}
        removeActivity={removeActivity}
      />
      <ActivitiesSection
        activities={completedActivities()}
        accounts={accounts}
        activityTemplates={activityTemplates}
        organizationId={organizationId}
        sectionName='completed'
        editActivity={editActivity}
        removeActivity={removeActivity}
      />
      {addActivityModalOpen &&
          <ActivityModal isOpen={addActivityModalOpen} organizationId={organizationId} onSubmit={addActivity} onSubmitMany={addActivity}
            onClose={closeAddActivityModal}/>}
      {filteredActivities.length === 0 && <span className="no-activities">{activities.length === 0 ? 'No activities created' : 'No activities match the selected filter'}</span>}
    </OrdoSpinner>);
};
