import React, {useState} from 'react';
import OrdoModal from '../../components/common/OrdoModal';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import {FlourishIntegration} from './FlourishIntegrationRow';
import {FlourishInventoryOrItemsRadioGroup} from './FlourishInventoryOrItemsRadioGroup';

type EditFlourishIntegrationModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: (configId:string, flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string) => any,
  integration: FlourishIntegration,
}

const EditFlourishIntegrationModal = ({isOpen, onClose, onSubmit, integration} : EditFlourishIntegrationModalProps) => {
  const [flourishAPIKey, setFlourishAPIKey] = useState(integration.apiKey);
  const [flourishAPIUsername, setFlourishAPIUsername] = useState(integration.apiUsername);
  const [flourishSyncInventoryOrItems, setFlourishSyncInventoryOrItems] = useState(integration.config?.syncInventoryOrItems || 'Both');
  const configId = integration.id;

  return (
    <OrdoModal show={isOpen}
      onClose={onClose}
      title="setup flourish integration"
      actionText='save'
      disableCTA={!flourishAPIKey || !flourishAPIUsername}
      onSubmit={() => onSubmit(configId, flourishAPIUsername, flourishAPIKey, flourishSyncInventoryOrItems)}>
      <div>
        <OrdoInput inputName="username" value={flourishAPIUsername} placeholder="flourish api username"
          label="API Username" inputSize="large" onChange={setFlourishAPIUsername}/>
        <OrdoInput inputName="key" value={flourishAPIKey} placeholder="flourish api key"
          label="API Key" inputSize="large" onChange={setFlourishAPIKey}/>

        <div className="form-group ">
          <p>Choose to sync Inventory or Items:</p>
          <div className="form-group mt-3 d-flex">
            <FlourishInventoryOrItemsRadioGroup selected={flourishSyncInventoryOrItems} onChange={setFlourishSyncInventoryOrItems} />
          </div>
        </div>
      </div>
    </OrdoModal>
  );
};

export default EditFlourishIntegrationModal;
