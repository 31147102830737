import {Paginator, PaginatorFactory} from '../../../utils/pagination/InMemoryPaginator';
import {ContactWithAccountName} from '../ContactsPage';
import {Organization} from '../../../models/Organization';
import {API, ContactsFilter} from '../../../lib/api/ordoApi';
import {BackendContactsPageFilter, ContactsPageFilter, InMemoryContactsPageFilter} from '../filter/ContactsFilter';
import {SelectableFilter} from '../../../application-models/OrderHistoryViewModel';
import Contact from '../../../models/order-entry/Contact';

type ContactsPagePaginationAndFilterInput = {
    page: number
    paginator: Paginator<ContactWithAccountName>
    searchedContactNameOrTitle: string
    contactsPageFilter: ContactsPageFilter
    selectedAccounts: SelectableFilter[]
    selectedSalesReps: SelectableFilter[]
}
export class ContactsPagePaginationAndFilter {
    readonly page: number;
    private readonly paginator: Paginator<ContactWithAccountName>;
    public contacts: ContactWithAccountName[]
    public filteredContacts: ContactWithAccountName[]
    public searchedContactNameOrTitle: string;
    public selectedAccounts: SelectableFilter[];

    public selectedSalesReps: SelectableFilter[];
    private readonly contactsPageFilter: ContactsPageFilter;
    public static FIRST_PAGE_NUMBER: number = 1;
    public static CONTACTS_PER_PAGE: number = 20;
    constructor(contactsPagePaginationAndFilterInput : ContactsPagePaginationAndFilterInput) {
      this.page = contactsPagePaginationAndFilterInput.page;
      this.paginator = contactsPagePaginationAndFilterInput.paginator;
      this.searchedContactNameOrTitle = contactsPagePaginationAndFilterInput.searchedContactNameOrTitle;
      this.selectedAccounts = contactsPagePaginationAndFilterInput.selectedAccounts;
      this.selectedSalesReps = contactsPagePaginationAndFilterInput.selectedSalesReps;
      this.contacts = this.paginator.getCurrentPageElements();
      this.filteredContacts = this.paginator.getCurrentPageElements();
      this.contactsPageFilter = contactsPagePaginationAndFilterInput.contactsPageFilter;
    }

    public static empty() : ContactsPagePaginationAndFilter {
      return new ContactsPagePaginationAndFilter({
        page: this.FIRST_PAGE_NUMBER,
        paginator: PaginatorFactory.empty(),
        selectedAccounts: [],
        selectedSalesReps: [],
        searchedContactNameOrTitle: '',
        contactsPageFilter: new InMemoryContactsPageFilter([])
      });
    }

    public async initialize(api: API, organization: Organization) : Promise<ContactsPagePaginationAndFilter> {
      const {paginator, contactsPageFilter} = await this.buildComponentsForPaginatedOrders(api, organization);
      return new ContactsPagePaginationAndFilter({
        page: ContactsPagePaginationAndFilter.FIRST_PAGE_NUMBER,
        paginator: paginator,
        searchedContactNameOrTitle: '',
        selectedAccounts: [],
        selectedSalesReps: [],
        contactsPageFilter: contactsPageFilter
      });
    }

    public totalAmountOfPages() {
      return this.paginator.amountOfPages(ContactsPagePaginationAndFilter.CONTACTS_PER_PAGE);
    }

    public async updatePage(page: number) {
      await this.paginator.paginate(ContactsPagePaginationAndFilter.CONTACTS_PER_PAGE, page);

      return new ContactsPagePaginationAndFilter({
        page: page,
        paginator: this.paginator,
        selectedAccounts: this.selectedAccounts,
        selectedSalesReps: this.selectedSalesReps,
        searchedContactNameOrTitle: this.searchedContactNameOrTitle,
        contactsPageFilter: this.contactsPageFilter
      });
    }

    public async filterByContactNameOrTitle(contactNameOrTitle: string) {
      this.searchedContactNameOrTitle = contactNameOrTitle;
      return this.applyMultiFilters();
    }


    public salesReps() {
      return this.contactsPageFilter.salesReps();
    }

    public accounts() {
      return this.contactsPageFilter.accounts();
    }

    public filterByAccounts(accounts: SelectableFilter[]) {
      this.selectedAccounts = accounts;
      return this.applyMultiFilters();
    }

    public filterBySalesReps(salesReps: SelectableFilter[]) {
      this.selectedSalesReps = salesReps;
      return this.applyMultiFilters();
    }

    public addContact(contact: Contact) {
      this.contacts = [{contact: contact, accountName: ''}, ...this.contacts];

      return new ContactsPagePaginationAndFilter({
        page: this.page,
        paginator: this.paginator,
        searchedContactNameOrTitle: this.searchedContactNameOrTitle,
        contactsPageFilter: this.contactsPageFilter,
        selectedAccounts: this.selectedAccounts,
        selectedSalesReps: this.selectedSalesReps
      });
    }

    public updateContact(contact: ContactWithAccountName, index: number) {
      this.contacts[index] = contact;

      return new ContactsPagePaginationAndFilter({
        page: this.page,
        paginator: this.paginator,
        searchedContactNameOrTitle: this.searchedContactNameOrTitle,
        contactsPageFilter: this.contactsPageFilter,
        selectedAccounts: this.selectedAccounts,
        selectedSalesReps: this.selectedSalesReps
      });
    }

    private async applyMultiFilters() {
      const newFilter: ContactsFilter = {
        contactNameOrTitle: this.searchedContactNameOrTitle,
        accountIds: this.selectedAccounts.map(account => account.value),
        salesReps: this.selectedSalesReps.map(status => status.value)
      };
      const paginator = this.paginator.withFilter(newFilter);
      await paginator.paginate(ContactsPagePaginationAndFilter.CONTACTS_PER_PAGE, ContactsPagePaginationAndFilter.FIRST_PAGE_NUMBER);
      return new ContactsPagePaginationAndFilter({
        searchedContactNameOrTitle: this.searchedContactNameOrTitle,
        selectedAccounts: this.selectedAccounts,
        selectedSalesReps: this.selectedSalesReps,
        page: ContactsPagePaginationAndFilter.FIRST_PAGE_NUMBER,
        paginator: paginator,
        contactsPageFilter: this.contactsPageFilter
      });
    }

    private async buildComponentsForPaginatedOrders(api: API, organization: Organization) {
      const paginator = await this.buildBackendPaginator(api, organization);
      const accounts = await api.getAssignedAccountsForDropdown(organization.id);

      const contactsPageFilter = new BackendContactsPageFilter(api, organization, accounts);

      return {paginator: paginator, contactsPageFilter: contactsPageFilter};
    }

    private buildBackendPaginator(api: API, organization: Organization) {
      return PaginatorFactory.inBackend((page: number, contactsPerPage: number, filter: any) => api.getOrganizationContacts(organization.id, page, contactsPerPage, filter),
        ContactsPagePaginationAndFilter.FIRST_PAGE_NUMBER,
        ContactsPagePaginationAndFilter.CONTACTS_PER_PAGE,
        {});
    }
}