/**
 * Please read the documentation before modifying this file:
 * https://ordo.atlassian.net/wiki/spaces/ENG/pages/695107585/Feature+Flags+How+to+add+a+new+feature+flag
 */

import {UserSession} from '../../models/UserSession';
import {MappingSourceName} from '../api/request-types';
import {SubscriptionType} from '../../models/Subscription';

/**
 * Represents a user that we present to an external feature flag system.
 * We should NOT be placing any personal information or credentials in here (e.g. email, idTokens, etc)
 * This will let the feature flag system determine what set of feature flags should currently be enabled for this user
 */
export type FeatureFlagUser = {
  id: string
  currentOrganizationId: string | null | undefined // Note (mk): Had to match the type in our user model.... We should update that
}

export function toFeatureFlagUser(userData: UserSession): FeatureFlagUser {
  return {
    id: userData.user.id,
    currentOrganizationId: userData.currentOrganization?.id,
  };
}

const FeatureFlagNames = [
  'metrc-integration',
  'flourish-integration',
  'integrations-integration',
  'nabis-integration',
  'gsuite-integration',
  'order-entry-inventory',
  'feature-level',
  'paginated-sales-activity-feature',
] as const;
// This is so default flags always sets all of them as false
type FlagName = typeof FeatureFlagNames[number];

// These values need to match with darkly's 'feature level' flag values which can not be edited.
// We have to live with the name discrepancy till we create new flags and use those instead.
/* Do not change the values ('basic', etc.), only the enum names (STARTER, etc.) */
export enum FeatureLevel {
  STARTER =  'basic',
  LITE = 'pro',
  PRO = 'premium'
}

export type FeatureFlags = {
  [flagName in FlagName]: boolean | FeatureLevel;
};

function defaultFlags() {
  const flags: Partial<FeatureFlags> = {};
  FeatureFlagNames.forEach((flagName: FlagName) => {
    switch (flagName) {
    case 'feature-level': {
      flags[flagName] = FeatureLevel.STARTER;
      break;
    }
    default: {
      flags[flagName] = false;
      break;
    }
    }
  });
  return flags as FeatureFlags;
}

export const DEFAULT_FLAGS: FeatureFlags =  defaultFlags();

export function allFlagsWithDefault(flags: Partial<FeatureFlags>) {
  const allFlags: Partial<FeatureFlags> = {};
  FeatureFlagNames.forEach(flagName => {
    allFlags[flagName] = flags[flagName] || DEFAULT_FLAGS[flagName];
  });
  return allFlags as FeatureFlags;
}
/**
 * A FeatureFlagChecker is able to perform somes checks based on the current state of the feature flags
 */
export interface FeatureFlagChecker {
  allow(flags: FeatureFlags, session?: UserSession): boolean
}

export class MetrcIntegrationFlagEnabledChecker {
  allow(flags: FeatureFlags) {
    return flags['metrc-integration'];
  }
}

export class FlourishIntegrationFlagEnabledChecker {
  allow(flags: FeatureFlags) {
    return flags['flourish-integration'];
  }
}

export class NabisIntegrationFlagEnabledChecker {
  allow(flags: FeatureFlags) {
    return flags['nabis-integration'];
  }
}

export class GSuiteIntegrationFlagEnabledChecker {
  allow(flags: FeatureFlags) {
    return flags['gsuite-integration'];
  }
}

export class InventoryFlagEnabledChecker {
  allow(flags: FeatureFlags, session: UserSession) {
    const currentOrg = session.currentOrganization;
    const orgWithNabisAndLimitedAccess = !!currentOrg?.externalApiConfigs.find(config => config.configSourceName === MappingSourceName.NABIS)
    && currentOrg?.subscription.type === SubscriptionType.LITE;
    return !!flags['order-entry-inventory'] || orgWithNabisAndLimitedAccess;
  }
}

export class OrderEntryFlagEnabledChecker {
  allow(flags: FeatureFlags) {
    return !!flags['order-entry-inventory'];
  }
}

export class PlaceAnOrderEnabledChecker {
  allow(flags: FeatureFlags) {
    return ![FeatureLevel.STARTER, FeatureLevel.LITE].includes(flags['feature-level'] as FeatureLevel);
  }
}

export class PaginatedSalesActivityFlagEnabledChecker {
  allow(flags: FeatureFlags) {
    return !!flags['paginated-sales-activity-feature'];
  }
}

export function displayMetrcIntegration(flags: FeatureFlags) {
  const checker = new MetrcIntegrationFlagEnabledChecker();
  return checker.allow(flags);
}

export function displayFlourishIntegration(flags: FeatureFlags) {
  const checker = new FlourishIntegrationFlagEnabledChecker();
  return checker.allow(flags);
}

export function displayNabisIntegration(flags: FeatureFlags) {
  const checker = new NabisIntegrationFlagEnabledChecker();
  return checker.allow(flags);
}

export function displayGoogleIntegration(flags: FeatureFlags) {
  const checker = new GSuiteIntegrationFlagEnabledChecker();
  return checker.allow(flags);
}

export function  displayPlaceAnOrderAction(flags: FeatureFlags) {
  const checker = new PlaceAnOrderEnabledChecker();
  return checker.allow(flags);
}

export function disableForNabisUserWithRestrictedAccess(session: UserSession, flags: FeatureFlags) {
  const currentOrg = session.currentOrganization;
  return !!currentOrg?.externalApiConfigs.find(config => config.configSourceName === MappingSourceName.NABIS)
    && (flags['feature-level'] as FeatureLevel === FeatureLevel.LITE);
}

export function paginateSalesActivity(flags: FeatureFlags) {
  const checker = new PaginatedSalesActivityFlagEnabledChecker();
  return checker.allow(flags);
}
