import {SubscriptionType} from '../../../models/Subscription';

export interface SubscriptionPlan {
  type: SubscriptionType;
  disabledReason(): string;
}

export class OrganizationSubscriptionPlan implements SubscriptionPlan {
  constructor(public readonly type: SubscriptionType,
              public readonly price: number,
              public readonly platformFeatures: string,
              public readonly userLimit?: number,
              public readonly disclaimerText?: string,
  ) {
  }

  public disabledReason(): string {
    return '';
  }
}

class NoSubscriptionPlan implements SubscriptionPlan {
  public readonly type: SubscriptionType;
  constructor() {
    this.type = SubscriptionType.NONE;
  }

  public disabledReason(): string {
    return 'No plan selected';
  }
}

type SubscriptionPlanMap = {
  starter: OrganizationSubscriptionPlan,
  lite: OrganizationSubscriptionPlan,
  pro: OrganizationSubscriptionPlan,
  enterprise: OrganizationSubscriptionPlan,
  none: NoSubscriptionPlan
};
export const SUBSCRIPTION_PLANS: SubscriptionPlanMap = {
  starter: new OrganizationSubscriptionPlan(SubscriptionType.STARTER,100,  'crm + order tracking', 1),
  lite: new OrganizationSubscriptionPlan(SubscriptionType.LITE, 350,  'crm + order management + reporting', 4),
  pro: new OrganizationSubscriptionPlan(SubscriptionType.PRO, 600,  'crm + order management + custom reporting + integrations + dedicated support', 8),
  enterprise: new OrganizationSubscriptionPlan(SubscriptionType.ENTERPRISE,0, 'pro + custom Integrations'),
  none: new NoSubscriptionPlan(),
};
