import {faDollarSign, faEnvelope, faFileAlt, IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {Note} from './Note';
import {Activity, activityAssignedTo, ExternalEmailActivity} from './sales-activity/Activity';
import {DiscountType, OrderStatus} from './order-entry/Order';
import {OrderSummary} from './order-entry/Summary';
import {Money} from './Money';
import Contact from './order-entry/Contact';
import {License} from './OrganizationLicense';
import {DateFormatTemplate, OrdoDate} from './OrdoDate';
import {Location} from './Location';
import {iconsOptions} from '../pages/activity-manager/components/activityTemplatesManager/iconsOptions';
import {User} from './User';
import {OrderVersionLineItem} from './order-entry/OrderVersionLineItem';

export type TimelineOrder =  {
  orgId: string,
  id: string,
  orderNumber: string
  createdAt: Date
  isTracked: boolean
  effective: Date
  createdBy: string,
  orderNotes: string
  status: OrderStatus
  deliveryDay: Date
  discountType: DiscountType
  discountPercentage: number
  discountCurrencyAmount: Money
  summary: OrderSummary
  location: Location
  currentVersionItems: OrderVersionLineItem[]
  invoiceURL: string
  contact?: Contact
  distributor?: License
}


export type TimelineItemType = 'activity' | 'externalActivity' | 'note' | 'order';

export interface TimelineItem {
  date(): OrdoDate
  style(): string,
  icon(): IconDefinition
  id(): string
  info(): TimelineItemType;
  item(): ExternalEmailActivity | Activity | Note | TimelineOrder;
  title(): string;
  createdBy(): string;
  displayDate(): string;
  contacts(): Contact[];
  shouldBeDisplayed(eventsToDisplay: string[]): boolean;
}

export const ORDER_EVENT_TYPE = 'Orders';
export const NOTE_EVENT_TYPE = 'Notes';

export type AccountTimeline = {
  accountId: string,
  orderEntryEnabled: boolean,
  name: string,
  salesReps: User[],
  contacts: Contact[],
  items: TimelineItem[]
};

export class ActivityTimelineItem implements TimelineItem {
  constructor(private activity: Activity){};

  public date(): OrdoDate {
    return OrdoDate.from(this.activity.date);
  }

  public id() {
    return this.activity.id;
  }

  public style(): string {
    return `activity ${ this.activity.completed ? '' : 'incomplete'}`;
  }

  public icon(): IconDefinition {
    return iconsOptions[this.activity.activityTemplate.iconName];
  }

  public info(): TimelineItemType {
    return 'activity';
  }

  public item() {
    return this.activity;
  }

  public title() {
    return this.activity.activityTemplate.name;
  }

  public createdBy(): string {
    return activityAssignedTo(this.activity);
  }

  public displayDate(): string {
    const date = OrdoDate.from(this.activity.date).format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY);
    const time = this.activity.time ? `, ${OrdoDate.from(this.activity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : '';
    return `${date}${time}`;
  }

  public contacts(): Contact[] {
    return this.activity.contacts;
  }

  public shouldBeDisplayed(eventsToDisplay: string[]) {
    return (eventsToDisplay.length === 0) || eventsToDisplay.includes(this.activity.activityTemplate.name);
  }
}

export class ExternalEmailActivityTimelineItem implements TimelineItem {
  constructor(private externalActivity: ExternalEmailActivity){};

  public date(): OrdoDate {
    return OrdoDate.from(this.externalActivity.date);
  }

  public id() {
    return this.externalActivity.id;
  }

  public style(): string {
    return 'external-activity';
  }

  public icon(): IconDefinition {
    return faEnvelope;
  }

  public info(): TimelineItemType {
    return 'externalActivity';
  }

  public item() {
    return this.externalActivity;
  }

  public title() {
    return this.externalActivity.description!;
  }

  public createdBy(): string {
    return activityAssignedTo(this.externalActivity);
  }

  public displayDate(): string {
    const date = OrdoDate.from(this.externalActivity.date).format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY);
    const time = this.externalActivity.time ? `, ${OrdoDate.from(this.externalActivity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : '';
    return `${date}${time}`;
  }

  public contacts(): Contact[] {
    return [];
  }

  public shouldBeDisplayed(_eventsToDisplay: string[]) {
    return true;
  }
}

export class NoteTimelineItem implements TimelineItem {
  constructor(private note: Note){};

  public id() {
    return this.note.id;
  }

  public date(): OrdoDate {
    return OrdoDate.from(new Date(this.note.lastEdited));
  }

  public style(): string {
    return 'note';
  }

  public icon(): IconDefinition {
    return faFileAlt;
  }

  public info(): TimelineItemType {
    return 'note';
  }

  public item() {
    return this.note;
  }

  public title() {
    return this.note.title;
  }

  public createdBy(): string {
    return `${this.note.createdBy.firstName} ${this.note.createdBy.lastName}`;
  }

  public displayDate(): string {
    return this.note.lastEdited;
  }

  public contacts(): Contact[] {
    return this.note.contacts;
  }

  public shouldBeDisplayed(eventsToDisplay: string[]) {
    return (eventsToDisplay.length === 0) || eventsToDisplay.includes(NOTE_EVENT_TYPE);
  }
}

export class OrderTimelineItem implements TimelineItem {
  constructor(private order: TimelineOrder){};

  public date(): OrdoDate {
    return OrdoDate.from(this.order.createdAt);
  }

  public id() {
    return this.order.id;
  }

  public style(): string {
    return 'order';
  }

  public icon(): IconDefinition {
    return faDollarSign;
  }

  public info(): TimelineItemType {
    return 'order';
  }

  public item() {
    return this.order;
  }

  public title() {
    return `Order #${ this.order.orderNumber } ${ this.order.status}`;
  }

  public createdBy(): string {
    return this.order.createdBy;
  }

  public displayDate(): string {
    return OrdoDate.from(this.order.createdAt).format(DateFormatTemplate.MONTH_DAY_HOUR_IN_12);
  }

  public contacts(): Contact[] {
    return this.order.contact ? [this.order.contact] : [];
  }

  public shouldBeDisplayed(eventsToDisplay: string[]) {
    return (eventsToDisplay.length === 0) || eventsToDisplay.includes(ORDER_EVENT_TYPE);
  }
}
