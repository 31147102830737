import {EventEmitter} from 'events';
import {initialize, LDClient} from 'launchdarkly-js-client-sdk';
import {allFlagsWithDefault, FeatureFlags, FeatureFlagUser} from '.';
import {appConfig} from '../config';


export class LaunchDarklyFeatureFlagFetcher extends EventEmitter {
  private client: LDClient;
  private static instance: LaunchDarklyFeatureFlagFetcher;

  static getInstance(user: FeatureFlagUser) {
    if (!this.instance) {
      this.instance = new LaunchDarklyFeatureFlagFetcher(user);
    }
    return this.instance;
  }

  static async updateSession(user: FeatureFlagUser) {
    if (!this.instance) {
      this.instance = new LaunchDarklyFeatureFlagFetcher(user);
      return;
    }
    await this.instance.client.identify({
      key: user.id,
      custom: {
        currentOrganization: user.currentOrganizationId || ''
      }
    });
    this.instance.emit('flags-updated', this.instance.getAllFlags());
  }

  private constructor(user: FeatureFlagUser) {
    super();
    const clientID = appConfig.launchDarklyClientId;
    if (!clientID)
      throw new Error('must provide feature flag client id');

    this.client = initialize(clientID, {
      key: user.id,
      custom: {
        currentOrganization: user.currentOrganizationId || '',
      }}, {
      bootstrap: 'localStorage'
    });
    this.client.on('change', (_updates) => {
      this.emit('flags-updated', this.getAllFlags());
    });
  }

  /**
   * refreshFlagsWhenReady should be called in order to get live feature flags from the Launch Darkly API.
   *
   * @returns The up-to-date feature flags
   */
  async refreshFlagsWhenReady(): Promise<FeatureFlags>  {
    await this.client.waitUntilReady();
    return this.getAllFlags();
  }

  getAllFlags(): FeatureFlags {
    const flags = this.client.allFlags();
    return allFlagsWithDefault(flags as FeatureFlags);
  }
}