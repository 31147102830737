import {useState} from 'react';
import {OrdoPageTabButton} from '../pages/components/common/OrdoPageTabs';


export default function useTabs<T>(initialState: T, options: { value:T, name: string }[]): {currentTab: T, buttons: OrdoPageTabButton[] } {
  const [tabState, setTabState] = useState<T>(initialState);

  const buttons = options.map(tab => {
    return {
      text: tab.name,
      onClick: () => setTabState(tab.value),
      dataTestId: `${tab.name}-tab-button`
    };
  });

  return {currentTab: tabState, buttons: buttons};

}