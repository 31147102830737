import React, {FC, useContext, useState, useEffect} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import '../../scss/purpose.scss';
import UserRegistrationForm from './steps/UserRegistrationForm';
import {API} from '../../lib/api/ordoApi';
import UserRegistration from '../../models/userRegistration';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {UserSessionContext} from '../../context/UserSessionContext';
import {FormFields} from '../../errors/form_error';
import AcceptInvitationAndJoinViewModel from '../../application-models/AcceptInvitationAndJoinViewModel';
import GeneralErrorMessage from '../components/common/GeneralErrorMessage';

type AcceptInvitationPageProps = {api: API} & RouteComponentProps<{invitationTokenId: string}>

const AcceptInvitationAndJoinPage: FC<AcceptInvitationPageProps> = ({api, match}: AcceptInvitationPageProps) => {
  const history = useHistory();
  const {invitationTokenId} = match.params;
  const {email} = history.location.state;
  const [viewModel, setViewModel] = useState(
    AcceptInvitationAndJoinViewModel.emptyAcceptInvitationAndJoinViewModel(api, invitationTokenId, email)
  );
  const userSessionContextData = useContext(UserSessionContext);

  const checkPassword = () => {
    const newViewModel = viewModel.checkPasswords();
    setViewModel(newViewModel);
  };

  useEffect(() => {
    checkPassword();
  }, [viewModel.userRegistration.password, viewModel.userRegistration.passwordConfirmation]);

  const redirectIfValid: (acceptInvitationAndJoinViewModel: AcceptInvitationAndJoinViewModel) => void =
    (acceptInvitationAndJoinViewModel: AcceptInvitationAndJoinViewModel) => {
      if (!acceptInvitationAndJoinViewModel.hasError()) {
        userSessionContextData.update(acceptInvitationAndJoinViewModel.userSession);
        history.push(ROUTE_NAMES.PROFILE);
      }
    };

  const doUserRegistration: () => Promise<void> = async () => {
    const acceptInvitationAndJoinViewModel = await viewModel.joinUser();
    setViewModel(acceptInvitationAndJoinViewModel);
    redirectIfValid(acceptInvitationAndJoinViewModel);
  };


  const updateUserRegistration = (fieldsToUpdate: UserRegistration) => {
    const userRegistration = new UserRegistration({
      ...viewModel.userRegistration,
      ...fieldsToUpdate
    });
    const newViewModel = viewModel.updateUserRegistration(userRegistration);
    setViewModel(newViewModel);
  };


  const checkEmptyFieldError = (field: FormFields) => {
    if (viewModel.hasErrorFor(field)) {
      const newViewModel = viewModel.removeErrorFor(field);
      setViewModel(newViewModel);
    }
  };

  return (
    <div className="row justify-content-center" style={{margin: 0}}>
      <div className="col-sm-8 col-lg-5">
        <GeneralErrorMessage viewModel={viewModel}/>
        <div className="card shadow zindex-100 mb-0">
          <UserRegistrationForm
            joinOrdoViewModel={viewModel}
            updateUserRegistration={updateUserRegistration}
            checkEmptyFieldError={checkEmptyFieldError}
            onCallToAction={doUserRegistration}
            callToAction='Join Ordo'
            readOnlyEmail
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(AcceptInvitationAndJoinPage);
