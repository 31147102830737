import React, {useContext, useState} from 'react';
import {Modal} from 'react-bootstrap';
import OrdoSpinner from '../OrdoSpinner';
import ImagePreview from './ImagePreview';
import OrdoFileInput from '../common/inputs/OrdoFileInput';
import OrdoInput from '../common/inputs/OrdoInput';
import OrdoButton from '../common/OrdoButton';
import {Brand} from '../../../models/Brand';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import {OrdoImage} from '../../../models/OrdoImage';
import {OrdoFile} from '../../../models/OrdoFile';
import {useAPI} from '../../../context/OrdoApiContext';
import {UserSessionContext} from '../../../context/UserSessionContext';
import useOrdoToasts from '../../../hooks/useOrdoToasts';

type EditBrandModalProps = {
  isOpen: boolean,
  onClose: Function,
  brand: Brand,
  onBrandUpdate: Function
}

export const EditBrandModal = ({isOpen, onClose, brand, onBrandUpdate} : EditBrandModalProps) => {
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const [newBrandName, setNewBrandName] = useState<string>(brand.name);
  const [brandImage, setNewBrandImage] = useState<OrdoImage>({preview: brand.logoImageURL});
  const api = useAPI();
  const { currentOrganization } = useContext(UserSessionContext);
  const {errorToast, successToast} = useOrdoToasts();

  const handleImageSelected = (ordoFile: OrdoFile) => {
    setNewBrandImage({preview: ordoFile.preview, raw: ordoFile.raw});
    document.getElementById('brand-name')?.focus();
  };

  const editBrand = () => {
    showSpinner();
    api.editBrand(currentOrganization()!.id, brand.id, newBrandName, brand.logoImageURL, brandImage.raw!)
      .then((response: Brand) => {
        onBrandUpdate(response);
        onClose();
        successToast('Brand updated');
      })
      .catch(() => errorToast('Could not update brand'))
      .finally(hideSpinner);
  };

  const canUpload = () => !!newBrandName && !!brandImage;

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <Modal onHide={onClose} show={isOpen} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit a brand</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={editBrand}>
          <div className="form-group mb-4">
            <div className="input-group upload-logo">
              <ImagePreview url={brandImage.preview} alt="logo" size={150}/>
              <OrdoFileInput text="Upload Logo" onFileSelected={handleImageSelected}/>
            </div>
            <div className="form-group mt-3">
              <div className="input-group brand-name-input">
                <OrdoInput
                  inputName='brand-name'
                  value={newBrandName}
                  placeholder="name of the brand"
                  inputSize='medium'
                  onChange={(event) => setNewBrandName(event)}
                />
              </div>
            </div>
          </div>
          <div className="save-brand-button">
            <OrdoButton dataTestId="save-brand-button" disabled={!canUpload()} text="Save" category="primary" onClick={() => {}}/>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  </OrdoSpinner>;
};
