import React from 'react';
import {ProductLine} from '../../../../models/order-entry/Cart';

type ItemLineInfoProps = {
  item: ProductLine,
}

export const ItemLineInfo = ({item}: ItemLineInfoProps) => {
  return (
    <div className="line-item-info">
      <div className="name info">{item.item.product.name}</div>
      <span className="info">{item.item.product.brand.name}</span>
      <span className="info">{item.item.product.unitsPerCaseToStringMobile()}</span>
    </div>
  );
};
