import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import {faDotCircle} from '@fortawesome/free-solid-svg-icons/faDotCircle';
import {faCheckSquare} from '@fortawesome/free-solid-svg-icons/faCheckSquare';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {ActivityQuestionType} from '../../../../models/sales-activity/ActivityQuestion';
import {DynamicQuestionOption} from './DynamicQuestionOption';

const MAX_AMOUNT_OF_OPTIONS = 8;

export const DynamicQuestionTypePreview = ({questionType, options, onOptionsUpdate} : {questionType: ActivityQuestionType,
  options: string[],
  onOptionsUpdate: (options: string[]) => void}) => {

  const [currentOptions, setCurrentOptions] = useState<string[]>(options.length > 0 ? options : ['option 1']);

  useEffect(() => onOptionsUpdate(currentOptions), [currentOptions]);

  const addOption = () => {
    if(currentOptions.length < MAX_AMOUNT_OF_OPTIONS) {
      const updatedOptions = currentOptions.concat(`option ${currentOptions.length + 1}`);
      setCurrentOptions(updatedOptions);
    }
  };

  const updateOption = (index: number, newOption: string) => {
    const updatedOptions = [...currentOptions];
    updatedOptions[index] = newOption;
    setCurrentOptions(updatedOptions);
  };

  const deleteOption = (index: number) => {
    const updatedOptions = currentOptions.filter((_q,i) => index !== i);
    setCurrentOptions(updatedOptions);
  };

  return(
    <div className='response-preview hidden'>
      <div className='options-containers'>
        {
          currentOptions.map((option, index) => {
            return(
              <div className='option-container' key={option}>
                <FontAwesomeIcon className="option-icon" onClick={() => {}} size="1x" icon={questionType === ActivityQuestionType.MultipleChoice ? faDotCircle : faCheckSquare}/>
                <DynamicQuestionOption option={option} onOptionUpdate={updateOption} index={index} key={option}/>
                {currentOptions.length > 1 && <FontAwesomeIcon className='delete-option-icon' icon={faTimes} onClick={() => deleteOption(index)}/>}
              </div>
            );
          })
        }
        <div className={`add-option-button ${currentOptions.length === MAX_AMOUNT_OF_OPTIONS ? 'disabled' : ''}`} onClick={addOption} role='presentation'>
          <FontAwesomeIcon className="date-icon" size="1x" icon={faPlusCircle}/>
          <span>add another option</span>
        </div>
      </div>
    </div>
  );
};