import { Role } from './Roles';
import {User} from './User';

export class AccountRepAssignment {
  public readonly accountId: string;
  public readonly user: User;
  public readonly role: Role;
  public readonly createdAt: Date;

  constructor(accountId: string, user: User, role: Role, createdAt: Date) {
    this.accountId = accountId;
    this.user = user;
    this.role = role;
    this.createdAt = createdAt;
  }

  public getName() : string{
    return `${this.user.firstName} ${this.user.lastName}`;
  }
}
