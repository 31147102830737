import {API} from '../lib/api/ordoApi';
import UserRegistration from '../models/userRegistration';
import BusinessError from '../errors/domain_errors';
import {notLoggedInUserSession} from '../models/User';
import FormError, {FormFields} from '../errors/form_error';
import InvalidField from '../errors/invalid_field';
import {UserSession} from '../models/UserSession';

export type AcceptInvitationAndJoinViewModelInput = {
	invitationTokenId: string,
	userRegistration: UserRegistration,
	ordoAPI: API,
	error?: FormError,
	userSession: UserSession
}

export default class AcceptInvitationAndJoinViewModel {
	private invitationTokenId: string;
	public userRegistration: UserRegistration;
	private ordoAPI: API;
	private error: FormError;
	public userSession: UserSession;

	constructor(viewModelInput: AcceptInvitationAndJoinViewModelInput) {
	  this.invitationTokenId = viewModelInput.invitationTokenId;
	  this.userRegistration = viewModelInput.userRegistration;
	  this.ordoAPI = viewModelInput.ordoAPI;
	  this.error = viewModelInput.error || FormError.withoutError();
	  this.userSession = viewModelInput.userSession;
	}

	public static emptyAcceptInvitationAndJoinViewModel(ordoAPI: API, invitationTokenId: string, email: string) {
	  const userRegistration = UserRegistration.emptyUserRegistration();
	  userRegistration.email = email;
	  return new AcceptInvitationAndJoinViewModel({
		  invitationTokenId: invitationTokenId,
	    ordoAPI: ordoAPI,
	    userRegistration: userRegistration,
	    userSession: notLoggedInUserSession
	  });
	}

	public async joinUser(): Promise<AcceptInvitationAndJoinViewModel> {
	  if (!this.userRegistrationIsValid()) {
	    const userInvalidFields = this.userRegistration.invalidFields();
	    return this.updateErrors(userInvalidFields);
	  }
	  try {
	    const userSession = await this.ordoAPI.joinInvitedUser(this.userRegistration, this.invitationTokenId);
	    this.userSession = userSession;
	    return this;
	  } catch (error) {
	    if (error instanceof BusinessError) {
	      return new AcceptInvitationAndJoinViewModel({
		      invitationTokenId: this.invitationTokenId,
	        ordoAPI: this.ordoAPI,
	        userRegistration: this.userRegistration,
	        error: error.formError,
	        userSession: this.userSession
	      });
	    }
	    const unknownErrorForm = FormError.unknown(error.message);
	    return new AcceptInvitationAndJoinViewModel({
		    invitationTokenId: this.invitationTokenId,
	      ordoAPI: this.ordoAPI,
	      userRegistration: this.userRegistration,
	      error: unknownErrorForm,
	      userSession: this.userSession
	    });
	  }
	}

	public updateUserRegistration(userRegistration: UserRegistration) {
	  return new AcceptInvitationAndJoinViewModel({
		  invitationTokenId: this.invitationTokenId,
	    ordoAPI: this.ordoAPI,
	    userRegistration: userRegistration,
	    error: this.error,
	    userSession: this.userSession
	  });
	}

	public checkPasswords() {
	  if (!this.matchingPasswords()) {
	    this.error.addError(new InvalidField('passwordConfirmation', 'Passwords don\'t match'));
	  } else {
	    this.error.removeErrorFor('passwordConfirmation');
	  }
	  return new AcceptInvitationAndJoinViewModel({
		  invitationTokenId: this.invitationTokenId,
	    ordoAPI: this.ordoAPI,
	    userRegistration: this.userRegistration,
	    error: this.error,
	    userSession: this.userSession
	  });
	}


	public updateErrors(invalidFields: InvalidField[]) {
	  const updatedFormError = FormError.withErrors(invalidFields);
	  return new AcceptInvitationAndJoinViewModel({
		  invitationTokenId: this.invitationTokenId,
	    ordoAPI: this.ordoAPI,
	    userRegistration: this.userRegistration,
	    error: updatedFormError,
	    userSession: this.userSession
	  });
	}

	public matchingPasswords() {
	  return this.userRegistration.password === this.userRegistration.passwordConfirmation;
	}

	public hasError() {
	  return this.error.hasError();
	}

	public hasErrorFor(input: FormFields) {
	  return this.error.hasErrorForInput(input);
	}

	public removeErrorFor(input: FormFields) {
	  this.error.removeErrorFor(input);
	  return new AcceptInvitationAndJoinViewModel({
		  invitationTokenId: this.invitationTokenId,
	    ordoAPI: this.ordoAPI,
	    userRegistration: this.userRegistration,
	    error: this.error,
	    userSession: this.userSession
	  });
	}

	errorMessage(input: FormFields): string {
	  return this.error.errorMessage(input);
	}

	public userRegistrationIsValid() {
	  return this.userRegistration.isValid();
	}
};
