import React, {useState} from 'react';
import '../../../scss/ordo/integrations/nabis-integration.scss';
import nabisLogo from '../nabis.png';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import OrdoButton from '../../components/common/OrdoButton';
import SensitiveValueText from '../../components/common/SensitiveValueText';
import EditNabisIntegrationModal from './EditNabisIntegrationModal';

type NabisIntegrationConfig = {
  apiKey: string
}

export type NabisIntegration = {
  id: string,
  config: NabisIntegrationConfig,
  organizationId: string,
}

type NabisIntegrationRowProps = {
  integration: NabisIntegration,
  onDeleteIntegration: Function
  onUpdateIntegration: Function
};

const NabisIntegrationRow = ({integration, onDeleteIntegration, onUpdateIntegration}: NabisIntegrationRowProps) => {
  const [openedDeleteIntegrationModal, setOpenedIntegrationContactModal] = useState(false);
  const [openedEditIntegrationModal, setOpeneEditIntegrationContactModal] = useState(false);
  const openDeleteIntegrationModal = () => setOpenedIntegrationContactModal(true);
  const openEditIntegrationModal = () => setOpeneEditIntegrationContactModal(true);
  const closeDeleteIntegrationModal = () => setOpenedIntegrationContactModal(false);
  const closeEditIntegrationModal = () => setOpeneEditIntegrationContactModal(false);

  const integrationDelete = () => onDeleteIntegration(integration);
  const integrationUpdate = (configId: string, nabisAPIKey: string) => {
    onUpdateIntegration(configId, nabisAPIKey).then(() => closeEditIntegrationModal());
  };

  return(<div className="nabis-integration">
    <div className="nabis-logo-and-api-key">
      <div className="nabis-logo">
        <img role='presentation' className="nabis-icon" alt='nabis-icon' src={nabisLogo}/>
      </div>
      <div className="nabis-api-key-information">
        <span className="title">nabis api key</span>
        <SensitiveValueText value={integration.config.apiKey}/>
      </div>
    </div>
    <div className="nabis-action-buttons">
      <div>
        <OrdoButton disabled={false} text="Edit" category="primary" onClick={openEditIntegrationModal} dataTestId=""/>
        <OrdoButton disabled={false} text="Delete" category="secondary" onClick={openDeleteIntegrationModal} dataTestId=""/>
      </div>
    </div>
    {openedDeleteIntegrationModal &&
    <ConfirmationModal
      show={openedDeleteIntegrationModal}
      onClose={closeDeleteIntegrationModal}
      onSubmit={integrationDelete}
      confirmationText="are you sure you want to delete Nabis integration config?"
      actionText='delete'
    />}
    {openedEditIntegrationModal &&
    <EditNabisIntegrationModal
      isOpen={openedEditIntegrationModal}
      onSubmit={integrationUpdate}
      onClose={closeEditIntegrationModal}
      integration={integration}
    />}
  </div>);
};

export default NabisIntegrationRow;
