import React, {FC, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import '../../scss/ordo/organizationPage/organizations-page-section.scss';
import '../../scss/ordo/common.scss';
import {UserSessionContext} from '../../context/UserSessionContext';
import {Organization} from '../../models/Organization';
import CreateOrganizationModal from '../components/modals/CreateOrganizationModal';
import OrganizationCard from './OrganizationCard';
import {OrdoPayment} from '../../models/payments/OrdoPayments';
import {PendingInvitation} from '../../models/PendingInvitation';
import {PendingInvitationCard} from './PendingInvitationCard';

type OrganizationsPageProps = {
  organizations: Organization[],
  pendingInvitations: PendingInvitation[],
  onOrganizationSelected: Function,
  onOrganizationCreated: Function,
  onEditOrganizationCardInfo: Function,
}

const OrganizationsPageSection: FC<OrganizationsPageProps> = ({organizations, pendingInvitations, onOrganizationSelected, onOrganizationCreated, onEditOrganizationCardInfo}: OrganizationsPageProps) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = ()=>setShowModal(false);
  const openModal = ()=> {
    OrdoPayment.reset();
    setShowModal(true);
  };

  return <div className='organization-page-tab-container'>
    <div className='new-organization-button'>
      <div className='d-flex flex-row justify-content-start align-items-center button-container'
        onClick={openModal} role="presentation"
      >
        <FontAwesomeIcon
          data-testid='create-organization-button' className='mr-3'
          size='lg' icon={faPlusCircle}
        />
        <div className="new-organization-text">new organization</div>
      </div>
    </div>
    <div className='user-organization-cards-container'>
      {
        pendingInvitations.map(pendingInvitation => <PendingInvitationCard key={pendingInvitation.invitationTokenId} pendingInvitation={pendingInvitation} onInvitationAction={onOrganizationCreated} onOrganizationSelected={onOrganizationSelected}/>)
      }
      {
        organizations.length > 0 ?
          organizations.map((organization: Organization) =>
            <UserSessionContext.Consumer key={organization.id}>
              {value => <OrganizationCard
                currentOrganizationId={value.currentOrganization() ? value.currentOrganization()!.id : ''}
                organization={organization}
                onCardSelected={onOrganizationSelected}
                onEditOrganizationCardInfo={onEditOrganizationCardInfo}
              />}
            </UserSessionContext.Consumer>)
          :
          <p>No organizations yet</p>
      }
    </div>
    <CreateOrganizationModal isOpen={showModal} onClose={closeModal} onSubmit={onOrganizationCreated}/>
  </div>;
};

export default OrganizationsPageSection;
