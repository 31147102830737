import React from 'react';
import {
  accountDateGoingStale,
  AccountPriority,
  isAccountActivityGoingStale,
  SalesActivityAccount,
  SalesActivityOrder
} from './salesActivityTypes';
import {PipelineCardBottomSectionTitle, SalesActivityCard} from './SalesActivityCard';
import SalesActivityViewModel from '../../application-models/sales-activity/SalesActivityViewModel';

export type UpdateAccountPriorityClickHandler = (accountId: string, currentPriority: AccountPriority) => Promise<void>;
export enum PipelineButtonText {
  CONFIRM_ORDER = 'confirm order',
  EDIT_ORDER = 'edit order',
  UNCONFIRM_ORDER = 'unconfirm',
  TRACK_ORDER = 'log order',
  NO_ACTION = '',
  STAGE_ORDER = 'stage order',
  VERIFY_DELIVERY = 'verify delivery',
  LOG_PAYMENT = 'log payment',
  ADD_CONTACT = 'add contact',
  ADD_ACTIVITY = 'add activity',
  ADD_NOTE = 'add note',
}
export const renderAccountCardWithStatus = (buttonText: string,
  onCTA: (r: SalesActivityAccount)=>void,
  onCardClick: (r: SalesActivityAccount) => void = () => {},
  onPriorityButtonClick: UpdateAccountPriorityClickHandler,
  accountCardMinWidth: number,
) => (account: SalesActivityAccount) => {
  const accountIsGoingStale = isAccountActivityGoingStale(account);
  const bottomSectionTitle = <PipelineCardBottomSectionTitle
    defaultText='last order'
    redColoredText='going stale'
    redColored={accountIsGoingStale}
  />;
  return(
    <SalesActivityCard
      key={account.id}
      accountName={account.name}
      subtitle={account.amountOfLocations > 1 ? `${account.amountOfLocations} licenses` : `${account.address}`}
      bottomSectionTitle={bottomSectionTitle}
      stale={accountIsGoingStale}
      bottomSectionSubtitle={accountIsGoingStale ? accountDateGoingStale(account) : account.lastOrderDate}
      buttonText={buttonText}
      onCallToAction={()=> onCTA(account)}
      onCardClick={()=>onCardClick(account)}
      priority={account.priority}
      onPriorityButtonClick={() => onPriorityButtonClick(account.id, account.priority)}
      emMinWidth={accountCardMinWidth}
      activeAccount={account.activeAccount}
      orderEntryEnabled={account.orderEntryEnabled}
    />
  );
};

export const renderAccountCard = (buttonText: string,
  onCTA: (r: SalesActivityAccount)=>void,
  onCardClick: (r: SalesActivityAccount) => void = () => {},
  onPriorityButtonClick: UpdateAccountPriorityClickHandler,
  accountCardMinWidth: number,
) => (account: SalesActivityAccount) => {
  const redColored = false;
  const bottomSectionTitle = <PipelineCardBottomSectionTitle
    defaultText='last order'
    redColoredText='last order'
    redColored={redColored}
  />;
  return(
    <SalesActivityCard
      key={account.id}
      accountName={account.name}
      subtitle={account.amountOfLocations > 1 ? `${account.amountOfLocations} licenses` : `${account.address}`}
      bottomSectionTitle={bottomSectionTitle}
      stale={redColored}
      bottomSectionSubtitle={account.lastOrderDate}
      buttonText={buttonText}
      onCallToAction={()=> onCTA(account)}
      onCardClick={()=>onCardClick(account)}
      priority={account.priority}
      onPriorityButtonClick={() => onPriorityButtonClick(account.id, account.priority)}
      emMinWidth={accountCardMinWidth}
      activeAccount={account.activeAccount}
      orderEntryEnabled={account.orderEntryEnabled}
    />
  );
};

export const renderOrderCard = (
  salesActivityViewModel: SalesActivityViewModel,
  buttonText: string,
  onCardButtonClick: (retActOrder: SalesActivityOrder) => void,
  onCTA: (retActOrder: SalesActivityOrder) => void,
  onCardClick: (retActOrder: SalesActivityOrder, callToAction: any) => void,
  onPriorityButtonClick: UpdateAccountPriorityClickHandler,
  accountCardMinWidth: number,
) => (order: SalesActivityOrder) => {
  const redColored = salesActivityViewModel.shouldRedHighlightBottomSection(order);
  const bottomSectionTitle = <PipelineCardBottomSectionTitle
    defaultText={salesActivityViewModel.cardBottomSectionTitle(order)}
    redColoredText={salesActivityViewModel.cardBottomSectionTitleRedColorText(order)}
    redColored={redColored}
  />;
  return(
    <SalesActivityCard
      key={order.id}
      accountName={order.name}
      subtitle={`#${order.orderNumber} ● ${order.salesValue.formatted()}`}
      bottomSectionTitle={bottomSectionTitle}
      stale={redColored}
      bottomSectionSubtitle={salesActivityViewModel.cardBottomSectionSubtitle(order)}
      buttonText={buttonText}
      onCallToAction={() => onCardButtonClick(order)}
      onCardClick={() => onCardClick(order, () => onCTA(order))}
      priority={order.priority}
      onPriorityButtonClick={() => onPriorityButtonClick(order.accountId, order.priority)}
      emMinWidth={accountCardMinWidth}
      activeAccount={order.activeAccount}
      orderEntryEnabled
    />
  );
};
