import React, {useState} from 'react';
import Account from '../../../models/Account';
import '../../../scss/ordo/accountsPage/accountPage/account-page.scss';
import AddContactModal from '../../components/modals/AddContactModal';
import OrdoButton from '../../components/common/OrdoButton';
import {AccountContactRow} from './AccountContactRow';

type AccountContactsProps = {
  account: Account,
  onUpdate: Function,
}

export const AccountContacts = ({account, onUpdate} : AccountContactsProps) => {
  const [addContactModalOpened, setAddContactModalOpened] = useState(false);

  const openAddContactModal = () => setAddContactModalOpened(true);
  const closeAddContactModal = () => setAddContactModalOpened(false);
  // TODO: css class inventory-list-card should be rename since we are using it in lots of places
  return (<div className='account-contacts card card-fluid inventory-list-card ordo-shadow ordo-card-border'>
    <div className='account-contacts__header'>
      <span>contacts</span>
      <OrdoButton text='add contact'
        onClick={openAddContactModal}
        category='primary'
        dataTestId='add-product-button'
        disabled={false}
        squared
        style={{padding: '0.2em 1em', height: '2em'}}
      />
    </div>
    <div className='account-contacts__list'> {account.contacts.map(contact =>
      <AccountContactRow
        orgId={account.organizationId}
        onSubmit={() => onUpdate()}
        key={contact.id}
        contact={contact}
      />
    )}</div>
    <AddContactModal
      isOpen={addContactModalOpened}
      onClose={closeAddContactModal}
      onSubmit={() => onUpdate()}
      orgId={account.organizationId}
      accountId={account.id}
    />
  </div>);
};
