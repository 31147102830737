import React from 'react';
import {Tier} from '../../../models/custom-pricing-tiers/Tier';
import OrdoButton from '../common/OrdoButton';
import {CustomPricingTierRow} from './CustomPricingTierRow';

type CustomTierListProps = {
  createTier: () => void,
  tiers: Tier[],
  editTier: (tier: Tier) => void ,
  deleteTier: (tier: Tier) => void ,
};

export function CustomTierList({createTier, tiers, editTier, deleteTier}: CustomTierListProps) {
  return <div className='custom-tier-list'>
    <div className="pricing-tiers-header">
      <OrdoButton disabled={false} text="+ add new pricing tier" category="primary" onClick={createTier}
        dataTestId="add-pricing-tier-button"/>
    </div>
    <div className="pricing-tiers-content">
      <div className="pricing-tiers-content__header">
        <span className="pricing-tier">pricing tier</span>
        <span className="accounts">accounts</span>
        <span className="options"/>
      </div>
      <div className="pricing-tiers-content__body">
        {
          tiers.map(tier => {
            return <CustomPricingTierRow key={tier.id} tier={tier} onEdit={() => editTier(tier)} onDelete={() => deleteTier(tier)}/>;
          })
        }
      </div>
    </div>
  </div>;
}