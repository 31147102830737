import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDollyFlatbed} from '@fortawesome/free-solid-svg-icons';
import '../../../../scss/ordo/mobileOrderEntry/mobile-order-entry-cart.scss';
import OrdoInformativeSearchableDropdown
  from '../../../components/common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {AccountLocation} from '../../../../models/Account';
import MobileDisplayAsModal from '../../../components/common/MobileDisplayAsModal';
import {ProductWithAvailability} from '../../../../models/productWithAvailability';
import {CartItemType} from '../../../../models/order-entry/CartItem';
import {MobileOrderEntryCart} from './MobileOrderEntryCart';
import {MobileOrderEntryDistributorSelection} from './MobileOrderEntryDistributorSelection';

type MobileOrderEntryCartSectionProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateAccountLocation: (accountLocation: AccountLocation) => void,
  updateViewModel: (viewModel: OrderEntryViewModel) => void,
  updateCart: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void,
  landscapeMode?: boolean;
}

export const MobileOrderEntryLocationAndCartSection = ({orderEntryViewModel, updateAccountLocation, updateViewModel, updateCart, landscapeMode = false}: MobileOrderEntryCartSectionProps) => {
  const [displayCartView, setDisplayCartView] = useState(false);

  return (
    <div className={`mobile-order-entry-header__selector ${landscapeMode ? 'landscape' : ''}`}>
      <OrdoInformativeSearchableDropdown
        dataTestId='select-location'
        selectedOption={orderEntryViewModel.getSelectedAccountLocation()}
        options={orderEntryViewModel.getSelectableAccountLocations()}
        onChangeSelectedOption={updateAccountLocation}
        placeholder='select a location'
        addedStyles={{
          marginBottom: '0', valueContainer: {padding: '0'},
          control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
        }}
      />
      {landscapeMode && <MobileOrderEntryDistributorSelection
        updateViewModel={updateViewModel}
        orderEntryViewModel={orderEntryViewModel}
      />}
      <div className='cart-with-items' role="presentation" onClick={() => setDisplayCartView(true)}>
        <div className='cart-with-items__number'>{orderEntryViewModel.cart.numberOfProductsInCart()}</div>
        <FontAwesomeIcon
          size="2x"
          className="cart-with-items__icon"
          icon={faDollyFlatbed}
        />
      </div>
      {displayCartView && <MobileDisplayAsModal
        show={displayCartView}
        onClose={() => {}}
        dialogClassName="view-mobile-as-modal"
      >
        <MobileOrderEntryCart
          orderEntryViewModel={orderEntryViewModel}
          onClose={() => {
            setDisplayCartView(false);
          }}
          updateCart={updateCart}
        />
      </MobileDisplayAsModal>}
    </div>);
};
