import React from 'react';
import OrdoButton from '../common/OrdoButton';
import {OrderEntryViewModel} from '../../../application-models/order-entry/OrderEntryViewModel';
import ImagePreview from '../brands/ImagePreview';
import {AddToCart} from './AddToCart';
import {ProductWithAvailability} from '../../../models/productWithAvailability';
import {CartItem, CartItemType} from '../../../models/order-entry/CartItem';
import {ProductSize} from '../product/ProductSize';

type OrderEntryCartProps = {
  orderEntryViewModel: OrderEntryViewModel;
  updateCart: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void;
  goToVerifyOrder: () => Promise<void>;
}

type OrderEntryCartLineProps = {
  cartItem: CartItem;
  orderEntryViewModel: OrderEntryViewModel;
  updateCart: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void;
}
export function cartLineDataTestId(productId:string) {
  return `cart-line-${productId}`;
}

function CartLine({cartItem, orderEntryViewModel, updateCart}: OrderEntryCartLineProps) {
  const amountOfCases =  orderEntryViewModel.amountOfProductFor(cartItem.product.id, CartItemType.CASE);
  const amountOfUnits = orderEntryViewModel.amountOfProductFor(cartItem.product.id, CartItemType.UNIT);

  const updateOrder = (newAmount: number) => {
    updateCart(cartItem.product, newAmount, cartItem.type);
  };

  const removeOrder = () => {
    updateCart(cartItem.product, 0, cartItem.type);
  };

  const maxAmountForItem = () => {
    if(cartItem.type === CartItemType.UNIT) {
      return cartItem.product.availability - amountOfCases * cartItem.product.unitsPerCase;
    } return (cartItem.product.availability - amountOfUnits)/cartItem.product.unitsPerCase;
  };

  return (
    <div className='item-line d-flex justify-content-around align-items-center' data-testid={cartLineDataTestId(cartItem.product.id)}>
      <div className="mr-2">
        <ImagePreview url={cartItem.product.imageUrl} alt={cartItem.product.name} size={50}/>
      </div>
      <div className='item-line__info'>
        <div className="product-title">{cartItem.product.name}</div>
        <ProductSize product={cartItem.product} isUnit={cartItem.type === CartItemType.UNIT}/>
        <div className='unit-text'>
          {cartItem.type === CartItemType.UNIT ? 'unit' : ''}
        </div>
      </div>
      <div>
        <AddToCart
          dataTestIdSuffix='cart-line-add-to-cart-button'
          productId={cartItem.product.id} productType={cartItem.type} page='cart'
          productIsDeleted={!!cartItem.product.deletedAt}
          style={{marginLeft: '.5em'}}
          buttonText={cartItem.type === CartItemType.UNIT ? 'add unit' : 'add case'}
          inputLabel={cartItem.type === CartItemType.UNIT ? 'unit' : 'case'}
          updateOrder={updateOrder}
          removeOrder={removeOrder}
          emptyOrders={(cartItem.type === CartItemType.CASE ? amountOfCases : amountOfUnits) === 0}
          ordersAmount={cartItem.type === CartItemType.CASE ? amountOfCases : amountOfUnits}
          maxAmount={maxAmountForItem()}
        />
      </div>
    </div>);
}

const OrderEntryCart = ({orderEntryViewModel, updateCart, goToVerifyOrder}: OrderEntryCartProps) => {
  const {cart} = orderEntryViewModel;
  return (
    <div className='card card-fluid ordo-shadow ordo-card-border order-entry-cart-container'>
      <div className="order-entry-cart-container__header">
        <span className="order-entry-cart-container__header__total">{cart.calculatedSubtotal().formatted()}</span>
        <span className="order-entry-cart-container__header__sub-total">subtotal ({cart.amountOfItems()} items)</span>
      </div>
      <div style={{width: '100%'}}>
        <OrdoButton
          disabled={!orderEntryViewModel.canCompleteOrder()}
          disabledText={orderEntryViewModel.completeOrderInvalidText()}
          text='review prices & discounts'
          category='primary'
          onClick={() => goToVerifyOrder()}
          dataTestId='complete-order-button'
          additionalClassName='complete-order-button'
        />
      </div>
      <div className='order-entry-cart-container__content'>
        {
          cart.content().filter(item => item.quantity > 0).map(cartItem => {
            return (
              <CartLine
                cartItem={cartItem}
                orderEntryViewModel={orderEntryViewModel}
                updateCart={updateCart}
                key={cartItem.product.id + cartItem.type}
              />
            );
          })
        }
      </div>
    </div>
  );
};

export default OrderEntryCart;
