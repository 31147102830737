import React from 'react';
import OrdoModal from '../common/OrdoModal';
import Product from '../../../models/Product';
import '../../../scss/ordo/inventoryPage/delete-product-modal.scss';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import ProductHeader from '../product/ProductHeaderPreview';


type ProductDeleteModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: Function,
  product: Product
}

const ProductDeleteModal = ({isOpen, onClose, product, onSubmit}: ProductDeleteModalProps) => {
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();

  const deleteProduct = async () => {
    showSpinner();
    onSubmit().finally(() => {
      hideSpinner();
      onClose();
    });
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoModal show={isOpen} onClose={onClose}
        onSubmit={deleteProduct}
        actionText='discontinue'
        disableCTA={spinnerVisibility}
        title='discontinue product'
      >
        <ProductHeader product={product}/>
        <div className='confirmation-message d-flex mt-4 font-weight-600'>
        Are you sure you want to remove this product? <br/>
        </div>
        <div className='confirmation-message d-flex mt-4'>
        All inventory will zero out, product data will be archived for on-going and past orders
        </div>
      </OrdoModal>
    </OrdoSpinner>
  );
};

export default ProductDeleteModal;
