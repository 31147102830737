import React from 'react';
import Product from '../../../models/Product';
import '../../../scss/ordo/product-header-preview.scss';
import {ProductSize} from './ProductSize';

export const ProductPreview = ({product}: { product: Product }) => {
  const categoryAndGenetics = product.categoryAndGenetics();
  return <div className="product-preview__item-image-and-name d-flex">
    <img src={product.imageUrl} alt={product.name} className="product-image h6 mb-0 text-sm"/>
    <div className="product-preview__item-image-and-name__name">
      <span className="product-name">{product.name}</span>
      <span className="product-category-size" title={categoryAndGenetics}>{categoryAndGenetics}</span>
    </div>
  </div>;
};

export const ProductPrice = ({product}: { product: Product }) => {
  return <div className="product-price">
    <span>${product.price}</span>
  </div>;
};

const ProductPreviewForAllocations = ({product}: { product: Product }) => {
  const categoryName = product.categoryToString();
  const genetics = product.geneticsToString();
  return <div className="item-image-and-info d-flex">
    <img src={product.imageUrl} alt={product.name} className="product-image h6 mb-0 text-sm"/>
    <div className="item-image-and-info__info">
      <span className="product-name">{product.name}</span>
      <span className="product-info" title={`${categoryName}, ${genetics}`}>{categoryName}, {genetics}</span>
      <span className="product-info">{product.unitsPerCaseToString()}</span>
      <span className="product-info">per case</span>
    </div>
  </div>;
};

export const ProductHeaderForAllocations = ({product, totalStock}: { product: Product, totalStock: number }) => {
  return <>
    <div className="product-header-for-allocations d-flex justify-content-between">
      <ProductPreviewForAllocations product={product}/>
      <div className="allocations-modal__total-stock">
        <span className="allocations-modal__total-stock__title">available units</span>
        <span className="allocations-modal__total-stock__value" data-testid="total-stock">{totalStock}</span>
      </div>
    </div>
  </>;
};
const ProductHeader = ({product}: { product: Product }) => {
  return <>
    <div className="product-header">
      <ProductPreview product={product}/>
      <ProductSize product={product} isUnit={product.unitSaleOnly}/>
      <ProductPrice product={product}/>
    </div>
  </>;
};
export default ProductHeader;
