import FormError from '../errors/form_error';
import {ProductFormInput, ProductFormViewModel} from './ProductFormViewModel';
import {API} from '../lib/api/ordoApi';
import {Brand} from '../models/Brand';
import ProductCategory from '../models/ProductCategory';

export default class AddProductViewModel extends ProductFormViewModel {

  public static initialize(api: API, brand: Brand, category: ProductCategory) {
    return new AddProductViewModel(this.newProductInput(brand, category), FormError.withoutError(), api, false);
  }

  private static newProductInput(brand: Brand, category: ProductCategory) {
    return {
      name: '',
      brand: brand,
      category: category,
      image: null,
      price: 0,
      unitsPerCase: 0,
      unitSaleOnly: false,
      caseSaleOnly: false,
      customerSKU: '',
    };
  }

  public new(productInfo: ProductFormInput, errors: FormError, api: API, inProgress: boolean) {
    return new AddProductViewModel(productInfo, errors, api, inProgress);
  }

  public async action(orgId: string): Promise<void> {
    await this.api.createProduct(orgId, this.productInformation, this.productImageData()!);
  }

  public actionName() {
    return 'create';
  }

  public hasImage(): boolean {
    return !!this.productInformation.image;
  }

  public get imagePreview(): string {
    return this.productInformation.image?.preview || '';
  }

  public isUnitSaleOnly() : boolean {
    return this.productInformation.unitSaleOnly;
  }

  private productImageData() {
    return this.productInformation.image?.raw || undefined;
  }
}
