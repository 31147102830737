import {RouteComponentProps, withRouter} from 'react-router-dom';
import React, {useContext, useEffect, useState, FC} from 'react';
import {UserSessionContext} from '../../../context/UserSessionContext';
import Account, {AccountLocation} from '../../../models/Account';
import {useAPI} from '../../../context/OrdoApiContext';
import {AccountHeader} from './AccountHeader';
import OrdoSpinner from '../../components/OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {AccountPageInfo} from './AccountPageInfo';
import {AccountContacts} from './AccountContacts';
import {AccountLocations} from './AccountLocations';
import {AccountActivity} from './activity/AccountActivity';
import { AccountOwedPayments } from './AccountOwedPayments';
import {PendingPaymentOrder} from '../../../models/order-payment/PendingPaymentOrder';
import {AccountActivityEntity} from '../../../models/AccountActivity';
import {ActivityTemplate, ActivityTemplateWithCount} from '../../../models/sales-activity/Activity';
import {User} from '../../../models/User';
import {AccountRepAssignment} from '../../../models/AccountRepAssignment';

type AccountPageProps = RouteComponentProps<{accountId: string}>

export const AccountPage : FC<AccountPageProps> = ({match}: AccountPageProps) => {
  const [account, setAccount] = useState<Account | undefined>(undefined);
  const [availableLocations, setAvailableLocations] = useState<AccountLocation[]>([]);
  const [owedPayments, setOwedPayments] = useState<PendingPaymentOrder[]>([]);
  const [accountActivity, setAccountActivity] = useState<AccountActivityEntity[]>([]);
  const [activityTemplates, setActivityTemplates] = useState<ActivityTemplate[]>([]);
  const [salesReps, setSalesReps] = useState<User[]>([]);
  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {errorToast} = useOrdoToasts();

  const fetchAccount = () => {
    showSpinner();
    api.getAccount(userSession.currentOrganization()?.id || '', match.params.accountId)
      .then((accountResponse: Account) => {
        setAccount(accountResponse);
      })
      .catch(() => errorToast('Could not retrieve Account'))
      .finally(() => hideSpinner());
  };

  const fetchAccountLocations = () => {
    if (account) {
      api.getAccountsLocationsForUser(account.organizationId, true)
        .then((accountLocations: AccountLocation[]) => {
          setAvailableLocations(accountLocations);
        });
    }
  };

  const fetchOwedOrders = () => {
    if (account) {
      api.getAccountOwedOrders(account.organizationId, account.id)
        .then((payments: PendingPaymentOrder[]) => {
          setOwedPayments(payments);
        });
    }
  };

  const fetchAccountActivity = () => {
    if (account) {
      api.getAccountActivity(account.organizationId, account.id)
        .then((accountActivityResponse: AccountActivityEntity[]) => setAccountActivity(accountActivityResponse));
    }
  };

  const fetchActivityTemplates = () => {
    if (account) {
      api.getActivitiesTemplatesForOrg(account.organizationId)
        .then((templatesResponse: ActivityTemplateWithCount[]) => setActivityTemplates(templatesResponse.map(templateResponse => templateResponse.activityTemplate)));
    }
  };

  const fetchSalesReps = () => {
    if (account) {
      api.getAccountAssignments(account.organizationId, account.id)
        .then((accountAssignmentsResponse: AccountRepAssignment[]) => setSalesReps(accountAssignmentsResponse.map(accountAssignment => accountAssignment.user)));
    }
  };

  const onAddressUpdated = (updatedLocation: AccountLocation) => {
    if(account) {
      const location = account.locations.find(al => al.locationId === updatedLocation.locationId)!;
      location.streetAddressLine1 = updatedLocation.streetAddressLine1;
      location.streetAddressLine2 = updatedLocation.streetAddressLine2;
      location.state = updatedLocation.state;
      location.zipCode = updatedLocation.zipCode;
      location.city = updatedLocation.city;
      location.county = updatedLocation.county;
      setAccount(new Account({
        id: account.id,
        name: account.name,
        organizationId: account.organizationId,
        locations: account.locations,
        allocations: account.allocations,
        assignments: account.assignments,
        contacts: account.contacts,
        notes: account.notes,
        activities: account.activities,
        stalePeriod: account.stalePeriod,
        priority: account.priority,
        value: account.value,
        conversion: account.conversion,
        active: account.active,
        orderEntryEnabled: account.orderEntryEnabled,
        status: account.status,
        lastOrderDate: account.lastOrderDate,
      }));
    }
  };


  useEffect(() => {
    fetchAccount();
  }, []);

  useEffect(() => {
    fetchAccountLocations();
    fetchOwedOrders();
    fetchAccountActivity();
    fetchActivityTemplates();
    fetchSalesReps();
  }, [account]);

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      {account && <div className="page-content-body">
        <AccountHeader account={account} onAccountUpdate={(updatedAccount: Account) => setAccount(updatedAccount)}/>
        <div className="account-columns">
          <div className="account-info-contacts-and-locations">
            <AccountPageInfo account={account}/>
            <AccountContacts account={account} onUpdate={fetchAccount}/>
            <AccountOwedPayments organizationId={account.organizationId} owedPayments={owedPayments} fetchOwedOrders={fetchOwedOrders}/>
            <AccountLocations account={account} availableLocations={availableLocations} onLinkingAccounts={(updatedAccount: Account) => setAccount(updatedAccount)} onAddressUpdated={onAddressUpdated}/>
          </div>
          <div className="account-activity-section">
            <AccountActivity account={account} accountActivity={accountActivity} templates={activityTemplates} salesReps={salesReps} setAccountActivity={setAccountActivity}/>
          </div>
        </div>
      </div>}
    </OrdoSpinner>
  );
};

export default withRouter(AccountPage);
