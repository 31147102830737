import React, {useState} from 'react';
import OrdoModal from '../../components/common/OrdoModal';
import OrdoInput from '../../components/common/inputs/OrdoInput';

type AddNabisIntegrationModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: (nabisAPIKey: string) => any,
}

const AddNabisIntegrationModal = ({isOpen, onClose, onSubmit} : AddNabisIntegrationModalProps) => {
  const [nabisAPIKey, setNabisAPIKey] = useState('');

  return (
    <OrdoModal show={isOpen}
      onClose={onClose}
      title="setup nabis integration"
      actionText='save'
      disableCTA={!nabisAPIKey}
      onSubmit={() => onSubmit(nabisAPIKey)}>
      <div>
        <OrdoInput inputName="key" value={nabisAPIKey} placeholder="nabis api key"
          label="API Key" inputSize="large" onChange={setNabisAPIKey}/>
      </div>
    </OrdoModal>
  );
};

export default AddNabisIntegrationModal;
