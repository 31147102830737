import Fuse from 'fuse.js';
import {isEmpty} from 'lodash';
import {API, OrderFilter} from '../../lib/api/ordoApi';
import {
  orderActivityStatusWarningInfo,
  orderActivityStatusInfo,
  orderActivityIsGoingStale,
  SalesActivityOrder,
  SalesActivityAccount,
  AccountPriority,
  AccountStatus,
  orderActivityDateInfo, AccountStatusAux,
} from '../../pages/sales-activity/salesActivityTypes';
import {OrderStatus} from '../../models/order-entry/Order';
import {Money} from '../../models/Money';
import Contact from '../../models/order-entry/Contact';
import {Activity, ExternalEmailActivity} from '../../models/sales-activity/Activity';
import { Note } from '../../models/Note';
import {OrdoDate} from '../../models/OrdoDate';
import {RepAssignmentIds} from '../../models/Assignment';
import Account, {AccountLocation} from '../../models/Account';
import {FeatureFlags, paginateSalesActivity} from '../../lib/featureFlags';
import {fullName} from '../../models/User';
import {Organization} from '../../models/Organization';

export type SelectablePipelineFilter = {
  label: string,
  value: string,
}

export type SalesActivityViewModelInput = {
  nonQualifiedAccounts: SalesActivityAccount[];
  verifiedPurchasers: SalesActivityAccount[];
  stalePurchasers: SalesActivityAccount[];
  lostPurchasers: SalesActivityAccount[];
  prospectAccounts: SalesActivityAccount[];
  pendingOrdersCards?:  SalesActivityOrder[];
  awaitingDeliveryCards?: SalesActivityOrder[];
  collectPaymentCards: SalesActivityOrder[];
  searchByAccountNameInput: string;
  selectedSalesReps: SelectablePipelineFilter[];
  allAccounts: Account[];
  allAssignments: RepAssignmentIds[];
  sortingCriteria: SortingCriteria,
  currentColumn: number
}

export type AccountDetailsInfo = {
  contacts: Contact[],
  notes: Note[],
  activities: (Activity | ExternalEmailActivity)[],
}

export enum SortingCriteria {
  ASCENDING,
  DESCENDING,
  NO_SORTING
}

export default class SalesActivityViewModel {
  private readonly nonQualifiedAccounts: SalesActivityAccount[];
  private readonly verifiedPurchasers: SalesActivityAccount[];
  private readonly stalePurchasers: SalesActivityAccount[];
  private readonly lostPurchasers: SalesActivityAccount[];
  private readonly prospectAccounts: SalesActivityAccount[];
  public pendingOrdersCards?:  SalesActivityOrder[];
  public readonly awaitingDeliveryCards?: SalesActivityOrder[];
  private readonly collectPaymentCards: SalesActivityOrder[];
  private readonly allAccounts: Account[];
  private readonly allAssignments: RepAssignmentIds[];
  public searchByAccountNameInput: string
  public selectedSalesReps: SelectablePipelineFilter[];
  public api: API;
  public sortingCriteria: SortingCriteria;
  public currentMobileColumn: number;
  private static ORDERS_PER_PAGE = 10_000;
  private static ACCOUNTS_PER_PAGE = 10_000;
  private static FIRST_PAGE = 1;
  private featureFlags: FeatureFlags;
  private organization: Organization;

  constructor(organization: Organization, api: API, salesActivityViewModelInput: SalesActivityViewModelInput, featureFlags: FeatureFlags) {
    this.nonQualifiedAccounts = salesActivityViewModelInput.nonQualifiedAccounts;
    this.verifiedPurchasers = salesActivityViewModelInput.verifiedPurchasers;
    this.stalePurchasers = salesActivityViewModelInput.stalePurchasers;
    this.lostPurchasers = salesActivityViewModelInput.lostPurchasers;
    this.prospectAccounts = salesActivityViewModelInput.prospectAccounts;
    this.pendingOrdersCards = salesActivityViewModelInput.pendingOrdersCards;
    this.awaitingDeliveryCards = salesActivityViewModelInput.awaitingDeliveryCards;
    this.collectPaymentCards = salesActivityViewModelInput.collectPaymentCards;
    this.searchByAccountNameInput = salesActivityViewModelInput.searchByAccountNameInput;
    this.selectedSalesReps = salesActivityViewModelInput.selectedSalesReps;
    this.allAccounts = salesActivityViewModelInput.allAccounts;
    this.allAssignments = salesActivityViewModelInput.allAssignments;
    this.api = api;
    this.sortingCriteria = salesActivityViewModelInput.sortingCriteria;
    this.currentMobileColumn = salesActivityViewModelInput.currentColumn;
    this.featureFlags = featureFlags;
    this.organization = organization;
  }

  public async initialize(api: API, orgId: string, sortingCriteria: SortingCriteria) {
    if (paginateSalesActivity(this.featureFlags)) {
      const assignments = await api.getAssignmentsForSalesActivity(orgId);
      const viewModelInput = {
        nonQualifiedAccounts: this.nonQualifiedAccounts,
        verifiedPurchasers: this.verifiedPurchasers,
        stalePurchasers: this.stalePurchasers,
        lostPurchasers: this.lostPurchasers,
        prospectAccounts: this.prospectAccounts,
        pendingOrdersCards: this.pendingOrdersCards,
        awaitingDeliveryCards: this.awaitingDeliveryCards,
        collectPaymentCards: this.collectPaymentCards,
        searchByAccountNameInput: this.searchByAccountNameInput,
        selectedSalesReps: this.selectedSalesReps,
        allAccounts: this.allAccounts,
        allAssignments: assignments,
        sortingCriteria: sortingCriteria,
        currentColumn: this.currentMobileColumn
      };
      return new SalesActivityViewModel(this.organization, api, viewModelInput, this.featureFlags);
    }

    const [pipelineData] = await Promise.all([api.getSalesActivity(orgId)]);

    const viewModelInput = {
      nonQualifiedAccounts: pipelineData.nonQualifiedAccounts,
      verifiedPurchasers: pipelineData.verifiedPurchasers,
      stalePurchasers: pipelineData.stalePurchasers,
      lostPurchasers: pipelineData.lostPurchasers,
      prospectAccounts: pipelineData.prospectAccounts,
      pendingOrdersCards: pipelineData.pendingOrdersCards,
      awaitingDeliveryCards: pipelineData.awaitingDeliveryCards,
      collectPaymentCards: pipelineData.collectPaymentCards,
      searchByAccountNameInput: this.searchByAccountNameInput,
      selectedSalesReps: this.selectedSalesReps,
      allAccounts: pipelineData.accounts,
      allAssignments: pipelineData.assignments,
      sortingCriteria: sortingCriteria,
      currentColumn: this.currentMobileColumn
    };

    return new SalesActivityViewModel(this.organization, api, viewModelInput, this.featureFlags);
  }

  public static emptySalesActivityViewModel(organization: Organization, api: API, featureFlags: FeatureFlags) {
    const viewModelInput = {
      nonQualifiedAccounts: [],
      verifiedPurchasers: [],
      stalePurchasers: [],
      lostPurchasers: [],
      prospectAccounts: [],
      pendingOrdersCards: [],
      awaitingDeliveryCards: [],
      collectPaymentCards: [],
      searchByAccountNameInput: '',
      selectedSalesReps: [],
      allAccounts: [],
      allAssignments: [],
      sortingCriteria: SortingCriteria.DESCENDING,
      currentColumn: 0,
    };

    return new SalesActivityViewModel(organization, api, viewModelInput, featureFlags);
  }

  public getProspectAccounts(prospectAccounts: SalesActivityAccount[]): SalesActivityAccount[] {
    return this.getAccounts(prospectAccounts, this.prospectAccounts);
  }

  public  getNonQualifiedAccounts(nonQualifiedAccounts: SalesActivityAccount[]): SalesActivityAccount[] {
    return this.getAccounts(nonQualifiedAccounts, this.nonQualifiedAccounts);
  }

  public getVerifiedPurchasers(verifiedAccounts: SalesActivityAccount[]): SalesActivityAccount[] {
    return this.getAccounts(verifiedAccounts, this.verifiedPurchasers);
  }

  public getStalePurchasers(staleAccounts: SalesActivityAccount[]): SalesActivityAccount[] {
    return this.getAccounts(staleAccounts, this.stalePurchasers);
  }

  public getLostPurchasers(lostAccounts: SalesActivityAccount[]): SalesActivityAccount[] {
    return this.getAccounts(lostAccounts, this.lostPurchasers);
  }

  private getAccounts(paginatedAccounts: SalesActivityAccount[], localAccounts: SalesActivityAccount[]) {
    const accounts = paginateSalesActivity(this.featureFlags) ? paginatedAccounts : localAccounts;
    const filteredAccounts = this.filterAccounts(accounts);
    return this.sortAccounts(filteredAccounts);
  }

  private sortAccounts(accountsList: SalesActivityAccount[]): SalesActivityAccount[] {
    const withOrders:SalesActivityAccount[] = [];
    const withNoOrders: SalesActivityAccount[] = [];
    accountsList.forEach(account => {
      if(account.lastOrderDate !== 'no orders') {
        withOrders.push((account));
      } else {
        withNoOrders.push(account);
      }
    });

    const sortingCriteria = (account1: SalesActivityAccount, account2: SalesActivityAccount) => {
      return this.sortByCriteria(OrdoDate.convertFromString(account1.lastOrderDate), OrdoDate.convertFromString(account2.lastOrderDate));
    };

    const sortedWithOrders = withOrders.sort((sortingCriteria));
    return this.sortingCriteria === SortingCriteria.DESCENDING ? sortedWithOrders.concat(withNoOrders) : withNoOrders.concat(sortedWithOrders);
  };

  public getPendingOrders(salesActivityPendingOrders: SalesActivityOrder[]): SalesActivityOrder[] {
    const sortByDatePlaced = (order1: SalesActivityOrder, order2: SalesActivityOrder) => {
      return this.sortByCriteria(order1.datePlaced, order2.datePlaced);
    };
    return this.getOrders(salesActivityPendingOrders, this.pendingOrdersCards, sortByDatePlaced);
  }

  public getAwaitingDeliveryCards(salesActivityConfirmedOrders: SalesActivityOrder[]): SalesActivityOrder[] {
    const sortByDeliveryDate = (order1: SalesActivityOrder, order2: SalesActivityOrder) => {
      return this.sortByCriteria( OrdoDate.convertFromString(order1.deliveryDate),  OrdoDate.convertFromString(order2.deliveryDate));
    };
    return this.getOrders(salesActivityConfirmedOrders, this.awaitingDeliveryCards, sortByDeliveryDate);
  }

  public getCollectPaymentCards(salesActivityCollectPaymentOrders: SalesActivityOrder[]): SalesActivityOrder[] {
    const sortByPaymentDate = (order1: SalesActivityOrder, order2: SalesActivityOrder) => {
      return this.sortByCriteria( OrdoDate.convertFromString(order1.paymentDueDate),  OrdoDate.convertFromString(order2.paymentDueDate));
    };
    return this.getOrders(salesActivityCollectPaymentOrders, this.collectPaymentCards, sortByPaymentDate);
  }

  private getOrders(salesActivityPaginatedOrders: SalesActivityOrder[], localOrders: SalesActivityOrder[] | undefined, sortFunction: (a: SalesActivityOrder, b: SalesActivityOrder) => number) {
    const salesActivityOrders = paginateSalesActivity(this.featureFlags) ? salesActivityPaginatedOrders : localOrders;
    const filteredItems = this.filterOrders(salesActivityOrders || []);
    return filteredItems.sort(sortFunction);
  }

  public async confirmOrder(organizationId: string, id: string) {
    await this.updateOrderStatus(organizationId, id, OrderStatus.Confirmed);
    return this.updatePipeline(organizationId);
  }

  public async markAccountAsWon(organizationId: string, account: SalesActivityAccount) {
    await this.api.updateAccountStatus(organizationId, account.id, AccountStatus.VERIFIED);
    await this.api.updateAccountStatusV2(organizationId, account.id, AccountStatusAux.ActiveBuyer);
    return this.updatePipeline(organizationId);
  }

  public async markAccountAsLost(organizationId: string, account: SalesActivityAccount) {
    await this.api.updateAccountStatus(organizationId, account.id, AccountStatus.LOST);
    await this.api.updateAccountStatusV2(organizationId, account.id, AccountStatusAux.Lost);
    return this.updatePipeline(organizationId);
  }

  public async markAccountAsProspect(organizationId: string, account: SalesActivityAccount) {
    await this.api.updateAccountStatus(organizationId, account.id, AccountStatus.UNKNOWN);
    await this.api.updateAccountStatusV2(organizationId, account.id, AccountStatusAux.Prospect);
    return this.updatePipeline(organizationId);
  }

  public cardBottomSectionTitle(order: SalesActivityOrder): string {
    return orderActivityStatusInfo(order);
  }

  public cardBottomSectionTitleRedColorText(order: SalesActivityOrder): string {
    return orderActivityStatusWarningInfo(order);
  }

  public shouldRedHighlightBottomSection(order: SalesActivityOrder): boolean {
    return orderActivityIsGoingStale(order);
  }

  public cardBottomSectionSubtitle(order: SalesActivityOrder): string {
    return orderActivityDateInfo(order);
  }

  public getQuantityText(list: SalesActivityAccount[]) : string {
    const quantity = list.length;
    return `${quantity} account${quantity !== 1 ? 's' : ''}`;
  }

  public getDealSubtitle(list: SalesActivityOrder[]) : string {
    const quantity = list.length;
    const salesTotalValue = list.reduce((a, b) => a + b.salesValue.getAmount(), 0);
    return `${quantity} deal${quantity !== 1 ? 's' : ''} ● ${Money.FromSerializable({ amount: salesTotalValue}).formatted()} in value`;
  }

  public filterByAccountName(accountName: string) {
    const viewModelInput = {
      nonQualifiedAccounts: this.nonQualifiedAccounts,
      verifiedPurchasers: this.verifiedPurchasers,
      stalePurchasers: this.stalePurchasers,
      lostPurchasers: this.lostPurchasers,
      prospectAccounts: this.prospectAccounts,
      pendingOrdersCards: this.pendingOrdersCards,
      awaitingDeliveryCards: this.awaitingDeliveryCards,
      collectPaymentCards: this.collectPaymentCards,
      searchByAccountNameInput: accountName,
      selectedSalesReps: this.selectedSalesReps,
      allAssignments: this.allAssignments,
      allAccounts: this.allAccounts,
      sortingCriteria: this.sortingCriteria,
      currentColumn: this.currentMobileColumn,
    };
    return new SalesActivityViewModel(this.organization, this.api, viewModelInput, this.featureFlags);
  }

  public updateAccountPriority(orgId: string, accountId: string, priority: AccountPriority, errorHandler: (err: Error) => void) {
    this.api.updateAccountPriority(orgId, accountId, priority)
      .catch((err) => {
        errorHandler(err);
      });

    const updateAccountPriority = (account: SalesActivityAccount) => {
      if (account.id === accountId) {
        return {
          ...account,
          priority: priority
        };
      }
      return account;
    };

    const updateOrderAccountPriority = (order: SalesActivityOrder) => {
      if (order.accountId === accountId) {
        return {
          ...order,
          priority: priority
        };
      }
      return order;
    };

    const updatedNonQualifiedAccounts = this.nonQualifiedAccounts.map(updateAccountPriority);
    const verifiedPurchasers = this.verifiedPurchasers.map(updateAccountPriority);
    const stalePurchasers = this.stalePurchasers.map(updateAccountPriority);
    const lostPurchasers = this.lostPurchasers.map(updateAccountPriority);
    const prospectAccounts = this.prospectAccounts.map(updateAccountPriority);
    const pendingOrdersCards = this.pendingOrdersCards?.map(updateOrderAccountPriority);
    const awaitingDeliveryCards = this.awaitingDeliveryCards?.map(updateOrderAccountPriority);
    const collectPaymentCards = this.collectPaymentCards.map(updateOrderAccountPriority);


    const viewModelInput: SalesActivityViewModelInput = {
      nonQualifiedAccounts: updatedNonQualifiedAccounts,
      verifiedPurchasers: verifiedPurchasers,
      stalePurchasers: stalePurchasers,
      lostPurchasers: lostPurchasers,
      prospectAccounts: prospectAccounts,
      pendingOrdersCards: pendingOrdersCards,
      awaitingDeliveryCards: awaitingDeliveryCards,
      collectPaymentCards: collectPaymentCards,
      searchByAccountNameInput: this.searchByAccountNameInput,
      selectedSalesReps: this.selectedSalesReps,
      allAssignments: this.allAssignments,
      allAccounts: this.allAccounts,
      sortingCriteria: this.sortingCriteria,
      currentColumn: this.currentMobileColumn,
    };

    return new SalesActivityViewModel(this.organization, this.api, viewModelInput, this.featureFlags);
  }

  public async getOrder(organizationId: string, order: SalesActivityOrder) {
    const {accountId} = order;
    const [fetchedOrder, contacts, notes, activities] = await Promise.all([
      this.api.getOrder(organizationId, order.id),
      this.api.getAccountContacts(organizationId, accountId),
      this.api.getAccountNotes(organizationId, accountId),
      this.api.getAccountActivities(organizationId, accountId),
    ]);
    return {order: fetchedOrder, contacts: contacts, notes: notes, activities: activities};
  }

  public async getAccountDetailsInformation(organizationId: string, accountId: string) {
    const [contacts, notes, activities] = await Promise.all([
      this.api.getAccountContacts(organizationId, accountId),
      this.api.getAccountNotes(organizationId, accountId),
      this.api.getAccountActivities(organizationId, accountId),
    ]).catch((error) => {
      throw error;
    });

    return  {contacts: contacts, notes: notes, activities: activities};
  }

  public async verifyOrderDelivery(organizationId: string, orderId: string) {
    await this.api.verifyOrderDelivery(organizationId, orderId);
    return this.updatePipeline(organizationId);
  }

  public async markOrderAsPaid(organizationId: string, order: SalesActivityOrder) {
    await this.api.updateOrderStatus(organizationId, order.id, OrderStatus.Paid);
    return this.updatePipeline(organizationId);
  }

  public async updatePipeline(organizationId: string) : Promise<SalesActivityViewModel> {
    if (paginateSalesActivity(this.featureFlags)) {
      return this;
    }
    const pipelineData = await this.api.getSalesActivity(organizationId);
    const viewModelInput = {
      nonQualifiedAccounts: pipelineData.nonQualifiedAccounts,
      verifiedPurchasers: pipelineData.verifiedPurchasers,
      stalePurchasers: pipelineData.stalePurchasers,
      lostPurchasers: pipelineData.lostPurchasers,
      prospectAccounts: pipelineData.prospectAccounts,
      pendingOrdersCards: pipelineData.pendingOrdersCards,
      awaitingDeliveryCards: pipelineData.awaitingDeliveryCards,
      collectPaymentCards: pipelineData.collectPaymentCards,
      searchByAccountNameInput: this.searchByAccountNameInput,
      selectedSalesReps: this.selectedSalesReps,
      allAssignments: pipelineData.assignments,
      allAccounts: this.allAccounts,
      sortingCriteria: this.sortingCriteria,
      currentColumn: this.currentMobileColumn,
    };
    return new SalesActivityViewModel(this.organization, this.api, viewModelInput, this.featureFlags);
  }

  public getAllAccounts() {
    return this.allAccounts;
  }


  private async updateOrderStatus(organizationId: string, id: string, status: OrderStatus) {
    await this.api.updateOrderStatus(organizationId, id, status);
  }

  private static filterListByAccount<T>(list: T[], accountName: string) {
    const searcher = new Fuse(
      list,
      {
        keys: ['name'],
        threshold: 0.0,
        ignoreLocation: true,
        findAllMatches: true,
      }
    );
    if (!accountName) return list;

    return searcher.search(accountName).map(res => res.item);
  }

  public filterBySalesRepName(selectedOptions: SelectablePipelineFilter[]): SalesActivityViewModel {

    const viewModelInput = {
      nonQualifiedAccounts: this.nonQualifiedAccounts,
      verifiedPurchasers: this.verifiedPurchasers,
      stalePurchasers: this.stalePurchasers,
      lostPurchasers: this.lostPurchasers,
      prospectAccounts: this.prospectAccounts,
      pendingOrdersCards: this.pendingOrdersCards,
      awaitingDeliveryCards: this.awaitingDeliveryCards,
      collectPaymentCards: this.collectPaymentCards,
      searchByAccountNameInput: this.searchByAccountNameInput,
      selectedSalesReps: selectedOptions,
      allAssignments: this.allAssignments,
      allAccounts: this.allAccounts,
      sortingCriteria: this.sortingCriteria,
      currentColumn: this.currentMobileColumn,
    };
    return new SalesActivityViewModel(this.organization, this.api, viewModelInput, this.featureFlags);
  }

  public salesReps(): SelectablePipelineFilter[] {
    return this.organization.members.map(member => {
      return {label: fullName(member.user), value: fullName(member.user)};
    });
  }

  private filterOrders(orders: SalesActivityOrder[]): SalesActivityOrder[] {
    const filteredOrders = orders.filter(order =>
      isEmpty(this.selectedSalesReps) || this.selectedSalesReps.some(option => option.value === order.createdBy)
    );
    return SalesActivityViewModel.filterListByAccount<SalesActivityOrder>(filteredOrders, this.searchByAccountNameInput);
  }

  private filterAccounts(accounts: SalesActivityAccount[]): SalesActivityAccount[] {
    const filteredAccounts = accounts.filter(account =>
      isEmpty(this.selectedSalesReps) || this.selectedSalesReps.some(option => this.allAssignments.some(assignment => assignment.memberName === option.value && assignment.accountsIds.includes(account.id)))
    );
    return SalesActivityViewModel.filterListByAccount<SalesActivityAccount>(filteredAccounts, this.searchByAccountNameInput);
  }

  public sortingCriteriaText(abreviated?: boolean): string {
    switch (this.sortingCriteria) {
    case SortingCriteria.ASCENDING:
      return abreviated ? 'asc' : 'ascending';
    case SortingCriteria.DESCENDING:
      return abreviated ? 'desc' : 'descending';
    default:
      return abreviated ? 'desc' : 'descending';
    }
  }

  public changeSortingCriteria() : SalesActivityViewModel {
    let newSortingCriteria;
    switch (this.sortingCriteria) {
    case SortingCriteria.ASCENDING:
      newSortingCriteria = SortingCriteria.DESCENDING;
      break;
    case SortingCriteria.DESCENDING:
      newSortingCriteria = SortingCriteria.ASCENDING;
      break;
    default:
      newSortingCriteria = SortingCriteria.DESCENDING;
      break;
    }

    const viewModelInput = {
      nonQualifiedAccounts: this.nonQualifiedAccounts,
      verifiedPurchasers: this.verifiedPurchasers,
      stalePurchasers: this.stalePurchasers,
      lostPurchasers: this.lostPurchasers,
      prospectAccounts: this.prospectAccounts,
      pendingOrdersCards: this.pendingOrdersCards,
      awaitingDeliveryCards: this.awaitingDeliveryCards,
      collectPaymentCards: this.collectPaymentCards,
      searchByAccountNameInput: this.searchByAccountNameInput,
      selectedSalesReps: this.selectedSalesReps,
      allAccounts: this.allAccounts,
      allAssignments: this.allAssignments,
      sortingCriteria: newSortingCriteria,
      currentColumn: this.currentMobileColumn,
    };
    return new SalesActivityViewModel(this.organization, this.api, viewModelInput, this.featureFlags);
  }

  private sortByCriteria(date1: OrdoDate, date2: OrdoDate){
    if(this.sortingCriteria === SortingCriteria.DESCENDING) {
      return date1.greaterThan(date2);
    }
    return date2.greaterThan(date1);
  }

  public changeCurrentColumn(columnIndex: number) {
    const viewModelInput = {
      nonQualifiedAccounts: this.nonQualifiedAccounts,
      verifiedPurchasers: this.verifiedPurchasers,
      stalePurchasers: this.stalePurchasers,
      lostPurchasers: this.lostPurchasers,
      prospectAccounts: this.prospectAccounts,
      pendingOrdersCards: this.pendingOrdersCards,
      awaitingDeliveryCards: this.awaitingDeliveryCards,
      collectPaymentCards: this.collectPaymentCards,
      searchByAccountNameInput: this.searchByAccountNameInput,
      selectedSalesReps: this.selectedSalesReps,
      allAccounts: this.allAccounts,
      allAssignments: this.allAssignments,
      sortingCriteria: this.sortingCriteria,
      currentColumn: columnIndex,
    };
    return new SalesActivityViewModel(this.organization, this.api, viewModelInput, this.featureFlags);
  }

  public getAllAccountsLocationsWithContacts(): AccountLocation[]{
    const accountsWithContacts = this.allAccounts.filter(account => account.hasContacts());
    let accountsLocations: AccountLocation[] = [];
    accountsWithContacts.forEach(account => {
      const accountLocation = account.locations.map(accLocation => {
        return {
          ...accLocation,
          account: account,
        };
      });
      accountsLocations = accountsLocations.concat(accountLocation);
    });

    return accountsLocations;
  }

  public fetchPendingOrders(api: API, orgId: string, callback: Function, errorCallback: Function, page: number = SalesActivityViewModel.FIRST_PAGE) {
    const filter = {orderStatuses: [OrderStatus.Pending]};
    this.fetchOrders(api, orgId, [], callback, errorCallback, filter,  page);
  }

  public fetchConfirmedOrders(api: API, orgId: string, callback: Function, errorCallback: Function, page: number = SalesActivityViewModel.FIRST_PAGE) {
    const filter = {orderStatuses: [OrderStatus.Confirmed]};
    this.fetchOrders(api, orgId, [], callback, errorCallback, filter, page);
  }

  public fetchDeliveredOrders(api: API, orgId: string, callback: Function, errorCallback: Function, page: number = SalesActivityViewModel.FIRST_PAGE) {
    const filter = {orderStatuses: [OrderStatus.Delivered]};
    this.fetchOrders(api, orgId, [], callback, errorCallback, filter, page);
  }

  public fetchNonQualifiedAccounts(api: API, orgId: string, currentNonQualifiedAccounts: SalesActivityAccount[], callback: Function, errorCallback: Function, page: number = SalesActivityViewModel.FIRST_PAGE) {
    api.getSalesActivityNonQualifiedAccounts(orgId, page, SalesActivityViewModel.ACCOUNTS_PER_PAGE)
      .then((result: {data: SalesActivityAccount[], total: number}) => {
        const accounts = result.data;
        const accountsCards = [...currentNonQualifiedAccounts || [], ...accounts];
        if (accounts.length === SalesActivityViewModel.ACCOUNTS_PER_PAGE) {
          this.fetchNonQualifiedAccounts(api, orgId, accountsCards, callback, errorCallback, page + 1);
        }
        callback(accountsCards);
      })
      .catch(()=> errorCallback('Couldn\'t retrieve the non-qualified accounts information'));
  }

  public fetchLostAccounts(api: API, orgId: string, currentLostAccounts: SalesActivityAccount[], callback: Function, errorCallback: Function, page: number = SalesActivityViewModel.FIRST_PAGE) {
    api.getSalesActivityLostAccounts(orgId, page, SalesActivityViewModel.ACCOUNTS_PER_PAGE)
      .then((result: {data: SalesActivityAccount[], total: number}) => {
        const accounts = result.data;
        const accountsCards = [...currentLostAccounts || [], ...accounts];
        if (accounts.length === SalesActivityViewModel.ACCOUNTS_PER_PAGE) {
          this.fetchLostAccounts(api, orgId, accountsCards, callback, errorCallback, page + 1);
        }
        callback(accountsCards);
      })
      .catch(()=> errorCallback('Couldn\'t retrieve the lost accounts information'));
  }

  public fetchProspectAccounts(api: API, orgId: string, currentLostAccounts: SalesActivityAccount[], callback: Function, errorCallback: Function, page: number = SalesActivityViewModel.FIRST_PAGE) {
    api.getSalesActivityProspectAccounts(orgId, page, SalesActivityViewModel.ACCOUNTS_PER_PAGE)
      .then((result: {data: SalesActivityAccount[], total: number}) => {
        const accounts = result.data;
        const accountsCards = [...currentLostAccounts || [], ...accounts];
        if (accounts.length === SalesActivityViewModel.ACCOUNTS_PER_PAGE) {
          this.fetchProspectAccounts(api, orgId, accountsCards, callback, errorCallback, page + 1);
        }
        callback(accountsCards);
      })
      .catch(()=> errorCallback('Couldn\'t retrieve the prospect accounts information'));
  }

  public fetchVerifiedAndStaleAccounts(api: API, orgId: string, currentVerifiedAccounts: SalesActivityAccount[], currentStaleAccounts: SalesActivityAccount[], verifiedCallback: Function, staleCallback: Function, errorCallback: Function, page: number = SalesActivityViewModel.FIRST_PAGE) {
    api.getSalesActivityVerifiedAndStaleAccounts(orgId, page, SalesActivityViewModel.ACCOUNTS_PER_PAGE)
      .then((result: {data: { verifiedAccounts: SalesActivityAccount[], staleAccounts: SalesActivityAccount[]}, total: number}) => {
        const {verifiedAccounts, staleAccounts} = result.data;
        const verifiedAccountsCards = [...currentVerifiedAccounts || [], ...verifiedAccounts];
        const staleAccountsCards = [...currentStaleAccounts || [], ...staleAccounts];
        const accountsFetched = verifiedAccounts.length + staleAccountsCards.length;
        if (accountsFetched === SalesActivityViewModel.ACCOUNTS_PER_PAGE) {
          this.fetchVerifiedAndStaleAccounts(api, orgId, verifiedAccountsCards, staleAccountsCards, verifiedCallback, staleCallback, errorCallback, page + 1);
        }
        verifiedCallback(verifiedAccountsCards);
        staleCallback(staleAccountsCards);
      })
      .catch(()=> errorCallback('Couldn\'t retrieve the verified and stale accounts information'));
  }

  private fetchOrders(api: API, orgId: string, currentOrdersCards: SalesActivityOrder[], callback: Function, errorCallback: Function, filter: OrderFilter, page: number = SalesActivityViewModel.FIRST_PAGE) {
    api.getSalesActivityFilteredOrders(orgId, page, SalesActivityViewModel.ORDERS_PER_PAGE, filter)
      .then((result: {data: SalesActivityOrder[], total: number}) => {
        const orders = result.data;
        const ordersCards = [...(currentOrdersCards || []), ...orders];
        callback(ordersCards);
        if (orders.length === SalesActivityViewModel.ORDERS_PER_PAGE) {
          this.fetchOrders(api, orgId, ordersCards, callback, errorCallback, filter, page + 1);
        }
      })
      .catch(()=> errorCallback('Couldn\'t retrieve the order information'));
  }
};
