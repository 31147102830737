import { LineItem, DiscountType } from '../../models/order-entry/Order';
import { SerializableMoney } from '../../models/Money';

export type CreateOrganizationLicenseRequest = {
  externalLicenseId: string
}

export type CreateAccountAssignmentRequest = {
  userId: string
  accountsIds: string[]
  disabledBrandIds: string[]
}

export type CheckoutOrderSummaryRequest = {
  lineItems: LineItem[],
  deliveryDate: Date
  distributorExternalLicenseId?: string,
  discountType?: DiscountType,
  discountCurrencyAmount?: SerializableMoney,
  discountPercentage?: number,
  distributorTaxesEnabled?: boolean,
}

export enum MappingSourceName {
  METRC = 'metrc',
  FAKE = 'fake',
  FLOURISH = 'flourish',
  NABIS = 'nabis'
}

export type ContactRequestInput = {
  name: string,
  email: string,
  title: string,
  phone: string,
  emailNotifications?: boolean,
  accountId: string,
}
