import Product from '../Product';
import {Money} from '../Money';
import {DiscountType, LineItemType} from './Order';
import { ProductWithAvailability } from '../productWithAvailability';

export class OrderVersionLineItem {
  constructor(
    public id: string,
    public productSnapshot: string,
    public productId: string,
    public product: Product,
    public quantity: number,
    public price: Money,
    public discountPercentage: number,
    public discountCurrencyAmount: Money,
    public discountType: DiscountType,
    public readonly salesTax: Money,
    public readonly exciseTax: Money,
    public type: LineItemType,
    public readonly notes: string,
  ) {}

  public discountToString(): string {
    switch (this.discountType) {
    case DiscountType.None:
      return 'none';
    case DiscountType.Dollar:
      return this.discountCurrencyAmount.formatted();
    case DiscountType.Percentage:
      return `${this.discountPercentage*100}%`;
    default:
      return '';
    }
  }

  /**
   * total represents the line item price before discounts
   */
  public total(): Money {
    return this.price.multiply(this.quantity);
  }

  /**
   * saleTotal represents the total value of this line item, including any discounts or price deductions
   * */
  public saleTotal(): Money {
    return this.total().subtract(this.discountAmount());
  }

  /**
   * salePrice represents the price of each case, including any discounts or price deductions
   * */
  public salePrice(): Money {
    return this.price;
  }

  /**
   * totalWithTaxes represents the line item price including taxes
   * We currently do not store tax information on a per-line-item basis, so this will be added later
   * */
  public totalWithTaxes(): Money {
    return this.saleTotal().add(this.salesTax).add(this.exciseTax);
  }

  public discountAmount(): Money {
    switch (this.discountType) {
    case DiscountType.Dollar:
      return this.discountCurrencyAmount;
    case DiscountType.Percentage:
      return this.total().multiply(this.discountPercentage);
    default:
      return Money.FromDollarAmount(0);
    }
  }

  /**
   * originalProductPrice represents the price per case before any reductions or discounts were made
   */
  public originalProductPrice(): Money {
    return Money.FromDollarAmount(this.product.price);
  }

  public get image(): string {
    return this.product.imageUrl;
  }

  public get name(): string {
    return this.product.name;
  }

  public get category(): string {
    return this.product.categoryToString();
  }

  public get genetics(): string {
    return this.product.geneticsToString();
  }

  public get brandImage(): string {
    return this.product.brand.logoImageURL;
  }

  public get brandName(): string {
    return this.product.brand.name;
  }

  public get unitsPerCase(): number {
    return this.product.unitsPerCase;
  }

  public get pricePerUnit(): Money {
    return this.product.unitSaleOnly ? this.price : this.price.divide(this.product.unitsPerCase).divide(this.quantity);
  }

  public updateProduct(asd: ProductWithAvailability) {
    this.product = asd;
  }

  public originalPrice() {
    switch (this.type) {
    case LineItemType.Case:
      return `${this.originalProductPrice().formatted()}(${this.originalProductPrice().divide(this.product.unitsPerCase).formatted()}/unit)`;
    case LineItemType.Unit:
      return this.product.unitSaleOnly ? this.originalProductPrice().formatted() : Money.FromDollarAmount(this.product.price/this.product.unitsPerCase).formatted();
    case LineItemType.Sample:
      return '';
    default:
      return '';
    }
  }

  public wrappedCaseSalePrice() {
    return this.salePrice().formatted();
  };

  public wrappedCaseSalePriceSubText() {
    return `(${this.salePrice().divide(this.product.unitsPerCase).formatted()}/unit)`;
  };

  public formattedSalesPrice() {
    switch (this.type) {
    case LineItemType.Case:
      return `${this.salePrice().formatted()}(${this.salePrice().divide(this.product.unitsPerCase).formatted()}/unit)`;
    case LineItemType.Unit:
      return this.salePrice().formatted();
    case LineItemType.Sample:
      return '';
    default:
      return '';
    }
  }

  public amountOfCasesOrUnits() : string {
    switch (this.type) {
    case LineItemType.Case:
      return `${this.quantity > 1 ? `${this.quantity} cases` : 'case'}`;
    case LineItemType.Unit:
      return `${this.quantity > 1 ? `${this.quantity} units` : 'unit'}`;
    case LineItemType.Sample:
      return '';
    default:
      return '';
    }
  }
}
