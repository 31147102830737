import FormError from './form_error';

export default class BusinessError extends Error {
  public formError: FormError;

  constructor(formError: FormError, message: string) {
    super(message);
    this.formError = formError;
  }
}
