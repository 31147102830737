export abstract class Paginator<T> {
  protected paginatedElements: T[] = []
  protected filter: any;

  protected constructor(filter: any = {}) {
    this.filter = filter;
  }

  public abstract paginate(elementsPerPage: number, page: number): Promise<void>;
  public abstract amountOfPages(elementsPerPage: number): number;

  public getCurrentPageElements(): T[] {
    return this.paginatedElements;
  }

  public withFilter(newFilter: any) {
    this.filter = newFilter;
    return this;
  }
}

export class InMemoryPaginator<T> extends Paginator<T> {
  protected originalElementsToPaginate: T[] = []
  private readonly filterFn?: ((p: T)=>boolean)
  constructor(originalElements: T[], filterFn?: ((p: T)=>boolean)) {
    super();
    this.originalElementsToPaginate = originalElements;
    this.filterFn = filterFn;
  }

  public async paginate(elementsPerPage: number, page: number): Promise<void> {
    this.paginatedElements = this.totalFilteredElements().slice(elementsPerPage * (page - 1), elementsPerPage * page);
  }

  public amountOfPages(elementsPerPage: number) {
    return Math.ceil(this.totalFilteredElements().length / elementsPerPage);
  }

  private totalFilteredElements() {
    return this.filterFn ? this.originalElementsToPaginate.filter(this.filterFn) : this.originalElementsToPaginate;
  }
}

export class DoNotPaginatePaginator<T> extends Paginator<T> {
  protected originalElementsToPaginate: T[] = []

  constructor(originalElements: T[], private filterFun: Function) {
    super();
    this.originalElementsToPaginate = originalElements;
  }

  public async paginate(_elementsPerPage: number, _page: number): Promise<void> {
    this.paginatedElements = this.filterFun(this.originalElementsToPaginate, this.filter);
  }

  public amountOfPages(elementsPerPage: number) {
    return Math.ceil(this.paginatedElements.length / elementsPerPage);
  }
}

export class InBackendPaginator<T> extends Paginator<T> {

  private total: number = 0;

  constructor(private retrieveElementsFun: Function, filter: any) {
    super(filter);
  }

  public async paginate(elementsPerPage: number, page: number): Promise<void> {
    const result = await this.retrieveElementsFun(page, elementsPerPage, this.filter);
    this.paginatedElements = result.data;
    this.total = result.total;
  }

  public amountOfPages(elementsPerPage: number) {
    return Math.ceil(this.total / elementsPerPage);
  }
}

export class PaginatorFactory {
  /**
   * inMemory returns a Paginator that given an array of elements to paginate will paginate them in memory.
   * @param elementsToPaginate using any here because Static members cannot reference class type parameters.
   * You can read more about this here: https://www.typescriptlang.org/docs/handbook/2/classes.html#type-parameters-in-static-members
   * @param page
   * @param perPage
   */
  public static async inMemory(elementsToPaginate: any[], page: number, perPage: number): Promise<InMemoryPaginator<any>> {
    const paginator = new InMemoryPaginator(elementsToPaginate);
    await paginator.paginate(perPage, page);
    return paginator;
  }

  /**
   * inBackend returns a Paginator that returns pages from the backend.
   * @param retrieveElementsFun
   * @param page
   * @param perPage
   * @param filter
   */
  public static async inBackend(retrieveElementsFun: Function, page: number, perPage: number, filter: any): Promise<InBackendPaginator<any>> {
    const paginator = new InBackendPaginator(retrieveElementsFun, filter);
    await paginator.paginate(perPage, page);
    return paginator;
  }

  /**
   * doNotPaginate returns a Paginator that does nothing.
   * @param elementsToPaginate using any here because Static members cannot reference class type parameters.
   * @param filterFun function to filter results.
   * You can read more about this here: https://www.typescriptlang.org/docs/handbook/2/classes.html#type-parameters-in-static-members
   */
  public static async doNotPaginate(elementsToPaginate: any[], filterFun: Function): Promise<DoNotPaginatePaginator<any>> {
    const paginator = new DoNotPaginatePaginator(elementsToPaginate, filterFun);
    await paginator.paginate(0, 0);
    return paginator;
  }

  public static empty(): InMemoryPaginator<any> {
    return new InMemoryPaginator<any>([]);
  }

  // TODO: add method for BackendPaginator
}
