import React, {useState} from 'react';
import '../../../scss/ordo/accountsPage/accountPage/account-page.scss';
import Account from '../../../models/Account';
import {AccountStatus} from '../../sales-activity/salesActivityTypes';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import {useAPI} from '../../../context/OrdoApiContext';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {DateFormatTemplate, OrdoDate} from '../../../models/OrdoDate';

type AccountPageInfoProps = {
  account: Account
}

export const AccountPageInfo = ({account} : AccountPageInfoProps) => {
  const [accountConversion, setAccountConversion] = useState(account.conversion);
  const [accountValue, setAccountValue] = useState(account.value);
  const [accountStalePeriod, setAccountStalePeriod] = useState(account.stalePeriod);
  const api = useAPI();
  const {errorToast, successToast}= useOrdoToasts();
  const [dueDate, setDueDate] = useState(OrdoDate.from(account.lastOrderDate!).addDays(account.stalePeriod));

  const shouldDisplayConversionAndValue = () => !(account.status === AccountStatus.VERIFIED);

  const validateConversionAndUpdate = () => {
    if(accountConversion !== account.conversion && accountConversion.toString() !== '' && accountConversion >= 0) {
      api.updateAccountConversion(account.organizationId, account.id, accountConversion)
        .then(() => {
          successToast('Account conversion updated');
        })
        .catch(() => errorToast('Could not update account conversion'));
    } else {
      setAccountConversion(account.conversion);
    }
  };

  const validateValueAndUpdate = () => {
    if(accountValue !== account.value && accountValue.toString() !== '' && accountValue >= 0) {
      api.updateAccountValue(account.organizationId, account.id, accountValue)
        .then(() => {
          successToast('Account value updated');
        })
        .catch(() => errorToast('Could not update account value'));
    } else {
      setAccountValue(account.value);
    }
  };

  const validateStalePeriodAndUpdate = () => {
    if(accountStalePeriod !== account.stalePeriod && accountStalePeriod.toString() !== '' && accountStalePeriod >= 0) {
      api.updateAccountStalePeriod(account.organizationId, account.id, accountStalePeriod)
        .then((updatedAccount) => {
          successToast('Account stale period updated');
          setDueDate(OrdoDate.from(account.lastOrderDate!).addDays(updatedAccount.stalePeriod));
        })
        .catch(() => errorToast('Could not update account stale period'));
    } else {
      setAccountStalePeriod(account.stalePeriod);
    }
  };

  return(
    <div className="account-info card card-fluid inventory-list-card ordo-shadow ordo-card-border">
      <div>
        <span className="section-title">account info</span>
      </div>
      {shouldDisplayConversionAndValue() && <div className="values">
        <div className={`account-value ${accountConversion < 0 ? 'invalid-input' : ''}`}>
          <span className="label">conversion %</span>
          <OrdoInput value={accountConversion}
            additionalClassName="value-input"
            onBlur={validateConversionAndUpdate}
            inputName="account-conversion"
            inputSize="large"
            inputType="number"
            bottomMargin={false}
            onChange={(conversion) => setAccountConversion(conversion)}
            min={0}
            placeholder={accountConversion.toString()}
            isValid={accountConversion >= 0}
            invalidMessage={accountConversion >= 0 ? '' : 'conversion cannot be negative'}
            key={`account-conversion-${account.id}`}/>
        </div>
        <div className={`account-value ${accountValue < 0 ? 'invalid-input' : ''}`}>
          <span className="label">value $</span>
          <OrdoInput value={accountValue}
            additionalClassName="value-input"
            inputName="account-value"
            inputSize="large"
            inputType="number"
            bottomMargin={false}
            onChange={(value) => setAccountValue(value)}
            onBlur={validateValueAndUpdate}
            min={0}
            placeholder={accountValue.toString()}
            isValid={accountValue >= 0}
            invalidMessage={accountValue >= 0 ? '' : 'value cannot be negative'}
            key={`account-value-${account.id}`}/>
        </div>
      </div>}
      {!shouldDisplayConversionAndValue() && <div className="values">
        <div className={`account-value ${(accountStalePeriod) < 0 ? 'invalid-input' : ''}`}>
          <span className="label">stale period</span>
          <OrdoInput value={accountStalePeriod}
            additionalClassName="value-input"
            inputName="account-stale-period"
            inputSize="large"
            inputType="number"
            bottomMargin={false}
            onChange={(stalePeriod) => setAccountStalePeriod(stalePeriod)}
            onBlur={validateStalePeriodAndUpdate}
            min={0}
            placeholder={accountStalePeriod.toString()}
            isValid={accountStalePeriod >= 0}
            invalidMessage={accountStalePeriod >= 0 ? '' : 'stale period cannot be negative'}
            key={`account-stale-period-${account.id}`}/>
        </div>

        <div className="account-last-order">
          <span className="title">next order due</span>
          <div className="date">
            {account.lastOrderDate && <span>{dueDate.format(DateFormatTemplate.DAY_MONTH_AND_DATE)}</span>}
            {!account.lastOrderDate && <span className="no-orders">none</span>}
          </div>
        </div>
      </div>}
    </div>
  );
};
