import React from 'react';
import OrdoModal from '../components/common/OrdoModal';
import {DateFormatTemplate, OrdoDate} from '../../models/OrdoDate';
import '../../scss/ordo/organizationPage/payments-history-modal.scss';

export type SubscriptionPayment = {
  date: Date,
  amount: number,
}

type PaymentsHistoryModalProps = {
  payments: SubscriptionPayment[],
  onClose: () => void,
  show: boolean
};

export const PaymentsHistoryModal = ({
  payments,
  show,
  onClose
}: PaymentsHistoryModalProps) => {
  return <OrdoModal
    title="your subscription payments"
    show={show}
    onClose={onClose}
    showFooter={false}>
    <div className="payment-history-modal">
      {payments.map((payment, index) => {
        const paymentDate = OrdoDate.from(payment.date);
        return <>
          <div className="payment-history-row">
            <div
              className="payment-history-row__date"
              title={paymentDate.format(DateFormatTemplate.FULL_DATE)}
            >
              {paymentDate.format(DateFormatTemplate.MONTH_NAME_AND_DAY)}
            </div>
            <div className="payment-history-row__amount">{`$${payment.amount.toFixed(2)}`}</div>
          </div>
          {(index !== payments.length - 1) && <div className='separator'/>}
        </>;
      })}
    </div>
  </OrdoModal>;
};