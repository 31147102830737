import {FormFields} from './form_error';

export default class InvalidField {
	public field: FormFields;
	public message: string;

	constructor(field: FormFields, message: string) {
	  this.field = field;
	  this.message = message;
	}
}
