import React, {useContext} from 'react';
import '../../../scss/ordo/general-header.scss';
import {useHistory} from 'react-router';
import {OrdoLogo} from '../common/OrdoLogo';
import {UserSessionContext} from '../../../context/UserSessionContext';
import OrdoInformativeSearchableDropdown from '../common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {
  toInformativeSelectableOption
} from '../common/searchable-dropdown/InformativeOption';
import {Organization} from '../../../models/Organization';
import {LaunchDarklyFeatureFlagFetcher} from '../../../lib/featureFlags/launchDarkly';
import {useAPI} from '../../../context/OrdoApiContext';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import {fullName} from '../../../models/User';
import {OrderEntryCartContext} from '../../../context/OrderEntryCartContext';
import {toFeatureFlagUser} from '../../../lib/featureFlags';
import {HEADER_MENU_Z_INDEX} from '../../../constants';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import ROUTE_NAMES from '../../../routes/ROUTE_NAMES';

const GeneralHeader = () => {
  const {user, update, organizations, currentOrganization} = useContext(UserSessionContext);
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {clearCart} = useContext(OrderEntryCartContext);
  const api = useAPI();
  const {successToast} = useOrdoToasts();
  const history = useHistory();
  const currentUserName = fullName(user());

  const selectableOrganizations = organizations().map((organization : Organization) =>
    toInformativeSelectableOption(organization.name, '', '', organization));

  const redirectToProfilePage = () => {
    successToast('Current organization has been successfully changed');
    history.push(ROUTE_NAMES.PROFILE);
  };

  const updateCurrentOrganization = (selectedOrganization: Organization) => {
    showSpinner();
    api.changeCurrentOrganization(selectedOrganization).then((userData) => {
      update(userData);
      clearCart();
      LaunchDarklyFeatureFlagFetcher.updateSession(toFeatureFlagUser(userData));
      redirectToProfilePage();
    }).finally(() => {
      hideSpinner();
    });
  };

  return <div className="general-header-container" >
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoLogo/>
      <OrdoInformativeSearchableDropdown
        dataTestId='select-organization'
        selectedOption={ toInformativeSelectableOption(currentUserName, (currentOrganization() || {name: ''}).name, '', (currentOrganization() || {} as Organization)) }
        options={selectableOrganizations}
        onChangeSelectedOption={(selectedOrganization) => {updateCurrentOrganization(selectedOrganization);}}
        placeholder='No current Organization'
        addedStyles={{menu: {zIndex: HEADER_MENU_Z_INDEX}}}
      />
    </OrdoSpinner>
  </div>;
};

export default GeneralHeader;
