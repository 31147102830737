import React, {PropsWithChildren, ReactNode, useState} from 'react';
import '../../scss/ordo/side-drawer.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {BackgroundOverlay} from '../components/common/BackgroundOverlay';

const DrawerAnimations = {
  SLIDE_IN : {name: 'drawer-slidein', duration: '.5s'},
  SLIDE_OUT :{name: 'drawer-slideout', duration: '.3s'}
};

export type SideDrawerProps = PropsWithChildren<{
  onClose: () => void,
  onMainAction: () => any,
  shouldOpen: boolean,
  content: (closeDrawer: Function, doActionAndClose: Function, doMainActionOnClose?: Function) => ReactNode
}>;

export const SideDrawer = ({shouldOpen, onClose, onMainAction, content}: SideDrawerProps) => {
  const [animation, setAnimation] = useState(DrawerAnimations.SLIDE_IN);
  const [doMainAction, setDoMainAction] = useState(false);
  const closeDrawer = () => setAnimation(DrawerAnimations.SLIDE_OUT);

  const doActionAndClose = () => {
    setAnimation(DrawerAnimations.SLIDE_OUT);
    setDoMainAction(true);
  };

  const onAnimationEnd = ({animationName}: {animationName: string}) => {
    if(animationName === DrawerAnimations.SLIDE_OUT.name) {
      onClose();
      if(doMainAction) onMainAction();
      setAnimation(DrawerAnimations.SLIDE_IN);
      setDoMainAction(false);
    }
  };

  const doMainActionOnClose = () => {
    setDoMainAction(true);
  };

  return shouldOpen ?
    <div className='drawer-container'>
      <BackgroundOverlay onClick={closeDrawer}/>
      <div className="drawer right"
        style={{animationName: animation.name, animationDuration: animation.duration}}
        onAnimationEnd={onAnimationEnd}
      >
        <div  className='close-icon' onClick={closeDrawer} role="presentation">
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
        {content(closeDrawer, doActionAndClose, doMainActionOnClose)}
      </div>
    </div>
    :
    <></>;
};
