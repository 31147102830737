import React, {PropsWithChildren, ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import SideBar from './components/sidebar/SideBar';
import '../scss/purpose.scss';
import '../scss/ordo/layout/ordo-layout.scss';
import {isFullPageRoute, WiderRoutes} from '../routes/ROUTE_NAMES';
import GeneralHeader from './components/sidebar/GeneralHeader';
import VerifyAccountOverlay from './components/VerifyAccountOverlay';
import {useResolution} from '../context/UseResolutions';
import {isMobileResolution} from '../context/resolutions';

const OrdoGeneralLayout = ({children}: PropsWithChildren<ReactNode>) => {
  const location = useLocation();
  const resolution = useResolution();
  const currentLocation = location.pathname;
  const showFullPage = isFullPageRoute(currentLocation);
  const makePageWider = WiderRoutes.includes(currentLocation) && !isMobileResolution(resolution);

  const OrdoLayout = <>
    <div className="ordo-application ordo-application-offset ready">
      <div className="ordo-container-application">
        <div className="page-with-sidebar">
          <SideBar/>
          <div className="ordo-main-content-container">
            <GeneralHeader/>
            <div className="scrollable-content">
              <div className={`${makePageWider ? 'wider-main-content' : 'main-content'}`}>
                <VerifyAccountOverlay>
                  {children}
                </VerifyAccountOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;

  // TODO: OR-661 use custom classes, not bootstrap ones.
  const OrdoFullPageLayout = <>
    <div className="ordo-application-full-page page-content with-gradient-background ready">
      <div className="ordo-logo">
        Ordo
      </div>
      <div className="min-vh-100 py-5 d-flex align-items-center">
        <div className="w-100">
          {children}
        </div>
      </div>
    </div>
  </>;

  return showFullPage ? OrdoFullPageLayout : OrdoLayout;
};

export default OrdoGeneralLayout;
