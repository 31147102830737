import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link, useLocation} from 'react-router-dom';
import React from 'react';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {NavigationButtonData} from '../../../models/side-bar/NavigationButtonData';

type SideBarButtonProps = {
  route: string,
  alternativeRoute?: string,
  title: string,
  icon: IconDefinition,
};

function isCurrentLocation(route: string, alternativeRoute?: string) {
  const location = useLocation();
  return location.pathname === route || location.pathname === alternativeRoute;
}

const SideBarButton = ({route, alternativeRoute, title, icon}: SideBarButtonProps) => {
  const isCurrentRoute = isCurrentLocation(route, alternativeRoute);

  const content = <FontAwesomeIcon icon={icon}/>;
  return <Link to={route} className={`sidebar-btn ${  isCurrentRoute ? 'current-page' :''}` } title={title}>
    {content}
  </Link>;
};

export const SideBarButtonExpanded = ({ route, alternativeRoute, title, icon, onClick = ()=>{}}: SideBarButtonProps & {onClick?: ()=> void}) => {
  const isCurrentRoute = isCurrentLocation(route, alternativeRoute);

  const content = <>
    <div  className='sidebar-btn'>
      <FontAwesomeIcon icon={icon}/>
    </div>
    <div className='button-label'>{title}</div>
  </>;

  return <Link to={route} className={`extended-button ${ isCurrentRoute ? 'current-page' :''}`} title={title} onClick={onClick}>
    {content}
  </Link>;
};

export const buildSideBarButtons = (navigationButtonsData: NavigationButtonData[], shouldExpand: boolean, actionOnRedirect?: ()=> void) => navigationButtonsData.map((navButton) => {
  return shouldExpand ?
    <SideBarButtonExpanded
      key={navButton.route}
      route={navButton.route}
      alternativeRoute={navButton.alternativeRoute}
      title={navButton.title}
      icon={navButton.icon}
      onClick={actionOnRedirect}
    />
    : <SideBarButton
      key={navButton.route}
      route={navButton.route}
      alternativeRoute={navButton.alternativeRoute}
      title={navButton.title}
      icon={navButton.icon}
    />;
});
