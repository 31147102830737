import React from 'react';
import {StateFilter} from '../../../../activity-manager/components/activitiesPage/ActivitiesStateFilters';
import {AccountActivityFilter} from './AccountActivityFilter';

export const AccountActivityFilters = ({filter, onUpdateFilter}: {filter: AccountActivityFilter, onUpdateFilter: Function}) => {
  return(
    <div className="activities-state-filters">
      <StateFilter text='all' checked={filter.hasAllFilter()} onClick={() => {onUpdateFilter(filter.toggleAllFilter());}}/>
      <StateFilter text='incomplete' checked={filter.hasIncompleteFilter()} onClick={() => {onUpdateFilter(filter.toggleIncompleteFilter());}}/>
      <StateFilter text='complete' checked={filter.hasCompleteFilter()} onClick={() => {onUpdateFilter(filter.toggleCompleteFilter());}}/>
      <StateFilter text='notes' checked={filter.hasNotesFilter()} onClick={() => {onUpdateFilter(filter.toggleNotesFilter());}}/>
      <StateFilter text='orders' checked={filter.hasOrdersFilter()} onClick={() => {onUpdateFilter(filter.toggleOrdersFilter());}}/>
    </div>
  );
};
