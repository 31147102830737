import React from 'react';
import { useHistory } from 'react-router';
import OrdoModal from '../components/common/OrdoModal';
import OrdoButton from '../components/common/OrdoButton';
import '../../scss/ordo/salesActivity/verify-order-modal.scss';
import {SalesActivityOrder} from './salesActivityTypes';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import { OrderStatus } from '../../models/order-entry/Order';
import {NON_ACTIVE_ACCOUNT_TEXT} from '../../models/Account';
import {OrderPrefilledMode} from '../../application-models/order-entry/order-entry-mode';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';

type VerifyOrderDeliveryModalProps = {
  isOpen: boolean,
  onClose: () => void,
  onVerify: (orderId: string) => Promise<void>,
  orderToVerifyDelivery: SalesActivityOrder | OrderWithCurrentVersion,
  editTrackedOrder: (orderId: string) => void,
  allowEdit: boolean
}

const VerifyOrderDeliveryModal = ({isOpen, onClose, onVerify, orderToVerifyDelivery, editTrackedOrder, allowEdit}: VerifyOrderDeliveryModalProps) => {
  const history = useHistory();
  const verifyWithEdit = () => {
    onClose();
    if(orderToVerifyDelivery.isTracked) {
      editTrackedOrder(orderToVerifyDelivery.id);
    } else {
      history.push(ROUTE_NAMES.EDIT_ORDER, {orderId: orderToVerifyDelivery.id, orderPrefillMode: OrderPrefilledMode.edit, newStatus: OrderStatus.Delivered});
    }
  };

  return(
    <OrdoModal title={`verify delivery for order #${orderToVerifyDelivery.orderNumber}`}
      show={isOpen}
      size="sm"
      onClose={onClose}
      showFooter={false}
      className='verify-order-modal'
    >

      <div className="verify-delivery-buttons">
        <div className="verify-order-button">
          <OrdoButton
            disabled={!allowEdit}
            text="verify with changes to the order"
            category="primary"
            style={{minWidth: '23em'}}
            onClick={verifyWithEdit}
            dataTestId="verify-delivery-with-changes-button"
            disabledText={NON_ACTIVE_ACCOUNT_TEXT}
          />
        </div>
        <div className="verify-order-button">
          <OrdoButton
            disabled={false}
            text="verify without changes to the order"
            category="secondary"
            style={{minWidth: '23em'}}
            onClick={() => onVerify(orderToVerifyDelivery.id)}
            dataTestId="verify-delivery-without-changes-button"
          />
        </div>
        <div className="verify-order-button">
          <OrdoButton
            disabled={false}
            text="cancel delivery verification"
            category="cancel"
            style={{minWidth: '23em'}}
            onClick={onClose}
            dataTestId="cancel-delivery-verification"/>
        </div>
      </div>
    </OrdoModal>
  );
};

export default VerifyOrderDeliveryModal;
