import React from 'react';
import { Brand } from '../../../models/Brand';
import ImagePreview from './ImagePreview';
import '../../../scss/ordo/common.scss';
import '../../../scss/ordo/brand-card.scss';

const BrandCard = ({brand, onClick}: {brand: Brand, onClick: Function}) => (
  <div role="presentation" data-testid={`brand-${brand.id}`} className="d-flex flex-column align-items-center brand-card" onClick={() => onClick(brand)}>
    <ImagePreview url={brand.logoImageURL} alt={brand.name} size={60}/>
    <div className="brand-card__name">
      {brand.name}
    </div>
  </div>
);

export default BrandCard;
