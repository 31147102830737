import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import Batch from '../../models/Batch';
import OrdoModal from '../components/common/OrdoModal';
import useModalToggle from '../../hooks/useModalToggle';
import DeleteBatchConfirmationModal from '../components/modals/DeleteBatchConfirmationModal';
import Product from '../../models/Product';
import OrdoInput from '../components/common/inputs/OrdoInput';
import {useAPI} from '../../context/OrdoApiContext';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import BatchModal from '../components/modals/BatchModal';

const ProductBatch = ({product, batch, organizationId, onBatchChange, distributorExternalLicenseId}: {product: Product, batch: Batch, organizationId: string, onBatchChange: Function, distributorExternalLicenseId: string}) => {
  const [showCOAModal, setShowCOAModal] = useState(false);
  const [showEditBatchModal, setShowEditBatchModal] = useState(false);
  const originalBatchQuantity = batch.quantityOfUnits;
  const [productBatch, setProductBatch] = useState(batch);
  const {showModal: showDeleteBatch, closeModal: closeDeleteBatch, openModal: openDeleteBatch} = useModalToggle();
  const api = useAPI();
  const {successToast, errorToast} = useOrdoToasts();

  const toggleEditBatchModal = () => setShowEditBatchModal(!showEditBatchModal);

  const internalQuantityUpdate = (newQuantity: string) => {
    const newQuantityToNumber = parseInt(newQuantity, 10);
    if (newQuantityToNumber > 0) {
      productBatch.updateQuantityOfUnits(newQuantityToNumber);
      setProductBatch(productBatch);
    }
  };

  const updateBatchQuantity = async () => {
    const batchToEdit = new Batch(productBatch.id, productBatch.productId, productBatch.batchId, productBatch.quantityOfUnits, productBatch.createdAt, productBatch.advertisedTHC, productBatch.distributorOrganizationLicenseId, productBatch.coaDocumentURL, productBatch.advertisedCBD, productBatch.coa, productBatch.packagedDate);
    if(productBatch.quantityOfUnits !== originalBatchQuantity && productBatch.quantityOfUnits > 0) {
      api.editBatch(organizationId, batchToEdit)
        .then(() => {
          successToast('batch quantity updated');
        })
        .catch(error => {
          errorToast(error.message);
        })
        .finally(() => onBatchChange());
    }
  };

  const batchAmountOfCases = () => Math.floor(productBatch.quantityOfUnits/product.unitsPerCase);

  const casesSubtext = () => {
    if(!product.unitSaleOnly) {
      return `${batchAmountOfCases()} ${batchAmountOfCases() > 1 ? 'cases' : 'case'}`;
    }
    return '';
  };

  const renderUnits = () => {
    return (
      <div className="batch-info__units-and-id__units-and-cases">
        <OrdoInput
          inputName="cases-number"
          value={productBatch.quantityOfUnits}
          inputType="number"
          placeholder=""
          inputSize="extra-short"
          bottomMargin={false}
          onChange={(value) => internalQuantityUpdate(value)}
          onBlur={updateBatchQuantity}
          min={1}
        />
        <span className="total-cases">{casesSubtext()}</span>
      </div>
    );
  };

  return (
    <div className="batch-info">
      <div className="batch-info__units-and-id">
        {renderUnits()}
        <div className="batch-info__id-and-distributor">
          <span className="batch-id">{batch.batchId}</span>
          <span className="distributor">{distributorExternalLicenseId}</span>
        </div>
      </div>
      <div className='batch-row-buttons'>
        {batch.coaDocumentURL && <button type="button" className="coa-button" style={{marginRight: '.5em'}} onClick={() => setShowCOAModal(true)}>coa</button>}
        <button type="button" className="coa-button" onClick={() => toggleEditBatchModal()}>edit</button>
        <FontAwesomeIcon className="interectable-icon delete-batch" size="lg" color="#8492A6" icon={faMinusCircle}
          onClick={openDeleteBatch}/>
      </div>
      <OrdoModal showFooter={false} size="xl" show={showCOAModal} onClose={() => setShowCOAModal(false)}>
        <embed src={batch.coaDocumentURL!} width="100%" height="500em"/>
      </OrdoModal>

      {
        showEditBatchModal && <BatchModal
          isOpen={showEditBatchModal}
          onClose={toggleEditBatchModal}
          product={product}
          onBatchUpdate={(b: Batch) => {
            setProductBatch(b);
            onBatchChange();
          }}
          batch={batch}/>
      }
      {showDeleteBatch && <DeleteBatchConfirmationModal
        show={showDeleteBatch}
        onClose={closeDeleteBatch}
        onDeleteConfirmed={onBatchChange}
        batch={batch}
        organizationId={organizationId}
      />}
    </div>);
};

export default ProductBatch;
