import {API} from '../../lib/api/ordoApi';
import { Note } from '../../models/Note';
import Contact from '../../models/order-entry/Contact';

export default class NoteViewModel {
  public constructor(
    public readonly api: API,
    public readonly title: string,
    public readonly description: string,
    public readonly contacts: Contact[],
  ) {}

  static empty(api: API): NoteViewModel {
    return new NoteViewModel(api,'', '', []);
  }

  public updateTitle(newTitle: string): NoteViewModel {
    return new NoteViewModel(this.api, newTitle, this.description, this.contacts);
  }

  public updateDescription(newDescription: string): NoteViewModel {
    return new NoteViewModel(this.api, this.title, newDescription, this.contacts);
  }

  public updateContacts(selectedContacts: Contact[]) {
    return new NoteViewModel(this.api, this.title, this.description, selectedContacts);
  }

  public async createNote(orgId: string, accountId: string): Promise<Note> {
    const noteInput = {title: this.title, description: this.description, contacts: this.contacts};
    return this.api.createNote(noteInput, orgId, accountId);
  }

  public async updateNote(orgId: string, accountId: string, noteId: string): Promise<Note> {
    const noteInput = {title: this.title, description: this.description, contacts: this.contacts};
    return this.api.updateNote(noteInput, orgId, accountId, noteId);
  }

  public async deleteNote(orgId: string, accountId: string, noteId: string): Promise<void> {
    await this.api.deleteNote(orgId, accountId, noteId);
  }

  public canSubmit(): boolean {
    return !!this.title && !!this.description;
  }

}
