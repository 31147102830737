export enum CategoryNames {
  FLOWER = 'Flower',
  VAPORIZER_ALL_IN_ONE = 'Vaporizer All-in-one',
  VAPORIZER_CARTRIDGE = 'Vaporizer Cartridge',
  PRE_ROLL = 'Pre-roll',
  LIVE_RESIN = 'Live Resin',
  SAUCE = 'Sauce',
  CRUMBLE = 'Crumble',
  DIAMONDS = 'Diamonds',
  EDIBLES = 'Edibles',
  DRINKS = 'Drinks',
  TOPICALS_AND_TINCTURES = 'Topicals & Tinctures',
  ACCESSORIES = 'Accessories',
  PRE_GROUND_FLOWER = 'Pre-ground Flower'
}

export default class ProductCategory {

  constructor(public id: string, public name: string) {
  }

  public isAccessories(): boolean {
    return this.name === CategoryNames.ACCESSORIES;
  }

  public toString(): string {
    return this.name;
  }
}
