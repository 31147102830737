import Batch from './Batch';
import {Brand} from './Brand';
import ProductCategory from './ProductCategory';
import {CartItemType} from './order-entry/CartItem';

export enum Genetics {
	Unknown = 0,
	SATIVA = 1,
	INDICA = 2,
	HYBRID = 3,
}

export function geneticsToString(genetics: Genetics | undefined): string {
  switch (genetics) {
  case Genetics.HYBRID:
    return 'hybrid';
  case Genetics.INDICA:
    return 'indica';
  case Genetics.SATIVA:
    return 'sativa';
  default:
    return '';
  }
}

export enum ProductMeasureUnit {
	GRAMS = 'grams',
	MILLIGRAMS = 'mg',
  UNKNOWN = 'unknown',
}

export type ConsumerUnitSize = {
	amount: number,
	unit: ProductMeasureUnit
}

export default class Product {
  constructor(public id: string,
	            public name: string,
	            public brand: Brand,
	            public category: ProductCategory,
	            public batches: Batch[],
	            public organizationId: string,
	            public imageUrl: string,
	            public price: number, // TODO (mk): Update this to type Money in a future PR
	            public unitsPerCase: number,
              public unitSaleOnly: boolean,
              public caseSaleOnly: boolean,
	            public size?: ConsumerUnitSize,
	            public genetics?: Genetics,
	            public advertisedTHC?: number,
	            public advertisedCBD?: number,
	            public preRolls?: number,
              public customerSKU?: string,
              public deletedAt?: Date) {
  }

  public isAccessories(): boolean {
    return this.category.isAccessories();
  }

  public geneticsToString(): string {
    return geneticsToString(this.genetics);
  }

  public sizeToString(): string {
    return this.size && this.size.amount && this.size.unit ? `${this.size.amount} ${this.generateConsumerSizeUnit()}` : '-';
  }

  public generateConsumerSizeUnit() {
    if(this.size?.unit === ProductMeasureUnit.GRAMS) {
      return this.size.amount === 1 ? 'gram' : 'grams';
    }
    if(this.size?.unit === ProductMeasureUnit.MILLIGRAMS) {
      return this.size.amount === 1 ? 'milligram' : 'milligrams';
    }
    return '';
  }

  public categoryToString() {
    return this.category.toString();
  }

  public brandToString() {
    return this.brand.name;
  }

  public totalStock(distributorOrganizationLicenseId: string) {
    const batches = this.batches.filter(batch => batch.distributorOrganizationLicenseId === distributorOrganizationLicenseId);
    return batches.reduce((accumulated, currentBatch) => {
      return accumulated + currentBatch.quantityOfUnits;
    }, 0);
  }

  public firstCOA() {
    if(this.batches.length === 0 ) return '';
    const firstBatch = this.batches.reduce((oldestCreated, batch) =>
      (oldestCreated.createdAt < batch.createdAt) && batch.quantityOfUnits > 0 ? oldestCreated : batch);
    return firstBatch.coaDocumentURL;
  }

  public unitsPerCaseToString(): string {
    if(this.isAccessories() || !this.size) {
      return `${this.unitsPerCase} units`;
    }
    if(this.unitsPerCase < 1) {
      return `${this.size.amount} ${this.generateConsumerSizeUnit()}`;
    }
    return `${this.size.amount} ${this.generateConsumerSizeUnit()} x ${this.unitsPerCase} units`;
  }

  public unitsPerCaseToStringMobile(): string {
    if(this.isAccessories() || !this.size) {
      return `${this.unitsPerCase}`;
    }
    const nonEmptySizeAmount = !!this.size.amount || this.size.amount === 0;
    return `${nonEmptySizeAmount? `${this.size.amount} ${this.size.unit} x ${this.unitsPerCase}` : `x${this.unitsPerCase}`} `;

  }

  public unitsToString(): string {
    if(this.isAccessories() || !this.size) {
      return `${this.unitsPerCase === 1 ? 'units' : 'unit'}`;
    }
    const nonEmptySizeAmount = !!this.size.amount || this.size.amount === 0;
    return `${nonEmptySizeAmount ? `${this.size.amount} ${this.unitToString()}` : '-'}`;
  };

  public unitToString(): string {
    switch (this.size?.unit) {
    case ProductMeasureUnit.GRAMS:
      return this.size?.amount === 1 ? 'gram' : 'grams';
    case ProductMeasureUnit.MILLIGRAMS:
      return this.size?.amount === 1 ? 'milligram' : 'milligrams';
    default:
      return 'grams';
    }
  }

  public perCaseToString(): string {
    return (this.isAccessories() || !this.size) ? '' : `x ${this.unitsPerCase} units`;
  }

  public categoryAndGenetics(): string {
    return this.genetics ? `${this.categoryToString()}, ${this.geneticsToString()}` : `${this.categoryToString()}`;
  }

  public calculatePricePerItem(itemType: CartItemType) {
    let {price} = this;
    if (itemType === CartItemType.CASE) {
      price = this.price;
    }
    if (itemType === CartItemType.UNIT) {
      price = this.unitSaleOnly ? this.price : this.price / this.unitsPerCase;
    }
    if (itemType === CartItemType.SAMPLE) {
      throw Error('Price for sample is not being handled');
    }

    return price;
  }

  public salesPrice() {
    return this.price;
  }

  public isDeleted(): boolean {
    return !!this.deletedAt;
  }
};
