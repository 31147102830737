import React from 'react';
import {OrdoFile} from '../../../../models/OrdoFile';

type OrdoFileInputProps = {
  text: string,
  disabled?: boolean,
  onFileSelected: Function,
  invalidMessage?: string,
  isValid?: boolean,
  supportedFormats?: string
}
const OrdoFileInput = ({onFileSelected, disabled, text, invalidMessage, isValid, supportedFormats} : OrdoFileInputProps) => {
  const handleFileSelected = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const ordoFile: OrdoFile = {
        preview: URL.createObjectURL(file),
        raw: file,
        name: file.name,
      };
      onFileSelected(ordoFile);
    }
  };

  return <>
    <label htmlFor="upload-button" className={`ordo-button-reduced-padding btn btn-sm btn-light btn-icon ordo-cancel-button rounded-pill ordo-file-input ${isValid ? '' : 'is-invalid'} ${disabled ? 'disabled' : ''} `}>
      <div className="btn-inner--text mr-0">{text}</div>
      <input disabled={disabled} className='d-none' type="file" id="upload-button" onChange={handleFileSelected} accept={supportedFormats}/>
    </label>
    {(isValid !== undefined && !isValid) && <div className="invalid-feedback">
      {invalidMessage}
    </div>}
  </>;
};
export default OrdoFileInput;
