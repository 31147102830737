import React from 'react';
import {Dropdown} from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import '../../../scss/ordo/dropdown.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretLeft, faCaretRight, faCaretUp, } from '@fortawesome/free-solid-svg-icons';

type OrdoDropdownOption = {
}

export type OrdoDropdownProps<T> = {
  placeholder: string,
  selectedOption: T | null,
  options: T[],
  onSelect: Function,
  displayName?: (t: T) => string
  invalidMessage?: string,
  isValid?: boolean
  height?: 'short' | 'tall'
  drop?: 'up' | 'left' | 'right' | 'down',
  forceLowerCase?: boolean,
  dropdownName?: string,
  disabled?: boolean
}
const caret = {
  'up': faCaretUp,
  'left': faCaretLeft,
  'right': faCaretRight,
  'down': faCaretDown,
};

const OrdoDropdown = <T extends OrdoDropdownOption>({dropdownName, selectedOption, disabled = false, placeholder, options, onSelect, displayName = (t)=>t.toString(), invalidMessage, isValid=true, height='tall', drop='down', forceLowerCase=true } : OrdoDropdownProps<T>) => {

  const adjustCase = (s: string) => forceLowerCase ? s.toLowerCase() : s;
  const labelText = selectedOption ? adjustCase(displayName(selectedOption))  : placeholder;
  const dataTestId = `${dropdownName}-ordo-dropdown`;
  return <><Dropdown
    drop={drop}
    alignRight
    onClick={(e: { preventDefault: () => void; }) => e.preventDefault()}
    className={`ordo-dropdown w-100 ${isValid? '' : 'is-invalid'}`}
  >
    <DropdownToggle className={`action-item mr-2 form-control ${height} ordo-dropdown__button-toggle`} data-testid={dataTestId} disabled={disabled}>
      <span className={`toggle-tag ${selectedOption ? 'selected-text':''}`}>{labelText}</span>
      <FontAwesomeIcon className='ml-0' icon={caret[drop]}/>
    </DropdownToggle>
    <Dropdown.Menu className='w-100'>
      {
        options.map((option, index) => {
          const optionName = displayName(option);
          return <Dropdown.Item as='button' key={optionName} className='font-weight-bold dropdown-item'
            onSelect={() => onSelect(option)} data-testid={`${dataTestId}--option-${index}`}>
            {adjustCase(optionName)}
          </Dropdown.Item>;
        }
        )
      }
    </Dropdown.Menu>
  </Dropdown>
  {(isValid !== undefined && !isValid) && <div className="invalid-feedback">
    {invalidMessage}
  </div>}
  </>;
};

export default OrdoDropdown;
