import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import '../../scss/ordo/orderHistory/order-history-page.scss';
import {debounce} from 'lodash';
import OrderHistoryOrderList from '../components/order-history/OrderHistoryOrderList';
import {API} from '../../lib/api/ordoApi';
import {useAPI} from '../../context/OrdoApiContext';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import {UserSessionContext} from '../../context/UserSessionContext';
import OrdoMultiSelect from '../components/common/OrdoMultiSelect';
import OrderHistoryViewModel, {SelectableFilter} from '../../application-models/OrderHistoryViewModel';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import OrdoInput from '../components/common/inputs/OrdoInput';
import useOrganizationRedirect from '../../hooks/useOrganizationRedirect';
import {AdminChecker} from '../../routes/permissions';
import {OrdoPagination} from '../components/pagination/OrdoPagination';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import {isMobileResolution, Resolution} from '../../context/resolutions';
import {useResolution} from '../../context/UseResolutions';
import {MobileOrderHistory} from './mobile/MobileOrderHistory';

const OrderHistoryPage = () => {
  const {errorToast} = useOrdoToasts();

  const api: API = useAPI();
  const {validateOrRedirect} = useOrganizationRedirect();
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const [viewModel, setViewModel] = useState(OrderHistoryViewModel.emptyOrderHistoryViewModel());
  const adminChecker = new AdminChecker();
  const resolution: Resolution = useResolution();
  const inMobileResolution = isMobileResolution(resolution);

  const filterBySalesReps = async (selectableSalesReps: SelectableFilter[]) => {
    setViewModel(await viewModel.filterBySalesReps(selectableSalesReps));
  };

  const filterByAccounts = async (selectableAccounts: SelectableFilter[]) => {
    setViewModel(await viewModel.filterByAccounts(selectableAccounts));
  };

  const filterByStatuses = async (selectableStatuses: SelectableFilter[]) => {
    setViewModel(await viewModel.filterByStatuses(selectableStatuses));
  };


  const filterByOrderNumber = debounce(async (orderNumber: string) => {
    setViewModel(await viewModel.filterByOrderNumber(orderNumber));
  }, 500);

  const updateOrder = (order: OrderWithCurrentVersion) => {
    setViewModel(viewModel.updateOrder(order));
  };

  const updatePage = (page: number) => {
    showSpinner();
    viewModel.updatePage(page)
      .then((updatedViewModel) => setViewModel(updatedViewModel))
      .catch(() => {
        errorToast(`Could not search page number ${page}`);
      })
      .finally(hideSpinner);
  };

  useEffect(() => {
    showSpinner();
    validateOrRedirect(() => {
      viewModel.initialize(api, organization)
        .then(setViewModel)
        .finally(hideSpinner);
    });
  }, [userSession.currentOrganization()] );

  const webOrderHistory = () => {
    return (
      <OrdoSpinner showSpinner={spinnerVisibility}>
        <div className='order-history-page'>
          <OrdoPageTitle title='order history' buttons={[]}/>
          <div className="page-content-body">
            <div className='order-history-page-order-filters'>
              <div className="order-history-search-input-order-id">
                <OrdoInput
                  bottomMargin={false}
                  inputName='search-order-input'
                  value={viewModel.searchedOrderNumber}
                  placeholder='search order id'
                  inputSize='large'
                  onChange={(value) => filterByOrderNumber(value)}
                />
              </div>
              {adminChecker.allow(userSession.userSession) &&
                  <OrdoMultiSelect
                    options={viewModel.salesReps()}
                    onChangeSelectedOption={filterBySalesReps}
                    selectedOptions={viewModel.selectedSalesReps}
                    placeholder='select sales rep'
                  />}
              <OrdoMultiSelect
                options={viewModel.accounts()}
                onChangeSelectedOption={filterByAccounts}
                selectedOptions={viewModel.selectedAccounts}
                placeholder='select account'
              />
              <OrdoMultiSelect
                options={viewModel.ordersStatus()}
                onChangeSelectedOption={filterByStatuses}
                selectedOptions={viewModel.selectedStatuses}
                placeholder='select status'
              />
            </div>
            <div className='order-history-page-orders-container ordo-shadow ordo-card-border'>
              <OrderHistoryOrderList
                orders={viewModel.filteredOrders}
                noOrdersMessage={viewModel.noOrdersToDisplayMessage()}
                onUpdateOrder={updateOrder}
              />
              <div className='order-history-footer'>
                <OrdoPagination
                  currentPage={viewModel.page}
                  totalOfPages={viewModel.totalAmountOfPages()}
                  onPageChange={updatePage}/>
              </div>
            </div>
          </div>
        </div>
      </OrdoSpinner>
    );
  };

  return (
    inMobileResolution ?
      <MobileOrderHistory orderHistoryViewModel={viewModel}
        updateOrderHistoryViewModel={setViewModel}
        onStatusFilterChange={filterByStatuses}
        onPageUpdate={updatePage}
        onUpdateOrder={updateOrder}/> :
      webOrderHistory()
  );
};

export default withRouter(OrderHistoryPage);
