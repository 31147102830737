import React from 'react';
import OrdoButton from '../../components/common/OrdoButton';
import {OrganizationSubscriptionPlan} from './SubscriptionPlan';
import '../../../scss/ordo/loginPage/subscription-card.scss';
import {SubscriptionType} from '../../../models/Subscription';

type SubscriptionPlanProps = {
  plan: OrganizationSubscriptionPlan
  isSelected: boolean,
  onSelectPlan: () => void,
};

export const SubscriptionPlanCard = ({plan, isSelected, onSelectPlan}: SubscriptionPlanProps) => {

  const planUserLimit = () => {
    switch (plan.type) {
    case SubscriptionType.STARTER:
      return 'includes 1 user';
    case SubscriptionType.ENTERPRISE:
      return 'unlimited users';
    default:
      return `includes up to ${plan.userLimit} users`;
    }
  };
  return <div className={`card card-fluid ordo-card-border ordo-shadow subscription-plans-card ${isSelected ? 'selected' : ''}`}>
    <div className='subscription-plans-card__plan-title'>{plan.type}</div>
    <div className="subscription-plans-card__pricing">
      {plan.price > 0
        ?<>
          <div className='subscription-plans-card__pricing__plan-price'>
            <span className='dollar-sign'>$</span>{plan.price}
          </div>
          <div className='subscription-plans-card__pricing__plan-description'>
            <div>per month</div>
          </div>
        </>
        :
        <div className='subscription-plans-card__pricing__plan-description'>
        priced for teams of all sizes
        </div>
      }
    </div>
    <div className='subscription-plans-card__plan-amount'>
      {planUserLimit()}
    </div>
    <div className='subscription-plans-card__plan-features'>
      <div className='subscription-plans-card__plan-features-platform'>{plan.platformFeatures}</div>
    </div>
    {isSelected ?
      <OrdoButton
        disabled={false}
        text='current plan'
        category='light'
        onClick={() => {}}
        dataTestId={`selected-plan-button-${plan.type}`}
        style={{width: '9em', padding: '0.2em 1.5em', cursor: 'none'}}
      /> :
      <OrdoButton disabled={false}
        text='select'
        category='primary'
        onClick={onSelectPlan}
        dataTestId={`select-plan-button-${plan.type}`}
        style={{width: '9em', padding: '0.2em 1.5em'}}
      />
    }
    {plan.disclaimerText && <div className='subscription-plans-card__plan-disclaimer'>{plan.disclaimerText}</div>}
  </div>;
};
