import {API} from '../lib/api/ordoApi';
import isValidEmail from '../utils/string';

export default class InviteUsersViewModel {
  public email: string;
  private api: API;
  public errorMessage: string;

  constructor(email: string, api: API, errorMessage: string) {
    this.email = email;
    this.api = api;
    this.errorMessage = errorMessage;
  }

  updateEmail(email: string) {
    const validEmailInput = email === '' || isValidEmail(email);
    return new InviteUsersViewModel(email, this.api, !validEmailInput ? 'Please, provide a valid email' : '' );
  }

  isEmptyEmail() {
    return this.email === '';
  }

  isValidEmailInput() {
    return this.isEmptyEmail() || isValidEmail(this.email);
  };

  sendInvitations(organizationId: string) {
    return this.api.sendInvitations(organizationId, [this.email]);
  }

  public static emptyViewModel(api: API) {
    return new InviteUsersViewModel('', api, '');
  }

  isValidInvitation() {
    return !this.isEmptyEmail() && isValidEmail(this.email);
  }
};
