import { API } from '../../../lib/api/ordoApi';
import { Tier } from '../../../models/custom-pricing-tiers/Tier';

export class TierFormMode {
  constructor(public readonly tier: Tier) {
  }

  public async submit(_orgId: string, _tier: Tier, _api: API): Promise<Tier> {
    throw new Error('Must be implemented by subclass');
  }

  public successMessage(): string {
    throw new Error('Must be implemented by subclass');
  }

  public resultingArray(_tier: Tier, _tiers: Tier[]): Tier[] {
    throw new Error('Must be implemented by subclass');
  }
}



export class TierFormCreate extends TierFormMode{
  constructor() {
    super(new Tier('', [], [], [], false, []));
  }

  public async submit(orgId: string, tier: Tier, api: API): Promise<Tier> {
    return api.createCustomPricingTier(orgId, tier);
  }

  public successMessage(): string {
    return 'Pricing tier created successfully!';
  }

  public resultingArray(newTier: Tier, tiers: Tier[]): Tier[] {
    return [newTier,...tiers];
  }
}

export class TierFormEdit extends  TierFormMode {
  constructor(tier: Tier) {
    super(tier);
  }

  public async submit(orgId: string, tier: Tier, api: API): Promise<Tier> {
    return api.updateCustomPricingTier(orgId, tier);
  }

  public successMessage(): string {
    return 'Pricing tier updated successfully!';
  }

  public resultingArray(updatedTier: Tier, tiers: Tier[]): Tier[] {
    const index = tiers.findIndex((originalTier) => originalTier.id === updatedTier.id);
    tiers.splice(index, 1, updatedTier);
    return tiers;
  }
}