import React from 'react';
import OrdoInformativeSearchableDropdown
  from '../../../components/common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {AccountLocation} from '../../../../models/Account';
import OrdoDatepicker, {OrdoDatepickerDisplayMode, SelectableDates} from '../../../components/common/OrdoDatepicker';
import {DateFormatTemplate, OrdoDate} from '../../../../models/OrdoDate';

type MobileOrderCheckoutLocationAndDateProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateAccountLocation: (accountLocation: AccountLocation) => void,
  updateViewModel: (orderEntryViewModel: OrderEntryViewModel) => void,
}

export const MobileOrderCheckoutLocationAndDate = ({orderEntryViewModel, updateAccountLocation, updateViewModel} : MobileOrderCheckoutLocationAndDateProps) => {

  const updateDeliveryDay = (date: Date) => {
    updateViewModel(orderEntryViewModel.updateDeliveryDay(OrdoDate.from(date)));
  };

  const selectedDeliveryDay = () => {
    if (orderEntryViewModel.selectedDeliveryDay) {
      const date = OrdoDate.from(orderEntryViewModel.selectedDeliveryDay);
      return date.format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY );
    }
    return undefined;
  };
  return (
    <div className="mobile-order-entry-header__selector">
      <OrdoInformativeSearchableDropdown
        dataTestId='select-location'
        selectedOption={orderEntryViewModel.getSelectedAccountLocation()}
        options={orderEntryViewModel.getSelectableAccountLocations()}
        onChangeSelectedOption={updateAccountLocation}
        placeholder='select a location'
        addedStyles={{
          marginBottom: '0', valueContainer: {padding: '0'},
          control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
        }}
      />
      <div className="select-delivery-date">
        <OrdoDatepicker
          onChange={updateDeliveryDay}
          displayTime={false}
          onlyTime={false}
          value={orderEntryViewModel.selectedDeliveryDay}
          displayMode={OrdoDatepickerDisplayMode.SINGLE_LINE}
          validDates={SelectableDates.ALL_TIME}
        />
        <div className="selected-date-text">{selectedDeliveryDay()}</div>
      </div>
    </div>);
};
