import React, {PropsWithChildren, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import '../../../scss/ordo/ordo-popup.scss';

type PopupProps = {
  popupSide: 'left' | 'right',
  sidePosition: string,
  verticalPosition: string,
  popupVerticalPlacement: 'top' | 'bottom',
  onClose: Function,
  title: string
} & PropsWithChildren<any>;

export function OrdoPopup({
  popupSide,
  sidePosition,
  popupVerticalPlacement,
  verticalPosition,
  onClose,
  title,
  children,
  id
}: PopupProps) {
  const handleClickOutside = (e: any)=> {
    const elementById = document.getElementById(id);
    if (!(elementById && elementById.contains(e.target))){
      onClose();
    }
  };

  useEffect( ()=> {
    // we want the popup to close when we click outside it
    window.addEventListener('click', handleClickOutside);
    // we also need to remove the listener when the component unmounts
    return () => window.removeEventListener('click', handleClickOutside);
  },[]);

  return <div role="presentation" className="ordo-popup" id={id}
    style={{[popupSide]: sidePosition, [popupVerticalPlacement]: verticalPosition}} onClick={(event) => {
      event.stopPropagation();
    }}>
    <div className='header'>
      <span className='title'>{title}</span>
      <FontAwesomeIcon onClick={onClose} icon={faTimes}/>
    </div>
    {children}
  </div>;
}
