import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faEnvelope, faPhoneAlt, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import Contact from '../../../models/order-entry/Contact';
import '../../../scss/ordo/salesActivity/contact-details.scss';
import ContactModalViewModel from '../../../application-models/order-entry/ContactModalViewModel';
import {API} from '../../../lib/api/ordoApi';
import {useAPI} from '../../../context/OrdoApiContext';
import {ContactForm} from '../../components/contacts/ContactForm';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import OrdoSpinner from '../../components/OrdoSpinner';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import MobileDisplayAsModal from '../../components/common/MobileDisplayAsModal';


type ContactDetailsRowProps = { orgId: string, contact: Contact, onContactDelete: (contact: Contact) => void, onContactUpdate: (contact: Contact) => void };
export const ContactDetailsRow = ({contact, onContactDelete, onContactUpdate, orgId}: ContactDetailsRowProps) => {
  const api: API = useAPI();
  const {showSpinner, hideSpinner, spinnerVisibility} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();

  const [openEditContactForm, setOpenEditContactForm] = useState(false);
  const [openedDeleteContactModal, setOpenedDeleteContactModal] = useState(false);
  const [addContactViewModel, setAddContactViewModel] = useState(new ContactModalViewModel(
    api, contact.name, contact.email, contact.title || '', contact.phone));

  const toggleEditContactForm = () => setOpenEditContactForm(!openEditContactForm);
  const openDeleteContactModal = () => setOpenedDeleteContactModal(true);
  const closeDeleteContactModal = () => setOpenedDeleteContactModal(false);

  const updateContact = () => {
    showSpinner();
    addContactViewModel.updateContact(contact, orgId)
      .then((updatedContact: Contact) => {
        hideSpinner();
        successToast('contact successfully updated');
        setOpenEditContactForm(false);
        onContactUpdate(updatedContact);
      })
      .catch(() => {
        errorToast('could not update the contact');
        hideSpinner();
      });
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="contact-row">
      <div className="expanded-contact-row">
        <div className="contact-name-and-position">
          <span className="name">{contact.name}</span>
          {!!contact.title && <span className="position">{contact.title}</span>}
        </div>
        {(!!contact.email || !!contact.phone) && <div className="contact-email-and-phone">
          {!!contact.email && <div className="email-or-phone">
            <FontAwesomeIcon size="lg" icon={faEnvelope}/>
            <span>{contact.email}</span>
          </div>}
          {!!contact.phone && <div className="email-or-phone">
            <FontAwesomeIcon size="lg" icon={faPhoneAlt}/>
            <span>{contact.phone}</span>
          </div>}
        </div>}
        <div className="contact-edit-and-delete">
          <FontAwesomeIcon className="edit-icon" onClick={toggleEditContactForm} size="lg" icon={faEdit}/>
          <FontAwesomeIcon className="delete-icon" onClick={openDeleteContactModal} size="lg" icon={faTrashAlt}/>
        </div>
      </div>
      {openEditContactForm &&
      <MobileDisplayAsModal title="edit a contact" onClose={() => setOpenEditContactForm(false)} show={openEditContactForm}>
        <div className="edit-form-container">
          <ContactForm viewModel={addContactViewModel} updateViewModel={setAddContactViewModel}
            onCancel={() => setOpenEditContactForm(false)}
            onSubmit={() => updateContact()}/>
        </div>
      </MobileDisplayAsModal>
      }
      {openedDeleteContactModal &&
      <ConfirmationModal
        show={openedDeleteContactModal}
        onClose={closeDeleteContactModal}
        onSubmit={onContactDelete}
        confirmationText={`are you sure you want to delete the contact ${contact.name}?`}
        actionText='delete'
      />}
    </div>
  </OrdoSpinner>;
};
