import {AccountTimeline} from '../../models/AccountTimeline';
import {ActualMember} from '../../models/member/ActualMember';

export class TimelineFilter {

  public static empty() {
    return new TimelineFilter('', []);
  }

  constructor(public readonly accountNameSearch: string, public readonly selectedSalesReps: ActualMember[]) {
  }

  public updateAccountSearch(value: string) {
    return new TimelineFilter(value, this.selectedSalesReps);
  }

  public updateSelectedSalesReps(salesReps: ActualMember[]) {
    return new TimelineFilter(this.accountNameSearch, salesReps);
  }

  private applyNameSearch(list: AccountTimeline[]) {
    if (!this.accountNameSearch) return list;

    const searchString = this.accountNameSearch.toLowerCase();
    return list.filter(a => a.name.toLowerCase().includes(searchString));
  }

  private applySalesRepsFilter(list: AccountTimeline[]) {
    if (this.selectedSalesReps.length === 0) return list;

    return list.filter(a => a.salesReps.some(user => this.selectedSalesReps.map(sr => sr.id).includes(user.id)));
  }

  public apply(accountTimelines: AccountTimeline[]) {
    const filteredTimelines = this.applyNameSearch(accountTimelines);
    return this.applySalesRepsFilter(filteredTimelines);
  }
}