import React, {PropsWithChildren, ReactNode, useContext} from 'react';
import {API} from '../lib/api/ordoApi';

export const OrdoApiContext = React.createContext<API | null>(null);

export function useAPI() : API {
  const api = useContext(OrdoApiContext);
  if (!api) {
    throw new Error('API is null');
  }
  return api;
}

type OrdoApiContextProps = {api: API} & PropsWithChildren<ReactNode>

const OrdoApiContextProvider = ({api, children}: OrdoApiContextProps) => {
  return (<OrdoApiContext.Provider value={api}>
    {children}
  </OrdoApiContext.Provider>);
};

export default OrdoApiContextProvider;
