import React, { useState } from 'react';
import OrdoModal from '../common/OrdoModal';
import {ActivityCompleteWizard} from '../activities/ActivityCompleteWizard';
import {Activity} from '../../../models/sales-activity/Activity';
import { ActivityCompletionResponse } from '../../../models/activity/responses/ActivityCompletionResponse';


type ActivityCompleteModalProps = {
  activity: Activity,
  isOpen: boolean,
  onClose: Function,
  onSubmit:  (responses: ActivityCompletionResponse[])=> void,
};

export const ActivityCompleteModal = ({activity, isOpen, onSubmit, onClose}: ActivityCompleteModalProps) => {
  const[wideDisplay, setWideDisplay] =  useState(false);

  return (
    <OrdoModal title='' onClose={onClose} onSubmit={()=>{}} show={isOpen} showFooter={false} className={wideDisplay ? 'wide-modal': ''}>
      <ActivityCompleteWizard activity={activity} onSubmit={onSubmit} onCancel={onClose} setWideDisplay={setWideDisplay}/>
    </OrdoModal>
  );
};