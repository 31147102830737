import { API } from '../lib/api/ordoApi';
import { CreateAccountAssignmentRequest } from '../lib/api/request-types';
import { AccessibleBrand } from '../models/Assignment';
import { Organization } from '../models/Organization';
import Account from '../models/Account';
import {ActualMember} from '../models/member/ActualMember';

export default class RepAssignmentViewModel {
  public readonly api: API
  public readonly selectedMember: ActualMember | null
  public readonly currentOrg: Organization | undefined
  public readonly accessibleBrands: AccessibleBrand[]
  public readonly accounts: Account[]
  public readonly error: Error | undefined;

  constructor(
    api: API,
    selectedMember: ActualMember | null,
    currentOrg: Organization | undefined,
    accessibleBrands: AccessibleBrand[],
    accounts: Account[],
    error: Error | undefined = undefined
  ) {
    this.api = api;
    this.selectedMember = selectedMember;
    this.currentOrg = currentOrg;
    this.accessibleBrands = accessibleBrands;
    this.accounts = accounts;
    this.error = error;
  }

  public static empty(api: API) {
    return new RepAssignmentViewModel(
      api,
      null,
      undefined,
      [],
      []
    );
  }


  public static async initialize(api: API, currentOrgId: string, selectedAccounts: Account[]) {
    try {
      const org = await api.getOrganization(currentOrgId);
      const accessibleBrands: AccessibleBrand[] = org.brands.map((brand) => {
        return {
          ...brand,
          disabled: false
        };
      });

      return new RepAssignmentViewModel(
        api,
        null,
        org,
        accessibleBrands,
        selectedAccounts,
      );
    } catch (error) {
      return new RepAssignmentViewModel(
        api,
        null,
        undefined,
        [],
        [],
        new Error('Unable to get assignments. Please try again by refreshing the page.')
      );
    }
  }

  public selectMember(member: ActualMember) {
    return new RepAssignmentViewModel(
      this.api,
      member,
      this.currentOrg,
      this.accessibleBrands,
      this.accounts
    );
  }

  public toggleBrand(brandId: string) {
    const brands = this.accessibleBrands.map((brand) => {
      let { disabled } = brand;
      if (brand.id === brandId) {
        disabled = !disabled;
      }
      return {
        ...brand,
        disabled: disabled
      };
    });

    return new RepAssignmentViewModel(
      this.api,
      this.selectedMember,
      this.currentOrg,
      brands,
      this.accounts,
    );
  }

  public async submitAccountAssignment() {
    try {
      const req: CreateAccountAssignmentRequest = {
        userId: this.selectedMember!.user!.id,
        accountsIds: this.accounts
          .map((account) => account.id),
        disabledBrandIds: this.disabledBrands().map((brand) => brand.id)
      };
      await this.api.assignAccounts(this.currentOrg!.id, req);
      return this;
    } catch (error) {
      return new RepAssignmentViewModel(
        this.api,
        this.selectedMember,
        this.currentOrg,
        this.accessibleBrands,
        this.accounts,
        new Error('Unable to submit account assignment. Please refresh the page and try again.')
      );
    }
  }

  public disabledBrands() {
    return this.accessibleBrands.filter((brand) => brand.disabled);
  }

  public clearError() {
    return new RepAssignmentViewModel(
      this.api,
      this.selectedMember,
      this.currentOrg,
      this.accessibleBrands,
      this.accounts,
      undefined
    );
  }
}

