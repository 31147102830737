import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {AccountTimeline, ActivityTimelineItem, NoteTimelineItem} from '../../models/AccountTimeline';
import {TimelineSlotContent} from './TimelineSlotContent';
import OrdoCardOptionsDropdown from '../components/common/OrdoCardOptionsDropdown';
import {TimePeriod} from '../../models/TimePeriod';
import {FutureTimeSlot} from '../../models/TimeSlot';
import NoteViewModel from '../../application-models/sales-activity/NoteViewModel';
import {useAPI} from '../../context/OrdoApiContext';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import {Note} from '../../models/Note';
import OrdoSpinner from '../components/OrdoSpinner';
import OrdoModal from '../components/common/OrdoModal';
import {NoteForm} from '../components/notes/NoteForm';
import {UserSessionContext} from '../../context/UserSessionContext';
import {Activity, ActivityTemplate} from '../../models/sales-activity/Activity';
import {ActivityForm} from '../components/activities/ActivityForm';
import ActivityViewModel from '../../application-models/sales-activity/ActivityViewModel';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {OrderEntryCartContext} from '../../context/OrderEntryCartContext';
import {TimelineSlot} from './TimelineSlot';
import {ActivityCompleteModal} from '../components/modals/ActivityCompleteModal';
import ConfirmationModal from '../components/common/ConfirmationModal';
import {useFeatureFlags} from '../../context/FeatureFlagsContext';
import {displayPlaceAnOrderAction} from '../../lib/featureFlags';

type AccountTimelineRowProps = {
  account: AccountTimeline,
  timePeriod: TimePeriod,
  popupVerticalPlacement: 'top' | 'bottom',
  activityTemplates: ActivityTemplate[],
  eventsToDisplay: string[]
};

enum ActivityAction {
  ACTIVITY_FORM,
  COMPLETE,
  MARK_AS_PENDING
}

export const AccountTimelineRow = ({account, timePeriod, popupVerticalPlacement, activityTemplates, eventsToDisplay}: AccountTimelineRowProps) => {

  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const orderEntryCartContextData = useContext(OrderEntryCartContext);
  const history = useHistory();
  const featureFlags = useFeatureFlags();

  const orgId = userSession.currentOrganization()?.id || '';
  const timelineSections = timePeriod.sections;
  const [items, setItems] = useState(account.items);
  const [itemsToDisplay, setItemsToDisplay] = useState(items.filter(item => item.shouldBeDisplayed(eventsToDisplay)));
  const [noteFormData, setNoteFormData] = useState<{noteVM: NoteViewModel, noteId?: string} | null>(null);
  const [activityFormData, setActivityFormData] = useState<{activityVM: ActivityViewModel, activity?: Activity, action: ActivityAction} | null>(null);

  useEffect( () => {
    const timelineItems = items.filter(item => item.shouldBeDisplayed(eventsToDisplay));
    setItemsToDisplay(timelineItems);
  }, [items, eventsToDisplay]);

  const {showSpinner, hideSpinner, spinnerVisibility} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();

  const closeNoteForm = ()=>setNoteFormData(null);
  const closeActivityForm = ()=>setActivityFormData(null);

  const createNote = () => {
    showSpinner();
    noteFormData!.noteVM.createNote(orgId, account.accountId)
      .then((note) => {
        setItems([...items, new NoteTimelineItem(note)]);
        hideSpinner();
        successToast('note successfully created');
        closeNoteForm();
      })
      .catch(() => {
        errorToast('could not create the note');
        hideSpinner();
      });
  };
  const updateNote = () => {
    showSpinner();
    noteFormData!.noteVM.updateNote(orgId, account.accountId, noteFormData!.noteId!)
      .then((updatedNote: Note) => {
        const index = items.findIndex(value => value.id() === updatedNote.id);
        items.splice(index, 1, new NoteTimelineItem(updatedNote));
        setItems([...items]);
        hideSpinner();
        successToast('note successfully updated');
        closeNoteForm();
      })
      .catch(() => {
        errorToast('could not update the note');
      })
      .finally(hideSpinner);
  };
  const submitNote = () => {
    if(noteFormData) {
      if(noteFormData.noteId) {
        updateNote();
      } else {
        createNote();
      }
    }
  };

  const placeOrder = () => {
    orderEntryCartContextData.clearCart();
    history.push(ROUTE_NAMES.ORDER_ENTRY, {accountId: account.accountId});
  };

  const goToAccountPage = () => {
    history.push(ROUTE_NAMES.ACCOUNT_PAGE.replace(':accountId', account.accountId));
  };

  const createActivity = () => {
    showSpinner();
    activityFormData!.activityVM.createActivity(account.accountId, orgId)
      .then((activity) => {
        setItems([...items, new ActivityTimelineItem(activity)]);
        hideSpinner();
        successToast('activity successfully created');
        closeActivityForm();
      })
      .catch(() => {
        errorToast('could not create the activity');
        hideSpinner();
      });
  };
  const updateActivity = () => {
    showSpinner();
    activityFormData!.activityVM.updateActivity(orgId, {accountId: account.accountId, id: activityFormData!.activity!.id} as Activity)
      .then((updatedActivity: Activity) => {
        const index = items.findIndex(value => value.id() === updatedActivity.id);
        items.splice(index, 1, new ActivityTimelineItem(updatedActivity));
        setItems([...items]);
        hideSpinner();
        successToast('activity successfully updated');
        closeActivityForm();
      })
      .catch(() => {
        errorToast('could not update the activity');
      })
      .finally(hideSpinner);
  };
  const submitActivity = () => {
    if(activityFormData) {
      if(activityFormData.activity) {
        updateActivity();
      } else {
        createActivity();
      }
    }
  };

  const activityCompletionCancelled = (activity: Activity) => {
    // eslint-disable-next-line no-param-reassign
    activity.completed = !activity.completed;
    closeActivityForm();
  };

  const onActivityUpdated = (activity: Activity) => {
    const activityIndex = items.findIndex(value => value.id() === activity.id);
    const [oldActivityItem] = items.splice(activityIndex, 1, new ActivityTimelineItem(activity));
    setItems([...items]);
    const vm = new ActivityViewModel(api, {
      accountId: activity.accountId,
      description: activity.description,
      contacts: activity.contacts,
      date: activity.date,
      time: activity.time,
      type: activity.type,
      completed: activity.completed,
      assignedSalesRep: activity.assignedSalesRep,
      activityTemplate: activity.activityTemplate,
      responses: activity.responses
    }, activityTemplates, [{...account, assignments: account.salesReps}], account.salesReps, {...account, assignments: account.salesReps},undefined);
    vm.updateActivity(orgId, {accountId: account.accountId, id: activity.id} as Activity)
      .then(()=> {
        closeActivityForm();
      })
      .catch(() => {
        errorToast('Could not update the activity');
        items.splice(activityIndex, 1, oldActivityItem);
        setItems([...items]);
      });
  };

  const actionsOptions = () => {
    const options: any[] = [
      {
        name: 'add activity',
        action: ()=>setActivityFormData({activityVM: new ActivityViewModel(api,{
          accountId: account.accountId,
          description: '',
          contacts: [],
          date: null,
          type: null,
          completed: false,
          assignedSalesRep: null,
          activityTemplate: null
        }, activityTemplates,[{...account, assignments: account.salesReps}], account.salesReps, {...account, assignments: account.salesReps}, [{...account, assignments: account.salesReps}]), action: ActivityAction.ACTIVITY_FORM})
      },
      {
        name: 'add note',
        action: ()=>setNoteFormData({noteVM: NoteViewModel.empty(api)}),
      }
    ];

    if (displayPlaceAnOrderAction(featureFlags)) {
      options.push({
        name: 'place an order',
        action: () => placeOrder(),
        disabled: !account.orderEntryEnabled,
      });
    }

    return options;
  };

  const halfAmountOfSlots = timePeriod.slots / 2;
  const openEditNoteModal = (note: Note) => setNoteFormData({
    noteVM: new NoteViewModel(api, note.title, note.description, note.contacts),
    noteId: note.id
  });
  const openEditActivityModal = (activity: Activity) => setActivityFormData({
    activityVM: new ActivityViewModel(api, {
      accountId: activity.accountId,
      description: activity.description,
      contacts: activity.contacts,
      date: activity.date,
      time: activity.time,
      type: activity.type,
      completed: activity.completed,
      activityTemplate: activity.activityTemplate,
      assignedSalesRep: activity.assignedSalesRep,
      responses: activity.responses
    }, activityTemplates, [{...account, assignments: account.salesReps}], account.salesReps, {...account, assignments: account.salesReps}, undefined), activity: activity, action: ActivityAction.ACTIVITY_FORM
  });

  const onActivityCheckboxClicked = (activity: Activity) => {
    if(activity.activityTemplate!.questions.length < 1) {
      onActivityUpdated(activity);
      return;
    }
    setActivityFormData({
      activityVM: new ActivityViewModel(api, {
        accountId: activity.accountId,
        description: activity.description,
        contacts: activity.contacts,
        date: activity.date,
        time: activity.time,
        type: activity.type,
        completed: activity.completed,
        activityTemplate: activity.activityTemplate,
        assignedSalesRep: activity.assignedSalesRep
      }, activityTemplates, [{...account, assignments: account.salesReps}],account.salesReps, {...account, assignments: account.salesReps},undefined),
      activity: activity,
      action: activity.completed ? ActivityAction.COMPLETE : ActivityAction.MARK_AS_PENDING
    });
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="account-row" key={account.accountId} id={account.accountId}>
      <div className="account-name" title={account.name}>
        <span  onClick={()=> goToAccountPage()} role='presentation'> {account.name} </span>
      </div>
      <div className="account-timeline">
        <div className="past-events">
          <div className="background-line"/>
          {timelineSections.map((timelineSection, index) => {
            const timeSlots = timelineSection.slots;
            const key = `${index}-${timeSlots.length}`;
            const sectionWidth = 100 * timelineSection.width();
            const slotWidth = 100 / timeSlots.length;
            return <div className="timeline-period" style={{maxWidth: `${sectionWidth}%`, width: `${sectionWidth}%`}} key={key}>
              {
                timeSlots.map(slot => {
                  return <TimelineSlot key={slot.start.toDate().toDateString() + account.accountId}
                    width={slotWidth}
                    slot={slot} items={itemsToDisplay}
                    popupSide={(slot.index < halfAmountOfSlots) ? 'right' : 'left'}
                    popupVerticalPlacement={popupVerticalPlacement}
                    onEditNote={openEditNoteModal}
                    onEditActivity={openEditActivityModal}
                    onActivityCheckboxClicked={onActivityCheckboxClicked}
                    accountName={account.name}
                  />;
                })
              }
            </div>;
          })}
        </div>
        <div className="upcoming">
          <div className="line-future"/>
          <div className="content">
            <TimelineSlotContent content={new FutureTimeSlot(timePeriod.end()).slotContent(itemsToDisplay)}
              popupSide='left' popupVerticalPlacement={popupVerticalPlacement}
              onEditNote={openEditNoteModal}
              onEditActivity={openEditActivityModal}
              onActivityCheckboxClicked={onActivityCheckboxClicked}
              accountName={account.name}
            />
          </div>
        </div>
      </div>
      <div className='options'>
        <OrdoCardOptionsDropdown options={actionsOptions()}/>
      </div>
    </div>
    {!!noteFormData &&
    <OrdoModal title={noteFormData.noteId ? 'edit a note' : 'add a note'}
      onClose={closeNoteForm} show={!!noteFormData} showFooter={false}>
      <div className="edit-form-container">
        <NoteForm
          viewModel={noteFormData.noteVM}
          updateViewModel={(vm)=> setNoteFormData({...noteFormData, noteVM: vm})}
          onSubmit={() => submitNote()}
          onCancel={closeNoteForm}
          accountContacts={account.contacts || []}
        />
      </div>
    </OrdoModal>}
    {!!activityFormData && activityFormData.action === ActivityAction.ACTIVITY_FORM && <OrdoModal title={activityFormData.activity ? 'edit an activity' : 'add an activity'}
      onClose={closeActivityForm} show={!!activityFormData} showFooter={false}>
      <div className="add-form-container">
        <div className="add-form">
          <ActivityForm
            selectedAccountId={account.accountId}
            accountsWithContacts={[{...account, assignments: account.salesReps}]}
            viewModel={activityFormData.activityVM}
            updateViewModel={(vm) => setActivityFormData({...activityFormData, activityVM: vm})}
            onSubmit={()=>submitActivity()}
            onCancel={closeActivityForm}
            accountContacts={account.contacts}
            canEditSalesRep={!activityFormData.activity}
            canEditAccount={false}
          />
        </div>
      </div>
    </OrdoModal>}
    {!!activityFormData && activityFormData.action === ActivityAction.COMPLETE  && <ActivityCompleteModal
      activity={activityFormData.activity!}
      isOpen
      onClose={()=>activityCompletionCancelled(activityFormData.activity!)}
      onSubmit={(responses)=>onActivityUpdated({...activityFormData.activityVM.toActivity(orgId, activityFormData.activity!.id), completed: true, responses: responses})}/>
    }
    {!!activityFormData && activityFormData.action === ActivityAction.MARK_AS_PENDING && <ConfirmationModal
      show
      onClose={() => activityCompletionCancelled(activityFormData.activity!)}
      onSubmit={() => onActivityUpdated({...activityFormData.activityVM.toActivity(orgId, activityFormData.activity!.id), completed: false, responses: undefined})}
      confirmationText="marking the activity as incomplete will delete any responses, are you sure you want to continue?"
      actionText='mark incomplete'
    />}
  </OrdoSpinner>;
};

export default AccountTimelineRow;
