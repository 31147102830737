import L, {GeoJSON, Layer, Map} from 'leaflet';
import * as californiaCounties from '../../pages/accounts/maps/States/CA_counties_geodata.json';
import * as illinoisCounties from '../../pages/accounts/maps/States/IL_counties_geodata.json';
import * as newYorkCounties from '../../pages/accounts/maps/States/NY_counties_geodata.json';
import * as nevadaCounties from '../../pages/accounts/maps/States/NV_counties_geodata.json';
import * as oklahomaCounties from '../../pages/accounts/maps/States/OK_counties_geodata.json';
import * as michiganCounties from '../../pages/accounts/maps/States/MI_counties_geodata.json';
import * as arizonaCounties from '../../pages/accounts/maps/States/AZ_counties_geodata.json';
import * as massachusettsCounties from '../../pages/accounts/maps/States/MA_counties_geodata.json';
import AccountsViewModel from '../../application-models/AccountsPageViewModel';
import {colorInGradient} from '../../utils/color';
import {PRIMARY_COLOR} from '../../constants';
import { Organization } from '../Organization';

const BASE_COLOR = '#f3faf8';
const SELECTED_COLOR = '#2b8f64';
const countyLabelClass = 'county-label';
const originalFontSize = '0.7em';

const stateMaps = {
  'CA': ()=>californiaCounties,
  'NV': ()=>nevadaCounties,
  'IL': ()=>illinoisCounties,
  'NY': ()=>newYorkCounties,
  'OK': ()=>oklahomaCounties,
  'MI': ()=>michiganCounties,
  'AZ': ()=>arizonaCounties,
  'MA': ()=>massachusettsCounties,
};

export class OrdoMap {
  private countiesLayerGroup: GeoJSON | null = null;
  private map: Map | null = null;
  private mostLocationsInACounty: number = 0;

  constructor(private mapId: string) {
  }

  public countyShapeStyle(feature: any, viewModel: AccountsViewModel) {
    const countyName = feature.properties.name;
    const percentage = this.mostLocationsInACounty > 0 ? viewModel.getAmountOfLocationsInCounty(countyName)/this.mostLocationsInACounty : 0.5;
    const isSelectedCounty = viewModel.isFilteringByCounty(countyName);
    return {
      // If the county is not selected we choose its color based on how many accounts it has
      // compared to the maximum amount of accounts in a county, following a gradient.
      fillColor: isSelectedCounty ? SELECTED_COLOR : colorInGradient(BASE_COLOR, PRIMARY_COLOR, percentage),
    };
  }

  private addLabelAndEvents(feature: any, layer: Layer, viewModel: AccountsViewModel, setViewModel: Function) {
    const countyName = feature.properties.name;
    // We add a permanent tooltip to act as a label for the county
    layer.bindTooltip(countyName,  {
      permanent: true,
      direction : 'center',
      className: countyLabelClass,
    }).openTooltip();
    layer.on({click: async (event) => {
      event.originalEvent.preventDefault();
      const accountsViewModel = await viewModel.toggleCountyInFilter(countyName);
      // When a state is selected we need to recalculate the styles
      this.updateCountiesStyles(accountsViewModel);
      setViewModel(accountsViewModel);
    }});
  }

  public updateCountiesStyles(viewModel: AccountsViewModel) {
    if(this.countiesLayerGroup) {
      this.countiesLayerGroup.setStyle((feature) => this.countyShapeStyle(feature!, viewModel));
    }
  }

  public initialize(viewModel: AccountsViewModel, setViewModel: Function, currentOrganization: Organization) {
    if(this.map) this.map.remove();

    this.mostLocationsInACounty = viewModel.maxLocationsInCounty();


    // @ts-ignore
    const stateCounties = stateMaps[currentOrganization.state]() || californiaCounties;
    // This is hardcoded to use the california map, eventually we can parametrize it to use
    // whichever state we want
    // @ts-ignore
    this.countiesLayerGroup = L.geoJSON(stateCounties.default,
      {
        // sets the style that each 'feature' (each county) should be initialized with
        style:(feature) => this.countyShapeStyle(feature, viewModel),
        // lets you add behaviour and other components to each feature
        onEachFeature: (feature, layer)=> this.addLabelAndEvents(feature, layer, viewModel, setViewModel)
      });

    this.map = L.map(this.mapId).setView(this.countiesLayerGroup.getBounds().getCenter(), 6);

    // This prevents weird behaviour on some browsers
    this.map.getContainer().focus = () => {};

    this.countiesLayerGroup.addTo(this.map);
    // This sets the bounds of the map so that the user can't scroll away infinitely
    this.map.setMaxBounds(this.countiesLayerGroup.getBounds());

    // This calculates which counties labels to display depending on zoom to avoid cluttering
    this.hideOverlappingTooltips();
    this.map.on('zoomend', () => {
      this.hideOverlappingTooltips();
    });
  }

  private checkOverlap(rect1: any, rect2: any) {
    return(!(rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom));
  }

  private hideOverlappingTooltips() {
    const tooltips = document.getElementsByClassName(countyLabelClass);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tooltips.length; i++) {
      // eslint-disable-next-line no-plusplus
      const tooltip = tooltips[i] as HTMLElement;
      tooltip.style.visibility = '';
      tooltip.style.fontSize = originalFontSize;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < tooltips.length; i++) {
      const tooltip = tooltips[i] as HTMLElement;
      if (tooltip.style.visibility !== 'hidden') {
        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < tooltips.length; j++) {
          const nextTooltip = tooltips[j] as HTMLElement;
          const longTooltip = (nextTooltip.textContent?.length || 0) > (tooltip.textContent?.length || 0) ? nextTooltip : tooltip;
          const shortTooltip = nextTooltip === longTooltip ? tooltip : nextTooltip;
          const longTooltipFontsize = longTooltip.style.fontSize;
          if (this.checkOverlap(tooltip.getBoundingClientRect(), nextTooltip.getBoundingClientRect())) {
            longTooltip.style.fontSize = '0.6em';
            shortTooltip.style.fontSize = '0.6em';
          }
          if (this.checkOverlap(tooltip.getBoundingClientRect(), nextTooltip.getBoundingClientRect())) {
            shortTooltip.style.visibility = 'hidden';
            longTooltip.style.fontSize = longTooltipFontsize;
          }
        }
      }
    }
  }
}
