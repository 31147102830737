import {PRIMARY_COLOR} from '../../../constants';

export const customPriceEditorDropdownStyles = (lineHeight?: number, borderColor: string = PRIMARY_COLOR, dropdownHeight: string = '2em') => ({
  control: {
    border: `1px solid ${borderColor}`,
    boxShadow: 'none',
    height: dropdownHeight,
    minHeight: dropdownHeight,
    '&:hover': {},
  },
  valueContainer: {
    height: dropdownHeight,
    minHeight: dropdownHeight,
    paddingTop: '0',
    lineHeight: lineHeight || 2
  },
  indicatorsContainer: {
    minHeight: dropdownHeight,
    height: dropdownHeight
  },
});

