import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import {OrderSummary} from '../../models/order-entry/Summary';
import {API} from '../../lib/api/ordoApi';
import {OrderStatus} from '../../models/order-entry/Order';
import {fullName} from '../../models/User';

class OrderInformationViewModel {

  public constructor(
    public order: OrderWithCurrentVersion,
    public api: API
  ) {}

  public orderStatusToString(): string {
    return this.order.statusToString();
  }

  public orderFormattedDate(): string {
    return this.order.formattedDate();
  }

  public orderFormattedDeliveryDate(): string {
    return this.order.formattedDeliveryDate();
  }

  public orderFormattedSalesValue(): string {
    return this.order.salesValue.formatted();
  }

  public currentVersionSummary(): OrderSummary {
    return this.order.summary;
  }

  public getOrder(): OrderWithCurrentVersion {
    return this.order;
  }

  public accountName(): string {
    return this.order.accountName;
  }

  public orderNumber(): string {
    const {orderNumber} = this.order;
    const minimumLength = 10;
    const paddedOrderNumber = orderNumber.padStart(minimumLength,'0');
    return [paddedOrderNumber.substring(0, minimumLength/2), paddedOrderNumber.substring(minimumLength/2)].join('-');
  }

  public salesRepFullName(): string {
    return this.order.salesRepName;
  }

  public contactName(): string {
    return this.order.contactName;
  }

  public async updateOrderStatus(id: string, organizationId: string, status: OrderStatus) {
    let order;
    if(status === OrderStatus.Delivered) {
      order = await this.api.verifyOrderDelivery(organizationId, id);
    } else {
      order = await this.api.updateOrderStatus(organizationId, id, status);
    }

    return new OrderInformationViewModel(order, this.api);
  }

  public updateOrderStatusDisplay(status: OrderStatus) {
    this.order.status = status;
    return new OrderInformationViewModel(this.order, this.api);
  }

  public isPending(): boolean {
    return this.order.isPending();
  }

  public isTracked(): boolean {
    return this.order.isTracked;
  }

  public invoiceURL(): string {
    return this.order.currentVersion.invoiceUrl;
  }

  public assignedSalesRepName(): string {
    return fullName(this.order.currentVersion.assignedSalesRep);
  }
}

export default OrderInformationViewModel;
