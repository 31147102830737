import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDollarSign, faPercent} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import OrdoInput from '../components/common/inputs/OrdoInput';
import {SalesActivityAccount} from './salesActivityTypes';
import {API} from '../../lib/api/ordoApi';
import {useAPI} from '../../context/OrdoApiContext';
import useOrdoToasts from '../../hooks/useOrdoToasts';

type AccountInfoProps = {
  account: SalesActivityAccount,
  orgId: string,
  onInformationChange: () => any,
  showValue: boolean
}

export const AccountInfo = ({account, orgId, onInformationChange, showValue}: AccountInfoProps) => {
  const api: API = useAPI();
  const [cardAccount, setCardAccount] = useState(account);
  const [accountStalePeriod, setAccountStalePeriod] = useState<number>(account.stalePeriod);
  const [accountConversion, setAccountConversion] = useState<number>(account.conversion);
  const [accountValue, setAccountValue] = useState<number>(account.value);
  const {successToast, errorToast} = useOrdoToasts();

  const isValidStalePeriod = (stalePeriod: number) => {
    return stalePeriod > 0;
  };

  const isValidAccountConversion = (conversion: number) => {
    return conversion >= 0;
  };

  const isValidAccountValue = (stalePeriod: number) => {
    return stalePeriod >= 0;
  };

  const updateStalePeriod = async (stalePeriod: string) => {
    const stalePeriodToNumber = parseInt(stalePeriod,10);
    if(stalePeriodToNumber !== cardAccount.stalePeriod && stalePeriod !== '') {
      setAccountStalePeriod(stalePeriodToNumber);
      if (isValidStalePeriod(stalePeriodToNumber)) {
        api.updateAccountStalePeriod(orgId, account.id, stalePeriodToNumber)
          .then((updatedAccount) => {
            setAccountStalePeriod(updatedAccount.stalePeriod);
            successToast('Stale period successfully updated');
            onInformationChange();
          })
          .catch(() => errorToast('Could not update stale period'));
      }
    } else {
      setAccountStalePeriod(cardAccount.stalePeriod);
    }
  };

  const updateAccountConversion = async (conversion: string) => {
    const conversionToNumber = parseInt(conversion,10);
    if(conversionToNumber !== cardAccount.conversion && (conversion !== '')) {
      setAccountConversion(conversionToNumber);
      if (isValidAccountConversion(conversionToNumber)) {
        api.updateAccountConversion(orgId, account.id, conversionToNumber)
          .then((updatedAccount) => {
            setAccountConversion(updatedAccount.conversion);
            successToast('Account conversion successfully updated');
            onInformationChange();
          })
          .catch(() => errorToast('Could not update conversion'));
      };
    } else {
      setAccountConversion(cardAccount.conversion);
    }
  };

  const updateAccountValue = async (value: string) => {
    const valueToNumber = parseFloat(value);
    if(valueToNumber !== cardAccount.value && value !== '') {
      setAccountValue(valueToNumber);
      if (isValidAccountValue(valueToNumber)) {
        api.updateAccountValue(orgId, account.id, valueToNumber)
          .then((updatedAccount) => {
            setCardAccount({...cardAccount, value: updatedAccount.value});
            successToast('Account value successfully updated');
            onInformationChange();
          })
          .catch(() => errorToast('Could not update account value'));
      }
    } else {
      setAccountValue(cardAccount.value);
    }
  };

  if(showValue) {
    return (
      <div className="account-header__account-personal-info__info__stale-period">
        <div className="account-header__account-personal-info__info__stale-period__conversion">
          <span className='account-header__account-personal-info__info__stale-period__conversion__text'>conversion</span>
          <FontAwesomeIcon className='account-header__account-personal-info__info__stale-period__conversion__text' icon={faPercent}/>
          <OrdoInput inputName="stale-period"
            bottomMargin={false}
            additionalClassName="account-header__account-personal-info__info__stale-period__conversion__input"
            value={accountConversion}
            max={100}
            min={0}
            inputType="number"
            placeholder=''
            onChange={(value) => setAccountConversion(parseInt(value, 10))}
            onBlur={(event: any) => updateAccountConversion(event.target.value)}
            inputSize="short"
          />
        </div>

        <div className="account-header__account-personal-info__info__stale-period__conversion">
          <span className='account-header__account-personal-info__info__stale-period__conversion__text'>value</span>
          <FontAwesomeIcon className='account-header__account-personal-info__info__stale-period__conversion__text' icon={faDollarSign}/>
          <OrdoInput inputName="stale-period"
            bottomMargin={false}
            value={accountValue}
            min={0}
            additionalClassName="account-header__account-personal-info__info__stale-period__conversion__input"
            inputType="number"
            placeholder=''
            onChange={(value) => setAccountValue(parseInt(value, 10))}
            onBlur={(event: any) => updateAccountValue(event.target.value)}
            inputSize="medium"
          />
        </div>
      </div>
    );
  }  return(
    <div className="account-header__account-personal-info__info__stale-period">
      <div className="account-header__account-personal-info__info__stale-period__conversion">
        <span className='account-header__account-personal-info__info__stale-period__conversion__text'>stale period</span>
        <OrdoInput inputName="stale-period"
          value={accountStalePeriod}
          inputType="number"
          bottomMargin={false}
          additionalClassName="account-header__account-personal-info__info__stale-period__conversion__input"
          placeholder=''
          onChange={(value) => setAccountStalePeriod(parseInt(value, 10))}
          onBlur={(event: any) => updateStalePeriod(event.target.value)}
          inputSize="short"
        />
      </div>
    </div>
  );
};
