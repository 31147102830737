import React, {useContext} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClone, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {ProductLine} from '../../../../models/order-entry/Cart';
import {isValidPrice, isValidQuantity} from '../../../../models/order-entry/OrderValidator';
import OrdoInput from '../../../components/common/inputs/OrdoInput';
import {ItemLine} from '../../../../models/order-entry/ItemLine';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import {AddDiscountButton} from '../../AddDiscountButton';
import {DiscountType} from '../../../../models/order-entry/Order';
import {CartItem, CartItemType} from '../../../../models/order-entry/CartItem';
import {ItemLineInfo} from './ItemLineInfo';
import OrdoSpinner from '../../../components/OrdoSpinner';
import OrdoButton from '../../../components/common/OrdoButton';
import {PRIMARY_COLOR} from '../../../../constants';

type MobileOrderEntryCheckoutItemProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateViewModel: (orderEntryViewModel: OrderEntryViewModel) => void,
  productLine: ProductLine,
}

export const MobileOrderEntryCheckoutItem = ({orderEntryViewModel, updateViewModel, productLine}: MobileOrderEntryCheckoutItemProps) => {
  const {line, item} = productLine;
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const updateCartItemLineQuantity = (itemLine: ItemLine) => {
    return async (event: any) => {
      showSpinner();
      const value = parseInt(event?.target?.value, 10);
      const vm = await orderEntryViewModel.updateItemQuantity(organization.id, itemLine, value);
      updateViewModel(vm);
      hideSpinner();
    };
  };

  const updateItemPricePerCase = (itemLine: ItemLine) => {
    return async (event: any) => {
      showSpinner();
      const value = parseFloat(event?.target?.value);
      const vm = await orderEntryViewModel.updateItemPrice(organization.id, itemLine, value);
      updateViewModel(vm);
      hideSpinner();
    };
  };

  const updateItemDiscount = (itemLine: ItemLine) => {
    return async (newValue: number) => {
      showSpinner();
      const newVM = await orderEntryViewModel.updateItemDiscount(organization.id, itemLine, newValue);
      updateViewModel(newVM);
      hideSpinner();
    };
  };

  const updateItemDiscountType = (itemLine: ItemLine) => {
    return async (value: DiscountType) => {
      showSpinner();
      const newVM = await orderEntryViewModel.updateItemDiscountType(organization.id,itemLine, value);
      hideSpinner();
      updateViewModel(newVM);
    };
  };

  const isValidLineQuantity = () => {
    return isValidQuantity(line.getQuantity()) && line.getQuantity() !== 0 && orderEntryViewModel.inventoryIsEnoughForProduct(item.product);
  };

  const keyForInput= item.product.id + line.id + productLine.index;

  const addNewLine = async (cartItem: CartItem) => {
    showSpinner();
    updateViewModel(await orderEntryViewModel.addLine(organization.id, cartItem));
    hideSpinner();
  };

  const removeLine = async (cartItem: CartItem, productLineNumber: number) => {
    showSpinner();
    updateViewModel(await orderEntryViewModel.removeLine(organization.id, cartItem, productLineNumber));
    hideSpinner();
  };


  return (<OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="line-item">
      <div className="line-item__info">
        <ItemLineInfo item={productLine}/>
        {productLine.index === 0 ? <OrdoButton
          additionalClassName="line-item__duplicate-or-remove-line"
          dataTestId='add-line-button'
          disabled={false}
          text='duplicate line'
          category='link'
          style={{padding: '0', display: 'flex', justifyContent: 'start'}}
          onClick={() => addNewLine(item)}
          firstChild={<FontAwesomeIcon color={`${PRIMARY_COLOR}`} size="1x" icon={faClone}
            style={{margin: '0 0.5em'}}
          />}
        /> : <OrdoButton
          additionalClassName="line-item__duplicate-or-remove-line"
          dataTestId='remove-line-button'
          disabled={false}
          text='remove line'
          style={{padding: '0', color: 'grey', display: 'flex', justifyContent: 'start'}}
          category='link'
          onClick={() => removeLine(item, productLine.index)}
          firstChild={<FontAwesomeIcon color="grey" size="1x" icon={faMinusCircle}
            style={{margin: '0 0.5em'}}
          />}
        /> }
      </div>
      <div className="line-item__quantity">
        <OrdoInput
          key={keyForInput}
          inputName="productItemQuantity"
          additionalClassName="line-item__quantity__input"
          value={line.getQuantity()}
          placeholder=''
          inputSize='short'
          inputType="number"
          bottomMargin={false}
          min={0}
          onBlur={updateCartItemLineQuantity(line)}
          isValid={isValidLineQuantity()}
          style={{
            width: '100%',
            padding: '0 .5em',
            fontWeight: 'initial',
            whiteSpace: 'nowrap',
            fontSize: '.8rem',
            marginBottom: '0',
            border: '2px solid #62BC9F',
            color: '#1F2D3D',
          }}/>
      </div>
      <div className="line-item__pricing">
        <OrdoInput
          key={keyForInput}
          inputName="lineItemPrice"
          additionalClassName="line-item__pricing__input"
          placeholder=""
          inputSize="short"
          bottomMargin={false}
          inputType="number"
          value={line.priceInputValue()}
          isValid={isValidPrice(line.priceInputValue())}
          onBlur={updateItemPricePerCase(line)}
          style={{
            width: '100%',
            padding: '0 .5em',
            fontWeight: 'initial',
            whiteSpace: 'nowrap',
            fontSize: '.8rem',
            marginBottom: '0',
            border: '2px solid #62BC9F',
            color: '#1F2D3D',
          }}
        />
        {item.type ===  CartItemType.CASE && <span className="line-item__pricing__price-per-unit">{`${item.lineItemPricePerUnit(productLine.index)}/unit`}</span>}
      </div>
      <div className="line-item__discount">
        <AddDiscountButton
          key={keyForInput}
          item={line}
          onChange={() => {}}
          onSwitch={updateItemDiscountType(line)}
          onBlur={updateItemDiscount(line)}
          discountButtonStyle={{width: '100%', fontSize: '.8rem'}}
          iconsStyle={{width: '1.9em', height: '1.9em'}}
          invalidWithoutMessage
        />
      </div>
    </div>
  </OrdoSpinner>
  );
};

