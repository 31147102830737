import React, {CSSProperties, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {PRIMARY_COLOR} from '../../constants';
import {DollarSignIcon} from '../components/common/inputs/OrdoPriceInput';
import {PercentageIcon} from '../components/common/inputs/OrdoPercentageInput';
import {DiscountType} from '../../models/order-entry/Order';
import OrdoMaskedInput from '../components/common/inputs/OrdoMaskedInput';
import {withDecimalPlaces} from '../../utils/string';
import {Money} from '../../models/Money';
import '../../scss/ordo/ordo-number-input.scss';
import { isValidDiscountPercentage, isValidDiscountPrice } from '../../models/order-entry/OrderValidator';

interface DiscountableItem {
  getDiscountType(): DiscountType
  discountExceedsPrice(): boolean
  totalWithoutDiscount(): Money
  discountInputValue(): number
}

export type AddDiscountButtonProps = {
  item: DiscountableItem,
  onChange: (discount: number) => void,
  onSwitch: (discountType: DiscountType) => void,
  onBlur: (event: any) => any,
  discountButtonStyle?: CSSProperties,
  addDiscountButtonStyle?: CSSProperties,
  iconsStyle?: CSSProperties,
  label?: string,
  invalidWithoutMessage?: boolean,
};

export const AddDiscountButton = ({
  item,
  onChange,
  onBlur,
  onSwitch,
  discountButtonStyle,
  addDiscountButtonStyle = {},
  iconsStyle = {},
  label,
  invalidWithoutMessage = false,
}: AddDiscountButtonProps) => {
  const [inputValue, setInputValue] = useState(item.discountInputValue());

  const discountType = item.getDiscountType();
  const showAddDiscountButton = discountType === DiscountType.None;
  const percentageIcon = <PercentageIcon/>;
  const dollarIcon = <DollarSignIcon/>;

  const handleToggleClick = () => {
    const otherDiscountType = discountType === DiscountType.Percentage ? DiscountType.Dollar : DiscountType.Percentage;
    onSwitch(otherDiscountType);
  };

  const validate = (value: number) => {
    switch (item.getDiscountType()) {
    case DiscountType.Dollar: {
      if (!isValidDiscountPrice(value, item.totalWithoutDiscount())) {
        return 'invalid discount';
      }
      return '';
    }
    case DiscountType.Percentage:
      if (!isValidDiscountPercentage(value)) {
        return 'invalid discount';
      }
      return '';
    default:
      return 'invalid discount type';
    }
  };

  const currentIcon = () => {
    switch(discountType) {
    case DiscountType.Percentage:
      return percentageIcon;
    default:
      return dollarIcon;
    }
  };

  const maxInputValue = () => {
    switch (discountType) {
    case DiscountType.Percentage:
      return 100;
    default:
      return item.totalWithoutDiscount().toUnit();
    }
  };

  const renderAddDiscountButton = () => {
    const onAddDiscountClick = () => {
      onSwitch(DiscountType.Dollar);
    };

    return (
      <div style={addDiscountButtonStyle}>
        <FontAwesomeIcon onClick={onAddDiscountClick} color={`${PRIMARY_COLOR}`} size="lg" icon={faPlusCircle} className="add-discount-button__icon"/>
        { label }
      </div>
    );
  };

  const completeToggleButtonStyle: CSSProperties = {
    width: '80px',
    padding: '0 1em',
    fontWeight: 'initial',
    fontSize: '0.8125rem',
    whiteSpace: 'nowrap',
    color: '#1F2D3D',
    ...discountButtonStyle
  };

  return (<div className='add-discount-button'>
    {showAddDiscountButton && renderAddDiscountButton()}
    {!showAddDiscountButton && <OrdoMaskedInput
      label={label || ''}
      value={discountType === DiscountType.Percentage ? inputValue*100 : inputValue}
      onChange={
        (event) => {
          let amount: number = parseFloat(event.target.value);
          if (Number.isNaN(amount)) amount = 0;
          const newAmount = discountType === DiscountType.Percentage ? amount/100 : amount;
          setInputValue(newAmount);
          onChange(withDecimalPlaces(newAmount));
        }
      }
      onBlur={() => onBlur(inputValue)}
      customIcon={<div className='switchable-icon'>{currentIcon()}</div>}
      onClickIcon={handleToggleClick}
      min={0}
      max={maxInputValue()}
      invalidMessage={validate(inputValue)}
      invalidWithoutMessage={invalidWithoutMessage}
      validate={
        (input: string) =>  {
          const value = parseFloat(input);
          return validate(value);
        }
      }
      style={completeToggleButtonStyle}
      iconsStyle={iconsStyle}
    />}
  </div>);
};
