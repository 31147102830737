import {Money} from '../Money';

export function isValidQuantity(quantity: number): boolean {
  return !Number.isNaN(quantity) && quantity >= 0;
}

export function isValidPrice(price: number): boolean {
  try {
    Money.FromDollarAmount(price);
    return price >= 0;
  } catch (error) {
    return false;
  }
}

export function isValidDiscountPrice(discount: number, subtotal: Money): boolean {
  if (!isValidPrice(discount)) return false;
  return subtotal.isGreaterOrEqualThan(Money.FromDollarAmount(discount));
}

export function isValidDiscountPercentage(percentage: number): boolean {
  return !Number.isNaN(percentage) && percentage >= 0 && percentage <= 100;
}

export function isValidFractionDiscountPercentage(percentage: number): boolean {
  return !Number.isNaN(percentage) && percentage >= 0 && percentage <= 1;
}

export function isValidTermPeriod(input: any): boolean {
  const parsedInput = parseInt(input, 10);
  const parsedInputAsFloat = parseFloat(input);
  return !(Number.isNaN(parsedInput)) && (parsedInput > 0) && (parsedInputAsFloat === parsedInput);
}

export function validateTermPeriod(input: any) {
  const parsedInput = parseInt(input, 10);
  const parsedInputAsFloat = parseFloat(input);
  if (Number.isNaN(parsedInput)) {
    return 'invalid term period';
  }
  if(!(parsedInput > 0)) {
    return 'the term period must be positive';
  }
  if (parsedInputAsFloat !== parsedInput) {
    return 'the term period must be an integer';
  }
  return '';
}
