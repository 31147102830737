import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import {orderBy} from 'lodash';
import {useAPI} from '../../context/OrdoApiContext';
import {UserSessionContext} from '../../context/UserSessionContext';
import {API} from '../../lib/api/ordoApi';
import {AccountTimeline} from '../../models/AccountTimeline';
import '../../scss/ordo/timeline-page/timeline-page.scss';
import {AccountTimelineRow} from './AccountTimelineRow';
import {AccountTimelineHeaderRow} from './AccountTimelineHeaderRow';
import {TimePeriod} from '../../models/TimePeriod';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import {ActivityTemplate} from '../../models/sales-activity/Activity';
import {TimelineFilter} from './TimelineFilter';
import {TimelineFilters} from './TimelineFilters';


function fetchNextPage(orgId: string, accounts: AccountTimeline[], api: API, page: number, amount: number, setAccounts: Function, hideSpinner: Function) {
  api.getAccountsTimeline(orgId, {amount: amount, offset: page}).then((newAccounts: AccountTimeline[]) => {
    const {length} = newAccounts;
    const accountTimelines = [...accounts, ...newAccounts];
    setAccounts(accountTimelines);
    if(length >= amount) {
      fetchNextPage(orgId, accountTimelines, api, page + amount, amount, setAccounts, hideSpinner);
    } else {
      hideSpinner();
    }
  });
}

const ACCOUNTS_PER_PAGE = 100;
const ACCOUNTS_WITH_POPUP_ON_TOP = 7;
const MINIMUM_AMOUNT_OF_ACCOUNTS_TO_CHANGE_POPUP_PLACEMENT = 4;
const timePeriods = [
  {data: TimePeriod.days15(), value: '15', label: 'past 15 days'},
  {data: TimePeriod.days30(), value: '30', label: 'past 30 days'},
  {data: TimePeriod.days90(), value: '90', label: 'past 90 days'}
];

export const TimelinePage = () => {
  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const currentOrganization = userSession.currentOrganization();

  const orgId = currentOrganization?.id || '';
  const [accounts, setAccounts] = useState<AccountTimeline[]>([]);
  const {hideSpinner: hideScrollSpinner, spinnerVisibility: scrollSpinnerVisibility, showSpinner: showScrollSpinner} = useSpinnerToggle();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(timePeriods[0].data);
  const [displayedEvents, setDisplayedEvents] = useState<{ type: string }[]>([]);
  const [filteredAccounts, setFilteredAccounts] = useState<AccountTimeline[]>([]);
  const [filter, setFilter] = useState<TimelineFilter>(TimelineFilter.empty());
  const [templates, setTemplates] = useState<ActivityTemplate[]>([]);
  const [descendingOrder, setDescendingOrder] = useState(true);


  useEffect( () => {
    showScrollSpinner();
    api.getActivitiesTemplatesForOrg(orgId).then((templatesWithCount) => {
      const activityTemplates: ActivityTemplate[] = templatesWithCount.map(activityTemplateWithCount => activityTemplateWithCount.activityTemplate);
      setTemplates(activityTemplates);
    });
    fetchNextPage(orgId, accounts, api, 0, ACCOUNTS_PER_PAGE, setAccounts, hideScrollSpinner);
  }, []);

  useEffect( () => {
    const eventsToDisplay = displayedEvents.map(e => e.type);
    const orderedAccounts = orderBy(accounts,
      (account) => {
        return account.items.filter(item => item.date().isAfter(timePeriod.start()) && item.shouldBeDisplayed(eventsToDisplay)).length;
      },
      descendingOrder ? 'desc' : 'asc');
    setFilteredAccounts(filter.apply(orderedAccounts));
  },[accounts, descendingOrder, filter, displayedEvents]);

  return <div className='timeline-page'>
    <TimelineFilters
      filter={filter} setFilter={setFilter}
      selectedTimePeriod={timePeriod} setTimePeriod={setTimePeriod} timePeriodOptions={timePeriods}
      displayedEvents={displayedEvents} setDisplayedEvents={setDisplayedEvents}
      descendingOrder={descendingOrder} setDescendingOrder={setDescendingOrder}
      activityTemplates={templates}
    />
    <AccountTimelineHeaderRow timePeriod={timePeriod}/>
    <div className='accounts-timeline'>
      {filteredAccounts.map((account, index) => {
        const shouldPlaceItBottom = (index > (accounts.length - ACCOUNTS_WITH_POPUP_ON_TOP)) && index > MINIMUM_AMOUNT_OF_ACCOUNTS_TO_CHANGE_POPUP_PLACEMENT;
        return <AccountTimelineRow
          key={account.accountId} account={account} timePeriod={timePeriod}
          popupVerticalPlacement={shouldPlaceItBottom ? 'bottom' : 'top'}
          activityTemplates={templates} eventsToDisplay={displayedEvents.map(e => e.type)}
        />;
      })}
      {scrollSpinnerVisibility && <div className='scroll-spinner'>
        <Spinner animation="border"/>
      </div>
      }
    </div>
  </div>;
};

export default withRouter(TimelinePage);
