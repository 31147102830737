import React, {useContext, useEffect, useState} from 'react';
import 'leaflet/dist/leaflet.css';
import '../../../scss/ordo/accountsPage/state-map.scss';
import AccountsViewModel from '../../../application-models/AccountsPageViewModel';
import {OrdoMap} from '../../../models/maps/OrdoMap';
import OrdoButton from '../../components/common/OrdoButton';
import {UserSessionContext} from '../../../context/UserSessionContext';


const CountiesFilterDisplay = ({viewModel, onClear}: { viewModel: AccountsViewModel, onClear: Function }) => {
  return <div className="counties-filter-indicator ordo-card-border ordo-shadow">
    <div className="counties-filter-indicator__title">Selected counties</div>
    <div className="counties-filter-indicator__counties-list">
      {viewModel.getFilter().selectedCounties.join(', ')}
    </div>
    <div className="counties-filter-indicator__counter">
      <OrdoButton text='clear' onClick={()=>onClear()}
        category='secondary' disabled={false}
        dataTestId='clear-counties-filter-button'
      />
    </div>
  </div>;
};

type InteractiveMapProps = {
  viewModel: AccountsViewModel
  setViewModel: Function
};
export const InteractiveMap = ({viewModel, setViewModel} : InteractiveMapProps) => {
  const mapId = 'map';
  const [ordoMap] = useState(new OrdoMap(mapId));
  const userSession = useContext(UserSessionContext);
  const currentOrganization = userSession.currentOrganization();

  useEffect(() => {
    /*
      This has to be done in a useEffect because it needs the
       div with the mapId to be rendered before doing its thing
    */
    ordoMap.initialize(viewModel, setViewModel, currentOrganization!);
  }, []);

  const clearCountiesFilter = async ()=> {
    const accountsViewModel = await viewModel.clearAllCountiesFromFilter();
    ordoMap.updateCountiesStyles(accountsViewModel);
    setViewModel(accountsViewModel);
  };

  return <div className="state-map">
    {viewModel.getFilter().selectedCounties.length > 0 &&
      <CountiesFilterDisplay viewModel={viewModel} onClear={clearCountiesFilter}/>
    }
    <div id={mapId}/>
  </div>;
};
