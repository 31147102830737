import React, {useState} from 'react';
import {PendingPaymentOrder} from '../../../models/order-payment/PendingPaymentOrder';
import OrdoButton from '../../components/common/OrdoButton';
import {Money} from '../../../models/Money';
import LogPaymentModal from '../../sales-activity/LogPaymentModal';

type AccountOwedPaymentsProps = {
  organizationId: string,
  owedPayments: PendingPaymentOrder[]
  fetchOwedOrders: (amountOwedAfterPayment?: Money) => void
}

export const AccountOwedPayments = ({organizationId, owedPayments, fetchOwedOrders}: AccountOwedPaymentsProps) => {

  const [logPaymentPendingOrder, setLogPaymentPendingOrder] = useState<PendingPaymentOrder | undefined>(undefined);

  const owedSum = () => Money.FromDollarAmount(owedPayments.reduce((accumulated, value) => accumulated + value.amountOwed.getAmount(), 0)/100);

  return(<div className='account-payments card card-fluid inventory-list-card ordo-shadow ordo-card-border'>
    <div className='title-container'>
      <span className="account-payments-title">outstanding payments</span>
      <span className='account-payments-subtitle'>{`${owedPayments.length} deals ● ${owedSum().formatted()} in value`}</span>
    </div>
    <div className='account-payments__list'>
      {owedPayments.map(owedPayment => {
        return(
          <div key={owedPayment.orderId} className='account-payments__list__order-payment'>
            <div className='order-number'>
              <span className='title'>Order</span>
              <a href={owedPayment.invoiceURL} target="_blank"
                rel="noreferrer">
                #{owedPayment.orderNumber}
              </a>
              <OrdoButton additionalClassName='log-payment-button' disabled={false} text='log payment' category='primary' onClick={() => setLogPaymentPendingOrder(owedPayment)} dataTestId=''/>
            </div>
            <div className='amount-owed'>
              <span className='debt'>{owedPayment.amountOwed.formatted()}</span>
              <span className='text'>amount owed</span>
            </div>
            {logPaymentPendingOrder && <LogPaymentModal isOpen={!!logPaymentPendingOrder}
              onClose={() => setLogPaymentPendingOrder(undefined)}
              onSubmit={fetchOwedOrders}
              organizationId={organizationId}
              pendingPaymentOrder={logPaymentPendingOrder}/>}
          </div>
        );
      })}
    </div>
  </div>);
};