import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {TimeSlot} from '../../models/TimeSlot';
import {TimelineItem, TimelineOrder} from '../../models/AccountTimeline';
import {Note} from '../../models/Note';
import {Activity, ExternalEmailActivity} from '../../models/sales-activity/Activity';
import {PopupItemInfo} from './PopupItemInfo';
import {OrdoCheckbox} from '../components/OrdoCheckbox';
import gmailLogo from '../profile/gmailLogo.png';
import {ThreadActivity} from '../components/common/ThreadActivity';
import {ActivityResponsesDisplay} from '../components/activities/ActivityResponsesDisplay';
import {AccountActivityOrderExpandedProducts} from '../accounts/account/activity/AccountActivityOrderExpandedProducts';
import '../../scss/ordo/timeline-page/timeline-page.scss';

type PopupInfoProps = {
  timelineItem: TimelineItem,
  updateNote: (note: Note) => void
  updateActivity: (activity: Activity) => void
  onActivityUpdated: (activity: Activity) => void
  setKeepOpen: (b: boolean) => void,
  accountName: string
};
const PopupInfo = ({timelineItem, accountName, onActivityUpdated, updateActivity, updateNote, setKeepOpen}: PopupInfoProps) => {
  switch (timelineItem.info()) {
  case 'activity':
    const activity = timelineItem.item() as Activity;
    return <PopupItemInfo
      uniqueKey={activity.id + timelineItem.style()}
      itemStyle={timelineItem.style()}
      icon={<FontAwesomeIcon icon={timelineItem.icon()!} size="2x" />}
      description={<span>{activity.description || ''}</span>}
      title={timelineItem.title()}
      contacts={timelineItem.contacts()}
      createdBy={timelineItem.createdBy()}
      date={timelineItem.displayDate()}
      onDelete={() => {}}
      onUpdate={() => updateActivity(activity)}
      checkbox={<OrdoCheckbox id={`activity-info-${activity.id}`} checked={activity.completed}
        onChange={() => {
          activity.completed = !activity.completed;
          onActivityUpdated(activity);
        }}/>}
      footer={<ActivityResponsesDisplay activity={activity} accountName={accountName} onOpen={()=>setKeepOpen(true)} onClose={()=>setKeepOpen(false)}/>}
    />;
  case 'externalActivity':
    const externalActivity = timelineItem.item() as ExternalEmailActivity;
    return <PopupItemInfo
      uniqueKey={externalActivity.id + timelineItem.style()}
      itemStyle={timelineItem.style()}
      icon={<FontAwesomeIcon icon={timelineItem.icon()!} size="2x" />}
      description={<ThreadActivity activity={externalActivity}/>}
      title={timelineItem.title()}
      contacts={timelineItem.contacts()}
      createdBy={timelineItem.createdBy()}
      date={timelineItem.displayDate()}
      checkbox={<img className="gmail-icon" alt='gmail-icon' src={gmailLogo}/>}
      extraText=''
      onUpdate={() => {}}
      onDelete={() => {}}
    />;
  case 'note':
    const note = timelineItem.item() as Note;
    return <PopupItemInfo
      uniqueKey={note.id + timelineItem.style()}
      itemStyle={timelineItem.style()}
      icon={<FontAwesomeIcon icon={timelineItem.icon()!} size="2x" />}
      description={<span>{note.description}</span>}
      title={timelineItem.title()}
      contacts={timelineItem.contacts()}
      createdBy={timelineItem.createdBy()}
      date={timelineItem.displayDate()}
      onDelete={() => {}}
      onUpdate={() => updateNote(note)}
    />;
  case 'order':
    const order = timelineItem.item() as TimelineOrder;
    return <PopupItemInfo
      uniqueKey={order.id + timelineItem.style()}
      itemStyle={timelineItem.style()}
      icon={<FontAwesomeIcon icon={timelineItem.icon()!} size="2x" />}
      title={timelineItem.title()}
      contacts={timelineItem.contacts()}
      description={<AccountActivityOrderExpandedProducts keepPopupOpen={() => setKeepOpen(true)} littleButton items={order.currentVersionItems} grandTotal={order.summary.grandTotal} invoiceURL={order.invoiceURL}/>}
      createdBy={timelineItem.createdBy()}
      date={timelineItem.displayDate()}
      extraText=''
      onDelete={() => {}}
      onUpdate={() => {}}
    />;
  default:
    return <></>;
  }
};
type PopupProps = {
  popupSide: 'left' | 'right', popupVerticalPlacement: 'top' | 'bottom',
  timeSlot: TimeSlot, timelineItems: TimelineItem[],
  onEditNote: (note: Note) => void
  onEditActivity: (activity: Activity) => void,
  onActivityCheckboxClicked: (activity: Activity) => void,
  setKeepOpen: (b: boolean) => void,
  accountName: string,
};

export function TimelinePopup({
  popupSide,
  timelineItems,
  timeSlot,
  popupVerticalPlacement,
  onActivityCheckboxClicked,
  onEditActivity,
  onEditNote,
  setKeepOpen,
  accountName,
}: PopupProps) {
  return <div role="presentation" className="info-popup"
    style={{[popupSide]: '-30em', [popupVerticalPlacement]: '1.7em'}} onClick={(event) => {
      event.stopPropagation();
    }}>
    <span className="info-popup__included-dates">{timeSlot.includedDates()}</span>
    <div className="info-popup__body">
      {
        timelineItems.map((timelineItem) => <PopupInfo key={timelineItem.id()}
          onActivityUpdated={onActivityCheckboxClicked}
          setKeepOpen={setKeepOpen}
          timelineItem={timelineItem}
          updateActivity={onEditActivity}
          updateNote={onEditNote}
          accountName={accountName}
        />)
      }
    </div>
  </div>;
}
