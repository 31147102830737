import React from 'react';
import {
  ActivityCompletionResponseProps,
  SingleValueActivityCompletionResponse
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import OrdoPriceInput from '../../common/inputs/OrdoPriceInput';

export const DollarValueResponse = ({
  response,
  updateResponse
}: ActivityCompletionResponseProps) => {
  const dollarValueResponse = response as SingleValueActivityCompletionResponse;

  const onChange = (value: number) => {
    dollarValueResponse.body = {value: value.toString(10)};
    updateResponse(dollarValueResponse);
  };

  const amount = parseFloat(dollarValueResponse.body.value);

  return <OrdoPriceInput label=''
    value={amount || 0}
    step={0}
    onChange={onChange} invalidMessage=""
  />;
};