import {FeatureFlags, FeatureLevel} from '../../../lib/featureFlags';
import {
  SalesActivityTable, SalesActivityTableInput
} from './SalesActivityTable';
import {LiteSalesActivityTable} from './LiteSalesActivityTable';
import {ProSalesActivityTable} from './ProSalesActivityTable';
import {PremiumSalesActivityTable} from './PremiumSalesActivityTable';

export class SalesActivityTableFactory {
  public static create(featureFlags: FeatureFlags, input: SalesActivityTableInput): SalesActivityTable {
    const featureLevel = featureFlags['feature-level'] as FeatureLevel;
    switch (featureLevel) {
    case FeatureLevel.STARTER:
      return new LiteSalesActivityTable(input);
    case FeatureLevel.LITE:
      return new ProSalesActivityTable(input);
    case FeatureLevel.PRO:
      return new PremiumSalesActivityTable(input);
    default:
      return new LiteSalesActivityTable(input);
    }
  }
}
