import React, {useEffect, useState} from 'react';
import OrdoButton from '../../components/common/OrdoButton';
import '../../../scss/ordo/loginPage/square-input.scss';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {OrdoPayment} from '../../../models/payments/OrdoPayments';

type SquarePaymentInputProps = {
  onPaymentMethodCreated: (toke: string) => void,
};
export const SquarePaymentInput = ({onPaymentMethodCreated} : SquarePaymentInputProps) => {
  const cardId = 'card-container';

  const {errorToast} = useOrdoToasts();
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [ordoPayments] = useState(OrdoPayment);

  const processPaymentMethod = async () => {
    ordoPayments.cardToken()
      .then((token: string) => {
        onPaymentMethodCreated(token);
      })
      .catch((e) => errorToast(e.message));
  };

  useEffect(()=> {
    if(!ordoPayments.card) {
      ordoPayments.initialize(cardId);
    }
  }, []);

  const displayCardInput = ()=> {
    setShouldDisplay(true);
  };

  return <div className="square-payment-input">
    {!shouldDisplay && <OrdoButton disabled={false} text="add credit card" category='primary'
      onClick={displayCardInput} dataTestId='add-credit-card-button'/> }
    <div className={shouldDisplay ? 'card-input-container' : 'card-input-hidden'}>
      <div id={cardId}/>
      <OrdoButton disabled={false} text='save' category='primary'
        onClick={processPaymentMethod} dataTestId="add-credit-card-button"/>
    </div>
  </div>;
};