import {User} from '../models/User';
import FormError, {FormFields, UserFormFields} from '../errors/form_error';
import InvalidField from '../errors/invalid_field';
import BusinessError from '../errors/domain_errors';
import { API } from '../lib/api/ordoApi';
import isValidEmail from '../utils/string';

export type UserUpdateFields = 'firstName' | 'lastName' | 'phone' | 'email' | 'id';

export type UserInformationInput = UserUpdateFields | 'email' | 'newPassword' | 'newPasswordConfirmation'

export type UserInformationViewModelInput = {
	id: string,
	firstName: string,
	lastName: string,
	phone: string,
	email: string,
	newPassword: string,
	newPasswordConfirmation: string,
}

export class UserInformationViewModel {
	public userInformation: UserInformationViewModelInput;
	private errors: FormError;
	private api: API;
  private readonly googleSyncEnabled: boolean;

  constructor(viewModelInput: UserInformationViewModelInput, errors: FormError, api: API, googleSyncEnabled: boolean) {
	  this.userInformation = viewModelInput;
	  this.errors = errors;
	  this.api = api;
    this.googleSyncEnabled = googleSyncEnabled;
  }

  public getGoogleSyncEnabled(): boolean {
    return this.googleSyncEnabled;
  }

  public static async initialize(user: User, orgId: string | null,  api: API) {
    const {formWithoutErrors, viewModelInput} = this.initializeUserAndErrorForm(user);
    let googleSyncEnabled = false;
    if(orgId) {
      googleSyncEnabled = await api.validateUserAuthSyncEnabled('google', orgId);
    }
	  return new UserInformationViewModel(viewModelInput, formWithoutErrors, api, googleSyncEnabled);
  }

  public static empty(user: User, api: API) {
    const {formWithoutErrors, viewModelInput} = this.initializeUserAndErrorForm(user);
    return new UserInformationViewModel(viewModelInput, formWithoutErrors, api, false);
  }

  private static initializeUserAndErrorForm(user: User) {
    const formWithoutErrors = FormError.withoutError();
    const viewModelInput = {
      firstName: user.firstName, lastName: user.lastName, phone: user.phone, email: user.email,
      newPassword: '', newPasswordConfirmation: '', id: user.id
    };
    return {formWithoutErrors: formWithoutErrors, viewModelInput: viewModelInput};
  }

  public async updateUserInformation(id: string) {
	  if (!this.isValidUpdate()) {
	    const userInvalidFields = this.invalidFields();
	    return this.updateErrors(userInvalidFields);
	  }
	  try {
	    const updatedInfo = {
	      firstName: this.userInformation.firstName,
	      lastName: this.userInformation.lastName,
	      phone: this.userInformation.phone,
		    email: this.userInformation.email,
	    };
	    await this.api.editUser(updatedInfo, id);
	    return this;
	  } catch (error) {
		  const viewModelInput = {
			  id: this.userInformation.id,
			  firstName: this.userInformation.firstName,
			  lastName: this.userInformation.lastName,
			  phone: this.userInformation.phone,
			  email: this.userInformation.email,
			  newPassword: this.userInformation.newPassword,
			  newPasswordConfirmation: this.userInformation.newPasswordConfirmation,
		  };
		  if (error instanceof BusinessError) {
	      return new UserInformationViewModel(viewModelInput, error.formError, this.api, this.googleSyncEnabled);
	    }
	    const unknownErrorForm = FormError.unknown(error.message);
	    return new UserInformationViewModel(viewModelInput, unknownErrorForm, this.api, this.googleSyncEnabled);
	  }
  }

  public hasErrorFor(input: UserFormFields) {
	  return this.errors.hasErrorForInput(input);
  }

  public errorMessage(input: UserFormFields) {
	  return this.errors.errorMessage(input);
  }

  public updateErrors(invalidFields: InvalidField[]) {
	  const updatedFormError = FormError.withErrors(invalidFields);
	  const viewModelInput = {
		  id: this.userInformation.id,
	    firstName: this.userInformation.firstName,
	    lastName: this.userInformation.lastName,
	    phone: this.userInformation.phone,
	    email: this.userInformation.email,
	    newPassword: this.userInformation.newPassword,
	    newPasswordConfirmation: this.userInformation.newPasswordConfirmation,
	  };
	  return new UserInformationViewModel(viewModelInput, updatedFormError, this.api, this.googleSyncEnabled);
  }

  public hasErrors() {
	  return this.errors.hasError();
  }

  public isValidUpdate() {
	  return !!this.userInformation.firstName && !!this.userInformation.lastName &&
		  !!this.userInformation.phone && isValidEmail(this.userInformation.email);
  }

  public invalidFields() {
	  const invalidFields = [];
	  if (!this.userInformation.firstName) invalidFields.push(this.invalidField('firstName'));
	  if (!this.userInformation.lastName) invalidFields.push(this.invalidField('lastName'));
	  if (!this.userInformation.phone) invalidFields.push(this.invalidField('phone'));
	  if (!isValidEmail(this.userInformation.email)) invalidFields.push(this.invalidField('email'));
	  return invalidFields;
  }

  public userInformationField(field: UserInformationInput) {
	  return this.userInformation[field];
  }

  private invalidField(field: string) {
	  const invalidFieldErrorMessage = field === 'email' ? 'Invalid email' : 'Mandatory Field';
	  return new InvalidField(field as FormFields, invalidFieldErrorMessage);
  }

  public  updateGoogleSyncEnabled(enabled: boolean) {
    const userInformationInput = {
		  id: this.userInformation.id,
		  firstName: this.userInformation.firstName,
		  lastName: this.userInformation.lastName,
		  phone: this.userInformation.phone,
		  email: this.userInformation.email,
		  newPassword: this.userInformation.newPassword,
		  newPasswordConfirmation: this.userInformation.newPasswordConfirmation,
	  };

    return new UserInformationViewModel(userInformationInput, this.errors, this.api, enabled);
  }
}
