import React from 'react';
import Fuse from 'fuse.js';
import {
  CustomFilterOption,
  CustomOptionComponent,
  CustomOptionComponentProps
} from '../common/searchable-dropdown/OrdoSearchableDropdown';
import Product from '../../../models/Product';
import {SelectableOption} from '../common/searchable-dropdown/selectableOption';

type ProductDropdownViewProps = {
  product: Product,
  innerProps: any,
  isMenu: boolean
}

const ProductDropdownView = ({innerProps, product, isMenu}: ProductDropdownViewProps) => {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...innerProps} className={`product-dropdown-view ${isMenu ? 'menu' : ''}`}>
    <div className="info">
      <div className="title"> {product.name}</div>
      <div className="subtitle"> {product.customerSKU}</div>
    </div>
    <div className="field"> {product.brand.name} </div>
    <div className="field"> {product.category.name} </div>
    <div className="field"> {product.geneticsToString() || '-'} </div>
    <div className="field"> {product.unitsPerCaseToString()  || '-'} </div>
  </div>;
};

export const ProductDropdownSelectedView: CustomOptionComponent = ({innerProps, data}: CustomOptionComponentProps) => {
  return <ProductDropdownView product={data.value} innerProps={innerProps} isMenu={false}/>;
};

export const ProductDropdownMenuView: CustomOptionComponent = ({innerProps, data}: CustomOptionComponentProps) => {
  return <ProductDropdownView product={data.value} innerProps={innerProps} isMenu/>;
};

export const filterProductDropdownOption: CustomFilterOption =
  <T extends Object>(candidate: { data: SelectableOption<T> }, input: string, options: SelectableOption<T>[]) => {
    // Reference: https://react-select.com/advanced#custom-filter-logic
    if (input) {
      const searcher = new Fuse(options,
        { keys: ['label', 'value.customerSKU'],
          threshold: 0.0,
          ignoreLocation: true,
          findAllMatches: true,
        });
      const searchResults = searcher.search(input);
      const titles = searchResults.map(sr => sr.item.label);
      return titles.some(title =>
        // NOTE: It would be better to do kind of a fussy search here:
        title === candidate.data.label
      );
    }
    return true;
  };
