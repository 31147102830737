import {User} from '../User';
import Order, {LineItemType, OrderStatus, OrderVersion} from './Order';
import {Money} from '../Money';
import {OrderSummary} from './Summary';
import {ProductWithAvailability} from '../productWithAvailability';
import Account from '../Account';
import {DateFormatTemplate, OrdoDate} from '../OrdoDate';

export default class OrderWithCurrentVersion extends Order {
  constructor(
    id: string,
    salesRep: User,
    status: OrderStatus,
    datePlaced: Date,
    salesValue: Money,
    orderNumber: string,
    public currentVersion: OrderVersion,
    public isTracked: boolean) {
    super(id, salesRep, status, datePlaced, salesValue, orderNumber);
  }

  public get summary(): OrderSummary {
    return this.currentVersion.summary;
  }

  public updateItemsWithAvail(productsWihAvailability: ProductWithAvailability[]) {
    this.currentVersion.items.forEach((item) => {
      const productWithAvailability = productsWihAvailability.find(product => product.id === item.product.id)!;
      item.updateProduct(productWithAvailability);
    });
  }

  public accountId(): string {
    return this.currentVersion.accountLocation.accountId;
  }

  public get accountLocation() {
    return this.currentVersion.accountLocation;
  }

  public get contact() {
    return this.currentVersion.contact;
  }

  public get distributor() {
    return this.currentVersion.distributor;
  }

  public get accountName() {
    return this.currentVersion.account.name;
  }

  public get contactName() {
    return `${this.contact.name}`;
  }

  public get accountStalePeriod() {
    return this.currentVersion.account.getStalePeriod();
  }

  public casesForProduct(productId: string) {
    const cases = this.currentVersion.items.filter(item => item.productId === productId && item.type === LineItemType.Case);
    return cases.reduce((accumulated, lineItem) => accumulated + lineItem.quantity, 0);
  }

  public unitsForProduct(productId: string) {
    const units = this.currentVersion.items.filter(item => item.productId === productId && item.type === LineItemType.Unit);
    return units.reduce((accumulated, lineItem) => accumulated + lineItem.quantity, 0);
  }

  public updateAccountStalePeriod(stalePeriod: number) {
    const account = Account.withStalePeriod(this.currentVersion.account, stalePeriod);
    this.currentVersion = {...this.currentVersion, account: account};
  }

  public formattedDeliveryDate(): string {
    const date = OrdoDate.from(this.currentVersion.deliveryDay);
    const month = date.format(DateFormatTemplate.MONTH_NAME_ABBREVIATED);
    const day = date.format(DateFormatTemplate.DAY_OF_MONTH);
    return `${month.toLocaleLowerCase()} ${day}`;
  }

  public getDeliveryDateAsTime(): number {
    const deliveryDate = new Date(this.currentVersion.deliveryDay);
    return deliveryDate.getTime();
  }

  public getDeliveryDate(): Date {
    return new Date(this.currentVersion.deliveryDay);
  }
}
