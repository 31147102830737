import React, {useContext, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {CARD_BORDER_COLOR, GRAY_400, PASTEL_RED, PRIMARY_COLOR} from '../../constants';
import Account, {AccountLocation} from '../../models/Account';
import AccountsViewModel, {AccountFilter} from '../../application-models/AccountsPageViewModel';
import OrdoInput from '../components/common/inputs/OrdoInput';
import OrdoMultiSelect from '../components/common/OrdoMultiSelect';
import {OrdoCheckbox} from '../components/OrdoCheckbox';
import OrdoButton from '../components/common/OrdoButton';
import {RepAssignmentModal} from '../components/modals/RepAssignmentModal';
import {EditRepAssignmentModal} from '../components/modals/EditRepAssignmentsModal';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import {OrdoPagination} from '../components/pagination/OrdoPagination';
import {AccountCard} from '../../models/AccountCard';
import { LinkAccountsModal } from '../components/modals/LinkAccountsModal';
import OrdoLittleButton from '../components/common/OrdoLittleButton';
import {UserSessionContext, UserSessionContextData} from '../../context/UserSessionContext';
import {ActualMember} from '../../models/member/ActualMember';
import ConfirmationModal from '../components/common/ConfirmationModal';
import {EditLocationAddress} from '../components/EditLocationAddress';

type AccountListProps = {
  orgId: string,
  viewModel: AccountsViewModel,
  setViewModel: Function,
  showSpinner: Function,
  hideSpinner: Function,
  onReloadPage: Function,
  onPageChange: Function,
};

export function AccountList({orgId, viewModel, setViewModel, showSpinner, hideSpinner, onPageChange, onReloadPage}: AccountListProps) {
  const {api} = viewModel;
  const {successToast, errorToast} = useOrdoToasts();
  const [linkAccountsModalOpen, setLinkAccountsModalOpen] = useState(false);
  const [unassignAccountModalOpen, setUnassignAccountModalOpen] = useState(false);
  const userSession: UserSessionContextData = useContext(UserSessionContext);
  const currentMember = userSession.getCurrentMember();
  const currentOrganization = userSession.currentOrganization();
  const [selectedAccountToUnassign, setSelectedAccountToUnassign] = useState<AccountCard | undefined>(undefined);

  const actionButtonStyles = {
    border: 'none',
    backgroundColor: 'transparent',
    color: PRIMARY_COLOR,
  };

  const clearAllAccounts = async () => {
    viewModel.clearAllSelectedAccounts()
      .then((updatedViewModel) => {
        setViewModel(updatedViewModel);
      })
      .catch(() => {
        errorToast('Could not clear selected accounts');
      })
      .finally();
  };

  const onLocationAddressUpdated = (updatedLocation: AccountLocation) => {
    setViewModel(viewModel.updatedAddress(updatedLocation));
  };

  const selectAllVisibleAccounts = () => {
    viewModel.selectAllVisibleRetailers()
      .then((updatedViewModel) => {
        setViewModel(updatedViewModel);
      })
      .catch(() => {
        errorToast('Could not select all visible accounts');
      })
      .finally();
  };

  const toggleSelectedAccount = async (account: Account) => {
    if (!viewModel.ableToSelectAccount(currentMember, account)) {
      return;
    }
    viewModel.toggleSelectedAccount(account.id)
      .then((updatedViewModel) => {
        setViewModel(updatedViewModel);
      })
      .catch(() => {
        errorToast('Could not select account');
      })
      .finally();
  };

  const updateSearchByNameInput = (searchInput: string) => {
    viewModel.withSearchInputByName(searchInput)
      .then((updatedViewModel) => {
        setViewModel(updatedViewModel);
      })
      .catch(() => {
        errorToast('Could not search accounts');
      })
      .finally();
  };

  const updateSearchByZipCodeInput = (searchInput: { value: string }[]) => {
    viewModel.withSearchInputByZipCode(searchInput.map(si => si.value))
      .then(updatedViewModel => setViewModel(updatedViewModel))
      .catch(() => {
        errorToast('Could not search accounts');
      })
      .finally();
  };

  const updateSearchBySalesRepInput = (searchInput: { data: ActualMember }[]) => {
    viewModel.withSearchInputBySalesRep(searchInput.map(si => si.data))
      .then(updatedViewModel => setViewModel(updatedViewModel))
      .catch(() => {
        errorToast('Could not search accounts');
      })
      .finally();
  };

  const updateFilter = (filter: AccountFilter) => {
    viewModel.withFilter(filter)
      .then((updatedViewModel) => {
        setViewModel(updatedViewModel);
      })
      .catch(() => {
        errorToast('Could not filter accounts');
      })
      .finally();
  };

  const toggleAccountAssignmentModal = () => {
    const open = viewModel.assignmentModalOpen;
    setViewModel(viewModel.withRepAssignmentModalOpen(!open));
  };

  const handleAssignmentAction = async () => {
    if(currentMember.isSalesRep()) {
      showSpinner();
      try {
        await viewModel.assignAccountsToSalesRep(currentMember.getUserId(), orgId);
        let updated = await viewModel.clearAllSelectedAccounts();
        updated = await updated.refreshRepAssignments(orgId);
        setViewModel(updated);
        successToast('Successfully assigned accounts');
      } catch (e) {
        errorToast('Unable to submit account assignment. Please refresh the page and try again.');
      } finally {
        hideSpinner();
      }
    } else {
      toggleAccountAssignmentModal();
    }
  };

  const toggleLinkAccountsModal = () => setLinkAccountsModalOpen(!linkAccountsModalOpen);

  const toggleManufacturersFilter = () => {
    const f = viewModel.getFilter();
    f.manufacturers = !f.manufacturers;
    updateFilter(f);
  };

  const toggleCultivatorsFilter = () => {
    const f = viewModel.getFilter();
    f.cultivators = !f.cultivators;
    updateFilter(f);
  };

  const toggleRetailerFilter = () => {
    const f = viewModel.getFilter();
    f.retailers = !f.retailers;
    updateFilter(f);
  };

  const toggleNonstorefrontRetailFilter = () => {
    const f = viewModel.getFilter();
    f.nonstorefrontRetailers = !f.nonstorefrontRetailers;
    updateFilter(f);
  };

  const toggleDistributorRetailFilter = () => {
    const f = viewModel.getFilter();
    f.distributors = !f.distributors;
    updateFilter(f);
  };

  const unassignAccountFromUser = (account: AccountCard) => {
    setSelectedAccountToUnassign(account);
    setUnassignAccountModalOpen(true);
  };

  const toggleMicrobusinesses = () => {
    const f = viewModel.getFilter();
    f.microbusinesses = !f.microbusinesses;
    updateFilter(f);
  };

  const onEditLinkClick = async (account: AccountCard) => {
    let updatedViewModel = await viewModel.clearAllSelectedAccounts();
    updatedViewModel = await updatedViewModel.toggleSelectedAccount(account.id);
    await setViewModel(updatedViewModel);
    toggleLinkAccountsModal();
  };

  const closeUnassignAccountModal = () => {
    setUnassignAccountModalOpen(false);
  };

  const drawerExpanded = viewModel.getDrawerState().expand;
  const canSeeFilters = currentOrganization?.state === 'CA';

  const renderSelectAllButton = () => {
    return (
      <OrdoButton dataTestId="accounts-select-all" disabled={false} text="select all"
        category="primary" onClick={() => selectAllVisibleAccounts()}
        style={actionButtonStyles}/>
    );
  };

  const renderClearSelectionButton = () => {
    return (
      <OrdoButton dataTestId="accounts-clear-selection" disabled={viewModel.noAccountCardsSelected()} text='clear'
        category="primary" onClick={() => clearAllAccounts()}
        style={actionButtonStyles}/>
    );
  };

  const renderSearchInput = () => {
    const shouldExpandZipCodeFilter = viewModel.getSearchByZipCodeInput().length > 2;
    const shouldExpandSalesRepFilter = viewModel.getSearchBySalesRepInput().length > 2;
    const multiSelectStyles = {
      container: {
        minWidth: '30%',
        width: 'auto',
        maxWidth: '50%',
      },
      control: {
        minHeight: '2.5em',
        borderColor: GRAY_400,
        boxShadow: `0 0 0.4em 0.01em ${CARD_BORDER_COLOR}`,
        '&:hover': {},
      },
      placeholder: {
        top: '40%',
      },
      valueContainer: {
        minHeight: '2em',
        padding: '0 8px',
        overflowY: shouldExpandSalesRepFilter ? 'scroll' : 'hidden'
      },
      multiValue: {
        width: '5em',
        alignItems: 'center',
        height: '1.5em',
        marginTop: '0',
        marginBottom: '4px',
      },
      indicatorsContainer: {
        minHeight: '2em',
        height: '2em'
      },
      indicatorSeparator: {
        margin: '4px 0'
      },
      menu: {
        marginTop: '0.75em'
      }
    };

    return (<div className={`${drawerExpanded ? 'search-and-assignment' : 'search-and-assignment--collapsed'}__search-section__input-and-filters__account-search-inputs`}>
      <OrdoInput inputName="accountName"
        value={viewModel.getSearchByNameInput()}
        placeholder="search by name or license"
        inputSize={drawerExpanded ? 'short' : 'large'}
        onChange={(value: string) => updateSearchByNameInput(value)}
        bottomMargin={false}
      />
      { drawerExpanded && <OrdoMultiSelect
        options={viewModel.selectableZipCodes()}
        selectedOptions={viewModel.getSearchByZipCodeInput()}
        onChangeSelectedOption={updateSearchByZipCodeInput}
        selectMultipleOptionsAtOnce
        placeholder="search by zipcode"
        addedStyles={
          {...multiSelectStyles,
            ...{valueContainer: {overflowY: `${shouldExpandZipCodeFilter ? 'scroll' : 'hidden'}`}}}
        }
      />}
      { drawerExpanded && <OrdoMultiSelect
        options={viewModel.selectableSalesReps()}
        selectedOptions={viewModel.getSearchBySalesRepInput()}
        onChangeSelectedOption={updateSearchBySalesRepInput}
        selectMultipleOptionsAtOnce
        placeholder="search by sales rep"
        addedStyles={
          {...multiSelectStyles,
            ...{valueContainer: {overflowY: `${shouldExpandSalesRepFilter ? 'scroll' : 'hidden'}`}}}
        }
      />}
    </div>
    );
  };

  const renderFilters = () => {
    return (
      <div>
        <div className='account-filters-section'>
          <div className='account-filters-section__filters'>
            <div className='account-filters-section__filters__group'>
              <OrdoCheckbox checked={viewModel.getFilter().retailers}
                onChange={toggleRetailerFilter}
                label='Retailers'
                id='Retailers'
                style={{marginRight: '1em'}}
              />
              <OrdoCheckbox checked={viewModel.getFilter().nonstorefrontRetailers}
                onChange={toggleNonstorefrontRetailFilter}
                label='Nonstorefront Retailers'
                id='Nonstorefront Retailers'
                style={{marginRight: '1em'}}
              />
              <OrdoCheckbox checked={viewModel.getFilter().distributors}
                onChange={toggleDistributorRetailFilter}
                label='Distributors'
                id='Distributors'
                style={{marginRight: '1em'}}
              />
            </div>
            <div className='account-filters-section__filters__group'>
              <OrdoCheckbox checked={viewModel.getFilter().microbusinesses}
                onChange={toggleMicrobusinesses}
                label='Microbusinesses'
                id='Microbusinesses'
                style={{marginRight: '1em'}}
              />
              <OrdoCheckbox checked={viewModel.getFilter().manufacturers}
                onChange={toggleManufacturersFilter}
                label='Manufacturers'
                id='Manufacturers'
                style={{marginRight: '1em'}}
              />
              <OrdoCheckbox checked={viewModel.getFilter().cultivators}
                onChange={toggleCultivatorsFilter}
                label='Cultivators'
                id='Cultivators'
                style={{marginRight: '1em'}}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAssignRepButton = () => {
    return (
      <div className='assign-to-rep-button'>
        <OrdoButton
          additionalClassName='accounts-assign-rep'
          style={{minWidth: '15.5em'}}
          dataTestId="accounts-assign-rep"
          disabled={viewModel.noAccountCardsSelected()}
          text={currentMember.isSalesRep() ? 'assign to self': 'assign to rep'}
          category="primary" onClick={() => handleAssignmentAction()}
        />
      </div>
    );
  };

  const renderLinkAccountsButton = () => {
    return (
      <div className='assign-to-rep-button'>
        <OrdoButton
          additionalClassName='location-assign-rep'
          style={{minWidth: '15.5em'}}
          dataTestId="accounts-assign-rep"
          disabled={!viewModel.canLinkAccounts(currentMember)}
          disabledText={currentMember.isSalesRep() ? 'Must select allowed accounts to link' : ''}
          text='edit or link accounts'
          category="secondary" onClick={() => toggleLinkAccountsModal()}
        />
      </div>
    );
  };

  const onSubmitUnassignAccount = async (member: ActualMember, item: AccountCard) => {
    showSpinner();
    try {
      await viewModel.unassignAccountFromUser(member, item, orgId);
      let updated = await viewModel.clearAllSelectedAccounts();
      updated = await updated.refreshRepAssignments(orgId);
      setViewModel(updated);
      successToast('Successfully unassigned account');
    } catch (e) {
      errorToast('Something went wrong unassigning the account');
    } finally {
      hideSpinner();
    }
  };

  const renderCardAssignmentsInfo = (item: AccountCard) => {
    const userCanUnassignFrom = () => {
      return currentMember.isSalesRep() && item.hasAssigned(currentMember);
    };

    const userCanUnassignFromAccount = userCanUnassignFrom();

    const assignmentsClass = `account-assignments-${userCanUnassignFromAccount ? 'delete' : 'tooltip'} `;
    return (
      <div>
        {item.hasAssignments() && <div className={`account-assignments ${assignmentsClass}`}>
          <div className="account-assignments-info">
            {item.getAssignmentText()}
          </div>
          {!userCanUnassignFromAccount && <div className="tooltip-info">
            {item.assignments.map(assignment => {
              return <span key={assignment.member.getUserId()}>{assignment.member.memberName()}</span>;
            })}
          </div>}
          {userCanUnassignFromAccount && <div className="account-unassign-cross">
            <FontAwesomeIcon
              className="mb-3 interectable-icon"
              size="lg"
              color={PASTEL_RED}
              icon={faTimesCircle}
              onClick={(event) => {
                event.stopPropagation();
                unassignAccountFromUser(item);
              }}/>
          </div>}
        </div>}
      </div>
    );
  };

  const renderCardInfo = (accountCard: AccountCard) => {
    const amountOfLicenses = accountCard.amountOfLicenses();
    const moreThanOneLicense = amountOfLicenses > 1;
    const firstLocation = accountCard.getLocations()[0];
    return (
      <div>
        {moreThanOneLicense && <p className="account-address license text-limit">{`${amountOfLicenses} licenses`}</p>}
        {!moreThanOneLicense && <p className="account-address license text-limit">{firstLocation.location.licenseNumber}</p>}
        {moreThanOneLicense && <div className="edit-link-button">
          <OrdoLittleButton
            disabled={false}
            text='edit link'
            category="secondary"
            onClick={() => onEditLinkClick(accountCard)}
            dataTestId=""
          />
        </div>}
        {!moreThanOneLicense && <div className='account-address-container'>
          <span className="account-address text-limit"> {firstLocation.streetAddressLine1} {firstLocation.streetAddressLine2}</span>
          <span className="account-address city text-limit">{firstLocation.city ? `${firstLocation.city}, ${firstLocation.state} ${firstLocation.zipCode}` : `${firstLocation.state} ${firstLocation.zipCode}`}</span>
          <EditLocationAddress accountLocation={firstLocation} onAddressUpdated={onLocationAddressUpdated}/>
        </div>
        }
      </div>
    );
  };

  const renderCard = (item: AccountCard, i: number) => {
    let cardClassName = 'account-card-container card ordo-card-border ordo-shadow ';
    if (item.selected) {
      cardClassName += ' selected';
    }

    return (
      <div data-testid={`account-card-${i}`}
        className={cardClassName}
        tabIndex={i}
        role='checkbox'
        aria-checked={item.selected}
        key={item.id}
        id={item.id}
        onClick={(event) => {
          event.preventDefault();
          toggleSelectedAccount(item);
        }}
        // Accessibility requirement
        onKeyDown={(event: any) => {
          if(event.target.id === item.id) {
            event.preventDefault();
            toggleSelectedAccount(item);
          }
        }}
      >
        <div className='account-card'>
          <span className="account-name text-limit" data-testid={`account-card-${i}-name`}>
            {item.isNewAccount() && <span className="new-account-label">New! </span>}
            {item.name}
          </span>
          {renderCardAssignmentsInfo(item)}
          {renderCardInfo(item)}
        </div>
      </div>
    );
  };

  const renderNoResultsFound = () => {
    return(
      <span>no results found</span>
    );
  };

  const renderAccounts = () => {
    const accounts = viewModel.actualAccountsPage;
    const accountCards = accounts.filter((acc) => acc.visible);
    return (
      <div data-testid='account-list' className={accountCards.length > 0 ? 'account-list' : 'no-accounts-found'}>
        {accountCards.length > 0 ? accountCards.map(renderCard) : renderNoResultsFound()}
      </div>
    );
  };

  const onRepAssignmentModalSubmit = async () => {
    showSpinner();
    try {
      let updated = viewModel.withRepAssignmentModalOpen(false);
      setViewModel(updated);
      updated = await updated.clearAllSelectedAccounts();
      updated = await updated.refreshRepAssignments(orgId);
      setViewModel(updated);
      successToast('Assignments created successfully');
    } catch (e) {
      errorToast('Something went wrong creating the assignments');
    }finally {
      hideSpinner();
    }
  };

  const onEditAssignmentModalSubmit = async () => {
    showSpinner();
    try {
      let updated = viewModel.withEditAssignmentsModalOpen(false);
      setViewModel(updated);
      updated = await updated.clearAllSelectedAccounts();
      updated = await updated.refreshRepAssignments(orgId);
      setViewModel(updated);
      successToast('Assignments edited successfully');
    } catch (e) {
      errorToast('There was a problem editing the assignments');
    }finally {
      hideSpinner();
    }
  };

  const onRepAssignmentModalClose = () => setViewModel(viewModel.withRepAssignmentModalOpen(false));
  const onEditAssignmentModalClose = () => setViewModel(viewModel.withEditAssignmentsModalOpen(false));
  const animationToPlay = drawerExpanded  ? 'account-list-expanded' : 'account-list-collapsed';
  return <div className='position-relative'>
    <div className={`account-box floating-panel ordo-shadow ordo-card-border ${viewModel.getDrawerState().shouldPlay ? animationToPlay : ''}`}>
      {viewModel.assignmentModalOpen && <RepAssignmentModal
        api={api}
        isOpen={viewModel.assignmentModalOpen}
        onClose={onRepAssignmentModalClose}
        onSubmit={onRepAssignmentModalSubmit}
        selectedAccounts={viewModel.accountsSelected()}
      />}
      {viewModel.editAssignmentModalOpen && <EditRepAssignmentModal
        api={api}
        isOpen={viewModel.editAssignmentModalOpen}
        onClose={onEditAssignmentModalClose}
        onSubmit={onEditAssignmentModalSubmit}
        assignment={viewModel.selectedAssignment}
      />}
      <div className={`${drawerExpanded ? 'search-and-assignment' : 'search-and-assignment--collapsed'}`}>
        <div className="search-and-assignment__search-section">
          <div className="search-and-assignment__search-section__input-and-filters">
            {renderSearchInput()}
            {drawerExpanded && canSeeFilters && renderFilters()}
          </div>
        </div>
        <div className="search-and-assignment__assignment-section">
          {renderAssignRepButton()}
          {renderLinkAccountsButton()}
          <div className='search-and-assignment--collapsed__search-section__action-buttons'>
            {renderSelectAllButton()}
            {renderClearSelectionButton()}
          </div>
        </div>
        <div className={`drawer-toggle-button ${drawerExpanded ? '' : 'drawer-toggle-button--collapsed'}`}>
          <OrdoButton
            dataTestId="account-list-drawer-toggle"
            onClick={()=>setViewModel(viewModel.updateDrawerState({shouldPlay: true, expand: !drawerExpanded}))}
            text=''
            firstChild={<FontAwesomeIcon icon={drawerExpanded ? faChevronLeft : faChevronRight}/>}
            disabled={false}
            category='primary'
          />
        </div>
      </div>
      <div className={`account-list-container ${drawerExpanded ? '' : 'collapsed'}`}>
        {renderAccounts()}
      </div>
      <div className='account-list-footer'>
        <OrdoPagination
          currentPage={viewModel.page}
          totalOfPages={viewModel.totalAmountOfPages()}
          onPageChange={onPageChange}/>
        <span className="selected-account-count"
          data-testid="selected-account-count"> {`(${viewModel.numRetailersSelected()}) accounts selected`}
        </span>
      </div>
    </div>
    {linkAccountsModalOpen && viewModel.getParentAccountSelected() && <LinkAccountsModal
      parentAccount={viewModel.getParentAccountSelected()!}
      locations={viewModel.getAllLocations(currentMember)}
      selectedLocations={viewModel.locationsFromAccountsSelected(currentMember)}
      isOpen={linkAccountsModalOpen}
      onClose={toggleLinkAccountsModal}
      orgId={orgId}
      onLocationAddressUpdated={onLocationAddressUpdated}
      onSubmit={(_a: Account) => onReloadPage()}
    />}
    {unassignAccountModalOpen && selectedAccountToUnassign && <ConfirmationModal show={unassignAccountModalOpen}
      onClose={closeUnassignAccountModal}
      onSubmit={() => onSubmitUnassignAccount(currentMember, selectedAccountToUnassign!)}
      actionText='unassign'
      confirmationText="Are you sure you want to unassign account?"
    />}
  </div>;
}
