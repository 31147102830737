import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Activity} from '../../models/sales-activity/Activity';
import {Note} from '../../models/Note';
import {TimelineSubdivision} from '../../models/TimeSlot';
import {TimelinePopup} from './TimelinePopup';


// stack the items vertically within the max height
function verticalOffset(index: number, total: number, max: number) {
  return ((max/(total+1)) * (index + 1)) - (max/2);
}


type TimelineSlotContentProps = {
  content: TimelineSubdivision,
  popupSide: 'left' | 'right'
  popupVerticalPlacement: 'top' | 'bottom'
  onEditNote: (note: Note) => void
  onEditActivity: (activity: Activity) => void
  onActivityCheckboxClicked: (activity: Activity) => void
  accountName: string
};

export const TimelineSlotContent = ({content, accountName, popupSide, popupVerticalPlacement, onActivityCheckboxClicked, onEditNote, onEditActivity}: TimelineSlotContentProps) => {
  const [clicked, setClicked] = useState(false);
  const [keepOpen, setKeepOpen] = useState(false);
  const total = content.items.length;

  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  return <div role="presentation" tabIndex={total > 0 ? 0 : undefined} onBlur={()=>setClicked(keepOpen || false)} onClick={()=>setClicked(!clicked)} className={`time-slot ${ clicked ? 'showing-info' : ''} ${ (clicked && (total > 1)) ? 'stacked' : ''}`}>
    {
      content.items.map((item, index) =>
        <div className={`timeline-item ${item.style()}` } id={item.id()} key={item.id()}
          style={total > 0 ? { marginTop: `${verticalOffset(index, total, 3)}em`} : undefined}
        >
          <FontAwesomeIcon icon={item.icon()}/>
        </div>    )
    }
    {clicked && <TimelinePopup popupSide={popupSide} popupVerticalPlacement={popupVerticalPlacement}
      timeSlot={content.timeSlot} timelineItems={content.items} onActivityCheckboxClicked={onActivityCheckboxClicked}
      onEditActivity={onEditActivity} onEditNote={onEditNote} setKeepOpen={setKeepOpen} accountName={accountName}/>}
  </div>;
};

export default TimelineSlotContent;
