import Fuse from 'fuse.js';
import {isEmpty, uniqBy} from 'lodash';
import {API, OrderFilter} from '../lib/api/ordoApi';
import OrderWithCurrentVersion from '../models/order-entry/OrderWithCurrentVersion';
import {Paginator, PaginatorFactory} from '../utils/pagination/InMemoryPaginator';
import {Organization} from '../models/Organization';
import {fullName} from '../models/User';
import {OrderStatus} from '../models/order-entry/Order';

export type SelectableFilter = {
  label: string,
  value: string,
}

export const toSelectableOrderFilter = (label: string, value: string): SelectableFilter => {
  return {label: label, value: value};
};

enum FILTER_TYPE {
  ACCOUNT = 'account',
  ACCOUNT_ID = 'account_id',
  ORDER_STATUS = 'order status',
  SALES_REP = 'sales rep',
  SALES_REP_ID = 'sales_rep_id',
}

export type OrderHistoryViewModelModelInput = {
  searchedOrderNumber: string;
  searchedOrderAccountName: string;
  selectedAccounts: SelectableFilter[];
  selectedStatuses: SelectableFilter[];
  selectedSalesReps: SelectableFilter[];
  page: number;
  paginator: Paginator<OrderWithCurrentVersion>;
  orderHistoryFilter: OrderHistoryFilter;
}

interface OrderHistoryFilter {
  salesReps(): SelectableFilter[]
  accounts(): SelectableFilter[]
  ordersStatus(): SelectableFilter[]
}

class InMemoryOrderHistoryFilter implements OrderHistoryFilter {
  constructor(private orders: OrderWithCurrentVersion[]) {
  }

  public salesReps() {
    return uniqBy(this.orders.map(order => toSelectableOrderFilter(fullName(order.currentVersion.assignedSalesRep), order.currentVersion.assignedSalesRep.id)), 'value');
  }

  public accounts() {
    return uniqBy(this.orders.map(order => toSelectableOrderFilter(order.accountName, order.accountId())), 'value');
  }

  ordersStatus(): SelectableFilter[] {
    return uniqBy(this.orders.map(order => toSelectableOrderFilter(order.statusToString(), order.statusToString())), 'value');
  }
}

class BackendOrderHistoryFilter implements OrderHistoryFilter {
  constructor(private api: API, private organization: Organization, private accountsWithOrders: any[]) {
  }

  public salesReps() {
    return this.organization.members.map(member => member.user).map(member => toSelectableOrderFilter(fullName(member), member.id));
  }

  public accounts() {
    return this.accountsWithOrders.map(account => {
      return toSelectableOrderFilter(account.name, account.id);
    });
  }

  ordersStatus(): SelectableFilter[] {
    return Object.values(OrderStatus).map(status => toSelectableOrderFilter(status, status));
  }
}

export default class OrderHistoryViewModel {
  public orders: OrderWithCurrentVersion[];
  public filteredOrders: OrderWithCurrentVersion[];
  public searchedOrderNumber: string;
  public searchedOrderAccountName: string;
  public selectedAccounts: SelectableFilter[];
  public selectedStatuses: SelectableFilter[];
  public selectedSalesReps: SelectableFilter[];
  readonly page: number;
  private readonly paginator: Paginator<OrderWithCurrentVersion>;
  private readonly orderHistoryFilter: OrderHistoryFilter;

  public static ORDERS_PER_PAGE: number = 20;
  public static FIRST_PAGE_NUMBER: number = 1;

  constructor(viewModelInput: OrderHistoryViewModelModelInput) {
    this.searchedOrderNumber = viewModelInput.searchedOrderNumber;
    this.searchedOrderAccountName = viewModelInput.searchedOrderAccountName;
    this.selectedAccounts = viewModelInput.selectedAccounts;
    this.selectedSalesReps = viewModelInput.selectedSalesReps;
    this.selectedStatuses = viewModelInput.selectedStatuses;
    this.page = viewModelInput.page;
    this.paginator = viewModelInput.paginator;
    this.orders = this.paginator.getCurrentPageElements();
    this.filteredOrders = this.paginator.getCurrentPageElements();
    this.orderHistoryFilter = viewModelInput.orderHistoryFilter;
  }

  public static emptyOrderHistoryViewModel() {
    return new OrderHistoryViewModel({
      searchedOrderNumber: '',
      searchedOrderAccountName: '',
      selectedAccounts: [],
      selectedStatuses: [],
      selectedSalesReps: [],
      page: this.FIRST_PAGE_NUMBER,
      paginator: PaginatorFactory.empty(),
      orderHistoryFilter: new InMemoryOrderHistoryFilter([])
    }
    );
  }

  public async initialize(api: API, organization: Organization) {
    const {paginator, orderHistoryFilter} = await this.buildComponentsForPaginatedOrders(api, organization);
    return new OrderHistoryViewModel({
      searchedOrderNumber: '',
      searchedOrderAccountName: '',
      selectedAccounts: [],
      selectedStatuses: [],
      selectedSalesReps: [],
      page: OrderHistoryViewModel.FIRST_PAGE_NUMBER,
      paginator: paginator,
      orderHistoryFilter: orderHistoryFilter
    });
  }

  // This function is only for testing
  public async updateOrders(orders: OrderWithCurrentVersion[]) {
    return new OrderHistoryViewModel({
      searchedOrderNumber: '',
      searchedOrderAccountName: '',
      selectedAccounts: [],
      selectedStatuses: [],
      selectedSalesReps: [],
      page: 0,
      paginator: await this.buildDoNotPaginatePaginator(orders),
      orderHistoryFilter: new InMemoryOrderHistoryFilter([])
    });
  }

  public get actualOrdersPage() {
    return this.paginator.getCurrentPageElements();
  }

  public filterByAccounts(accounts: SelectableFilter[]) {
    this.selectedAccounts = accounts;
    return this.applyMultiFilters();
  }

  public filterByStatuses(statuses: SelectableFilter[]) {
    this.selectedStatuses = statuses;
    return this.applyMultiFilters();
  }

  public filterBySalesReps(salesReps: SelectableFilter[]) {
    this.selectedSalesReps = salesReps;
    return this.applyMultiFilters();
  }

  public async filterByOrderNumber(orderId: string) {
    this.searchedOrderNumber = orderId;
    return this.applyMultiFilters();
  }

  public async filterByOrderNumberOrAccountName(orderNumberOrAccountName: string) {
    this.searchedOrderNumber = orderNumberOrAccountName;
    this.searchedOrderAccountName = orderNumberOrAccountName;
    return this.applyMultiFilters();
  }

  public salesReps() {
    return this.orderHistoryFilter.salesReps();
  }

  public accounts() {
    return this.orderHistoryFilter.accounts();
  }

  public ordersStatus() {
    return this.orderHistoryFilter.ordersStatus();
  }

  public async updatePage(page: number) {
    await this.paginator.paginate(OrderHistoryViewModel.ORDERS_PER_PAGE, page);

    return new OrderHistoryViewModel({
      searchedOrderNumber: this.searchedOrderNumber,
      searchedOrderAccountName: this.searchedOrderAccountName,
      selectedAccounts: this.selectedAccounts,
      selectedStatuses: this.selectedStatuses,
      selectedSalesReps: this.selectedSalesReps,
      page: page,
      paginator: this.paginator,
      orderHistoryFilter: this.orderHistoryFilter
    });
  }

  private async applyMultiFilters() {
    const newFilter: OrderFilter = {
      orderNumber: this.searchedOrderNumber,
      orderAccountName: this.searchedOrderAccountName,
      accountIds: this.selectedAccounts.map(account => account.value),
      orderStatuses: this.selectedStatuses.map(status => status.value),
      salesReps: this.selectedSalesReps.map(status => status.value)
    };
    const paginator = this.paginator.withFilter(newFilter);
    await paginator.paginate(OrderHistoryViewModel.ORDERS_PER_PAGE, OrderHistoryViewModel.FIRST_PAGE_NUMBER);
    return new OrderHistoryViewModel({
      searchedOrderNumber: this.searchedOrderNumber,
      searchedOrderAccountName: this.searchedOrderAccountName,
      selectedAccounts: this.selectedAccounts,
      selectedStatuses: this.selectedStatuses,
      selectedSalesReps: this.selectedSalesReps,
      page: OrderHistoryViewModel.FIRST_PAGE_NUMBER,
      paginator: paginator,
      orderHistoryFilter: this.orderHistoryFilter
    });
  }

  public noOrdersToDisplayMessage() {
    if(isEmpty(this.orders)) return 'No orders found';
    if(isEmpty(this.filteredOrders)) return 'No orders match the selected filters';
    return '';
  }

  public totalAmountOfPages() {
    return this.paginator.amountOfPages(OrderHistoryViewModel.ORDERS_PER_PAGE);
  }

  updateOrder(updatedOrder: OrderWithCurrentVersion) {
    this.orders = this.orders.filter(order => order.id !== updatedOrder.id);
    this.orders.push(updatedOrder);

    return new OrderHistoryViewModel({
      searchedOrderNumber: this.searchedOrderNumber,
      searchedOrderAccountName: this.searchedOrderAccountName,
      selectedAccounts: this.selectedAccounts,
      selectedStatuses: this.selectedStatuses,
      selectedSalesReps: this.selectedSalesReps,
      page: this.page,
      paginator: this.paginator,
      orderHistoryFilter: this.orderHistoryFilter,
    });
  }

  private async buildDoNotPaginatePaginator(orders: OrderWithCurrentVersion[]) {
    return PaginatorFactory.doNotPaginate(orders, (ordersToFilter: OrderWithCurrentVersion[], filter: OrderFilter) => {
      const filterOrders = this.filterOrdersByNumber(ordersToFilter, filter.orderNumber);
      const filterOrdersByAccountName = this.filterOrdersByAccountName(filterOrders, filter.orderAccountName);
      if (isEmpty(filter.accountIds) && isEmpty(filter.orderStatuses) && isEmpty(filter.salesReps)) {
        return filterOrdersByAccountName;
      }
      return filterOrdersByAccountName.filter(order => {
        return this.filterCondition(order, filter.accountIds, FILTER_TYPE.ACCOUNT_ID) &&
            this.filterCondition(order, filter.orderStatuses, FILTER_TYPE.ORDER_STATUS) &&
            this.filterCondition(order, filter.salesReps, FILTER_TYPE.SALES_REP_ID);
      });
    });
  }

  private filterOrdersByNumber(orders: OrderWithCurrentVersion[], orderNumberFilter: string | undefined): OrderWithCurrentVersion[] {
    if (orderNumberFilter && orderNumberFilter !== '') {
      const searcher = new Fuse(orders,
        {
          keys: ['orderNumber'],
          threshold: 0.0,
          ignoreLocation: true,
          findAllMatches: true,
        });
      return searcher.search(orderNumberFilter).map(res => res.item);
    }
    return orders;
  }

  private filterOrdersByAccountName(orders: OrderWithCurrentVersion[], orderAccountNameFilter: string | undefined): OrderWithCurrentVersion[] {
    if (orderAccountNameFilter && orderAccountNameFilter !== '') {
      const searcher = new Fuse(orders,
        {
          keys: ['currentVersion.account.name'],
          threshold: 0.0,
          ignoreLocation: true,
          findAllMatches: true,
        });
      return searcher.search(orderAccountNameFilter).map(res => res.item);
    }
    return orders;
  }


  private filterCondition(order: OrderWithCurrentVersion, checkedOptions: string[] | undefined, filterType: string): boolean {
    return isEmpty(checkedOptions) || (checkedOptions || []).some(option => option === this.getFilteredValue(order, filterType));
  }

  private getFilteredValue(order: OrderWithCurrentVersion, filter: string): string {
    if (filter === FILTER_TYPE.ACCOUNT) return order.accountName;
    if (filter === FILTER_TYPE.ACCOUNT_ID) return order.accountId();
    if (filter === FILTER_TYPE.SALES_REP) return order.salesRepName;
    if (filter === FILTER_TYPE.SALES_REP_ID) return order.currentVersion.assignedSalesRep.id;
    if (filter === FILTER_TYPE.ORDER_STATUS) return order.statusToString();
    return '';
  }

  private async buildComponentsForPaginatedOrders(api: API, organization: Organization) {
    const paginatorPromise = this.buildBackendPaginator(api, organization);
    const accountLocationsPromise = api.getCurrentUserAccountsWithOrders(organization.id);

    const [paginator, accountLocations] = await Promise.all([paginatorPromise, accountLocationsPromise]);
    const orderHistoryFilter = new BackendOrderHistoryFilter(api, organization, this.sortAccountLocations(accountLocations));
    return {paginator: paginator, orderHistoryFilter: orderHistoryFilter};
  }

  private sortAccountLocations(accountLocations: any[]) {
    return accountLocations.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  private buildBackendPaginator(api: API, organization: Organization) {
    return PaginatorFactory.inBackend((page: number, ordersPerPage: number, filter: any) => api.getCurrentUserOrders(organization.id, page, ordersPerPage, filter),
      OrderHistoryViewModel.FIRST_PAGE_NUMBER,
      OrderHistoryViewModel.ORDERS_PER_PAGE,
      {accountIds: []});
  }
}
