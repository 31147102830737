import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import OrderWithCurrentVersion from '../../../models/order-entry/OrderWithCurrentVersion';
import OrderLineItems from '../../components/order-history/OrderLineItems';
import {isMobileResolution} from '../../../context/resolutions';
import {
  OrderLineColumnsForDetails,
  OrderLineColumnsForMobileDetails
} from '../../components/order-history/OrderLineItemsColumns';
import {useResolution} from '../../../context/UseResolutions';
import {PRIMARY_COLOR} from '../../../constants';
import OrderDeliverySummary from '../../components/order-history/OrderDeliverySummary';
import OrderPaymentSummary from '../../components/order-history/OrderPaymentSummary';
import {OrderStatus} from '../../../models/order-entry/Order';

const headerStyles = {
  backgroundColor: PRIMARY_COLOR,
  color: 'white',
  height: '2em',
  padding: '0 0.5em',
  fontSize: '0.75em',
};

const summaryStyles = {
  ...headerStyles,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

type MobileOrderExpandedDetailsProps = {
  orderVersion: OrderWithCurrentVersion,
  status: string, onClose: () => void,
  landscape: boolean,
  onUpdateOrder: (status: OrderStatus) => void
  onUpdateOrderDisplayStatus: (status: OrderStatus) => void

};
export const MobileOrderExpandedDetails = ({orderVersion, status, onClose, landscape, onUpdateOrder, onUpdateOrderDisplayStatus}: MobileOrderExpandedDetailsProps) => {
  const resolution = useResolution();

  return(
    <div className='mobile-order-expanded-details'>
      <div className='mobile-order-expanded-details-header'>
        <div>
          <span className='account-name'>{orderVersion.accountName}</span>
          <div className='license-number-and-status'>
            <span>{`order #: ${orderVersion.orderNumber}`}</span>
            <span className='order-status'>{status}</span>
          </div>
        </div>
        <FontAwesomeIcon className='exit-icon' size='sm' icon={faTimes} onClick={onClose}/>
      </div>
      <div className={`mobile-order-expanded-details-order ${landscape ? 'landscape' : ''}`}>
        <div className="order-details-items">
          <OrderLineItems order={orderVersion} headerStyles={headerStyles}
            columns={ isMobileResolution(resolution) ? OrderLineColumnsForMobileDetails : OrderLineColumnsForDetails}/>
        </div>
      </div>
      <div className={`mobile-order-expanded-details-delivery-summary ${landscape ? 'landscape' : ''}`}>
        <div className='order-payment-summary__title' style={summaryStyles}>
          delivery summary
        </div>
        <OrderDeliverySummary order={orderVersion} onUpdateOrderStatus={onUpdateOrder} onUpdateOrderDisplayStatus={onUpdateOrderDisplayStatus}
          showButtons/>
      </div>
      <div className={`mobile-order-expanded-details-order-payment-summary ${landscape ? 'landscape' : ''}`}>
        <div className='order-payment-summary__title' style={summaryStyles}>
          payment summary
        </div>
        <OrderPaymentSummary
          orderSummary={orderVersion.summary}
          invoiceUrl={orderVersion.currentVersion.invoiceUrl}
        />
      </div>
    </div>
  );
};