import {OrderEntryMode} from './index';
import {OrderEntryStep} from '../OrderEntryViewModel';
import OrderWithCurrentVersion from '../../../models/order-entry/OrderWithCurrentVersion';
import {API} from '../../../lib/api/ordoApi';
import {Cart, SerializableCart} from '../../../models/order-entry/Cart';
import {ProductWithAvailability} from '../../../models/productWithAvailability';
import {OrderSummary, PaymentMethod} from '../../../models/order-entry/Summary';
import Contact from '../../../models/order-entry/Contact';
import { OrderEntryCartContextData } from '../../../context/OrderEntryCartContext';
import {EditOrderInput, OrderInput, OrderStatus} from '../../../models/order-entry/Order';
import ROUTE_NAMES from '../../../routes/ROUTE_NAMES';
import { AccountLocation } from '../../../models/Account';
import { OrganizationLicense } from '../../../models/OrganizationLicense';
import { User } from '../../../models/User';

export class Edit implements OrderEntryMode {
  public constructor(
    public readonly order: OrderWithCurrentVersion,

    // newStatus allows the caller to include an updated status in the edit order request
    public readonly newStatus?: OrderStatus
  ) {
  }

  public submitOrderCTAText(): string {
    return 'save changes';
  }

  public initialStep(): OrderEntryStep {
    return OrderEntryStep.VerifyOrder;
  }

  public orderNotes(): string {
    return this.order.currentVersion.orderNotes;
  }

  public async initializeAccountWithContacts(
    _selectedAccountLocation: AccountLocation | undefined,
    _accountLocations: AccountLocation[],
    api: API,
    orgId: string,
    _accountId?: string): Promise<{ selectedAccountLocation: AccountLocation; contacts: Contact[] }> {
    const selectedAccLocation = this.order.accountLocation;
    const contacts = await api.getAccountContacts(orgId, selectedAccLocation!.accountId);
    return {selectedAccountLocation: selectedAccLocation, contacts: contacts};
  }

  public initializeCart(
    _serializableCart: SerializableCart,
    selectedAccountLocation: AccountLocation | undefined,
    products: ProductWithAvailability[]): Cart {
    return Cart.recreateFromOrder(this.order, products, selectedAccountLocation);
  }

  public orderSummary(): OrderSummary | undefined {
    return this.order.summary;
  }

  public selectedPaymentMethod(): PaymentMethod | undefined {
    return this.order.summary.paymentMethod;
  }

  public termPeriod(): number | undefined {
    return this.order.summary.termPeriod;
  }

  public selectedDeliveryDay(): Date | undefined{
    return this.order.currentVersion.deliveryDay;
  }

  public contact(): Contact | undefined {
    return this.order.contact;
  }

  public selectedDistributor(): OrganizationLicense | undefined {
    return this.order.distributor;
  }

  public saveCart(_cart: Cart, _orderEntryCartContextData: OrderEntryCartContextData): void {
    // nothing to do here.
  }

  public async submitOrder(api: API, orgId: string, order: OrderInput): Promise<void> {
    const editOrderInput: EditOrderInput = {
      ...order,
      status: this.newStatus
    };
    await api.editOrder(orgId, editOrderInput, this.order.orderId);
  }

  /**
   * availability while editing an order is unique in that we want to consider the inventory that is currently being reserved by this order.
   */
  public async availability(api: API, orgId: string, distributorOrganizationLicenseId: string, accountId: string): Promise<ProductWithAvailability[]> {
    const products = await api.getProductsWithAvailability(orgId, distributorOrganizationLicenseId, accountId);

    if(this.order.distributor.id === distributorOrganizationLicenseId) { // we only want this used stock to be available if the distributor has not changed
      for (const product of products) {
        if (!product.unitSaleOnly) {
          product.availability += this.order.casesForProduct(product.id)*product.unitsPerCase;
        }
        if (!product.caseSaleOnly) {
          product.availability += this.order.unitsForProduct(product.id);
        }
      }
    }

    return products;
  }

  public getSuccessMessage() {
    return 'successfully updated the order';
  }

  public nextRoute() {
    return ROUTE_NAMES.SALES_ACTIVITY;
  }

  public nextMobileRoute() {
    return ROUTE_NAMES.SALES_ACTIVITY;
  }

  public distributorTaxesEnabled(): boolean {
    return !!this.order.currentVersion.summary.distributorTaxesEnabled;
  }

  public assignedSalesRep(): User | undefined {
    return this.order.currentVersion.assignedSalesRep;
  }

  public isEdit(): boolean {
    return true;
  }
}
