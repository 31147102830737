import React, {useContext, useState} from 'react';
import OrderWithCurrentVersion from '../../../models/order-entry/OrderWithCurrentVersion';
import OrderInformationViewModel from '../../../application-models/order-history/OrderInformationViewModel';
import {API} from '../../../lib/api/ordoApi';
import {useAPI} from '../../../context/OrdoApiContext';
import MobileDisplayAsModal from '../../components/common/MobileDisplayAsModal';
import {MobileOrderGeneralInformation} from './MobileOrderGeneralInformation';
import {MobileOrderExpandedDetails} from './MobileOrderExpandedDetails';
import {OrderStatus} from '../../../models/order-entry/Order';
import {UserSessionContext} from '../../../context/UserSessionContext';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../components/OrdoSpinner';
import useOrdoToasts from '../../../hooks/useOrdoToasts';

export const MobileOrderHistoryOrderItem = ({orderVersion, landscape, onUpdateOrder}: {orderVersion: OrderWithCurrentVersion, landscape: boolean, onUpdateOrder: Function}) => {
  const api: API = useAPI();
  const [orderInformationViewModel, setOrderInformationViewModel] = useState(new OrderInformationViewModel(orderVersion, api));
  const [expanded, setExpanded] = useState(false);
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {errorToast, successToast} = useOrdoToasts();

  const updateOrderStatus = async (status: OrderStatus) => {
    showSpinner();
    orderInformationViewModel.updateOrderStatus(orderVersion.orderId, organization.id, status)
      .then((updatedViewModel) => {
        successToast('order status updated');
        setOrderInformationViewModel(updatedViewModel);
        onUpdateOrder(updatedViewModel.order);
      })
      .catch(()=> errorToast('could not update order status'))
      .finally(() => {
        hideSpinner();
        setExpanded(!expanded);
      });
  };

  const updateOrderDisplayStatus = (status: OrderStatus) => {
    const updatedViewModel = orderInformationViewModel.updateOrderStatusDisplay(status);
    setOrderInformationViewModel(updatedViewModel);
    onUpdateOrder(updatedViewModel.order);
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <MobileOrderGeneralInformation orderInformationViewModel={orderInformationViewModel} onClick={() => setExpanded(true)}/>
      {expanded && <MobileDisplayAsModal show={expanded} onClose={() => {
        setExpanded(false);
      }}>
        <MobileOrderExpandedDetails orderVersion={orderInformationViewModel.getOrder()}
          status={orderInformationViewModel.orderStatusToString()}
          onClose={() => setExpanded(false)}
          landscape={landscape}
          onUpdateOrder={updateOrderStatus}
          onUpdateOrderDisplayStatus={updateOrderDisplayStatus}
        />
      </MobileDisplayAsModal>}
    </OrdoSpinner>
  );
};