import {isSalesRep, Role} from '../Roles';
import {User} from '../User';
import {AllocationCollection} from '../allocation/allocationCollection';
import { NotMemberInput } from './NotMember';
import { Member } from './Member';

export type ActualMemberInput = NotMemberInput & {user: User}

export class ActualMember extends Member {
  constructor(role: Role, public readonly user: User) {
    super(role, user.email, user.id);
  }

  public hasAllocations(allocations: AllocationCollection): boolean {
    return allocations.someIsAssignedToUser(this.user!);
  }

  public isSalesRep(): boolean {
    return isSalesRep(this.role);
  };

  public hasEmail(email: string): boolean {
    return this.user.email === email;
  }

  public getUserId(): string {
    return this.user.id;
  }

  public getPhone(): string {
    return this.user.phone;
  }

  public getName(): string {
    return `${this.user.firstName} ${this.user.lastName}`;
  }

  public hasUserId(id: string): boolean {
    return this.getUserId() === id;
  }

  public get alreadyMember() {
    return true;
  }

  public memberName() {
    return `${this.user.firstName} ${this.user.lastName}`;
  }
}
