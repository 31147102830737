import React from 'react';
import {
  ActivityCompletionResponseProps,
  MultiValueActivityCompletionResponse,
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import {OrdoCheckbox} from '../../OrdoCheckbox';


export const CheckboxResponse = ({
  response,
  updateResponse
}: ActivityCompletionResponseProps) => {
  const checkboxResponse = response as MultiValueActivityCompletionResponse;

  const onOptionSelected = (option: number) => {
    const {selectedOptions} = checkboxResponse.body;
    if(selectedOptions.includes(option)){
      const index = selectedOptions.findIndex((o => o === option));
      selectedOptions.splice(index, 1);
    } else {
      selectedOptions.push(option);
    }

    updateResponse(checkboxResponse);
  };

  return <div className='checkbox-response'>
    {checkboxResponse.options.map((option, index) => {
      return <div className='checkbox-response__row' key={option}>
        <OrdoCheckbox id={option+index} checked={checkboxResponse.body.selectedOptions.includes(index)} onChange={()=>onOptionSelected(index)}/>
        <span>{option}</span>
      </div>;
    })}
  </div>;
};