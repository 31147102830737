import React, {useEffect, useState} from 'react';
import {debounce} from 'lodash';
import {ContactsPagePaginationAndFilter} from '../paginationAndFilters/ContactsPagePaginationAndFilter';
import '../../../scss/ordo/contactsPage/mobile/mobile-contacts-page.scss';
import OrdoPageTitle from '../../components/common/OrdoPageTitle';
import OrdoButton from '../../components/common/OrdoButton';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import {ContactWithAccountName} from '../ContactsPage';
import {OrdoPagination} from '../../components/pagination/OrdoPagination';
import AddContactModal from '../../components/modals/AddContactModal';
import Contact from '../../../models/order-entry/Contact';
import Account from '../../../models/Account';
import EditContactModal from '../../components/modals/EditContactModal';
import {MobileContactsList} from './MobileContactsList';

type MobileContactsPageProps = {
  contactsPaginationAndFilters: ContactsPagePaginationAndFilter,
  onPageUpdate: (page: number) => void,
  onFilterUpdate: (updated: ContactsPagePaginationAndFilter) => void,
  organizationId: string
  accounts: Account[]
}
export const MobileContactsPage = ({contactsPaginationAndFilters, onPageUpdate, onFilterUpdate, organizationId, accounts} : MobileContactsPageProps) => {
  const [openAddContactModal, setOpenAddContactModal] = useState(false);
  const [openEditContactModal, setOpenEditContactModal] = useState(false);
  const [contactToEdit, setContactToEdit] = useState<{ contact: Contact, index: number } | undefined>(undefined);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }, [window.innerWidth, window.innerHeight]);

  const landscape = () => screenWidth > screenHeight;

  const filterByContactNameOrTitle = debounce(async (contactName: string) => {
    onFilterUpdate(await contactsPaginationAndFilters.filterByContactNameOrTitle(contactName));
  }, 500);

  const addContact = (contact: Contact) => {
    onFilterUpdate(contactsPaginationAndFilters.addContact(contact));
    return Promise.resolve();
  };

  const updateContact = (index: number) => (contact: ContactWithAccountName) => {
    onFilterUpdate(contactsPaginationAndFilters.updateContact(contact, index));
  };

  return (<div className="mobile-contacts-page-container">
    <div className={`mobile-contacts-page-header ${landscape() ? 'landscape' : ''}`}>
      <OrdoPageTitle title='contacts'
        buttons={[]}/>
      <div className="mobile-add-contact-button">
        <OrdoButton disabled={false} text="add contact" category="primary" onClick={() => setOpenAddContactModal(!openAddContactModal)} dataTestId=""/>
      </div>
    </div>
    <div className='mobile-contacts-page-filters'>
      <OrdoInput
        bottomMargin={false}
        inputName='search-order-input'
        value={contactsPaginationAndFilters.searchedContactNameOrTitle}
        placeholder='search by name'
        inputSize='large'
        onChange={filterByContactNameOrTitle}
      />

    </div>
    <div className='mobile-contacts-page-list'>
      <MobileContactsList contacts={contactsPaginationAndFilters.filteredContacts} onSetContactToEdit={(contactWithAccountName, index) => {
        setContactToEdit({contact: contactWithAccountName.contact, index: index});
        setOpenEditContactModal(true);}
      }/>
      <div className='contacts-list-footer'>
        <OrdoPagination
          currentPage={contactsPaginationAndFilters.page}
          totalOfPages={contactsPaginationAndFilters.totalAmountOfPages()}
          onPageChange={onPageUpdate}/>
      </div>
    </div>
    {openAddContactModal && <AddContactModal isOpen={openAddContactModal}
      onClose={() => setOpenAddContactModal(!openAddContactModal)}
      onSubmit={addContact}
      displayLabel={false}
      orgId={organizationId} accountId=""
      accounts={accounts}/>
    }
    {
      openEditContactModal && contactToEdit?.contact && <EditContactModal isOpen={openEditContactModal} accounts={accounts} displayLabel={false}
        onClose={() => {
          setOpenEditContactModal(!openEditContactModal);
          setContactToEdit(undefined);
        }} onSubmit={() => Promise.resolve()} orgId={organizationId} contact={contactToEdit.contact} updateContactWithAccount={updateContact(contactToEdit.index)}/>
    }
  </div>);
};