import {ActivityQuestion, ActivityQuestionType} from '../../sales-activity/ActivityQuestion';
import {OrdoFile} from '../../OrdoFile';

export const ACTIVITY_RESPONSE_FILE_IDENTIFIER = 'activity_response_file';

export type ActivityCompletionResponseProps = { response: ActivityCompletionResponse, updateResponse: Function };
export type ActivityCompletionResponseDisplayProps = { response: ActivityCompletionResponse};

export type StaticQuestionResponse = {
  value: string
}

export type DynamicQuestionResponse = {
  selectedOptions: number[]
}

export type FileURLQuestionResponse = {
  fileURLs: string[],
  filenames: string[]
}

type FileUploadQuestionResponse = {
  files: OrdoFile[],
}

export type DateTimeQuestionResponse = {
  date: Date | null;
  time: Date | null;
}

export class ActivityCompletionResponse {
  public readonly index: number
  public readonly isRequired: boolean
  public readonly questionTitle: string
  public readonly questionType: ActivityQuestionType

  constructor(index: number, isRequired: boolean, questionTitle: string, questionType: ActivityQuestionType) {
    this.index = index;
    this.isRequired = isRequired;
    this.questionTitle = questionTitle;
    this.questionType = questionType;
  }

  public isFulfilled(): boolean {
    return !this.isRequired || (this.isRequired && this.hasValidResponse());
  }

  public hasValidResponse() {
    return false;
  }

  public asFormData() {
    return this;
  }

  public hasFiles() {
    return false;
  }

  public getFiles(): File[] {
    throw new Error('no file in this response type');
  }

  public validateBody(body: any, isValid: boolean) {
    if(!isValid && this.isRequired) {
      throw new Error(`invalid body for response type ${this.questionType}: ${JSON.stringify(body)}`);
    }
  }
}

export class SingleValueActivityCompletionResponse extends ActivityCompletionResponse {
  public body: StaticQuestionResponse

  constructor(index: number, isRequired: boolean, questionTitle: string, questionType: ActivityQuestionType, body?: StaticQuestionResponse) {
    super(index, isRequired,questionTitle, questionType);
    if(body) this.validateBody(body, !!body.value);
    this.body = body || { value: ''};
  }

  public hasValidResponse() {
    return !!this.body.value;
  }
}


export class MultiValueActivityCompletionResponse extends ActivityCompletionResponse {
  public body: DynamicQuestionResponse
  public options: string[]

  constructor(index: number, isRequired: boolean, questionTitle: string, questionType: ActivityQuestionType, options: string[], body?: DynamicQuestionResponse) {
    super(index, isRequired,questionTitle, questionType);
    if(body) this.validateBody(body, !!body.selectedOptions);
    this.options = options;
    this.body = body || { selectedOptions: []};
  }

  public hasValidResponse() {
    return this.body.selectedOptions.length > 0;
  }
}

export class FileURLActivityCompletionResponse extends ActivityCompletionResponse {
  public body: FileURLQuestionResponse;

  constructor(index: number, isRequired: boolean, questionTitle: string, questionType: ActivityQuestionType, body?: FileURLQuestionResponse) {
    super(index, isRequired,questionTitle, questionType);
    if(body) this.validateBody(body, !!body.fileURLs);
    this.body = body || { fileURLs: [], filenames: []};
  }

  public hasValidResponse() {
    return this.body.fileURLs.length > 0;
  }
}


export class FileUploadActivityCompletionResponse extends ActivityCompletionResponse {
  public body: FileUploadQuestionResponse;

  constructor(index: number, isRequired: boolean, questionTitle: string, questionType: ActivityQuestionType) {
    super(index, isRequired,questionTitle, questionType);
    this.body = { files: []};
  }

  public hasValidResponse() {
    return this.body.files.length > 0;
  }

  public asFormData() {
    if(!this.isFulfilled()) {
      throw new Error('Files for upload not selected');
    }
    return {...this, body: {filenames: this.body.files.map(file=>file.name)}};
  }

  public hasFiles() {
    return this.hasValidResponse();
  }

  public getFiles() {
    return this.body.files.map(file => file.raw);
  }
}

export class DateTimeActivityCompletionResponse extends ActivityCompletionResponse {
  public body: DateTimeQuestionResponse;

  constructor(index: number, isRequired: boolean, questionTitle: string, questionType: ActivityQuestionType, body?: DateTimeQuestionResponse) {
    super(index, isRequired,questionTitle, questionType);
    if(body) this.validateBody(body, !!body.date);
    this.body = body || { date: null, time: null};
  }

  public hasValidResponse() {
    return !!this.body.date;
  }
}

export function createActivityCompletionResponse(question: ActivityQuestion){
  switch(question.questionType) {
  case ActivityQuestionType.TextResponse:
  case ActivityQuestionType.NumericResponse:
  case ActivityQuestionType.DollarValue:
  case ActivityQuestionType.ProductDropdown:
    return new SingleValueActivityCompletionResponse(question.index, question.isRequired, question.questionTitle, question.questionType);
  case ActivityQuestionType.Checkboxes:
  case ActivityQuestionType.MultipleChoice:
    return new MultiValueActivityCompletionResponse(question.index, question.isRequired, question.questionTitle, question.questionType, question.options || []);
  case ActivityQuestionType.DateTime:
    return new DateTimeActivityCompletionResponse(question.index, question.isRequired, question.questionTitle, question.questionType);
  case ActivityQuestionType.FileUpload:
    return new FileUploadActivityCompletionResponse(question.index, question.isRequired, question.questionTitle, question.questionType);
  default:
    throw new Error(`Unhandled question type: ${question.questionType}`);
  }
}