import React from 'react';
import OrderInformationViewModel from '../../../application-models/order-history/OrderInformationViewModel';
import OrdoButton from '../common/OrdoButton';
import {OrderStatus} from '../../../models/order-entry/Order';
import ConfirmationModal from '../common/ConfirmationModal';
import useModalToggle from '../../../hooks/useModalToggle';

type OrderExpandedDetailsProps = {
  orderInformationViewModel: OrderInformationViewModel;
  onUpdateOrderStatus: Function,
}

const TrackedOrderExpandedDetails = ({orderInformationViewModel, onUpdateOrderStatus} : OrderExpandedDetailsProps) => {
  const {closeModal,openModal,showModal} = useModalToggle();

  return (
    <div className='tracked-order-action-buttons'>
      <div className='tracked-order-action-buttons__cancel'>
        {orderInformationViewModel.isPending() && <OrdoButton
          disabled={false}
          text='cancel order'
          category='danger'
          onClick={openModal}
          dataTestId='cancel-order-button'
        />}
      </div>
      {orderInformationViewModel.invoiceURL() && <div className="tracked-order-action-buttons__view-invoice">
        <OrdoButton disabled={false} text="view invoice" category="primary" onClick={() => { window.open(orderInformationViewModel.invoiceURL(), '_blank'); }} dataTestId="orde-details-view-invoice"/>
      </div>}
      <ConfirmationModal show={showModal} onClose={closeModal} onSubmit={() => {
        closeModal();
        onUpdateOrderStatus(OrderStatus.Cancelled);
      }} confirmationText='Are you sure you want to cancel this order?'/>
    </div>);
};

export default TrackedOrderExpandedDetails;
