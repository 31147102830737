import React from 'react';
import {PendingInvitation} from '../../models/PendingInvitation';
import OrdoButton from '../components/common/OrdoButton';
import {useAPI} from '../../context/OrdoApiContext';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import OrdoSpinner from '../components/OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import {UserSession} from '../../models/UserSession';

export const PendingInvitationCard = ({pendingInvitation, onInvitationAction, onOrganizationSelected} : {pendingInvitation: PendingInvitation, onInvitationAction: Function, onOrganizationSelected: Function}) => {
  const api = useAPI();
  const {successToast, errorToast} = useOrdoToasts();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();


  const acceptInvitation = () => {
    showSpinner();
    api.acceptInvitation(pendingInvitation.invitationTokenId)
      .then((userSession: UserSession) => {
        onInvitationAction();
        onOrganizationSelected(userSession.currentOrganization);
        successToast(`Joined ${pendingInvitation.organizationName}`);
      })
      .catch(() => errorToast(`Could not join ${pendingInvitation.organizationName}`))
      .finally(hideSpinner);
  };

  const declineInvitation = () => {
    showSpinner();
    api.declineInvitation(pendingInvitation.invitationTokenId)
      .then(() => {
        onInvitationAction();
      })
      .catch(() => errorToast(`Could not decline ${pendingInvitation.organizationName} invite`))
      .finally(hideSpinner);
  };

  return(<div className='card card-fluid pending-invitation-card' key={`invitation-${pendingInvitation.invitationTokenId}`}>
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='organization-card__upper-section'>
        <h6 className="organization-card__upper-section__title">{pendingInvitation.organizationName}</h6>
      </div>
      <div className="card-body organization-card__body">
        <div className="organization-card__body__user-section">
          <h6 className="mb-0 organization-card__body__owner_title">owner</h6>
          <p className="text-muted mb-0 text-truncate organization-card__body__owner_name">
            {pendingInvitation.organizationOwnerName}
          </p>
          <p className="text-muted mb-0 text-truncate organization-card__body__owner_email">
            {pendingInvitation.organizationOwnerEmail}
          </p>
        </div>

        <div className="brands-title">
          <h6 className="mb-0 organization-card__body__pending-invite">pending invite</h6>
        </div>
        <div className="organization-card__body__invitations-container">
          <div className="invitation-buttons">
            <OrdoButton disabled={false} text='accept' category='primary' onClick={acceptInvitation} dataTestId=''/>
            <OrdoButton disabled={false} text='decline' category='cancel' onClick={declineInvitation} dataTestId=''/>
          </div>
        </div>
      </div>
    </OrdoSpinner>
  </div>);
};