import React, {useContext, useEffect, useState} from 'react';
import OrdoSpinner from '../components/OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import { ContactsPageFilters } from './filter/ContactsPageFilters';
import '../../scss/ordo/contactsPage/contacts-page.scss';
import { ContactsList } from './ContactsList';
import {useAPI} from '../../context/OrdoApiContext';
import {UserSessionContext} from '../../context/UserSessionContext';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import Contact from '../../models/order-entry/Contact';
import AddContactModal from '../components/modals/AddContactModal';
import Account from '../../models/Account';
import { ContactsPagePaginationAndFilter } from './paginationAndFilters/ContactsPagePaginationAndFilter';
import useOrganizationRedirect from '../../hooks/useOrganizationRedirect';
import {OrdoPagination} from '../components/pagination/OrdoPagination';
import {isMobileResolution, Resolution} from '../../context/resolutions';
import {useResolution} from '../../context/UseResolutions';
import { MobileContactsPage } from './mobile/MobileContactsPage';

export type ContactWithAccountName = {
  contact: Contact
  accountName: string
}
export const ContactsPage = () => {
  const { spinnerVisibility, showSpinner, hideSpinner } = useSpinnerToggle();
  const api = useAPI();
  const {errorToast} = useOrdoToasts();
  const [createContactModalOpen, setCreateContactModalOpen] = useState<boolean>(false);
  const [accountsNamesAndIds, setAccountNamesAndIds] = useState<Account[]>([]);
  const [contactsPaginationAndFilters, setContactsPaginationAndFilter] = useState<ContactsPagePaginationAndFilter>(ContactsPagePaginationAndFilter.empty());
  const {validateOrRedirect} = useOrganizationRedirect();
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const resolution: Resolution = useResolution();
  const inMobileResolution = isMobileResolution(resolution);


  const toggleCreateContactModalOpen = () => setCreateContactModalOpen(!createContactModalOpen);

  const displayableAccounts = accountsNamesAndIds.map(a => {
    return {accountId: a.id, accountName: a.name};
  });

  useEffect(() => {
    showSpinner();
    validateOrRedirect(() => {
      contactsPaginationAndFilters.initialize(api, organization)
        .then(setContactsPaginationAndFilter)
        .finally(hideSpinner);
    });
  }, [userSession.currentOrganization()] );

  const updatePage = (page: number) => {
    showSpinner();
    contactsPaginationAndFilters.updatePage(page)
      .then((updatedContactsPaginationAndFilters) => setContactsPaginationAndFilter(updatedContactsPaginationAndFilters))
      .catch(() => {
        errorToast(`Could not search page number ${page}`);
      })
      .finally(hideSpinner);
  };

  useEffect(() => {
    showSpinner();
    api.getAssignedAccountsForDropdown(organization.id)
      .then((accountsWithNameAndId: Account[]) => setAccountNamesAndIds(accountsWithNameAndId))
      .catch(() => errorToast('Cannot get accounts'))
      .finally(hideSpinner);
  }, []);

  const addContact = (contact: Contact) => {
    setContactsPaginationAndFilter(contactsPaginationAndFilters.addContact(contact));
    return Promise.resolve();
  };

  const updateContact = (contact: ContactWithAccountName, index: number) => {
    setContactsPaginationAndFilter(contactsPaginationAndFilters.updateContact(contact, index));
  };

  const renderContactsPage = () => {
    return <>
      <OrdoPageTitle title='contacts' buttons={[]}/>
      <div className="page-content-body">
        <ContactsPageFilters contactsPagePaginationAndFilter={contactsPaginationAndFilters} onFilterUpdate={setContactsPaginationAndFilter}/>
        <div className="contacts-list card card-fluid ordo-shadow ordo-card-border">
          <ContactsList contactsWithAccountName={contactsPaginationAndFilters.filteredContacts}
            displayableAccounts={displayableAccounts}
            openAddContactModal={toggleCreateContactModalOpen}
            onUpdateContact={updateContact}
            organizationId={organization.id}/>
          <div className='contacts-list-footer'>
            <OrdoPagination
              currentPage={contactsPaginationAndFilters.page}
              totalOfPages={contactsPaginationAndFilters.totalAmountOfPages()}
              onPageChange={updatePage}/>
          </div>
        </div>
      </div>
      <AddContactModal isOpen={createContactModalOpen} onClose={toggleCreateContactModalOpen} onSubmit={addContact} orgId={organization.id} accountId="" accounts={accountsNamesAndIds}/>
    </>;
  };

  const renderMobileContactsPage = () => <MobileContactsPage contactsPaginationAndFilters={contactsPaginationAndFilters}
    onPageUpdate={updatePage}
    organizationId={organization.id}
    accounts={accountsNamesAndIds}
    onFilterUpdate={setContactsPaginationAndFilter}/>;

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      {inMobileResolution ? renderMobileContactsPage() : renderContactsPage()}
    </OrdoSpinner>
  );
};