import React from 'react';
import {OrderVersionLineItem} from '../../../../models/order-entry/OrderVersionLineItem';
import {DiscountType, LineItemType} from '../../../../models/order-entry/Order';
import {Money} from '../../../../models/Money';
import OrdoButton from '../../../components/common/OrdoButton';

const salePrice = (item: OrderVersionLineItem) => {
  if(item.type === LineItemType.Case) {
    return <>
      <span>{item.wrappedCaseSalePrice()}</span>
      <span className='subtitle'>{item.wrappedCaseSalePriceSubText()}</span>
    </>;
  }
  return <>
    <span>{item.formattedSalesPrice()}</span>
  </>;
};

export const AccountActivityOrderExpandedProducts = ({items, invoiceURL, grandTotal, littleButton = false, keepPopupOpen}: {items: OrderVersionLineItem[], invoiceURL: string, grandTotal: Money, littleButton?: boolean, keepPopupOpen?: Function}) => {

  return(
    <div className='order-items' role='presentation'>
      {items.map((item: OrderVersionLineItem, index: number) =>
        <div className='order-item-container' key={item.id}>
          <img src={item.product.imageUrl} alt={item.product.name} className="product-image h6 mb-0 text-sm"/>
          <div className='product-info name'>
            <span className="product-name">{item.product.name}</span>
            <span>{`${item.product.categoryToString()}, ${item.product.geneticsToString()}`}</span>
          </div>
          <div className='product-info product-size'>
            <span className="product-name">{item.product.sizeToString()}</span>
            <span>{item.amountOfCasesOrUnits()}</span>
          </div>
          <div className='product-info'>
            {index === 0 && <span className='title'>price</span>}
            <div className="original-price">{salePrice(item)}</div>
          </div>
          <div className='product-info'>
            {index === 0 && <span className='title'>discount</span>}
            {/* eslint-disable-next-line no-nested-ternary */}
            <span className="discount">{item.discountType === DiscountType.Percentage ? `%${item.discountPercentage * 100}` : ( item.discountType === DiscountType.Dollar ? `${item.discountAmount().formatted()}` : '-')}</span>
          </div>
          <div className='product-info'>
            {index === 0 && <span className='title'>sale total</span>}
            <span className="sale-total">{item.saleTotal().formatted()}</span>
          </div>
        </div>

      )}
      <div className={`order-item-container-footer ${invoiceURL ? '' : 'right-align'}`} onClick={(event) => event.stopPropagation()} role='presentation'>
        {invoiceURL && <>
          {!littleButton && <OrdoButton disabled={false} text="view invoice" category="primary" onClick={() => {
            window.open(invoiceURL, '_blank');
          }} dataTestId="orde-details-view-invoice"/>}
          {littleButton && <div className='order-item-container-footer-button' role='presentation' onClick={(event) => {
            event.stopPropagation();
            if (keepPopupOpen) keepPopupOpen();
            window.open(invoiceURL, '_blank');
          }}>
            view invoice
          </div>}
        </>}
        <div className={`order-item-container-footer-total ${littleButton ? 'timeline-order-total' : ''}`}>{grandTotal.formatted()}</div>
      </div>

    </div>
  );
};