import {remove} from 'lodash';
import FormError, {FormFields} from '../errors/form_error';
import {API} from '../lib/api/ordoApi';
import Product from '../models/Product';
import {User} from '../models/User';
import {AllocationCollection} from '../models/allocation/allocationCollection';
import {OrgMemberAllocation} from '../models/allocation/orgMemberAllocation';
import {AccountAllocation} from '../models/allocation/accountAllocation';
import {Organization} from '../models/Organization';
import {SelectableOption, toSelectableOption} from '../pages/components/common/searchable-dropdown/selectableOption';
import {Allocation} from '../models/allocation/allocation';
import Account from '../models/Account';
import {OrganizationLicense} from '../models/OrganizationLicense';
import {toInformativeSelectableOption} from '../pages/components/common/searchable-dropdown/InformativeOption';

export class AllocationsViewModel {

  public static emptyAllocationsViewModel(api: API, product: Product, organization: Organization) {
    const allocationsCache = new Map<string, AllocationCollection>();
    let selectedDistributor;
    if(organization.licenses.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      selectedDistributor = organization.licenses[0];
    }
    return new AllocationsViewModel(api, allocationsCache, product, organization, [], [], AllocationCollection.empty(product), [], FormError.withoutError(),AllocationCollection.empty(product), selectedDistributor);
  }

  public static async initialize(api: API, inputOrg: Organization, product: Product) {
    if(inputOrg.licenses.length > 0) {
      const selectedDistributor = inputOrg.licenses[0];
      const [accounts, allocations, organization] = await Promise.all([
        api.getAssignedAccountsForDropdown(inputOrg.id),
        api.getAllocations(inputOrg.id, product, selectedDistributor!.id),
        api.getOrganization(inputOrg.id)
      ]);
      const orgMembersWithoutAllocations = this.getOrgMembersWithoutAllocations(organization, allocations);

      const emptyOrgMembersAllocations = orgMembersWithoutAllocations.map((saleRep) => {
        return new OrgMemberAllocation(0, product.id, saleRep, selectedDistributor!.id);
      });
      const selectableAccounts = this.getAccountsWithoutAllocations(accounts, allocations).map(account => toSelectableOption(account, account.name));

      const allocationsCollection = allocations.concat(emptyOrgMembersAllocations);

      const allocationsCache = new Map<string, AllocationCollection>().set(selectedDistributor!.id, allocationsCollection);

      return new AllocationsViewModel(api, allocationsCache, product, organization, accounts, orgMembersWithoutAllocations, allocationsCollection, selectableAccounts, FormError.withoutError(), allocationsCollection.accounts(), selectedDistributor);
    }
    return AllocationsViewModel.emptyAllocationsViewModel(api, product, inputOrg);
  }

  constructor(
    private api: API,
    private allocationsCache: Map<string, AllocationCollection>, // keys are distributors ids
    private product: Product,
    private organization: Organization,
    public accounts: Account[],
    private orgMembers: User[],
    private allocations: AllocationCollection,
    public selectableAccounts: SelectableOption<Account>[],
    private formError: FormError,
    public displayableAccountAllocations: AllocationCollection,
    public selectedDistributor: OrganizationLicense | undefined // TODO BELU: ver si a esto le puedo sacar el undefined
  ) {
  }

  public get totalStock(): number {
    if(!this.selectedDistributor) {
      return 0;
    }
    const productTotalStock = this.product.totalStock(this.selectedDistributor!.id);
    return  Math.max(0, productTotalStock - this.allocations.totalAllocated());
  }

  public async saveAllocations(orgId: string) {
    const {isValid, errorMessage} = this.allocations.validate(this.selectedDistributor);
    if(!isValid || !this.selectedDistributor) {
      return new AllocationsViewModel(
        this.api,
        this.allocationsCache,
        this.product,
        this.organization,
        this.accounts,
        this.orgMembers,
        this.allocations,
        this.selectableAccounts,
        FormError.unknown(errorMessage),
        this.displayableAccountAllocations,
        this.selectedDistributor);
    }
    try {
      await this.api.bulkCreateOrUpdateAllocations(orgId, this.product.id, this.allocations, this.selectedDistributor.id);
      return this;
    } catch (e) {
      return new AllocationsViewModel(
        this.api,
        this.allocationsCache,
        this.product,
        this.organization,
        this.accounts,
        this.orgMembers,
        this.allocations,
        this.selectableAccounts,
        FormError.unknown(`${e.message}.\nPlease refresh the page to get the updated available stock.`),
        this.displayableAccountAllocations,
        this.selectedDistributor);
    }
  }

  public hasErrorFor(input: FormFields): boolean {
    return this.formError.hasErrorForInput(input);
  }

  public errorMessage(input: FormFields): string {
    return this.formError.errorMessage(input);
  }

  public orgMembersAllocations(): AllocationCollection {
    return this.allocations.orgMembers();
  }

  public accountsAllocations(): AllocationCollection {
    return this.allocations.accounts();
  }

  public addAccountAllocation(account: Account) {
    const accountAllocation = new AccountAllocation(0, this.product.id, account, this.selectedDistributor!.id);
    const newAllocations = this.allocations.add(accountAllocation);
    const displayableAccountAllocations = this.displayableAccountAllocations.add(accountAllocation);
    remove(this.selectableAccounts, toSelectableOption(account, account.name));

    return new AllocationsViewModel(
      this.api,
      this.allocationsCache,
      this.product,
      this.organization,
      this.accounts,
      this.orgMembers,
      newAllocations,
      this.selectableAccounts,
      FormError.withoutError(),
      displayableAccountAllocations,
      this.selectedDistributor
    ).validateAllocations();
  }

  public updateOrgMembersAllocations(allocation: Allocation, quantity: number) {
    const newOrgMembersAllocations = this.orgMembersAllocations().updateAllocation(allocation, quantity);
    const newAllocations = this.accountsAllocations().concat(newOrgMembersAllocations);
    return new AllocationsViewModel(
      this.api,
      this.allocationsCache,
      this.product,
      this.organization,
      this.accounts,
      this.orgMembers,
      newAllocations,
      this.selectableAccounts,
      FormError.withoutError(),
      this.displayableAccountAllocations,
      this.selectedDistributor
    );
  }

  public updateAccountAllocation(allocation: Allocation, quantity: number) {
    const newAccountsAllocations = this.accountsAllocations().updateAllocation(allocation, quantity);
    const newDisplayableAccountsAllocations = this.displayableAccountAllocations.updateAllocation(allocation, quantity);
    const newAllocations = this.orgMembersAllocations().concat(newAccountsAllocations);

    return new AllocationsViewModel(
      this.api,
      this.allocationsCache,
      this.product,
      this.organization,
      this.accounts,
      this.orgMembers,
      newAllocations,
      this.selectableAccounts,
      FormError.withoutError(),
      newDisplayableAccountsAllocations,
      this.selectedDistributor
    ).validateAllocations();
  }

  public validateAllocations() {
    const {isValid, errorMessage} = this.allocations.validate(this.selectedDistributor);
    return isValid ? this : new AllocationsViewModel(
      this.api,
      this.allocationsCache,
      this.product,
      this.organization,
      this.accounts,
      this.orgMembers,
      this.allocations,
      this.selectableAccounts,
      FormError.unknown(errorMessage),
      this.displayableAccountAllocations,
      this.selectedDistributor
    );
  }

  public deleteAccountAllocation(allocation: Allocation) {
    const account = allocation.assignedTo as Account;
    this.selectableAccounts.push(toSelectableOption(account, account.name));
    const newDisplayableAccountAllocations = this.removeDisplayableAccountAllocation(allocation);
    const newAccountsAllocations = this.accountsAllocations().deleteAllocation(allocation);
    const newAllocations = this.orgMembersAllocations().concat(newAccountsAllocations);
    return new AllocationsViewModel(
      this.api,
      this.allocationsCache,
      this.product,
      this.organization,
      this.accounts,
      this.orgMembers,
      newAllocations,
      this.selectableAccounts,
      FormError.withoutError(),
      newDisplayableAccountAllocations,
      this.selectedDistributor
    );
  }

  /**
   * removeAccountAllocation removes an account allocation from the displayable account allocations list.
   * @param accountAllocation: the account allocation to be removed.
   * Returns the all the account allocations except the removed one.
   */
  public removeDisplayableAccountAllocation(accountAllocation: Allocation): AllocationCollection {
    return this.displayableAccountAllocations.removeAccountAllocation(accountAllocation);
  }

  private static getOrgMembersWithoutAllocations(organization: Organization, allocations: AllocationCollection) {
    return organization.members
      .filter(member => !member.hasAllocations(allocations))
      .map(member => member.user);
  }

  private static getAccountsWithoutAllocations(accounts: Account[], allocations: AllocationCollection) {
    const accountsAllocations = allocations.accounts();
    return accounts.filter(account => !accountsAllocations.someIsAssignedToAccount(account));
  }

  public getSelectedDistributor() {
    return this.selectedDistributor ? toInformativeSelectableOption(
      this.selectedDistributor.license.name,
      '',
      this.selectedDistributor.license.licenseNumber,
      this.selectedDistributor) : null;
  }

  public getSelectableDistributors() {
    return this.organization.licenses.filter(distributor => distributor.id !== this.selectedDistributor!.id).map(distributor => toInformativeSelectableOption(
      distributor.license.name,
      '',
      distributor.license.licenseNumber,
      distributor)
    );
  }

  public async updateCurrentDistributor(selectedDistributor: OrganizationLicense) : Promise<AllocationsViewModel>{
    let allocations : AllocationCollection;
    const allocationsInCache = this.allocationsCache.get(selectedDistributor.id);
    if(!allocationsInCache) {
      allocations = await this.api.getAllocations(this.organization.id, this.product, selectedDistributor.id);
    } else {
      allocations = allocationsInCache;
    }

    const orgMembersWithoutAllocations = AllocationsViewModel.getOrgMembersWithoutAllocations(this.organization, allocations);

    const emptyOrgMembersAllocations = orgMembersWithoutAllocations.map((saleRep) => {
      return new OrgMemberAllocation(0, this.product.id, saleRep, selectedDistributor!.id);
    });
    const selectableAccounts = AllocationsViewModel.getAccountsWithoutAllocations(this.accounts, allocations).map(account => toSelectableOption(account, account.name));

    const allocationsCollection = allocations.concat(emptyOrgMembersAllocations);

    this.allocationsCache.set(selectedDistributor.id, allocationsCollection);
    return new AllocationsViewModel(this.api, this.allocationsCache, this.product, this.organization, this.accounts, orgMembersWithoutAllocations, allocationsCollection, selectableAccounts, FormError.withoutError(), allocationsCollection.accounts(), selectedDistributor);
  }

  public hasDistributors() : boolean{
    return this.organization.licenses.length > 0;
  }

  public hasMoreThanOneDistributor() : boolean{
    return this.organization.licenses.length > 1;
  }
}
