import React, {CSSProperties, ReactNode, useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle, faClone} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import OrdoButton from '../../components/common/OrdoButton';
import OrdoPageTitle from '../../components/common/OrdoPageTitle';
import {PRIMARY_COLOR} from '../../../constants';
import OrdoTable, {createTableCell} from '../../components/common/OrdoTable';
import {OrderEntryViewModel} from '../../../application-models/order-entry/OrderEntryViewModel';
import {CartItem} from '../../../models/order-entry/CartItem';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import {UserSessionContext} from '../../../context/UserSessionContext';
import OrdoSpinner from '../../components/OrdoSpinner';
import OrdoInformativeSearchableDropdown
  from '../../components/common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {OrganizationLicense} from '../../../models/OrganizationLicense';
import Contact from '../../../models/order-entry/Contact';
import AddContactButton from '../AddContactButton';
import AddContactModal from '../../components/modals/AddContactModal';
import OrdoDatepicker, {OrdoDatepickerDisplayMode, SelectableDates} from '../../components/common/OrdoDatepicker';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import {AddDiscountButton} from '../AddDiscountButton';
import {DiscountType} from '../../../models/order-entry/Order';
import {ItemLine} from '../../../models/order-entry/ItemLine';
import {ProductLine} from '../../../models/order-entry/Cart';
import {isValidPrice, isValidQuantity} from '../../../models/order-entry/OrderValidator';
import {OrdoDate} from '../../../models/OrdoDate';
import ROUTE_NAMES from '../../../routes/ROUTE_NAMES';
import useOrganizationRedirect from '../../../hooks/useOrganizationRedirect';
import {AccountLocation} from '../../../models/Account';
import {OrderSummary} from './OrderSummary';
import {OrderEntryLineItemNote} from '../../components/order-entry/OrderEntryLineItemNote';

type OrderEntrySecondStepProps = {
  orderEntryViewModel: OrderEntryViewModel;
  updateAccountLocation: (accountLocation: AccountLocation) => void;
  updateViewModel: (viewModel: OrderEntryViewModel) => void
}

const CheckoutPage = ({orderEntryViewModel, updateAccountLocation, updateViewModel}: OrderEntrySecondStepProps) => {
  const userSession = useContext(UserSessionContext);
  const {validateOrRedirect} = useOrganizationRedirect();
  const history = useHistory();
  const organization = userSession.currentOrganization()!;
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const { successToast, errorToast } = useOrdoToasts();
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);


  const updateCurrentDistributor = async (selectableDistributor: OrganizationLicense) => {
    showSpinner();
    const updatedViewModel = await orderEntryViewModel.updateDistributor(organization.id, selectableDistributor);
    updateViewModel(updatedViewModel);
    hideSpinner();
  };

  const fetchData = () => {
    showSpinner();
    orderEntryViewModel
      .initializeCheckoutPage(organization.id)
      .then(updateViewModel)
      .finally(hideSpinner);
  };

  useEffect(fetchData, [organization, orderEntryViewModel.selectedAccountLocation]);

  useEffect(() => {
    validateOrRedirect(() => history.push(ROUTE_NAMES.ORDER_ENTRY));
  }, [userSession.currentOrganization()]);

  useEffect(() => {
    if (orderEntryViewModel.summaryError) {
      const { message } = orderEntryViewModel.summaryError;
      errorToast(`Error getting the order summary: ${message}`);
    }
  },
  [orderEntryViewModel.summaryError]
  );

  const textEllipsisStyle = {overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '17ch'};

  const addNewLine = async (item: CartItem) => {
    showSpinner();
    updateViewModel(await orderEntryViewModel.addLine(organization.id, item));
    hideSpinner();
  };

  const removeLine = async (item: CartItem, line: number) => {
    showSpinner();
    updateViewModel(await orderEntryViewModel.removeLine(organization.id, item, line));
    hideSpinner();
  };

  const updateItemDiscount = (itemLine: ItemLine) => {
    return async (newValue: number) => {
      showSpinner();
      const newVM = await orderEntryViewModel.updateItemDiscount(organization.id, itemLine, newValue);
      updateViewModel(newVM);
      hideSpinner();
    };
  };

  const updateItemDiscountType = (itemLine: ItemLine) => {
    return async (value: DiscountType) => {
      showSpinner();
      const newVM = await orderEntryViewModel.updateItemDiscountType(organization.id,itemLine, value);
      hideSpinner();
      updateViewModel(newVM);
    };
  };

  const updateItemNotes = (itemLine: ItemLine) => {
    return async (notes: string) => {
      showSpinner();
      const newVM = await orderEntryViewModel.updateItemNotes(organization.id,itemLine, notes);
      hideSpinner();
      updateViewModel(newVM);
    };
  };

  const updateItemPricePerCase = (itemLine: ItemLine) => {
    return async (event: any) => {
      showSpinner();
      const value = parseFloat(event?.target?.value);
      const vm = await orderEntryViewModel.updateItemPrice(organization.id, itemLine, value);
      updateViewModel(vm);
      hideSpinner();
    };
  };

  const updateItemPricePerUnit = (itemLine: ItemLine, unitsPerCase: number) => {
    return async (event: any) => {
      showSpinner();
      const value = parseFloat(event?.target?.value);
      const vm = await orderEntryViewModel.updateItemPrice(organization.id, itemLine, value * unitsPerCase);
      updateViewModel(vm);
      hideSpinner();
    };
  };

  const updateCartItemLineQuantity = (itemLine: ItemLine) => {
    return async (event: any) => {
      showSpinner();
      const value = parseInt(event?.target?.value, 10);
      const vm = await orderEntryViewModel.updateItemQuantity(organization.id, itemLine, value);
      updateViewModel(vm);
      hideSpinner();
    };
  };

  const productLineItemName = (item: CartItem, hasError: boolean): ReactNode => {
    return <div className='product-name-container'>
      <span style={{...textEllipsisStyle, position: 'absolute', bottom: '0.6em', maxWidth: '30em'}}
        data-toggle="tooltip"
        data-placement="bottom"
        title={`${item.product.name} - ${item.product.categoryToString()}`}>
        {`${item.product.name} - ${item.product.categoryToString()}`}
      </span>
      <div className="invalid-feedback">
        {hasError ? 'insufficient inventory' : ''}
      </div>
    </div>;
  };

  const convertToProductLineItem = (item: CartItem, line: number): ReactNode => {
    const buttonStyle = {paddingLeft: '0', fontWeight: 'bold', fontSize: '0.75rem'};
    return <div>
      {productLineItemName(item, orderEntryViewModel.hasErrorFor(item.product.id))}
      {line === 0 ? <OrdoButton dataTestId='add-line-button'
        style={buttonStyle as CSSProperties}
        disabled={false}
        text='duplicate line'
        category='link'
        onClick={() => addNewLine(item)}
        firstChild={<FontAwesomeIcon color={`${PRIMARY_COLOR}`} size="1x" icon={faClone}
          style={{margin: '0 0.5em'}}/>}
      /> : <OrdoButton dataTestId='remove-line-button'
        style={{...(buttonStyle as CSSProperties), color: 'grey'}}
        disabled={false}
        text='remove line'
        category='link'
        onClick={() => removeLine(item, line)}
        firstChild={<FontAwesomeIcon color="grey" size="1x" icon={faMinusCircle}
          style={{margin: '0 0.5em'}}/>}
      /> }
    </div>;
  };

  const updateContact: (selectedContact: Contact) => void = (selectedContact: Contact) => {
    updateViewModel(orderEntryViewModel.updateSelectedContact(selectedContact));
  };

  const openAddContactModal = () => setAddContactModalOpen(true);
  const closeAddContactModal = () => setAddContactModalOpen(false);

  const refreshContacts = () => {
    return orderEntryViewModel.fetchContacts(organization.id).then(updateViewModel);
  };

  // OrdoDatePicker passes a Moment into this...
  const updateDeliveryDay = (date: Date) => {
    updateViewModel(orderEntryViewModel.updateDeliveryDay(OrdoDate.from(date)));
  };

  const submit = () => {
    showSpinner();
    orderEntryViewModel.submitOrder(organization.id).then((vm) => {
      if(vm.hasError()) {
        errorToast(vm.errorMessage());
        updateViewModel(vm);
      } else {
        successToast(vm.getSuccessMessage());
        history.push(vm.nextRoute());
        updateViewModel(vm);
      }
    }).finally(hideSpinner);
  };

  const productBrand = (item: CartItem) => {
    return <span data-toggle="tooltip"
      data-placement="bottom"
      title={`${item.product.brandToString()}`}
      style={textEllipsisStyle}>
      {item.product.brandToString()}
    </span>;
  };

  const ordoInputWidth = '70px'; // should be lower than ordoInputCellWidth
  const ordoInputCellWidth = '80px';
  const ordoInputFontSize = '0.7rem';

  const renderCasesTable = () => {
    return (
      <div className="order-entry-second-step-container__body__tables__cases ordo-shadow ordo-card-border ">
        <OrdoTable removeTableBorders tableTitles={[
          'product',
          'unit size',
          'brand',
          'units',
          'unit price',
          'cases ordered',
          'case price',
          'discount',
          'taxes',
          'line total',
          ''
        ]} tableRows={orderEntryViewModel.cartCaseItems().map((productLine: ProductLine) => {
          const {item, line, index} = productLine;
          return {
            tableCells: [
              createTableCell(convertToProductLineItem(item, index)),
              createTableCell(`${item.product.sizeToString()}`),
              createTableCell(productBrand(item)),
              createTableCell(`${item.product.unitsPerCase}`),
              createTableCell(<OrdoInput
                inputName="lineItemPrice"
                placeholder=""
                inputSize="short"
                inputType="number"
                value={line.priceInputValueForCase(item.product.unitsPerCase)}
                validate={(input: any) => {
                  const parsedInput = parseFloat(input);
                  if (!isValidPrice(parsedInput)) {
                    return 'Invalid price';
                  }
                  return '';
                }}
                onBlur={updateItemPricePerUnit(line, item.product.unitsPerCase)}
                style={{
                  width: ordoInputWidth,
                  padding: '0 1em',
                  fontWeight: 'initial',
                  fontSize: ordoInputFontSize,
                  whiteSpace: 'nowrap',
                  color: '#1F2D3D',
                }}
              />, {width: ordoInputCellWidth}),
              createTableCell(<OrdoInput inputName="productItemQuantity"
                value={line.getQuantity()}
                placeholder=''
                inputSize='short'
                inputType="number"
                min={0}
                onBlur={updateCartItemLineQuantity(line)}
                validate={(input: any) => {
                  const parsedInput = parseInt(input, 10);
                  if (!isValidQuantity(parsedInput)) {
                    return 'invalid quantity';
                  }
                  if (!orderEntryViewModel.inventoryIsEnoughForProduct(item.product)) {
                    return 'stock exceeded';
                  }
                  if (line.getQuantity() === 0) {
                    return 'add a case or remove line';
                  }
                  if(item.product.deletedAt) {
                    return 'product no longer available';
                  }
                  return '';
                }}
                style={{
                  width: ordoInputWidth,
                  padding: '0 1em',
                  fontWeight: 'initial',
                  fontSize: ordoInputFontSize,
                  whiteSpace: 'nowrap',
                  color: '#1F2D3D',
                }}/>, {width: ordoInputCellWidth}),
              createTableCell(<OrdoInput
                inputName="lineItemPrice"
                placeholder=""
                inputSize="short"
                inputType="number"
                value={line.priceInputValue()}
                validate={(input: any) => {
                  const parsedInput = parseFloat(input);
                  if (!isValidPrice(parsedInput)) {
                    return 'Invalid price';
                  }
                  return '';
                }}
                onBlur={updateItemPricePerCase(line)}
                style={{
                  width: ordoInputWidth,
                  padding: '0 1em',
                  fontWeight: 'initial',
                  fontSize: ordoInputFontSize,
                  whiteSpace: 'nowrap',
                  color: '#1F2D3D',
                }}
              />, {width: ordoInputCellWidth}),
              createTableCell(<AddDiscountButton
                item={line}
                onChange={() => {}}
                onSwitch={updateItemDiscountType(line)}
                onBlur={updateItemDiscount(line)}
                discountButtonStyle={{width: ordoInputWidth, fontSize: ordoInputFontSize}}
                iconsStyle={{width: '1.7em', height: '1.7em'}}
              />, {width: ordoInputCellWidth, fontSize: ordoInputFontSize}),
              createTableCell(`${line.getTaxes().formatted()}`),
              createTableCell(`${line.total().formatted()}`),
              createTableCell(<OrderEntryLineItemNote item={item} line={line} index={index} updateNotes={updateItemNotes(line)}/>),
            ]
          };
        })}/>
      </div>
    );
  };

  const renderUnitTable = () => {
    if (orderEntryViewModel.cartUnitItems().length === 0) {
      return null;
    }
    return (
      <div className="order-entry-second-step-container__body__tables__units ordo-shadow ordo-card-border">
        <OrdoTable removeTableBorders tableTitles={[
          'product by unit',
          'unit size',
          'brand',
          'units',
          'unit price',
          'discount',
          'taxes',
          'line total',
          ''
        ]} tableRows={orderEntryViewModel.cartUnitItems().map((productLine: ProductLine) => {
          const {item, line, index} = productLine;
          return {
            tableCells: [
              createTableCell(convertToProductLineItem(item, index)),
              createTableCell(`${item.product.sizeToString()}`),
              createTableCell(productBrand(item)),
              createTableCell(<OrdoInput inputName="unitItemQuantity"
                value={line.quantity}
                placeholder=''
                inputSize='short'
                inputType="number"
                onChange={() => {}}
                onBlur={updateCartItemLineQuantity(line)}
                validate={(input: any) => {
                  const parsedInput = parseInt(input, 10);
                  if (!isValidQuantity(parsedInput)) {
                    return 'invalid quantity';
                  }
                  if (!orderEntryViewModel.inventoryIsEnoughForProduct(item.product)) {
                    return 'stock exceeded';
                  }
                  if (line.getQuantity() === 0) {
                    return 'add a unit or remove line';
                  }
                  if(item.product.deletedAt) {
                    return 'product no longer available';
                  }
                  return '';
                }}
                style={{
                  width: ordoInputWidth,
                  padding: '0 1em',
                  fontWeight: 'initial',
                  fontSize: ordoInputFontSize,
                  whiteSpace: 'nowrap',
                  color: '#1F2D3D',
                }}/>, {width: ordoInputCellWidth}),
              createTableCell(<OrdoInput inputName="unitPrice"
                value={line.priceInputValue()}
                placeholder=''
                inputSize='short'
                inputType="string"
                onChange={() => {}}
                validate={(input: any) => {
                  const parsedInput = parseFloat(input);
                  if (!isValidPrice(parsedInput)) {
                    return 'Invalid price';
                  }
                  return '';
                }}
                onBlur={updateItemPricePerCase(line)}
                style={{
                  width: ordoInputWidth,
                  padding: '0 1em',
                  fontWeight: 'initial',
                  fontSize: ordoInputFontSize,
                  whiteSpace: 'nowrap',
                  color: '#1F2D3D',
                }}/>, {width: ordoInputCellWidth}),
              createTableCell(<AddDiscountButton
                item={line}
                onChange={() => {}}
                onSwitch={updateItemDiscountType(line)}
                onBlur={updateItemDiscount(line)}
                discountButtonStyle={{width: ordoInputWidth, fontSize: ordoInputFontSize}}
                iconsStyle={{width: '1.7em', height: '1.7em'}}
              />, {width: ordoInputCellWidth, fontSize: ordoInputFontSize, display: 'flex', justifyContent: 'center', alignItems: 'center'}),
              createTableCell(line.getTaxes().formatted()),
              createTableCell(line.total().formatted()),
              createTableCell(<OrderEntryLineItemNote item={item} line={line} index={index} updateNotes={updateItemNotes(line)}/>),
            ]
          };
        })}/>
      </div>
    );
  };

  const renderOrderSummary = () => <OrderSummary orderEntryViewModel={orderEntryViewModel} updateViewModel={updateViewModel} organization={organization} onSubmit={submit}/>;

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="order-entry-second-step-container">
        <OrdoPageTitle title="complete invoice"
          buttons={[]}
          style={{marginBottom: 0}}
          showBackButton
          onPressBackButton={() => updateViewModel(orderEntryViewModel.goToGenerateOrder())}/>
        <div className="page-content-body">
          <div className="order-entry-second-step-container__verify">
            <div className="order-entry-second-step-container__verify__account">
              <span className="order-entry-second-step_text">confirm shop</span>
              <OrdoInformativeSearchableDropdown
                dataTestId='select-location'
                selectedOption={orderEntryViewModel.getSelectedAccountLocation()}
                options={orderEntryViewModel.getSelectableAccountLocations()}
                onChangeSelectedOption={updateAccountLocation}
                placeholder='select a location'/>
            </div>
            <div className="order-entry-second-step-container__verify__contact">
              <span className="order-entry-second-step_text">verify contact</span>
              {orderEntryViewModel.getSelectableContacts().length > 0 ?
                <OrdoInformativeSearchableDropdown
                  selectedOption={orderEntryViewModel.getSelectedContact()}
                  options={orderEntryViewModel.getSelectableContacts()}
                  onChangeSelectedOption={updateContact}
                  placeholder='select a Contact'
                  onAddItem={openAddContactModal}
                  addItemText='add a new contact'
                /> :
                <AddContactButton onClick={openAddContactModal}/>
              }
            </div>
            <div className="order-entry-second-step-container__verify__distributor">
              <span className="order-entry-second-step_text">confirm distributor</span>
              <OrdoInformativeSearchableDropdown
                selectedOption={orderEntryViewModel.getSelectedDistributor()}
                options={orderEntryViewModel.getSelectableDistributors()}
                canEditValue={orderEntryViewModel.hasMoreThanOneDistributor()}
                onChangeSelectedOption={(distributor) => updateCurrentDistributor(distributor)}
                placeholder='select a Distributor'
              />
            </div>
            <div className="order-entry-second-step-container__verify__delivery-day">
              <span className="order-entry-second-step_text">confirm delivery day</span>
              <OrdoDatepicker
                value={orderEntryViewModel.selectedDeliveryDay}
                placeholder="select a delivery day"
                onChange={updateDeliveryDay}
                displayMode={OrdoDatepickerDisplayMode.CARD}
                onlyTime={false}
                validDates={SelectableDates.ALL_TIME}
              />
            </div>

          </div>
          <div className="order-entry-second-step-container__body">
            <div className="order-entry-second-step-container__body__tables">
              { renderCasesTable() }
              { renderUnitTable() }
            </div>
            { renderOrderSummary() }
          </div>
        </div>
      </div>
      <AddContactModal
        onClose={closeAddContactModal}
        isOpen={addContactModalOpen}
        onSubmit={refreshContacts}
        orgId={organization.id}
        accountId={orderEntryViewModel.selectedAccountLocation!.accountId}
      />
    </OrdoSpinner>);
};

export default CheckoutPage;
