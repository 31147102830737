import React, {CSSProperties, ReactNode} from 'react';
import OrdoTable, {createTableCell} from '../common/OrdoTable';
import OrderWithCurrentVersion from '../../../models/order-entry/OrderWithCurrentVersion';
import '../../../scss/ordo/orderHistory/order-line-item.scss';
import { OrderVersionLineItem } from '../../../models/order-entry/OrderVersionLineItem';

type OrderLineItemsProps = {
  order: OrderWithCurrentVersion,
  headerStyles: CSSProperties,
  columns: {name: string, renderCell: (item:OrderVersionLineItem) => ReactNode}[]
};

const OrderLineItems = ({order, headerStyles, columns}: OrderLineItemsProps) => {

  const tableTitles = columns.map(col => col.name);

  return <>
    <OrdoTable
      tableTitles={tableTitles}
      tableRows={order.currentVersion.items.map(item => ({
        tableCells: columns.map(column => createTableCell(
          column.renderCell(item), column.name === 'product' ? {verticalAlign: 'middle', marginTop: '1em', width: '32%'} : {verticalAlign: 'middle', marginTop: '1em'})
        )})
      )}
      tableStyles={{width: '-webkit-fill-available', minWidth: '100%'}}
      headerStyles={{...headerStyles, position: 'sticky', top: '0'}}
      removeTableBorders
    />
  </>;
};

export default OrderLineItems;
