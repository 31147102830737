import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useContext, useState} from 'react';
import {
  faBars,
} from '@fortawesome/free-solid-svg-icons';
import '../../../scss/ordo/side-bar.scss';
import {UserSessionContext} from '../../../context/UserSessionContext';
import { FeatureFlags } from '../../../lib/featureFlags';
import {
  NavigationButtonData,
  navigationButtonsData,
  shouldShowIconForResolution
} from '../../../models/side-bar/NavigationButtonData';
import { buildSideBarButtons } from './SideBarButton';
import {ExpandedSideBar} from './ExpandedSideBar';
import {UserSession} from '../../../models/UserSession';
import { RoutePermissionChecker } from '../../../routes/permissions';
import {useResolution} from '../../../context/UseResolutions';
import {isMobileResolution, Resolution} from '../../../context/resolutions';
import {useFeatureFlags} from '../../../context/FeatureFlagsContext';

function shouldShowIcon(navigationButton: NavigationButtonData, userSession: UserSession, flags: FeatureFlags, resolutionKind: Resolution) {
  const checker = new RoutePermissionChecker();
  return checker.allow(userSession, flags, navigationButton.route) &&
    shouldShowIconForResolution(navigationButton, resolutionKind);
}

const SideBar = () => {
  const {userSession} = useContext(UserSessionContext);
  const flags = useFeatureFlags();
  const resolutionKind: Resolution = useResolution();
  const [expanded, setExpanded] = useState(false);

  const collapseSideBar = () => setExpanded(false);
  const expandSideBar = () => setExpanded(true);

  const activeButtons = navigationButtonsData.filter((navigationButton) => {
    return shouldShowIcon(navigationButton, userSession, flags, resolutionKind);
  });

  const sideBarButtons = isMobileResolution(resolutionKind) ? [] : buildSideBarButtons(activeButtons,false);

  return <div className="sidebar-container">
    <ExpandedSideBar
      shouldOpen={expanded}
      collapseSideBar={collapseSideBar}
      navButtonsData={activeButtons}
      userSession={userSession}
    />
    <div className="sidebar">
      {[
        <div className="sidebar-btn menu-toggle" role="presentation" onClick={expandSideBar} key='openMenu'>
          <FontAwesomeIcon icon={faBars}/>
        </div>,
        ...sideBarButtons
      ]}
    </div>
  </div>;
};

export default SideBar;
