import React, {useContext} from 'react';
import OrdoInformativeSearchableDropdown
  from '../../../components/common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {OrganizationLicense} from '../../../../models/OrganizationLicense';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import OrdoSpinner from '../../../components/OrdoSpinner';

type MobileOrderEntryDistributorSelectionProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateViewModel: (viewModel: OrderEntryViewModel) => void,
}

export const MobileOrderEntryDistributorSelection = ({orderEntryViewModel, updateViewModel} : MobileOrderEntryDistributorSelectionProps) => {
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;

  const updateCurrentDistributor = async (selectableDistributor: OrganizationLicense) => {
    showSpinner();
    const updatedViewModel = await orderEntryViewModel.updateDistributor(organization.id, selectableDistributor);
    updateViewModel(updatedViewModel);
    hideSpinner();
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoInformativeSearchableDropdown
        selectedOption={orderEntryViewModel.getSelectedDistributor()}
        options={orderEntryViewModel.getSelectableDistributors()}
        onChangeSelectedOption={(distributor) => updateCurrentDistributor(distributor)}
        placeholder='select a Distributor'
        singleRowHeight
        canEditValue={orderEntryViewModel.hasMoreThanOneDistributor()}
        addedStyles={{
          marginBottom: '0', valueContainer: {padding: '0'},
          control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
        }}
      />
    </OrdoSpinner>
  );
};