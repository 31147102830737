import React, {CSSProperties, PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import '../../scss/ordo/ordo-checkbox.scss';

type OrdoCheckboxProps = {
  checked: boolean,
  onChange: Function,
  onMouseLeave?: Function,
  notToggleOnClick?: boolean,
  label?: string,
  style?: CSSProperties,
  invalidMessage?: string,
  dataTestId?: string,
  disabled?: boolean
  id?: string,
} & PropsWithChildren<ReactNode>

export const OrdoCheckbox = ({checked, onChange, onMouseLeave, label, style = {}, invalidMessage, dataTestId, children, id, disabled=false, notToggleOnClick=false}: OrdoCheckboxProps) => {
  const [checkedState, toggleChecked] = useState(checked);

  useEffect(() => toggleChecked(checked), [checked]);

  const showErrorMessage = invalidMessage?  invalidMessage.length > 0 : false;
  return <div className='ordo-checkbox-container' style={style} onMouseLeave={(event) => { if(onMouseLeave) onMouseLeave(event); }}>
    <div className='ordo-checkbox'>
      <label htmlFor={`checkbox${id}`} className='ordo-checkbox__label'>
        <input id={`checkbox${id}`}
          className='ordo-checkbox__label__checkbox'
          type="checkbox"
          checked={checkedState}
          disabled={disabled}
          onChange={() => {
            onChange();
            if(!notToggleOnClick) toggleChecked(!checkedState);
          }}
          data-testid={dataTestId}
        />
        <span className={`ordo-checkbox__label__span ${checked ? 'checked' : ''}`}/>
        {label}
        {children}
      </label>
    </div>
    {showErrorMessage && <div className="invalid-feedback">
      {invalidMessage}
    </div>}
  </div>;
};
