import {RouteComponentProps, withRouter} from 'react-router-dom';
import React, {FC, ReactNode, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import '../../scss/ordo/salesActivity/sales-activity-page.scss';
import {API} from '../../lib/api/ordoApi';
import {useAPI} from '../../context/OrdoApiContext';
import {UserSessionContext} from '../../context/UserSessionContext';
import {AccountPriority, SalesActivityAccount, SalesActivityOrder, TrackOrderModalInput} from './salesActivityTypes';
import {SideDrawer} from './SideDrawer';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {OrderEntryCartContext} from '../../context/OrderEntryCartContext';
import SalesActivityOrderDetails from './SalesActivityOrderDetails';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import SalesActivityViewModel, {
  AccountDetailsInfo,
  SelectablePipelineFilter,
  SortingCriteria
} from '../../application-models/sales-activity/SalesActivityViewModel';
import VerifyOrderDeliveryModal from './VerifyOrderDeliveryModal';
import {AccountDetails} from './AccountDetails';
import {SalesActivityCardDetails} from './SalesActivityCardDetails';
import useOrganizationRedirect from '../../hooks/useOrganizationRedirect';
import TrackOrderModal from './TrackOrderModal';
import WonLostModal from './WonLostModal';
import ConfirmationModal from '../components/common/ConfirmationModal';
import {useResolution} from '../../context/UseResolutions';
import {isMobileResolution, Resolution} from '../../context/resolutions';
import OrdoMobileFriendlyButton from '../components/common/OrdoMobileFriendlyButton';
import {MobilePipeline} from './MobilePipeline';
import {Pipeline} from './Pipeline';
import {SalesActivityTableFactory} from '../../application-models/sales-activity/sales-activity-table/SalesActivityTableFactory';
import UpdateStaleAccountStatusModal from './UpdateStaleAccountStatusModal';
import {SalesActivityPipeline} from '../../application-models/sales-activity/sales-activity-table/SalesActivityTable';
import LogPaymentModal from './LogPaymentModal';
import {Money} from '../../models/Money';
import {useFeatureFlags} from '../../context/FeatureFlagsContext';
import {paginateSalesActivity} from '../../lib/featureFlags';

type DrawerContent = (closeDrawer: Function, doActionAndClose: Function) => ReactNode;

const SalesActivityPage: FC<RouteComponentProps> = () => {
  const api: API = useAPI();
  const resolution: Resolution = useResolution();
  const featureFlags = useFeatureFlags();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const userSession = useContext(UserSessionContext);
  const [salesActivityViewModel, setSalesActivityViewModel] = useState(SalesActivityViewModel.emptySalesActivityViewModel(userSession.currentOrganization()!, api, featureFlags));
  const [salesActivityPendingOrders, setSalesActivityPendingOrders] = useState([]);
  const [salesActivityAwaitingDeliveryOrders, setSalesActivityAwaitingDeliveryOrders] = useState([]);
  const [salesActivityCollectPaymentOrders, setSalesActivityCollectPaymentOrders] = useState([]);
  const [nonQualifiedAccounts, setNonQualifiedAccounts] = useState([]);
  const [lostAccounts, setLostAccounts] = useState([]);
  const [prospectAccounts, setProspectAccounts] = useState([]);
  const [verifiedAccounts, setVerifiedAccounts] = useState([]);
  const [staleAccounts, setStaleAccounts] = useState([]);
  const orderEntryCartContextData = useContext(OrderEntryCartContext);
  const history = useHistory();
  const {validateOrRedirect} = useOrganizationRedirect();
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerContent, setDrawerContent] = useState<DrawerContent>(() => () => <div/>);
  const orgId = userSession.currentOrganization()!.id;
  const [verifyOrderModalOrder, setVerifyOrderModalOrder] = useState<SalesActivityOrder | undefined>(undefined);
  const [accountProspectModal, setAccountProspectModal] = useState<SalesActivityAccount | undefined>(undefined);
  const [staleAccountStatusUpdateModal, setStaleAccountStatusUpdateModal] = useState<SalesActivityAccount | undefined>(undefined);
  const [accountLostModal, setAccountLostModal] = useState<SalesActivityAccount | undefined>(undefined);
  const [collectPaymentModalOrder, setCollectPaymentModalOrder] = useState<SalesActivityOrder | undefined>(undefined);
  const [trackedOrderModalData, setTrackedOrderModalData] = useState<TrackOrderModalInput| undefined>(undefined);
  const [searchAccountByNameInput, setSearchAccountByNameInput] = useState<string>('');

  const closeVerifyDeliveryModal = () => setVerifyOrderModalOrder(undefined);
  const closeAccountProspectModal = () => setAccountProspectModal(undefined);
  const closeStaleAccountStatusUpdateModal = () => setStaleAccountStatusUpdateModal(undefined);
  const closeAccountLostModal = () => setAccountLostModal(undefined);
  const closeCollectPaymentConfirmationModal = () => setCollectPaymentModalOrder(undefined);
  const closeTrackOrderModal = () => setTrackedOrderModalData(undefined);


  const showAccountDetails = (showValue: boolean) => (account: SalesActivityAccount)=> {
    showSpinner();
    salesActivityViewModel.getAccountDetailsInformation(orgId!, account.id)
      .then(({contacts, notes, activities}: AccountDetailsInfo)=>{
        setDrawerContent(() => {
          return(
            (_onClose: any, onCTA: any, doMainActionOnClose: any) =>
              <SalesActivityCardDetails>
                <AccountDetails
                  account={account!}
                  orgId={orgId!}
                  onSubmit={onCTA}
                  onPriorityChange={doMainActionOnClose}
                  onStalePeriodChange={doMainActionOnClose}
                  notes={notes}
                  contacts={contacts}
                  activities={activities}
                  showValue={showValue}
                />
              </SalesActivityCardDetails>
          );
        });
        setShowDrawer(true);
      })
      .catch(()=> errorToast('Couldn\'t retrieve the accounts information'))
      .finally(hideSpinner);
  };

  const refreshPipelineDataAfterOrderIsConfirmed = () => {
    if (paginateSalesActivity(featureFlags)) {
      salesActivityViewModel.fetchPendingOrders(api, userSession.currentOrganization()!.id, setSalesActivityPendingOrders, errorToast);
      salesActivityViewModel.fetchConfirmedOrders(api, userSession.currentOrganization()!.id, setSalesActivityAwaitingDeliveryOrders, errorToast);
    }
  };

  const refreshPipelineDataAfterOrderIsVerifyDelivery = () => {
    if (paginateSalesActivity(featureFlags)) {
      salesActivityViewModel.fetchConfirmedOrders(api, userSession.currentOrganization()!.id, setSalesActivityAwaitingDeliveryOrders, errorToast);
      salesActivityViewModel.fetchDeliveredOrders(api, userSession.currentOrganization()!.id, setSalesActivityCollectPaymentOrders, errorToast);
      salesActivityViewModel.fetchVerifiedAndStaleAccounts(api, userSession.currentOrganization()!.id, [], [], setVerifiedAccounts, setStaleAccounts, errorToast);
    }
  };

  const refreshPipelineDataAfterAccountIsWon = () => {
    if (paginateSalesActivity(featureFlags)) {
      salesActivityViewModel.fetchProspectAccounts(api, userSession.currentOrganization()!.id, [], setProspectAccounts, errorToast);
      salesActivityViewModel.fetchVerifiedAndStaleAccounts(api, userSession.currentOrganization()!.id, [], [], setVerifiedAccounts, setStaleAccounts, errorToast);
    }
  };

  const refreshPipelineDataAfterAccountIsLostOrMovedToProspect = () => {
    if (paginateSalesActivity(featureFlags)) {
      salesActivityViewModel.fetchProspectAccounts(api, userSession.currentOrganization()!.id, [], setProspectAccounts, errorToast);
      salesActivityViewModel.fetchLostAccounts(api, userSession.currentOrganization()!.id, [], setLostAccounts, errorToast);
      salesActivityViewModel.fetchVerifiedAndStaleAccounts(api, userSession.currentOrganization()!.id, [], [], setVerifiedAccounts, setStaleAccounts, errorToast);
    }
  };

  const confirmOrder = async (order: SalesActivityOrder) => {
    showSpinner();
    salesActivityViewModel
      .confirmOrder(userSession.currentOrganization()!.id, order.id)
      .then((updatedViewModel) => {
        refreshPipelineDataAfterOrderIsConfirmed();
        return updatedViewModel;
      })
      .then((updatedViewModel) => {
        successToast('Order was confirmed');
        setSalesActivityViewModel(updatedViewModel);
      })
      .catch( (e) => {
        errorToast(`Order could not be confirmed - \n${ e.message}`);
      })
      .finally(hideSpinner);
  };

  const confirmOrderFromDrawer = async (order: SalesActivityOrder) => {
    return salesActivityViewModel
      .confirmOrder(userSession.currentOrganization()!.id, order.id)
      .then((updatedViewModel) => {
        successToast('Order was confirmed');
        setSalesActivityViewModel(updatedViewModel);
      })
      .catch( () => {
        errorToast('Order could not be confirmed');
      });
  };

  const openOrderDetails = (selectedOrder: SalesActivityOrder, callToAction: any) => {
    showSpinner();
    salesActivityViewModel.getOrder(orgId!, selectedOrder).then(({order, contacts, notes, activities}: { order: OrderWithCurrentVersion} & AccountDetailsInfo)=>{
      setDrawerContent(() => {
        return(
          (onClose: any, onCTA: any, doMainActionOnClose: any) =>
            <SalesActivityOrderDetails
              order={order}
              contacts={contacts}
              notes={notes}
              activities={activities}
              orgId={orgId!}
              onClose={onClose}
              onSubmit={onCTA}
              cta={callToAction}
              doMainActionOnClose={doMainActionOnClose}
              trackedOrderAction={(orderToEdit)=>setTrackedOrderModalData({location: order.currentVersion.accountLocation ,order: orderToEdit})}
            />
        );
      });
      setShowDrawer(true);
    }).finally(hideSpinner);
  };

  const openVerifyDeliveryModal = async (order: SalesActivityOrder)  => {
    setVerifyOrderModalOrder(order);
  };

  const openCollectPaymentConfirmationModal = async (order: SalesActivityOrder)  => {
    setCollectPaymentModalOrder(order);
  };

  const stageOrder = (account: SalesActivityAccount) => {
    orderEntryCartContextData.clearCart();
    history.push(ROUTE_NAMES.ORDER_ENTRY, {accountId: account.id});
  };

  const openProspectCTAModal = (account: SalesActivityAccount) => {
    setAccountProspectModal(account);
  };

  const openAccountLostCTAModal = (account: SalesActivityAccount) => {
    setAccountLostModal(account);
  };

  const openStaleAccountUpdateStatusModal = (account: SalesActivityAccount) => {
    setStaleAccountStatusUpdateModal(account);
  };

  const onAccountPriorityClick = async (accountId: string, currentPriority: AccountPriority) => {
    const getUpdatedPriority = () => {
      switch (currentPriority) {
      case 1: return 2;
      case 2: return 3;
      case 3: return 1;
      default: return 3;
      }
    };

    showSpinner();
    const updatedVm = salesActivityViewModel.updateAccountPriority(orgId, accountId, getUpdatedPriority(), (_err: Error) => {
      errorToast('Unable to update account priority for this account. Please refresh and try again');
    });
    setSalesActivityViewModel(updatedVm);
    hideSpinner();
  };

  const accountCardMinWidth = 13.5;
  const inMobileResolution = isMobileResolution(resolution);

  const trackOrder = (account: SalesActivityAccount) => {
    const defaultLocation = salesActivityViewModel.getAllAccountsLocationsWithContacts().find(location => location.accountId === account.id);
    setTrackedOrderModalData({location: defaultLocation});
  };
  const salesActivityTableInput = {
    salesActivityViewModel: salesActivityViewModel,
    salesActivityPendingOrders: salesActivityPendingOrders,
    salesActivityAwaitingDeliveryOrders: salesActivityAwaitingDeliveryOrders,
    salesActivityCollectPaymentOrders: salesActivityCollectPaymentOrders,
    nonQualifiedAccounts: nonQualifiedAccounts,
    lostAccounts: lostAccounts,
    prospectAccounts: prospectAccounts,
    verifiedAccounts: verifiedAccounts,
    staleAccounts: staleAccounts,
    showAccountDetails: showAccountDetails,
    onAccountPriorityClick: onAccountPriorityClick,
    openProspectCTAModal: openProspectCTAModal,
    openStaleAccountUpdateStatusModal: openStaleAccountUpdateStatusModal,
    stageOrder: stageOrder,
    trackOrder: trackOrder,
    openCollectPaymentConfirmationModal: openCollectPaymentConfirmationModal,
    openOrderDetails: openOrderDetails,
    openAccountLostCTAModal: openAccountLostCTAModal,
    confirmOrder: confirmOrder,
    confirmOrderFromDrawer: confirmOrderFromDrawer,
    openVerifyDeliveryModal: openVerifyDeliveryModal,
    accountCardMinWidth: accountCardMinWidth
  };
  const salesActivityTable = SalesActivityTableFactory.create(featureFlags, salesActivityTableInput);
  const {header: headersColumns, columns: cardsColumns}: SalesActivityPipeline  = salesActivityTable.buildPipeline();

  const fetchPipelineData = () => {
    if (paginateSalesActivity(featureFlags)) {
      salesActivityTable.fetchAwaitingDeliveryOrders(api, userSession.currentOrganization()!.id, setSalesActivityAwaitingDeliveryOrders, errorToast);
      salesActivityTable.fetchCollectPaymentOrders(api, userSession.currentOrganization()!.id, setSalesActivityCollectPaymentOrders, errorToast);
      salesActivityTable.fetchNonQualifiedAccounts(api, userSession.currentOrganization()!.id, setNonQualifiedAccounts, errorToast);
      salesActivityTable.fetchLostAccounts(api, userSession.currentOrganization()!.id, setLostAccounts, errorToast);
      salesActivityTable.fetchProspectAccounts(api, userSession.currentOrganization()!.id, setProspectAccounts, errorToast);
      salesActivityTable.fetchVerifiedAndStaleAccounts(api, userSession.currentOrganization()!.id, setVerifiedAccounts, setStaleAccounts, errorToast);
      salesActivityTable.fetchPendingOrders(api, userSession.currentOrganization()!.id, setSalesActivityPendingOrders, errorToast);
    }
  };

  const updateColumns = () => {
    showSpinner();
    fetchPipelineData();
    return salesActivityViewModel
      .initialize(api, userSession.currentOrganization()!.id, SortingCriteria.ASCENDING)
      .then(setSalesActivityViewModel)
      .catch(()=> errorToast('Couldn\'t retrieve the order information'))
      .finally(hideSpinner);
  };

  const onWon = async (account: SalesActivityAccount) => {
    showSpinner();
    salesActivityViewModel
      .markAccountAsWon(userSession.currentOrganization()!.id, account)
      .then((viewModel: SalesActivityViewModel) => {
        refreshPipelineDataAfterAccountIsWon();
        return viewModel;
      })
      .then((updatedViewModel) => {
        successToast('Account moved to Verified Buyer column');
        setSalesActivityViewModel(updatedViewModel);
        closeAccountProspectModal();
      })
      .catch(() => {
        errorToast('Couldn\'t move the account to Verified Buyer column');
      })
      .finally(hideSpinner);
  };

  const onLost = async (account: SalesActivityAccount) => {
    showSpinner();
    salesActivityViewModel
      .markAccountAsLost(userSession.currentOrganization()!.id, account)
      .then((viewModel: SalesActivityViewModel) => {
        refreshPipelineDataAfterAccountIsLostOrMovedToProspect();
        return viewModel;
      })
      .then((updatedViewModel) => {
        successToast('Account moved to lost');
        setSalesActivityViewModel(updatedViewModel);
        closeAccountProspectModal();
        closeStaleAccountStatusUpdateModal();
      })
      .catch(() => {
        errorToast('Couldn\'t move the account to Lost column');
      })
      .finally(hideSpinner);
  };

  const onSubmitAccountLostModal = async (account: SalesActivityAccount) => {
    showSpinner();
    salesActivityViewModel
      .markAccountAsProspect(userSession.currentOrganization()!.id, account)
      .then((viewModel: SalesActivityViewModel) => {
        refreshPipelineDataAfterAccountIsLostOrMovedToProspect();
        return viewModel;
      })
      .then((updatedViewModel) => {
        successToast('Account moved to Prospect');
        setSalesActivityViewModel(updatedViewModel);
        closeAccountLostModal();
      })
      .catch(() => {
        errorToast('Couldn\'t move the account to Prospect column');
      })
      .finally(hideSpinner);
  };

  const verifyDelivery = async (orderId: string | undefined) => {
    showSpinner();
    salesActivityViewModel
      .verifyOrderDelivery(userSession.currentOrganization()!.id, orderId!)
      .then((updatedViewModel) => {
        refreshPipelineDataAfterOrderIsVerifyDelivery();
        return updatedViewModel;
      })
      .then((updatedViewModel) => {
        successToast('Order delivery was verified');
        setVerifyOrderModalOrder(undefined);
        setSalesActivityViewModel(updatedViewModel);
      })
      .catch(() => {
        errorToast('Order delivery could not be verified');
      })
      .finally(hideSpinner);
  };

  const editTrackedOrderToVerifyDelivery = async (orderId: string) => {
    showSpinner();
    api.getOrder(orgId, orderId).then(order => {
      setTrackedOrderModalData({order: order, action: ()=>verifyDelivery(orderId)});
    }).finally(hideSpinner);
  };

  const trackOrderSubmit = () => {
    if(trackedOrderModalData?.action) trackedOrderModalData.action();
    closeTrackOrderModal();
    updateColumns();
  };

  const closeSidePanel = ()=> setShowDrawer(false);

  const handleSearchByAccount = (searchAccountByName: string) => {
    setSearchAccountByNameInput(searchAccountByName);
    const updatedViewModel = salesActivityViewModel.filterByAccountName(searchAccountByName);
    setSalesActivityViewModel(updatedViewModel);
  };

  const filterBySalesReps = (selectableSalesReps: SelectablePipelineFilter[]) => {
    setSalesActivityViewModel(salesActivityViewModel.filterBySalesRepName(selectableSalesReps));
  };

  useEffect(() => {
    validateOrRedirect(updateColumns);
  }, [userSession.currentOrganization()]);

  const activeCardsColumnsLength = () => {
    // TODO: calculate number of columns depending on Basic or Premium accounts
    return cardsColumns.length;
  };

  const headerMinWidth = accountCardMinWidth;


  const pipelineColumnWidth = `${100 / activeCardsColumnsLength()}%`;

  const changeSortingCriteria = () => setSalesActivityViewModel(salesActivityViewModel.changeSortingCriteria());

  return(<OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="sales-activity-page">
      <SideDrawer onClose={closeSidePanel}
        onMainAction={updateColumns}
        shouldOpen={showDrawer}
        content={drawerContent}
      />
      <div className='sales-activity-header'>
        <OrdoPageTitle title='sales activity' buttons={[]}/>
        {salesActivityTable.canTrackOrder() && <div className='track-order-button-container'>
          <OrdoMobileFriendlyButton disabled={false} text='log an order' category="primary" onClick={()=> setTrackedOrderModalData({})} dataTestId='add-track-order-header' />
        </div>}
      </div>
      {
        inMobileResolution
          ? <MobilePipeline
            columns={cardsColumns} headers={headersColumns}
            viewModel={salesActivityViewModel} setViewModel={setSalesActivityViewModel}
            searchAccountByNameInput={searchAccountByNameInput}
            changeSortingCriteria={changeSortingCriteria}
            handleSearchByAccount={handleSearchByAccount}
          />
          : <Pipeline
            columns={cardsColumns} headers={headersColumns}
            viewModel={salesActivityViewModel}
            searchAccountByNameInput={searchAccountByNameInput}
            changeSortingCriteria={changeSortingCriteria}
            handleSearchByAccount={handleSearchByAccount}
            filterBySalesReps={filterBySalesReps} accountCardMinWidth={accountCardMinWidth}
            headerMinWidth={headerMinWidth} pipelineColumnWidth={pipelineColumnWidth}
          />
      }
    </div>
    {!!accountLostModal && <ConfirmationModal show={!!accountLostModal}
      onClose={closeAccountLostModal}
      onSubmit={() => onSubmitAccountLostModal(accountLostModal)}
      confirmationText="Are you sure you want to move the Account to the Prospect column?"
    />}
    {!!accountProspectModal && <WonLostModal isOpen={!!accountProspectModal}
      account={accountProspectModal}
      onLost={() => onLost(accountProspectModal)}
      onWon={() => onWon(accountProspectModal)}
      onClose={closeAccountProspectModal}
    />}
    {!!staleAccountStatusUpdateModal && <UpdateStaleAccountStatusModal isOpen={!!staleAccountStatusUpdateModal}
      account={staleAccountStatusUpdateModal}
      onLost={() => onLost(staleAccountStatusUpdateModal)}
      ctaText={salesActivityTable.buyerActionText()}
      callToAction={salesActivityTable.buyerAction()}
      onClose={closeStaleAccountStatusUpdateModal}
    />}
    {!!verifyOrderModalOrder && <VerifyOrderDeliveryModal isOpen={!!verifyOrderModalOrder}
      orderToVerifyDelivery={verifyOrderModalOrder!} allowEdit={verifyOrderModalOrder!.activeAccount}
      onClose={closeVerifyDeliveryModal}
      onVerify={verifyDelivery}
      editTrackedOrder={editTrackedOrderToVerifyDelivery}
    />}
    {!!collectPaymentModalOrder && <LogPaymentModal isOpen={!!collectPaymentModalOrder}
      onClose={closeCollectPaymentConfirmationModal}
      onSubmit={updateColumns}
      organizationId={orgId}
      pendingPaymentOrder={{
        orderId: collectPaymentModalOrder.id ,
        orderNumber: collectPaymentModalOrder.orderNumber,
        amountOwed: Money.FromDollarAmount(0),
        invoiceURL: collectPaymentModalOrder.invoiceURL,
      }}/>}
    {!!trackedOrderModalData && <TrackOrderModal
      isOpen={!!trackedOrderModalData}
      onClose={closeTrackOrderModal}
      onSubmit={trackOrderSubmit}
      selectedLocation={trackedOrderModalData?.location}
      existingOrder={trackedOrderModalData.order}
      accountsLocations={salesActivityViewModel.getAllAccountsLocationsWithContacts()}
      organizationId={orgId!}
    />}
  </OrdoSpinner>);
};

export default withRouter(SalesActivityPage);
