import {v4} from 'uuid';

export type ActivityQuestion = {
  id: string,
  index: number,
  isRequired: boolean,
  questionTitle: string,
  questionType: ActivityQuestionType,
  options?: string[]
}

export enum ActivityQuestionType {
  NoTypeSelectedYet = '',
  TextResponse = 'text response',
  NumericResponse = 'numeric response',
  DollarValue = 'dollar value',
  DateTime = 'date & time',
  FileUpload = 'file upload',
  MultipleChoice = 'multiple choice',
  Checkboxes = 'checkboxes',
  ProductDropdown = 'product dropdown'
}

export const initialQuestion = () => [{id: v4(), index: 0, questionTitle: 'Question 1', isRequired: false, questionType: ActivityQuestionType.NoTypeSelectedYet}];

export const questionTypes = [ActivityQuestionType.TextResponse,
  ActivityQuestionType.NumericResponse,
  ActivityQuestionType.DollarValue,
  ActivityQuestionType.DateTime,
  ActivityQuestionType.FileUpload,
  ActivityQuestionType.MultipleChoice,
  ActivityQuestionType.Checkboxes,
  ActivityQuestionType.ProductDropdown];

export const parseActivityType = (typeAsString: string): ActivityQuestionType => {
  switch (typeAsString) {
  case '':
    return ActivityQuestionType.NoTypeSelectedYet;
  case 'text response':
    return ActivityQuestionType.TextResponse;
  case 'numeric response':
    return ActivityQuestionType.NumericResponse;
  case 'dollar value':
    return ActivityQuestionType.DollarValue;
  case 'date & time':
    return ActivityQuestionType.DateTime;
  case 'file upload':
    return ActivityQuestionType.FileUpload;
  case 'checkboxes':
    return ActivityQuestionType.Checkboxes;
  case 'multiple choice':
    return ActivityQuestionType.MultipleChoice;
  case 'product dropdown':
    return ActivityQuestionType.ProductDropdown;
  default:
    return ActivityQuestionType.NoTypeSelectedYet;
  }
};

export const isStaticQuestionType = (type: ActivityQuestionType) => type !== ActivityQuestionType.Checkboxes && type !== ActivityQuestionType.MultipleChoice;