import { API } from '../lib/api/ordoApi';
import { CreateAccountAssignmentRequest } from '../lib/api/request-types';
import { RepAssignment } from '../models/Assignment';
import {RemovableAccount} from '../models/RemovableAccount';

// RemovableAccount represents an account that can be 'removed'.
// This type allows us to keep track of which retail locations have been unassigned so that we can send a proper request to the backend.

// TODO (mk): Handle common errors
export default class EditRepAssignmentViewModel {
  public readonly api: API
  public readonly currentOrgId: string
  public readonly assignment: RepAssignment | undefined

  // updatedAccounts are the accounts that the user can interact with when editing the assignments.
  // We track these separately so that the user can remove the accounts as needed and submit the final list when ready.
  public readonly updatedAccounts: RemovableAccount[]

  constructor(
    api: API,
    currentOrgId: string,
    assignment: RepAssignment | undefined,
    updatedAccounts: RemovableAccount[] = []
  ) {
    this.api = api;
    this.currentOrgId = currentOrgId;
    this.assignment = assignment;
    this.updatedAccounts = updatedAccounts;
  }

  public static empty(api: API, currentOrgId: string) {
    return new EditRepAssignmentViewModel(
      api,
      currentOrgId,
      undefined,
    );
  }

  public static async initialize(api: API, currentOrgId: string, assignment: RepAssignment | undefined) {
    const updatedAccounts = assignment?.accounts.map((account) => {
      return RemovableAccount.fromAccount(account, false);
    });
    return new EditRepAssignmentViewModel(
      api,
      currentOrgId,
      assignment,
      updatedAccounts
    );
  }

  public toggleBrand(brandId: string) {
    if (!this.assignment) return this;
    const brands = this.assignment.accessibleBrands.map((brand) => {
      let { disabled } = brand;
      if (brand.id === brandId) {
        disabled = !disabled;
      }
      return {
        ...brand,
        disabled: disabled
      };
    });
    this.assignment.accessibleBrands = brands;
    return new EditRepAssignmentViewModel(
      this.api,
      this.currentOrgId,
      this.assignment,
      this.updatedAccounts
    );
  }

  public async submitAccountAssignment() {
    if (!this.assignment) return this;
    const req: CreateAccountAssignmentRequest = {
      userId: this.assignment!.member.getUserId(),
      accountsIds: this.updatedAccounts
        .filter((account) => !account.removed)
        .map((account) => account.id),
      disabledBrandIds: this.disabledBrands().map((brand) => brand.id)
    };
    await this.api.replaceAssignments(this.assignment!.orgId, req);
    return this;
  }

  public disabledBrands() {
    return this.assignment!.accessibleBrands.filter((brand) => brand.disabled);
  }

  public removeAssignedAccount(accountId: string) {
    const updatedAccounts = this.updatedAccounts.map((removableAccount) => {
      let { removed } = removableAccount;
      if (removableAccount.id === accountId) {
        removed = true;
      }
      return RemovableAccount.fromAccount(removableAccount, removed);
    });
    return new EditRepAssignmentViewModel(
      this.api,
      this.currentOrgId,
      this.assignment,
      updatedAccounts
    );
  }

  public clearAssignedAccounts() {
    const updatedAccounts = this.updatedAccounts.map((removableAccount) => {
      return RemovableAccount.fromAccount(removableAccount, true);
    });
    return new EditRepAssignmentViewModel(
      this.api,
      this.currentOrgId,
      this.assignment,
      updatedAccounts
    );
  }
}

