import React from 'react';
import Contact from '../../../../models/order-entry/Contact';
import '../../../../scss/ordo/activitiesPage/activities-list.scss';

export const ActivityContacts = ({activityContacts}: {activityContacts: Contact[]}) => {
  return(
    <div className="activity-contacts-container">
      {(activityContacts.length > 0) &&
          <div className="activity-contact ellipsis">
            <span>{activityContacts[0].name}</span>
          </div>
      }
      {(activityContacts.length > 1) &&
          <div className="activity-contact">
            <span>{`+${activityContacts.length - 1}`}</span>
          </div>
      }
    </div>
  );
};
