import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClone} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {ContactWithAccountName} from '../ContactsPage';

export const MobileContactsList = ({contacts, onSetContactToEdit} : {contacts: ContactWithAccountName[], onSetContactToEdit: (contact: ContactWithAccountName, index: number) => void}) => {
  return (<>
    {contacts.map((contactWithAccountName: ContactWithAccountName, index: number) => {
      return <div role='presentation' className='mobile-contacts-page-list-item ordo-shadow ordo-card-border' key={contactWithAccountName.contact.id} onClick={() => {
        onSetContactToEdit(contactWithAccountName, index);
      }
      }>
        <div className='mobile-contacts-page-item-info'>
          <div className='item-info-field account-info'>
            <span className='title'>{contactWithAccountName.contact.name}</span>
            <span className='order-number'>{contactWithAccountName.contact.title || '-'}</span>
            <span className='account-name'>{contactWithAccountName.accountName}</span>
          </div>
          <div className='mobile-contact-email-and-phone'>
            <div className='item-info-field'>
              <span className='description'>{contactWithAccountName.contact.phone}</span>
              {contactWithAccountName.contact.phone && <FontAwesomeIcon className='copy-icon' size="1x" icon={faClone} onClick={(event) => {
                event.stopPropagation();
                navigator.clipboard.writeText(contactWithAccountName.contact.phone);
              }}/>}
            </div>
            <div className='item-info-field'>
              <span className='description'>{contactWithAccountName.contact.email}</span>
              {contactWithAccountName.contact.email && <FontAwesomeIcon className='copy-icon' size="1x" icon={faClone} onClick={(event) => {
                event.stopPropagation();
                navigator.clipboard.writeText(contactWithAccountName.contact.email);
              }}/>}
            </div>
          </div>
        </div>
      </div>;
    })}
  </>);

};