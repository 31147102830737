import React, {useContext, useState} from 'react';
import '../../../../scss/ordo/flourish-inventory-sync.scss';
import flourishLogo from '../../../profile/flourishLogo.png';
import FlourishSyncIntegrationModal from './FlourishSyncIntegrationModal';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import {useAPI} from '../../../../context/OrdoApiContext';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import useOrdoToasts from '../../../../hooks/useOrdoToasts';
import OrdoSpinner from '../../OrdoSpinner';

type FlourishItemsSyncProps = {
  onSuccess: () => Promise<void>,
}

export const FlourishItemsSync = ({onSuccess} : FlourishItemsSyncProps) => {
  const [showFlourishSyncIntegrationModal, setShowFlourishSyncIntegrationModal] = useState(false);

  const userSessionContextData = useContext(UserSessionContext);
  const orgId = userSessionContextData.currentOrganization()!.id;
  const api = useAPI();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();

  const toggleShowFlourishSyncIntegrationModal = () => setShowFlourishSyncIntegrationModal(!showFlourishSyncIntegrationModal);

  const syncFlourishIntegration = async () => {
    showSpinner();
    api.flourishSyncInventory(orgId)
      .then(() => {
        onSuccess().then(() => {
          successToast('Flourish inventory sync was successful');
        });
      }).catch((error: { message: any; }) => {
        errorToast(error.message || 'Flourish inventory sync failed');
      }).finally(() => {
        toggleShowFlourishSyncIntegrationModal();
        hideSpinner();
      });
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <div role="presentation" className="flourish-sync-button" onClick={toggleShowFlourishSyncIntegrationModal}>
      <div className="flourish-sync-button__logo">
        <img role="presentation" className="flourish-icon" alt="flourish-icon" src={flourishLogo}/>
      </div>
      <div className='flourish-sync-button__text'>flourish sync</div>
    </div>
    {showFlourishSyncIntegrationModal &&
    <FlourishSyncIntegrationModal
      isOpen={showFlourishSyncIntegrationModal}
      onSubmit={syncFlourishIntegration}
      onClose={toggleShowFlourishSyncIntegrationModal}
    />}
  </OrdoSpinner>;
};