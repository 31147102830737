import React, {useContext} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import {UserSessionContext} from '../../context/UserSessionContext';
import {AdminChecker} from '../../routes/permissions';
import OrdoMultiSelect from '../components/common/OrdoMultiSelect';
import {HeaderColumns, SalesActivityColumns, SalesActivityEntity} from './salesActivityTypes';
import SalesActivityViewModel, {SelectablePipelineFilter} from '../../application-models/sales-activity/SalesActivityViewModel';

type PipelineProps = {
  viewModel: SalesActivityViewModel,
  searchAccountByNameInput: string,
  handleSearchByAccount: (search: string) => void,
  changeSortingCriteria: () => void,
  filterBySalesReps: (selectableSalesReps: SelectablePipelineFilter[]) => void,
  columns: SalesActivityColumns,
  headers: HeaderColumns,
  pipelineColumnWidth: string,
  headerMinWidth: number,
  accountCardMinWidth: number,
};
export const Pipeline = ({
  columns,
  headers,
  viewModel,
  pipelineColumnWidth,
  headerMinWidth,
  accountCardMinWidth,
  filterBySalesReps,
  searchAccountByNameInput,
  handleSearchByAccount,
  changeSortingCriteria
}: PipelineProps) => {

  const userSession = useContext(UserSessionContext);
  const adminChecker = new AdminChecker();

  return <div className="page-content-body">
    <div className='sales-activity-filters'>
      <input
        id='search-by-account-name-input'
        data-testid='search-by-location-name-input'
        type='text'
        className='search-by-account-input'
        placeholder='search account name'
        value={searchAccountByNameInput}
        onChange={(event) => handleSearchByAccount(event.target.value)}
      />
      {
        adminChecker.allow(userSession.userSession) &&
        <OrdoMultiSelect
          options={viewModel.salesReps()}
          onChangeSelectedOption={filterBySalesReps}
          selectedOptions={viewModel.selectedSalesReps}
          placeholder='select sales rep'
          addedStyles={{container: {width: '20%'}}}
        />
      }
      <div role="presentation" onClick={changeSortingCriteria} className="sorting-date-filter">
        <FontAwesomeIcon className="sort-icon" icon={faExchangeAlt}/>
        <span>{`Date: ${viewModel.sortingCriteriaText()}`}</span>
      </div>
    </div>
    <div className='sales-activity-page-container'>
      <div className="sales-activity-pipeline-headers ordo-shadow ordo-card-border">
        {headers.map((column) => {
          return (
            <div key={column.key} className="sales-activity-pipeline-header"
              style={{maxWidth: `${pipelineColumnWidth}`, width: `${headerMinWidth}em`}}>
              <span className="title">{column.title}</span>
              <span className="subtitle">{column.quantity}</span>
            </div>
          );
        })}
      </div>
      <div className="sales-activity-pipeline-card-container">
        {columns.map((column) => {
          return (
            <div key={column.key} className="pipeline-cards-column"
              style={{maxWidth: `${pipelineColumnWidth}`, width: `${accountCardMinWidth}em`}}>
              {column.list.map((accountOrOrder: SalesActivityEntity) => column.renderWith(accountOrOrder))}
            </div>
          );
        })}
      </div>
    </div>
  </div>;
};
