import React from 'react';
import {OrderVersionLineItem} from '../../../models/order-entry/OrderVersionLineItem';
import {ProductSize} from '../product/ProductSize';
import {LineItemType} from '../../../models/order-entry/Order';
import {LineItemNote} from './LineItemNote';


const product = (item: OrderVersionLineItem) => {
  return <>
    <div className="ordo-product-preview">
      <img src={item.image} alt={item.name} className="ordo-product-preview__image"/>
      <div className="ordo-product-preview__info">
        <span className="ordo-product-preview__info__name" title={item.name}>{item.name}</span>
        <span className="ordo-product-preview__info__detail" title={`${item.category}, ${item.genetics}`}>
          {item.category}, {item.genetics}
        </span>
      </div>
    </div>
  </>;
};
const brand = (item: OrderVersionLineItem) => {
  return <>
    <div className="ordo-product-preview">
      <img src={item.brandImage} alt={item.brandName} className="ordo-product-preview__image"/>
    </div>
  </>;
};
const size = (item: OrderVersionLineItem) => {
  return <ProductSize product={item.product} isUnit={item.type === LineItemType.Unit} unitTag/>;
};

const cases = (item: OrderVersionLineItem) => {
  return <>
    <span>{item.quantity}</span>
  </>;
};

const casePrice = (item: OrderVersionLineItem) => {
  return <>
    <span>
      {item.originalPrice()}
    </span>
  </>;
};
const discount = (item: OrderVersionLineItem) => {
  return <>
    <span>{item.discountToString()}</span>
  </>;
};
const salePrice = (item: OrderVersionLineItem) => {
  if(item.type === LineItemType.Case) {
    return <div className='d-flex flex-column'>
      <span>{item.wrappedCaseSalePrice()}</span>
      <span className='case-sale-price-subtext'>{item.wrappedCaseSalePriceSubText()}</span>
    </div>;
  }
  return <>
    <span>{item.formattedSalesPrice()}</span>
  </>;
};
const saleTotal = (item: OrderVersionLineItem) => {
  return <>
    <span>{item.saleTotal().formatted()}</span>
  </>;
};
const taxes = (item: OrderVersionLineItem) => {
  return <>
    <span> {item.exciseTax.add(item.salesTax).formatted()} </span>
  </>;
};
const total = (item: OrderVersionLineItem) => {
  return <>
    <span>{item.totalWithTaxes().formatted()}</span>
  </>;
};

const notes = (item: OrderVersionLineItem) => {
  return <>
    <LineItemNote item={item}/>
  </>;
};

export const AllOrderLineColumns = [
  {name: 'product', renderCell: product},
  {name: 'brand', renderCell: brand},
  {name: 'size', renderCell: size},
  {name: 'qty', renderCell: cases},
  {name: 'original price', renderCell: casePrice},
  {name: 'discount', renderCell: discount},
  {name: 'sale price', renderCell: salePrice},
  {name: 'sale total', renderCell: saleTotal},
  {name: 'taxes', renderCell: taxes},
  {name: 'total', renderCell: total},
  {name: '', renderCell: notes}
];

export const OrderLineColumnsForDetails = [
  {name: 'product', renderCell: product},
  {name: 'brand', renderCell: brand},
  {name: 'size', renderCell: size},
  {name: 'qty', renderCell: cases},
  {name: 'discount', renderCell: discount},
  {name: 'sale price', renderCell: salePrice},
  {name: 'taxes', renderCell: taxes},
  {name: 'total', renderCell: total},
  {name: '', renderCell: notes}
];

export const OrderLineColumnsForMobileDetails = [
  {name: 'product', renderCell: product},
  {name: 'size', renderCell: size},
  {name: 'qty', renderCell: cases},
  {name: 'sale price', renderCell: salePrice},
  {name: 'total', renderCell: total},
];
