import React, {ChangeEvent, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faStickyNote, faTimes } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import { ItemLine } from '../../../models/order-entry/ItemLine';
import '../../../scss/ordo/orderEntry/order-line-item-note.scss';
import OrdoButton from '../common/OrdoButton';
import {OrdoPopup} from '../common/OrdoPopup';
import OrdoTextArea from '../common/OrdoTextArea';
import ConfirmationModal from '../common/ConfirmationModal';
import { CartItem } from '../../../models/order-entry/CartItem';

type OrderLineItemNoteProps = {
  line: ItemLine,
  item: CartItem,
  index: number,
  updateNotes: Function
}
const CHAR_LIMIT = 100;

export const OrderEntryLineItemNote = ({line, item, index, updateNotes} :OrderLineItemNoteProps) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [localNotes, setLocalNotes] = useState(line.getNotes());

  const closePopup = ()=>{
    setLocalNotes(line.getNotes());
    setDisplayPopup(false);
  };

  return <div className='order-line-item-note relative-pos'>
    {
      line.getNotes()
        ? <div className="options">
          <FontAwesomeIcon onClick={(event) => {
            event.stopPropagation();
            setDisplayPopup(!displayPopup);
          }} className='edit' icon={faStickyNote}/>
          <FontAwesomeIcon onClick={()=>setShowDeleteConfirmation(true)} className="delete" icon={faTimes}/>
        </div>
        : <OrdoButton additionalClassName='add-note-button' category='link' onClick={()=>setDisplayPopup(true)}
          text='add note' disabled={false} dataTestId={`line-item-add-note_${index}`}
        />
    }
    {displayPopup &&
    <OrdoPopup id={v4()} title='Edit Note' popupSide='left' sidePosition='-15em' verticalPosition='1.7em' popupVerticalPlacement='top' onClose={closePopup}>
      <div className='note-edit'>
        <OrdoTextArea
          style={{
            margin: '1em 0 .5em',
            height: '6em'
          }} handleChange={(event: ChangeEvent<HTMLInputElement>) => setLocalNotes(event.target.value)}
          placeholder='' value={localNotes} maxValue={CHAR_LIMIT}
        />
        <span className='foot-note'>*max 100 characters</span>
        <div className='buttons'>
          <OrdoButton disabled={localNotes.length > CHAR_LIMIT} text='save' category="primary" onClick={()=>updateNotes(localNotes)} dataTestId='popup-save-button'/>
          <OrdoButton disabled={false} text='cancel' category="cancel" onClick={closePopup} dataTestId='popup-cancel-button'/>
        </div>
      </div>
    </OrdoPopup>
    }
    <ConfirmationModal show={showDeleteConfirmation} onClose={()=>setShowDeleteConfirmation(false)}
      onSubmit={()=>updateNotes('')} confirmationText={`Delete the notes for this line of ${item.product.name}?`}/>
  </div>;
};