import React from 'react';
import {isEmpty} from 'lodash';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {ItemLines} from './ItemLines';
import {CartItemType} from '../../../../models/order-entry/CartItem';

type MobileOrderEntryCheckoutItemsProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateViewModel: (orderEntryViewModel: OrderEntryViewModel) => void,
  landscapeMode?: boolean;
}

export const MobileOrderEntryCheckoutItems = ({orderEntryViewModel, updateViewModel, landscapeMode = false}: MobileOrderEntryCheckoutItemsProps) => {
  const casesItems = orderEntryViewModel.cartCaseItems();
  const unitItems = orderEntryViewModel.cartUnitItems();

  return (
    <div className={`mobile-checkout-products-container ${landscapeMode ? 'landscape' : ''}`}>
      {!isEmpty(casesItems) && <ItemLines
        itemType={CartItemType.CASE}
        viewModel={orderEntryViewModel}
        itemsList={casesItems}
        updateViewModel={updateViewModel}
      />}
      {!isEmpty(unitItems) && <ItemLines
        itemType={CartItemType.UNIT}
        viewModel={orderEntryViewModel}
        itemsList={unitItems}
        updateViewModel={updateViewModel}
      />}
    </div>
  );
};
