import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClone, faEdit, faEnvelope, faPhone, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import Contact from '../../../models/order-entry/Contact';
import '../../../scss/ordo/contact-information-tooltip.scss';
import EditContactModal from '../../components/modals/EditContactModal';

type MultiContactInformationTooltipProps = {
  isContactTooltipVisible: boolean,
  contacts: Contact[],
  organizationId:string,
  onContactEdit: (contact: Contact) => void
}

export const ContactInformation = ({contact, organizationId, onContactEdit, toggleDeleteContactModal, onToggleEdit}: {contact: Contact,
  organizationId: string,
  onContactEdit: (contact: Contact) => void,
  toggleDeleteContactModal?: () => void,
  onToggleEdit?: (visible: boolean) => void}) => {
  const [editContactModal, setEditContactModal] = useState(false);
  const toggleEditContactModal = () => setEditContactModal(!editContactModal);

  const onContactUpdate = (updatedContact: Contact) => onContactEdit(updatedContact);

  return <div className='multicontact-tooltip-container__contact'>
    <div className='multicontact-tooltip-container__contact__header'>
      <div className='contact-name-and-title'>
        <span className='name' title={contact.name}>{contact.name}</span>
        {contact.title && <span className='title' title={contact.title}>{contact.title}</span>}
      </div>
      <div className="contact-edit-and-delete">
        <FontAwesomeIcon className="edit-icon" onClick={() => {
          toggleEditContactModal();
          if(onToggleEdit) {
            onToggleEdit(false);
          }
        }} size="1x" icon={faEdit}/>
        {!!toggleDeleteContactModal && <FontAwesomeIcon className="edit-icon" onClick={toggleDeleteContactModal} size="1x" icon={faTrashAlt}/>}
      </div>
    </div>
    <div className='multicontact-tooltip-container__contact__fields'>
      <div className='contact-field'>
        <FontAwesomeIcon size="1x" icon={faPhone} className='contact-field__icon'/>
        <span>{contact.phone ? contact.phone : 'Phone not provided'}</span>
        <FontAwesomeIcon className='copy-icon' size="1x" icon={faClone} onClick={() => navigator.clipboard.writeText(contact.phone)}/>
      </div>
      <div className='contact-field'>
        <FontAwesomeIcon size="1x" icon={faEnvelope} className='contact-field__icon'/>
        <span>{contact.email ? contact.email : 'Email not provided'}</span>
        <FontAwesomeIcon className='copy-icon' size="1x" icon={faClone} onClick={() => navigator.clipboard.writeText(contact.email)}/>
      </div>
    </div>
    {editContactModal && <EditContactModal
      isOpen={editContactModal}
      onClose={() => {
        toggleEditContactModal();
        if(onToggleEdit) onToggleEdit(true);
      }}
      onSubmit={() => Promise.resolve()}
      updateContact={onContactUpdate}
      orgId={organizationId}
      contact={contact}
    />}
  </div>;
};

export const MultiContactInformationTooltip = ({isContactTooltipVisible, contacts, organizationId, onContactEdit} : MultiContactInformationTooltipProps) => {
  const [isVisible, setIsVisible] = useState(true);

  return (
    <div className={`multicontact-tooltip-container card card-fluid inventory-list-card ordo-card-border ordo-shadow ${isContactTooltipVisible && isVisible ? 'contact-row-tooltip-visible' : ''}`}>
      {contacts.map((contact: Contact) => {
        return <ContactInformation contact={contact} key={contact.id}
          organizationId={organizationId}
          onContactEdit={onContactEdit}
          onToggleEdit={(visible) => setIsVisible(visible)}/>;
      })}
    </div>
  );
};
