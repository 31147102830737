import React, {PropsWithChildren, ReactNode} from 'react';
import {Modal} from 'react-bootstrap';
import '../../../scss/ordo/ordo-modal.scss';
import OrdoButton from './OrdoButton';

export enum CTADisplayMode {
  CENTER = 'center',
  WIZARD = 'wizard',
}

export type OrdoModalProps = PropsWithChildren<{
  title?: string | ReactNode,
  formId?: string,
  show: boolean,
  onClose: Function,
  onSubmit?: Function,
  actionText?: string,
  showFooter?: boolean,
  footer?: ReactNode,
  dataTestId?: string,
  disableCTA?: boolean,
  size?: 'sm' | 'lg' | 'xl',
  dialogClassName?: string
  disabledCTAText?: string
  secondaryButtonText?: string
  secondaryButton?: boolean,
  onSecondaryCallToAction?: Function,
  buttonDisplay?: CTADisplayMode,
  className?: string,
  header?: boolean,
}>;

/*
* Things to have in mind when using this modal:
* - The modal CTA does not need an onSubmit if the modal body has a form
* - The modal CTA needs a formId if the modal body has a form to reference it
*/
const OrdoModal = ({
  title,
  formId,
  onClose,
  show,
  children,
  footer,
  dataTestId,
  size,
  showFooter = true,
  actionText,
  disableCTA,
  dialogClassName = '',
  onSubmit,
  disabledCTAText,
  secondaryButtonText,
  secondaryButton = true,
  onSecondaryCallToAction,
  buttonDisplay = CTADisplayMode.CENTER,
  className = '',
  header
}: OrdoModalProps) => {
  return (<Modal
    dialogClassName={dialogClassName}
    onHide={onClose}
    show={show}
    animation={false}
    data-testid={dataTestId}
    centered
    className={`ordo-modal ${className} ${title ? '' : 'no-header'} `}
    size={size}
  >
    <Modal.Header closeButton>
      {header ? title : <h5 className='header-aligned' data-testid='ordo-modal-title'>{title}</h5>}
    </Modal.Header>
    <Modal.Body>
      {children}
    </Modal.Body>
    {showFooter && <Modal.Footer className={`ordo-modal-footer--${buttonDisplay}`}>
      <div className="cta-container">
        {actionText && <OrdoButton text={actionText || ''}
          onClick={() => {
            if(onSubmit) onSubmit();
          //  This may not actually do anything because the action should be included in
          //  the form of the body the modal has. This is done to prevent default browser behaviour
          //  when the button submits.
          //  Note the formName prop, your form should have that as an id.
          }}
          category='primary'
          disabled={!!disableCTA}
          dataTestId={`${dataTestId}-modal-button`}
          formName={formId}
          disabledText={disabledCTAText}
        />}
        { secondaryButton && <OrdoButton text={secondaryButtonText || 'cancel'}
          onClick={() => {
            if(onSecondaryCallToAction) {
              onSecondaryCallToAction();
            } else {
              onClose();
            }
          }}
          category='cancel'
          disabled={false}
          dataTestId='cancel-button'
        />}
      </div>
      {footer}
    </Modal.Footer>}
  </Modal>);
};

export default OrdoModal;
