import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import CreateBrandViewModel from '../../../application-models/createBrandViewModel';
import {API} from '../../../lib/api/ordoApi';
import { Organization } from '../../../models/Organization';
import OrdoButton from '../common/OrdoButton';
import ImagePreview from './ImagePreview';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import '../../../scss/ordo/organizationPage/create-brand.scss';
import OrdoInput from '../common/inputs/OrdoInput';
import OrdoFileInput from '../common/inputs/OrdoFileInput';
import {OrdoFile} from '../../../models/OrdoFile';

const CreateBrandModal = ({onSubmit, onClose, isOpen, api, organization}: {onSubmit: Function, isOpen: boolean, onClose: Function, api: API, organization: Organization}) => {
  const [createBrandViewModel, setCreateBrandViewModel] = useState(CreateBrandViewModel.empty(api));
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const submit = () => {
    showSpinner();
    setCreateBrandViewModel(createBrandViewModel.setUploading());
    return createBrandViewModel.submit(organization.id).then(() => {
      onSubmit();
      setCreateBrandViewModel(CreateBrandViewModel.empty(api));
    }).finally(hideSpinner);
  };

  const handleImageSelected = (ordoFile: OrdoFile) => {
    setCreateBrandViewModel(createBrandViewModel.updateViewModel({logo: {preview: ordoFile.preview, raw: ordoFile.raw}}));
    document.getElementById('brand-name')?.focus();
  };

  const closeModal = () => {
    onClose();
    setCreateBrandViewModel(CreateBrandViewModel.empty(api));
  };

  const updateViewModel = (value: string) => {
    setCreateBrandViewModel(createBrandViewModel.updateViewModel({ name: value }));
  };

  const saveBrand = (event: any) => {
    event.preventDefault();
    submit();
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <Modal onHide={closeModal} show={isOpen} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Add a brand</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(event) => saveBrand(event)}>
          <div className="form-group mb-4">
            <div className="input-group upload-logo">
              {createBrandViewModel.logo?.preview && (
                <ImagePreview url={createBrandViewModel.logo.preview} alt="logo" size={150}/>
              )}
              <OrdoFileInput text="Upload Logo" onFileSelected={handleImageSelected}/>
            </div>
            <div className="form-group mt-3">
              <div className="input-group brand-name-input">
                <OrdoInput
                  inputName='brand-name'
                  value={createBrandViewModel.name}
                  placeholder="name of the brand"
                  inputSize='medium'
                  onChange={(event) => updateViewModel(event)}
                />
              </div>
            </div>
          </div>
          <div className="save-brand-button">
            <OrdoButton dataTestId="save-brand-button" disabled={!createBrandViewModel.canUpload()} text="Save" category="primary" onClick={() => {}}/>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  </OrdoSpinner>;
};

export default CreateBrandModal;
