import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import {useAPI} from '../../../context/OrdoApiContext';
import CreateOrganizationViewModel from '../../../application-models/CreateOrganizationViewModel';
import CompanyRegistrationForm from '../../registration/steps/CompanyRegistrationForm';
import OrganizationRegistration from '../../../models/organizationRegistration';
import {FormFields} from '../../../errors/form_error';
import GeneralErrorMessage from '../common/GeneralErrorMessage';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import SubscriptionRegistration from '../../../models/subscriptionRegistration';
import SubscriptionRegistrationForm from '../../registration/steps/SubscriptionForm';
import {RegistrationStep} from '../../../application-models/RegistrationViewModel';
import '../../../scss/ordo/organizationPage/create-organization.scss';
import {OrdoPayment} from '../../../models/payments/OrdoPayments';
import useOrdoToasts from '../../../hooks/useOrdoToasts';

const CreateOrganizationModal = ({onSubmit, onClose, isOpen}: {onSubmit: Function, onClose: Function, isOpen: boolean}) => {
  const api= useAPI();
  const [viewModel, setViewModel] = useState(CreateOrganizationViewModel.empty(api));
  const {errorToast, successToast} = useOrdoToasts();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const closeModal = () => {
    onClose();
    setViewModel(CreateOrganizationViewModel.empty(api));
    OrdoPayment.reset();
  };

  const registerCompany = async () => {
    showSpinner();
    const viewModelRegistrationAttempted = await viewModel.registerCompany();
    if(!viewModelRegistrationAttempted.hasError()){
      onSubmit();
      closeModal();
      successToast('The new organization was successfully created');
      OrdoPayment.reset();
    } else {
      setViewModel(viewModelRegistrationAttempted);
      errorToast('There was a problem trying to create the organization. Please try later');
    }
    hideSpinner();
  };

  const updateCompanyRegistration = (fieldsToUpdate: OrganizationRegistration) => {
    const companyRegistration = new OrganizationRegistration({
      ...viewModel.companyRegistration,
      ...fieldsToUpdate,
    });
    const newViewModel = viewModel.updateCompanyRegistration(companyRegistration);
    setViewModel(newViewModel);
  };

  const updatePlanRegistration = (updatedRegistration: SubscriptionRegistration) => {
    const newViewModel = viewModel.updateSubscriptionRegistration(updatedRegistration);
    setViewModel(newViewModel);
  };

  const doStepBack = () => {
    setViewModel(viewModel.stepBack());
  };

  const doNextStep = () => {
    setViewModel(viewModel.nextStep());
  };

  const checkEmptyFieldError = (field: FormFields) => {
    if (viewModel.hasErrorFor(field)) {
      const newViewModel = viewModel.removeErrorFor(field);
      setViewModel(newViewModel);
    }
  };

  return  <OrdoSpinner showSpinner={spinnerVisibility}>
    <Modal onHide={closeModal} show={isOpen} animation={false} data-testid='create-organization-modal'
      dialogClassName={viewModel.currentStep === RegistrationStep.RegisterSubscription ? 'wide-modal-dialog' : ''}>
      <Modal.Header closeButton>
        <Modal.Title>Register a company</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <GeneralErrorMessage viewModel={viewModel}/>
        {viewModel.currentStep === RegistrationStep.RegisterCompany && <CompanyRegistrationForm
          registrationViewModel={viewModel}
          updateCompanyRegistration={updateCompanyRegistration}
          onCompanyRegistration={doNextStep}
          checkEmptyFieldError={checkEmptyFieldError}
          isModal
        />}
        {viewModel.currentStep === RegistrationStep.RegisterSubscription &&<SubscriptionRegistrationForm
          viewModel={viewModel}
          updatePlanRegistration={updatePlanRegistration}
          onStepBack={doStepBack}
          onCallToAction={registerCompany}
          isModal
        />}
      </Modal.Body>
    </Modal>
  </OrdoSpinner>;
};

export default CreateOrganizationModal;
