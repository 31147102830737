import {useToasts} from 'react-toast-notifications';

export default function useOrdoToasts(){
  const {addToast} = useToasts();

  const successToast = (message: string) => addToast(message, { autoDismiss: true, appearance: 'success' });
  const errorToast = (message: string) => addToast(message, { autoDismiss: true, appearance: 'error' });

  return {successToast: successToast, errorToast: errorToast};

}