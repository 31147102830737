import {OrganizationLicense} from '../../models/OrganizationLicense';
import {FirstMetrcSyncStep} from './FirstMetrcSyncStep';
import {API} from '../../lib/api/ordoApi';
import {MetrcItemSyncStep, MetrcSyncStep} from './MetrcItemSyncStep';
import {ThirdMetrcSyncStep} from './ThirdMetrcSyncStep';
import {FourthMetrcSyncStep} from './FourthMetrcSyncStep';

export class SecondMetrcSyncStep implements MetrcSyncStep {
  constructor() {
  }

  step(): MetrcItemSyncStep {
    return MetrcItemSyncStep.SECOND;
  }

  actionText(): string {
    return 'begin sync';
  }

  secondaryActionText(): string {
    return 'back';
  }

  disableAction(_selectedLicenses: OrganizationLicense[]): boolean {
    return false;
  }

  headerSubtitle(): string {
    return 'by continuing all allocations will be cleared';
  }

  headerText(): string {
    return 'metrc sync';
  }

  nextStep(): MetrcSyncStep {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new ThirdMetrcSyncStep();
  }

  previousStep(): MetrcSyncStep {
    return new FirstMetrcSyncStep();
  }

  submit(api: API, orgId:string, selectedLicenses: OrganizationLicense[], callback: Function) {
    api.metrcSyncInventory(orgId, selectedLicenses).then((syncInventorySummary) => {
      callback(new FourthMetrcSyncStep(), syncInventorySummary);
    });
  }
}