import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import { Organization } from '../../../models/Organization';
import InviteUsersViewModel from '../../../application-models/InviteUsersViewModel';
import {API} from '../../../lib/api/ordoApi';
import OrdoButton from '../common/OrdoButton';
import OrdoSpinner from '../OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoInput from '../common/inputs/OrdoInput';
import useOrdoToasts from '../../../hooks/useOrdoToasts';

const InviteUsersModal = ({onClose, onSubmit, isOpen, organization, api}:
  { isOpen: boolean,
    onSubmit: Function,
    api: API
    onClose: Function,
    organization: Organization}) => {

  const {successToast, errorToast} = useOrdoToasts();
  const [inviteUsersViewModel, setInviteUsersViewModel] = useState(InviteUsersViewModel.emptyViewModel(api));

  const closeModal = () => {
    setInviteUsersViewModel(InviteUsersViewModel.emptyViewModel(api));
    onClose();
  };

  const updateEmail = (email: string) => {
    const updatedViewModel = inviteUsersViewModel.updateEmail(email);
    setInviteUsersViewModel(updatedViewModel);
  };

  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const sendInvitations = () => {
    showSpinner();
    inviteUsersViewModel.sendInvitations(organization.id)
      .then(() => {
        successToast('Invitation sent!');
        onSubmit();
        setInviteUsersViewModel(InviteUsersViewModel.emptyViewModel(api));
      })
      .catch((_e) => errorToast('Something went wrong, could not send the invitation'))
      .finally(hideSpinner);
  };


  return (<OrdoSpinner showSpinner={spinnerVisibility}>
    <Modal
      onHide={closeModal}
      show={isOpen}
      animation={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Invite users to {organization.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Provide an email below to invite members to your team</p>
        <form onSubmit={(event) => {
          event.preventDefault();
          sendInvitations();
        }}>
          <div className="form-group mb-5 ">
            <div className="form-group mt-3">
              <div className="input-group">
                <OrdoInput inputName="invite-user-email-input"
                  value={inviteUsersViewModel.email}
                  placeholder="email"
                  onChange={updateEmail}
                  inputSize="large"
                  isValid={inviteUsersViewModel.isValidEmailInput()}
                  invalidMessage={inviteUsersViewModel.errorMessage}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <OrdoButton dataTestId="invite-user-button"
              disabled={!inviteUsersViewModel.isValidInvitation()}
              text="Send invitation" category="primary" onClick={() => {}}/>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  </OrdoSpinner>);
};
export default InviteUsersModal;
