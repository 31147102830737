import {API} from '../lib/api/ordoApi';
import UserRegistration from '../models/userRegistration';
import OrganizationRegistration from '../models/organizationRegistration';
import BusinessError from '../errors/domain_errors';
import {User} from '../models/User';
import FormError, {belongsToOrganizationFormFields, belongsToUserFormFields, FormFields} from '../errors/form_error';
import InvalidField from '../errors/invalid_field';
import {Organization} from '../models/Organization';
import SubscriptionRegistration from '../models/subscriptionRegistration';

export enum RegistrationStep {
  RegisterUser = 0,
  RegisterCompany = 1,
  RegisterSubscription = 2,
}

export type RegistrationViewModelInput = {
  currentStep: number,
  ordoAPI: API,
  userRegistration: UserRegistration,
  companyRegistration: OrganizationRegistration,
  subscriptionRegistration: SubscriptionRegistration,
  error?: FormError
  registeredUser?: User
}

export default class RegistrationViewModel {
  public currentStep: RegistrationStep;
  public userRegistration: UserRegistration;
  public companyRegistration: OrganizationRegistration;
  public subscriptionRegistration: SubscriptionRegistration;
  public registeredUser?: User;
  public registeredOrganization?: Organization;

  private readonly ordoAPI: API;
  private error: FormError;

  constructor(viewModelInput: RegistrationViewModelInput) {
    this.currentStep = viewModelInput.currentStep;
    this.userRegistration = viewModelInput.userRegistration;
    this.companyRegistration = viewModelInput.companyRegistration;
    this.subscriptionRegistration = viewModelInput.subscriptionRegistration;
    this.ordoAPI = viewModelInput.ordoAPI;
    this.error = viewModelInput.error || FormError.withoutError();
    this.registeredUser = viewModelInput.registeredUser;
  }

  public static emptyRegistrationViewModel(ordoAPI: API) {
    const userRegistration = UserRegistration.emptyUserRegistration();
    const companyRegistration = OrganizationRegistration.emptyCompanyRegistration();
    const subscriptionRegistration = SubscriptionRegistration.empty();
    return new RegistrationViewModel({
      currentStep: RegistrationStep.RegisterUser,
      ordoAPI: ordoAPI,
      userRegistration: userRegistration,
      companyRegistration: companyRegistration,
      subscriptionRegistration: subscriptionRegistration
    });
  }

  public async registerUser(): Promise<RegistrationViewModel> {
    try {
      const userInput = {email: this.userRegistration.email,
        firstName: this.userRegistration.firstName,
        lastName: this.userRegistration.lastName,
        phone: this.userRegistration.phone,
        password: this.userRegistration.password,
        passwordConfirmation: this.userRegistration.passwordConfirmation
      };
      const {user} = await this.ordoAPI.register(userInput, this.userRegistration.password);
      this.registeredUser = user;
      return this;
    } catch (error) {
      if (error instanceof BusinessError) {
        return new RegistrationViewModel({
          currentStep: RegistrationStep.RegisterUser,
          ordoAPI: this.ordoAPI,
          userRegistration: this.userRegistration,
          companyRegistration: this.companyRegistration,
          error: error.formError,
          subscriptionRegistration: this.subscriptionRegistration
        });
      }
      const unknownErrorForm = FormError.unknown(error.message);
      return new RegistrationViewModel({
        currentStep: RegistrationStep.RegisterUser,
        ordoAPI: this.ordoAPI,
        userRegistration: this.userRegistration,
        companyRegistration: this.companyRegistration,
        subscriptionRegistration: this.subscriptionRegistration,
        error: unknownErrorForm,
      });
    }
  }

  public async registerUserAndCompany(): Promise<RegistrationViewModel>{
    if (!this.companyRegistrationIsValid()) {
      const companyInvalidFields = this.companyRegistration.invalidFields();
      return this.updateErrors(companyInvalidFields);
    }
    try {
      const userAndOrganization = await this.ordoAPI.registerUserAndCompany(this.userRegistration, this.companyRegistration, this.subscriptionRegistration);
      this.registeredUser = userAndOrganization.user;
      this.registeredOrganization = userAndOrganization.organization;
      this.error = FormError.withoutError();
      return this;
    } catch (error) {
      if (error instanceof BusinessError) {
        return new RegistrationViewModel({
          currentStep: this.getCurrentStep(error.formError.singleError()),
          ordoAPI: this.ordoAPI,
          userRegistration: this.userRegistration,
          companyRegistration: this.companyRegistration,
          subscriptionRegistration: this.subscriptionRegistration,
          error: error.formError,
        });
      }
      const unknownErrorForm = FormError.unknown(error.message);
      return new RegistrationViewModel({
        currentStep: this.currentStep,
        ordoAPI: this.ordoAPI,
        userRegistration: this.userRegistration,
        companyRegistration: this.companyRegistration,
        subscriptionRegistration: this.subscriptionRegistration,
        error: unknownErrorForm,
      });
    }
  }

  public nextStep(): RegistrationViewModel {
    if (!this.hasNextStep()) return this;
    if (!this.userRegistrationIsValid() && this.currentStep === RegistrationStep.RegisterUser) {
      const userInvalidFields = this.userRegistration.invalidFields();
      return this.updateErrors(userInvalidFields);
    }
    if (!this.companyRegistrationIsValid()  && this.currentStep === RegistrationStep.RegisterCompany) {
      const companyInvalidFields = this.companyRegistration.invalidFields();
      return this.updateErrors(companyInvalidFields);
    }
    return new RegistrationViewModel({
      currentStep: this.currentStep + 1,
      ordoAPI: this.ordoAPI,
      userRegistration: this.userRegistration,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
    });
  }

  public stepBack(companyRegistration: OrganizationRegistration) {
    if (!this.hasPreviousStep()) return this;
    return new RegistrationViewModel({
      currentStep: this.currentStep - 1,
      ordoAPI: this.ordoAPI,
      userRegistration: this.userRegistration,
      companyRegistration: companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
    });
  };

  public updateCompanyRegistration(companyRegistration: OrganizationRegistration) {
    return new RegistrationViewModel({
      currentStep: this.currentStep,
      ordoAPI: this.ordoAPI,
      userRegistration: this.userRegistration,
      companyRegistration: companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
    });
  }

  public updateUserRegistration(userRegistration: UserRegistration) {
    return new RegistrationViewModel({
      currentStep: this.currentStep,
      ordoAPI: this.ordoAPI,
      userRegistration: userRegistration,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
    });
  }

  public updateSubscriptionRegistration(updatedRegistration: SubscriptionRegistration) {
    return new RegistrationViewModel({
      currentStep: this.currentStep,
      ordoAPI: this.ordoAPI,
      userRegistration: this.userRegistration,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: updatedRegistration,
      error: this.error,
    });
  }

  public checkPasswords() {
    if (!this.matchingPasswords()) {
      this.error.addError(new InvalidField('passwordConfirmation', 'Passwords don\'t match'));
    } else {
      this.error.removeErrorFor('passwordConfirmation');
    }
    return new RegistrationViewModel({
      currentStep: this.currentStep,
      ordoAPI: this.ordoAPI,
      userRegistration: this.userRegistration,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
    });
  }


  public updateErrors(invalidFields: InvalidField[]) {
    const updatedFormError = FormError.withErrors(invalidFields);
    return new RegistrationViewModel({
      currentStep: this.currentStep,
      ordoAPI: this.ordoAPI,
      userRegistration: this.userRegistration,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: updatedFormError,
    });
  }

  public matchingPasswords() {
    return this.userRegistration.password === this.userRegistration.passwordConfirmation;
  }

  public hasError() {
    return this.error.hasError();
  }

  public hasErrorFor(input: FormFields) {
    return this.error.hasErrorForInput(input);
  }

  public removeErrorFor(input: FormFields) {
    this.error.removeErrorFor(input);
    return new RegistrationViewModel({
      currentStep: this.currentStep,
      ordoAPI: this.ordoAPI,
      userRegistration: this.userRegistration,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
    });
  }

  errorMessage(input: FormFields): string {
    return this.error.errorMessage(input);
  }

  public userRegistrationIsValid() {
    return this.userRegistration.isValid();
  }

  public companyRegistrationIsValid() {
    return this.companyRegistration.isValid();
  }

  private hasNextStep(): boolean {
    return (this.currentStep + 1) in RegistrationStep;
  }

  private hasPreviousStep(): boolean {
    return (this.currentStep - 1) in RegistrationStep;
  }

  private getCurrentStep(field: FormFields) {
    if (belongsToUserFormFields(field)) {
      return RegistrationStep.RegisterUser;
    }
    if (belongsToOrganizationFormFields(field)) {
      return RegistrationStep.RegisterCompany;
    }
    return this.currentStep;
  }
};
