import React from 'react';
import {MetrcItemsSyncViewModel} from '../../../../application-models/metrc-integration/MetrcItemsSyncViewModel';
import {MetrcItemSyncStep} from '../../../../application-models/metrc-integration/MetrcItemSyncStep';
import metrcLogo from '../../../profile/metrc.png';
import OrdoModal, {CTADisplayMode} from '../../common/OrdoModal';

type MetrcInventorySyncWizardProps = {
  viewModel: MetrcItemsSyncViewModel,
  show: boolean,
  onClose: () => void,
  onSubmit: () => void,
  onSecondaryAction: () => void,
  steps: Map<MetrcItemSyncStep, ()=>JSX.Element>
};

export function MetrcInventorySyncWizard({
  viewModel,
  show,
  onClose,
  onSubmit,
  onSecondaryAction,
  steps
}: MetrcInventorySyncWizardProps) {

  const modalHeader = (<>
    <div className="metrc-sync-button__logo">
      <img role="presentation" className="metrc-icon" alt="metrc-icon" src={metrcLogo}/>
    </div>
    <div className='metrc-sync-button__text'>{viewModel.getHeaderText()}</div>
  </>);

  const metrcIntegrationModalHeader = (
    <div>
      <div className="metrc-integration-header">
        {modalHeader}
      </div>
      {viewModel.getHeaderSubtitle() &&
      <span className="metrc-integration-header-text">{viewModel.getHeaderSubtitle()}</span>}
    </div>
  );

  const renderStep = steps.get(viewModel.getCurrentStep()) || (()=>(<></>));

  return <OrdoModal size={viewModel.isStep(MetrcItemSyncStep.FOURTH) ? 'lg' : 'sm'} show={show}
    secondaryButtonText={viewModel.secondaryButtonText()}
    onClose={onClose} onSubmit={onSubmit}
    showFooter={!viewModel.isStep(MetrcItemSyncStep.THIRD)}
    title={metrcIntegrationModalHeader} disableCTA={viewModel.shouldDisableCTA()}
    buttonDisplay={CTADisplayMode.WIZARD}
    actionText={viewModel.getCTAText()} secondaryButton={!!viewModel.secondaryButtonText()}
    onSecondaryCallToAction={onSecondaryAction} className='metrc-inventory-sync-modal'>
    <div className="metrc-integration-sync-container">
      {renderStep()}
    </div>
  </OrdoModal>;
}