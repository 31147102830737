import React, {useState} from 'react';
import {ProductWithAvailability} from '../../../models/productWithAvailability';
import OrdoModal from '../common/OrdoModal';
import {AddToCart} from './AddToCart';
import {OrderEntryViewModel} from '../../../application-models/order-entry/OrderEntryViewModel';
import {CartItemType} from '../../../models/order-entry/CartItem';
import {ProductSize} from '../product/ProductSize';
import '../../../scss/ordo/inventoryPage/inventory-list-item.scss';
import '../../../scss/ordo/order-entry-page.scss';
import OrdoButton from '../common/OrdoButton';


type OrderEntryListItemProps = {
  product: ProductWithAvailability,
  onChange: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void,
  orderEntryViewModel: OrderEntryViewModel;
}

const OrderEntryListItem = ({product, onChange, orderEntryViewModel} : OrderEntryListItemProps) => {
  const amountOfCases =  orderEntryViewModel.amountOfProductFor(product.id, CartItemType.CASE);
  const amountUnits = orderEntryViewModel.amountOfProductFor(product.id, CartItemType.UNIT);
  const currentAvailableUnitsQuantity = product.availability - amountUnits - (amountOfCases * product.unitsPerCase);
  const currentAvailableCasesQuantity = Math.floor((product.availability - amountUnits)/product.unitsPerCase) - amountOfCases;

  const [showCOAModal, setShowCOAModal] = useState(false);

  const updateCaseOrder = (newAmount: number) => {
    if(currentAvailableCasesQuantity > 0 || amountOfCases - newAmount >= 0) {
      onChange(product, newAmount, CartItemType.CASE);
    }
  };

  const updateUnitOrder = (newAmount: number) => {
    if(currentAvailableUnitsQuantity > 0 || amountUnits - newAmount >= 0) {
      onChange(product, newAmount, CartItemType.UNIT);
    }
  };

  const removeProductOrder = () => {
    onChange(product, 0, CartItemType.CASE);
  };

  const removeUnitOrder = () => {
    onChange(product, 0, CartItemType.UNIT);
  };

  const productCategoryAndGenetics = product.categoryAndGenetics();
  return (
    <tr className="inventory-list-item-row">
      <td style={{margin: '1em 0'}}>
        <div className="item-image-and-name d-flex">
          <img src={product.imageUrl} alt={product.name} className="product-image h6 mb-0 text-sm"/>
          <div className="item-image-and-name__name">
            <div className="item-name-and-category column-text">
              <span className="product-name">{product.name}</span>
              <span className="product-category">{productCategoryAndGenetics}</span>
              <span className="product-category product-sku-short" title={product.customerSKU}>{product.customerSKU}</span>
            </div>
            <div className="coa-info">
              {product.firstCOA() && <OrdoButton additionalClassName='coa-button' dataTestId={`${product.id}-coa`} disabled={false} text="coa"
                category="primary" onClick={() => setShowCOAModal(true)}/>}
              <OrdoModal showFooter={false} size="xl" show={showCOAModal} onClose={() => setShowCOAModal(false)}>
                <embed src={product.firstCOA()} width="100%" height="500em"/>
              </OrdoModal>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="item-image-and-name d-flex">
          <img src={product.brand.logoImageURL} alt={product.brand.name} className="product-image h6 mb-0 text-sm"/>
        </div>
      </td>
      <td>
        <div className="column-text">
          <ProductSize product={product} isCaseOnly={product.caseSaleOnly} isUnit={product.unitSaleOnly} displayUnits={!product.unitSaleOnly}/>
        </div>
      </td>
      <td>
        <div className="column-text">
          <div className="product-info">
            {!product.caseSaleOnly && <span className={!product.unitSaleOnly ? 'upper-info' : ''}>{`$${((product.salesPrice())).toFixed(2)}`}</span>}
            {!product.unitSaleOnly && <span className={!product.caseSaleOnly ? 'bottom-info': ''}>{`$${((product.salesPrice() * product.unitsPerCase)).toFixed(2)}`}</span>}
          </div>
        </div>
      </td>
      <td>
        <div className="column-text">
          <div className="product-info">
            {!product.caseSaleOnly && <span className={!product.unitSaleOnly ? 'upper-info' : ''}>{`${Math.max(0, currentAvailableUnitsQuantity)}`}</span>}
            {!product.unitSaleOnly && <span className={!product.caseSaleOnly ? 'bottom-info': ''}>{`${Math.max(0, currentAvailableCasesQuantity)}`}</span>}
          </div>
        </div>
      </td>
      <td>
        <div className="add-orders">
          {!product.caseSaleOnly && <AddToCart
            dataTestIdSuffix='order-entry-list-add-unit-to-cart-button'
            productId={product.id} productType={CartItemType.UNIT} page='list'
            buttonText='add unit'
            inputLabel='unit'
            productIsDeleted={!!product.deletedAt}
            updateOrder={updateUnitOrder}
            removeOrder={removeUnitOrder}
            emptyOrders={amountUnits === 0}
            ordersAmount={amountUnits}
            maxAmount={product.availability - amountOfCases * product.unitsPerCase}
            style={{marginTop: '1.5em', marginBottom: '1.5em' }}
            stylesForButton={{padding: '.4em 2.5em'}}
          />}
          {!product.unitSaleOnly && <AddToCart
            dataTestIdSuffix='order-entry-list-add-case-to-cart-button'
            productId={product.id} productType={CartItemType.CASE} page='list'
            buttonText='add case'
            inputLabel='case'
            productIsDeleted={!!product.deletedAt}
            updateOrder={updateCaseOrder}
            removeOrder={removeProductOrder}
            emptyOrders={amountOfCases === 0}
            ordersAmount={amountOfCases}
            maxAmount={(product.availability - amountUnits)/product.unitsPerCase}
            style={{marginBottom: product.caseSaleOnly ? undefined :'1.5em' }}
            stylesForButton={{padding: '.4em 2.5em'}}
          />}
        </div>
      </td>
    </tr>);
};

export default OrderEntryListItem;
