import {OrganizationLicense} from '../../models/OrganizationLicense';
import {FirstMetrcSyncStep} from './FirstMetrcSyncStep';
import {API} from '../../lib/api/ordoApi';
import {MetrcItemSyncStep, MetrcSyncStep} from './MetrcItemSyncStep';

export class FourthMetrcSyncStep implements MetrcSyncStep {
  constructor() {
  }

  step(): MetrcItemSyncStep {
    return MetrcItemSyncStep.FOURTH;
  }

  actionText(): string {
    return 'complete';
  }

  secondaryActionText(): string {
    return '';
  }

  disableAction(_selectedLicenses: OrganizationLicense[]): boolean {
    return false;
  }

  headerSubtitle(): string {
    return '';
  }

  headerText(): string {
    return 'metrc sync summary';
  }

  nextStep(): MetrcSyncStep {
    return new FirstMetrcSyncStep();
  }

  previousStep(): MetrcSyncStep {
    return new FirstMetrcSyncStep();
  }

  submit(_api: API, _orgId: string, _selectedLicences: OrganizationLicense[], _callback: Function): void {
  }
}