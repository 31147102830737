import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight, faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {HeaderColumns, SalesActivityColumns, SalesActivityEntity} from './salesActivityTypes';
import SalesActivityViewModel from '../../application-models/sales-activity/SalesActivityViewModel';

type MobilePipelineProps = {
  viewModel: SalesActivityViewModel,
  setViewModel: (vm: SalesActivityViewModel) => void,
  searchAccountByNameInput: string,
  handleSearchByAccount: (search: string) => void,
  changeSortingCriteria: () => void,
  columns: SalesActivityColumns,
  headers: HeaderColumns
};
export const MobilePipeline = ({
  columns,
  headers,
  viewModel,
  setViewModel,
  searchAccountByNameInput,
  handleSearchByAccount,
  changeSortingCriteria
}: MobilePipelineProps) => {
  const displayedColumn = viewModel.currentMobileColumn;
  const setDisplayedColumn = (columnIndex: number) => setViewModel(viewModel.changeCurrentColumn(columnIndex));
  const column = columns[displayedColumn];
  const header = headers[displayedColumn];
  return <div className="page-content-body mobile-pipeline-content">
    <div className='sales-activity-filters'>
      <input
        id='search-by-account-name-input'
        data-testid='search-by-account-name-input'
        type='text'
        className='search-by-account-input'
        placeholder='search account name'
        value={searchAccountByNameInput}
        onChange={(event) => handleSearchByAccount(event.target.value)}
      />
      <div
        role="presentation"
        onClick={changeSortingCriteria} className="sorting-date-filter"
        title={`Date: ${viewModel.sortingCriteriaText()}`}
      >
        <FontAwesomeIcon className="sort-icon" icon={faExchangeAlt}/>
        <span className="sorting-text">{viewModel.sortingCriteriaText(true)}</span>
      </div>
    </div>
    <div className='sales-activity-page-container mobile-pipeline-content'>
      <div className="sales-activity-pipeline-mobile-header">
        <div className="header-navigation navigate-back"
          style={{visibility: displayedColumn > 0 ? undefined : 'hidden'}}
          role="presentation"
          onClick={() => setDisplayedColumn(displayedColumn - 1)}
        >
          <FontAwesomeIcon icon={faChevronLeft}/>
        </div>
        <div key={header.key} className="sales-activity-pipeline-header">
          <span className="title">{header.title}</span>
          <span className="subtitle">{header.quantity}</span>
        </div>
        <div className="header-navigation navigate-forward"
          style={{visibility: displayedColumn < columns.length - 1 ? undefined : 'hidden'}}
          role="presentation"
          onClick={() => setDisplayedColumn(displayedColumn + 1)}
        >
          <FontAwesomeIcon icon={faChevronRight}/>
        </div>
      </div>
      <div className="sales-activity-pipeline-card-container">
        <div key={column.key} className="pipeline-cards-column">
          {column.list.map((accountOrOrder: SalesActivityEntity) => column.renderWith(accountOrOrder))}
        </div>
      </div>
    </div>
  </div>;
};
