import React, {useState} from 'react';
import '../../../scss/ordo/integrations/flourish-integration.scss';
import flourishLogo from '../flourish.jpg';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import EditFlourishIntegrationModal from './EditFlourishIntegrationModal';
import OrdoButton from '../../components/common/OrdoButton';
import {appConfig} from '../../../lib/config';
import SensitiveValueText from '../../components/common/SensitiveValueText';

type FlourishIntegrationConfig = {
  syncInventoryOrItems: string
}

export type FlourishIntegration = {
  id: string,
  apiUsername: string,
  apiKey: string,
  signatureKey: string,
  organizationId: string,
  config: FlourishIntegrationConfig,
}


type FlourishIntegrationRowProps = {
  integration: FlourishIntegration,
  onDeleteIntegration: Function
  onUpdateIntegration: Function
};

const FlourishIntegrationRow = ({integration, onDeleteIntegration, onUpdateIntegration}: FlourishIntegrationRowProps) => {
  const [openedDeleteIntegrationModal, setOpenedIntegrationContactModal] = useState(false);
  const [openedEditIntegrationModal, setOpeneEditIntegrationContactModal] = useState(false);
  const openDeleteIntegrationModal = () => setOpenedIntegrationContactModal(true);
  const openEditIntegrationModal = () => setOpeneEditIntegrationContactModal(true);
  const closeDeleteIntegrationModal = () => setOpenedIntegrationContactModal(false);
  const closeEditIntegrationModal = () => setOpeneEditIntegrationContactModal(false);

  const integrationDelete = () => onDeleteIntegration(integration);
  const integrationUpdate = (configId: string, flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string) => {
    onUpdateIntegration(configId, flourishAPIUsername, flourishAPIKey, syncInventoryOrItems).then(() => closeEditIntegrationModal());
  };

  return(<div className="flourish-integration">
    <div className="flourish-logo-and-api-key">
      <div className="flourish-logo">
        <img role='presentation' className="flourish-icon" alt='flourish-icon' src={flourishLogo}/>
      </div>
      <div className="flourish-api-username-information">
        <span className="title">API Username</span>
        <SensitiveValueText value={integration.apiUsername}/>
      </div>
      <div className="flourish-api-key-information">
        <span className="title">API Key</span>
        <SensitiveValueText value={integration.apiKey}/>
      </div>
      <div className="flourish-signature-key-information">
        <span className="title">Webhook Signing Key</span>
        <SensitiveValueText value={integration.signatureKey}/>
      </div>
      <div className="flourish-sync-inventory-or-items">
        <span className="title">Sync inventory or items</span>
        <span className="key">{integration.config?.syncInventoryOrItems || 'Both'}</span>
      </div>
      <div className="flourish-signature-key-information">
        <span className="title">Webhook Endpoint URL</span>
        <span className="key">{appConfig.apiUrl}/flourish-webhook</span>
      </div>
      <div className="flourish-signature-key-information">
        <span className="title">Webhook Headers</span>
        <ul>
          <li><span className="key" style={{fontWeight: 'bold'}}>Key:</span>&nbsp;<span>content-type</span>&nbsp;-&nbsp;<span style={{fontWeight: 'bold'}}>Value:</span>&nbsp;<span>application/json</span></li>
          <li><span className="key" style={{fontWeight: 'bold'}}>Key:</span>&nbsp;<span>organization-id</span>&nbsp;-&nbsp;<span style={{fontWeight: 'bold'}}>Value:</span>&nbsp;<span>{integration.organizationId}</span></li>
        </ul>
      </div>
    </div>
    <div className="flourish-action-buttons">
      <div>
        <OrdoButton disabled={false} text="Edit" category="primary" onClick={openEditIntegrationModal} dataTestId=""/>
        <OrdoButton disabled={false} text="Delete" category="secondary" onClick={openDeleteIntegrationModal} dataTestId=""/>
      </div>
    </div>
    {openedDeleteIntegrationModal &&
    <ConfirmationModal
      show={openedDeleteIntegrationModal}
      onClose={closeDeleteIntegrationModal}
      onSubmit={integrationDelete}
      confirmationText="are you sure you want to delete Flourish integration config?"
      actionText='delete'
    />}
    {openedEditIntegrationModal &&
    <EditFlourishIntegrationModal
      isOpen={openedEditIntegrationModal}
      onSubmit={integrationUpdate}
      onClose={closeEditIntegrationModal}
      integration={integration}
    />}
  </div>);
};

export default FlourishIntegrationRow;
