import React, {useContext, useEffect, useState} from 'react';
import '../../../../scss/ordo/accountsPage/accountPage/account-page.scss';
import {useHistory} from 'react-router';
import {Note} from '../../../../models/Note';
import {Activity, ActivityTemplate} from '../../../../models/sales-activity/Activity';
import {useAPI} from '../../../../context/OrdoApiContext';
import Account from '../../../../models/Account';
import {AccountActivityEntity, AccountActivityType} from '../../../../models/AccountActivity';
import {AccountRepAssignment} from '../../../../models/AccountRepAssignment';
import OrderWithCurrentVersion from '../../../../models/order-entry/OrderWithCurrentVersion';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {AccountActivityFilters} from './filter/AccountActivityFilters';
import {AccountActivityFilter} from './filter/AccountActivityFilter';
import {AccountActivityList} from './filter/AccountActivityList';
import OrdoCardOptionsDropdown from '../../../components/common/OrdoCardOptionsDropdown';
import OrdoModal from '../../../components/common/OrdoModal';
import {NoteForm} from '../../../components/notes/NoteForm';
import NoteViewModel from '../../../../application-models/sales-activity/NoteViewModel';
import useOrdoToasts from '../../../../hooks/useOrdoToasts';
import {ActivityForm} from '../../../components/activities/ActivityForm';
import ActivityViewModel from '../../../../application-models/sales-activity/ActivityViewModel';
import ROUTE_NAMES from '../../../../routes/ROUTE_NAMES';
import {OrderEntryCartContext} from '../../../../context/OrderEntryCartContext';
import {useFeatureFlags} from '../../../../context/FeatureFlagsContext';
import {displayPlaceAnOrderAction} from '../../../../lib/featureFlags';
import {User} from '../../../../models/User';

export const AccountActivity = ({account, accountActivity, templates, salesReps, setAccountActivity}: {account: Account, accountActivity: AccountActivityEntity[], templates: ActivityTemplate[], salesReps: User[], setAccountActivity: (account: AccountActivityEntity[]) => void}) => {
  const api = useAPI();
  const orderEntryCartContextData = useContext(OrderEntryCartContext);
  const featureFlags = useFeatureFlags();
  const history = useHistory();
  const {successToast, errorToast} = useOrdoToasts();
  const {spinnerVisibility, showSpinner, hideSpinner} = useSpinnerToggle();
  const [filter, setFilter] = useState(AccountActivityFilter.default());
  const [createNoteModalOpen, setCreateNoteModalOpen] = useState(false);
  const [createActivityModalOpen, setCreateCActivityModalOpen] = useState(false);
  const [noteViewModel, setNoteViewModel] = useState(NoteViewModel.empty(api));
  const [activityViewModel, setActivityViewModel] = useState(ActivityViewModel.empty(api));

  useEffect(() => {
    setActivityViewModel(activityViewModel.withTemplatesAndAccountContactsAndSalesReps(templates, {accountId: account.id, contacts: account.contacts, name: account.name, assignments: account.assignments.map(assignment => assignment.member.user)}, salesReps));
  }, [account, templates, salesReps]);

  const toggleNoteModal = () => setCreateNoteModalOpen(!createNoteModalOpen);
  const toggleActivityModal = () => {
    setCreateCActivityModalOpen(!createActivityModalOpen);
    setActivityViewModel(activityViewModel.clearActivity());
  };

  const stageOrder = () => {
    orderEntryCartContextData.clearCart();
    history.push(ROUTE_NAMES.ORDER_ENTRY, {accountId: account.id});
  };

  const deleteEntity = (index: number) => {
    const updatedActivities = [...accountActivity];
    updatedActivities.splice(index, 1);
    setAccountActivity(updatedActivities);
  };

  const updateEntity = (index: number, updatedEntity: (Activity | Note | AccountRepAssignment | OrderWithCurrentVersion), entityType: AccountActivityType, sortingDate: Date) => {
    const updatedAccountActivityEntity = new AccountActivityEntity(updatedEntity, entityType, sortingDate);
    const updatedAccountActivity = accountActivity.slice(0);
    updatedAccountActivity[index] = updatedAccountActivityEntity;
    setAccountActivity(updatedAccountActivity);
  };

  const createNote = () => {
    showSpinner();
    noteViewModel.createNote(account.organizationId, account.id)
      .then((createdNote: Note) => {
        const noteAsActivityEntity : AccountActivityEntity = new AccountActivityEntity(createdNote, AccountActivityType.NOTE, new Date(createdNote.lastEdited));
        setAccountActivity(accountActivity.concat(noteAsActivityEntity));
        successToast('Note created');
        toggleNoteModal();
      })
      .catch(() => errorToast('Could not create note'))
      .finally(hideSpinner);
  };

  const createActivity = () => {
    showSpinner();
    activityViewModel.createActivity(account.id, account.organizationId)
      .then((createdActivity: Activity) => {
        const activityAsActivityEntity : AccountActivityEntity = new AccountActivityEntity(createdActivity, AccountActivityType.ACTIVITY, createdActivity.date);
        setAccountActivity(accountActivity.concat(activityAsActivityEntity));
        setActivityViewModel(activityViewModel.clearActivity());
        successToast('Activity created');
        toggleActivityModal();
      })
      .catch(() => errorToast('Could not create activity'))
      .finally(hideSpinner);
  };

  const actionOptions = () => {
    const options: any = [{
      name: 'add note',
      action: () => {
        toggleNoteModal();
      }
    },
    {
      name: 'add activity',
      action: () => {
        toggleActivityModal();
      }
    }];

    if (displayPlaceAnOrderAction(featureFlags)) {
      options.push({
        name: 'place an order',
        action: () => {
          stageOrder();
        },
        disabled: !account.orderEntryEnabled
      });
    }

    return options;
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="account-activity card card-fluid inventory-list-card ordo-shadow ordo-card-border">
        <div>
          <span className="section-title">account activity</span>
        </div>
        <div className="filters-and-options-container">
          <AccountActivityFilters filter={filter} onUpdateFilter={setFilter}/>
          <OrdoCardOptionsDropdown options={actionOptions()} size="2x"/>
        </div>
        <AccountActivityList accountActivity={accountActivity} filter={filter} account={account} deleteEntity={deleteEntity} updateEntity={updateEntity}/>
      </div>
      {createNoteModalOpen && <OrdoModal title='add note' showFooter={false} show={createNoteModalOpen} onClose={toggleNoteModal}>
        <div className="note-form-container">
          <NoteForm viewModel={noteViewModel} updateViewModel={setNoteViewModel} onSubmit={createNote} onCancel={toggleNoteModal} accountContacts={account.contacts}/>
        </div>
      </OrdoModal>}
      {createActivityModalOpen && <OrdoModal title='add activity' showFooter={false} show={createActivityModalOpen} onClose={toggleActivityModal}>
        <ActivityForm selectedAccountId={account.id}
          viewModel={activityViewModel}
          updateViewModel={setActivityViewModel}
          onSubmit={createActivity}
          onCancel={toggleActivityModal}
          accountContacts={account.contacts}
          canEditSalesRep
          accountsWithContacts={[{accountId: account.id, contacts: account.contacts, name: account.name, assignments: account.assignments.map(assignment => assignment.member.user)}]}
        />
      </OrdoModal>}
    </OrdoSpinner>);
};
