import React, {useEffect, useState} from 'react';
import {ActivityItem} from '../../../../models/sales-activity/Activity';
import {useAPI} from '../../../../context/OrdoApiContext';
import useOrdoToasts from '../../../../hooks/useOrdoToasts';
import {ActivityItemRow} from './ActivityItemRow';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../components/OrdoSpinner';
import OrdoButton from '../../../components/common/OrdoButton';
import {ActivityModal} from '../../../components/modals/ActivityModal';
import {ActivitiesFilter} from '../../filter/ActivitiesFilter';

type ActivitiesListProps = {
  organizationId: string,
  filter: ActivitiesFilter
}

export const ActivitiesList = ({organizationId, filter} : ActivitiesListProps) => {
  const [activities, setActivities] = useState<ActivityItem[]>([]);
  const [filteredActivities, setFilteredActivities] = useState<ActivityItem[]>([]);
  const api = useAPI();
  const {errorToast} = useOrdoToasts();
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const [addActivityModal, setAddActivityModal] = useState(false);

  useEffect(() => {
    showSpinner();
    api.getOrganizationActivities(organizationId)
      .then((activitiesResponse: ActivityItem[]) => {
        setActivities(activitiesResponse);
        setFilteredActivities(filter.filterActivityItems(activitiesResponse));
      })
      .catch(() => errorToast('Could not fetch organization activities'))
      .finally(hideSpinner);
  }, [organizationId]);

  useEffect(() => {
    setFilteredActivities(filter.filterActivityItems(activities));
  }, [filter, activities]);

  const removeActivity = (activityId: string) => {
    const updatedActivities = activities.filter(activity => activity.id !== activityId);
    setActivities(updatedActivities);
  };

  const editActivity = (updatedActivity: ActivityItem) => {
    const index = activities.findIndex((activity) => activity.id === updatedActivity.id);
    activities.splice(index, 1, updatedActivity);
    setActivities(activities);
  };

  const toggleAddActivityModal = () => setAddActivityModal(!addActivityModal);

  const addActivity = (activity: ActivityItem) => {
    setActivities(activities.concat(activity));
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="add-activity-button">
        <OrdoButton disabled={false} text="add activity" category="primary" onClick={toggleAddActivityModal} dataTestId=""/>
      </div>
      { filteredActivities.length > 0 && <div className="activity-items">
        <div className="activities-list-headers">
          <div className="status">
            <span>Status</span>
          </div>
          <div className="account">
            <span>Account</span>
          </div>
          <div className="activity-name">
            <span>Activity name</span>
          </div>
          <div className="contact">
            <span>Contact(s)</span>
          </div>
          <div className="date">
            <span>Date</span>
          </div>
          <div className="description">
            <span>Description</span>
          </div>
          <div className="assigned">
            <span>Assigned</span>
          </div>
          <div className="edit-activity"/>
          <div className="delete-activity"/>
        </div>
        {filteredActivities.map((activity: ActivityItem) => <ActivityItemRow
          key={activity.id}
          activityItem={activity}
          orgId={organizationId}
          onActivityEdit={editActivity}
          onDeleteActivity={removeActivity}/>)}
      </div>}
      {addActivityModal && <ActivityModal isOpen={addActivityModal}
        organizationId={organizationId}
        onSubmit={addActivity}
        onSubmitMany={addActivity}
        onClose={toggleAddActivityModal}
        canEditSalesRep
        customClassName='wide-modal'/>}
      {filteredActivities.length === 0 && <span>No activities created</span>}
    </OrdoSpinner>);
};
