import FormError from '../errors/form_error';
import {ProductFormInput, ProductFormViewModel} from './ProductFormViewModel';
import {API} from '../lib/api/ordoApi';
import Product from '../models/Product';

export default class EditProductViewModel extends ProductFormViewModel {
  private readonly productId: string;

  public static initialize(api: API, editingProduct: Product) {
    return new EditProductViewModel(this.existingProductInput(editingProduct), FormError.withoutError(), api, false, editingProduct.id);
  }

  constructor(viewModelInput: ProductFormInput, errors: FormError, api: API, inProgress: boolean, productId: string ) {
    super(viewModelInput, errors, api, inProgress);
    this.productId = productId;
  }

  private static existingProductInput(editingProduct: Product): ProductFormInput {
    return {
      name: editingProduct.name,
      brand: editingProduct.brand,
      category: editingProduct.category,
      imageUrl: editingProduct.imageUrl,
      image: null,
      price: editingProduct.price,
      unitsPerCase: editingProduct.unitsPerCase,
      consumerUnitSizeAmount: editingProduct.size?.amount,
      consumerUnitSizeUnit: editingProduct.size?.unit,
      genetics: editingProduct.genetics,
      preRolls: editingProduct.preRolls,
      unitSaleOnly: editingProduct.unitSaleOnly,
      caseSaleOnly: editingProduct.caseSaleOnly,
      customerSKU: editingProduct.customerSKU,
    };
  }

  public new(productInfo: ProductFormInput, errors: FormError, api: API, inProgress: boolean) {
    return new EditProductViewModel(productInfo, errors, api, inProgress, this.productId);
  }


  public async action(orgId: string): Promise<void> {
    await this.api.editProduct(orgId, this.productId, this.productInformation, this.getImageData());
  }

  public actionName() {
    return 'update';
  }

  public hasImage(): boolean {
    return !!this.productInformation.image || !!this.productInformation.imageUrl;
  }

  public get imagePreview(): string {
    const preview = this.productInformation.image ? this.productInformation.image?.preview  : this.productInformation.imageUrl;
    return preview || '';
  }

  public getImageData() {
    return this.productInformation.image?.raw;
  }

  public isUnitSaleOnly(): boolean {
    return this.productInformation.unitSaleOnly;
  }
}
