import 'firebase/auth';
import BusinessError from '../errors/domain_errors';
import FormErrorHandler from '../errors/formErrorHandler';
import {UserFormFields} from '../errors/form_error';

export default class FirebaseErrorHandler {
  private static ERROR_CODES_AND_MESSAGES: { [firebaseCode: string]: { code: UserFormFields, message: string } } = {
    'auth/wrong-password': {
      code: 'emailOrPassword',
      message: 'Invalid email or password'
    },
    'auth/user-not-found': {
      code: 'emailOrPassword',
      message: 'Invalid email or password'
    },
    'auth/invalid-email': {
      code: 'emailOrPassword',
      message: 'Invalid email or password'
    },
    'auth/weak-password': {
      code: 'password',
      message: 'Password too weak'
    },
    'auth/email-already-in-use': {
      code: 'email',
      message: 'Email already in use'
    },
    'auth/invalid-action-code': {
      code: 'email',
      message: 'Password reset link invalid'
    },
    'auth/expired-action-code': {
      code: 'email',
      message: 'Password reset link expired'
    },
    'auth/user-disabled': {
      code: 'email',
      message: 'Your user has been disabled, please contact an administrator'
    },
  }

  public static handleBusinessError(error: any): BusinessError {
    const {code, message} = error;
    const field = FirebaseErrorHandler.fromFirebaseErrorCodeToField(code);
    const formError = FormErrorHandler.handleError(field, message);
    return new BusinessError(formError, message);
  }

  public static handleError(error: any): Error {
    const errorCode = error.code;
    const message = FirebaseErrorHandler.generateOrdoErrorMessage(errorCode);
    return new Error(message);
  }


  private static fromFirebaseErrorCodeToField(code: string): UserFormFields {
    return FirebaseErrorHandler.ERROR_CODES_AND_MESSAGES[code].code || 'unknown';
  }

  private static generateOrdoErrorMessage(code: string) {
    return FirebaseErrorHandler.ERROR_CODES_AND_MESSAGES[code].message || 'unknown';
  }
}
