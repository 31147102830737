import React from 'react';
import Product from '../../../models/Product';

export const ProductSize = ({product, isCaseOnly, isUnit, displayUnits=false, unitTag=false}: { product: Product, isCaseOnly?:boolean, isUnit?: boolean, displayUnits?: boolean, unitTag?: boolean}) => {
  return (
    <div>
      {(displayUnits && !isCaseOnly) && <div className={`product-size ${displayUnits ? 'units' : ''}`} title={product.unitsPerCaseToString()}>
        <span>{`${product.unitsToString()}`}</span>
      </div>}
      <div className={`product-size ${displayUnits ? 'cases' : ''} ${isCaseOnly ? 'cases-only' : ''}`} title={product.unitsPerCaseToString()}>
        <span>{`${product.unitsToString()}`}</span>
        {!isUnit && <span>{product.perCaseToString()}</span>}
        {isUnit && unitTag && <span className='only-unit-tag'>unit</span>}
      </div>
    </div>
  );
};
