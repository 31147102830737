import { API } from '../lib/api/ordoApi';
import { UserSession } from '../models/UserSession';

/**
 * UserSessionRefresher utilizes the API to update the UserSessionContext.
 * This is intended to be used when we perform certain actions that might update the user session or we want fresh data.
 *
 */
export class UserSessionRefresher {
  constructor(
    private readonly api: API,
    private readonly updateUserSessionContext:(session: UserSession) => void
  ) {}

  async refresh(): Promise<UserSession> {
    const profile = await this.api.userProfile();
    const session: UserSession = {
      user: profile.user,
      role: profile.role,
      organizations: profile.organizations,
      pendingInvitations: profile.pendingInvitations,
      currentOrganization: profile.organizations.find((org) => org.id === profile.user.currentOrganizationId),
      emailVerified: profile.emailVerified,
    };
    this.updateUserSessionContext(session);
    return session;
  }
}
