// ED: Centralize all .env access here so later on NginX sub_filter runs only once
// https://nginx.org/en/docs/http/ngx_http_sub_module.html#sub_filter_once

/* eslint-disable no-process-env */
export const appConfig = {
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.REACT_APP_FIREBASE_APP_ID || '',
  },
  apiUrl: process.env.REACT_APP_ORDO_API_URL || '',
  launchDarklyClientId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID || '',
  datadog: {
    appId: process.env.REACT_APP_DATADOG_APP_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || ''
  },
  appUrl: process.env.REACT_APP_ORDO_URL || '',
  googleLoginClientId: process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID || '',
  googleLoginOauthScope: process.env.REACT_APP_GOOGLE_OAUTH_SCOPE || '',
  env: process.env.NODE_ENV,
  squareConfig: {
    applicationId: process.env.REACT_APP_SQUARE_APPLICATION_ID || '',
    locationId: process.env.REACT_APP_SQUARE_LOCATION_ID || ''
  },
  ordoVersion: process.env.REACT_APP_ORDO_VERSION
};
