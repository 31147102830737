import React, {FC, useContext, useEffect, useState, useCallback} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import InventoryList from '../components/inventory/InventoryList';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import {UserSessionRefresher} from '../../context/UserSessionRefresher';
import '../../scss/ordo/inventoryPage/inventory-page.scss';
import useOrganizationRedirect from '../../hooks/useOrganizationRedirect';
import {UserSessionContext} from '../../context/UserSessionContext';
import useTabs from '../../hooks/useTabs';
import CustomPricingTiers from '../components/custom-pricing-tiers/CustomPricingTiers';
import {disableForNabisUserWithRestrictedAccess} from '../../lib/featureFlags';
import {useFeatureFlags} from '../../context/FeatureFlagsContext';
import {Brand} from '../../models/Brand';
import Product from '../../models/Product';
import ProductCategory, {CategoryNames} from '../../models/ProductCategory';
import {useAPI} from '../../context/OrdoApiContext';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';

type InventoryPageProps = RouteComponentProps & {
  refresher: UserSessionRefresher,
}

enum InventoryTabState {
  inventory = 'inventory',
  customPricingTiers = 'pricing'
}

function tabTitle(tab: InventoryTabState): string {
  switch (tab) {
  case InventoryTabState.customPricingTiers:
    return 'custom price tiers';
  case InventoryTabState.inventory:
  default:
    return 'inventory & allocations';
  }
}

const InventoryPage: FC<InventoryPageProps>  = ({refresher}: InventoryPageProps) => {
  const {validateOrRedirect} = useOrganizationRedirect();
  const userSession = useContext(UserSessionContext);
  const featureFlags = useFeatureFlags();
  const api = useAPI();
  const organizationId = userSession.currentOrganization()!.id;
  const {hideSpinner,showSpinner,spinnerVisibility} = useSpinnerToggle();

  const [brands, setBrands] =  useState<Brand[]>([]);
  const [products, setProducts] =  useState<Product[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);


  const {currentTab, buttons} = useTabs<InventoryTabState>(InventoryTabState.inventory, Object.values(InventoryTabState).map((t) => ({
    value: t as InventoryTabState,
    name: t
  })));
  const customTiersAccess = !disableForNabisUserWithRestrictedAccess(userSession.userSession, featureFlags);

  const fetchData = useCallback(() => {
    showSpinner();
    Promise.all([
      api.getProducts(organizationId),
      api.getBrandsForOrg(organizationId),
      api.getCategories(organizationId).then((categoriesResponse: ProductCategory[]) => categoriesResponse.filter(category => (Object.values(CategoryNames) as string[]).includes(category.name)))
    ]).then(([  updatedProducts, updatedBrands, updatedCategories]) => {
      setProducts(updatedProducts);
      setBrands(updatedBrands);
      setCategories(updatedCategories);
    }).finally(hideSpinner);
  },[]);

  const refreshProducts = () => {
    showSpinner();
    return api.getProducts(organizationId)
      .then(newProducts => setProducts(newProducts))
      .finally(hideSpinner);
  };

  useEffect(() => {
    validateOrRedirect(fetchData);
  }, [organizationId]);

  useEffect(() => {
    showSpinner();
    refresher.refresh().finally(hideSpinner);
  }, []);

  return (
    <div className='inventory-page'>
      <OrdoPageTitle
        title={tabTitle(currentTab)}
        buttons={buttons}
        visibleTabs={customTiersAccess}
      />
      <div className="page-content-body">
        {customTiersAccess && <CustomPricingTiers
          brands={brands} categories={categories} products={products}
          isVisible={currentTab === InventoryTabState.customPricingTiers}
          loading={spinnerVisibility}
        />}
        <InventoryList
          loading={spinnerVisibility}
          isVisible={currentTab === InventoryTabState.inventory}
          brands={brands} categories={categories} products={products}
          refreshProducts={refreshProducts}
        />
      </div>
    </div>
  );
};

export default withRouter(InventoryPage);
