import {Location} from './Location';
import {Allocation} from './allocation/allocation';
import {RepAssignment} from './Assignment';
import Contact from './order-entry/Contact';
import {Note} from './Note';
import {Activity} from './sales-activity/Activity';
import {AccountPriority, AccountStatus} from '../pages/sales-activity/salesActivityTypes';
import {ActualMember} from './member/ActualMember';

export type AccountData = {
  id: string,
  name: string,
  organizationId: string,
  locations: AccountLocation[],
  allocations: Allocation[],
  assignments: RepAssignment[],
  contacts: Contact[],
  notes: Note[],
  activities: Activity[],
  stalePeriod: number,
  priority: AccountPriority,
  value: number,
  conversion: number,
  active: boolean,
  orderEntryEnabled: boolean,
  status: AccountStatus,
  lastOrderDate?: Date
}

export type AccountLocation = {
  location: Location,
  locationId: string,
  accountId: string,
  organizationId: string,
  account?: Account,
  streetAddressLine1?: string
  streetAddressLine2?: string
  city?: string
  state: string
  zipCode: string,
  county: {name: string, id: string}
}

export type AccountInfo = {
  stalePeriod?: number;
  conversion?: number;
  value?: number
}

export const isSameAccountLocation = (accountLocation: AccountLocation, anotherAccountLocation: AccountLocation) => {
  return (accountLocation.location.id === anotherAccountLocation.location.id &&
    accountLocation.accountId === anotherAccountLocation.accountId &&
    accountLocation.organizationId === anotherAccountLocation.organizationId);
};

export const accountLocationHasStreetAddress = (accountLocation: AccountLocation) => {
  return accountLocation.streetAddressLine1 || accountLocation.streetAddressLine2;
};

export const NON_ACTIVE_ACCOUNT_TEXT = 'Non Active Account';

export default class Account {
  public readonly id: string;
  public readonly name: string;
  public readonly organizationId: string;
  public readonly locations: AccountLocation[];
  public readonly allocations: Allocation[];
  public readonly assignments: RepAssignment[];
  public readonly contacts: Contact[];
  public readonly notes: Note[];
  public readonly activities: Activity[];
  public readonly stalePeriod: number;
  public readonly priority: AccountPriority;
  public readonly value: number;
  public readonly conversion: number;
  public readonly active: boolean;
  public readonly orderEntryEnabled: boolean;
  public readonly status: AccountStatus;
  public readonly lastOrderDate?: Date;

  public static placeholder(): Account {
    return new Account({
      id: '',
      name: '',
      organizationId: '',
      locations: [],
      allocations: [],
      assignments: [],
      contacts: [],
      notes: [],
      activities: [],
      // status: AccountStatus,
      stalePeriod: 30,
      priority: 3,
      value: 0,
      conversion: 0,
      active: true,
      orderEntryEnabled: true,
      status: AccountStatus.UNKNOWN
    });
  }

  constructor(data: AccountData) {
    this.id = data.id;
    this.name = data.name;
    this.organizationId = data.organizationId;
    this.locations = data.locations;
    this.allocations = data.allocations;
    this.assignments = data.assignments;
    this.contacts = data.contacts;
    this.notes = data.notes;
    this.activities = data.activities;
    this.stalePeriod = data.stalePeriod;
    this.priority = data.priority;
    this.value = data.value;
    this.conversion = data.conversion;
    this.active = data.active;
    this.orderEntryEnabled = data.orderEntryEnabled;
    this.status = data.status;
    this.lastOrderDate = data.lastOrderDate;
  }

  static withStalePeriod(account: Account, stalePeriod: number) {
    return new Account({
      id: account.id,
      name: account.name,
      organizationId: account.organizationId,
      locations: account.locations,
      allocations: account.allocations,
      assignments: account.assignments,
      contacts: account.contacts,
      notes: account.notes,
      activities: account.activities,
      stalePeriod: stalePeriod,
      priority: account.priority,
      value: account.value,
      conversion: account.conversion,
      active: account.active,
      orderEntryEnabled: account.orderEntryEnabled,
      status: account.status
    });
  }

  public hasLocationFromCounties(counties: string[]) {
    const accountCounties = this.allAccountCountiesNames();
    return counties.some(county => accountCounties.includes(county));
  }

  public getLocations() : AccountLocation[] {
    return this.locations;
  };

  public getStalePeriod(): number {
    return this.stalePeriod;
  }

  public allAccountCountiesNames() : string[]{
    return this.getLocations().map(location => location.county.name);
  }

  public allAccountZipCodes() {
    return this.getLocations().map(location => location.zipCode);
  }

  public allAccountLicenses() {
    return this.getLocations().map(accLocation => accLocation.location.licenseNumber);
  }

  public hasLicenseStartingWith(prefix: string) : boolean {
    return this.locations.length > 0 && this.getLocations().some(accLocation => accLocation.location.licenseNumber.toLowerCase().startsWith(prefix));
  }

  public hasLocationWithZipCode(zipCodes: string[]) {
    const accountCounties = this.allAccountZipCodes();
    return zipCodes.some(zipCode => accountCounties.includes(zipCode));
  }

  public matchesLicenseSearch(search: string) {
    const licenses = this.allAccountLicenses();
    return licenses.some(license => license.toLowerCase().includes(search));
  }

  public address() {
    return this.locations.length === 1 ? `${this.locations[0].location.addressLine1} ${this.locations[0].location.addressLine2}` : undefined;
  }

  public hasLocation(location: Location) {
    return this.locations.some(accountLocation => accountLocation.location.id === location.id);
  }

  public amountOfLicenses() {
    return this.getLocations().length;
  }

  public hasContacts(): boolean {
    return this.contacts.length > 0;
  }

  public addAssignment(assignment: RepAssignment) {
    this.assignments.push(assignment);
  }

  public hasAssigned(member: ActualMember): boolean {
    return !!this.assignments.find(assignment => assignment.member.hasUserId(member.getUserId()));
  }
};
