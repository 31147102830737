export default class Contact {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly email: string,
    public readonly phone: string,
    public readonly accountId: string,
    public readonly title?: string,
  ) {}
}

export const emptyContact = new Contact('', '', '', '', '', '');
