import {API} from '../lib/api/ordoApi';
import Batch from '../models/Batch';
import FormError, {FormFields} from '../errors/form_error';

export default class DeleteProductBatchViewModel {
  public batch: Batch
  private api: API
  protected errors: FormError;

  public static initialize(batch: Batch, api: API) {
    return new DeleteProductBatchViewModel(batch, api);
  }

  constructor(batch: Batch, api: API, error?: FormError) {
    this.batch = batch;
    this.api = api;
    this.errors = error || FormError.withoutError();
  }

  public hasError() {
    return this.errors.hasError();
  }

  public hasErrorFor(field: FormFields) {
    return this.errors.hasErrorForInput(field);
  }

  public errorMessage() {
    return this.errors.errorMessage('unknown');
  }

  public async deleteBatch(organizationId: string) {
    try {
      await this.api.deleteBatch(organizationId, this.batch);
      return new DeleteProductBatchViewModel(this.batch, this.api);
    } catch (e) {
      return new DeleteProductBatchViewModel(this.batch, this.api, FormError.unknown(e.message));
    }
  }
}
