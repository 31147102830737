import React, {useEffect, useState} from 'react';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Product from '../../../models/Product';
import '../../../scss/ordo/inventoryPage/batches.scss';
import {ProductHeaderForAllocations} from '../product/ProductHeaderPreview';
import OrdoModal from '../common/OrdoModal';
import GeneralErrorMessage from '../common/GeneralErrorMessage';
import {AllocationsViewModel} from '../../../application-models/allocationsViewModel';
import {Organization} from '../../../models/Organization';
import {API} from '../../../lib/api/ordoApi';
import OrdoSpinner from '../OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import '../../../scss/ordo/inventoryPage/allocations-modal.scss';
import OrdoInput from '../common/inputs/OrdoInput';
import OrdoSearchableDropdown from '../common/searchable-dropdown/OrdoSearchableDropdown';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {SelectableOption} from '../common/searchable-dropdown/selectableOption';
import Account from '../../../models/Account';
import { Allocation } from '../../../models/allocation/allocation';
import OrdoInformativeSearchableDropdown from '../common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {OrganizationLicense} from '../../../models/OrganizationLicense';

type AllocationsModalProps = {
  isOpen: boolean,
  onClose: Function,
  product: Product,
  api: API,
  organization: Organization,
}

const AllocationsModal = ({onClose, isOpen, product, api, organization}: AllocationsModalProps) => {
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const [viewModel, setViewModel] = useState(AllocationsViewModel.emptyAllocationsViewModel(api, product, organization));
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  useEffect(() => {
    showSpinner();
    AllocationsViewModel.initialize(api, organization, product)
      .then((updatedViewModel: AllocationsViewModel) => {
        setViewModel(updatedViewModel);
      })
      .finally(hideSpinner);
  }, [product, isOpen]);

  const closeModal = () => {
    setViewModel(AllocationsViewModel.emptyAllocationsViewModel(api, product, organization));
    onClose();
  };

  const validateAllocations = () => {
    const newViewModel = viewModel.validateAllocations();
    setViewModel(newViewModel);
  };

  const updateOrgMemberAllocation = (allocation: Allocation, quantity: string) => {
    const number = quantity === '' ? 0 : parseInt(quantity, 10);
    const newViewModel = viewModel.updateOrgMembersAllocations(allocation, number);
    setViewModel(newViewModel);
  };

  const updateAccountAllocation = (allocation: Allocation, quantity: string) => {
    const number = quantity === '' ? 0 : parseInt(quantity, 10);
    const newViewModel = viewModel.updateAccountAllocation(allocation, number);
    setViewModel(newViewModel);
  };

  const deleteAccountAllocation = (allocation: Allocation) => {
    const newViewModel = viewModel.deleteAccountAllocation(allocation);
    setViewModel(newViewModel);
  };

  const { successToast } = useOrdoToasts();

  const saveAllocations = async () => {
    setDisabledSaveButton(true);
    showSpinner();
    const newViewModel = await viewModel.saveAllocations(organization.id);
    if(newViewModel.hasErrorFor('unknown')) {
      setViewModel(newViewModel);
      hideSpinner();
    } else {
      successToast('Allocations created successfully');
      closeModal();
      setDisabledSaveButton(false);
      hideSpinner();
    }
  };

  const addAccountAllocation = (option: SelectableOption<Account>) => {
    const selectedAccount = (option as SelectableOption<Account>).value;
    const newViewModel = viewModel.addAccountAllocation(selectedAccount);
    setViewModel(newViewModel);
  };

  const updateCurrentDistributor = async (selectableDistributor: OrganizationLicense) => {
    showSpinner();
    viewModel.updateCurrentDistributor(selectableDistributor)
      .then(updatedViewModel => setViewModel(updatedViewModel))
      .finally(hideSpinner);

  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <OrdoModal
      show={isOpen}
      header
      title={<div className='allocations-modal-header'>
        <h5 className='title'>{viewModel.hasDistributors() ? 'allocate inventory for' : 'allocate inventory'}</h5>
        {viewModel.hasDistributors() && <OrdoInformativeSearchableDropdown
          selectedOption={viewModel.getSelectedDistributor()}
          singleRowHeight
          canEditValue={viewModel.hasMoreThanOneDistributor()}
          options={viewModel.getSelectableDistributors()}
          onChangeSelectedOption={(distributor) => updateCurrentDistributor(distributor)}
          placeholder='select a distributor'
        />}
      </div>}
      onClose={closeModal}
      actionText="Save"
      showFooter={viewModel.hasDistributors()}
      disableCTA={viewModel.hasErrorFor('unknown') || disabledSaveButton}
      onSubmit={saveAllocations}
    >
      {viewModel.hasDistributors() &&
          <>
            <GeneralErrorMessage viewModel={viewModel}/>

            <ProductHeaderForAllocations product={product} totalStock={viewModel.totalStock}/>
            <div className="allocations-modal__section-divider">
              <span>rep allocations</span>
              <hr/>
            </div>

            <div className="allocations-modal__allocations_section">
              {viewModel.orgMembersAllocations().map((allocation, index) => {
                return <div className="allocations-modal__allocations_section__allocation" key={`${allocation.name()}-${allocation.assignedToId()}`}>
                  <div className="allocations-modal__allocations_section__allocation__name" title={allocation.name()}>
                    <span> {allocation.name()} </span>
                  </div>
                  <div className="allocations-modal__allocations_section__allocation__update">
                    <OrdoInput inputName={`allocation-quantity-org-member-${index}`}
                      value={allocation.quantity}
                      inputType="number"
                      placeholder=""
                      inputSize="extra-short"
                      onBlur={validateAllocations}
                      onChange={(quantity) => updateOrgMemberAllocation(allocation, quantity)}
                      bottomMargin={false}
                      style={{width: '4.5em'}}
                      additionalClassName='allocation_amount_input'
                    />
                  </div>
                </div>;
              })}
            </div>

            <div className="allocations-modal__section-divider">
              <span>customer allocations</span>
              <hr/>
            </div>
            <div className="allocations-modal__accounts-section">
              <div className="allocations-modal__accounts-section__search_account__select">
                <OrdoSearchableDropdown
                  options={viewModel.selectableAccounts}
                  selectedOption=''
                  onChangeSelectedOption={(option) => addAccountAllocation(option)}
                  singleRowHeight
                />
              </div>
              <div className="allocations-modal__allocations_section">
                {viewModel.displayableAccountAllocations.map((allocation) => {
                  return <div className="allocations-modal__allocations_section__allocation" key={allocation.assignedToId()}>
                    <div className="allocations-modal__allocations_section__allocation__name" title={allocation.name()}>
                      <span> {allocation.name()} </span>
                    </div>
                    <div className="allocations-modal__allocations_section__allocation__update">
                      <OrdoInput inputName={`${allocation.name()}-${allocation.assignedToId()}`}
                        value={allocation.quantity}
                        inputType="number"
                        placeholder=""
                        inputSize="extra-short"
                        onBlur={validateAllocations}
                        onChange={(quantity) => updateAccountAllocation(allocation, quantity)}
                        bottomMargin={false}
                        style={{width: '4.5em'}}
                        additionalClassName='allocation_amount_input'
                      />
                      <FontAwesomeIcon className="ml-3 interectable-icon" size="lg" color="#8492A6" icon={faMinusCircle}
                        onClick={() => deleteAccountAllocation(allocation)}/>
                    </div>
                  </div>;
                })}
              </div>
            </div>
          </>
      }
      {
        !viewModel.hasDistributors() &&
          <div className='no-licenses'>
            <span className='text-dark'>Please, enable an organization license to allocate inventory</span>
          </div>
      }
    </OrdoModal>
  </OrdoSpinner>;
};

export default AllocationsModal;
