import {PipelineButtonText} from '../../../pages/sales-activity/salesActivityFunctions';
import {SalesActivityPipeline, SalesActivityTable} from './SalesActivityTable';
import {SalesActivityPipelineBuilder} from './SalesActivityPipelineBuilder';

export class ProSalesActivityTable extends SalesActivityTable {

  public canTrackOrder(): boolean {
    return false;
  }

  public buildPipeline(): SalesActivityPipeline {
    return new SalesActivityPipelineBuilder(this)
      .withNonQualifiedLead()
      .withProspectBuyers()
      .withVerifiedPurchasers()
      .withStalePurchasers()
      .withCollectPayment()
      .withLostPurchasers()
      .build();
  }

  public buyerAction(){
    return ()=>{};
  }

  public buyerActionText(): PipelineButtonText {
    return PipelineButtonText.NO_ACTION;
  }

}