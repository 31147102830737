import React, {useContext, useState} from 'react';
import {ActivityTemplateWithCount} from '../../../../models/sales-activity/Activity';
import OrdoCardOptionsDropdown from '../../../components/common/OrdoCardOptionsDropdown';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import {DeleteActivityTemplateConfirmationModal} from '../../../components/modals/DeleteActivityTemplateConfirmationModal';


type ActivityTemplateItemProps = {
  activityTemplateWithCount: ActivityTemplateWithCount,
  onActivityTemplateUpdateSelected: Function,
  onDelete: Function,
  index: number
}

export const ActivityTemplateItem = ({activityTemplateWithCount, onActivityTemplateUpdateSelected, onDelete, index}: ActivityTemplateItemProps) => {
  const {spinnerVisibility} = useSpinnerToggle();
  const {userSession} = useContext(UserSessionContext);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);


  const toggleConfirmActivityTemplateDelete = () => setOpenDeleteConfirmationModal(!openDeleteConfirmationModal);

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="activity-type-item">
        <div className="activity-type-item-name">
          <span>{activityTemplateWithCount.activityTemplate.name}</span>
        </div>
        <div className="activity-type-item-created">
          <span>{activityTemplateWithCount.created}</span>
        </div>
        <div className="activity-type-item-complete">
          <span>{activityTemplateWithCount.completed}</span>
        </div>
        <div>
          <OrdoCardOptionsDropdown options={[{
            name: 'edit',
            action: () => {
              onActivityTemplateUpdateSelected(index, activityTemplateWithCount.activityTemplate);
            }
          }, {
            name: 'delete',
            action: () => {toggleConfirmActivityTemplateDelete();}
          }]} size="1x"/>
        </div>
        {openDeleteConfirmationModal && <DeleteActivityTemplateConfirmationModal
          isOpen={openDeleteConfirmationModal}
          activityTemplate={activityTemplateWithCount.activityTemplate}
          onClose={toggleConfirmActivityTemplateDelete}
          onSubmit={(activityTemplateId: string) => {
            onDelete(activityTemplateId);
            toggleConfirmActivityTemplateDelete();
          }}
          organizationId={userSession.currentOrganization!.id}
        />}
      </div>
    </OrdoSpinner>
  );

};
