export type RoleName = 'Admin' | 'Sales Rep' | 'Unknown';

export enum Role {
  Unknown = 0,
  Admin = 1,
  SalesRep = 2
};

export const isSalesRep = (aRole: Role): boolean => {
  return aRole === 2;
};

export const roleFor = (name: RoleName): Role => {
  if(name === 'Admin') {
    return 1;
  }
  if(name === 'Sales Rep') {
    return 2;
  }
  return 0;
};

export function roleOption(name: RoleName) {
  return {name: name, value: roleFor(name)};
}


export const Roles = [
  roleOption('Unknown'),
  roleOption('Admin'),
  roleOption('Sales Rep'),
];
