import React, {useContext, useEffect} from 'react';
import '../../../../scss/ordo/mobileOrderEntry/checkout/mobile-checkout.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import { MobileOrderCheckoutLocationAndDate } from './MobileOrderCheckoutLocationAndDate';
import {AccountLocation} from '../../../../models/Account';
import OrdoButton from '../../../components/common/OrdoButton';
import {MobileOrderCheckoutContactAndDistributor} from './MobileOrderEntryCheckoutContactAndDistributor';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {MobileOrderEntryCheckoutItems} from './MobileOrderEntryCheckoutItems';

type MobileOrderEntryCheckoutProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateAccountLocation: (accountLocation: AccountLocation) => void,
  goToSummary: () => void,
  goToGenerateOrder: () => void,
  updateViewModel: (orderEntryViewModel: OrderEntryViewModel) => void,
  landscapeMode?: boolean;
}

export const MobileOrderEntryCheckout = ({orderEntryViewModel, updateAccountLocation, goToGenerateOrder, updateViewModel, goToSummary, landscapeMode = false} : MobileOrderEntryCheckoutProps) => {
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const fetchData = () => {
    showSpinner();
    orderEntryViewModel
      .initializeCheckoutPage(organization.id)
      .then(updateViewModel)
      .finally(hideSpinner);
  };

  useEffect(fetchData, [organization, orderEntryViewModel.selectedAccountLocation]);

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="mobile-checkout-container">
        <div className="mobile-checkout-title">
          <div className="mobile-checkout-title__icon">
            <OrdoButton
              disabled={false}
              style={{fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'left', padding: 0}}
              text="back"
              firstChild={<FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '0.75rem'}}/>}
              category="link"
              onClick={goToGenerateOrder}
              dataTestId="go-back"
            />
          </div>
          <div className={`mobile-checkout-title__text ${landscapeMode ? 'landscape' : ''}`}>
            <span>Discounts & Pricing</span>
          </div>
        </div>
        <div className={`mobile-order-entry-header ${landscapeMode ? 'landscape' : ''}`}>
          <MobileOrderCheckoutLocationAndDate
            orderEntryViewModel={orderEntryViewModel}
            updateAccountLocation={updateAccountLocation}
            updateViewModel={updateViewModel}
          />
          <MobileOrderCheckoutContactAndDistributor
            orderEntryViewModel={orderEntryViewModel}
            updateViewModel={updateViewModel}
          />
        </div>
        <MobileOrderEntryCheckoutItems orderEntryViewModel={orderEntryViewModel}
          updateViewModel={updateViewModel}
          landscapeMode={landscapeMode}
        />
        <div className={`redirect-to-next-view ${landscapeMode ? 'landscape' : ''}`}>
          <OrdoButton
            category="primary"
            dataTestId="review-totals-button"
            style={{padding: '0.25em 1em', maxWidth: '25em'}}
            text={`${orderEntryViewModel.grandTotal()} review totals`}
            onClick={goToSummary}
            disabled={!orderEntryViewModel.canGoToMobileSummary()}
            disabledText={orderEntryViewModel.goToMobileSummaryInvalidText()}
          />
        </div>
      </div>
    </OrdoSpinner>
  );
};
