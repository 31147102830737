import FormError, { FormFields } from './form_error';
import InvalidField from './invalid_field';

export default class FormErrorHandler {
  public static handleError(field: FormFields, message: string) {
    const formError = FormError.withoutError();
    formError.addError(new InvalidField(field, message));
    return formError;
  }
}
