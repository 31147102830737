import { isEmpty } from 'lodash';
import React, {useContext, useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlay, faStop} from '@fortawesome/free-solid-svg-icons';
import Account from '../../../models/Account';
import '../../../scss/ordo/accountsPage/accountPage/account-page.scss';
import {AccountPriorityButton} from '../../sales-activity/AccountPriorityButton';
import {AccountStatus} from '../../sales-activity/salesActivityTypes';
import {useAPI} from '../../../context/OrdoApiContext';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {AdminChecker} from '../../../routes/permissions';

export const AccountHeader = ({account, onAccountUpdate}: {account : Account, onAccountUpdate: Function}) => {
  const [accountName, setAccountName] = useState(account.name);
  const api = useAPI();
  const {errorToast, successToast}= useOrdoToasts();
  const userSession = useContext(UserSessionContext);
  const adminChecker = new AdminChecker();
  const isAdmin = adminChecker.allow(userSession.userSession);

  const accountStatus = () => {
    if (isEmpty(account.contacts)) return 'Non-qualified Lead';
    switch (account.status) {
    case AccountStatus.LOST:
      return 'Lost';
    case AccountStatus.VERIFIED:
      return 'Verified purchaser';
    case AccountStatus.UNKNOWN:
      return !isEmpty(account.contacts) ? 'Prospect' : 'Non-qualified Lead';
    default:
      return 'Non-qualified Lead';
    }
  };

  useEffect(() => setAccountName(account.name), [account]);
  const updateAccountName = () => {
    if(account.name !== accountName && accountName !== '') {
      api.updateAccount(account.organizationId, account.id, {name: accountName, status: account.status})
        .then((updatedAccount: Account) => {
          successToast('Account name updated');
          onAccountUpdate(updatedAccount);
        })
        .catch(() => errorToast('Could not update Account name'));
    } else {
      setAccountName(account.name);
    }
  };

  const toggleOrderEntryEnabled = () => {
    const newValue = !account.orderEntryEnabled;
    api.updateAccount(account.organizationId, account.id, {orderEntryEnabled: newValue})
      .then((updatedAccount: Account) => {
        successToast(updatedAccount.orderEntryEnabled ? `Order entry resumed for ${account.name}`: `${account.name} set on hold for order entry`);
        onAccountUpdate(updatedAccount);
      })
      .catch(() => errorToast('Could not update Account name'));
  };

  return(
    <div className="account-header card card-fluid inventory-list-card ordo-shadow ordo-card-border">
      <div className="account-status-and-name">
        <span className="section-title">{accountStatus()}</span>
        <input className="account-name" value={accountName} key={account.id}
          onChange={(event) => setAccountName(event.target.value)}
          onBlur={() => updateAccountName()}/>
      </div>
      {isAdmin && <div className="toggle-order-entry" role="presentation" onClick={toggleOrderEntryEnabled}>
        <FontAwesomeIcon icon={account.orderEntryEnabled ? faStop : faPlay}/>
        <span>{account.orderEntryEnabled ? 'disable ordering' : 'resume ordering'}</span>
      </div>}
      <div className="owners-and-priority">
        <div className="priority">
          <AccountPriorityButton
            account={account}
            organizationId={account.organizationId}
            onPriorityChange={() => {}}/>
        </div>
      </div>
    </div>
  );
};
