import React, {useState} from 'react';
import '../../../scss/ordo/sensitive-value-text.scss';
import {
  faCopy,
  faEye, faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useOrdoToasts from '../../../hooks/useOrdoToasts';

type SensitiveValueFieldProps = {
    value: string
};

const SensitiveValueText = ({value}: SensitiveValueFieldProps) => {
  const [showSecretValue, setShowSecretValue] = useState(false);
  const {successToast} = useOrdoToasts();

  const toggleSecretValue = () => setShowSecretValue(!showSecretValue);

  const showSensitiveValueIcon = () => {
    if (showSecretValue) {
      return faEyeSlash;
    }
    return faEye;
  };

  const copySecretValue = () => {
    navigator.clipboard.writeText(value);
    successToast('Value copied!');
  };

  const showValue = (aValue: string) => {
    if (showSecretValue) {
      return aValue;
    }
    const firstLetters = aValue.substring(0, aValue.length-4);
    return aValue.replace(firstLetters, '*'.repeat(firstLetters.length));
  };

  return(<div>
    <span className="secret-value-text">{showValue(value)}</span>
    <FontAwesomeIcon onClick={toggleSecretValue} className="toggle-sensitive-value-icon" size="lg" icon={showSensitiveValueIcon()} />
    <FontAwesomeIcon onClick={copySecretValue} className="copy-sensitive-value-icon" size="lg" icon={faCopy} />
  </div>);
};

export default SensitiveValueText;