import React, {FC, useContext, useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {ProfileData} from '../../models/ProfileData';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import OrganizationsPageSection from './OrganizationsPageSection';
import {UserSessionContext} from '../../context/UserSessionContext';
import {Organization} from '../../models/Organization';
import {OrdoPageTabsProps} from '../components/common/OrdoPageTabs';
import UserInformationTab from './UserInformationTab';
import OrdoSpinner from '../components/OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import {OrderEntryCartContext} from '../../context/OrderEntryCartContext';
import {UserSessionRefresher} from '../../context/UserSessionRefresher';
import {useAPI} from '../../context/OrdoApiContext';
import '../../scss/ordo/user-information-tab.scss';
import {LaunchDarklyFeatureFlagFetcher} from '../../lib/featureFlags/launchDarkly';
import {toFeatureFlagUser} from '../../lib/featureFlags';

enum ProfileTabState {
  Personal,
  Organizations
}

type ProfilePageProps = {
  refresher: UserSessionRefresher,
} & RouteComponentProps;

const ProfilePage: FC<ProfilePageProps>  = ({refresher}: ProfilePageProps) => {
  const [profileData, setProfileData] = useState<ProfileData>({organizations: [], pendingInvitations: []});
  const [tabState, setTabState] = useState<ProfileTabState>(ProfileTabState.Personal);
  const api = useAPI();
  const {update} = useContext(UserSessionContext);
  const {clearCart} = useContext(OrderEntryCartContext);

  const updateCurrentOrganization = (organization: Organization) => {
    api.changeCurrentOrganization(organization).then((userData) => {
      update(userData);
      clearCart();
      LaunchDarklyFeatureFlagFetcher.updateSession(toFeatureFlagUser(userData));
    });
  };

  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const updateProfileData = async () => {
    showSpinner();
    const session = await refresher.refresh();
    setProfileData({organizations: session.organizations, pendingInvitations: session.pendingInvitations});
    hideSpinner();
  };

  const updateOrganizations = async () => {
    const session = await refresher.refresh();
    setProfileData({organizations: session.organizations, pendingInvitations: session.pendingInvitations});
  };

  useEffect(() => {
    updateProfileData();
  }, []);

  const renderContent = (tab: ProfileTabState) => {
    switch (tab) {
    case ProfileTabState.Organizations:
      return (
        <OrganizationsPageSection
          organizations={profileData.organizations}
          pendingInvitations={profileData.pendingInvitations}
          onOrganizationSelected={updateCurrentOrganization}
          onOrganizationCreated={updateOrganizations}
          onEditOrganizationCardInfo={updateOrganizations}
        />
      );

    default:
      return (
        <UserInformationTab api={api} refresher={refresher}/>
      );
    }
  };

  const tabProps: OrdoPageTabsProps = {
    buttons: [
      {
        text: 'personal',
        onClick: () => setTabState(ProfileTabState.Personal),
        dataTestId: 'profile-page-tab-button-personal'
      },
      {
        text: 'organizations',
        onClick: () => setTabState(ProfileTabState.Organizations),
        dataTestId: 'profile-page-tab-button-organization'
      }
    ]
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='profile-page'>
        <OrdoPageTitle title='your profile' buttons={tabProps.buttons}/>
        <div className='page-content-body'>
          { renderContent(tabState) }
        </div>
      </div>
    </OrdoSpinner>
  );
};

export default withRouter(ProfilePage);
