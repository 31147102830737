import { API } from '../lib/api/ordoApi';
import {Subscription} from '../models/Subscription';

export default class EditSubscriptionViewModel {
  private readonly api: API;
  public subscription: Subscription;
  public organizationId: string;

  public static initialize(api: API, editingSubscription: Subscription, orgId: string) {
    return new EditSubscriptionViewModel(api, editingSubscription, orgId);
  }

  constructor(api: API, subscription: Subscription,orgId: string) {
    this.api = api;
    this.subscription = subscription;
    this.organizationId = orgId;
  }

  public async updateSubscription() {
    await this.api.updateSubscription(this.organizationId, this.subscription.id, this.subscription);
  }

  public updatePlan(subscription: Partial<Subscription>) {
    const updatedSubscription = {...this.subscription, ...subscription};
    return new EditSubscriptionViewModel(this.api, updatedSubscription, this.organizationId);
  }
}
