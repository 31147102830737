import {Activity, ExternalEmailActivity} from './sales-activity/Activity';
import {Note} from './Note';

import OrderWithCurrentVersion from './order-entry/OrderWithCurrentVersion';
import {AccountRepAssignment} from './AccountRepAssignment';

export enum AccountActivityType {
  ACTIVITY,
  NOTE,
  ASSIGNMENT,
  ORDER
}

export class AccountActivityEntity {
  public readonly accountActivity: (Activity | ExternalEmailActivity | Note | AccountRepAssignment | OrderWithCurrentVersion);
  public readonly accountActivityType: AccountActivityType;
  public readonly sortingDate: Date;

  constructor(accountActivity: (Activity | Note | AccountRepAssignment | OrderWithCurrentVersion), accountActivityType: AccountActivityType, sortingDate: Date) {
    this.accountActivity = accountActivity;
    this.accountActivityType = accountActivityType;
    this.sortingDate = sortingDate;
  }
}
