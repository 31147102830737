import React, {CSSProperties} from 'react';
import '../../../scss/ordo/buttons.scss';
import { OrdoButtonCategory } from './OrdoButton';

export type OrdoButtonProps = {
  disabled: boolean,
  text: string,
  category: OrdoButtonCategory,
  onClick: (event: any) => void,
  dataTestId: string,
  firstChild?: React.ReactNode,
  secondChild?: React.ReactNode,
  formName?: string,
  squared?: boolean,
  forceLowerCase?: boolean,
  style?: CSSProperties
  disabledText?: string,
}

const OrdoLittleButton = ({disabled, text, category, onClick, dataTestId, firstChild, secondChild, formName, squared,
  forceLowerCase = true, style= {}, disabledText}: OrdoButtonProps) => {
  const categoryClassName = () => {
    switch (category) {
    case 'primary':
      return 'btn-primary ordo-primary-button';
    case 'cancel':
      return 'btn-light ordo-cancel-button';
    case 'link':
      return 'btn-link ordo-transparent-button';
    case 'secondary':
      return 'btn-primary ordo-secondary-button';
    default:
      return `btn-${category}`;
    }
  };
  const className = `${categoryClassName()} min-width`;
  const invalidMessage = disabled ? disabledText : '';
  return <button data-testid={dataTestId}
    title={invalidMessage}
    type="submit"
    className={`btn ordo-little-button ${squared ? '' : 'rounded-pill'} ${className}`}
    style={style}
    disabled={disabled}
    onClick={(event) => {
      event.stopPropagation();
      onClick(event);
    }}
    form={formName}>
    {firstChild}
    <span className="btn-inner--text little-button-text">{forceLowerCase ? text.toLowerCase() : text}</span>
    {secondChild}
  </button>;
};

export default OrdoLittleButton;
