import {PipelineButtonText} from '../../../pages/sales-activity/salesActivityFunctions';
import {SalesActivityPipeline, SalesActivityTable} from './SalesActivityTable';
import {SalesActivityPipelineBuilder} from './SalesActivityPipelineBuilder';

export class LiteSalesActivityTable extends SalesActivityTable {

  public buildPipeline(): SalesActivityPipeline {
    return new SalesActivityPipelineBuilder(this)
      .withNonQualifiedLead()
      .withProspectBuyers()
      .withVerifiedPurchasers()
      .withStalePurchasers()
      .withCollectPayment()
      .withLostPurchasers()
      .build();
  }

  public buyerAction() {
    return this.trackOrder;
  }

  public buyerActionText(): PipelineButtonText {
    return PipelineButtonText.TRACK_ORDER;
  }

}