import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import '../../../scss/ordo/notes/activity-form.scss';
import OrdoTextArea from '../common/OrdoTextArea';
import ActivityViewModel from '../../../application-models/sales-activity/ActivityViewModel';
import OrdoDropdown from '../common/OrdoDropdown';
import OrdoDatepicker, {OrdoDatepickerDisplayMode, SelectableDates} from '../common/OrdoDatepicker';
import OrdoButton from '../common/OrdoButton';
import Contact from '../../../models/order-entry/Contact';
import OrdoMultiSelect, {
  MultiSelectableOption,
  toInformativeMultiSelectableOption,
  toMultiSelectableOption
} from '../common/OrdoMultiSelect';
import {CARD_BORDER_COLOR, GRAY_400} from '../../../constants';
import {ActivityTemplate} from '../../../models/sales-activity/Activity';
import {OrdoCheckbox} from '../OrdoCheckbox';
import OrdoSearchableDropdown from '../common/searchable-dropdown/OrdoSearchableDropdown';
import {OrdoTimePicker} from '../common/OrdoTimePicker';
import {InformativeMenuOption} from '../common/searchable-dropdown/InformativeOption';
import {UserSessionContext} from '../../../context/UserSessionContext';
import AddContactModal from '../modals/AddContactModal';
import {MultiSelectorWithTags} from '../common/MultiSelectorWithTags';
import {ActivityCompleteModal} from '../modals/ActivityCompleteModal';
import ConfirmationModal from '../common/ConfirmationModal';
import { ActivityCompletionResponse } from '../../../models/activity/responses/ActivityCompletionResponse';
import {AccountWithContactsAndSalesReps} from '../../../models/AccountWithContactsAndSalesReps';
import {User} from '../../../models/User';

const MAX_DESCRIPTION_LENGTH = 2500;

type ActivityFormProps = {
  viewModel: ActivityViewModel,
  updateViewModel: (vm: ActivityViewModel) => void,
  onSubmit: Function,
  onCancel: () => void,
  accountContacts: Contact[],
  activityId?: string,
  selectedAccountId?: string,
  canEditAccount?: boolean,
  canEditSalesRep?: boolean,
  accountsWithContacts: AccountWithContactsAndSalesReps[]
};

export const ActivityForm = ({viewModel, updateViewModel, onSubmit, activityId, onCancel, accountContacts, accountsWithContacts, selectedAccountId, canEditAccount, canEditSalesRep}: ActivityFormProps) => {
  const userSession = useContext(UserSessionContext);
  const organizationId = userSession.currentOrganization()!.id;
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);
  const [openCompleteActivityWizard, setOpenCompleteActivityWizard] = useState(false);
  const [openMarkActivityAsPending, setOpenMarkActivityAsPending] = useState(false);
  const [initialCompletedValue] = useState(viewModel.completed);

  const closeCompleteActivityModal = () => setOpenCompleteActivityWizard(false);
  const closeMarkActivityAsPendingModal = () => setOpenMarkActivityAsPending(false);
  const updateEventTemplate = (newEventTemplate: ActivityTemplate) => updateViewModel(viewModel.update({activityTemplate: newEventTemplate}));
  const updateSelectedSalesRep = (salesRep: User) => updateViewModel(viewModel.update({assignedSalesRep: salesRep}));
  const updateDescription = (newDescription: string) => updateViewModel(viewModel.update({description: newDescription}));
  const updateDate = (newDate: Date) => updateViewModel(viewModel.update({date: newDate}));
  const updateTime = (newDate: Date) => {
    updateViewModel(viewModel.update({time: viewModel.timeForSelectedDate(newDate)}));
  };

  const updateCompleted = (completed: boolean, responses: ActivityCompletionResponse[]) => {
    // eslint-disable-next-line no-param-reassign
    viewModel.activity.completed = completed;
    // eslint-disable-next-line no-param-reassign
    viewModel.activity.responses = responses;
    updateViewModel(viewModel.update({completed: completed, responses: responses}));
    onSubmit();
    closeCompleteActivityModal();
    closeMarkActivityAsPendingModal();
  };
  const updateContacts = (selectedContacts: MultiSelectableOption<Contact>[]) => {
    updateViewModel(viewModel.update({contacts: selectedContacts.map(selected => selected.data!)}));
  };

  const openActivityCompletionModal = () => {
    if(initialCompletedValue !== viewModel.completed) {
      if(viewModel.completed) {
        if((viewModel.activity.activityTemplate!.questions.length) > 0) {
          setOpenCompleteActivityWizard(true);
        } else {
          updateCompleted(true, []);
        }
      }
      else setOpenMarkActivityAsPending(true);
    } else {
      onSubmit();
    }
  };

  const updateSelectedAccount = (option: any) => {
    updateContacts([]);
    updateViewModel(viewModel.updateSelectedAccount(option));
  };

  const shouldExpandContacts = viewModel.contacts.length > 2;
  const multiSelectHeight = shouldExpandContacts ? '2.8em' : '2.2em';
  const selectableAccounts = accountsWithContacts.filter(account => !viewModel.selectedAccounts?.includes(account)).map(accWithContacts => {
    return {label: accWithContacts.name, value: accWithContacts.accountId};
  });

  const openAddContact = () => {
    setAddContactModalOpen(true);
  };
  const closeAddContact = () => {
    setAddContactModalOpen(false);
  };
  const contactCreated = ((newContact: Contact) => {
    const selectedAccount = viewModel.singleSelectedAccount();
    selectedAccount?.contacts.push(newContact);
    updateViewModel(viewModel.update({contacts: [...viewModel.contacts, newContact]}));
    closeAddContact();
    return Promise.resolve();
  });

  useEffect(() => {}, [viewModel]);

  return <div className="activity-form" role="presentation" onClick={(event) => event.stopPropagation()}>
    {canEditAccount && (viewModel.selectedAccounts === undefined ? <OrdoSearchableDropdown
      options={selectableAccounts}
      selectedOption={viewModel.selectedAccount()}
      placeholder='select an account'
      onChangeSelectedOption={(option) => updateSelectedAccount(option)}
      canEditValue={canEditAccount}
      preSelectedValue={selectedAccountId ? viewModel.selectedAccount() : false}
      singleRowHeight
      addedStyles={
        {
          control: {
            height: '2em'
          },
          valueContainer: {
            height: '2em'
          },
          indicatorsContainer: {
            height: '2em'
          },
          input: {
            marginTop: '-0.25em'
          }
        }
      }
    /> :
      <MultiSelectorWithTags<string>
        placeholder="search accounts by name" displayAmount={5}
        options={selectableAccounts}
        selectedOptions={viewModel.selectedAccounts.map(accWithContacts => ({label: accWithContacts.name, value: accWithContacts.accountId}))}
        onOptionSelected={(accountId)=>updateViewModel(viewModel.addAccount(accountId))}
        onOptionRemoved={(accountId)=>updateViewModel(viewModel.removeAccount(accountId))}
      />)
    }
    <div className="activity-form__header">
      <div className='select-type'>
        <OrdoDropdown
          height="short"
          selectedOption={viewModel.selectedSalesRep}
          placeholder='assign to rep'
          onSelect={updateSelectedSalesRep}
          options={viewModel.salesRepsForAccount()}
          displayName={(salesRep)=> `${salesRep.firstName} ${salesRep.lastName}`}
          forceLowerCase={false}
          disabled={!canEditSalesRep || viewModel.disabledSalesRepSelection()}
        />
      </div>
      <div className='select-type'>
        <OrdoDropdown
          height="short"
          selectedOption={viewModel.eventType}
          placeholder='activity type'
          onSelect={updateEventTemplate}
          options={viewModel.getActivityTemplateOptions()}
          displayName={(activityTemplate)=>activityTemplate.name}
          forceLowerCase={false}
        />
      </div>
      <OrdoMultiSelect
        options={accountContacts.filter(contact => !viewModel.contacts.includes(contact)).map(contact => toInformativeMultiSelectableOption(contact.name, '', contact.email, contact))}
        onChangeSelectedOption={updateContacts}
        selectedOptions={viewModel.contacts.map(c => toMultiSelectableOption(c.name, c.name, c))}
        placeholder='contacts'
        disabled={viewModel.selectedAccounts && (viewModel.selectedAccounts.length > 1)}
        onAddItem={viewModel.selectedAccountId() ? openAddContact : ()=>{}}
        addItemText={viewModel.selectedAccountId() ? 'add a new contact' : 'select an account to add a contact'}
        addItemDisabled={!viewModel.selectedAccountId()}
        menuOptionCustomComponent={InformativeMenuOption}
        addedStyles={{
          container: {
            minWidth: '30%',
            width: 'auto',
            marginRight: '0',
          },
          control: {
            minHeight: '2em',
            height: multiSelectHeight,
            borderColor: GRAY_400,
            boxShadow: `0 0 0.4em 0.01em ${CARD_BORDER_COLOR}`,
            '&:hover': {},
            paddingLeft: viewModel.contacts.length > 0 ? '0.25rem' : '0.5rem',
            maxHeight: '2.2em',
            marginBottom: '0',
          },
          valueContainer: {
            minHeight: '2em',
            height: multiSelectHeight,
            padding: '2px 2px 0',
            overflowY: shouldExpandContacts ? 'scroll' : 'hidden',
          },
          multiValue: {
            width: 'min-content',
            maxWidth: '5.5em',
            alignItems: 'center',
            height: '1.5em',
            marginTop: '0',
            marginBottom: '4px',
          },
          multiValueLabel: {
            paddingRight: '0'
          },
          multiValueRemove: {
            padding: '0'
          },
          indicatorsContainer: {
            minHeight: '2em',
            height: '2em',
            padding: '2px 2px 2px 0'
          },
          clearIndicator: {
            padding: '2px'
          },
          dropdownIndicator: {
            padding: '2px'
          },
          indicatorSeparator: {
            margin: '4px 0'
          }
        }}
      />
    </div>
    <div className="activity-form__date-and-type">
      <div className="date-and-time">
        <OrdoDatepicker
          placeholder="select a date"
          onChange={updateDate}
          displayTime={false}
          onlyTime={false}
          value={viewModel.date || undefined}
          displayMode={OrdoDatepickerDisplayMode.SINGLE_LINE}
          validDates={SelectableDates.ALL_TIME}
          timeConstraints={{
            minutes: {
              min: 0,
              max: 59,
              step: 15
            }
          }}
        />
        <OrdoTimePicker onChangeDate={updateTime} activityTime={viewModel.getTime()}/>
      </div>
    </div>
    <div className="activity-form__description">
      <OrdoTextArea
        placeholder='description'
        style={{width: '100%'}}
        value={viewModel.description || ''}
        maxValue={MAX_DESCRIPTION_LENGTH}
        handleChange={(event: ChangeEvent<HTMLInputElement>) => updateDescription(event.target.value)}/>
    </div>
    <div className="account-details__buttons">
      <OrdoButton disabled={!viewModel.canSubmit()} text="save" category="primary"
        onClick={() => openActivityCompletionModal()}
        dataTestId="activity-submit-button"/>
      <OrdoButton disabled={false} text="cancel" category="cancel" onClick={onCancel}
        dataTestId="activity-cancel-button"/>
    </div>
    <div className="activity-form__completed">
      <OrdoCheckbox notToggleOnClick id={activityId} checked={viewModel.completed} onChange={()=>updateViewModel(viewModel.update({completed: !viewModel.completed}))}/>
      <span className="text">mark as complete</span>
    </div>
    {addContactModalOpen && <AddContactModal isOpen={addContactModalOpen} onClose={closeAddContact} onSubmit={contactCreated} orgId={organizationId} accountId={viewModel.selectedAccountId()}/>}
    {openCompleteActivityWizard && <ActivityCompleteModal
      activity={viewModel.toActivity(organizationId, activityId || '')}
      isOpen={openCompleteActivityWizard}
      onClose={closeCompleteActivityModal}
      onSubmit={(responses)=>updateCompleted(true, responses)}
    />}
    {openMarkActivityAsPending && <ConfirmationModal
      show={openMarkActivityAsPending}
      onClose={closeMarkActivityAsPendingModal}
      onSubmit={()=>updateCompleted(false, [])}
      confirmationText="marking the activity as incomplete will delete any responses, are you sure you want to continue?"
      actionText='mark incomplete'
    />}
  </div>;
};
