import React from 'react';
import {ButtonGroup, ToggleButton} from 'react-bootstrap';
import '../../../scss/ordo/selector.scss';

type SelectableOption = { name: string, value: number } | string;
export type OrdoSelectorProps = {
  selected?: number | string,
  options: SelectableOption[],
  onChange: Function,
  size?: 'sm' | 'lg',
  vertical?: boolean,
  invalidMessage?: string,
  isValid?: boolean,
  width?: '100' | '75' | '50' | '25'
}

const OrdoSelector = ({selected, onChange, options, size, vertical=false, width='100', invalidMessage, isValid=true}: OrdoSelectorProps) => {
  const changeSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.currentTarget.value;
    const value = typeof options[0] === 'string' ? selectedValue : parseInt(selectedValue,10);
    onChange(value);
  };


  return (
    <>
      <ButtonGroup toggle vertical={vertical} size={size} className={`w-${width} ${  isValid  ? '' : 'is-invalid'}`}>
        {options.map((option ) => {
          const value = typeof option === 'string' ? option : option.value;
          const name = typeof option === 'string' ? option : option.name;
          return <ToggleButton
            type='radio'
            key={name}
            value={value}
            className={selected === value ? '' : 'unselected'}
            variant={selected === value ? 'primary' : 'outline-dark'}
            checked={selected === value}
            onChange={changeSelected}
          >
            <div className='d-flex h-100 align-items-center justify-content-center'>{name}</div>
          </ToggleButton>;
        })}
      </ButtonGroup>
      {(isValid !== undefined && !isValid) && <div className="invalid-feedback">
        {invalidMessage}
      </div>}
    </>
  );
};

export default OrdoSelector;