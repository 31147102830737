import React, {CSSProperties} from 'react';
import '../../../scss/ordo/common.scss';

type OrdoTextAreaProps = {
  style: CSSProperties,
  value: string,
  handleChange: Function,
  placeholder?: string,
  maxValue?: number,
  disabled?: boolean
}

const OrdoTextArea = ({style = {}, value, handleChange, placeholder, maxValue, disabled}: OrdoTextAreaProps) => {
  return <textarea className="ordo-text-area"
    disabled={disabled}
    style={style}
    value={value}
    placeholder={placeholder}
    onChange={(event) => handleChange(event)}
    maxLength={maxValue}
  />;
};

export default OrdoTextArea;
