import React, {FC} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import LoginViewModel from '../../application-models/loginViewModel';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {FormFields, UserFormFields} from '../../errors/form_error';
import OrdoSpinner from '../components/OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoInput from '../components/common/inputs/OrdoInput';
import OrdoButton from '../components/common/OrdoButton';
import '../../scss/ordo/loginPage/login-page.scss';

type LoginProps = {
  showCreateAccount: boolean,
  title: string,
  callToActionText: string,
  callToAction: () => Promise<any>,
  loginViewModel: LoginViewModel,
  setLoginViewModel: Function,
} & RouteComponentProps

const Login: FC<LoginProps>  = ({showCreateAccount, title, callToActionText, callToAction, loginViewModel, setLoginViewModel}: LoginProps) => {
  const history = useHistory();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const login = () => {
    showSpinner();
    callToAction().finally(hideSpinner);
  };

  const register = () => {
    history.push(ROUTE_NAMES.REGISTER);
  };

  const recoverPassword = () => history.push(ROUTE_NAMES.PASSWORD_RESET);

  const errorMessageForInput = (input: UserFormFields) => loginViewModel.hasErrorFor(input) ? loginViewModel.errorMessage(input) : '';

  const checkEmptyFieldError = (field: FormFields) => {
    if (loginViewModel.hasErrorFor(field)) {
      const newViewModel = loginViewModel.removeErrorFor(field);
      setLoginViewModel(newViewModel);
    }
  };

  const GeneralErrorMessage = () => {
    if (loginViewModel.hasErrorFor('unknown')) {
      return <div className="alert alert-danger" role="alert">
        {loginViewModel.errorMessage('unknown')  || 'Ooops! Something bad happen, please try again later.'}
      </div>;
    }
    return <></>;
  };

  const updateEmail = (email: string) => {
    checkEmptyFieldError('emailOrPassword');
    setLoginViewModel(loginViewModel.updateViewModel({ email: email }));
  };

  const updatePassword = (password: string) => {
    checkEmptyFieldError('emailOrPassword');
    setLoginViewModel(loginViewModel.updateViewModel({ password: password }));
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="row justify-content-center" style={{margin: 0}}>
        <div className="col-sm-8 col-lg-4">
          {GeneralErrorMessage()}
          <div className="card shadow zindex-100 mb-0 login-card">
            <div className="card-body px-md-5 py-5">
              <div className="mb-5">
                <h6 className="h3">{title}</h6>
                <p className="text-muted mb-0">Sign in to your account to continue.</p>
              </div>
              <span className="clearfix"/>
              <form>
                <div className="form-group">
                  <OrdoInput inputType="email"
                    label="email"
                    inputName="login-email-input"
                    value={loginViewModel.email}
                    placeholder="type your email"
                    isValid={!errorMessageForInput('emailOrPassword')}
                    onChange={updateEmail}
                    onBlur={() => checkEmptyFieldError('emailOrPassword')}
                    inputSize="large"/>
                </div>
                <div className="form-group mb-4">
                  <div className="input-group">
                    <OrdoInput inputType="password"
                      label="password"
                      inputName="login-password-input"
                      value={loginViewModel.password}
                      placeholder="type your password"
                      inputSize="large"
                      onChange={updatePassword}
                      onBlur={() => checkEmptyFieldError('emailOrPassword')}
                      invalidMessage={errorMessageForInput('emailOrPassword')}
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <a href="#!" onClick={recoverPassword} className="small text-muted text-underline--dashed border-primary">Lost
                          password?
                    </a>
                  </div>
                </div>
                <div className="login-footer">
                  <OrdoButton
                    additionalClassName='registration-button'
                    disabled={false}
                    text={callToActionText}
                    category="primary"
                    onClick={login}
                    dataTestId="login-button"/>
                  {showCreateAccount ? <div><small>Not registered?</small>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" className="small font-weight-bold" onClick={register}> Create account</a>
                  </div> : <></>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </OrdoSpinner>
  );
};

export default withRouter(Login);
