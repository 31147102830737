import React, {useContext, useEffect, useState} from 'react';
import useSpinnerToggle from '../../../../../hooks/useSpinnerToggle';
import {UserSessionContext} from '../../../../../context/UserSessionContext';
import {ActivitiesFilter} from '../../../filter/ActivitiesFilter';
import {ActivitiesStateFilters} from '../ActivitiesStateFilters';
import OrdoSpinner from '../../../../components/OrdoSpinner';
import {ActivitiesMobileList} from './ActivitiesMobileList';
import OrdoButton from '../../../../components/common/OrdoButton';
import OrdoPageTitle from '../../../../components/common/OrdoPageTitle';
import '../../../../../scss/ordo/activitiesPage/mobile-activities-page.scss';
import {useAPI} from '../../../../../context/OrdoApiContext';
import {AccountWithContactsAndSalesReps} from '../../../../../models/AccountWithContactsAndSalesReps';

export const ActivitiesMobilePage = () => {
  const { spinnerVisibility} = useSpinnerToggle();
  const {userSession} = useContext(UserSessionContext);
  const [addActivityModalOpen, setAddActivityModalOpen] = useState(false);
  const [filter, setFilter] = useState(ActivitiesFilter.empty());
  const api = useAPI();
  const [accounts, setAccounts] = useState<AccountWithContactsAndSalesReps[]>([]);

  useEffect(() => {
    api.getAccountsWithContactsAndSalesReps(userSession.currentOrganization!.id)
      .then((accountsResponse: AccountWithContactsAndSalesReps[]) => setAccounts(accountsResponse));
  }, []);


  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="activities-mobile-header">
        <OrdoPageTitle title='activities'
          buttons={[]}/>
        <div className="add-activity-button">
          <OrdoButton disabled={false} text="add activity" category="primary" onClick={() => setAddActivityModalOpen(!addActivityModalOpen)} dataTestId=""/>
        </div>
      </div>
      <div className='activity-page-filters'>
        <ActivitiesStateFilters filter={filter} onUpdateFilter={setFilter}/>
      </div>
      <div>
        <ActivitiesMobileList organizationId={userSession.currentOrganization!.id}
          filter={filter}
          accounts={accounts}
          addActivityModalOpen={addActivityModalOpen}
          closeAddActivityModal={() => setAddActivityModalOpen(!addActivityModalOpen)}/>
      </div>
    </OrdoSpinner>
  );
};
