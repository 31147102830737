import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import React, {useState} from 'react';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import OrdoSpinner from '../../components/OrdoSpinner';
import '../../../scss/ordo/salesActivity/sales-activity-card-drawer-details.scss';
import NoteViewModel from '../../../application-models/sales-activity/NoteViewModel';
import {NoteForm} from '../../components/notes/NoteForm';
import {NoteDetailsRow} from './NoteDetailsRow';
import {useAPI} from '../../../context/OrdoApiContext';
import { Note } from '../../../models/Note';
import OrdoMobileFriendlyButton from '../../components/common/OrdoMobileFriendlyButton';
import MobileDisplayAsModal from '../../components/common/MobileDisplayAsModal';
import Contact from '../../../models/order-entry/Contact';
import {NON_ACTIVE_ACCOUNT_TEXT} from '../../../models/Account';
import { PipelineButtonText } from '../salesActivityFunctions';

type AccountNotesDetailsProps = {
  orgId: string,
  accountId: string,
  notes: Note[],
  accountContacts: Contact[],
  activeAccount: boolean,
}


export const AccountNotesDetails = ({orgId, accountId, notes, accountContacts, activeAccount}: AccountNotesDetailsProps) => {
  const api = useAPI();
  const [displayNotes, setDisplayNotes] = useState(false);
  const [openedAddNoteForm, setOpenedAddNoteForm] = useState(false);
  const [addNoteViewModel, setAddNoteViewModel] = useState(NoteViewModel.empty(api));
  const closeNoteForm = () => setOpenedAddNoteForm(false);
  const openNoteForm = () => setOpenedAddNoteForm(true);
  const {spinnerVisibility, showSpinner, hideSpinner} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const [accountNotes, setAccountNotes] = useState<Note[]>(notes);

  const toggleNotes = () => setDisplayNotes(!displayNotes);

  const createNotes = () => {
    showSpinner();
    addNoteViewModel.createNote(orgId, accountId)
      .then((note) => {
        hideSpinner();
        successToast('note successfully created');
        accountNotes.unshift(note);
        setAccountNotes(accountNotes);
        setOpenedAddNoteForm(false);
        setAddNoteViewModel(NoteViewModel.empty(api));
      })
      .catch(() => {
        errorToast('could not create the note');
        hideSpinner();
      });
  };

  const updateNote = (updatedNote: Note) => {
    const updatedNotes = accountNotes.map((note) => {
      if (note.id === updatedNote.id) {
        return updatedNote;
      }
      return note;
    });
    setAccountNotes(updatedNotes);
  };

  const deleteNote = (toDeleteNote: Note) => {
    showSpinner();
    addNoteViewModel.deleteNote(orgId, accountId, toDeleteNote.id)
      .then(() => {
        setAccountNotes(accountNotes.filter(note => note.id !== toDeleteNote.id));
        successToast('Note successfully deleted');
      })
      .catch(() => errorToast('Could not delete note'))
      .finally(hideSpinner);
  };

  const showBottomSection = () => {
    return openedAddNoteForm || displayNotes;
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <div className={`order-details-section ${showBottomSection() ? 'section-expanded' : 'section-collapsed'}`}>
      <div className="upper-section">
        <div className={`caret-and-title ${displayNotes ? 'opened' : ''}`} onClick={toggleNotes} role="presentation">
          <FontAwesomeIcon className={`caret-icon ${displayNotes ? 'opened' : 'closed'}`} size="lg"
            icon={faAngleRight}/>
          <span
            className="details-title">{`Notes (${accountNotes.length})`} </span>
        </div>
        <OrdoMobileFriendlyButton
          disabled={!activeAccount}
          text={PipelineButtonText.ADD_NOTE}
          category="primary"
          onClick={openNoteForm}
          dataTestId="sales-activity-order-details-add-note-button"
          disabledText={NON_ACTIVE_ACCOUNT_TEXT}
        />
      </div>

      {showBottomSection() &&
      <div className="bottom-section">
        <div className="add-form-container">
          {openedAddNoteForm && <MobileDisplayAsModal title="add a note" onClose={closeNoteForm} show={openedAddNoteForm}>
            <div className="add-form">
              <NoteForm
                viewModel={addNoteViewModel}
                updateViewModel={setAddNoteViewModel}
                onSubmit={createNotes}
                onCancel={closeNoteForm}
                accountContacts={accountContacts}
              />
            </div>
          </MobileDisplayAsModal>
          }
        </div>
        {displayNotes && accountNotes.map(note =>
          <NoteDetailsRow
            key={note.id}
            orgId={orgId}
            accountId={accountId}
            note={note}
            onNoteUpdate={updateNote}
            onNoteDelete={() => deleteNote(note)}
            accountContacts={accountContacts}
          />
        )}
      </div>}
    </div>
  </OrdoSpinner>;
};
