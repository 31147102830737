import React from 'react';
import OrderInformationViewModel from '../../../application-models/order-history/OrderInformationViewModel';

const OrderGeneralInformation = ({orderInformationViewModel, onClick}: { orderInformationViewModel: OrderInformationViewModel, onClick: (event:any) => void}) => {

  return <div className="order-history-order-item"
    onClick={onClick}
    onKeyPress={() => {
    }}
    role="button"
    tabIndex={0}
  >
    <div className="order-item-column wider-column account">
      <span className="account-name text-limit">{orderInformationViewModel.accountName()}</span>
      <span className="order-number">Order #{orderInformationViewModel.orderNumber()}</span>
    </div>
    <div className="order-item-column wider-column">
      <span>{orderInformationViewModel.assignedSalesRepName()}</span>
    </div>
    <div className="order-item-column wider-column contact-name">
      <span>{orderInformationViewModel.contactName()}</span>
    </div>
    <div className={`order-item-column narrower-column order-status ${orderInformationViewModel.orderStatusToString()}`}>
      <span>{orderInformationViewModel.orderStatusToString()}</span>
    </div>
    <div className="order-item-column narrower-column">
      <span>{orderInformationViewModel.orderFormattedDeliveryDate()}</span>
    </div>
    <div className="order-item-column narrower-column order-sales-value">
      <span>{orderInformationViewModel.orderFormattedSalesValue()}</span>
    </div>
  </div>;
};

export default OrderGeneralInformation;
