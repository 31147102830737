import {AccountActivityEntity, AccountActivityType} from '../../../../../models/AccountActivity';
import {Activity} from '../../../../../models/sales-activity/Activity';

export class AccountActivityFilter {
  public all: boolean
  public incomplete: boolean
  public complete: boolean
  public notes: boolean
  public orders: boolean

  constructor(all: boolean, incomplete: boolean, complete: boolean, notes: boolean, orders: boolean) {
    this.all = all;
    this.incomplete = incomplete;
    this.complete = complete;
    this.notes = notes;
    this.orders = orders;
  }

  public hasAllFilter() : boolean{
    return this.all;
  }

  public hasCompleteFilter() : boolean {
    return this.complete;
  }

  public hasIncompleteFilter() : boolean {
    return this.incomplete;
  }

  public hasNotesFilter() : boolean {
    return this.notes;
  }

  public hasOrdersFilter() : boolean{
    return this.orders;
  }

  public toggleAllFilter() : AccountActivityFilter{
    return new AccountActivityFilter(!this.all, false, false, false, false);
  };

  public toggleIncompleteFilter() : AccountActivityFilter{
    return new AccountActivityFilter(false, !this.incomplete, false, false, false);
  };

  public toggleCompleteFilter() : AccountActivityFilter{
    return new AccountActivityFilter(false, false, !this.complete, false, false);
  };

  public toggleNotesFilter() : AccountActivityFilter{
    return new AccountActivityFilter(false, false, false, !this.notes, false);
  };

  public toggleOrdersFilter() : AccountActivityFilter{
    return new AccountActivityFilter(false, false, false, false, !this.orders);
  };

  public filterAccountActivity(accountActivity: AccountActivityEntity[]) {
    return accountActivity.filter((accountActivityEntity) => {
      return this.all ||
        (this.applyIncompleteFilter(accountActivityEntity) &&
        this.applyCompleteFilter(accountActivityEntity) &&
        this.applyNotesFilter(accountActivityEntity) &&
        this.applyOrderFilter(accountActivityEntity));
    });
  };

  static default() : AccountActivityFilter{
    return new AccountActivityFilter(true, false, false, false, false);
  }

  private applyIncompleteFilter(accountActivityEntity: AccountActivityEntity) : boolean {
    return this.incomplete ? ( accountActivityEntity.accountActivityType === AccountActivityType.ACTIVITY &&
      !(accountActivityEntity.accountActivity as Activity).completed) : true;
  };

  private applyCompleteFilter(accountActivityEntity: AccountActivityEntity): boolean {
    return this.complete ? (accountActivityEntity.accountActivityType === AccountActivityType.ACTIVITY &&
      (accountActivityEntity.accountActivity as Activity).completed) : true;
  }

  private applyNotesFilter(accountActivityEntity: AccountActivityEntity) : boolean {
    return this.notes ? (accountActivityEntity.accountActivityType === AccountActivityType.NOTE) : true;
  }

  private applyOrderFilter(accountActivityEntity: AccountActivityEntity) : boolean {
    return this.orders ? (accountActivityEntity.accountActivityType === AccountActivityType.ORDER) : true;
  }
}
