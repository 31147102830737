import React, {FC, useContext, useState} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import LoginViewModel from '../../application-models/loginViewModel';
import {API} from '../../lib/api/ordoApi';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {UserSessionContext} from '../../context/UserSessionContext';
import Login from './Login';

const LoginPage: FC<{ api: API } & RouteComponentProps>  = ({api}: { api: API }) => {
  const [loginViewModel, setLoginViewModel] = useState(LoginViewModel.emptyLoginViewModel(api));
  const history = useHistory();
  const userContextData = useContext(UserSessionContext);

  const login = async () => {
    const viewModelWithoutErrors = loginViewModel.cleanErrors();
    setLoginViewModel(viewModelWithoutErrors);

    const newLoginViewModel = await viewModelWithoutErrors.signIn();
    setLoginViewModel(newLoginViewModel);
    if (!newLoginViewModel.hasError()) {
      userContextData.update(newLoginViewModel.userSession);
      history.push(ROUTE_NAMES.PROFILE);
    }
  };

  return (
    <Login showCreateAccount
      title="Login"
      callToActionText="Sign in"
      callToAction={login}
      loginViewModel={loginViewModel}
      setLoginViewModel={setLoginViewModel}/>
  );
};

export default withRouter(LoginPage);
