import React, {CSSProperties, FC} from 'react';
import '../../../scss/ordo/common.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import { OrdoPageTabs, OrdoPageTabsProps } from './OrdoPageTabs';
import OrdoButton from './OrdoButton';

type OrdoPageTitleProps = {
  title: string,
  showBackButton?: boolean,
  onPressBackButton?: (event: any) => void,
  centered?: boolean,
  style?: CSSProperties
  visibleTabs?: boolean,
} & OrdoPageTabsProps;


const OrdoPageTitle: FC<OrdoPageTitleProps> = ({
  title,
  centered,
  buttons,
  style = {},
  showBackButton = false,
  visibleTabs = true,
  onPressBackButton = () => {}}: OrdoPageTitleProps) =>(

  <div className='ordo-title-container'>
    <div className='ordo-title-and-buttons' style={style}>
      <h3 className={`ordo-page-title ${centered ? 'ordo-title-align-center' : ''}`}> {title} </h3>
      {visibleTabs && <OrdoPageTabs buttons={buttons}/>}
    </div>
    {showBackButton && <OrdoButton
      disabled={false}
      style={{fontSize: '1rem', fontWeight: 'bold', textAlign: 'left', padding: 0, marginBottom: '1em'}}
      text="back"
      firstChild={<FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '0.6rem'}}/>}
      category="link"
      onClick={onPressBackButton}
      dataTestId="go-back"/>}
  </div>
);

export default OrdoPageTitle;
