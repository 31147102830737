import React, {useContext} from 'react';
import {debounce} from 'lodash';
import OrdoMultiSelect from '../../components/common/OrdoMultiSelect';
import {ContactsPagePaginationAndFilter} from '../paginationAndFilters/ContactsPagePaginationAndFilter';
import {SelectableFilter} from '../../../application-models/OrderHistoryViewModel';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {AdminChecker} from '../../../routes/permissions';

export const ContactsPageFilters = ({contactsPagePaginationAndFilter, onFilterUpdate} : {contactsPagePaginationAndFilter : ContactsPagePaginationAndFilter, onFilterUpdate: (filter: ContactsPagePaginationAndFilter) => void}) => {
  const userSession = useContext(UserSessionContext);
  const adminChecker = new AdminChecker();
  const isAdmin = adminChecker.allow(userSession.userSession);

  const filterByContactNameOrTitle = debounce(async (contactName: string) => {
    onFilterUpdate(await contactsPagePaginationAndFilter.filterByContactNameOrTitle(contactName));
  }, 100);

  const filterByAccounts = async (selectableAccounts: SelectableFilter[]) => {
    onFilterUpdate(await contactsPagePaginationAndFilter.filterByAccounts(selectableAccounts));
  };

  const filterBySalesReps = async (selectableSalesReps: SelectableFilter[]) => {
    onFilterUpdate(await contactsPagePaginationAndFilter.filterBySalesReps(selectableSalesReps));
  };


  return(
    <div className='contacts-page-filters'>
      <input
        id='search-by-account-name-input'
        data-testid='search-by-location-name-input'
        type='text'
        style={{width: '25%'}}
        className='search-by-account-input'
        placeholder='search by name or title'
        value={contactsPagePaginationAndFilter.searchedContactNameOrTitle}
        onChange={(event) => filterByContactNameOrTitle(event.target.value)}
      />

      {isAdmin && <OrdoMultiSelect
        placeholder="select sales rep" addedStyles={
          {
            container: {width: '30%'},
            control: {marginBottom: '0'},
            multiValue: {width: '45%'}
          }
        }
        options={contactsPagePaginationAndFilter.salesReps()}
        onChangeSelectedOption={filterBySalesReps}
        selectedOptions={contactsPagePaginationAndFilter.selectedSalesReps}
      />}

      <OrdoMultiSelect
        options={contactsPagePaginationAndFilter.accounts()}
        addedStyles={
          {
            container: {width: '30%'},
            control: {marginBottom: '0'},
            multiValue: {width: '45%'}
          }
        }
        onChangeSelectedOption={filterByAccounts}
        selectedOptions={contactsPagePaginationAndFilter.selectedAccounts}
        placeholder='select account'
      />
    </div>
  );
};