import {API} from '../../lib/api/ordoApi';
import Contact from '../../models/order-entry/Contact';
import {ContactRequestInput} from '../../lib/api/request-types';

export default class ContactModalViewModel {
  public constructor(
    public readonly api: API,
    public readonly name: string,
    public readonly email: string,
    public readonly title: string,
    public readonly phone: string,
    public readonly emailNotifications: boolean = true,
  ) {}

  public static empty(api: API) {
    return new ContactModalViewModel(api,'', '', '', '');
  }

  public updateName(newName: string) {
    return new ContactModalViewModel(this.api, newName, this.email, this.title, this.phone);
  }

  public updateTitle(newTitle: string) {
    return new ContactModalViewModel(this.api, this.name, this.email, newTitle, this.phone);
  }

  public updateEmail(newEmail: string) {
    return new ContactModalViewModel(this.api, this.name, newEmail, this.title, this.phone);
  }

  public updatePhone(newPhone: string) {
    return new ContactModalViewModel(this.api, this.name, this.email, this.title, newPhone);
  }

  public toggleEmailNotifications() {
    return new ContactModalViewModel(this.api, this.name, this.email, this.title, this.phone, !this.emailNotifications);
  }

  public async createContact(accountId?: string, orgId?: string, ) {
    if(!accountId) throw Error('accountId missing');
    const contactInput = this.getContactData(accountId!);
    return this.api.createContact(contactInput, orgId);
  }

  public async updateContact(contact: Contact, orgId: string, accountId?: string) {
    const selectedAccount = accountId || contact.accountId;
    if(!selectedAccount) throw Error('accountId missing');
    const contactInput = this.getContactData(selectedAccount);
    return this.api.updateContact(contactInput, contact.id, contact.accountId, orgId);
  }

  public getContactData(accountId: string): ContactRequestInput {
    return {
      name: this.name,
      email: this.email,
      phone: this.phone,
      title: this.title,
      emailNotifications: this.emailNotifications,
      accountId: accountId
    };
  }

  public canSubmit() {
    return !!this.name;
  }
}
