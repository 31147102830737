import React from 'react';
import {faEnvelope, faPhoneAlt} from '@fortawesome/free-solid-svg-icons';
import ContactModalViewModel from '../../../application-models/order-entry/ContactModalViewModel';
import OrdoInput from '../common/inputs/OrdoInput';
import '../../../scss/ordo/contacts/contact-form.scss';
import OrdoInputWithIcon from '../common/inputs/OrdoInputWithIcon';
import OrdoButton from '../common/OrdoButton';
import {useResolution} from '../../../context/UseResolutions';
import {isMobileResolution} from '../../../context/resolutions';

type ContactFormProps = {
  viewModel: ContactModalViewModel,
  updateViewModel: (vm: ContactModalViewModel) => void,
  onCancel: () => void,
  onSubmit: () => void
  canSubmit?: boolean
};

export const ContactForm = ({viewModel,updateViewModel, onCancel, onSubmit, canSubmit = true}: ContactFormProps) => {
  const resolution = useResolution();
  const isMobile = isMobileResolution(resolution);
  const updateContactName = (newName: string) => updateViewModel(viewModel.updateName(newName));
  const updateContactTitle = (newTitle: string) => updateViewModel(viewModel.updateTitle(newTitle));
  const updateContactEmail = (newEmail: string) => updateViewModel(viewModel.updateEmail(newEmail));
  const updateContactPhone = (newPhone: string) => updateViewModel(viewModel.updatePhone(newPhone));

  return <div className="contact-form">
    <div>
      <OrdoInput inputName="name" value={viewModel.name} placeholder="contact name" label={isMobile?'':'name'}
        inputSize="large" onChange={updateContactName}/>
    </div>
    <div>
      <OrdoInput inputName="title" value={viewModel.title} placeholder="title or position"
        label={isMobile?'':'title or position'} inputSize="large" onChange={updateContactTitle}/>
    </div>
    <div className='contact-form__mail-and-phone'>
      <div className='phone'>
        <OrdoInputWithIcon inputName="phone" bottomMargin={false} value={viewModel.phone} placeholder="phone" inputSize="large"
          onChange={updateContactPhone}  icon={faPhoneAlt}/>
      </div>
      <div className='mail'>
        <OrdoInputWithIcon inputName="email" bottomMargin={false} value={viewModel.email} placeholder="email" inputSize="large"
          onChange={updateContactEmail} icon={faEnvelope}/>
      </div>
    </div>
    <div className="account-details__buttons">
      <OrdoButton disabled={!viewModel.canSubmit() || !canSubmit} text="save" category="primary"
        onClick={onSubmit}
        dataTestId="contact-submit-button"/>
      <OrdoButton disabled={false} text="cancel" category="cancel" onClick={onCancel}
        dataTestId="contact-cancel-button"/>
    </div>
  </div>;
};
