import React, {CSSProperties} from 'react';
import '../../../scss/ordo/buttons.scss';

export type OrdoButtonCategory = 'primary' | 'cancel' | 'link' | 'success' | 'warning' | 'danger' | 'light' | 'dark' | 'secondary';

export type OrdoButtonProps = {
  disabled: boolean,
  text: string,
  category: OrdoButtonCategory,
  onClick: (event: any) => void,
  dataTestId: string,
  textStyle?: string,
  firstChild?: React.ReactNode,
  secondChild?: React.ReactNode,
  formName?: string,
  squared?: boolean,
  forceLowerCase?: boolean,
  style?: CSSProperties
  disabledText?: string
  additionalClassName?: string
}

const OrdoButton = ({disabled, text, category, onClick, dataTestId,
  textStyle, firstChild, secondChild, formName, squared,
  forceLowerCase = true, style= {}, disabledText, additionalClassName}: OrdoButtonProps) => {
  const categoryClassName = () => {
    switch (category) {
    case 'primary':
      return 'btn-primary ordo-primary-button';
    case 'cancel':
      return 'btn-light ordo-cancel-button';
    case 'link':
      return 'btn-link ordo-transparent-button';
    case 'secondary':
      return 'btn-secondary ordo-secondary-button';
    default:
      return `btn-${category}`;
    }
  };
  const className = `${categoryClassName()} min-width ${additionalClassName || ''}`;
  const invalidMessage = disabled ? disabledText : '';
  return <button data-testid={dataTestId}
    title={invalidMessage}
    type="submit"
    className={`ordo-button btn btn-sm ordo-button-reduced-padding ${squared ? 'squared' : 'rounded-pill'} ${className}`}
    style={style}
    disabled={disabled}
    onClick={(event) => {
      event.stopPropagation();
      onClick(event);
    }}
    form={formName}>
    <div className='button-content'>
      {firstChild}
      <span className={`btn-inner--text ${textStyle || ''}`}>{forceLowerCase ? text.toLowerCase() : text}</span>
      {secondChild}
    </div>
  </button>;
};

export default OrdoButton;
