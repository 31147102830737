import Product from '../Product';
import { User } from '../User';
import {Allocation} from './allocation';
import Account from '../Account';
import {OrganizationLicense} from '../OrganizationLicense';

export const ALLOCATIONS_OUT_OF_RANGE_ERROR_MESSAGE = 'Cannot allocate more than the total stock for this product.';
export const NEGATIVE_ALLOCATION_QUANTITY_ERROR_MESSAGE = 'Cannot allocate a negative quantity';
export const INVALID_DISTRIBUTOR_ORGANIZATION_LICENSE = 'Please, select a valid distributor';

export class AllocationCollection extends Array<Allocation> {

  public static empty(product: Product) {
    return new AllocationCollection(product, ...[]);
  }

  constructor(private readonly product: Product, ...allocations: Allocation[]) {
    super(...allocations);
    this.product = product;
  }

  public concat(allocations: Allocation[]): AllocationCollection {
    return new AllocationCollection(this.product, ...[...this, ...allocations]);
  }

  public add(allocation: Allocation): AllocationCollection {
    this.unshift(allocation);
    return new AllocationCollection(this.product, ...this);
  }

  public updateAllocation(allocation: Allocation, quantity: number): AllocationCollection {
    const allocationIndex = this.findIndex(alloc =>   alloc.hasSameAssignee(allocation));
    this[allocationIndex].updateQuantity(quantity);
    return new AllocationCollection(this.product, ...this);
  }

  public totalAllocated(): number {
    return this.reduce((accumulated, current) => {
      return accumulated + current.quantity;
    }, 0);
  }

  public orgMembers(): AllocationCollection {
    const allocations = this.filter((allocation: Allocation) => {
      return allocation.isOrgMemberAllocation();
    });
    return new AllocationCollection(this.product, ...allocations);
  }

  public accounts(): AllocationCollection {
    const allocations = this.filter((allocation: Allocation) => {
      return allocation.isAccountAllocation();
    });
    return new AllocationCollection(this.product, ...allocations);
  }

  public validate(distributorOrganizationLicense: OrganizationLicense | undefined): {isValid: boolean, errorMessage: string} {
    const validDistributorOrganizationLicense = distributorOrganizationLicense !== null && distributorOrganizationLicense !== undefined;
    const validDistributorOrganizationLicenseId = validDistributorOrganizationLicense && distributorOrganizationLicense ? distributorOrganizationLicense.id : '';
    const freeSpaceToAllocate = this.product.totalStock(validDistributorOrganizationLicenseId) - this.totalAllocated();
    const thereIsFreeSpace = freeSpaceToAllocate >= 0;
    const everyAllocationQuantityIsPositive = this.every(allocation => allocation.quantity >= 0);

    let errorMessage = '';
    if(!thereIsFreeSpace) {
      errorMessage = ALLOCATIONS_OUT_OF_RANGE_ERROR_MESSAGE;
    }
    if(!everyAllocationQuantityIsPositive) {
      errorMessage = NEGATIVE_ALLOCATION_QUANTITY_ERROR_MESSAGE;
    }
    if(!validDistributorOrganizationLicense) {
      errorMessage = INVALID_DISTRIBUTOR_ORGANIZATION_LICENSE;
    }
    return {
      isValid: thereIsFreeSpace && everyAllocationQuantityIsPositive && validDistributorOrganizationLicense,
      errorMessage: errorMessage
    };
  }

  public toDataTransferObject(orgId: string) {
    return this.map(allocation => allocation.toDataTransferObject(orgId));
  }

  public someIsAssignedToUser(user: User): boolean {
    return this.some((allocation) => allocation.isAssignedToUser(user));
  }

  public someIsAssignedToAccount(account: Account): boolean {
    return this.some((allocation) => !allocation.hasDifferentAccountAssigned(account));
  }

  public deleteAllocation(allocation: Allocation) {
    return this.updateAllocation(allocation, 0);
  }

  public removeAccountAllocation(accountAllocation: Allocation): AllocationCollection {
    const allocations = this.filter((allocation: Allocation) => {
      return allocation.hasDifferentAccountAssigned(accountAllocation.assignedTo as Account);
    });
    return new AllocationCollection(this.product, ...allocations);
  }
}
