import React from 'react';
import '../../../scss/ordo/salesActivity/activity-details.scss';
import {activityAssignedTo, ExternalEmailActivity} from '../../../models/sales-activity/Activity';
import gmailLogo from '../../profile/gmailLogo.png';
import {DateFormatTemplate, OrdoDate} from '../../../models/OrdoDate';
import {ThreadActivity} from '../../components/common/ThreadActivity';

type ExternalEmailActivityDetailsRowProps = {
  activity: ExternalEmailActivity,
};

export const ExternalEmailActivityDetailsRow = ({activity}: ExternalEmailActivityDetailsRowProps) => {
  const dateToText = `${OrdoDate.from(activity.date).format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY)}${activity.time ? `, ${OrdoDate.from(activity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : ''}`;

  return (
    <div className="activity-row">
      <div className="activity-row__header__info">
        <div className="activity-row__header__info__checked">
          <img className="gmail-icon" alt='gmail-icon' src={gmailLogo}/>
          <div className="activity-row__header__info__subject" title={activity.description}>{activity.description}</div>
        </div>
        <div className="activity-row__header__info__subtitle-info">
          <span className="activity-row__header__info__subtitle-info__date">{dateToText}</span>
          <span> • </span>
          <span className="activity-row__header__info__subtitle-info__subtitle">{activityAssignedTo(activity)}</span>
        </div>
        <ThreadActivity activity={activity}/>
      </div>
    </div>);
};
