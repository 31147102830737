import React, {useEffect, useState} from 'react';
import {sortBy} from 'lodash';
import '../../../../scss/ordo/mobileOrderEntry/mobile-order-entry.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {AccountLocation} from '../../../../models/Account';
import {ProductWithAvailability} from '../../../../models/productWithAvailability';
import {MobileOrderEntryListItem} from './MobileOrderEntryListItem';
import {CartItemType} from '../../../../models/order-entry/CartItem';
import OrdoButton from '../../../components/common/OrdoButton';
import {MobileOrderEntryFiltersSection} from './MobileOrderEntryFiltersSection';
import {MobileOrderEntryLocationAndCartSection} from './MobileOrderEntryLocationAndCartSection';

type MobileOrderEntrySecondStepProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateViewModel: (viewModel: OrderEntryViewModel) => void,
  updateAccountLocation: (accountLocation: AccountLocation) => void,
  updateCart: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void,
  goToVerifyOrder: () => void;
  goToFirstStep: () => void;
  landscapeMode?: boolean;
}

export const MobileOrderEntrySecondStep = ({orderEntryViewModel, updateViewModel, updateCart, updateAccountLocation, goToVerifyOrder, goToFirstStep, landscapeMode = false} : MobileOrderEntrySecondStepProps) => {
  const [filteredProducts, setFilteredProducts] = useState<ProductWithAvailability[]>(orderEntryViewModel.products);

  useEffect(() => {
    setFilteredProducts(orderEntryViewModel.products);
  }, [orderEntryViewModel.products]);

  const sortedProducts = () => {
    return sortBy(filteredProducts.filter(p => p.availability > 0), ['name']);
  };

  return(
    <>
      {
        !orderEntryViewModel.hasDistributors() && <div>
          <span>Please, enable an organization license to create an order</span>
        </div>
      }
      {
        orderEntryViewModel.hasDistributors() && <div className="mobile-order-entry-container">
          <div className="back-icon">
            <OrdoButton
              disabled={false}
              style={{fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'left', padding: 0}}
              text="back"
              firstChild={<FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '0.75rem'}}/>}
              category="link"
              onClick={goToFirstStep}
              dataTestId="go-back"
            />
          </div>
          <div className={`mobile-order-entry-header ${landscapeMode ? 'landscape' : ''}`}>
            <MobileOrderEntryLocationAndCartSection
              orderEntryViewModel={orderEntryViewModel}
              landscapeMode={landscapeMode}
              updateViewModel={updateViewModel}
              updateAccountLocation={updateAccountLocation}
              updateCart={updateCart}
            />
            <MobileOrderEntryFiltersSection
              landscapeMode={landscapeMode}
              updateFilteredProducts={setFilteredProducts}
              productsToFilter={orderEntryViewModel.products}
            />
          </div>
          <div className={`products-container ${landscapeMode ? 'landscape' : ''}`}>
            <div className="products-table-header">
              <div className="product-name">
                <span>product</span>
              </div>
              <div className="product-pricing">
                <span>pricing</span>
              </div>
            </div>
            <div className='products-list'>
              {sortedProducts().map((productWithAvailability) =>
                <MobileOrderEntryListItem
                  orderEntryViewModel={orderEntryViewModel}
                  onChange={updateCart}
                  product={productWithAvailability}
                  key={productWithAvailability.id}
                  landscapeMode={landscapeMode}
                />)}
            </div>
          </div>
          <div className={`redirect-to-next-view ${landscapeMode ? 'landscape' : ''}`}>
            <OrdoButton
              disabled={!orderEntryViewModel.canCompleteOrder()}
              disabledText={orderEntryViewModel.completeOrderInvalidText()}
              text="review prices and discounts"
              category="primary"
              onClick={() => goToVerifyOrder()}
              dataTestId="review prices and discounts-button"
              style={{padding: '0.25em 1em', maxWidth: '25em'}}
            />
          </div>
        </div>
      }
    </>

  );
};
