import React, {FC, useContext, useState} from 'react';
import '../../scss/ordo/organizationPage/organizations-page-section.scss';
import '../../scss/ordo/common.scss';
import {Organization} from '../../models/Organization';
import OrdoCardOptionsDropdown from '../components/common/OrdoCardOptionsDropdown';
import EditSubscriptionFormModal from './EditSubscriptionFormModal';
import {UserSessionContext} from '../../context/UserSessionContext';
import {useAPI} from '../../context/OrdoApiContext';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import {PaymentsHistoryModal, SubscriptionPayment} from './PaymentsHistoryModal';
import useOrdoToasts from '../../hooks/useOrdoToasts';

type OrganizationCardProps = { organization: Organization, onCardSelected: Function, onEditOrganizationCardInfo: Function, currentOrganizationId: string };


const OrganizationCard: FC<OrganizationCardProps> = ({organization, onCardSelected, onEditOrganizationCardInfo, currentOrganizationId}: OrganizationCardProps) => {
  const isCurrentOrganization = (org: Organization) => currentOrganizationId === org.id;
  const borderStyle = (org: Organization) => isCurrentOrganization(org) ? 'selected' : '';
  const userSessionContextData = useContext(UserSessionContext);
  const [showSubscriptionManagementModal, setShowSubscriptionManagementModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState<{payments: SubscriptionPayment[], show: boolean}>({payments: [], show: false});
  const api = useAPI();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {errorToast} = useOrdoToasts();

  const showPaymentHistory = () => {
    showSpinner();
    api.paymentsHistory(organization.id)
      .then(orgPaymentHistory => setPaymentHistory({payments: orgPaymentHistory, show: true}))
      .catch(() => errorToast('Could not retrieve payments, try again later'))
      .finally(hideSpinner);
  };
  const closePaymentHistory = () => {
    setPaymentHistory({payments: [], show: false});
  };

  const options = [
    {
      name: 'manage billing details',
      action: () => setShowSubscriptionManagementModal(true),
    },
    {
      name: 'view payment history',
      action: () => showPaymentHistory(),
    }
  ];

  const selectOrganization = (org: Organization) => {
    if(!isCurrentOrganization(org)){
      onCardSelected(org);
    }
  };

  const handleKeyDown = (event: any, org: Organization) => {
    if (!event || event.type === 'click' ||
      (['keydown', 'keypress'].includes(event.type) && ['Enter', ' '].includes(event.key))
    ) {
      selectOrganization(org);
    }
  };

  const renderOrganizationBrands = () => {
    if (organization.brands.length > 0) {
      return organization.brands.slice(0,4).map(brand => {
        return (<div key={brand.id} className='brand'>
          <img alt='brand' src={brand.logoImageURL} className='rounded-lg'/>
        </div>);
      });
    }
    return(
      <div className="no-brands-added">
        <p>No brands added yet</p>
      </div>
    );
  };

  const renderMoreBrandsText = () => {
    return organization.brands.length > 4 ?
      (
        <div className="more-brands-text">
          <span>{`+${organization.brands.length - 4} brand${organization.brands.length - 4 > 1 ? 's' : ''} `}</span>
        </div>
      ) : <div/>;
  };

  const isSubscriptionOwner = () => {
    return userSessionContextData.user().id === organization.subscription.subscriptionUserId;
  };

  return (
    <div className={`card card-fluid organization-card ${borderStyle(organization)}`}
      key={`organization-${organization.id}`}
      data-testid={`organization-${organization.id}`}
      onClick={() => selectOrganization(organization)}
      role='presentation'
      onKeyDown={(event) => handleKeyDown(event, organization)}
    >
      <OrdoSpinner showSpinner={spinnerVisibility}>

        <div className='organization-card__upper-section'>
          <h6 className="organization-card__upper-section__title">{organization.name}</h6>
          {isSubscriptionOwner() && <OrdoCardOptionsDropdown options={options}/>}
        </div>
        <div className="card-body organization-card__body">
          <div className="organization-card__body__user-section">
            <h6 className="mb-0 organization-card__body__owner_title">owner</h6>
            <p className="text-muted mb-0 text-truncate organization-card__body__owner_name">
              {`${organization.owner.firstName} ${organization.owner.lastName}`}
            </p>
            <p className="text-muted mb-0 text-truncate organization-card__body__owner_email">
              {organization.owner.email}
            </p>
          </div>

          <div className="brands-title">
            <h6 className="mb-0 organization-card__body__owner_title">brands</h6>
          </div>
          <div className="organization-card__body__brands-container">
            <div className="brands">
              {renderOrganizationBrands()}
            </div>
            {renderMoreBrandsText()}
          </div>

          <div className="organization-status">
            <span className="h6 font-weight-bold text-center text-nowrap text-muted text-lowercase">{organization.status}</span>
          </div>
        </div>
        {showSubscriptionManagementModal && <EditSubscriptionFormModal
          organization={organization}
          isOpen={showSubscriptionManagementModal}
          onClose={() => setShowSubscriptionManagementModal(false)}
          onSubmit={onEditOrganizationCardInfo}
        />}
        {paymentHistory.show &&
        <PaymentsHistoryModal
          payments={paymentHistory.payments}
          onClose={closePaymentHistory}
          show={paymentHistory.show}
        />
        }
      </OrdoSpinner>
    </div>
  );
};

export default OrganizationCard;
