import React, {useContext} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import {AdminChecker} from '../../routes/permissions';
import {UserSessionContext} from '../../context/UserSessionContext';
import OrdoSearchableDropdown from '../components/common/searchable-dropdown/OrdoSearchableDropdown';
import {TimePeriod} from '../../models/TimePeriod';
import OrdoMultiSelect from '../components/common/OrdoMultiSelect';
import {NOTE_EVENT_TYPE, ORDER_EVENT_TYPE} from '../../models/AccountTimeline';
import {TimelineFilter} from './TimelineFilter';
import {ActivityTemplate} from '../../models/sales-activity/Activity';
import {fullName} from '../../models/User';

type TimelineFiltersProps = {
  filter: TimelineFilter,
  selectedTimePeriod: TimePeriod,
  timePeriodOptions: { data: TimePeriod, value: string, label: string }[],
  displayedEvents: { type: string }[],
  descendingOrder: boolean,
  activityTemplates: ActivityTemplate[],
  setFilter: (filter: TimelineFilter) => void
  setTimePeriod: (timePeriod: TimePeriod) => void
  setDisplayedEvents: (events: { type: string }[]) => void
  setDescendingOrder: (descendingOrder: boolean) => void
};
export const TimelineFilters = ({
  filter, selectedTimePeriod, timePeriodOptions, setFilter, setTimePeriod, setDisplayedEvents,
  activityTemplates, descendingOrder, displayedEvents, setDescendingOrder
}: TimelineFiltersProps) => {
  const adminChecker = new AdminChecker();
  const userSession = useContext(UserSessionContext);
  const currentOrganization = userSession.currentOrganization();
  const salesRepOptions = currentOrganization?.members.map((m) => {
    return {label: fullName(m.user), value: m.user.email, data: m.user};
  }) || [];

  return <div className="timeline-page__filters">
    <input
      id="search-by-account-name-input"
      data-testid="search-by-location-name-input"
      type="text"
      className="search-by-account-input"
      placeholder="search account name"
      value={filter.accountNameSearch}
      onChange={(event) => setFilter(filter.updateAccountSearch(event.target.value))}
    />
    <OrdoSearchableDropdown placeholder="state"
      dataTestId="select-time-period"
      selectedOption={timePeriodOptions.find(t => t.data.days === selectedTimePeriod.days)}
      options={timePeriodOptions}
      onChangeSelectedOption={(selected: { data: TimePeriod }) => setTimePeriod(selected.data)}
      singleRowHeight
    />
    {
      adminChecker.allow(userSession.userSession) &&
      <OrdoMultiSelect
        options={salesRepOptions}
        onChangeSelectedOption={(salesreps: any[]) => setFilter(filter.updateSelectedSalesReps(salesreps.map((salesrep: any) => salesrep.data)))}
        selectedOptions={filter.selectedSalesReps.map(member => ({
          label: member.email,
          value: member.email,
          data: member
        }))}
        placeholder="select sales rep"
        addedStyles={
          {
            container: {width: '20%'},
            control: {marginBottom: '0'},
            multiValue: {width: 'auto'}
          }
        }
      />
    }
    <OrdoMultiSelect
      options={[...activityTemplates.map(template => ({label: template.name, value: template.name})), {
        label: ORDER_EVENT_TYPE,
        value: ORDER_EVENT_TYPE
      }, {label: NOTE_EVENT_TYPE, value: NOTE_EVENT_TYPE}]}
      onChangeSelectedOption={(events: { label: string }[]) => setDisplayedEvents(events.map(e => ({type: e.label})))}
      selectedOptions={displayedEvents.map(e => ({label: e.type, value: e.type}))}
      placeholder="events: all"
      addedStyles={
        {
          container: {width: '20%'},
          control: {marginBottom: '0'},
          multiValue: {width: '45%'}
        }
      }
    />

    <div role="presentation" onClick={() => setDescendingOrder(!descendingOrder)} className="sorting-amount-filter">
      <FontAwesomeIcon className="sort-icon" icon={faExchangeAlt}/>
      <span>{`#events: ${descendingOrder ? 'descending' : 'ascending'}`}</span>
    </div>
  </div>;
};