import React from 'react';
import {ActivitiesFilter} from '../../filter/ActivitiesFilter';

type StateFilterProps = {
  text: string,
  checked: boolean,
  onClick: Function
}

export const StateFilter = ({text, checked, onClick}: StateFilterProps) => {
  return(
    <div role="presentation" className={`activities-state-filter ${checked ? 'checked' : ''}`} onClick={() => onClick()}>
      <span>{text}</span>
    </div>
  );
};

export const ActivitiesStateFilters = ({filter, onUpdateFilter}: {filter: ActivitiesFilter, onUpdateFilter: Function}) => {
  return(
    <div className="activities-state-filters">
      <StateFilter text='today' checked={filter.hasTodayFilter()} onClick={() => {onUpdateFilter(filter.toggleTodayFilter());}}/>
      <StateFilter text='to-do' checked={filter.hasToDoFilter()} onClick={() => {onUpdateFilter(filter.toggleToDoFilter());}}/>
      <StateFilter text='overdue' checked={filter.hasOverdueFilter()} onClick={() => {onUpdateFilter(filter.toggleOverdueFilter());}}/>
      <StateFilter text='complete' checked={filter.hasCompleteFilter()} onClick={() => {onUpdateFilter(filter.toggleCompleteFilter());}}/>
    </div>
  );
};
