import React from 'react';
import {MobileOrderEntryCheckoutItem} from './MobileOrderEntryCheckoutItem';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {ProductLine} from '../../../../models/order-entry/Cart';
import {CartItemType} from '../../../../models/order-entry/CartItem';

type ItemLinesProps = {
  itemType: CartItemType,
  viewModel: OrderEntryViewModel,
  itemsList: ProductLine[],
  updateViewModel: (orderEntryViewModel: OrderEntryViewModel) => void,
};

export const ItemLines = ({itemType, viewModel, itemsList, updateViewModel}: ItemLinesProps) => {
  return (
    <div className="mobile-checkout-products-container__table">
      <div className="table-header">
        <div className="item-info">
          <span>{itemType === CartItemType.CASE ? 'cases' : 'units'}</span>
        </div>
        <div className="item-qty">
          <span>qty</span>
        </div>
        <div className="item-price">
          <span>pricing</span>
        </div>
        <div className="item-discount">
          <span>discount</span>
        </div>
      </div>
      <div className='items-list'>
        {itemsList.map((productLine) =>
          <MobileOrderEntryCheckoutItem
            orderEntryViewModel={viewModel}
            updateViewModel={updateViewModel}
            productLine={productLine}
            key={productLine.item.product.id + productLine.line.id + productLine.index}
          />)}
      </div>
    </div>
  );
};
