import {OrderError} from './order_error';

export class BusinessOrderError extends Error {
  public orderError: OrderError;

  constructor(orderError: OrderError, message: string) {
    super(message);
    this.orderError = orderError;
  }
}
