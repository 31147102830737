import React, {FC, useContext, useState} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import LoginViewModel from '../../application-models/loginViewModel';
import {API} from '../../lib/api/ordoApi';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {UserSessionContext} from '../../context/UserSessionContext';
import Login from './Login';

type LoginAndAcceptInvitationProps = { api: API } & RouteComponentProps<{ invitationTokenId: string }>

const LoginAndAcceptInvitation: FC<LoginAndAcceptInvitationProps>  = ({api, match}: LoginAndAcceptInvitationProps) => {
  const [loginViewModel, setLoginViewModel] = useState(LoginViewModel.emptyLoginViewModel(api));
  const history = useHistory();
  const userContextData = useContext(UserSessionContext);

  const loginAndAcceptInvitation = async () => {
    const viewModelWithoutErrors = loginViewModel.cleanErrors();
    setLoginViewModel(viewModelWithoutErrors);

    const newLoginViewModel = await viewModelWithoutErrors.signInAndAcceptInvitation(match.params.invitationTokenId);
    setLoginViewModel(newLoginViewModel);
    if (!newLoginViewModel.hasError()) {
      userContextData.update(newLoginViewModel.userSession);
      history.push(ROUTE_NAMES.PROFILE);
    }
  };

  return (
    <Login showCreateAccount={false}
      title="Login & Join Ordo"
      callToActionText="Sign in & Accept Invitation"
      callToAction={loginAndAcceptInvitation}
      loginViewModel={loginViewModel}
      setLoginViewModel={setLoginViewModel}
    />
  );
};

export default withRouter(LoginAndAcceptInvitation);
