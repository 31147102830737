import React, {FC, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import AccountsViewModel from '../../application-models/AccountsPageViewModel';
import '../../scss/ordo/accountsPage/list.scss';
import '../../scss/ordo/accountsPage/accounts-page.scss';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import {UserSessionContext} from '../../context/UserSessionContext';
import useOrganizationRedirect from '../../hooks/useOrganizationRedirect';
import RepAssignmentCard from './RepAssignmentCard';
import {useAPI} from '../../context/OrdoApiContext';
import {AccountList} from './AccountList';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import {InteractiveMap} from './maps/InteractiveMap';
import {AdminChecker} from '../../routes/permissions';
import Account from '../../models/Account';

type AreaSummaryProps = {
  total: number,
  newLicenses: number,
  assigned: number,
  unassigned: number,
}

const AreaSummary = ({total, newLicenses, assigned, unassigned} : AreaSummaryProps) => {
  return <div className="area-summary ordo-card-border ordo-shadow">
    <div className="area-summary__title">Area Summary</div>
    <div className="area-summary__counter">
      <span>Licenses</span>
      <span>{total}</span>
    </div>
    <div className="area-summary__counter">
      <span>New Licenses</span>
      <span>{newLicenses}</span>
    </div>
    <div className="area-summary__counter">
      <span>Assigned</span>
      <span>{assigned}</span>
    </div>
    <div className="area-summary__counter">
      <span>Unassigned</span>
      <span>{unassigned}</span>
    </div>
  </div>;
};

export const AccountsPage: FC  = () => {
  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const adminChecker = new AdminChecker();
  const {validateOrRedirect} = useOrganizationRedirect();
  const currentOrganization = userSession.currentOrganization();
  const curOrgId = currentOrganization?.id || '';
  const [viewModel, setViewModel] = useState(AccountsViewModel.empty(api));
  const {pageNumber} = useParams<{pageNumber: string}>();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {errorToast} = useOrdoToasts();

  const initialize = () => {
    showSpinner();
    AccountsViewModel.initialize(api, curOrgId, parseInt(pageNumber,10) || 1, currentOrganization?.state || '').then(setViewModel).finally(hideSpinner);
  };

  const refresh = () => {
    showSpinner();
    viewModel.refresh(api, curOrgId, parseInt(pageNumber,10) || 1, currentOrganization?.state || '').then(setViewModel).finally(hideSpinner);
  };

  useEffect(() => {
    validateOrRedirect(initialize);
  }, [currentOrganization]);

  const renderError = () => {
    if (viewModel.fetchError) {
      return (
        <div className="alert alert-danger" role="alert">
          { viewModel.fetchError.message }
        </div>
      );
    }
    return <span/>;
  };

  const renderAssignments = () => {
    return viewModel.assignments.map((assignment, index) => {
      return ( <RepAssignmentCard key={assignment.member.getUserId()} assignment={assignment} index={index}
        onCardClick={(event: { preventDefault: () => void; }) => {
          event.preventDefault();
          const updated = viewModel.withAssignment(assignment);
          setViewModel(updated.withEditAssignmentsModalOpen(true));
        }}/>
      );
    });
  };

  const updatePage = (page: number) => {
    viewModel.updatePage(page)
      .then((updatedViewModel) => setViewModel(updatedViewModel))
      .catch(() => {
        errorToast(`Could not search page number ${page}`);
      })
      .finally(hideSpinner);
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='accounts-page account-container'>
        <OrdoPageTitle title='accounts and territories' buttons={[]}/>
        {renderError()}
        <div className="page-content-body ">
          <div className='accounts-page-content ordo-shadow ordo-card-border'>
            <AccountList
              orgId={curOrgId}
              viewModel={viewModel}
              setViewModel={setViewModel}
              showSpinner={showSpinner}
              onReloadPage={(_updatedAccount: Account) => refresh()}
              hideSpinner={hideSpinner}
              onPageChange={updatePage}/>
            <div className="wrapper-for-map">
              <div className="account-box"/>
              <AreaSummary
                total={viewModel.amountOfAccounts()}
                newLicenses={viewModel.amountOfNewLicenses()}
                assigned={viewModel.amountOfAssignedAccounts()}
                unassigned={viewModel.amountOfUnassignedAccounts()}
              />
              <InteractiveMap viewModel={viewModel} setViewModel={setViewModel}/>
            </div>
          </div>
        </div>
      </div>
      {adminChecker.allow(userSession.userSession) && <div className='col assignment-container'>
        {renderAssignments()}
      </div>}
    </OrdoSpinner>
  );
};
