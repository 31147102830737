import React, {useState} from 'react';
import {uniqWith} from 'lodash';
import {v4} from 'uuid';
import Account from '../../../models/Account';
import OrdoButton from '../common/OrdoButton';
import OrdoInput from '../common/inputs/OrdoInput';
import {GroupPricePriceRule, ProductSKUPriceRule, Tier} from '../../../models/custom-pricing-tiers/Tier';
import ProductCategory from '../../../models/ProductCategory';
import {Brand} from '../../../models/Brand';
import Product, {ConsumerUnitSize, Genetics, geneticsToString} from '../../../models/Product';
import {GroupPriceEditor} from './GroupPriceEditor';
import {ProductSKUPriceEditor} from './ProductSKUPriceEditor';
import {MultiSelectorWithTags} from '../common/MultiSelectorWithTags';

enum ACCOUNT_GROUP_OPTIONS {
  ALL_ACCOUNTS,
  GOLD_ACCOUNTS,
  SILVER_ACCOUNTS,
  BRONZE_ACCOUNTS,
}

type CustomPricingTierFormProps = {
  onSubmit: (tier: Tier) => void,
  onCancel: () => void,
  accounts: Account[],
  brands: Brand[],
  categories: ProductCategory[],
  products: Product[],
  tierToEdit: Tier,
}


function canSubmitTier(tier: Tier) {
  return !!tier.name && tier.productSKUPrices.every((skuPrice) => skuPrice.customerSKU);
}

function disabledReason(tier: Tier) {
  if (!tier.name) {
    return 'Tier name missing';
  }
  if (!tier.productSKUPrices.every((skuPrice) => skuPrice.customerSKU)) {
    return 'A product SKU selection is missing';
  }
  return '';
}

export const CustomPricingTierForm = ({tierToEdit, accounts, brands, categories, products, onSubmit, onCancel}: CustomPricingTierFormProps) => {
  const brandsOptions = brands.map(brand => {
    return {label: brand.name, value: brand.id};
  });
  const categoriesOptions = categories.map(category => {
    return {label: category.name, value: category.id};
  });
  const geneticsOptions = [Genetics.HYBRID, Genetics.INDICA, Genetics.SATIVA].map(genetics => {
    return {label: geneticsToString(genetics), value: genetics};
  });
  const productOptions = products.map(product => {
    return {label: product.name, value: product};
  });
  const existingSizes = products.filter(product => !!product.size).map(product => product.size);

  const sizes = uniqWith<ConsumerUnitSize>(existingSizes as ConsumerUnitSize[], (size1, size2) => size1!.unit === size2!.unit && size1!.amount === size2!.amount );
  const sizesOptions = sizes.map(size => {
    return {label: `${size.amount} ${size.unit}`, value: size};
  });

  // @ts-ignore
  brandsOptions.unshift({label:'all', value: undefined});
  // @ts-ignore
  categoriesOptions.unshift({label:'all', value: undefined});
  // @ts-ignore
  geneticsOptions.unshift({label:'all', value: undefined});
  // @ts-ignore
  sizesOptions.unshift({label:'all', value: undefined});

  const [tier, setTier] = useState<Tier>(tierToEdit);

  const accountSelected = (value:  ACCOUNT_GROUP_OPTIONS | string) => {
    switch (value) {
    case ACCOUNT_GROUP_OPTIONS.ALL_ACCOUNTS:
      setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, true, tier.priorities, tier.id));
      return;
    case ACCOUNT_GROUP_OPTIONS.GOLD_ACCOUNTS:
      tier.priorities.push(1);
      break;
    case ACCOUNT_GROUP_OPTIONS.SILVER_ACCOUNTS:
      tier.priorities.push(2);
      break;
    case ACCOUNT_GROUP_OPTIONS.BRONZE_ACCOUNTS:
      tier.priorities.push(3);
      break;
    default:
      tier.accountIds.push(value as string);
      break;
    }
    setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };

  const accountRemoved = (value:  ACCOUNT_GROUP_OPTIONS | string) => {
    switch (value) {
    case ACCOUNT_GROUP_OPTIONS.ALL_ACCOUNTS:
      setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, false, tier.priorities, tier.id));
      return;
    case ACCOUNT_GROUP_OPTIONS.GOLD_ACCOUNTS:
      tier.priorities = tier.priorities.filter(priority => priority !== 1);
      break;
    case ACCOUNT_GROUP_OPTIONS.SILVER_ACCOUNTS:
      tier.priorities = tier.priorities.filter(priority => priority !== 2);
      break;
    case ACCOUNT_GROUP_OPTIONS.BRONZE_ACCOUNTS:
      tier.priorities = tier.priorities.filter(priority => priority !== 3);
      break;
    default:
      tier.accountIds = tier.accountIds.filter(accountId => accountId !== value);
      break;
    }
    setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };

  const updateName = (newName: string) => {
    setTier(new Tier(newName, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };

  const addSKUPrice = () => {
    tier.productSKUPrices.push({id: v4(), customerSKU: '', unitPrice: 0 });
    setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };

  const addGroupPrice = () => {
    tier.groupPrices.push({id: v4(), unitPrice: 0});
    setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };

  const tierAccounts = accounts.filter(account => tier.accountIds.includes(account.id)).map(account => ({label: account.name, value: account.id}));

  const groupOptions = [];
  const tierOptions = [];
  const allAccountsOption = {label: 'all accounts', value: ACCOUNT_GROUP_OPTIONS.ALL_ACCOUNTS};
  if(tier.allAccounts) {
    tierOptions.push(allAccountsOption);
  } else {
    groupOptions.push(allAccountsOption);
  }

  const priority1Option = {label: 'tier 1 accounts', value: ACCOUNT_GROUP_OPTIONS.GOLD_ACCOUNTS};
  if(tier.priorities.includes(1)) {
    tierOptions.push(priority1Option);
  } else {
    groupOptions.push(priority1Option);
  }
  const priority2Option = {label: 'tier 2 accounts', value: ACCOUNT_GROUP_OPTIONS.SILVER_ACCOUNTS};
  if(tier.priorities.includes(2)) {
    tierOptions.push(priority2Option);
  } else {
    groupOptions.push(priority2Option);
  }
  const priority3Option = {label: 'tier 3 accounts', value: ACCOUNT_GROUP_OPTIONS.BRONZE_ACCOUNTS};
  if(tier.priorities.includes(3)) {
    tierOptions.push(priority3Option);
  } else {
    groupOptions.push(priority3Option);
  }

  const accountOptions = [
    ...groupOptions,
    ...accounts.filter(account => !tier.accountIds.includes(account.id)).map(account => {
      return {label: account.name, value: account.id};
    })
  ];

  const updateProductSKUPrice = (productSKUPrice: ProductSKUPriceRule, index: number) => {
    tier.productSKUPrices.splice(index, 1, productSKUPrice);
    setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };
  const removeProductSKUPrice = (index: number) => {
    tier.productSKUPrices.splice(index, 1);
    setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };
  const updateGroupPrice = (groupPrice: GroupPricePriceRule, index: number) => {
    tier.groupPrices.splice(index, 1, groupPrice);
    setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };
  const removeGroupPrice = (index: number) => {
    tier.groupPrices.splice(index, 1);
    setTier(new Tier(tier.name, tier.productSKUPrices, tier.groupPrices, tier.accountIds, tier.allAccounts, tier.priorities, tier.id));
  };

  return <div className="custom-tier-form">
    <OrdoInput inputName="pricing tier" inputTitle="pricing tier" value={tier.name} placeholder="enter a tier name"
      inputSize="large" onChange={updateName}/>
    <div className='custom-tier-form__custom-price-section'>
      <div className='header'>
        <span className='title'>add custom price</span>
        <OrdoButton text="add group price"
          onClick={addGroupPrice}
          category="primary"
          disabled={false}
          dataTestId="group-price-button"
        />
        <OrdoButton text="add sku price"
          onClick={addSKUPrice}
          category="primary"
          disabled={false}
          dataTestId="sku-price-button"
        />
      </div>
      {tier.groupPrices.map((groupPrice, index) => {
        return <GroupPriceEditor groupPrice={groupPrice}
          key={groupPrice.id}
          brands={brandsOptions}
          categories={categoriesOptions}
          sizes={sizesOptions}
          genetics={geneticsOptions}
          index={index}
          onChange={updateGroupPrice}
          onRemoveRow={removeGroupPrice}
        />;
      })}
      {tier.productSKUPrices.map((skuPrice, index) => {
        return <ProductSKUPriceEditor
          key={skuPrice.id}
          skuPrice={skuPrice} products={productOptions}
          index={index} onChange={updateProductSKUPrice} onRemoveRow={removeProductSKUPrice}/>;
      })}
    </div>
    <div className='custom-tier-form__accounts-section'>
      <span className='title'>add account</span>
      <MultiSelectorWithTags<string | ACCOUNT_GROUP_OPTIONS>
        placeholder="search account by name"
        options={accountOptions}
        onOptionSelected={(option) => accountSelected(option)}
        selectedOptions={[...tierOptions,...tierAccounts]}
        onOptionRemoved={(option) => accountRemoved(option)}
      />
    </div>
    <div className="custom-tier-form__buttons-container">
      <OrdoButton text="save"
        onClick={() => onSubmit(tier)}
        category="primary"
        disabled={!canSubmitTier(tier)}
        disabledText={disabledReason(tier)}
        dataTestId="save-button"
      />
      <OrdoButton text="cancel"
        onClick={onCancel}
        category="cancel"
        disabled={false}
        dataTestId="cancel-button"
      />
    </div>
  </div>;
};