import React, {PropsWithChildren, ReactNode, useState} from 'react';
import { CART_KEY } from './local-storage-constants';
import {Cart, SerializableCart} from '../models/order-entry/Cart';

export type OrderEntryCartContextData = {
  storedCart: SerializableCart,
  saveCart: Function,
  clearCart: Function,
}

export const defaultOrderEntryCartContextData = {
  storedCart: ({items: []}),
  saveCart: () => {},
  clearCart: () => {},
};

function getCurrentCartData(storage: Storage): SerializableCart {
  let cart: SerializableCart = defaultOrderEntryCartContextData.storedCart;
  const item: string | null = storage.getItem(CART_KEY);
  if (item) {
    // In case we do (intentionally or unintentionally) make a breaking change, we should just try a best effort and return an empty cart otherwise.
    try {
      cart = JSON.parse(item) as SerializableCart;
    } catch (err) {
      /* eslint-disable no-console */
      console.warn('could not parse stored cart');
      console.warn(err);
      /* eslint-enable no-console */
    }
  }
  return cart;
}

function updateCurrentCartData(storage: Storage, cart: Cart): SerializableCart {
  const storedCart = {accountLocation: cart.accountLocation, items: cart.items.map((item) => item.toJSON())};
  storage.setItem(CART_KEY, JSON.stringify(storedCart));
  return storedCart;
}

function deleteCurrentCartData(storage: Storage): void{
  storage.removeItem(CART_KEY);
}

export const OrderEntryCartContext = React.createContext<OrderEntryCartContextData>(defaultOrderEntryCartContextData);
type OrderEntryCartContextProviderProps = {
  storage: Storage
} & PropsWithChildren<ReactNode>
const OrderEntryCartContextProvider = ({children, storage}: OrderEntryCartContextProviderProps) => {
  const [orderEntryCartData, setOrderEntryCartData] = useState(getCurrentCartData(storage));

  const update = (cart: Cart): void => {
    const cartData = updateCurrentCartData(storage, cart);
    setOrderEntryCartData(cartData);
  };

  const clear = (): void => {
    deleteCurrentCartData(storage);
    setOrderEntryCartData(defaultOrderEntryCartContextData.storedCart);
  };

  return (<OrderEntryCartContext.Provider value={{storedCart: orderEntryCartData, saveCart: update, clearCart: clear}}>
    {children}
  </OrderEntryCartContext.Provider>);
};

export default OrderEntryCartContextProvider;
