import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import {OrderStatus} from '../../models/order-entry/Order';
import Contact from '../../models/order-entry/Contact';
import {DateFormatTemplate, OrdoDate} from '../../models/OrdoDate';
import {SalesActivityAccount} from '../../pages/sales-activity/salesActivityTypes';
import { PipelineButtonText } from '../../pages/sales-activity/salesActivityFunctions';
import {accountLocationHasStreetAddress} from '../../models/Account';

export class SalesActivityOrderDetailsViewModel {
  constructor(
    public order: OrderWithCurrentVersion,
    public accountContacts: Contact[],
  ) {}

  public accountName(): string {
    return this.order.accountName;
  }

  public orderStatus(): OrderStatus {
    return this.order.status;
  }

  public isPendingOrder(): boolean {
    return this.orderStatus() === OrderStatus.Pending;
  }

  public isConfirmedOrder(): boolean {
    return this.orderStatus() === OrderStatus.Confirmed;
  }

  public orderNumber(): string{
    return this.order.orderNumber;
  }

  public licenseNumber(): string{
    return this.order.accountLocation.location.licenseNumber;
  }

  public accountLocationAddress(): string{
    const {accountLocation} = this.order;
    return accountLocationHasStreetAddress(accountLocation) ? `${accountLocation.streetAddressLine1} ${accountLocation.streetAddressLine2}, ${accountLocation.city} ${accountLocation.state}  ${accountLocation.zipCode}` :
      `${accountLocation.city} ${accountLocation.state}  ${accountLocation.zipCode}`;
  }

  public currentVersionSummary() {
    return this.order.summary;
  }

  public invoiceUrl() {
    return this.order.currentVersion.invoiceUrl;
  }

  public statusDescription() {
    switch (this.order.status){
    case OrderStatus.Pending:
      return 'confirm purchaser approval';
    case OrderStatus.Confirmed:
      return 'awaiting delivery validation';
    case OrderStatus.Delivered:
      return 'all or part of payment outstanding';
    default:
      return '';
    }
  }

  public getAccountStalePeriod() {
    return this.order.currentVersion.account.getStalePeriod();
  }

  public amountOfContacts(): number {
    return this.accountContacts.length;
  }

  public accountId(): string {
    return this.order.accountId();
  }

  public addContact(contact: Contact): SalesActivityOrderDetailsViewModel{
    this.accountContacts.push(contact);
    return new SalesActivityOrderDetailsViewModel(this.order, this.accountContacts);
  }

  public getCtaText() : string {
    switch(this.orderStatus()) {
    case OrderStatus.Pending:
      return PipelineButtonText.CONFIRM_ORDER;
    case OrderStatus.Confirmed:
      return PipelineButtonText.VERIFY_DELIVERY;
    case OrderStatus.Delivered:
      return PipelineButtonText.LOG_PAYMENT;
    default:
      return PipelineButtonText.CONFIRM_ORDER;
    }
  }

  public getHeaderDescription() : string{
    switch(this.orderStatus()) {
    case OrderStatus.Pending:
      return 'pending';
    case OrderStatus.Confirmed:
      return `placed ${OrdoDate.from(this.order.datePlaced).format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY).toLowerCase()}`;
    case OrderStatus.Delivered:
      return 'collect payment';
    default:
      return 'pending';
    }
  }

  public updateRetailerStalePeriod(stalePeriod: number): SalesActivityOrderDetailsViewModel {
    this.order.updateAccountStalePeriod(stalePeriod);
    return this;
  }

  public salesActivityAccount(): SalesActivityAccount {
    const {account} = this.order.currentVersion;
    return {
      id: account.id,
      name: account.name,
      address: account.address(),
      accountLocations: account.locations,
      amountOfLocations: account.locations.length,
      lastOrderDate: '',
      priority: account.priority,
      stalePeriod: account.stalePeriod,
      conversion: account.conversion,
      value: account.value,
      activeAccount: account.active,
      orderEntryEnabled: account.orderEntryEnabled,
    };
  }
}
