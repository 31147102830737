import {
  faBoxes,
  faChartLine,
  faClipboardList,
  faFileInvoice,
  faShoppingBag,
  faStore,
  faUser,
  faUsers,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {faBuilding} from '@fortawesome/free-solid-svg-icons/faBuilding';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {Resolution} from '../../context/resolutions';
import { faTimeline } from '../../custom-icons/faTimeline';

export type NavigationButtonData = {
  title: string,
  route: string,
  alternativeRoute?: string,
  icon: IconDefinition,
  notDisplayableResolutions: Resolution[],
}

// @ts-ignore
export const navigationButtonsData: NavigationButtonData[] = [
  {
    title: 'manage account',
    route: ROUTE_NAMES.PROFILE,
    icon: faUser,
    notDisplayableResolutions: [],
  },
  {
    title: 'manage organization',
    route: ROUTE_NAMES.ORGANIZATION,
    icon: faBuilding,
    notDisplayableResolutions: [Resolution.MOBILE],
  },
  {
    title: 'order history',
    route: ROUTE_NAMES.ORDER_HISTORY,
    icon: faChartLine,
    notDisplayableResolutions: [],
  },
  {
    title: 'inventory',
    route: ROUTE_NAMES.INVENTORY,
    icon: faBoxes,
    notDisplayableResolutions: [Resolution.MOBILE],
  },
  {
    title: 'order entry',
    route: ROUTE_NAMES.ORDER_ENTRY,
    alternativeRoute: ROUTE_NAMES.EDIT_ORDER,
    icon: faFileInvoice,
    notDisplayableResolutions: [],
  },
  {
    title: 'accounts',
    route: ROUTE_NAMES.ACCOUNTS,
    icon: faStore,
    notDisplayableResolutions: [Resolution.MOBILE],
  },
  {
    title: 'contacts',
    route: ROUTE_NAMES.CONTACTS_PAGE,
    icon: faUsers,
    notDisplayableResolutions: [],
  },
  {
    title: 'account timeline',
    route: ROUTE_NAMES.TIMELINE,
    // @ts-ignore
    icon: faTimeline,
    notDisplayableResolutions: [Resolution.MOBILE],
  },
  {
    title: 'sales activity',
    route: ROUTE_NAMES.SALES_ACTIVITY,
    icon: faShoppingBag,
    notDisplayableResolutions: [],
  },
  {
    title: 'activities',
    route: ROUTE_NAMES.ACTIVITY_MANAGER,
    icon: faClipboardList,
    notDisplayableResolutions: [],
  }
];

export const shouldShowIconForResolution = (navigationButton: NavigationButtonData, resolutionKind: Resolution) => {
  return !navigationButton.notDisplayableResolutions.includes(resolutionKind);
};
