import React, {useState} from 'react';
import '../../../scss/ordo/accountsPage/accountPage/account-page.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {activityAssignedTo, ExternalEmailActivity} from '../../../models/sales-activity/Activity';
import gmailLogo from '../../profile/gmailLogo.png';
import {DateFormatTemplate, OrdoDate} from '../../../models/OrdoDate';
import {ThreadActivity} from '../../components/common/ThreadActivity';

type ExternalEmailActivityForAccountActivityProps = {
  activity: ExternalEmailActivity,
};

export const ExternalEmailActivityForAccountActivity = ({activity}: ExternalEmailActivityForAccountActivityProps) => {
  const [expandedCard, setExpandedCard] = useState(false);
  const dateToText = `${OrdoDate.from(activity.date).format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY)}${activity.time ? `, ${OrdoDate.from(activity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : ''}`;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="account-activity-card" onClick={() => setExpandedCard(!expandedCard)} role="presentation">
      <div className="icon-container external-email-activity">
        <FontAwesomeIcon icon={faEnvelope} size="2x" />
      </div>
      <div className="information">
        <div className="collapsed">
          <div>
            <div className="title-and-complete">
              <div>
                <img className="gmail-icon" alt='gmail-icon' src={gmailLogo}/>
              </div>
              <div className="activity-title activity-check" title={activity.description}>
                <span>
                  {activity.description}
                </span>
              </div>
            </div>
            <div className="note-information">
              <span>{dateToText}</span>
              <span> • </span>
              <span>{activityAssignedTo(activity)}</span>
            </div>
          </div>
        </div>
        { expandedCard &&
        <div className="description">
          <ThreadActivity activity={activity}/>
        </div>
        }
      </div>
    </div>
  );
};
