import React, {useState} from 'react';
import OrdoModal from '../../components/common/OrdoModal';
import OrdoInput from '../../components/common/inputs/OrdoInput';

type AddMetrcIntegrationModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: (metricAPIKey: string) => any,
}

const AddMetrcIntegrationModal = ({isOpen, onClose, onSubmit} : AddMetrcIntegrationModalProps) => {
  const [metricAPIKey, setMetricAPIKey] = useState('');

  return (
    <OrdoModal show={isOpen}
      onClose={onClose}
      title="setup metrc integration"
      actionText='save'
      disableCTA={!metricAPIKey}
      onSubmit={() => onSubmit(metricAPIKey)}>
      <div>
        <OrdoInput inputName="title" value={metricAPIKey} placeholder="metric api key"
          label="metric api key" inputSize="large" onChange={setMetricAPIKey}/>
      </div>
    </OrdoModal>
  );
};

export default AddMetrcIntegrationModal;
