import React from 'react';
import Datetime, {TimeConstraints} from 'react-datetime';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import { Moment } from 'moment';
import 'react-datetime/css/react-datetime.css';
import {DateFormatTemplate, OrdoDate} from '../../../models/OrdoDate';
import '../../../scss/ordo/ordo-datepicker.scss';
import {BORDER_RADIUS, PRIMARY_COLOR} from '../../../constants';

export enum OrdoDatepickerDisplayMode {
  CARD ,
  SINGLE_LINE
}

export enum SelectableDates {
  ALL_TIME,
  FROM_TODAY_FORWARD,
}

type OrdoDatepickerProps = {
  placeholder?: string,
  onChange: (date: Date) => void,
  height?: string,
  value?: Date,
  displayMode: OrdoDatepickerDisplayMode,
  validDates: SelectableDates,
  timeConstraints?: TimeConstraints,
  displayTime?: boolean,
  onlyTime?: boolean,
  displayYear?: boolean
}

const NO_DATE_SELECTED = '-';

const fromTodayForward = (current: Moment) => {
  const yesterday = OrdoDate.yesterday().toDate();
  return current.isAfter(yesterday);
};

const allTime = (_current: Moment) => {
  return true;
};

const selectDateValidator = (rule: SelectableDates) => {
  switch (rule) {
  case SelectableDates.FROM_TODAY_FORWARD:
    return fromTodayForward;
  case SelectableDates.ALL_TIME:
  default:
    return allTime;
  }
};

const OrdoDatepicker  = ({placeholder, onChange, height, value, displayMode, validDates, timeConstraints, displayTime=true, onlyTime=false, displayYear=false}: OrdoDatepickerProps) => {

  const cardRenderInput = (props: any) => {
    let selectedDay = NO_DATE_SELECTED;
    let selectedDayInfo = placeholder;
    // eslint-disable-next-line react/prop-types
    const {className, ...otherProps} = props;

    if (value) {
      const date = OrdoDate.from(value);
      selectedDay = date.format(DateFormatTemplate.WEEKDAY_NAME);
      selectedDayInfo = date.format(DateFormatTemplate.FULL_DATE);
      const selectedDateClassName = `selected-date-container ${className}`;

      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={selectedDateClassName} style={{height: `${height || '100%'}`, cursor: 'pointer', borderRadius: BORDER_RADIUS}} {...otherProps} >
          <div className="d-flex flex-column">
            <span className='selected-day'>{selectedDay}</span>
            <span className='selected-day-info'>{selectedDayInfo}</span>
          </div>
        </div>
      );
    }
    const selectedDateClassName = `no-value-selected ${className}`;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div className={selectedDateClassName} style={{height: `${height || '3.15rem'}`, cursor: 'pointer'}} {...otherProps} >
        <FontAwesomeIcon color={`${PRIMARY_COLOR}`} size="1x" icon={faCalendarAlt}  style={{marginRight: '0.5em'}}/>
        <span className='not-day-selected'>{selectedDayInfo}</span>
      </div>
    );
  };

  const singleLineRenderInput = (props: any) => {
    let selectedDayInfo = placeholder;
    if (value) {
      const date = OrdoDate.from(value);
      if(displayTime) {
        if(displayYear) {
          selectedDayInfo = date.format(DateFormatTemplate.WEEKDAY_NAME_AND_MONTH_YEAR);
        } else {
          selectedDayInfo = date.format(DateFormatTemplate.DAY_DATE_TIME);
        }
      } else if(displayYear) {
        selectedDayInfo = date.format(DateFormatTemplate.DAY_OF_MONTH_DETAILED_WITH_YEAR);
      } else {
        selectedDayInfo = date.format(DateFormatTemplate.DAY_OF_MONTH_DETAILED);
      }
    }
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <div style={{padding: '0.5em', height: `${height || '100%'} `, cursor: 'pointer', border: 'none', boxShadow: 'none'}} {...props} >
        <div className="displayed-time">
          <FontAwesomeIcon className='datepicker-icon' icon={faCalendarAlt}/>
          {placeholder && <span className={`selected-day-info${value ? '' : 'placeholder'}` }>{selectedDayInfo}</span>}
        </div>
      </div>
    );
  };



  const selectDisplayMode = (mode: OrdoDatepickerDisplayMode) => {
    switch (mode){
    case OrdoDatepickerDisplayMode.SINGLE_LINE:
      return {className: 'single-line-display', renderInput: singleLineRenderInput};
    case OrdoDatepickerDisplayMode.CARD:
    default:
      return {className: 'ordo-shadow', renderInput: cardRenderInput };
    }
  };

  const displayModeInfo = selectDisplayMode(displayMode);

  const onDateChange = (moment: Moment|string) => {
    if(typeof moment === 'string') throw Error(`invalid date: ${moment}`);

    onChange(moment.toDate());
  };

  return ( <div role='presentation' className={`ordo-datepicker-container ${ displayModeInfo.className}`}>
    <Datetime
      dateFormat={!onlyTime}
      timeFormat={displayTime}
      renderInput={ displayModeInfo.renderInput }
      isValidDate={selectDateValidator(validDates)}
      onChange={onDateChange}
      timeConstraints={timeConstraints}
      closeOnSelect
      closeOnClickOutside
    />
  </div>
  );
};

export default OrdoDatepicker;
