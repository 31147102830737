import { API } from '../../lib/api/ordoApi';
import { MappingSourceName } from '../../lib/api/request-types';
import { MetrcItemResponse, ProductMappingResponse } from '../../lib/api/response-types';
import Product from '../../models/Product';

class ProductMappings {
  constructor(private mappings: ProductMappingResponse[]) {
    this.mappings = mappings;
  }

  public get(productId: string, externalLicenseId: string): ProductMappingResponse | undefined {
    const ret = this.mappings.find((mapping) => {
      return mapping.productId === productId && mapping.externalLicenseId === externalLicenseId;
    });
    return ret;
  }
}

export enum MetrcProductLinkWizardStep {
  SelectLicense = 0,
  SelectExternalItem = 1
}

export class MetrcProductLinkViewModel {
  constructor(
    public api: API,
    public step: MetrcProductLinkWizardStep,
    public selectedLicenseNumber: string,
    public productMappings: ProductMappings,
    public licenses: string[],
    public selectedProduct: Product,
    public metrcItems: MetrcItemResponse[],
    public selectedMetrcItem?: MetrcItemResponse,
    public error?: Error
  ) {
  }

  public static initialize(api: API, product: Product) {
    return new MetrcProductLinkViewModel(api, MetrcProductLinkWizardStep.SelectLicense, '', new ProductMappings([]), [], product, []);
  }

  public setProductMappings(mappings: ProductMappingResponse[]) {
    return new MetrcProductLinkViewModel(
      this.api,
      this.step,
      this.selectedLicenseNumber,
      new ProductMappings(mappings),
      this.licenses,
      this.selectedProduct,
      this.metrcItems,
      this.selectedMetrcItem,
      this.error
    );
  }

  public setLicenses(licenses: string[]) {
    return new MetrcProductLinkViewModel(
      this.api,
      this.step,
      this.selectedLicenseNumber,
      this.productMappings,
      licenses,
      this.selectedProduct,
      this.metrcItems,
      this.selectedMetrcItem,
      this.error
    );
  }

  public setSelectedLicense(licenseNumber: string) {
    return new MetrcProductLinkViewModel(
      this.api,
      this.step,
      licenseNumber,
      this.productMappings,
      this.licenses,
      this.selectedProduct,
      this.metrcItems,
      this.selectedMetrcItem,
      this.error
    );
  }

  public setSelectLicenseStep() {
    return new MetrcProductLinkViewModel(
      this.api,
      MetrcProductLinkWizardStep.SelectLicense,
      this.selectedLicenseNumber,
      this.productMappings,
      this.licenses,
      this.selectedProduct,
      this.metrcItems,
      this.selectedMetrcItem,
      this.error
    );
  }

  public setSelectItemStep() {
    return new MetrcProductLinkViewModel(
      this.api,
      MetrcProductLinkWizardStep.SelectExternalItem,
      this.selectedLicenseNumber,
      this.productMappings,
      this.licenses,
      this.selectedProduct,
      this.metrcItems,
      this.selectedMetrcItem,
      this.error
    );
  }

  public setMetrcItems(items: MetrcItemResponse[]) {
    return new MetrcProductLinkViewModel(
      this.api,
      this.step,
      this.selectedLicenseNumber,
      this.productMappings,
      this.licenses,
      this.selectedProduct,
      items,
      this.selectedMetrcItem,
      this.error
    );
  }

  public setSelectedMetrcItem(item: MetrcItemResponse) {
    return new MetrcProductLinkViewModel(
      this.api,
      this.step,
      this.selectedLicenseNumber,
      this.productMappings,
      this.licenses,
      this.selectedProduct,
      this.metrcItems,
      item,
      this.error
    );
  }

  public async submitProductMapping(orgId: string) {
    // return with updated error
    let error;
    try {
      await this.api.setMetrcProductMappings(
        orgId,
        this.selectedProduct.id,
        this.selectedMetrcItem?.Id.toString() || '',
        this.selectedLicenseNumber
      );
    } catch (err) {
      error = err as Error;
    }
    return new MetrcProductLinkViewModel(
      this.api,
      this.step,
      this.selectedLicenseNumber,
      this.productMappings,
      this.licenses,
      this.selectedProduct,
      this.metrcItems,
      this.selectedMetrcItem,
      error
    );
  };

  public getPrimaryButtonText(): string {
    switch (this.step) {
    case MetrcProductLinkWizardStep.SelectExternalItem:
      return 'save';
    default:
      return 'continue';
    }
  }

  public canProceed(): boolean {
    switch (this.step) {
    case MetrcProductLinkWizardStep.SelectExternalItem:
      return true; // TODO (mk): actual validation
    default:
      return this.selectedLicenseNumber !== '';
    }
  }

  public getSubtitle(): string {
    switch(this.step) {
    case MetrcProductLinkWizardStep.SelectExternalItem:
      return 'Choose Metrc Item to Link';
    default:
      return 'Choose License to Link an Item';
    }
  }

  public getModalSize(): 'lg' | 'sm' {
    switch(this.step) {
    case MetrcProductLinkWizardStep.SelectExternalItem:
      return 'lg';
    default:
      return 'sm';
    }
  }

  public mappings(): ProductMappingResponse[] {
    // We need to create one row per license. Also check to see if it is already associated with a license
    return this.licenses.map((licenseNumber) => {
      const mapping = this.productMappings.get(this.selectedProduct.id, licenseNumber);
      return {
        externalId: mapping?.externalId || 'none',
        externalLicenseId: licenseNumber,
        productId: this.selectedProduct.id,
        source: mapping?.source || MappingSourceName.METRC
      };
    });
  }

  public linkProduct(externalId: string) {
    if (!this.readyToLink()) {
      this.error = new Error('must select a license number and a product before linking');
      return;
    }

    try {
      // eslint-disable-next-line no-console
      console.log(`linking ${this.selectedProduct?.id} to ${externalId}`);

    } catch(err) {
      this.error = new Error('unable to link product at this time. please try again later');
    }
  }

  private readyToLink() {
    return (this.selectedLicenseNumber !== '' && this.selectedProduct !== undefined);
  }
}
