import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faClock} from '@fortawesome/free-solid-svg-icons';
import {
  ActivityCompletionResponseDisplayProps,
  DateTimeActivityCompletionResponse,
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import {DateFormatTemplate, OrdoDate} from '../../../../models/OrdoDate';


export const DateTimeResponseDisplay = ({
  response,
}: ActivityCompletionResponseDisplayProps) => {
  const dateTimeResponse = response as DateTimeActivityCompletionResponse;
  const {date, time} = dateTimeResponse.body;
  const selectedDayInfo = OrdoDate.from(date!).format(DateFormatTemplate.DAY_OF_MONTH_DETAILED);

  return <div className='datetime-response'>
    <FontAwesomeIcon className='datepicker-icon' icon={faCalendarAlt}/>
    <span className="selected-day-info">{selectedDayInfo}</span>
    {time && <div className='time-display'>
      <FontAwesomeIcon className='datepicker-icon' icon={faClock}/>
      <span className="selected-day-info">{OrdoDate.from(time!).format(DateFormatTemplate.HOURS_AND_MINUTES)}</span>
    </div>}
  </div>;
};