import React, {useState} from 'react';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import '../../../scss/ordo/integrations/metrc-integration.scss';
import metrcLogo from '../metrc.png';
import ConfirmationModal from '../../components/common/ConfirmationModal';

export type MetrcIntegration = {
  apiKey: string,
  licenseNumbers: string[],
}

type MetrcIntegrationRowProps = {
  integration: MetrcIntegration,
  onDeleteIntegration: Function
};

const MetrcIntegrationRow = ({integration, onDeleteIntegration}: MetrcIntegrationRowProps) => {
  const [openedDeleteIntegrationModal, setOpenedIntegrationContactModal] = useState(false);
  const openDeleteIntegrationModal = () => setOpenedIntegrationContactModal(true);
  const closeDeleteIntegrationModal = () => setOpenedIntegrationContactModal(false);

  const integrationDelete = () => onDeleteIntegration(integration);

  return(<div className="metrc-integration">
    <div className="metrc-logo-and-api-key">
      <div className="metrc-logo">
        <img role='presentation' className="metrc-icon" alt='metrc-icon' src={metrcLogo}/>
      </div>
      <div className="metric-api-key-information">
        <span className="title">metric api key</span>
        <span className="key">{integration.apiKey}</span>
      </div>
    </div>
    <div className="enabled-licenses">
      <span className="title">enabled licenses</span>
      <span className="licenses">{integration.licenseNumbers.slice(0,7).map(license => license).join(', ')} <b>{integration.licenseNumbers.length > 7 ? ` +${integration.licenseNumbers.length - 7} more` : ''}</b></span>
    </div>
    <div className="delete-metrc-integration">
      <FontAwesomeIcon onClick={openDeleteIntegrationModal} className="delete-icon" size="lg" icon={faTrashAlt}/>
    </div>
    {openedDeleteIntegrationModal &&
    <ConfirmationModal
      show={openedDeleteIntegrationModal}
      onClose={closeDeleteIntegrationModal}
      onSubmit={integrationDelete}
      confirmationText="are you sure you want to delete this API key?"
      actionText='delete'
    />}
  </div>);
};

export default MetrcIntegrationRow;
