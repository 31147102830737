import { Brand } from './Brand';
import { ActualMember } from './member/ActualMember';
import {NotMember} from './member/NotMember';
import {OrganizationLicense} from './OrganizationLicense';
import {User} from './User';
import {NoSubscription, Subscription} from './Subscription';
import { MappingSourceName } from '../lib/api/request-types';

export type OrganizationRegistrationInput = {
  id: string,
  name: string,
  size: string,
  state: string,
  termsOfUseAccepted: boolean,
}

export type ExternalApiConfig = {
  id: string,
  configSourceName: MappingSourceName,
}

export type Organization = {
  id: string,
  name: string,
  licenses: OrganizationLicense[],
  size: string,
  state: string,
  status?: ORGANIZATION_STATUS,
  brands: Brand[],
  members: ActualMember[],
  pendingInvitations: NotMember[],
  owner: User,
  subscription: Subscription,
  externalApiConfigs: ExternalApiConfig[],
}

export enum ORGANIZATION_STATUS {
  PENDING = 'Pending',
  VERIFIED = 'Verified'
}

export const emptyOrganization: Organization = {
  id: '',
  name: '',
  licenses: [],
  size: '',
  state: '',
  status: ORGANIZATION_STATUS.PENDING,
  brands: [],
  members: [],
  pendingInvitations: [],
  owner: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    id: '',
  },
  subscription: NoSubscription,
  externalApiConfigs: [],
};
