import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';
import ComplianceTabViewModel from '../../../application-models/ComplianceTabViewModel';
import {OrganizationLicense} from '../../../models/OrganizationLicense';
import OrdoPageSubTitle from '../../components/common/OrdoPageSubTitle';
import {CreateOrganizationLicenseModal} from '../CreateOrgLicenseModal';
import {CreateOrganizationLicenseInput} from '../../../application-models/organization-page/CreateOrganizationLicenseViewModel';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {BORDER_RADIUS} from '../../../constants';
import '../../../scss/ordo/organizationPage/compliance-page.scss';
import MetrcIntegrationRow, {MetrcIntegration} from './MetrcIntegrationRow';
import {useAPI} from '../../../context/OrdoApiContext';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import {UserSessionContext} from '../../../context/UserSessionContext';
import OrdoSpinner from '../../components/OrdoSpinner';
import {displayFlourishIntegration, displayMetrcIntegration, displayNabisIntegration} from '../../../lib/featureFlags';
import AddMetrcIntegrationModal from './AddMetrcIntegrationModal';
import AddFlourishIntegrationModal from './AddFlourishIntegrationModal';
import FlourishIntegrationRow, {FlourishIntegration} from './FlourishIntegrationRow';
import NabisIntegrationRow, {NabisIntegration} from './NabisIntegrationRow';
import AddNabisIntegrationModal from './AddNabisIntegrationModal';
import {UserSessionRefresher} from '../../../context/UserSessionRefresher';
import {useFeatureFlags} from '../../../context/FeatureFlagsContext';

type ComplianceTabProps = {
  refresher: UserSessionRefresher,
}

const ComplianceTab = ({refresher}: ComplianceTabProps) => {
  const userSessionContextData = useContext(UserSessionContext);
  const api = useAPI();
  const featureFlags = useFeatureFlags();

  const [showAddOrgLicenseModal, setShowAddOrgLicenseModal] = useState(false);
  const [showAddMetrcIntegrationModal, setShowAddMetrcIntegrationModal] = useState(false);
  const [showAddFlourishIntegrationModal, setShowAddFlourishIntegrationModal] = useState(false);
  const [showAddNabisIntegrationModal, setShowAddNabisIntegrationModal] = useState(false);
  const [complianceViewModel, setComplianceViewModel] = useState(ComplianceTabViewModel.empty(api, userSessionContextData.currentOrganization()!.id));

  const cardStyle: React.CSSProperties = {
    border: '3px',
    borderRadius: BORDER_RADIUS,
    margin: '3em',
    width: '20%'
  };

  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  useEffect(() => {
    showSpinner();
    Promise.all([
      ComplianceTabViewModel.initialize(api, userSessionContextData.currentOrganization()!.id).then(setComplianceViewModel)
    ]).finally(hideSpinner);
  }, []);


  const toggleAddOrgLicenseModal = () => setShowAddOrgLicenseModal(!showAddOrgLicenseModal);
  const toggleAddMetrcIntegrationModal = () => setShowAddMetrcIntegrationModal(!showAddMetrcIntegrationModal);
  const toggleAddFlourishIntegrationModal = () => setShowAddFlourishIntegrationModal(!showAddFlourishIntegrationModal);
  const toggleAddNabisIntegrationModal = () => setShowAddNabisIntegrationModal(!showAddNabisIntegrationModal);

  const closeOrgLicenseModal = () => setShowAddOrgLicenseModal(false);
  const {successToast, errorToast} = useOrdoToasts();

  const addMetrcIntegration = (metricAPIKey: string) => {
    showSpinner();
    complianceViewModel.addMetrcIntegration(metricAPIKey)
      .then((updatedViewModel) => {
        setComplianceViewModel(updatedViewModel);
        successToast('Metrc integration successfully created');
      })
      .catch((error) => {
        errorToast(error.message || 'Could not create Metrc integration');
      })
      .finally(() => {
        toggleAddMetrcIntegrationModal();
        hideSpinner();
      });
  };

  const addFlourishIntegration = (flourishAPIUsername:string, flourishAPIKey: string, syncInventoryOrItems: string) => {
    showSpinner();
    complianceViewModel.addFlourishIntegration(flourishAPIUsername, flourishAPIKey, syncInventoryOrItems)
      .then((updatedViewModel) => {
        setComplianceViewModel(updatedViewModel);
        successToast('Flourish integration successfully updated');
      })
      .catch((error) => {
        errorToast(error.message || 'Could not update Flourish integration');
      })
      .finally(() => {
        toggleAddFlourishIntegrationModal();
        hideSpinner();
      });
  };

  const addNabisIntegration = (nabisAPIKey: string) => {
    showSpinner();
    complianceViewModel.addNabisIntegration(nabisAPIKey)
      .then((updatedViewModel) => {
        setComplianceViewModel(updatedViewModel);
        refresher.refresh();
        successToast('Nabis integration successfully updated');
      })
      .catch((error) => {
        errorToast(error.message || 'Could not update Nabis integration');
      })
      .finally(() => {
        toggleAddNabisIntegrationModal();
        hideSpinner();
      });
  };

  const updateFlourishIntegration = (configId: string, flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string) => {
    showSpinner();
    return complianceViewModel.updateFlourishIntegration(configId, flourishAPIUsername, flourishAPIKey, syncInventoryOrItems)
      .then((updatedViewModel) => {
        setComplianceViewModel(updatedViewModel);
        successToast('Flourish integration successfully updated');
      })
      .catch((error) => {
        errorToast(error.message || 'Could not update Flourish integration');
      })
      .finally(() => {
        // TODO: close Edit modal
        hideSpinner();
      });
  };

  const updateNabisIntegration = (configId: string, nabisAPIKey: string) => {
    showSpinner();
    return complianceViewModel.updateNabisIntegration(configId, nabisAPIKey)
      .then((updatedViewModel) => {
        setComplianceViewModel(updatedViewModel);
        successToast('Nabis integration successfully updated');
      })
      .catch((error) => {
        errorToast(error.message || 'Could not update Nabis integration');
      })
      .finally(() => {
        // TODO: close Edit modal
        hideSpinner();
      });
  };

  const renderCard = (item: OrganizationLicense) => {
    const pendingTagStyle: React.CSSProperties = {
      position: 'absolute',
      right: '10px',
      fontWeight: 'bold',
      color: 'lightgrey'
    };

    const pendingTag = item.status.toLowerCase() === 'pending' ? 'pending' : '';
    return (
      <div key={ item.license.licenseNumber } className='card' style={cardStyle}>
        <div className='card-body'>
          <h6>{ item.license.name }</h6>
          <span> { item.license.licenseNumber} </span>
          <span style={pendingTagStyle}> {pendingTag} </span>
        </div>
      </div>
    );
  };

  const renderLicenses = () => {
    const licenses = complianceViewModel.organizationLicenses.map(renderCard);
    const onSubmit = async (orgLicenseData: CreateOrganizationLicenseInput) => {
      const updated = await complianceViewModel.createOrganizationLicense(orgLicenseData);
      setComplianceViewModel(updated);
      if (!updated.error.hasError()) {
        closeOrgLicenseModal();
        const newViewModel = await ComplianceTabViewModel.initialize(updated.api, updated.orgId);
        setComplianceViewModel(newViewModel);
        successToast('License successfully created');
      } else {
        errorToast(`${ complianceViewModel.error.errorMessage('licenseNumber') || 'Could not create the license'} `);
      }
    };

    return (
      <OrdoSpinner showSpinner={spinnerVisibility}>
        <div>
          <div className='d-flex flex-row justify-content-start align-items-center' >
            <OrdoPageSubTitle title='licenses'/>
            <FontAwesomeIcon
              data-testid='create-license-button'
              className='mr-3 add-icon'
              size='lg'
              icon={faPlusCircle}
              style={{marginLeft: '20px'}}
              onClick={toggleAddOrgLicenseModal}
            />
            {showAddOrgLicenseModal && <CreateOrganizationLicenseModal
              onSubmit={onSubmit}
              isOpen={showAddOrgLicenseModal}
              onClose={() => {
                closeOrgLicenseModal();
                setComplianceViewModel(complianceViewModel.withoutErrors());
              }}
              licenseNumberError={complianceViewModel.error.errorMessage('licenseNumber')}
              organizationState={userSessionContextData.currentOrganization()!.state}
            />}
          </div>
          <div className='row'>
            { licenses }
          </div>
        </div>
      </OrdoSpinner>
    );
  };

  const deleteMetrcIntegration = (integration: MetrcIntegration) => {
    showSpinner();
    complianceViewModel.deleteMetrcIntegration(integration)
      .then(setComplianceViewModel)
      .catch((error) => errorToast(error.message))
      .finally(hideSpinner);
  };

  const deleteFlourishIntegration = (integration: FlourishIntegration) => {
    showSpinner();
    complianceViewModel.deleteFlourishIntegration(integration)
      .then(setComplianceViewModel)
      .catch((error) => errorToast(error.message))
      .finally(hideSpinner);
  };

  const deleteNabisIntegration = (integration: NabisIntegration) => {
    showSpinner();
    complianceViewModel.deleteNabisIntegration(integration)
      .then((updatedViewModel) => {
        setComplianceViewModel(updatedViewModel);
        refresher.refresh();
      })
      .catch((error) => errorToast(error.message))
      .finally(hideSpinner);
  };

  const renderMetrcIntegrations = () => {
    if (displayMetrcIntegration(featureFlags)) {
      return (
        <div className='metric-integrations-section' >
          <div className="title-and-button">
            <OrdoPageSubTitle title='metrc integration'/>
            <FontAwesomeIcon
              data-testid='create-license-button'
              className='mr-3 add-icon'
              size='lg'
              icon={faPlusCircle}
              style={{marginLeft: '20px'}}
              onClick={toggleAddMetrcIntegrationModal}
            />
          </div>
          <div className="metrc-integrations-container">
            {complianceViewModel.getMetrcIntegrations().map((i: MetrcIntegration) =>  <MetrcIntegrationRow integration={i} onDeleteIntegration={deleteMetrcIntegration} key={v4()}/>)}
          </div>
          {showAddMetrcIntegrationModal &&
          <AddMetrcIntegrationModal
            isOpen={showAddMetrcIntegrationModal}
            onSubmit={addMetrcIntegration}
            onClose={toggleAddMetrcIntegrationModal}
          />}
        </div>
      );
    }
    return <div/>;
  };

  const renderFlourishIntegrations = () => {
    if (displayFlourishIntegration(featureFlags)) {
      return (
        <div className='flourish-integrations-section' >
          <div className="title-and-button">
            <OrdoPageSubTitle title='flourish integration'/>
            {complianceViewModel.getFlourishIntegrations().length === 0 && <FontAwesomeIcon
              data-testid='create-license-button'
              className='mr-3 add-icon'
              size='lg'
              icon={faPlusCircle}
              style={{marginLeft: '20px'}}
              onClick={toggleAddFlourishIntegrationModal}
            />}
          </div>
          <div className="flourish-integrations-container">
            {complianceViewModel.getFlourishIntegrations().map((i: FlourishIntegration) =>  <FlourishIntegrationRow key={i.id} integration={i} onDeleteIntegration={deleteFlourishIntegration} onUpdateIntegration={updateFlourishIntegration}/>)}
          </div>
          {showAddFlourishIntegrationModal &&
          <AddFlourishIntegrationModal
            isOpen={showAddFlourishIntegrationModal}
            onSubmit={addFlourishIntegration}
            onClose={toggleAddFlourishIntegrationModal}
          />}
        </div>
      );
    }
    return <div/>;
  };

  const renderNabisIntegrations = () => {
    if (displayNabisIntegration(featureFlags)) {
      return (
        <div className='nabis-integrations-section' >
          <div className="title-and-button">
            <OrdoPageSubTitle title='nabis integration'/>
            <FontAwesomeIcon
              data-testid='create-license-button'
              className='mr-3 add-icon'
              size='lg'
              icon={faPlusCircle}
              style={{marginLeft: '20px'}}
              onClick={toggleAddNabisIntegrationModal}
            />
          </div>
          <div className="nabis-integrations-container">
            {complianceViewModel.getNabisIntegrations().map((i: NabisIntegration) =>
              <NabisIntegrationRow
                integration={i} key={i.id}
                onDeleteIntegration={deleteNabisIntegration}
                onUpdateIntegration={updateNabisIntegration}
              />)}
          </div>
          {showAddNabisIntegrationModal &&
          <AddNabisIntegrationModal
            isOpen={showAddNabisIntegrationModal}
            onSubmit={addNabisIntegration}
            onClose={toggleAddNabisIntegrationModal}
          />}
        </div>
      );
    }
    return <div/>;
  };

  return (
    <div className='col'>
      { renderLicenses() }
      { renderFlourishIntegrations() }
      { renderMetrcIntegrations() }
      { renderNabisIntegrations() }
    </div>
  );
};

export default ComplianceTab;
