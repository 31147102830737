import {DateFormatTemplate, OrdoDate} from './OrdoDate';
import {TimelineItem} from './AccountTimeline';

export type TimelineSubdivision = {
  timeSlot: TimeSlot
  items: TimelineItem[]
};

export class TimeSlot {
  public isInRange(_date: OrdoDate): boolean {
    throw Error('Must be implemented by subclass');
  }

  public slotContent(items: TimelineItem[]): TimelineSubdivision {
    const compareFn = (a: TimelineItem, b: TimelineItem) => a.date().isAfter(b.date()) ? -1 : 1;
    return {
      timeSlot: this,
      items: items.filter(item => this.isInRange(item.date())).sort(compareFn ),
    };
  }

  public includedDates(): string {
    throw Error('Must be implemented by subclass');
  }
}

export class PastTimeSlot extends TimeSlot {
  start: OrdoDate;
  end: OrdoDate;

  constructor(start: OrdoDate, end: OrdoDate, public readonly index: number) {
    super();
    this.start = start;
    this.end = end;
  }

  public isInRange(date: OrdoDate): boolean {
    return (this.start.beforeThan(date) || this.start.isSameDate(date)) && this.end.isAfter(date);
  }

  public includedDates() {
    const includedEnd = this.end.subtract(1,'day');
    const difference = includedEnd.difference(this.start, 'day');
    if(difference === 0) return this.start.format(DateFormatTemplate.WEEKDAY_NAME_AND_MONTH_YEAR);
    return `${this.start.format(DateFormatTemplate.WEEKDAY_NAME_AND_MONTH_YEAR)  } - ${includedEnd.format(DateFormatTemplate.WEEKDAY_NAME_AND_MONTH_YEAR)}`;
  }
}

export class FutureTimeSlot extends TimeSlot {
  start: OrdoDate;

  constructor(start: OrdoDate) {
    super();
    this.start = start;
  }

  public isInRange(date: OrdoDate): boolean {
    return date.isAfter(this.start.subtract(1,'day'));
  }

  public includedDates() {
    return `After ${this.start.format(DateFormatTemplate.WEEKDAY_NAME_AND_MONTH_YEAR)}`;
  }
}
