import {groupBy} from 'lodash';
import {ProductWithAvailability} from '../productWithAvailability';
import {DiscountType, LineItem, LineItemType, LineItemWithTax} from './Order';
import {CartItem, CartItemType, SerializableCartItem} from './CartItem';
import {Money} from '../Money';
import {ItemLine} from './ItemLine';
import {isValidDiscountPrice, isValidFractionDiscountPercentage} from './OrderValidator';
import OrderWithCurrentVersion from './OrderWithCurrentVersion';
import {AccountLocation} from '../Account';

export type SerializableCart = {
  accountLocation?: AccountLocation
  items: SerializableCartItem[]
}

export type ProductLine = {
  item: CartItem
  line: ItemLine
  index: number
};

export type Discount = {
  type: DiscountType
  percentage: number
  currencyAmount: Money
}

export function emptyDiscount (): Discount {
  return {
    type: DiscountType.None,
    percentage: 0,
    currencyAmount: new Money(0)
  };
}

export class Cart {
  public static emptyCart() : Cart {
    return new Cart([], emptyDiscount());
  }

  public static recreateFrom(storedCart: SerializableCart, products: ProductWithAvailability[]) {
    const items: CartItem[] = storedCart.items.map((storedItem: SerializableCartItem) => {
      const correspondingProduct = products.find(product => product.id === storedItem.productId);
      // We will handle in a different ticket what happens if the product isn't found
      return CartItem.recreateFrom(storedItem, correspondingProduct!);
    });
    return new Cart(items, emptyDiscount(), storedCart.accountLocation);
  }

  public static recreateFromOrder(order: OrderWithCurrentVersion, products: ProductWithAvailability[], selectedAccountLocation: AccountLocation | undefined) {
    const items = groupBy(order.currentVersion.items, (item) => `${item.product.id}-${item.type}`);
    const cartItems: CartItem[] = Object.keys(items).map(aKey => CartItem.recreateFromLineItems(items[aKey], products));
    const orderDiscount: Discount = {
      type: order.currentVersion.discountType,
      percentage: order.currentVersion.discountPercentage,
      currencyAmount: order.currentVersion.discountCurrencyAmount,
    };
    return new Cart(cartItems, orderDiscount, selectedAccountLocation);
  }

  constructor(
    public items: CartItem[],
    private wholeOrderDiscount: Discount,
    public accountLocation?: AccountLocation) {
  }

  public updateItems(product: ProductWithAvailability,quantity: number, type: CartItemType){
    const existingItem = this.findItemOfProductAndType(product.id, type);
    if(existingItem) {
      existingItem.updateProductQuantity(quantity);
    } else {
      const price = product.calculatePricePerItemWithTier(type);
      this.items.push(CartItem.create(product, quantity, type, price));
    }
    this.removeItemsWithZeroQuantity(quantity);
  }

  public amountOfItemTypeForProduct(productId: string, orderType: CartItemType) {
    const cartItem = this.findItemOfProductAndType(productId, orderType);
    return cartItem?.quantity || 0;
  }

  public calculatedSubtotal(): Money {
    let total = new Money(0);
    this.items.forEach((item) => {
      item.lines.forEach((lineItem) => {
        total = total.add(lineItem.total());
      });
    });
    return total;
  }

  public canCompleteOrder() {
    return this.amountOfItems() > 0 && this.validOrderQuantity() && this.noZeroQuantities() && this.hasNoDeletedProducts();
  }

  public noZeroQuantities() {
    return this.items.every((item) => item.noZeroQuantityLines());
  };

  public amountOfItems() {
    return this.items.reduce((accumulated, item) => {
      return accumulated + item.quantity;
    }, 0);
  }

  public validOrderQuantity() {
    return this.cartProducts().every(product => this.amountWithinStock(product));
  }

  public content() {
    return this.items;
  }

  public unitItems(): ProductLine[] {
    return this.items.filter(item => item.type === CartItemType.UNIT).flatMap((item: CartItem) => {
      return item.lines.map((line, index) => {
        return {
          item: item,
          line: line,
          index: index
        };
      });
    });
  }

  public caseItems(): ProductLine[] {
    return this.items.filter(item => item.type === CartItemType.CASE).flatMap((item: CartItem) => {
      return item.lines.map((line, index) => {
        return {
          item: item,
          line: line,
          index: index
        };
      });
    });
  }

  public accountLocationChanged(accountLocation: AccountLocation, productsWithUpdatedAvailability: ProductWithAvailability[]) {
    this.accountLocation = accountLocation;
    this.availabilityChanged(productsWithUpdatedAvailability);
  }

  public availabilityChanged(productsWithUpdatedAvailability: ProductWithAvailability[]) {
    this.items = this.items.map((cartItem) => {
      const updatedAddedProduct = productsWithUpdatedAvailability.find(product => product.id === cartItem.product.id);
      if(updatedAddedProduct){
        return cartItem.updateProduct(updatedAddedProduct);
      }
      //  TODO: We would handle the case in which a product in the cart isn't present anymore in OR-284
      return cartItem;
    });
  }

  public generateLineItems(): LineItem[] {
    return this.items.flatMap(item => {
      return item.lines.map((line: ItemLine) => {
        return {
          id: line.id,
          productId: item.product.id,
          quantity: line.getQuantity(),
          price: line.getPrice(),
          discountPercentage: (line.getDiscountPercentage()),
          discountCurrencyAmount: line.getDiscountCurrencyAmount(),
          discountType: line.getDiscountType(),
          sample: item.type === CartItemType.SAMPLE,
          type: Cart.getLineItemType(item),
          caseSize: item.product.unitsPerCase,
          consumerUnitSizeAmount: item.product.size?.amount || undefined,
          consumerUnitSizeUnit: item.product.size?.unit || undefined,
          tierId: item.product.tierId,
          tierPriceApplied: item.product.tierId ? Money.FromDollarAmount(item.product.unitPrice) : undefined,
          notes: line.getNotes()
        };
      });
    });
  }

  public clearItems() {
    this.items = [];
    this.accountLocation = undefined;
  };

  public newLine(item: CartItem) {
    item.newLine();
  }

  public noLinesLeftAtZero() {
    return !this.items.some(item => item.lines.some(line => line.getQuantity() <= 0));
  }

  public hasValidDiscounts(): boolean {
    const validItemDiscounts = this.items.every(item =>
      item.lines.every((line) =>
        !line.discountExceedsPrice()
      )
    );
    const validWholeOrderDiscount = !this.discountExceedsPrice();
    return validItemDiscounts && validWholeOrderDiscount;
  }

  public updateLineItemTax(itemsWithTax: LineItemWithTax[]) {
    this.items.forEach((item) => {
      item.lines.forEach((line) => {
        const updatedLine = itemsWithTax.find((x) => x.id === line.id);
        if (!updatedLine) return;
        line.setTaxes(updatedLine.exciseTax, updatedLine.salesTax);
      });
    });
  }

  public getDiscountType(): DiscountType {
    return this.wholeOrderDiscount.type;
  }

  public getDiscountCurrencyAmount(): Money {
    return this.wholeOrderDiscount.currencyAmount;
  }

  public getDiscountPercentage(): number {
    return this.wholeOrderDiscount.percentage;
  }

  public discountExceedsPrice(): boolean {
    switch (this.wholeOrderDiscount.type) {
    case DiscountType.Dollar:
      return this.wholeOrderDiscount.currencyAmount.isGreaterThan(this.calculatedSubtotal());
    case DiscountType.Percentage:
      return !isValidFractionDiscountPercentage(this.wholeOrderDiscount.percentage);
    default:
      return false;
    }
  }

  public totalWithoutDiscount(): Money {
    return this.calculatedSubtotal();
  }

  public discountInputValue(): number {
    switch (this.wholeOrderDiscount.type) {
    case DiscountType.Dollar:
      return this.wholeOrderDiscount.currencyAmount.toUnit();
    case DiscountType.Percentage:
      return this.wholeOrderDiscount.percentage;
    default:
      return 0;
    }
  }

  public setDiscountType(type: DiscountType) {
    this.wholeOrderDiscount.type = type;
  }

  public setDiscount(discount: number) {
    switch (this.wholeOrderDiscount.type) {
    case DiscountType.Dollar:
      if (!isValidDiscountPrice(discount, this.totalWithoutDiscount())) return;
      this.wholeOrderDiscount.currencyAmount = Money.FromDollarAmount(discount);
      break;
    case DiscountType.Percentage:
      if (!isValidFractionDiscountPercentage(discount)) return;
      this.wholeOrderDiscount.percentage = discount;
      break;
    default:
      // Nothing to do
    }

    if (discount === 0) {
      this.setDiscountType(DiscountType.None);
    }
  }

  public allCaseLinesFromProduct(product: ProductWithAvailability) {
    return this.items.filter(cartItem => cartItem.product.id === product.id && cartItem.type === CartItemType.CASE).flatMap(item => item.lines);
  }

  public allUnitLinesFromProduct(product: ProductWithAvailability) {
    return this.items.filter(cartItem => cartItem.product.id === product.id && cartItem.type === CartItemType.UNIT).flatMap(item => item.lines);
  }

  public numberOfProductsInCart() {
    const productsIds = this.items.map(item => item.product.id);
    return (new Set(productsIds)).size;
  }

  private static getLineItemType(line: CartItem) {
    if(line.type === CartItemType.UNIT) {
      return LineItemType.Unit;
    }
    return line.type === CartItemType.CASE ? LineItemType.Case : LineItemType.Sample;
  }

  private removeItemsWithZeroQuantity(quantity: number) {
    if (quantity === 0) this.items = this.items.filter(cartItem => cartItem.quantity > 0);
  }

  private findItemOfProductAndType(productId: string, orderType: CartItemType) {
    return this.items.find(item => item.product.id === productId && item.type === orderType);
  }

  private cartProducts() {
    const products = this.items.map(item => item.product);
    return Array.from(new Set(products));
  }

  private amountWithinStock(product: ProductWithAvailability) {
    const unitsItem = this.findItemOfProductAndType(product.id, CartItemType.UNIT);
    const casesItem = this.findItemOfProductAndType(product.id, CartItemType.CASE);

    let amountOfUnits = unitsItem ? unitsItem.quantity : 0;
    amountOfUnits += casesItem ? casesItem.quantity*product.unitsPerCase : 0;

    return amountOfUnits <= product.availability;
  }

  public hasNoDeletedProducts(): boolean {
    return this.items.every(i => !i.product.deletedAt);
  }
}
