import {v4} from 'uuid';
import {
  AccountActivityResponse,
  AccountLocationResponse, AccountRepAssignmentResponse,
  AccountResponse,
  AccountStatusResponse,
  ActivityItemResponse,
  ActivityResponse,
  ActivityResponseResponse,
  ActivityTemplateResponse,
  ActivityTemplateWithCountResponse,
  AllocationResponse,
  BatchResponse,
  ContactResponse,
  ContactWithAccountNameResponse,
  ExternalApiConfigResponse,
  ExternalEmailActivityResponse,
  LicenseResponse,
  LocationResponse,
  NoteResponse, OrderModelResponse, OrderPaymentResponse, OrderResponse,
  OrderStatusResponse,
  OrderVersionLineItemResponse,
  OrderVersionResponse,
  OrderWithCurrentVersionResponse,
  OrganizationLicenseResponse,
  OrganizationResponse, PendingInvitationResponse, PendingPaymentOrderResponse,
  PipelineDataResponse,
  ProductResponse,
  ProductWithAvailabilityResponse,
  RepAssignmentResponse,
  SubscriptionResponse, TierResponse,
  UserResponse,
  UserSessionResponse
} from './response-types';
import {Allocation} from '../../models/allocation/allocation';
import {AccountAllocation} from '../../models/allocation/accountAllocation';
import {User} from '../../models/User';
import {OrgMemberAllocation} from '../../models/allocation/orgMemberAllocation';
import Account, {AccountLocation} from '../../models/Account';
import {
  AccountStatus,
  PipelineData,
  SalesActivityAccount,
  SalesActivityOrder
} from '../../pages/sales-activity/salesActivityTypes';
import {DateFormatTemplate, OrdoDate} from '../../models/OrdoDate';
import Batch from '../../models/Batch';
import Product from '../../models/Product';
import ProductCategory from '../../models/ProductCategory';
import {ProductWithAvailability} from '../../models/productWithAvailability';
import {emptyOrganizationLicense, License, OrganizationLicense} from '../../models/OrganizationLicense';
import Contact, {emptyContact} from '../../models/order-entry/Contact';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import {Money} from '../../models/Money';
import {OrderStatus, OrderVersion} from '../../models/order-entry/Order';
import {OrderSummaryFrom} from '../../models/order-entry/Summary';
import {OrderVersionLineItem} from '../../models/order-entry/OrderVersionLineItem';
import {ExternalApiConfig, Organization} from '../../models/Organization';
import {ActualMember} from '../../models/member/ActualMember';
import {NotMember, NotMemberInput} from '../../models/member/NotMember';
import {UserSession} from '../../models/UserSession';
import {Note} from '../../models/Note';
import {ActivityQuestionType} from '../../models/sales-activity/ActivityQuestion';
import {
  DateTimeActivityCompletionResponse, FileURLActivityCompletionResponse,
  MultiValueActivityCompletionResponse,
  SingleValueActivityCompletionResponse
} from '../../models/activity/responses/ActivityCompletionResponse';
import {
  Activity,
  ActivityItem,
  ActivityTemplate,
  ActivityTemplateWithCount,
  ExternalEmailActivity
} from '../../models/sales-activity/Activity';
import {ActivityTypeName, convertFromBackendActivityType} from '../../models/sales-activity/ActivityTypeName';
import {Subscription} from '../../models/Subscription';
import {AccountRepAssignment} from '../../models/AccountRepAssignment';
import {AccountActivityEntity, AccountActivityType} from '../../models/AccountActivity';
import {Tier} from '../../models/custom-pricing-tiers/Tier';
import {OrderPayment} from '../../models/order-payment/OrderPayment';
import {PendingPaymentOrder} from '../../models/order-payment/PendingPaymentOrder';
import {PendingInvitation} from '../../models/PendingInvitation';

export class OrdoDomainConverter {

  public convertToAllocations(allocationResp: AllocationResponse): Allocation {
    if (!allocationResp.assignedTo) {
      // eslint-disable-next-line no-console
      console.log('This allocations is missing account and sales rep field: ', allocationResp);
      throw Error('unable to bring allocations');
    }

    if (allocationResp.assignedToAccount) {
      const accountResponse = allocationResp.assignedTo as AccountResponse;
      const assignedTo = this.convertToAccount(accountResponse);
      return new AccountAllocation(
        allocationResp.quantity,
        allocationResp.productId,
        assignedTo,
        allocationResp.distributorOrganizationLicenseId,
        allocationResp.id,
      );
    }

    const assignedTo = allocationResp.assignedTo as User;
    return new OrgMemberAllocation(
      allocationResp.quantity,
      allocationResp.productId,
      assignedTo,
      allocationResp.distributorOrganizationLicenseId,
      allocationResp.id,
    );
  }

  public convertToDomainUser(userResp: UserResponse): User {
    return {
      email: userResp.email,
      id: userResp.id,
      lastName: userResp.lastName,
      phone: userResp.phone,
      firstName: userResp.firstName,
      currentOrganizationId: userResp.currentOrganizationId || undefined,
    };
  }

  public convertToAccount(accountResponse: AccountResponse) {
    return new Account({
      id: accountResponse.id,
      name: accountResponse.name,
      contacts: (accountResponse.contacts || []).map((contactResponse) => this.convertToDomainContact(contactResponse)),
      notes: (accountResponse.notes || []).map((noteResponse) => this.convertToNote(noteResponse)),
      activities: (accountResponse.activities || []).map((activityResponse) => this.convertToActivity(activityResponse)),
      stalePeriod: accountResponse.stalePeriod,
      priority: accountResponse.priority,
      value: accountResponse.value,
      conversion: accountResponse.conversion,
      locations: (accountResponse.locations  || []).map(accountLocation => this.convertToAccountLocation(accountLocation)),
      allocations: (accountResponse.allocations || []).map((allocationResponse) => this.convertToAllocations(allocationResponse)),
      assignments: [], // Dev note: It seems this field was not being used for ex Retailers.
      organizationId: accountResponse.organizationId,
      active: accountResponse.active,
      orderEntryEnabled: accountResponse.orderEntryEnabled,
      status: this.convertToAccountStatus(accountResponse.status),
      lastOrderDate: accountResponse.lastOrderDate ? new Date(accountResponse.lastOrderDate) : undefined
    });
  }

  public convertToAccountStatus(status: AccountStatusResponse): AccountStatus {
    switch (status) {
    case 0:
      return AccountStatus.LOST;
    case 1:
      return AccountStatus.VERIFIED;
    case 2:
      return AccountStatus.UNKNOWN;
    default:
      return AccountStatus.UNKNOWN;
    }
  };

  public convertToAccountLocation(accountLocationResponse: AccountLocationResponse): AccountLocation {
    return {
      location: this.convertToLocation(accountLocationResponse.location),
      locationId: accountLocationResponse.locationId,
      accountId: accountLocationResponse.accountId,
      organizationId: accountLocationResponse.organizationId,
      account: accountLocationResponse.account? this.convertToAccount(accountLocationResponse.account) : undefined,
      streetAddressLine1: accountLocationResponse.streetAddressLine1,
      streetAddressLine2: accountLocationResponse.streetAddressLine2,
      zipCode: accountLocationResponse.zipCode,
      city: accountLocationResponse.city,
      county: accountLocationResponse.county,
      state: accountLocationResponse.state,
    };
  }

  public convertToLocation(locationResponse: LocationResponse) {
    return {
      id: locationResponse.id,
      name: locationResponse.name,
      type: locationResponse.type,
      addressLine1: locationResponse.streetAddressLine1,
      addressLine2: locationResponse.streetAddressLine2,
      city: locationResponse.city,
      state: locationResponse.state,
      zipCode: locationResponse.zipCode,
      licenseNumber: locationResponse.license.externalLicenseId,
      issuedAt: OrdoDate.from(locationResponse.license.issuedAt),
      county: locationResponse.county
    };
  }

  public convertToDomainBatch(batch: BatchResponse) {
    return new Batch(
      batch.id,
      batch.productId,
      batch.batchId,
      batch.quantityOfUnits,
      batch.createdAt,
      batch.advertisedTHC,
      batch.distributorOrganizationLicenseId,
      batch.coaDocumentURL,
      batch.advertisedCBD,
      undefined,
      batch.packagedDate
    );
  }

  public convertToDomainProduct(product: ProductResponse) {
    const batches = product.batches ? product.batches.map((batch: BatchResponse) => this.convertToDomainBatch(batch)) : [];
    return new Product(
      product.id,
      product.name,
      product.brand,
      new ProductCategory(product.category.id, product.category.name),
      batches,
      product.organizationId,
      product.imageUrl,
      product.price,
      product.unitsPerCase,
      product.unitSaleOnly,
      product.caseSaleOnly,
      product.size,
      product.genetics,
      product.advertisedTHC,
      product.advertisedCBD,
      product.preRolls,
      product.customerSKU,
    );
  };

  public convertToProductsWithAvailability(productsResponse: ProductWithAvailabilityResponse[]): ProductWithAvailability[] {
    return productsResponse.map((productWithAvail: ProductWithAvailabilityResponse) => {
      const {product} = productWithAvail;
      const batches = product.batches.map((batch) => this.convertToDomainBatch(batch));
      return new ProductWithAvailability(
        product.id,
        product.name,
        product.brand,
        new ProductCategory(product.category.id, product.category.name),
        batches,
        product.organizationId,
        product.imageUrl,
        product.price,
        product.unitsPerCase,
        productWithAvail.availability,
        product.unitSaleOnly,
        product.caseSaleOnly,
        productWithAvail.unitPrice,
        product.size,
        product.genetics,
        product.advertisedTHC,
        product.advertisedCBD,
        product.preRolls,
        product.customerSKU,
        productWithAvail.tierId,
        product.deletedAt
      );
    });
  }

  public convertToOrganizationLicense(item: OrganizationLicenseResponse): OrganizationLicense {
    return {
      id: item.id,
      license: {
        id: item.license.id,
        name: item.license.legalName,
        licenseNumber: item.license.externalLicenseId,
        ordoType: item.license.ordoType
      },
      status: item.status.toString(),
      streetAddressLine1: item.streetAddressLine1,
      streetAddressLine2: item.streetAddressLine2,
      city: item.city,
      state: item.state,
      zipCode: item.zipCode,
      createdAt: new Date(item.createdAt),
    };
  }

  public convertToLicense(item: LicenseResponse): License {
    return {
      id: item.id,
      name: item.legalName,
      licenseNumber: item.externalLicenseId,
      ordoType: item.ordoType
    };
  }

  public convertToDomainContact(contact: ContactResponse): Contact {
    return new Contact(contact.id, contact.name, contact.email, contact.phone, contact.accountId, contact.title);
  }

  // Old order version format that duplicates data
  public convertToOrderWithCurrentVersion(orderWithCurrentVersion: OrderWithCurrentVersionResponse): OrderWithCurrentVersion {
    const order = orderWithCurrentVersion.order.model;
    const {lastVersion} = orderWithCurrentVersion;
    return this.convertToOrderWithCurrentVersionCommon(order, lastVersion);
  }

  // New order version format that reduces the amount of data
  public convertToOrder(orderWithCurrentVersion: OrderResponse): OrderWithCurrentVersion {
    const order = orderWithCurrentVersion.model;
    const {lastVersion} = order;
    return this.convertToOrderWithCurrentVersionCommon(order, lastVersion);
  }

  public convertToOrderVersion(orderVersion: OrderVersionResponse): OrderVersion {
    const distributor = orderVersion.distributor ? this.convertToOrganizationLicense(orderVersion.distributor) : emptyOrganizationLicense;
    const contact = orderVersion.contact ?
      new Contact(orderVersion.contact.id, orderVersion.contact.name, orderVersion.contact.email, orderVersion.contact.phone, orderVersion.contact.accountId, orderVersion.contact.title) :
      emptyContact;

    const account = this.convertToAccount(orderVersion.account);
    const accountLocation = this.convertToAccountLocation(orderVersion.location);
    accountLocation.account = account;
    return {
      id: orderVersion.id,
      account: account,
      effective: orderVersion.effective,
      items: orderVersion.items.map(item => this.convertToOrderVersionLineItem(item)),
      batches: orderVersion.batches,
      orderNotes: orderVersion.orderNotes,
      status: this.convertToOrderStatus(orderVersion.status),
      deliveryDay: orderVersion.deliveryDay,
      discountType: orderVersion.discountType,
      discountPercentage: orderVersion.discountPercentage,
      discountCurrencyAmount: Money.FromSerializable(orderVersion.discountCurrencyAmount),
      summary: OrderSummaryFrom(orderVersion.summary),
      invoiceUrl: orderVersion.invoiceUrl,
      contact: contact,
      accountLocation: accountLocation,
      distributor: distributor,
      assignedSalesRep: orderVersion.assignedSalesRep
    };

  }

  public convertToOrderVersionLineItem(orderVersionLineItem: OrderVersionLineItemResponse): OrderVersionLineItem {
    return new OrderVersionLineItem(
      orderVersionLineItem.id,
      orderVersionLineItem.productSnapshot,
      orderVersionLineItem.productId,
      this.convertToDomainProduct(orderVersionLineItem.product),
      orderVersionLineItem.quantity,
      Money.FromSerializable(orderVersionLineItem.price),
      orderVersionLineItem.discountPercentage,
      Money.FromSerializable(orderVersionLineItem.discountCurrencyAmount),
      orderVersionLineItem.discountType,
      Money.FromSerializable(orderVersionLineItem.salesTax),
      Money.FromSerializable(orderVersionLineItem.exciseTax),
      orderVersionLineItem.type,
      orderVersionLineItem.notes,
    );
  }

  // TODO (mk): Think it makes sense to just have these 'convert' methods as isolated functions
  // Just writing as a method to follow the current pattern
  public convertToOrderStatus(response: OrderStatusResponse): OrderStatus {
    switch (response) {
    case OrderStatusResponse.Pending:
      return OrderStatus.Pending;
    case OrderStatusResponse.Cancelled:
      return OrderStatus.Cancelled;
    case OrderStatusResponse.Confirmed:
      return OrderStatus.Confirmed;
    case OrderStatusResponse.Paid:
      return OrderStatus.Paid;
    case OrderStatusResponse.Delivered:
      return OrderStatus.Delivered;
    default:
      return OrderStatus.Unknown;
    }
  }

  public convertToExternalApiConfig(config: ExternalApiConfigResponse): ExternalApiConfig {
    return {
      id: config.id,
      configSourceName: config.configSourceName,
    };
  }

  public convertToDomainOrganization(organization: OrganizationResponse): Organization {
    return {
      id: organization.id,
      name: organization.name,
      licenses: organization.licenses.map((license) => this.convertToOrganizationLicense(license)),
      size: organization.size,
      state: organization.state,
      status: organization.status,
      brands: organization.brands,
      members: organization.members.map(member => new ActualMember(member.role, member.user!)),
      pendingInvitations: organization.pendingInvitations.map((pendingInvitation: NotMemberInput) =>
        new NotMember(pendingInvitation.role, pendingInvitation.email, pendingInvitation.id)
      ),
      owner: organization.owner,
      subscription: this.convertToSubscription(organization.subscription),
      externalApiConfigs: organization.externalApiConfigs.map(externalApiConfig => this.convertToExternalApiConfig(externalApiConfig)),
    };
  }

  public convertToDomainUserSession(userSessionResponse: UserSessionResponse): UserSession {
    return {
      user: this.convertToDomainUser(userSessionResponse.user),
      role: userSessionResponse.role,
      currentOrganization: userSessionResponse.currentOrganization ? this.convertToDomainOrganization(userSessionResponse.currentOrganization) : undefined,
      organizations: (userSessionResponse.organizations || []).map((organization) => this.convertToDomainOrganization(organization)),
      pendingInvitations: (userSessionResponse.pendingInvitations || []).map((pendingInvitation: PendingInvitationResponse) => this.convertToPendingInvitationResponse(pendingInvitation)),
      emailVerified: userSessionResponse.emailVerified,
    };
  }

  public convertToPipelineData(pipelineData: PipelineDataResponse): PipelineData {
    const nonQualifiedAccounts = pipelineData.nonQualifiedAccounts.map((account: AccountResponse) => this.convertToSalesActivityAccount(account));
    const prospectAccounts = pipelineData.prospectAccounts.map((account: AccountResponse) => this.convertToSalesActivityAccount(account));
    const verifiedPurchasers = pipelineData.verifiedPurchasers.map((account: AccountResponse) => this.convertToSalesActivityAccount(account));
    const stalePurchasers = pipelineData.stalePurchasers.map((account: AccountResponse) => this.convertToSalesActivityAccount(account));
    const lostPurchasers = pipelineData.lostPurchasers.map((account: AccountResponse) => this.convertToSalesActivityAccount(account));
    const accounts = pipelineData.accounts.map((account: AccountResponse) => this.convertToAccount(account));

    const pendingOrdersCards = pipelineData.pendingOrdersCards?.map((card: OrderWithCurrentVersionResponse) => this.convertToSalesActivityOrder(card.lastVersion, card.order.model));
    const awaitingDeliveryCards = pipelineData.awaitingDeliveryCards?.map((card: OrderWithCurrentVersionResponse) => this.convertToSalesActivityOrder(card.lastVersion, card.order.model));
    const collectPaymentCards = pipelineData.collectPaymentCards.map((card: OrderWithCurrentVersionResponse) => this.convertToSalesActivityOrder(card.lastVersion, card.order.model));

    const assignments = pipelineData.assignments.map((assignment: RepAssignmentResponse) => this.convertToRepAssignmentsIds(assignment));
    return {
      nonQualifiedAccounts: nonQualifiedAccounts,
      prospectAccounts: prospectAccounts,
      verifiedPurchasers: verifiedPurchasers,
      stalePurchasers: stalePurchasers,
      lostPurchasers: lostPurchasers,
      pendingOrdersCards: pendingOrdersCards,
      awaitingDeliveryCards: awaitingDeliveryCards,
      collectPaymentCards: collectPaymentCards,
      assignments: assignments,
      accounts: accounts
    };
  }

  private parseAccountLocationAddress(accountLocationResponse: AccountLocationResponse): string {
    return (accountLocationResponse.streetAddressLine1 || accountLocationResponse.streetAddressLine2) ?
      `${accountLocationResponse.streetAddressLine1} ${accountLocationResponse.streetAddressLine2}` :
      `${accountLocationResponse.city} ${accountLocationResponse.state} ${accountLocationResponse.zipCode}`;
  }

  public convertToSalesActivityAccount(accountResponse: AccountResponse): SalesActivityAccount {
    const address = accountResponse.locations.length === 1 ? this.parseAccountLocationAddress(accountResponse.locations[0]) : undefined;
    return {
      id: accountResponse.id,
      name: accountResponse.name,
      address: address,
      amountOfLocations: accountResponse.locations.length,
      accountLocations: accountResponse.locations.map(location => this.convertToAccountLocation(location)),
      lastOrderDate: accountResponse.lastOrderDate ? OrdoDate.from(accountResponse.lastOrderDate).format(DateFormatTemplate.LOCATION_ACTIVITY_CARDS_DATE) : 'no orders',
      priority: accountResponse.priority,
      stalePeriod: accountResponse.stalePeriod,
      value: accountResponse.value,
      conversion: accountResponse.conversion,
      activeAccount: accountResponse.active,
      orderEntryEnabled: accountResponse.orderEntryEnabled
    };
  }

  public convertToSalesActivityOrder(orderLastVersion: OrderVersionResponse, orderModel: OrderModelResponse): SalesActivityOrder {
    const {deliveryDay} = orderLastVersion;
    const orderDeliveryDate = OrdoDate.from(deliveryDay);
    const orderPaymentDueDate = OrdoDate
      .from(deliveryDay)
      .addDays(orderLastVersion.summary.termPeriod || 0);

    const {location} = orderLastVersion;

    const tracked = orderModel.isTracked;
    const orderVersion = this.convertToOrderVersion(orderLastVersion);

    return {
      id: orderModel.id,
      locationId: location.locationId,
      accountId: orderLastVersion.account.id,
      name: orderLastVersion.account.name,
      orderNumber: orderModel.orderNumber,
      salesValue: tracked ? orderVersion.summary.total : orderVersion.summary.grandTotalWithTaxesCriteria(),
      status: this.convertToOrderStatus(orderLastVersion.status),
      deliveryDate: orderDeliveryDate.format(DateFormatTemplate.LOCATION_ACTIVITY_CARDS_DATE),
      paymentDueDate: orderPaymentDueDate.format(DateFormatTemplate.LOCATION_ACTIVITY_CARDS_DATE),
      priority: orderLastVersion.account.priority,
      datePlaced: OrdoDate.from(orderModel.createdAt),
      isTracked: tracked,
      createdBy: `${orderModel.createdBy.user.firstName} ${orderModel.createdBy.user.lastName}`,
      activeAccount: orderLastVersion.account.active,
      invoiceURL: orderLastVersion.invoiceUrl
    };
  }

  public convertToNote(note: NoteResponse): Note {
    return {
      id: note.id,
      title: note.subject,
      description: note.description,
      lastEdited: OrdoDate.from(note.lastEdited).format(DateFormatTemplate.MONTH_DAY_YEAR_HOUR_IN_12),
      createdBy: note.createdBy,
      contacts: note.contacts.map(this.convertToDomainContact)
    };
  }

  public convertToActivityCompletionResponse(backendResponse: ActivityResponseResponse){
    switch(backendResponse.questionType) {
    case ActivityQuestionType.TextResponse:
    case ActivityQuestionType.NumericResponse:
    case ActivityQuestionType.DollarValue:
    case ActivityQuestionType.ProductDropdown:
      return new SingleValueActivityCompletionResponse(backendResponse.index, backendResponse.isRequired, backendResponse.questionTitle, backendResponse.questionType, backendResponse.body);
    case ActivityQuestionType.Checkboxes:
    case ActivityQuestionType.MultipleChoice:
      return new MultiValueActivityCompletionResponse(backendResponse.index, backendResponse.isRequired, backendResponse.questionTitle, backendResponse.questionType, backendResponse.options || [], backendResponse.body);
    case ActivityQuestionType.DateTime:
      return new DateTimeActivityCompletionResponse(backendResponse.index, backendResponse.isRequired, backendResponse.questionTitle, backendResponse.questionType, backendResponse.body);
    case ActivityQuestionType.FileUpload:
      return new FileURLActivityCompletionResponse(backendResponse.index, backendResponse.isRequired, backendResponse.questionTitle, backendResponse.questionType, backendResponse.body);
    default:
      throw new Error(`Unhandled question type: ${backendResponse.questionType}`);
    }
  }

  public convertToActivity(activity: ActivityResponse | ExternalEmailActivityResponse): Activity | ExternalEmailActivity {

    const type = convertFromBackendActivityType(activity.type);
    const baseActivity = {
      ...activity,
      type: type,
      contacts: activity.contacts.map((contact) => this.convertToDomainContact(contact)),
      activityTemplate: this.convertToActivityTemplate(activity.activityTemplate),
      assignedSalesRep: activity.assignedSalesRep ? this.convertToDomainUser(activity.assignedSalesRep) : null,
      completed: activity.completed,
      responses: activity.responses?.map(r => this.convertToActivityCompletionResponse(r))
    };
    switch (type) {
    case ActivityTypeName.EXTERNAL_EMAIL_ACTIVITY:
      return {...baseActivity, emailThreadActivity: (activity as ExternalEmailActivityResponse).emailThreadActivity};
    default:
      return baseActivity;
    }
  }

  public convertToRepAssignmentsIds(assignment: RepAssignmentResponse) {

    return {
      memberName: `${assignment.user.firstName} ${assignment.user.lastName}`,
      orgId: assignment.orgId,
      accountsIds: assignment.accountsIds,
    };
  };

  public convertToSubscription(subscription: SubscriptionResponse): Subscription {
    return {
      id: subscription.id,
      externalSubscriptionId: subscription.externalSubscriptionId,
      externalCustomerId: subscription.externalCustomerId,
      status: subscription.status,
      type: subscription.type,
      subscriptionEmail: subscription.subscriptionEmail,
      subscriptionUserId: subscription.subscriptionUserId,
    };
  }

  public convertToActivityTemplate(activityTemplateResponse: ActivityTemplateResponse){
    const questionsWithId = activityTemplateResponse.questions.map(q => {
      return {...q, id: v4()};
    });
    return new ActivityTemplate(activityTemplateResponse.id, activityTemplateResponse.name, questionsWithId, activityTemplateResponse.iconName);
  }

  public convertToActivityItem(activityItemResponse: ActivityItemResponse): ActivityItem {
    return {...this.convertToActivity(activityItemResponse), accountName: activityItemResponse.accountName};
  }

  public convertToActivityTemplateWithCount(activityTemplateWithCountResponse: ActivityTemplateWithCountResponse): ActivityTemplateWithCount {
    return {
      activityTemplate: this.convertToActivityTemplate(activityTemplateWithCountResponse.activityTemplate),
      created: activityTemplateWithCountResponse.created,
      completed: activityTemplateWithCountResponse.completed
    };
  }

  public convertToAccountRepAssignment(accountRepAssignment: AccountRepAssignmentResponse): AccountRepAssignment {
    return new AccountRepAssignment(accountRepAssignment.accountId, this.convertToDomainUser(accountRepAssignment.user), accountRepAssignment.role, accountRepAssignment.createdAt);
  }

  public convertToAccountActivity(accountActivity: AccountActivityResponse[]): AccountActivityEntity[] {
    return accountActivity.map((accountActivityResponse: AccountActivityResponse) => {
      switch (accountActivityResponse.accountActivityType) {
      case AccountActivityType.ACTIVITY:
        return new AccountActivityEntity(this.convertToActivity(accountActivityResponse.accountActivity as ActivityResponse), AccountActivityType.ACTIVITY, accountActivityResponse.sortingDate);
      case AccountActivityType.NOTE:
        return new AccountActivityEntity(this.convertToNote(accountActivityResponse.accountActivity as NoteResponse), AccountActivityType.NOTE, accountActivityResponse.sortingDate);
      case AccountActivityType.ORDER:
        return new AccountActivityEntity(this.convertToOrderWithCurrentVersion(accountActivityResponse.accountActivity as OrderWithCurrentVersionResponse), AccountActivityType.ORDER, accountActivityResponse.sortingDate);
      case AccountActivityType.ASSIGNMENT:
        return new AccountActivityEntity(this.convertToAccountRepAssignment(accountActivityResponse.accountActivity as AccountRepAssignmentResponse), AccountActivityType.ASSIGNMENT, accountActivityResponse.sortingDate);
      default:
        return new AccountActivityEntity(this.convertToActivity(accountActivityResponse.accountActivity as ActivityResponse), AccountActivityType.ACTIVITY, accountActivityResponse.sortingDate);
      }
    });
  };

  public convertToDomainCustomPriceTier(createdTier: TierResponse) {
    return new Tier(createdTier.name, createdTier.productSKUTiers, createdTier.groupPriceTiers, createdTier.accountsIds, createdTier.allAccounts, createdTier.priorities, createdTier.id);
  }

  public convertToOrderPayment(paymentResponse: OrderPaymentResponse): OrderPayment {
    return new OrderPayment(paymentResponse.id,
      paymentResponse.orderId,
      Money.FromSerializable(paymentResponse.amountPaid),
      Money.FromSerializable(paymentResponse.amountOwedBeforePayment),
      Money.FromSerializable(paymentResponse.amountOwedAfterPayment),
      paymentResponse.createdAt,
      paymentResponse.systemPayment,
      paymentResponse.userId);
  }

  public convertToPendingPaymentOrder(pendingPaymentOrderResponse: PendingPaymentOrderResponse): PendingPaymentOrder {
    return {
      orderId: pendingPaymentOrderResponse.orderId,
      orderNumber: pendingPaymentOrderResponse.orderNumber,
      amountOwed: Money.FromSerializable(pendingPaymentOrderResponse.amountOwed),
      invoiceURL: pendingPaymentOrderResponse.invoiceURL,
    };
  }

  // Common code between old and new Order response
  private convertToOrderWithCurrentVersionCommon(order: OrderModelResponse, lastVersion: OrderVersionResponse): OrderWithCurrentVersion {
    const orderCreator = order.createdBy.user;
    const salesRep = {
      email: orderCreator.email,
      id: orderCreator.id,
      lastName: orderCreator.lastName,
      phone: orderCreator.phone,
      firstName: orderCreator.firstName,
    };

    const salesValue = Money.FromSerializable(order.isTracked ? lastVersion.summary.total : lastVersion.summary.subtotal);
    return new OrderWithCurrentVersion(
      order.id,
      salesRep,
      this.convertToOrderStatus(lastVersion.status),
      new Date(order.createdAt),
      salesValue,
      order.orderNumber,
      this.convertToOrderVersion(lastVersion),
      order.isTracked
    );
  }

  public convertToPendingInvitationResponse(pendingInvitationResponse: PendingInvitationResponse) {
    return new PendingInvitation(pendingInvitationResponse.organizationId,
      pendingInvitationResponse.organizationName,
      pendingInvitationResponse.organizationOwnerName,
      pendingInvitationResponse.organizationOwnerEmail,
      pendingInvitationResponse.invitationTokenId);
  }

  public convertToContactWithAccountName(contactWithAccountNameResponse: ContactWithAccountNameResponse) {
    return {
      contact: this.convertToDomainContact(contactWithAccountNameResponse.contact),
      accountName: contactWithAccountNameResponse.accountName
    };
  }
}
