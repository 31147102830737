import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import '../../scss/ordo/order-entry-page.scss';

const AddContactButton = ({onClick, inDropdown = false} : {onClick: Function, inDropdown?: boolean}) => {
  return (
    <div role="button"
      tabIndex={0}
      onKeyPress={() => onClick()}
      onClick={() => onClick()}
      className={`${inDropdown ? '' : 'isolated'} add-contact-button`}>
      <span>add a contact</span>
      <FontAwesomeIcon
        data-testid='create-organization-button' className="add-contact-icon"
        size='1x' icon={faPlus}
      />
    </div>
  );
};

export default AddContactButton;
