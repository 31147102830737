import Product, {ConsumerUnitSize, Genetics} from './Product';
import {Brand} from './Brand';
import ProductCategory from './ProductCategory';
import Batch from './Batch';
import {CartItemType} from './order-entry/CartItem';

export class ProductWithAvailability extends Product {
  public readonly tierId?: string;
  public readonly unitPrice: number;

  constructor(
    id: string,
    name: string,
    brand: Brand,
    category: ProductCategory,
    batches: Batch[],
    organizationId: string,
    imageUrl: string,
    price: number,
    unitsPerCase: number,
    public availability: number,
    unitSaleOnly: boolean,
    caseSaleOnly: boolean,
    unitPrice: number,
    size?: ConsumerUnitSize,
    genetics?: Genetics,
    advertisedTHC?: number,
    advertisedCBD?: number,
    preRolls?: number,
    customerSKU?: string,
    tierId?: string,
    deletedAt?: Date
  ) {
    super(id,
      name,
      brand,
      category,
      batches,
      organizationId,
      imageUrl,
      price,
      unitsPerCase,
      unitSaleOnly,
      caseSaleOnly,
      size,
      genetics,
      advertisedTHC,
      advertisedCBD,
      preRolls,
      customerSKU,
      deletedAt
    );

    this.tierId = tierId;
    this.unitPrice = unitPrice;
  }

  public salesPrice() {
    return this.unitPrice;
  }

  public calculatePricePerItemWithTier(itemType: CartItemType) {
    if (itemType === CartItemType.CASE) {
      return this.unitPrice * this.unitsPerCase;
    }
    if (itemType === CartItemType.SAMPLE) {
      throw Error('Price for sample is not being handled');
    }
    return this.unitPrice;
  }
}

