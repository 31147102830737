import React from 'react';
import OrdoSpinner from '../OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import ConfirmationModal from '../common/ConfirmationModal';
import {ActivityTemplate} from '../../../models/sales-activity/Activity';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {useAPI} from '../../../context/OrdoApiContext';

type DeleteActivityTemplateConfirmationModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: Function,
  activityTemplate: ActivityTemplate,
  organizationId: string
}

export const DeleteActivityTemplateConfirmationModal = ({isOpen, onClose, onSubmit, organizationId, activityTemplate}: DeleteActivityTemplateConfirmationModalProps) => {
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const api = useAPI();

  const deleteActivityTemplate = () => {
    showSpinner();
    api.deleteActivityTemplate(organizationId, activityTemplate.id)
      .then(() => {
        onSubmit(activityTemplate.id);
        successToast('Activity template deleted');
      })
      .catch(() => errorToast('Could not delete activity template'))
      .finally(() => {
        onClose();
        hideSpinner();
      });
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <ConfirmationModal
        show={isOpen}
        onClose={onClose}
        onSubmit={deleteActivityTemplate}
        actionText="delete"
        disableCTA={spinnerVisibility}
        confirmationText={`are you sure you want to delete activity template ${activityTemplate.name}?`}
      />
    </OrdoSpinner>
  );
};
