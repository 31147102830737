import React, {useContext} from 'react';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import '../../../../scss/ordo/mobileOrderEntry/checkout/mobile-summary.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import OrdoButton from '../../../components/common/OrdoButton';
import OrdoInformativeSearchableDropdown
  from '../../../components/common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import {OrganizationLicense} from '../../../../models/OrganizationLicense';
import Contact from '../../../../models/order-entry/Contact';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {locationAddress} from '../../../../models/Location';
import OrdoDatepicker, {OrdoDatepickerDisplayMode, SelectableDates} from '../../../components/common/OrdoDatepicker';
import {DateFormatTemplate, OrdoDate} from '../../../../models/OrdoDate';
import {WholeOrderDiscountButton} from '../../WholeOrderDiscountButton';
import {DiscountType} from '../../../../models/order-entry/Order';
import OrdoDropdown from '../../../components/common/OrdoDropdown';
import OrdoTextArea from '../../../components/common/OrdoTextArea';
import OrdoInput from '../../../components/common/inputs/OrdoInput';
import {isValidTermPeriod} from '../../../../models/order-entry/OrderValidator';
import {OrdoCheckbox} from '../../../components/OrdoCheckbox';

type MobileOrderEntrySummaryProps = {
  orderEntryViewModel: OrderEntryViewModel,
  goToVerifyOrder: () => void,
  updateViewModel: (orderEntryViewModel: OrderEntryViewModel) => void,
  onSubmit: () => void,
  landscapeMode?: boolean;
}

export const MobileOrderEntrySummary = ({orderEntryViewModel, goToVerifyOrder, updateViewModel, onSubmit, landscapeMode = false}: MobileOrderEntrySummaryProps) => {
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const updateCurrentDistributor = async (selectableDistributor: OrganizationLicense) => {
    showSpinner();
    const updatedViewModel = await orderEntryViewModel.updateDistributor(organization.id, selectableDistributor);
    updateViewModel(updatedViewModel);
    hideSpinner();
  };

  const updateContact: (selectedContact: Contact) => void = (selectedContact: Contact) => {
    updateViewModel(orderEntryViewModel.updateSelectedContact(selectedContact));
  };

  const deliveryDate = () => {
    const date = OrdoDate.from(orderEntryViewModel.selectedDeliveryDay!);
    return date.format(DateFormatTemplate.FULLDAY_OF_MONTH_DETAILED );
  };

  const updateDeliveryDay = (date: Date) => {
    updateViewModel(orderEntryViewModel.updateDeliveryDay(OrdoDate.from(date)));
  };

  const updateWholeOrderDiscount = () => {
    return async (value: number) => {
      showSpinner();
      const newVm = await orderEntryViewModel.updateWholeOrderDiscount(organization.id, value);
      updateViewModel(newVm);
      hideSpinner();
    };
  };

  const updateWholeOrderDiscountType = () => {
    return async (value: DiscountType) => {
      showSpinner();
      const newVM = await orderEntryViewModel.updateWholeOrderDiscountType(organization.id, value);
      updateViewModel(newVM);
      hideSpinner();
    };
  };

  const updateDistributorTaxesEnabled = async () => {
    showSpinner();
    const vm = await orderEntryViewModel.updateDistributorTaxesEnabled(organization.id);
    updateViewModel(vm);
    hideSpinner();
  };

  const updateCurrentPaymentMethod = (paymentMethod: string) => {
    const updatedViewModel = orderEntryViewModel.updateCurrentPaymentMethod(paymentMethod);
    updateViewModel(updatedViewModel);
  };

  const updateTermPeriod = (termPeriod: number) => {
    const updatedViewModel = orderEntryViewModel.updateTermPeriod(termPeriod);
    updateViewModel(updatedViewModel);
  };

  return (<OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="mobile-summary-container">
      <div className={`mobile-summary-header ${landscapeMode ? 'landscape' : ''}`}>
        <div className="mobile-summary-title">
          <div className="mobile-summary-title__icon">
            <OrdoButton
              disabled={false}
              style={{fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'left', padding: 0}}
              text="back"
              firstChild={<FontAwesomeIcon icon={faArrowLeft} style={{fontSize: '0.75rem'}}/>}
              category="link"
              onClick={goToVerifyOrder}
              dataTestId="go-back"
            />
          </div>
          <div className={`mobile-checkout-title__text ${landscapeMode ? 'landscape' : ''}`}>
            <span>Order Summary</span>
          </div>
        </div>
        <div className={`mobile-summary-selected-account ${landscapeMode ? 'landscape' : ''}`}>
          <span className="account-name">{orderEntryViewModel.selectedAccountLocation?.account?.name}</span>
          <div>
            <span className="location-license">{orderEntryViewModel.selectedAccountLocation!.location.licenseNumber}</span>
            <span className="location-address">{locationAddress(orderEntryViewModel.selectedAccountLocation!)}</span>
          </div>
        </div>
        <div className={`mobile-summary-selectors ${landscapeMode ? 'landscape' : ''}`}>
          <OrdoInformativeSearchableDropdown
            selectedOption={orderEntryViewModel.getSelectedDistributor()}
            options={orderEntryViewModel.getSelectableDistributors()}
            canEditValue={orderEntryViewModel.hasMoreThanOneDistributor()}
            onChangeSelectedOption={(distributor) => updateCurrentDistributor(distributor)}
            placeholder='select a distributor'
            addedStyles={{
              marginBottom: '0', container: {height: '2em'}, valueContainer: {height: '2em', display: 'flex', alignItems: 'end', padding: '0'},
              control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
            }}
          />
          <OrdoInformativeSearchableDropdown
            selectedOption={orderEntryViewModel.getSelectedContact()}
            options={orderEntryViewModel.getSelectableContacts()}
            onChangeSelectedOption={updateContact}
            placeholder='select a contact'
            addedStyles={{
              marginBottom: '0', container: {height: '2em'}, valueContainer: {height: '2em', display: 'flex', alignItems: 'end', padding: '0'},
              control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
            }}
          />
          <div className={`select-delivery-date ${landscapeMode ? 'landscape' : ''}`}>
            <OrdoDatepicker
              onChange={updateDeliveryDay}
              displayTime={false}
              onlyTime={false}
              value={orderEntryViewModel.selectedDeliveryDay}
              displayMode={OrdoDatepickerDisplayMode.SINGLE_LINE}
              validDates={SelectableDates.ALL_TIME}
            />
            <span className="date">{deliveryDate()}</span>
          </div>
        </div>
      </div>
      <div className={`divider ${landscapeMode ? 'landscape' : ''}`}>
        <span>order totals</span>
      </div>
      <div className={`order-summary-container ${landscapeMode ? 'landscape' : ''}`}>
        <div className="order-summary-details">
          <div className="details-row">
            <span className="title">total</span>
            <span className="value">{orderEntryViewModel.total()}</span>
          </div>
          <div className="details-row">
            <span className="title">discounts</span>
            <span className="value">{orderEntryViewModel.discountTotal()}</span>
          </div>
          <WholeOrderDiscountButton
            label='whole order discount'
            item={orderEntryViewModel.cart}
            onChange={() => {}}
            onSwitch={updateWholeOrderDiscountType()}
            onBlur={updateWholeOrderDiscount()}
            discountButtonStyle={{marginBottom: '0 !important', width: '160px', backgroundColor: '#fffff'}}
            iconsStyle={{marginBottom: '0 !important', width: '2em', height: '2em'}}
            invalidWithoutMessage
          />
          <div className="details-row">
            <span className="title">sub total</span>
            <span className="value">{orderEntryViewModel.subtotal()}</span>
          </div>
          <div className="details-row">
            <span className="title">tax total</span>
            <span className="value">{orderEntryViewModel.totalTax()}</span>
          </div>
          {orderEntryViewModel.canEnableDistributorTaxes() && <OrdoCheckbox
            checked={orderEntryViewModel.distributorTaxesEnabled}
            onChange={updateDistributorTaxesEnabled}
            label='distributor will remit taxes'
            id='calculate-taxes'
            style={{marginRight: '1em'}}
          />}
          <div className="details-row">
            <span className="title">grand total</span>
            <span className="value grand-total">{orderEntryViewModel.grandTotal()}</span>
          </div>
        </div>
        <div className={`payment-method ${landscapeMode ? 'landscape' : ''}`}>
          <OrdoDropdown
            placeholder='select a payment method'
            selectedOption={orderEntryViewModel.getSelectedPaymentMethod()}
            options={orderEntryViewModel.getSelectablePaymentMethods()}
            onSelect={(paymentMethod: string) => updateCurrentPaymentMethod(paymentMethod)}
            height='short'
          />
          {orderEntryViewModel.selectedPaymentMethod === 'net terms' &&
          <div className='payment-method__net-term-period'>
            <div>Term Period</div>
            <OrdoInput
              inputName='net-terms-input'
              value={orderEntryViewModel.getTermPeriod()}
              placeholder='7, 15, 30, etc'
              inputType='number'
              inputSize={landscapeMode ? 'large' : 'medium'}
              onChange={updateTermPeriod}
              isValid={isValidTermPeriod(orderEntryViewModel.getTermPeriod())}
              bottomMargin={false}
            />
          </div>}
        </div>
        <div className="order-notes">
          <span className="order-notes__text"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Max length 250 characters">
              order notes
          </span>
          <OrdoTextArea
            style={{height: '9em'}}
            value={orderEntryViewModel.deliveryDetailsNotes}
            handleChange={(event: any) => updateViewModel(orderEntryViewModel.updateOrderNotes(event.target.value))}
          />
        </div>
      </div>
      <div className={`redirect-to-next-view ${landscapeMode ? 'landscape' : ''}`}>
        <OrdoButton
          disabled={!orderEntryViewModel.canSubmitOrder() && !spinnerVisibility}
          disabledText={orderEntryViewModel.submitOrderInvalidText()}
          text="complete order"
          category="primary"
          onClick={onSubmit}
          dataTestId="complete-order-button"
          style={{padding: '0.25em 0.5em', maxWidth: '25em'}}
        />
      </div>
    </div>
  </OrdoSpinner>);
};
