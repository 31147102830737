import React, {useRef, useState} from 'react';

export const DynamicQuestionOption = ({option, onOptionUpdate, index} : {option: string, onOptionUpdate: (i: number, newValue: string) => void, index: number}) => {
  const [optionValue, setOptionValue] = useState(option);
  const questionIndex = useRef(index);

  return(
    <div className='option-name-container'>
      <input className='option-name' value={optionValue}
        style={{
          width: '100%'
        }}
        onChange={(event) => setOptionValue(event.target.value)}
        onBlur={() => onOptionUpdate(questionIndex.current, optionValue)}/>
    </div>
  );
};