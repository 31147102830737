import React, {PropsWithChildren} from 'react';
import {useResolution} from '../../../context/UseResolutions';
import {isMobileResolution} from '../../../context/resolutions';
import OrdoModal from './OrdoModal';
import '../../../scss/ordo/display-as-modal-in-mobile.scss';

type OrdoModalWrapperForMobileProps = {
  show: boolean,
  onClose: (event?: any) => void,
  title?: string,
  dialogClassName?: string,
};

const MobileDisplayAsModal = ({children, show, onClose, title, dialogClassName} : PropsWithChildren<OrdoModalWrapperForMobileProps>) => {
  const resolution = useResolution();

  return <>
    {
      isMobileResolution(resolution)
        ? <OrdoModal
          header={false}
          dialogClassName={dialogClassName}
          className='mobile-modal-wrapper'
          title={title}
          onClose={onClose}
          show={show}
          showFooter={false}
        >
          {children}
        </OrdoModal>
        : children
    }
  </>;
};

export default MobileDisplayAsModal;
