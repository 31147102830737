import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDotCircle} from '@fortawesome/free-solid-svg-icons/faDotCircle';

import '../../../scss/ordo/integrations/flourish-integration.scss';

const syncInventoryOrItemsOptions = () => {
  return [
    'Inventory',
    'Items',
    'Both',
  ];
};

export type FlourishInventoryOrItemsRadioGroupProps = {
  selected?: number | string,
  onChange: Function,
}

export const FlourishInventoryOrItemsRadioGroup = ({
  selected, onChange
}: FlourishInventoryOrItemsRadioGroupProps) => {

  return <div className='checkbox-response'>
    {syncInventoryOrItemsOptions().map((option) => {
      const checked = selected === option;
      return <div className='checkbox-response__row' id={option} key={option}>
        <FontAwesomeIcon className={`option-icon ${checked ? 'checked' : ''}`} onClick={()=>onChange(option)} size="1x" icon={faDotCircle}/>
        <span>{option}</span>
      </div>;
    })}
  </div>;
};