import React from 'react';
import {TimePeriod} from '../../models/TimePeriod';


type AccountTimelineHeaderRowProps = {
  timePeriod: TimePeriod
};

export const AccountTimelineHeaderRow = ({timePeriod}: AccountTimelineHeaderRowProps) => {

  const timelineSections = timePeriod.sections;

  return <div className="timeline-header">
    <span className="account-name"> accounts </span>
    <div className="account-timeline">
      <div className="past-events">
        {timelineSections.map((section, index) => {
          const key = `${index}-${section.slots.length}`;
          return <div className="timeline-period" style={{width: `${(100*section.width())}%`}} id={key} key={key}>
            {section.description()}
            { index < timelineSections.length-1 && <div className="vertical-line"/>}
          </div>;
        })
        }
      </div>
      <div className='indicators'>
        <div className="vertical-line left"/>
        <span className="indicator left">
          today
        </span>
        <span className="indicator right">
          upcoming
        </span>
        <div className="vertical-line right"/>
      </div>
    </div>

  </div>;
};

export default AccountTimelineHeaderRow;