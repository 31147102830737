import React from 'react';
import OrderInformationViewModel from '../../../application-models/order-history/OrderInformationViewModel';

export const MobileOrderGeneralInformation = ({orderInformationViewModel, onClick} : {orderInformationViewModel: OrderInformationViewModel, onClick: () => void}) => {
  return(
    <div role='presentation' className='mobile-order-history-order-item ordo-shadow ordo-card-border' onClick={onClick}>
      <div className='mobile-order-history-order-item-info'>
        <div className='item-info-field account-info'>
          <span className='title'>{orderInformationViewModel.accountName()}</span>
          <span className='order-number'>order #:{orderInformationViewModel.orderNumber()}</span>
          <span className='order-status'>{orderInformationViewModel.orderStatusToString()}</span>
        </div>
        <div className='item-info-field'>
          <span className='title'>delivery</span>
          <span className='description'>{orderInformationViewModel.orderFormattedDeliveryDate()}</span>
        </div>
        <div className='item-info-field'>
          <span className='title'>sales value</span>
          <span className='description'>{orderInformationViewModel.orderFormattedSalesValue()}</span>
        </div>
      </div>
    </div>
  );
};