import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useState} from 'react';
import { isEmpty } from 'lodash';
import ContactModalViewModel from '../../../application-models/order-entry/ContactModalViewModel';
import {ContactForm} from '../../components/contacts/ContactForm';
import {ContactDetailsRow} from './ContactDetailsRow';
import {API} from '../../../lib/api/ordoApi';
import {useAPI} from '../../../context/OrdoApiContext';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import OrdoSpinner from '../../components/OrdoSpinner';
import '../../../scss/ordo/salesActivity/contact-details.scss';
import Contact from '../../../models/order-entry/Contact';
import {UserSessionContext} from '../../../context/UserSessionContext';
import OrdoMobileFriendlyButton from '../../components/common/OrdoMobileFriendlyButton';
import MobileDisplayAsModal from '../../components/common/MobileDisplayAsModal';
import {NON_ACTIVE_ACCOUNT_TEXT} from '../../../models/Account';
import { PipelineButtonText } from '../salesActivityFunctions';

type AccountContactsDetailsProps = {
  accountId: string,
  orgId: string,
  onSubmit: Function,
  contacts: Contact[],
  activeAccount: boolean,
};

export const AccountContactsDetails = ({accountId, orgId, onSubmit, contacts, activeAccount }: AccountContactsDetailsProps) => {
  const api: API = useAPI();
  const [displayContacts, setDisplayContacts] = useState(false);
  const [openedAddContactForm, setOpenedAddContactForm] = useState(false);
  const [addContactViewModel, setAddContactViewModel] = useState(ContactModalViewModel.empty(api));
  const closeContactForm = () => setOpenedAddContactForm(false);
  const openContactForm = () => setOpenedAddContactForm(true);
  const {spinnerVisibility, showSpinner, hideSpinner} = useSpinnerToggle();
  const [accountContacts, setAccountContacts] = useState<Contact[]>(contacts);
  const {successToast, errorToast} = useOrdoToasts();
  const userSession = useContext(UserSessionContext);
  const toggleContacts = () => setDisplayContacts(!displayContacts);

  const createContact = () => {
    showSpinner();
    addContactViewModel.createContact(accountId, orgId)
      .then((contact) => {
        hideSpinner();
        successToast('contact successfully created');
        if (isEmpty(accountContacts) && onSubmit) {
          onSubmit();
        } else {
          setOpenedAddContactForm(false);
          accountContacts.push(contact);
          setAccountContacts(accountContacts);
          setDisplayContacts(true);
        }
        setAddContactViewModel(ContactModalViewModel.empty(api));
      })
      .catch(() => {
        errorToast('could not create the contact');
        hideSpinner();
      });
  };


  const showBottomSection = () => {
    return openedAddContactForm || displayContacts;
  };

  const deleteContact = (contact: Contact) => {
    showSpinner();
    api.deleteContact(userSession.currentOrganization()!.id, contact.id, accountId)
      .then(() => {
        setAccountContacts(accountContacts.filter(c => contact.id !== c.id));
        successToast('Contact successfully deleted');
      })
      .catch(() => errorToast('Could not delete contact'))
      .finally(() => {
        hideSpinner();
        onSubmit();
      });
  };

  const updateContact = (updatedContact: Contact) => {
    const updatedContacts = accountContacts.map((contact) => {
      if(contact.id === updatedContact.id) {
        return updatedContact;
      } return contact;
    });
    setAccountContacts(updatedContacts);
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <div className={`order-details-section ${showBottomSection() ? 'section-expanded' : 'section-collapsed'}`}>
      <div className="upper-section">
        <div className={`caret-and-title ${displayContacts ? 'opened' : ''}`} onClick={toggleContacts} role="presentation">
          <FontAwesomeIcon className={`caret-icon ${displayContacts ? 'opened' : 'closed'}`} size="lg"
            icon={faAngleRight}/>
          <span
            className="details-title">{`Contacts (${accountContacts.length})`} </span>
        </div>
        <OrdoMobileFriendlyButton
          disabled={!activeAccount}
          text={PipelineButtonText.ADD_CONTACT}
          category="primary"
          onClick={openContactForm}
          dataTestId="sales-activity-order-details-add-contact-button"
          disabledText={NON_ACTIVE_ACCOUNT_TEXT}
        />
      </div>
      {showBottomSection() &&
      <div className="bottom-section">
        <div className="add-form-container">
          {openedAddContactForm && <MobileDisplayAsModal title="add a contact" onClose={closeContactForm} show={openedAddContactForm}>
            <div className="add-form">
              <ContactForm viewModel={addContactViewModel} updateViewModel={setAddContactViewModel}
                onCancel={closeContactForm} onSubmit={createContact}/>
            </div>
          </MobileDisplayAsModal>
          }
        </div>
        <div className="contacts">
          {displayContacts && accountContacts.map(contact =>
            <ContactDetailsRow
              orgId={orgId}
              key={contact.id}
              contact={contact}
              onContactUpdate={updateContact}
              onContactDelete={() => deleteContact(contact)}
            />
          )}
        </div>
      </div>}
    </div>
  </OrdoSpinner>;
};
