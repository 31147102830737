import React from 'react';
import {ProductWithAvailability} from '../../../../models/productWithAvailability';

export const ItemInfo = ({product} : {product: ProductWithAvailability}) => {
  return (
    <div className="item-info">
      <img src={product.imageUrl} alt={product.name} className="product-image"/>
      <div className="product-info">
        <div title={product.name} className="product-info__line product-info__name">{product.name}</div>
        <span className="product-info__line">{product.brand.name}</span>
        <span className="product-info__line">{product.categoryToString()}</span>
        <span className="product-info__line">{product.geneticsToString()}</span>
      </div>
    </div>
  );
};
