import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import {useAuth} from '../../context/AuthContext';
import {UserSessionContext} from '../../context/UserSessionContext';
import {useAPI} from '../../context/OrdoApiContext';
import OrdoSpinner from '../components/OrdoSpinner';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';

const VerifyEmail = ({redirectLink}: { redirectLink: string }) => {
  const history = useHistory();
  const {update, user} = useContext(UserSessionContext);
  const {errorToast, successToast} = useOrdoToasts();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const api = useAPI();
  const auth = useAuth();

  const goToProfilePage = () => history.push(ROUTE_NAMES.PROFILE);

  const verifyEmail = async () => {
    showSpinner();
    try {
      await auth.verifyEmailVerificationCode(user().email, redirectLink);
      const userSession = await api.verifyEmail(user().email);
      update(userSession);
      successToast('Email verified successfully');
    } catch (error) {
      errorToast(error.message);
    } finally {
      hideSpinner();
      goToProfilePage();
    }
  };

  useEffect(() => {
    verifyEmail();
  }, []);

  return (<OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="d-flex justify-content-center">
      <h6 className="h3">Verify Email</h6>
    </div>
  </OrdoSpinner>);
};

export default VerifyEmail;
