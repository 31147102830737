import {ConsumerUnitSize, Genetics} from '../Product';
import {AccountPriority} from '../../pages/sales-activity/salesActivityTypes';

export type ProductSKUPriceRule = {
  customerSKU: string,
  unitPrice: number,
  id: string,
};
export type GroupPricePriceRule = {
  unitPrice: number,
  brandId?: string,
  categoryId?: string,
  genetics?: Genetics,
  consumerUnitSize?: ConsumerUnitSize,
  id: string,
}

export type TierAccount = {
  id: string,
  name: string,
  priority: AccountPriority
}

export class Tier {
  constructor(
    public readonly name: string,
    public readonly productSKUPrices: ProductSKUPriceRule[],
    public readonly groupPrices: GroupPricePriceRule[],
    public accountIds: string[],
    public readonly allAccounts: boolean,
    public priorities: AccountPriority[],
    public readonly id?: string
  ) {}
}