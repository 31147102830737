import React, {FC, useContext, useEffect, useState} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import RegisterPage from './registration/RegisterPage';
import LoginPage from './login/LoginPage';
import ROUTE_NAMES from '../routes/ROUTE_NAMES';
import PrivateRoute from '../routes/PrivateRoute';
import {UserSessionContext} from '../context/UserSessionContext';
import ProfilePage from './profile/ProfilePage';
import OrdoGeneralLayout from './OrdoGeneralLayout';
import OrganizationPage from './profile/OrganizationPage';
import AcceptInvitationPage from './AcceptInvitationPage';
import LoginAndAcceptInvitationPage from './login/LoginAndAcceptInvitationPage';
import AcceptInvitationAndJoinPage from './registration/AcceptInvitationAndJoinPage';
import InventoryPage from './inventory/InventoryPage';
import {AccountsPage} from './accounts/AccountsPage';
import StrictRoute from '../routes/StrictRoute';
import OrderEntryPage from './order-entry/OrderEntryPage';
import OrderHistoryPage from './order-history/OrderHistory';
import '../scss/ordo/scrollbar.scss';
import {UserSessionRefresher} from '../context/UserSessionRefresher';
import {useAPI} from '../context/OrdoApiContext';
import SalesActivityPage from './sales-activity/SalesActivityPage';
import {LaunchDarklyFeatureFlagFetcher} from '../lib/featureFlags/launchDarkly';
import {FeatureFlags, FeatureFlagUser, toFeatureFlagUser} from '../lib/featureFlags';
import PasswordRecoveryPage from './password-recovery/PasswordRecoveryPage';
import {DatadogRum} from '../lib/metrics/datadog';
import AccountManagementPage from './account-management/AccountManagementPage';
import { ActivityManagerPage } from './activity-manager/ActivityManagerPage';
import {AccountPage} from './accounts/account/AccountPage';
import {TimelinePage} from './timeline/TimelinePage';
import OrdoSpinner from './components/OrdoSpinner';
import FeatureFlagsProvider from '../context/FeatureFlagsContext';
import { ContactsPage } from './contacts/ContactsPage';

const App: FC = () => {
  const {update, userSession} = useContext(UserSessionContext);
  const ordoApi = useAPI();
  const refresher = new UserSessionRefresher(ordoApi, update);
  const featureFlagUser: FeatureFlagUser = toFeatureFlagUser(userSession);

  const flagFetcher = LaunchDarklyFeatureFlagFetcher.getInstance(featureFlagUser);
  const defaultFlags = flagFetcher.getAllFlags();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>(defaultFlags);
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false);

  // TODO: remove this user session refresh in the future, once all the users have probably updated their local storage by then
  const userIsLoggedInAndWithCurrentOrgOutdated = !!userSession.currentOrganization && !userSession.currentOrganization?.externalApiConfigs;
  const updateOrgInfoInSession = async () => {
    if (userIsLoggedInAndWithCurrentOrgOutdated) {
      await refresher.refresh();
    }
  };

  useEffect( () => {
    DatadogRum.init();
    flagFetcher.refreshFlagsWhenReady()
      .then((flags) => {
        setFeatureFlags(flags);
        setFeatureFlagsLoaded(true);
      })
      .catch(() => setFeatureFlagsLoaded(true));
    flagFetcher.on('flags-updated', (flags) => {
      setFeatureFlags(flags);
    });
  }, []);

  useEffect(() => {
    updateOrgInfoInSession();
  }, []);

  return (!featureFlagsLoaded ||  userIsLoggedInAndWithCurrentOrgOutdated?
    <OrdoSpinner showSpinner={!featureFlagsLoaded}/> :
    <BrowserRouter>
      <FeatureFlagsProvider featureFlags={featureFlags}>
        <OrdoGeneralLayout>
          <Route
            exact
            path={ROUTE_NAMES.LOGIN}
            render={() => <LoginPage api={ordoApi}/>}
          />
          <Route
            exact
            path={ROUTE_NAMES.LOGIN_AND_ACCEPT_INVITATION}
            render={() => <LoginAndAcceptInvitationPage api={ordoApi}/>}
          />
          <Route
            exact
            path={ROUTE_NAMES.REGISTER}
            render={() => <RegisterPage api={ordoApi}/>}
          />
          <Route
            exact
            path={ROUTE_NAMES.ACCEPT_INVITATION}
            render={() => <AcceptInvitationPage api={ordoApi}/>}
          />
          <Route
            exact
            path={ROUTE_NAMES.ACCEPT_INVITATION_AND_JOIN_ORDO}
            render={() => <AcceptInvitationAndJoinPage api={ordoApi}/>}
          />
          <Route
            exact
            path={ROUTE_NAMES.PASSWORD_RESET}
            render={() => <PasswordRecoveryPage/>}
          />
          <Route
            exact
            path={ROUTE_NAMES.ACCOUNT_MANAGEMENT}
            render={() => <AccountManagementPage/>}
          />
          <Switch>
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.ACTIVITY_MANAGER}
              render={() =>
                <ActivityManagerPage/>
              }
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.TIMELINE}
              render={() =>
                <TimelinePage/>
              }
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.ORDER_ENTRY}
              render={() =>
                <OrderEntryPage/>
              }
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.EDIT_ORDER}
              render={() =>
                <OrderEntryPage/>
              }
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.ACCOUNTS}
              render={() => <AccountsPage/>}
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.ACCOUNT_PAGE}
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              render={(props) => <AccountPage {...props}/>}
            />

            <StrictRoute
              redirectRoute={ROUTE_NAMES.LOGIN}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.ORGANIZATION}
              render={() =>
                <OrganizationPage
                  refresher={refresher}
                />}
            />
            <PrivateRoute
              redirectRoute={ROUTE_NAMES.LOGIN}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.PROFILE}
              render={() =>
                <ProfilePage
                  refresher={refresher}
                />
              }
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.SALES_ACTIVITY}
              render={() =>
                <SalesActivityPage/>
              }
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.INVENTORY}
              render={() =>
                <InventoryPage
                  refresher={refresher}
                />
              }
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.ORDER_HISTORY}
              render={() => <OrderHistoryPage />}
            />
            <StrictRoute
              redirectRoute={ROUTE_NAMES.PROFILE}
              cookies={() => document.cookie}
              currentTime={() => Date.now()}
              userSessionContext={UserSessionContext}
              exact
              path={ROUTE_NAMES.CONTACTS_PAGE}
              render={() =>
                <ContactsPage/>
              }
            />
          </Switch>
        </OrdoGeneralLayout>
      </FeatureFlagsProvider>
    </BrowserRouter>);
};

export default App;
