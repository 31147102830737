import React, {useContext, useState} from 'react';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import {ActivityTemplatesManager} from './components/activityTemplatesManager/ActivityTemplatesManager';
import {OrdoPageTabsProps} from '../components/common/OrdoPageTabs';
import {ActivitiesPage} from './components/activitiesPage/ActivitiesPage';
import {AdminChecker} from '../../routes/permissions';
import {UserSessionContext} from '../../context/UserSessionContext';
import {isMobileResolution, Resolution} from '../../context/resolutions';
import {useResolution} from '../../context/UseResolutions';
import { ActivitiesMobilePage } from './components/activitiesPage/mobile/ActivitiesMobilePage';
import '../../scss/ordo/activitiesPage/mobile-activities-page.scss';

enum ActivitiesTabState {
  Manager,
  Activities
}

export const ActivityManagerPage = () => {
  const [tabState, setTabState] = useState<ActivitiesTabState>(ActivitiesTabState.Activities);
  const adminChecker = new AdminChecker();
  const userSession = useContext(UserSessionContext);
  const resolution: Resolution = useResolution();

  const inMobileResolution = isMobileResolution(resolution);
  const tabProps: OrdoPageTabsProps = {
    buttons: [
      {
        text: 'activities',
        onClick: () => setTabState(ActivitiesTabState.Activities),
        dataTestId: 'activities-page-tab-button-activities'
      },
      {
        text: 'manager',
        onClick: () => setTabState(ActivitiesTabState.Manager),
        dataTestId: 'activities-page-tab-button-manager'
      }
    ]
  };

  const renderContent = () => {
    switch (tabState) {
    case ActivitiesTabState.Activities:
      return <ActivitiesPage/>;
    case ActivitiesTabState.Manager:
      return <ActivityTemplatesManager/>;
    default:
      return <ActivitiesPage/>;
    }
  };

  return(
    <div className='activity-manager-page'>
      {!inMobileResolution &&
          <>
            <OrdoPageTitle title='activity manager'
              buttons={adminChecker.allow(userSession.userSession) ? tabProps.buttons : []}/>
            <div className="page-content-body">
              {renderContent()}
            </div>
          </>
      }
      {
        inMobileResolution &&
          <>
            <div className="mobile-activities-content-body">
              <ActivitiesMobilePage/>
            </div>
          </>
      }
    </div>
  );
};
