import React, {useState} from 'react';
import OrdoModal from '../common/OrdoModal';
import OrdoSpinner from '../OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import ContactModalViewModel from '../../../application-models/order-entry/ContactModalViewModel';
import {ContactForm} from '../contacts/ContactForm';
import {useAPI} from '../../../context/OrdoApiContext';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import Contact from '../../../models/order-entry/Contact';
import Account from '../../../models/Account';
import OrdoDropdown from '../common/OrdoDropdown';

export type ContactFormData = {
  name: string
  email: string
  phone: string
  title: string
}

type AddContactModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: (contact: Contact) => Promise<void>,
  orgId: string,
  accountId: string,
  displayLabel?: boolean,
  accounts?: Account[]
}

const AddContactModal = ({isOpen, onClose, onSubmit, orgId, accountId, accounts, displayLabel = true}: AddContactModalProps) => {
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const api = useAPI();
  const [addContactModalViewModel, setAddContactModalViewModel] = useState(ContactModalViewModel.empty(api));
  const toasts = useOrdoToasts();

  const [selectedAccount, setAccount] = useState<Account | null>(accounts && accounts.length > 0 ? accounts[0] : null);
  const createContact = async () => {
    showSpinner();
    addContactModalViewModel.createContact(selectedAccount ? selectedAccount.id : accountId, orgId)
      .then((newContact)=> {
        toasts.successToast('Contact created successfully');
        onSubmit(newContact).then(()=> setAddContactModalViewModel(ContactModalViewModel.empty(api)));
      })
      .catch(() => toasts.errorToast('Failed to create contact'))
      .finally(() => {
        hideSpinner();
        onClose();
      });
  };

  return (<div>
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoModal show={isOpen}
        title='add a contact'
        onClose={() => onClose()}
        onSubmit={() => {}}
        showFooter={false}
        actionText='save'
        disableCTA={!addContactModalViewModel.canSubmit()}
      >
        {accounts &&
            <div className='contact-form-account-selection'>
              {displayLabel && <span>account</span>}
              <OrdoDropdown
                height="short"
                selectedOption={selectedAccount}
                placeholder='select an account'
                onSelect={(account: Account) => setAccount(account)}
                options={accounts}
                displayName={(account: Account) => account.name}
                forceLowerCase={false}
                disabled={!accounts}
              />
            </div>
        }
        <ContactForm
          viewModel={addContactModalViewModel}
          updateViewModel={setAddContactModalViewModel}
          onCancel={() => onClose()}
          onSubmit={createContact}
          canSubmit={!!accountId || !!selectedAccount}
        />
      </OrdoModal>
    </OrdoSpinner>
  </div>);
};

export default  AddContactModal;
