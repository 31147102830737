import React, {useContext, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPercent} from '@fortawesome/free-solid-svg-icons';
import {faTimes} from '@fortawesome/free-solid-svg-icons/faTimes';
import AddBatchViewModel from '../../../application-models/add-batch-view-model';
import {useAPI} from '../../../context/OrdoApiContext';
import {UserSessionContext} from '../../../context/UserSessionContext';
import Product from '../../../models/Product';
import OrdoFileInput from '../common/inputs/OrdoFileInput';
import OrdoInput from '../common/inputs/OrdoInput';
import '../../../scss/ordo/inventoryPage/batches.scss';
import ProductHeader from '../product/ProductHeaderPreview';
import OrdoModal from '../common/OrdoModal';
import GeneralErrorMessage from '../common/GeneralErrorMessage';
import {BatchFormFields} from '../../../errors/form_error';
import OrdoSpinner from '../OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoInformativeSearchableDropdown from '../common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import Batch from '../../../models/Batch';
import OrdoDatepicker, {OrdoDatepickerDisplayMode, SelectableDates} from '../common/OrdoDatepicker';

type AddBatchModalProps = {
  isOpen: boolean,
  onClose: Function,
  product: Product,
  batch?: Batch,
  onBatchUpdate: (batch: Batch) => void
}

const BatchModal = ({onClose, isOpen, product, batch, onBatchUpdate}: AddBatchModalProps) => {
  const api = useAPI();
  const userSession = useContext(UserSessionContext);

  const organization = userSession.currentOrganization()!;

  const freshViewModel = () => AddBatchViewModel.initialize(api, organization, product, batch);
  const [viewModel, setViewModel] = useState(freshViewModel());
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const closeModal = () => {
    setViewModel(freshViewModel());
    onClose();
  };

  const addBatch = async () => {
    setViewModel(viewModel.setInProgress());
    showSpinner();
    const newViewModel = await viewModel.addBatch();
    if(!newViewModel.hasError()) {
      closeModal();
    } else {
      setViewModel(newViewModel);
    }
    hideSpinner();
  };

  const updateBatch = async () => {
    setViewModel(viewModel.setInProgress());
    showSpinner();
    const newViewModel = await viewModel.editBatch();
    if(!newViewModel.hasError()) {
      onBatchUpdate(newViewModel.batch);
      onClose();
    } else {
      setViewModel(newViewModel);
    }
    hideSpinner();
  };

  const submitAction = () => {
    if(batch) {
      return updateBatch();
    }
    return addBatch();
  };
  const updateBatchId = (batchId: string): void => {
    const newViewModel = viewModel.updateBatchId(batchId);
    setViewModel(newViewModel);
  };

  const updateBatchQuantity = (batchQuantity: string): void => {
    const newViewModel = viewModel.updateBatchQuantity(parseInt(batchQuantity, 10));
    setViewModel(newViewModel);
  };

  const updateBatchCoaFile = (coaFile: any): void => {
    const newViewModel = viewModel.updateBatchCoaFile(coaFile);
    setViewModel(newViewModel);
  };

  const validateBatchId = (): void => {
    const newViewModel = viewModel.validateBatchId();
    setViewModel(newViewModel);
  };

  const validateDistributorOrganizationLicense = () => {
    const newViewModel = viewModel.validateDistributorOrganizationLicenseId();
    setViewModel(newViewModel);
  };

  const validateNumberOfUnits = (): void => {
    const newViewModel = viewModel.validateNumberOfCases();
    setViewModel(newViewModel);
  };

  function invalidMessageFor(field: BatchFormFields, isValidField: boolean) {
    const errorMessage = viewModel.errorMessageFor(field);
    if (errorMessage) {
      return errorMessage;
    }
    return !isValidField ? 'Required field' : '';
  }
  const formId = 'modal-form';

  const updateBatchAdvertisedTHC = (newAdvertisedTHC: string) => {
    setViewModel(viewModel.updateAdvertisedTHC(parseInt(newAdvertisedTHC, 10)));
  };

  const updateBatchAdvertisedCBD = (newAdvertisedCBD: string) => {
    setViewModel(viewModel.updateAdvertisedCBD(parseInt(newAdvertisedCBD, 10)));
  };

  const updatePackagedDate = (date: Date) => {
    setViewModel(viewModel.updatePackagedDate(date));
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <OrdoModal title={`${batch ? 'edit batch' : `add a batch for ${product.name}`}`}
      formId={formId}
      show={isOpen}
      onClose={closeModal}
      showFooter={viewModel.hasDistributors()}
      actionText={`${batch ? 'update batch' : 'upload batch'}`}
      disableCTA={!viewModel.isValidBatch || viewModel.inProgress}>
      {viewModel.hasDistributors() &&
          <>
            <ProductHeader product={product}/>
            <form id={formId} onSubmit={(event) => {
              event.preventDefault();
              submitAction();
            }}>
              <GeneralErrorMessage viewModel={viewModel}/>
              <div className="form-group mb-5 ">
                <div className="form-group mt-3">
                  <OrdoInput inputName="batch-id"
                    value={viewModel.batch.batchId}
                    placeholder="batch ID"
                    inputSize="large"
                    isValid={viewModel.isValidBatchId && !viewModel.hasErrorFor('batch-id')}
                    invalidMessage={invalidMessageFor('batch-id', viewModel.isValidBatchId)}
                    onChange={updateBatchId}
                    onBlur={validateBatchId}
                    inputTitle="enter batch ID"
                  />
                </div>
                <div className="form-group mt-3 fulfillment-location-container">
                  <span className='fulfillment-location-title'>fulfillment location</span>
                  <OrdoInformativeSearchableDropdown
                    dataTestId='select-account'
                    selectedOption={viewModel.getSelectedDistributorOrganizationLicense()}
                    options={viewModel.getSelectableDistributorOrganizationLicenses()}
                    onChangeSelectedOption={(selected) => setViewModel(viewModel.setDistributorOrganizationLicense(selected))}
                    canEditValue={viewModel.hasMoreThanOneDistributor()}
                    placeholder='select fulfillment center'
                    singleRowHeight
                    css={{
                      marginBottom: 0
                    }
                    }
                    onBlur={validateDistributorOrganizationLicense}
                    addedStyles={!viewModel.isValidDistributorOrganizationLicenseId ? {
                      control: {
                        border: '2px solid #FF7070',
                        boxShadow: 'none',
                        '&:hover': {
                          border: '2px solid #FF7070',
                          boxShadow: 'none'
                        }
                      },
                      marginBottom: '0'
                    } : {}}
                  />
                  {!viewModel.isValidDistributorOrganizationLicenseId && <span className='error-message'>Required field</span>}
                </div>
                <div className="form-group mt-3">
                  <div className="thc-and-cbd-container">
                    <div className="advertised-percentage">
                      <OrdoInput inputName="number-of-units"
                        value={viewModel.batch.advertisedTHC}
                        placeholder="0"
                        min={0}
                        max={100}
                        inputSize="large"
                        isValid={viewModel.isValidNumberOfAdvertisedTHC() && !viewModel.hasErrorFor('number-of-units')}
                        invalidMessage={invalidMessageFor('number-of-units', viewModel.isValidNumberOfAdvertisedTHC())}
                        onChange={updateBatchAdvertisedTHC}
                        onBlur={validateNumberOfUnits}
                        inputTitle="total thc"
                        bottomMargin={false}
                        inputType="number"
                      />
                      <FontAwesomeIcon className="icon" icon={faPercent}/>
                    </div>
                    <div className="advertised-percentage">
                      <OrdoInput inputName="number-of-cases"
                        value={viewModel.batch.advertisedCBD || ''}
                        placeholder="0"
                        inputSize="large"
                        min={0}
                        max={100}
                        onChange={updateBatchAdvertisedCBD}
                        onBlur={validateNumberOfUnits}
                        inputTitle="total cbd"
                        bottomMargin={false}
                        inputType="number"
                      />
                      <FontAwesomeIcon  className="icon" icon={faPercent}/>
                    </div>
                  </div>
                  <div className='packaged-date-container'>
                    <OrdoDatepicker
                      value={viewModel.getPackagedDate()}
                      placeholder="select a packaged date"
                      displayTime={false}
                      displayYear
                      onChange={(selectedDate: Date) => updatePackagedDate(selectedDate)}
                      displayMode={OrdoDatepickerDisplayMode.SINGLE_LINE}
                      validDates={SelectableDates.ALL_TIME}
                    />
                    {viewModel.getPackagedDate() && <FontAwesomeIcon className='icon' onClick={() => {
                      setViewModel(viewModel.removePackagedDate());
                    }} icon={faTimes}/>}
                  </div>
                </div>
                <div className="form-group mt-3">
                  <OrdoInput inputName="number-of-units"
                    value={viewModel.batch.quantityOfUnits}
                    placeholder="# of units"
                    inputSize="large"
                    isValid={viewModel.isValidNumberOfUnits && !viewModel.hasErrorFor('number-of-units')}
                    invalidMessage={invalidMessageFor('number-of-units', viewModel.isValidNumberOfUnits)}
                    onChange={updateBatchQuantity}
                    onBlur={validateNumberOfUnits}
                    inputTitle="number of units"
                    inputType="number"
                  />
                </div>
                <div className="d-flex flex-column align-items-start">
                  <OrdoFileInput text="coa document"
                    onFileSelected={updateBatchCoaFile}
                    isValid={viewModel.isValidCoa && !viewModel.hasErrorFor('coa')}
                    invalidMessage={invalidMessageFor('coa', viewModel.isValidCoa)}/>
                  <div className='coa-document-section'>
                    <span className="ml-3">{viewModel.batchCoaFileName}</span>
                    {viewModel.batchCoaFileName && <FontAwesomeIcon className='icon' onClick={() => {setViewModel(viewModel.removeCOAFile());}} icon={faTimes}/>}
                  </div>
                </div>
              </div>
            </form>
          </>
      }
      {
        !viewModel.hasDistributors() &&
          <div className='no-licenses'>
            <span className='text-dark'>Please, enable an organization license to upload a batch</span>
          </div>
      }
    </OrdoModal>
  </OrdoSpinner>;
};

export default BatchModal;
