import isValidEmail from '../utils/string';
import InvalidField from '../errors/invalid_field';
import {FormFields} from '../errors/form_error';

export interface UserRegistrationInput {
  firstName: string,
  lastName: string,
  phone: string,
  email: string,
  password: string,
  passwordConfirmation: string
}

export default class UserRegistration {
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public password: string;
  public passwordConfirmation: string;

  constructor(userRegistrationInput: UserRegistrationInput) {
    this.firstName = userRegistrationInput.firstName;
    this.lastName = userRegistrationInput.lastName;
    this.email = userRegistrationInput.email;
    this.phone = userRegistrationInput.phone;
    this.password = userRegistrationInput.password;
    this.passwordConfirmation = userRegistrationInput.passwordConfirmation;
  }

  public static emptyUserRegistration() {
    return new UserRegistration({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirmation: '',
    });
  }

  public isValid() {
    return this.validateEmail() && this.validatePassword() && this.validatePasswordConfirmation()
      && this.validatePhone() && this.validateFirstName() && this.validateLastName() && this.validatePasswordMatch();
  }

  public invalidFields() {
    const invalidFields = [];
    if (!this.validateFirstName()) invalidFields.push(this.invalidField('firstName'));
    if (!this.validateLastName()) invalidFields.push(this.invalidField('lastName'));
    if (!this.validateEmail()) invalidFields.push(this.invalidField('email'));
    if (!this.validatePhone()) invalidFields.push(this.invalidField('phone'));
    if (!this.validatePassword()) invalidFields.push(this.invalidField('password'));
    if (!this.validatePasswordConfirmation()) invalidFields.push(this.invalidField('passwordConfirmation'));
    if (!this.validatePasswordMatch()) invalidFields.push(this.invalidField('passwordConfirmation'));
    return invalidFields;
  }

  invalidField(field: string) {
    if (field === 'email') return new InvalidField('email', 'The email is invalid');
    if (field === 'passwordConfirmation' && !this.validatePasswordMatch()) return new InvalidField('passwordConfirmation', 'Passwords don\'t match');
    return new InvalidField(field as FormFields, 'Mandatory field');
  }

  private validateFirstName() {
    return !!this.firstName;
  }

  private validateLastName() {
    return !!this.lastName;
  }

  private validateEmail() {
    return isValidEmail(this.email);
  }

  private validatePhone() {
    return !!this.phone;
  }

  private validatePassword() {
    return !!this.password;
  }

  private validatePasswordConfirmation() {
    return !!this.passwordConfirmation;
  }

  private validatePasswordMatch() {
    return this.password === this.passwordConfirmation;
  }

}
