import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {ToastProvider} from 'react-toast-notifications';
import firebase from 'firebase';
import OrdoAPI from './lib/api/ordoApi';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import FirebaseAuthService from './services/auth_service';
import UserSessionContextProvider from './context/UserSessionContext';
import OrderEntryCartContextProvider from './context/OrderEntryCartContext';
import OrdoApiContextProvider from './context/OrdoApiContext';
import AuthProvider from './context/AuthContext';
import {appConfig} from './lib/config';

const firebaseApp = firebase.initializeApp(appConfig.firebase);
firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

const authService = FirebaseAuthService.createService(firebaseApp);
ReactDOM.render(
  <React.StrictMode>
    <UserSessionContextProvider>
      <OrderEntryCartContextProvider storage={localStorage}>
        <OrdoApiContextProvider api={OrdoAPI.createAPI(authService)}>
          <AuthProvider authService={authService}>
            <ToastProvider>
              <App/>
            </ToastProvider>
          </AuthProvider>
        </OrdoApiContextProvider>
      </OrderEntryCartContextProvider>
    </UserSessionContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
