import InvalidField from '../errors/invalid_field';
import {emptyOrganization, OrganizationRegistrationInput} from './Organization';
import {FormFields} from '../errors/form_error';

export default class OrganizationRegistration {
  public id: string;
  public name: string;
  public state: string;
  public size: string;
  public termsOfUseAccepted: boolean;

  constructor(companyRegistrationInput: OrganizationRegistrationInput) {
    this.id = companyRegistrationInput.id;
    this.name = companyRegistrationInput.name;
    this.state = companyRegistrationInput.state;
    this.size = companyRegistrationInput.size;
    this.termsOfUseAccepted = companyRegistrationInput.termsOfUseAccepted;
  }

  public static emptyCompanyRegistration() {
    return new OrganizationRegistration({...emptyOrganization, state: 'California', termsOfUseAccepted: false} as OrganizationRegistrationInput);
  }

  public isValid() {
    return this.validCompanyName() && this.validateSize() && this.validateState() && this.validateTermsOfUseAccepted();
  }

  public invalidFields() {
    const invalidFields = [];
    if (!this.validCompanyName()) invalidFields.push(this.invalidField('name'));
    if (!this.validateState()) invalidFields.push(this.invalidField('state'));
    if (!this.validateSize()) invalidFields.push(this.invalidField('size'));
    if (!this.validateTermsOfUseAccepted()) invalidFields.push(this.invalidField('termsOfUse', 'You must accept our terms of use & service.'));
    return invalidFields;
  }

  invalidField(field: FormFields, message: string = 'Mandatory field') {
    return new InvalidField(field, message);
  }

  private validateState() {
    return !!this.state;
  }

  private validCompanyName() {
    return !!this.name;
  }

  private validateSize() {
    return !!this.size;
  }

  private validateTermsOfUseAccepted() {
    return this.termsOfUseAccepted;
  }
}
