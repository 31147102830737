import React, {useContext} from 'react';
import '../../../scss/ordo/member-card.scss';
import '../../../scss/ordo/common.scss';
import {Member} from '../../../models/member/Member';
import OrdoCardOptionsDropdown from '../common/OrdoCardOptionsDropdown';
import {UserSessionContext} from '../../../context/UserSessionContext';

type MemberCardProps = {
  member: Member,
  onEditRole: (member: Member) => void,
  onDeleteInvitation: (member: Member) => void,
  onDeleteMember: (member: Member) => void
}

const MemberCard = ({member, onEditRole, onDeleteInvitation, onDeleteMember}: MemberCardProps) => {
  const {getCurrentMember} = useContext(UserSessionContext);

  const memberOptions = [{
    name: 'Edit role',
    action: () => onEditRole(member)
  },
  {
    name: 'Delete member',
    action: () => onDeleteMember(member)
  }];
  const notMemberOptions = [{
    name: 'Delete invitation',
    action: () => onDeleteInvitation(member)
  }];

  const shouldShowMemberOptions = () => {
    return member.alreadyMember;
  };

  const shouldShowOptions = () => {
    const currentMember = getCurrentMember();
    const isOwnCard = currentMember.hasEmail(member.email);
    return !currentMember.isSalesRep() && !isOwnCard;
  };

  return (
    <div className="card card-fluid member-card ordo-card-border ordo-shadow">
      <div className="d-flex justify-content-between align-items-end" data-testid={`ordo-member-card-${member.getName()}`}>
        <h6 data-testid={`ordo-member-card-title-${member.getName()}`}
          className={`member-card__body__owner_name ${(!member.alreadyMember ? 'pending_invite' : '')}`}>
          {member.getName()}
        </h6>
        {shouldShowOptions() && <OrdoCardOptionsDropdown options={shouldShowMemberOptions() ? memberOptions : notMemberOptions}/>}
      </div>
      <div className="d-flex">
        <div className="d-flex justify-content-center">
          <div>
            <p className="text-muted mb-0 text-truncate member-card__body__email_and_phone">
              {member.email}
            </p>
            <p className="text-muted mb-0 text-truncate member-card__body__email_and_phone">
              {member.getPhone() || <br/>}
            </p>
          </div>
        </div>
        <br/>
      </div>
    </div>
  );
};

export default MemberCard;
