import React from 'react';

export type OrderHistoryHeader = {
  title: string,
  isWide: boolean
}

type OrderHistoryOrderListHeadersProps = {
  headers: OrderHistoryHeader[]
}
const OrderHistoryOrderListHeaders = ({headers} : OrderHistoryOrderListHeadersProps) => {
  return <div className="order-history-order-item-container">
    <div className="order-history-headers headers">
      {headers.map((header: OrderHistoryHeader) => {
        return <div key={header.title} className={`order-item-column ${header.isWide ? 'wider-column' : 'narrower-column'}  d-flex flex-column`}>
          <span>{header.title}</span>
        </div>;
      })}
    </div>
  </div>;
};

export default OrderHistoryOrderListHeaders;
