import {
  HeaderColumns,
  SalesActivityAccount,
  SalesActivityColumns,
  SalesActivityOrder
} from '../../../pages/sales-activity/salesActivityTypes';
import SalesActivityViewModel from '../SalesActivityViewModel';
import {PipelineButtonText, UpdateAccountPriorityClickHandler} from '../../../pages/sales-activity/salesActivityFunctions';
import {API} from '../../../lib/api/ordoApi';

export type SalesActivityTableInput = {
  salesActivityViewModel: SalesActivityViewModel,
  salesActivityPendingOrders: SalesActivityOrder[],
  salesActivityAwaitingDeliveryOrders: SalesActivityOrder[],
  salesActivityCollectPaymentOrders: SalesActivityOrder[],
  nonQualifiedAccounts: SalesActivityAccount[],
  lostAccounts: SalesActivityAccount[],
  prospectAccounts: SalesActivityAccount[],
  verifiedAccounts: SalesActivityAccount[],
  staleAccounts: SalesActivityAccount[],
  showAccountDetails: Function,
  onAccountPriorityClick: UpdateAccountPriorityClickHandler,
  openProspectCTAModal: (account: SalesActivityAccount) => void,
  openStaleAccountUpdateStatusModal: (account: SalesActivityAccount) => void,
  stageOrder: (account: SalesActivityAccount) => void,
  trackOrder: (account: SalesActivityAccount) => void,
  openCollectPaymentConfirmationModal: (order: SalesActivityOrder) => void,
  openOrderDetails: (order: SalesActivityOrder, cta: any) => void,
  openAccountLostCTAModal: (account: SalesActivityAccount) => void,
  confirmOrder: (order: SalesActivityOrder) => void,
  confirmOrderFromDrawer: (order: SalesActivityOrder) => void,
  openVerifyDeliveryModal: (order: SalesActivityOrder) => void,
  accountCardMinWidth: number
}

export type SalesActivityPipeline = { header: HeaderColumns, columns: SalesActivityColumns };

export abstract class SalesActivityTable {

  public readonly salesActivityViewModel: SalesActivityViewModel;
  public readonly salesActivityPendingOrders: SalesActivityOrder[];
  public readonly salesActivityAwaitingDeliveryOrders: SalesActivityOrder[];
  public readonly salesActivityCollectPaymentOrders: SalesActivityOrder[];
  public readonly nonQualifiedAccounts: SalesActivityAccount[];
  public readonly lostAccounts: SalesActivityAccount[];
  public readonly prospectAccounts: SalesActivityAccount[];
  public readonly verifiedAccounts: SalesActivityAccount[];
  public readonly staleAccounts: SalesActivityAccount[];
  public readonly showAccountDetails: Function;
  public readonly onAccountPriorityClick: UpdateAccountPriorityClickHandler;
  public readonly openProspectCTAModal: (account: SalesActivityAccount) => void;
  public readonly openStaleAccountUpdateStatusModal: (account: SalesActivityAccount) => void;
  public readonly stageOrder: (account: SalesActivityAccount) => void;
  public readonly trackOrder: (account: SalesActivityAccount) => void;
  public readonly openCollectPaymentConfirmationModal: (order: SalesActivityOrder) => void;
  public readonly openOrderDetails: (order: SalesActivityOrder, cta: any) => void;
  public readonly openAccountLostCTAModal: (account: SalesActivityAccount) => void;
  public readonly confirmOrder: (order: SalesActivityOrder) => void;
  public readonly confirmOrderFromDrawer: (order: SalesActivityOrder) => void;
  public readonly openVerifyDeliveryModal: (order: SalesActivityOrder) => void;
  public readonly accountCardMinWidth: number;

  constructor(input: SalesActivityTableInput) {
    this.salesActivityViewModel = input.salesActivityViewModel;
    this.salesActivityPendingOrders = input.salesActivityPendingOrders;
    this.salesActivityAwaitingDeliveryOrders = input.salesActivityAwaitingDeliveryOrders;
    this.salesActivityCollectPaymentOrders = input.salesActivityCollectPaymentOrders;
    this.nonQualifiedAccounts = input.nonQualifiedAccounts;
    this.lostAccounts = input.lostAccounts;
    this.prospectAccounts = input.prospectAccounts;
    this.verifiedAccounts = input.verifiedAccounts;
    this.staleAccounts = input.staleAccounts;
    this.showAccountDetails = input.showAccountDetails;
    this.onAccountPriorityClick = input.onAccountPriorityClick;
    this.openProspectCTAModal = input.openProspectCTAModal;
    this.openStaleAccountUpdateStatusModal = input.openStaleAccountUpdateStatusModal;
    this.stageOrder = input.stageOrder;
    this.trackOrder = input.trackOrder;
    this.openCollectPaymentConfirmationModal = input.openCollectPaymentConfirmationModal;
    this.openOrderDetails = input.openOrderDetails;
    this.openAccountLostCTAModal = input.openAccountLostCTAModal;
    this.confirmOrder = input.confirmOrder;
    this.confirmOrderFromDrawer = input.confirmOrderFromDrawer;
    this.openVerifyDeliveryModal = input.openVerifyDeliveryModal;
    this.accountCardMinWidth = input.accountCardMinWidth;
  }

  public abstract buildPipeline(): SalesActivityPipeline

  public abstract buyerAction():  (account: SalesActivityAccount) => void

  public abstract buyerActionText():  PipelineButtonText

  public canTrackOrder(): boolean {
    return true;
  }

  fetchPendingOrders(_api: API, _id: string, _setSalesActivityPendingOrders: Function, _errorCallback: Function) {
    // DO NOTHING OR IMPLEMENT BY SUBCLASSES
  }

  fetchAwaitingDeliveryOrders(_api: API, _id: string, _setSalesActivityConfirmedOrders: Function, _errorCallback: Function) {
    // DO NOTHING OR IMPLEMENT BY SUBCLASSES
  }

  fetchCollectPaymentOrders(api: API, id: string, setSalesActivityDeliveredOrders: Function, errorCallback: Function) {
    this.salesActivityViewModel.fetchDeliveredOrders(api, id, setSalesActivityDeliveredOrders, errorCallback);
  }

  fetchNonQualifiedAccounts(api: API, id: string, setNonQualifiedAccounts: Function, errorCallback: Function) {
    this.salesActivityViewModel.fetchNonQualifiedAccounts(api, id, [], setNonQualifiedAccounts, errorCallback);
  }

  fetchLostAccounts(api: API, id: string, setLostAccounts: Function, errorCallback: Function) {
    this.salesActivityViewModel.fetchLostAccounts(api, id, [], setLostAccounts, errorCallback);
  }

  fetchProspectAccounts(api: API, id: string, setProspectAccounts: Function, errorCallback: Function) {
    this.salesActivityViewModel.fetchProspectAccounts(api, id, [], setProspectAccounts, errorCallback);
  }

  fetchVerifiedAndStaleAccounts(api: API, id: string, setVerifiedAccounts: Function, setStaleAccounts: Function, errorCallback: Function) {
    this.salesActivityViewModel.fetchVerifiedAndStaleAccounts(api, id, [], [], setVerifiedAccounts, setStaleAccounts, errorCallback);
  }
}

