import React, {useEffect, useState} from 'react';
import OrdoInput from '../components/common/inputs/OrdoInput';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import {
  CreateOrganizationLicenseInput,
  CreateOrganizationLicenseViewModel,
  OrganizationLicenseFields
} from '../../application-models/organization-page/CreateOrganizationLicenseViewModel';
import OrdoModal from '../components/common/OrdoModal';


export type CreateOrganizationLicenseModalProps = {
  onSubmit: (licenseData: CreateOrganizationLicenseInput) => Promise<void>,
  onClose: () => void,
  isOpen: boolean,
  licenseNumberError?: string,
  organizationState: string
}

export const CreateOrganizationLicenseModal = ({onSubmit, onClose, isOpen, licenseNumberError, organizationState}: CreateOrganizationLicenseModalProps) => {
  const [viewModel, setViewModel] = useState(CreateOrganizationLicenseViewModel.empty(organizationState));

  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const resetViewModel = () => {
    setViewModel(CreateOrganizationLicenseViewModel.empty(organizationState));
  };

  useEffect(resetViewModel,[isOpen]);


  const submit = async () => {
    viewModel.validateFields();
    if(!viewModel.hasError()) {
      showSpinner();
      await onSubmit(viewModel.organizationLicenseData);
      hideSpinner();
    }
  };

  const update = (fields: OrganizationLicenseFields) => {
    setViewModel(viewModel.updateInformation(fields));
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoModal
        show={isOpen} onClose={onClose}
        onSubmit={submit} actionText="Add license"
        disableCTA={viewModel.shouldDisableButton()}
        disabledCTAText={viewModel.disabledButtonText()}
        dataTestId="add-license"
      >
        <OrdoInput
          inputTitle="license number"
          placeholder="license number"
          onChange={(value) => update({externalLicenseId: value})}
          inputName='licenseNumber'
          onBlur={() => {}}
          value={viewModel.licenseNumber}
          inputSize='medium'
          isValid={!(viewModel.hasErrorFor('licenseNumber') || licenseNumberError)}
          invalidMessage={licenseNumberError || viewModel.errorMessage('licenseNumber')}
        />
        <OrdoInput
          placeholder="state"
          inputTitle="state"
          onChange={(value) => update({state: value})}
          inputName='state'
          onBlur={() => {}}
          value={viewModel.state}
          inputSize='medium'
          readOnly
          isValid={!viewModel.hasErrorFor('state')}
          invalidMessage={viewModel.errorMessage('state')}
        />
        <OrdoInput
          inputTitle="city"
          placeholder="city"
          onChange={(value) => update({city: value})}
          inputName='city'
          onBlur={() => {}}
          value={viewModel.city}
          inputSize='medium'
          isValid={!viewModel.hasErrorFor('city')}
          invalidMessage={viewModel.errorMessage('city')}
        />
        <OrdoInput
          inputTitle="street address line 1"
          placeholder="street address line 1"
          onChange={(value) => update({streetAddressLine1: value})}
          inputName='streetAddressLine1'
          onBlur={() => {}}
          value={viewModel.streetAddressLine1}
          inputSize='medium'
          isValid={!viewModel.hasErrorFor('streetAddressLine1')}
          invalidMessage={viewModel.errorMessage('streetAddressLine1')}
        />
        <OrdoInput
          inputTitle="street address line 2"
          placeholder="street address line 2"
          onChange={(value) => update({streetAddressLine2: value})}
          inputName='streetAddressLine2'
          onBlur={() => {}}
          value={viewModel.streetAddressLine2}
          inputSize='medium'
          isValid={!viewModel.hasErrorFor('streetAddressLine2')}
          invalidMessage={viewModel.errorMessage('streetAddressLine2')}
        />
        <OrdoInput
          placeholder="zip code"
          inputTitle="zip code"
          onChange={(value) => update({zipCode: value})}
          inputName='zipCode'
          onBlur={() => {}}
          value={viewModel.zipCode}
          inputSize='medium'
          isValid={!viewModel.hasErrorFor('zipCode')}
          invalidMessage={viewModel.errorMessage('zipCode')}
        />
      </OrdoModal>
    </OrdoSpinner>
  );
};
