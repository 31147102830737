import React, {PropsWithChildren, ReactNode, useState} from 'react';
import {assign} from 'lodash';
import {Organization} from '../models/Organization';
import {notLoggedInUserSession, User} from '../models/User';
import {UserSession} from '../models/UserSession';
import {USER_SESSION_KEY} from './local-storage-constants';
import {ActualMember} from '../models/member/ActualMember';
import {Role} from '../models/Roles';

export type UserSessionContextData = {
  userSession: UserSession,
  update: (session: Partial<UserSession>) => void,
  user: () => User,
  userEmailIsVerified: () => boolean,
  role: () => Role,
  getCurrentMember: () => ActualMember,
  currentOrganization: () => Organization | undefined,
  organizations: () => Organization[],
  clearSessionData: () => void
}

export const defaultValue: UserSessionContextData = {
  userSession: notLoggedInUserSession,
  update: () => {},
  user: () => notLoggedInUserSession.user,
  userEmailIsVerified: () => notLoggedInUserSession.emailVerified,
  role: () => notLoggedInUserSession.role,
  getCurrentMember: () => new ActualMember(notLoggedInUserSession.role, notLoggedInUserSession.user),
  currentOrganization: () => notLoggedInUserSession.currentOrganization,
  clearSessionData: () => {},
  organizations: () => notLoggedInUserSession.organizations,
};

function sanitizeLocalStorageSession(session: any): UserSession {
  const {currentOrganization} = session;
  const org :Organization = currentOrganization ? {...currentOrganization, externalApiConfigs: currentOrganization.externalApiConfigs || []} : currentOrganization;
  return {...session, currentOrganization: org};
}

function getUserSessionData(storage: Storage, defaultUserSession?: UserSession): UserSession {
  const userSession = defaultUserSession || defaultValue.userSession;
  const item: string | null = storage.getItem(USER_SESSION_KEY);
  return item ? sanitizeLocalStorageSession(JSON.parse(item)) : userSession;
}

function clearSessionData(storage: Storage): void {
  storage.clear();
}

export function updateUserSessionData(storage: Storage, userSession: Partial<UserSession>): UserSession {
  const curUserSession = getUserSessionData(storage);
  const updatedUserSession: UserSession = assign(curUserSession, userSession);
  storage.setItem(USER_SESSION_KEY, JSON.stringify(updatedUserSession));
  return updatedUserSession;
}

export const UserSessionContext = React.createContext<UserSessionContextData>(defaultValue);

type UserSessionContextProps = {defaultUserSession?: UserSession} & PropsWithChildren<ReactNode>
const UserSessionContextProvider = ({defaultUserSession, children}: UserSessionContextProps) => {
  const [userSessionData, setUserSessionData] = useState(getUserSessionData(localStorage, defaultUserSession));

  const update = (userSession: Partial<UserSession>): void => {
    const updateUserSession = updateUserSessionData(localStorage, userSession);
    setUserSessionData(updateUserSession);
  };

  return (<UserSessionContext.Provider value={{
    userSession: userSessionData,
    update: update,
    user: () => userSessionData.user,
    userEmailIsVerified: () => userSessionData.emailVerified,
    role: () => userSessionData.role,
    getCurrentMember: () => new ActualMember(userSessionData.role, userSessionData.user),
    currentOrganization: () => userSessionData.currentOrganization,
    organizations: () => userSessionData.organizations || [],
    clearSessionData: () => clearSessionData(localStorage)
  }}>
    {children}
  </UserSessionContext.Provider>);
};

export default UserSessionContextProvider;
