import {uniqBy} from 'lodash';
import {SelectableFilter, toSelectableOrderFilter} from '../../../application-models/OrderHistoryViewModel';
import {API} from '../../../lib/api/ordoApi';
import {Organization} from '../../../models/Organization';
import {ContactWithAccountName} from '../ContactsPage';
import {fullName} from '../../../models/User';
import Account from '../../../models/Account';

export interface ContactsPageFilter {
  accounts(): SelectableFilter[]

  salesReps(): SelectableFilter[];
}

export class InMemoryContactsPageFilter implements ContactsPageFilter {
  constructor(private contactsWithAccountName: ContactWithAccountName[]) {
  }

  public salesReps(): SelectableFilter[] {
    return [];
  }

  public accounts() {
    return uniqBy(this.contactsWithAccountName.map(contactWithAccountName => toSelectableOrderFilter(contactWithAccountName.accountName, contactWithAccountName.contact.accountId)), 'value');
  }
}

export class BackendContactsPageFilter implements ContactsPageFilter {
  constructor(private api: API, private organization: Organization, private accountsWithContactsAndSalesReps: Account[]) {
  }

  public salesReps() {
    return this.organization.members.map(member => member.user).map(member => toSelectableOrderFilter(fullName(member), member.id));
  }

  public accounts() {
    return this.accountsWithContactsAndSalesReps.map(account => toSelectableOrderFilter(account.name, account.id));
  }
}