import {
  HeaderColumns, SalesActivityAccount,
  SalesActivityColumns,
  SalesActivityEntity, SalesActivityOrder,
} from '../../../pages/sales-activity/salesActivityTypes';
import {
  PipelineButtonText,
  renderAccountCard, renderAccountCardWithStatus, renderOrderCard,
} from '../../../pages/sales-activity/salesActivityFunctions';
import {SalesActivityPipeline, SalesActivityTable} from './SalesActivityTable';
import SalesActivityViewModel from '../SalesActivityViewModel';

export class SalesActivityPipelineBuilder {

  private columns: SalesActivityColumns = []
  private header: HeaderColumns = []
  private readonly salesActivityViewModel: SalesActivityViewModel;
  private readonly salesActivityPendingOrders: SalesActivityOrder[];
  private readonly salesActivityAwaitingDeliveryOrders: SalesActivityOrder[];
  private readonly salesActivityCollectPaymentOrders: SalesActivityOrder[];
  private readonly nonQualifiedAccounts: SalesActivityAccount[];
  private readonly lostAccounts: SalesActivityAccount[];
  private readonly prospectAccounts: SalesActivityAccount[];
  private readonly verifiedAccounts: SalesActivityAccount[];
  private readonly staleAccounts: SalesActivityAccount[];

  constructor(private salesActivityTable: SalesActivityTable) {
    this.salesActivityViewModel = salesActivityTable.salesActivityViewModel;
    this.salesActivityPendingOrders = salesActivityTable.salesActivityPendingOrders;
    this.salesActivityAwaitingDeliveryOrders = salesActivityTable.salesActivityAwaitingDeliveryOrders;
    this.salesActivityCollectPaymentOrders = salesActivityTable.salesActivityCollectPaymentOrders;
    this.nonQualifiedAccounts = salesActivityTable.nonQualifiedAccounts;
    this.lostAccounts = salesActivityTable.lostAccounts;
    this.prospectAccounts = salesActivityTable.prospectAccounts;
    this.verifiedAccounts = salesActivityTable.verifiedAccounts;
    this.staleAccounts = salesActivityTable.staleAccounts;
  }

  public build(): SalesActivityPipeline {
    return {columns: this.columns, header: this.header};
  }

  public withNonQualifiedLead() {
    const nonQualifiedAccounts = this.salesActivityViewModel.getNonQualifiedAccounts(this.nonQualifiedAccounts);
    return this.addColumn(
      'Non-qualified lead',
      this.salesActivityViewModel.getQuantityText(nonQualifiedAccounts),
      nonQualifiedAccounts,
      renderAccountCard(PipelineButtonText.ADD_CONTACT, this.salesActivityTable.showAccountDetails(true), this.salesActivityTable.showAccountDetails(true), this.salesActivityTable.onAccountPriorityClick, this.salesActivityTable.accountCardMinWidth));
  }

  public withProspectBuyers() {
    const prospectAccounts = this.salesActivityViewModel.getProspectAccounts(this.prospectAccounts);
    return this.addColumn(
      'Prospect',
      this.salesActivityViewModel.getQuantityText(prospectAccounts),
      prospectAccounts,
      renderAccountCard('won/lost', this.salesActivityTable.openProspectCTAModal, this.salesActivityTable.showAccountDetails(true), this.salesActivityTable.onAccountPriorityClick, this.salesActivityTable.accountCardMinWidth)
    );
  }

  public withVerifiedPurchasers() {
    const verifiedPurchasers = this.salesActivityViewModel.getVerifiedPurchasers(this.verifiedAccounts);
    return this.addColumn(
      'Verified purchaser',
      this.salesActivityViewModel.getQuantityText(verifiedPurchasers),
      verifiedPurchasers,
      renderAccountCardWithStatus(this.salesActivityTable.buyerActionText(), this.salesActivityTable.buyerAction(), this.salesActivityTable.showAccountDetails(false), this.salesActivityTable.onAccountPriorityClick, this.salesActivityTable.accountCardMinWidth)
    );
  }

  public withStalePurchasers() {
    const stalePurchasers = this.salesActivityViewModel.getStalePurchasers(this.staleAccounts);
    return this.addColumn(
      'Stale purchaser',
      this.salesActivityViewModel.getQuantityText(stalePurchasers),
      stalePurchasers,
      renderAccountCard('update status', this.salesActivityTable.openStaleAccountUpdateStatusModal, this.salesActivityTable.showAccountDetails(false), this.salesActivityTable.onAccountPriorityClick, this.salesActivityTable.accountCardMinWidth));
  }

  public withPendingOrders() {
    const pendingOrders = this.salesActivityViewModel.getPendingOrders(this.salesActivityPendingOrders);
    return this.addColumn(
      'Pending orders',
      this.salesActivityViewModel.getDealSubtitle(pendingOrders),
      pendingOrders,
      renderOrderCard(this.salesActivityViewModel, PipelineButtonText.CONFIRM_ORDER, this.salesActivityTable.confirmOrder,this.salesActivityTable.confirmOrderFromDrawer, this.salesActivityTable.openOrderDetails, this.salesActivityTable.onAccountPriorityClick, this.salesActivityTable.accountCardMinWidth)
    );
  }

  public withAwaitingDelivery() {
    const awaitingDeliveryCards = this.salesActivityViewModel.getAwaitingDeliveryCards(this.salesActivityAwaitingDeliveryOrders);
    return this.addColumn(
      'Awaiting delivery',
      this.salesActivityViewModel.getDealSubtitle(awaitingDeliveryCards),
      awaitingDeliveryCards,
      renderOrderCard(this.salesActivityViewModel, PipelineButtonText.VERIFY_DELIVERY, this.salesActivityTable.openVerifyDeliveryModal, this.salesActivityTable.openVerifyDeliveryModal, this.salesActivityTable.openOrderDetails, this.salesActivityTable.onAccountPriorityClick, this.salesActivityTable.accountCardMinWidth)
    );
  }

  public withCollectPayment() {
    const collectPaymentCards = this.salesActivityViewModel.getCollectPaymentCards(this.salesActivityCollectPaymentOrders);
    return this.addColumn(
      'Collect payment',
      this.salesActivityViewModel.getDealSubtitle(collectPaymentCards),
      collectPaymentCards,
      renderOrderCard(this.salesActivityViewModel, PipelineButtonText.LOG_PAYMENT, this.salesActivityTable.openCollectPaymentConfirmationModal, this.salesActivityTable.openCollectPaymentConfirmationModal, this.salesActivityTable.openOrderDetails, this.salesActivityTable.onAccountPriorityClick, this.salesActivityTable.accountCardMinWidth)
    );
  }

  public withLostPurchasers() {
    const lostPurchasers = this.salesActivityViewModel.getLostPurchasers(this.lostAccounts);
    return this.addColumn(
      'Lost',
      this.salesActivityViewModel.getQuantityText(lostPurchasers),
      lostPurchasers,
      renderAccountCard('prospect', this.salesActivityTable.openAccountLostCTAModal, this.salesActivityTable.showAccountDetails(true), this.salesActivityTable.onAccountPriorityClick, this.salesActivityTable.accountCardMinWidth)
    );
  }

  private addColumn(title: string, quantity: string, list: SalesActivityEntity[], renderWith: Function) {
    this.header.push({
      title: title,
      quantity: quantity,
      key: `${title}-header`,
    });
    this.columns.push({
      key: `${title}-content`,
      list: list,
      renderWith: renderWith
    });
    return this;
  }
}