import { Allocation } from './allocation';
import Account from '../Account';

export class AccountAllocation extends Allocation {
  constructor(
    public quantity: number,
    public productId: string,
    public assignedTo: Account,
    public distributorOrganizationLicenseId: string,
    public id?: string) {
    super(quantity, productId, assignedTo, id);
  }

  public isOrgMemberAllocation(): boolean {
    return false;
  }

  public isAccountAllocation(): boolean {
    return true;
  }

  public name(): string {
    return `${this.assignedTo.name}`;
  }
}
