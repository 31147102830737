import {OrganizationLicense} from '../../models/OrganizationLicense';
import {API} from '../../lib/api/ordoApi';
import {SyncInventorySummary} from './SyncInventorySummary';
import {MetrcItemSyncStep, MetrcSyncStep} from './MetrcItemSyncStep';
import {FirstMetrcSyncStep} from './FirstMetrcSyncStep';

export class MetrcItemsSyncViewModel {

  constructor(public selectedLicenses: OrganizationLicense[],
              private currentStep: MetrcSyncStep,
              private api: API,
              private inventorySyncResult?: SyncInventorySummary
  ) {
  }

  public static initialize(api: API) {
    return new MetrcItemsSyncViewModel([], new FirstMetrcSyncStep(), api);
  }

  public toggleLicense(orgLicense: OrganizationLicense) {
    if(this.isSelected(orgLicense)) {
      this.selectedLicenses = (this.selectedLicenses.filter(selectedLicense => selectedLicense !== orgLicense));
    } else {
      this.selectedLicenses.push(orgLicense);
    }

    return new MetrcItemsSyncViewModel(this.selectedLicenses, this.currentStep, this.api);
  };

  public isSelected(organizationLicense: OrganizationLicense) {
    return this.selectedLicenses.includes(organizationLicense);
  }

  public getCTAText() : string {
    return this.currentStep.actionText();
  }

  public shouldDisableCTA() : boolean {
    return this.currentStep.disableAction(this.selectedLicenses);
  }

  public submitStep(setViewModel: Function, orgId: string) : MetrcItemsSyncViewModel{
    this.currentStep.submit(this.api, orgId, this.selectedLicenses, (newStep: MetrcSyncStep, syncInventorySummary: SyncInventorySummary) =>
      setViewModel(new MetrcItemsSyncViewModel(this.selectedLicenses, newStep, this.api, syncInventorySummary)));
    const nextStep = this.currentStep.nextStep();
    return new MetrcItemsSyncViewModel(this.selectedLicenses, nextStep, this.api);
  }

  public goStepBack() : MetrcItemsSyncViewModel {
    return new MetrcItemsSyncViewModel(this.selectedLicenses, this.currentStep.previousStep(), this.api);
  }

  public secondaryButtonText() {
    return this.currentStep.secondaryActionText();
  }


  public getHeaderText() {
    return this.currentStep.headerText();
  }

  public getHeaderSubtitle() {
    return this.currentStep.headerSubtitle();
  }

  public getCurrentStep() : MetrcItemSyncStep {
    return this.currentStep.step();
  }

  public isStep(step: MetrcItemSyncStep): boolean{
    return this.currentStep.step() === step;
  }

  public resultTotal() {
    return this.inventorySyncResult ? this.inventorySyncResult.productsFound + this.inventorySyncResult.unlinkedProducts + this.inventorySyncResult.outOfStock : 0;
  }

  public get syncResult() {
    return this.inventorySyncResult || {
      productsFound: 0,
      unlinkedProducts: 0,
      outOfStock: 0,
      totalQuantity: 0,
      errors: []
    };
  }


}
