import React, {useContext, useEffect, useState,} from 'react';
import {useHistory} from 'react-router';
import {OrderEntryStep, OrderEntryViewModel} from '../../../application-models/order-entry/OrderEntryViewModel';
import {AccountLocation} from '../../../models/Account';
import '../../../scss/ordo/mobileOrderEntry/mobile-order-entry.scss';
import {ProductWithAvailability} from '../../../models/productWithAvailability';
import {CartItemType} from '../../../models/order-entry/CartItem';
import {MobileOrderEntrySecondStep} from './secondStep/MobileOrderEntrySecondStep';
import {MobileOrderEntryCheckout} from './thirdStep/MobileOrderEntryCheckout';
import {MobileOrderEntrySummary} from './thirdStep/MobileOrderEntrySummary';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../components/OrdoSpinner';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {MobileOrderEntryFirstStep} from './firstStep/MobileOrderEntryFirstStep';


type MobileOrderEntryProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateAccountLocation: (accountLocation: AccountLocation) => void,
  updateCart: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void,
  updateViewModel: (orderEntryViewModel: OrderEntryViewModel) => void,
  landscapeMode?: boolean
}

export const MobileOrderEntry = ({orderEntryViewModel, updateCart, updateAccountLocation, updateViewModel, landscapeMode=false} : MobileOrderEntryProps) => {
  const history = useHistory();
  const { successToast, errorToast } = useOrdoToasts();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const [step, setStep] = useState(orderEntryViewModel.currentStep); // lo que esta pasando es que cada vez que esta pagina se recarga el initializeStepForMobile te manda al paso 0 de nuevo

  useEffect(() => setStep(orderEntryViewModel.currentStep), [orderEntryViewModel.currentStep]);

  const submit = () => {
    showSpinner();
    orderEntryViewModel.submitOrder(organization.id).then((vm) => {
      if(vm.hasError()) {
        errorToast(vm.errorMessage());
        updateViewModel(vm);
      } else {
        successToast(vm.getSuccessMessage());
        history.push(vm.nextMobileRoute());
        updateViewModel(vm);
      }
    }).finally(hideSpinner);
  };

  const goToSecondStep = () => {
    updateViewModel(orderEntryViewModel.toStep(OrderEntryStep.GenerateOrder));
    setStep(OrderEntryStep.GenerateOrder);
  };
  const goToFirstStep = () => {
    updateViewModel(orderEntryViewModel.toStep(OrderEntryStep.ChooseAccountAndDistributor));
    setStep(OrderEntryStep.ChooseAccountAndDistributor);
  };
  const goToThirdStep = () => {
    updateViewModel(orderEntryViewModel.toStep(OrderEntryStep.DiscountsAndPricing));
    setStep(OrderEntryStep.DiscountsAndPricing);
  };
  const goToFourthStep = () => {
    updateViewModel(orderEntryViewModel.toStep(OrderEntryStep.VerifyOrder));
    setStep(OrderEntryStep.VerifyOrder);
  };

  // eslint-disable-next-line consistent-return
  function renderStep() {
    // eslint-disable-next-line default-case
    switch (step) {
    case OrderEntryStep.ChooseAccountAndDistributor:
      return (<MobileOrderEntryFirstStep orderEntryViewModel={orderEntryViewModel}
        updateViewModel={updateViewModel}
        goToSecondStep={goToSecondStep}
        landscapeMode={landscapeMode}
        updateAccountLocation={updateAccountLocation}/>);
    case OrderEntryStep.GenerateOrder:
      return (<MobileOrderEntrySecondStep
        goToVerifyOrder={goToThirdStep}
        goToFirstStep={goToFirstStep}
        updateViewModel={updateViewModel}
        orderEntryViewModel={orderEntryViewModel}
        updateAccountLocation={updateAccountLocation}
        updateCart={updateCart}
        landscapeMode={landscapeMode}
      />);
    case OrderEntryStep.DiscountsAndPricing:
      return (<MobileOrderEntryCheckout
        orderEntryViewModel={orderEntryViewModel}
        updateViewModel={updateViewModel}
        updateAccountLocation={updateAccountLocation}
        goToGenerateOrder={goToSecondStep}
        goToSummary={goToFourthStep}
        landscapeMode={landscapeMode}
      />);

    case OrderEntryStep.VerifyOrder:
      return (<MobileOrderEntrySummary
        orderEntryViewModel={orderEntryViewModel}
        updateViewModel={updateViewModel}
        goToVerifyOrder={goToThirdStep}
        onSubmit={submit}
        landscapeMode={landscapeMode}
      />);
    }
  }

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      {renderStep()}
    </OrdoSpinner>
  );
};

