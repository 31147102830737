import React, {useEffect, useState} from 'react';
import OrdoModal from '../components/common/OrdoModal';
import '../../scss/ordo/salesActivity/verify-order-modal.scss';
import OrdoInput from '../components/common/inputs/OrdoInput';
import OrdoButton from '../components/common/OrdoButton';
import {useAPI} from '../../context/OrdoApiContext';
import {Money} from '../../models/Money';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import {PendingPaymentOrder} from '../../models/order-payment/PendingPaymentOrder';

type CollectPaymentConfirmationModalProps = {
  isOpen: boolean,
  onClose: () => void,
  onSubmit: (amountOwedAfterPayment?: Money) => void,
  organizationId: string,
  pendingPaymentOrder: PendingPaymentOrder
}

const LogPaymentModal = ({isOpen, onClose, onSubmit, organizationId, pendingPaymentOrder}: CollectPaymentConfirmationModalProps) => {
  const [amountToPay, setAmountToPay] = useState(0);
  const [amountOwed, setAmountOwed] = useState(Money.FromDollarAmount(0));
  const api = useAPI();
  const {successToast, errorToast} = useOrdoToasts();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  useEffect(() => {
    showSpinner();
    api.getOrderOwedAmount(organizationId, pendingPaymentOrder.orderId)
      .then((owed: Money) => {
        setAmountOwed(owed);
        setAmountToPay(owed.getAmount()/100);
      })
      .catch(() => {
        errorToast(`Could not retrieve owed amount for order #${pendingPaymentOrder.orderNumber}`);
        onClose();
      })
      .finally(hideSpinner);
  },[]);

  const logPayment = () => {
    showSpinner();
    api.logPayment(organizationId, pendingPaymentOrder.orderId, Money.FromDollarAmount(amountToPay))
      .then((orderPayment) => {
        onClose();
        onSubmit(orderPayment.amountOwedAfterPayment);
        successToast(`Payment successfully logged for order #${pendingPaymentOrder.orderNumber}`);
      })
      .catch(() => errorToast(`Could not create payment for order #${pendingPaymentOrder.orderNumber}`))
      .finally(hideSpinner);
  };

  const isValidAmountToPay = () => !!amountToPay && amountToPay > 0;
  return(
    <OrdoModal title={<div className='log-payment-header'>
      <span>log payment for order</span>
      <a className='invoice-link' href={pendingPaymentOrder.invoiceURL} target="_blank"
        rel="noreferrer">
          #{pendingPaymentOrder.orderNumber}
      </a>
    </div>}
    show={isOpen}
    size="sm"
    onClose={onClose}
    showFooter={false}>
      <OrdoSpinner showSpinner={spinnerVisibility}>
        <div className="log-payment-container">
          <span className='amount-owed'>{amountOwed.formatted()}</span>
          <span className='amount-owed-subtitle'>amount owed</span>
          <span className={`label ${!isValidAmountToPay() ? 'with-error' : ''}`}>amount paid</span>
          <OrdoInput inputName='log-payment-input'
            value={amountToPay}
            min={0}
            isValid={isValidAmountToPay()}
            inputType='number'
            placeholder={amountOwed.formatted()}
            onChange={setAmountToPay}
            inputSize='medium'/>
          <div>
            <OrdoButton disabled={!isValidAmountToPay()} text='save' category='primary' onClick={logPayment} dataTestId=''/>
            <OrdoButton disabled={false} text='cancel' category='cancel' onClick={onClose} dataTestId=''/>
          </div>
        </div>
      </OrdoSpinner>
    </OrdoModal>
  );
};

export default LogPaymentModal;
