import React, {useCallback, useContext, useEffect, useState} from 'react';
import '../../../scss/ordo/customPricingTiers/custom-pricing-tiers.scss';
import {Tier} from '../../../models/custom-pricing-tiers/Tier';
import {CustomTierList} from './CustomTierList';
import Account from '../../../models/Account';
import {CustomPricingTierForm} from './CustomPricingTierForm';
import {Brand} from '../../../models/Brand';
import Product from '../../../models/Product';
import {useAPI} from '../../../context/OrdoApiContext';
import {UserSessionContext} from '../../../context/UserSessionContext';
import ProductCategory from '../../../models/ProductCategory';
import OrdoSpinner from '../OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {TierFormCreate, TierFormEdit, TierFormMode} from './TierFormMode';
import ConfirmationModal from '../common/ConfirmationModal';
import useModalToggle from '../../../hooks/useModalToggle';


enum PageView {
  FORM,
  LIST
}

type CustomPricingTiersProps = {
  isVisible: boolean,
  loading: boolean,
  brands: Brand[],
  categories: ProductCategory[],
  products: Product[]
}

const CustomPricingTiers = ({isVisible, brands, categories, products, loading}: CustomPricingTiersProps) => {
  const [pageView, setPageView] = useState<PageView>(PageView.LIST);
  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const organizationId = userSession.currentOrganization()!.id;


  const [tierToDelete, setTierToDelete] = useState<Tier | null>(null);
  const {closeModal,openModal,showModal} = useModalToggle();

  const [accounts, setAccounts] =  useState<Account[]>([]);
  const [tiers, setTiers] =  useState<Tier[]>([]);
  const [tierFormMode, setTierFormMode] = useState<TierFormMode>(new TierFormCreate());
  const {hideSpinner,showSpinner,spinnerVisibility} = useSpinnerToggle();
  const {successToast} = useOrdoToasts();

  useEffect(() => {
    showSpinner();
    Promise.all([
      api.getCustomPricingTiers(organizationId),
      api.getAssignedAccountsForDropdown(organizationId)
    ]).then(([existingTiers,  updatedAccounts]) => {
      setTiers(existingTiers);
      setAccounts(updatedAccounts);
    }).finally(hideSpinner);
  },[]);

  const resetPage = useCallback(() => {
    setTierFormMode(new TierFormCreate());
    setPageView(PageView.LIST);
  }, []);

  const closeDeleteModal = useCallback(() => {
    closeModal();
    setTierToDelete(null);
  },[]);

  const deleteTier = (tier: Tier)=> {
    showSpinner();
    api.deleteCustomPricingTier(organizationId, tier).then(() => {
      successToast('Pricing tier deleted successfully!');
      const index = tiers.findIndex((originalTier) => originalTier.id === tier.id);
      tiers.splice(index, 1);
      setTiers(tiers);
      resetPage();
      closeDeleteModal();
    }).finally(hideSpinner);
  };


  const submit = (tier: Tier)=> {
    showSpinner();
    tierFormMode.submit(organizationId, tier, api).then((createdTier) => {
      successToast(tierFormMode.successMessage());
      setTiers(tierFormMode.resultingArray(createdTier, tiers));
      resetPage();
    }).finally(hideSpinner);
  };

  const renderContent = () => {
    switch (pageView) {
    case PageView.FORM:
      return <CustomPricingTierForm
        accounts={accounts} products={products} brands={brands} categories={categories}
        onSubmit={submit} onCancel={resetPage}
        tierToEdit={tierFormMode.tier}/>;
    case PageView.LIST:
    default:
      return <CustomTierList tiers={tiers}
        createTier={() => {
          setTierFormMode(new TierFormCreate());
          setPageView(PageView.FORM);
        }}
        editTier={(tier)=>{
          setTierFormMode(new TierFormEdit(tier));
          setPageView(PageView.FORM);
        }}
        deleteTier={(tier) => {
          setTierToDelete(tier);
          openModal();
        }}
      />;
    }
  };

  return (
    <OrdoSpinner showSpinner={(spinnerVisibility || loading) && isVisible}>
      <div className={`card card-fluid ordo-shadow ordo-card-border pricing-tiers-card ${isVisible ? '' : 'ordo-hide'}`} >
        {renderContent()}
        {
          tierToDelete && <ConfirmationModal show={showModal} onClose={closeModal}
            onSubmit={()=>deleteTier(tierToDelete!)} confirmationText={`Are you sure you want to delete tier ${tierToDelete?.name}?`}/>
        }
      </div>
    </OrdoSpinner>);
};
export default CustomPricingTiers;