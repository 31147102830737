import {useState} from 'react';

export default function useSpinnerToggle(){
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);

  const hideSpinner = () => setSpinnerVisibility(false);
  const showSpinner = () => setSpinnerVisibility(true);

  return {spinnerVisibility: spinnerVisibility, hideSpinner: hideSpinner, showSpinner: showSpinner};

}
