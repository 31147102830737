import React, {ChangeEvent} from 'react';
import OrdoInput from '../common/inputs/OrdoInput';
import '../../../scss/ordo/notes/note-form.scss';
import NoteViewModel from '../../../application-models/sales-activity/NoteViewModel';
import OrdoTextArea from '../common/OrdoTextArea';
import OrdoButton from '../common/OrdoButton';
import OrdoMultiSelect, {MultiSelectableOption, toMultiSelectableOption} from '../common/OrdoMultiSelect';
import Contact from '../../../models/order-entry/Contact';
import {CARD_BORDER_COLOR, GRAY_400} from '../../../constants';

const MAX_TITLE_LENGTH = 255;
const MAX_DESCRIPTION_LENGTH = 2500;

type AddNoteFormProps = {
  viewModel: NoteViewModel,
  updateViewModel: (vm: NoteViewModel) => void
  onSubmit: () => void,
  onCancel: () => void,
  accountContacts: Contact[]
};
export const NoteForm = ({viewModel,updateViewModel, onSubmit, onCancel, accountContacts}: AddNoteFormProps) => {
  const updateTitle = (newTitle: string) => updateViewModel(viewModel.updateTitle(newTitle));
  const updateDescription = (newDescription: string) => updateViewModel(viewModel.updateDescription(newDescription));
  const updateContacts = (selectedContacts: MultiSelectableOption<Contact>[]) => {
    updateViewModel(viewModel.updateContacts(selectedContacts.map(selected => selected.data!)));
  };
  const shouldExpandContacts = viewModel.contacts.length > 1;
  const multiSelectHeight = shouldExpandContacts ? '2.8em' : '2.2em';

  return <div className="note-form" role="presentation" onClick={(event) => event.stopPropagation()}>
    <div className="note-form__header">
      <OrdoInput inputName="title" value={viewModel.title} placeholder="subject or title"
        inputSize="large" onChange={updateTitle} maxLength={MAX_TITLE_LENGTH}/>
      <OrdoMultiSelect
        options={accountContacts.map(c => toMultiSelectableOption(c.name, c.name,c ))}
        onChangeSelectedOption={updateContacts}
        selectedOptions={viewModel.contacts.map(c => toMultiSelectableOption(c.name, c.name, c))}
        placeholder='contacts'
        addedStyles={{
          container: {
            minWidth: '30%',
            width: 'auto',
            maxWidth: '70%',
            marginRight: '0',
            marginLeft: '0.5em'
          },
          control: {
            minHeight: '2em',
            height: multiSelectHeight,
            borderColor: GRAY_400,
            boxShadow: `0 0 0.4em 0.01em ${CARD_BORDER_COLOR}`,
            '&:hover': {},
            paddingLeft: viewModel.contacts.length > 0 ? '0.25rem' : '0.5rem',
            maxHeight: '2.2em',
            marginBottom: '0',
          },
          valueContainer: {
            minHeight: '2em',
            height: multiSelectHeight,
            padding: '2px 2px 0',
            overflowY: shouldExpandContacts ? 'scroll' : 'hidden',
          },
          multiValue: {
            width: 'min-content',
            maxWidth: '5.5em',
            alignItems: 'center',
            height: '1.5em',
            marginTop: '0',
            marginBottom: '4px',
          },
          multiValueLabel: {
            paddingRight: '0'
          },
          multiValueRemove: {
            padding: '0'
          },
          indicatorsContainer: {
            minHeight: '2em',
            height: '2em',
            padding: '4px 2px 2px 0'
          },
          clearIndicator: {
            padding: '2px'
          },
          dropdownIndicator: {
            padding: '2px'
          },
          indicatorSeparator: {
            margin: '4px 0'
          }
        }}
      />
    </div>
    <div className="note-form__description">
      <OrdoTextArea
        placeholder='description'
        style={{width: '100%'}}
        value={viewModel.description || ''}
        handleChange={(event: ChangeEvent<HTMLInputElement>) => updateDescription(event.target.value)}
        maxValue={MAX_DESCRIPTION_LENGTH}
      />
    </div>
    <div className="account-details__buttons">
      <OrdoButton disabled={!viewModel.canSubmit()} text="save" category="primary"
        onClick={onSubmit}
        dataTestId="note-submit-button"/>
      <OrdoButton disabled={false} text="cancel" category="cancel" onClick={onCancel}
        dataTestId="note-cancel-button"/>
    </div>
  </div>;
};
