import InvalidField from './invalid_field';

export type UserFormFields = 'email' | 'password' | 'passwordConfirmation' | 'lastName' | 'firstName' | 'phone' | 'emailOrPassword';
export const belongsToUserFormFields = (field: string) =>  field === 'email' || field === 'password' || field === 'passwordConfirmation' ||
  field === 'lastName' || field === 'firstName' || field === 'phone';
export type OrganizationFormFields = 'name' | 'size' | 'state' | 'termsOfUse';
export const belongsToOrganizationFormFields = (field: string) =>  ['name', 'size', 'state', 'termsOfUse'].includes(field);
export type BatchFormFields = 'number-of-units' | 'batch-id' | 'coa' | 'advertised-thc'
export type ProductFormFields = 'name' | 'brand' |'category' | 'image' | 'price'	| 'unitsPerCase'| 'consumerUnitSizeAmount' | 'consumerUnitSizeUnit' | 'genetics' | 'advertisedTHC' | 'advertisedCBD' | 'preRolls' | 'customerSKU'
export type OrganizationLicenseFormFields = 'licenseNumber' | 'streetAddressLine2' | 'streetAddressLine1' | 'city' | 'zipCode' | 'state'

export type FormFields = UserFormFields | OrganizationFormFields | ProductFormFields | BatchFormFields | OrganizationLicenseFormFields |'unknown'

export default class FormError {
  public errors: Map<FormFields, string>;

  public static withoutError() {
    return new FormError();
  }

  public static withErrors(fields: InvalidField[]) {
    const formError = new FormError();
    fields.forEach((field: InvalidField) => formError.addError(field));
    return formError;
  }

  public static unknown(errorMessage: string) {
    const unknownError = new InvalidField('unknown', errorMessage);
    return FormError.withErrors([unknownError]);
  }

  private constructor() {
    this.errors = new Map();
  }

  public addError(invalidField: InvalidField) {
    this.errors.set(invalidField.field, invalidField.message);
  }

  public removeErrorFor(field: FormFields) {
    this.errors.delete(field);
  }

  public hasError() {
    return this.errors.size !== 0;
  }

  public hasErrorForInput(input: FormFields) {
    return this.errors.has(input);
  }

  public errorMessage(input: FormFields) {
    return this.errors.get(input) || '';
  }

  public singleError() {
    return this.errors.keys().next().value;
  }
}
