import React, {useState} from 'react';
import '../../scss/ordo/salesActivity/sales-activity-order-details.scss';
import {useHistory} from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {
  SalesActivityOrderDetailsViewModel
} from '../../application-models/sales-activity/SalesActivityOrderDetailsViewModel';
import OrderLineItems from '../components/order-history/OrderLineItems';
import {PRIMARY_COLOR} from '../../constants';
import {
  OrderLineColumnsForDetails,
  OrderLineColumnsForMobileDetails
} from '../components/order-history/OrderLineItemsColumns';
import OrderDeliverySummary from '../components/order-history/OrderDeliverySummary';
import OrderPaymentSummary from '../components/order-history/OrderPaymentSummary';
import {AccountContactsDetails} from './contacts/AccountContactsDetails';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import OrdoSpinner from '../components/OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import {SalesActivityCardDetails} from './SalesActivityCardDetails';
import {AccountNotesDetails} from './notes/AccountNotesDetails';
import {AccountActivitiesDetails} from './activities/AccountActivitiesDetails';
import {AccountDetailsInfo} from '../../application-models/sales-activity/SalesActivityViewModel';
import OrdoMobileFriendlyButton from '../components/common/OrdoMobileFriendlyButton';
import {useResolution} from '../../context/UseResolutions';
import {isMobileResolution} from '../../context/resolutions';
import {AccountInfo} from './AccountInfo';
import {AccountPriorityButton} from './AccountPriorityButton';
import {NON_ACTIVE_ACCOUNT_TEXT} from '../../models/Account';
import {PipelineButtonText} from './salesActivityFunctions';
import {OrderPrefilledMode} from '../../application-models/order-entry/order-entry-mode';
import useModalToggle from '../../hooks/useModalToggle';
import ConfirmationModal from '../components/common/ConfirmationModal';
import {useAPI} from '../../context/OrdoApiContext';
import {OrderStatus} from '../../models/order-entry/Order';

const headerStyles = {
  backgroundColor: PRIMARY_COLOR,
  color: 'white',
  height: '2em',
  padding: '0 0.5em',
  fontSize: '0.75em',
};

const summaryStyles = {
  ...headerStyles,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
type DetailedOrderHeaderProps = {
  viewModel: SalesActivityOrderDetailsViewModel,
  onEditOrder: (event: any) => void,
  ctaAction: (event: any) => void,
  onUnconfirmOrder: () => void,
};

type DetailedOrderSummaryProps = {
  viewModel: SalesActivityOrderDetailsViewModel,
  onSubmit: ()=> void
};
const DetailedOrderSummary = ({viewModel, onSubmit}: DetailedOrderSummaryProps) => {
  const resolution = useResolution();

  return <div className='order-details-body'>
    <div className="order-details-items">
      <OrderLineItems order={viewModel.order} headerStyles={headerStyles}
        columns={ isMobileResolution(resolution) ? OrderLineColumnsForMobileDetails : OrderLineColumnsForDetails}/>
    </div>
    <div className='order-details-body__summary'>
      <div className="order-details-body__summary__delivery">
        <div className='delivery-summary__title' style={summaryStyles}>
                    delivery summary
        </div>
        <OrderDeliverySummary order={viewModel.order} onUpdateOrderStatus={onSubmit} onUpdateOrderDisplayStatus={()=>{}}
          showButtons={false}/>
      </div>
      <div className="order-details-body__summary__payment">
        <div className='order-payment-summary__title' style={summaryStyles}>
                    payment summary
        </div>
        <OrderPaymentSummary
          orderSummary={viewModel.currentVersionSummary()}
          invoiceUrl={viewModel.invoiceUrl()}
        />
      </div>
    </div>
  </div>;
};
const DetailedOrderHeader = ({viewModel, onEditOrder, ctaAction, onUnconfirmOrder}: DetailedOrderHeaderProps) => {
  const {showModal,openModal,closeModal} = useModalToggle();
  const unconfirmOrder = () => {
    onUnconfirmOrder();
    closeModal();
  };

  return <div className="order-details-header">
    <div className="order-details-info">
      <div className="order-number-and-account">
        <span className="order-number-and-account__order">order </span>
        <span>#{viewModel.orderNumber()} </span>
      </div>
      <div className="order-account-info">
        <span className='order-account-info__status'>{viewModel.getHeaderDescription()} </span>
      </div>
    </div>
    <div className="order-buttons">
      {viewModel.isPendingOrder() &&
      <OrdoMobileFriendlyButton
        squared
        onClick={onEditOrder}
        text={PipelineButtonText.EDIT_ORDER}
        category='primary'
        dataTestId="edit-order"
        disabled={!viewModel.salesActivityAccount().activeAccount}
        disabledText={NON_ACTIVE_ACCOUNT_TEXT}
      />}
      {viewModel.isConfirmedOrder() &&
        <OrdoMobileFriendlyButton
          squared
          onClick={openModal}
          text={PipelineButtonText.UNCONFIRM_ORDER}
          category='primary'
          dataTestId="unconfirm-order"
          disabled={!viewModel.salesActivityAccount().activeAccount}
          disabledText={NON_ACTIVE_ACCOUNT_TEXT}
        />}
      {showModal &&
        <ConfirmationModal show={showModal} onClose={closeModal} onSubmit={unconfirmOrder}
          confirmationText={`Are you show you want to send order ${viewModel.orderNumber()} back to pending status?` }
        />}
      <OrdoMobileFriendlyButton
        squared onClick={ctaAction}
        text={viewModel.getCtaText()}
        category='primary'
        dataTestId="confirm-order"
        disabled={viewModel.getCtaText() === PipelineButtonText.CONFIRM_ORDER && !viewModel.salesActivityAccount().activeAccount}
        disabledText={NON_ACTIVE_ACCOUNT_TEXT}
      />
    </div>
  </div>;
};
type DetailedOrderProps = DetailedOrderHeaderProps & DetailedOrderSummaryProps & {
  order: OrderWithCurrentVersion,
};

const DetailedOrder = ({order, viewModel, onEditOrder, ctaAction, onSubmit, onUnconfirmOrder}: DetailedOrderProps) => {
  const [showSummary, setShowSummary] = useState(false);


  const orderIsNotTracked = !order.isTracked;
  const showBottomSection = () => {
    return showSummary && orderIsNotTracked;
  };

  return     <div className={`order-details-section order-summary-section ${showSummary ? 'section-expanded' : 'section-collapsed'}`}>
    <div className="upper-section">
      <div className={`caret-and-title order-details-row-header ${showSummary ? 'opened' : ''}`} onClick={()=>setShowSummary(!showSummary)} role="presentation">
        {orderIsNotTracked && <FontAwesomeIcon className={`caret-icon ${showSummary ? 'opened' : 'closed'}`} size="lg"
          icon={faAngleRight}/>}
        <DetailedOrderHeader viewModel={viewModel} onEditOrder={onEditOrder} ctaAction={ctaAction} onUnconfirmOrder={onUnconfirmOrder}/>
      </div>
    </div>
    {showBottomSection() &&
        <DetailedOrderSummary viewModel={viewModel} onSubmit={onSubmit}/>
    }
  </div>;
};


type SalesActivityOrderProps = {
  order: OrderWithCurrentVersion,
  orgId: string,
  onSubmit: () => any,
  onClose: () => any,
  trackedOrderAction?: (order: OrderWithCurrentVersion) => any,
  cta: () => any,
  doMainActionOnClose: () => any
} & AccountDetailsInfo

const SalesActivityOrderDetails = ({
  order,
  contacts,
  notes,
  activities,
  orgId,
  onSubmit,
  onClose,
  cta,
  trackedOrderAction,
  doMainActionOnClose
}: SalesActivityOrderProps) => {
  const [orderDetailsViewModel] = useState<SalesActivityOrderDetailsViewModel>(new SalesActivityOrderDetailsViewModel(order, contacts));
  const {spinnerVisibility, showSpinner, hideSpinner} = useSpinnerToggle();
  const history = useHistory();
  const api = useAPI();

  const ctaAction = () => {
    showSpinner();
    cta().then(() => {
      onSubmit();
    }).catch(() => {
    }).finally(hideSpinner);
  };

  const unconfirmOrder = () => {
    showSpinner();
    api.updateOrderStatus(orgId, order.orderId, OrderStatus.Pending).then(() => {
      onSubmit();
    }).catch(() => {
    }).finally(hideSpinner);
  };

  const onEditOrder = () => {
    onClose();
    if (order.isTracked) {
      trackedOrderAction!(order);
    } else {
      history.push(ROUTE_NAMES.EDIT_ORDER, {orderId: order.id, orderPrefillMode: OrderPrefilledMode.edit});
    }
  };

  const goToAccountPage = () => history.push(`accounts/${order.accountId()}`);

  const accountId = orderDetailsViewModel.accountId();
  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="order-details-container">
        <div className="account-header-in-order-details">
          <div className="account-header-in-order-details__account-personal-info">
            <div className="account-header-in-order-details__account-personal-info__name" role="presentation" onClick={goToAccountPage}> {orderDetailsViewModel.accountName()}</div>
            <div className="account-header-in-order-details__account-personal-info__info">
              <div className="account-header-in-order-details__account-personal-info__info__license"> {orderDetailsViewModel.licenseNumber()}</div>
              <div className="account-header-in-order-details__account-personal-info__info__address"> {orderDetailsViewModel.accountLocationAddress()} </div>
            </div>
            <AccountInfo account={orderDetailsViewModel.salesActivityAccount()} orgId={orgId} onInformationChange={() => {}} showValue={false}/>
          </div>
          <AccountPriorityButton account={orderDetailsViewModel.salesActivityAccount()} organizationId={orgId} onPriorityChange={doMainActionOnClose}/>
        </div>
        <SalesActivityCardDetails>
          <DetailedOrder order={order} viewModel={orderDetailsViewModel}
            onSubmit={onSubmit} onEditOrder={onEditOrder} ctaAction={ctaAction} onUnconfirmOrder={unconfirmOrder}/>
          <AccountContactsDetails
            orgId={orgId}
            accountId={accountId}
            onSubmit={onClose}
            contacts={contacts}
            activeAccount={orderDetailsViewModel.salesActivityAccount().activeAccount}
          />
          <AccountNotesDetails
            orgId={orgId}
            accountId={accountId}
            notes={notes}
            accountContacts={contacts}
            activeAccount={orderDetailsViewModel.salesActivityAccount().activeAccount}
          />
          <AccountActivitiesDetails
            orgId={orgId}
            accountId={accountId}
            accountName={orderDetailsViewModel.accountName()}
            activities={activities}
            activeAccount={orderDetailsViewModel.salesActivityAccount().activeAccount}
          />
        </SalesActivityCardDetails>
      </div>
    </OrdoSpinner>
  );
};

export default SalesActivityOrderDetails;
