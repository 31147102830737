import React from 'react';

export const ActivityTemplatesManagerHeaders = () => {
  return(
    <div className="activities-types-table-headers">
      <div className="activity-type">
        <span>activity type</span>
      </div>
      <div className="created">
        <span>created</span>
      </div>
      <div className="complete">
        <span>complete</span>
      </div>
    </div>
  );
};
