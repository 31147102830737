import {User} from '../User';
import {Allocation} from './allocation';

export class OrgMemberAllocation extends Allocation {
  constructor(
    public quantity: number,
    public productId: string,
    public assignedTo: User,
    public distributorOrganizationLicenseId: string ,
    public id?: string) {
    super(quantity, productId, assignedTo, id);
  }

  public isOrgMemberAllocation(): boolean {
    return true;
  }

  public isAccountAllocation(): boolean {
    return false;
  }

  public name(): string {
    return `${this.assignedTo.firstName} ${this.assignedTo.lastName}`;
  }
}
