import {roleFor} from './Roles';
import {UserSession} from './UserSession';

export type User = {
  email: string;
  id: string;
  lastName: string;
  phone: string;
  firstName: string;
  currentOrganizationId?: string | null;
}

export function fullName(user: User) {
  return `${user.firstName } ${user.lastName}`;
}

export const emptyUser = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  id: '',
  currentOrganizationId: ''
};

export const userNotLoggedIn = emptyUser;

export const notLoggedInUserSession: UserSession = {
  user: userNotLoggedIn,
  currentOrganization: undefined,
  role: roleFor('Unknown'),
  organizations: [],
  pendingInvitations: [],
  emailVerified: false,
};
