import {OrganizationLicense} from '../../models/OrganizationLicense';
import {SecondMetrcSyncStep} from './SecondMetrcSyncStep';
import {API} from '../../lib/api/ordoApi';
import {MetrcItemSyncStep, MetrcSyncStep} from './MetrcItemSyncStep';

export class ThirdMetrcSyncStep implements MetrcSyncStep {
  constructor() {
  }

  step(): MetrcItemSyncStep {
    return MetrcItemSyncStep.THIRD;
  }

  actionText(): string {
    return '';
  }

  secondaryActionText(): string {
    return 'cancel';
  }

  disableAction(_selectedLicenses: OrganizationLicense[]): boolean {
    return false;
  }

  headerSubtitle(): string {
    return '';
  }

  headerText(): string {
    return 'metrc sync in-progress';
  }

  nextStep(): MetrcSyncStep {
    return this;
  }

  previousStep(): MetrcSyncStep {
    return new SecondMetrcSyncStep();
  }

  submit(_api: API, _orgId: string, _selectedLicences: OrganizationLicense[], _callback: Function): void {
  }
}