import {orderBy} from 'lodash';
import {ActivityItem, ActivityTemplate} from '../../../models/sales-activity/Activity';
import {OrdoDate} from '../../../models/OrdoDate';
import {MultiSelectableOption, toMultiSelectableOption} from '../../components/common/OrdoMultiSelect';
import {fullName, User} from '../../../models/User';
import {SortingCriteria} from '../../../application-models/sales-activity/SalesActivityViewModel';

export class ActivitiesFilter {
  public accountName: string;
  public todayFilter: boolean
  public toDoFilter: boolean
  public overdueFilter: boolean
  public completedFilter: boolean
  public selectedTemplates: ActivityTemplate[];
  public selectedUsers: User[];
  public sortingCriteria: SortingCriteria;

  constructor(accountName: string,
    todayFilter: boolean,
    toDoFilter: boolean,
    overdueFilter: boolean,
    completedFilter: boolean,
    selectedTemplates: ActivityTemplate[],
    selectedSalesReps: User[],
    sortingCriteria: SortingCriteria) {
    this.accountName = accountName;
    this.todayFilter = todayFilter;
    this.toDoFilter = toDoFilter;
    this.overdueFilter = overdueFilter;
    this.completedFilter = completedFilter;
    this.selectedTemplates = selectedTemplates;
    this.selectedUsers = selectedSalesReps;
    this.sortingCriteria = sortingCriteria;
  }

  public static empty() {
    return new ActivitiesFilter('', false, false, false, false, [], [], SortingCriteria.DESCENDING);
  }

  public getAccountValue() {
    return this.accountName;
  }

  public updateAccountSearch(search: string): ActivitiesFilter {
    return new ActivitiesFilter(search, this.todayFilter, this.toDoFilter, this.overdueFilter, this.completedFilter, this.selectedTemplates, this.selectedUsers, this.sortingCriteria);
  }

  public toggleTodayFilter(): ActivitiesFilter {
    return new ActivitiesFilter(this.accountName, !this.todayFilter, false, false, false, this.selectedTemplates, this.selectedUsers, this.sortingCriteria);
  }

  public toggleToDoFilter(): ActivitiesFilter {
    return new ActivitiesFilter(this.accountName, false, !this.toDoFilter, false, false, this.selectedTemplates, this.selectedUsers, this.sortingCriteria);
  }

  public toggleOverdueFilter(): ActivitiesFilter {
    return new ActivitiesFilter(this.accountName, false, false, !this.overdueFilter, false, this.selectedTemplates, this.selectedUsers, this.sortingCriteria);
  }

  public toggleCompleteFilter(): ActivitiesFilter {
    return new ActivitiesFilter(this.accountName, false, false, false, !this.completedFilter, this.selectedTemplates, this.selectedUsers, this.sortingCriteria);
  }

  public setActivityTypeFilter(activityTypes: MultiSelectableOption<ActivityTemplate>[]): ActivitiesFilter {
    const types = activityTypes.map((aT: MultiSelectableOption<ActivityTemplate>) => aT.data!);
    return new ActivitiesFilter(this.accountName, this.todayFilter, this.toDoFilter, this.overdueFilter, this.completedFilter, types, this.selectedUsers, this.sortingCriteria);
  }

  public setSalesRepFilter(salesreps: MultiSelectableOption<User>[]): ActivitiesFilter {
    const users = salesreps.map((u: MultiSelectableOption<User>) => u.data!);
    return new ActivitiesFilter(this.accountName, this.todayFilter, this.toDoFilter, this.overdueFilter, this.completedFilter, this.selectedTemplates, users, this.sortingCriteria);
  }

  public filterActivityItems(activityItems: ActivityItem[]): ActivityItem[] {
    const search = this.getAccountValue().toLowerCase();
    const filteredActivities = activityItems.filter(activityItem => {
      return ActivitiesFilter.applyAccountNameFilter(activityItem, search) &&
        this.applyTemplatesFilter(activityItem) &&
        this.applySalesRepFilter(activityItem) &&
        this.applyDatesFilter(activityItem) &&
        this.applyToDoFilter(activityItem) &&
        this.applyOverdueFilter(activityItem) &&
        this.applyCompletedFilter(activityItem);
    });

    return orderBy(filteredActivities,  'date' ,this.sortingCriteria === SortingCriteria.ASCENDING ? ['asc'] : ['desc']);
  }

  public sortingCriteriaText(): string {
    switch (this.sortingCriteria) {
    case SortingCriteria.ASCENDING:
      return 'ascending';
    case SortingCriteria.DESCENDING:
      return 'descending';
    default:
      return 'descending';
    }
  }

  private static applyAccountNameFilter(activityItem: ActivityItem, search: string) {
    return activityItem.accountName.toLowerCase().includes(search);
  }

  public hasTodayFilter(): boolean {
    return this.todayFilter;
  }

  public hasToDoFilter(): boolean {
    return this.toDoFilter;
  }

  public hasOverdueFilter() {
    return this.overdueFilter;
  }

  public hasCompleteFilter(): boolean {
    return this.completedFilter;
  }

  private applyDatesFilter(activityItem: ActivityItem): boolean {
    return this.todayFilter ? OrdoDate.isToday(activityItem.date) : true;
  }

  private applyToDoFilter(activityItem: ActivityItem): boolean {
    return this.toDoFilter ? (!activityItem.completed && !OrdoDate.isDueDate(activityItem.date) && !OrdoDate.isToday(activityItem.date)) : true;
  }

  private applyOverdueFilter(activityItem: ActivityItem): boolean {
    return this.overdueFilter ? (!activityItem.completed && OrdoDate.isDueDate(activityItem.date)) : true;
  }

  private applyCompletedFilter(activityItem: ActivityItem): boolean {
    return this.completedFilter ? activityItem.completed : true;
  }

  private applyTemplatesFilter(activityItem: ActivityItem): boolean {
    return this.selectedTemplates.length > 0 ? (this.selectedTemplates.some(at => at.id === activityItem.activityTemplate.id)) : true;
  }

  private applySalesRepFilter(activityItem: ActivityItem): boolean {
    return this.selectedUsers.length > 0 ? (this.selectedUsers.some(u => u.id === activityItem.createdBy?.id)) : true;
  }

  public selectedActivityTemplates(): MultiSelectableOption<ActivityTemplate>[] {
    return this.selectedTemplates.map((t: ActivityTemplate) => toMultiSelectableOption(t.name, t.id, t));
  }

  public selectedSalesReps(): MultiSelectableOption<ActivityTemplate>[] {
    return this.selectedUsers.map((u: User) => toMultiSelectableOption(fullName(u), u.id, u));
  }

  public changeSortingCriteria(): ActivitiesFilter {
    let newSortingCriteria;
    switch (this.sortingCriteria) {
    case SortingCriteria.ASCENDING:
      newSortingCriteria = SortingCriteria.DESCENDING;
      break;
    case SortingCriteria.DESCENDING:
      newSortingCriteria = SortingCriteria.ASCENDING;
      break;
    default:
      newSortingCriteria = SortingCriteria.DESCENDING;
      break;
    }

    return new ActivitiesFilter(this.accountName, this.todayFilter, this.toDoFilter, this.overdueFilter, this.completedFilter, this.selectedTemplates, this.selectedUsers, newSortingCriteria);
  }
}
