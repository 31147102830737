import {Role} from '../Roles';
import {AllocationCollection} from '../allocation/allocationCollection';

export abstract class Member {
  constructor(
    public readonly role: Role,
    public readonly email: string,
    public readonly id: string) {
  }

  public abstract hasAllocations(allocations: AllocationCollection): boolean;

  public abstract isSalesRep(): boolean;

  public abstract hasEmail(email: string): boolean;

  public abstract getUserId(): string | undefined;

  public abstract getPhone(): string | undefined;

  public abstract getName(): string;

  public abstract get alreadyMember(): boolean;
}
