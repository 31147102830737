import React from 'react';
import {
  ActivityCompletionResponseDisplayProps,
  SingleValueActivityCompletionResponse
} from '../../../../models/activity/responses/ActivityCompletionResponse';

export const NumericResponseDisplay = ({
  response,
}: ActivityCompletionResponseDisplayProps) => {
  const textResponse = response as SingleValueActivityCompletionResponse;

  return <p className='text-response'> {textResponse.body.value}</p>;
};