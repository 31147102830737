import React, {useEffect, useRef, useState} from 'react';
import '../../../scss/ordo/accountsPage/accountPage/account-page.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import Contact from '../../../models/order-entry/Contact';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import {useAPI} from '../../../context/OrdoApiContext';
import OrdoSpinner from '../../components/OrdoSpinner';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import {ContactInformation} from './MultiContactInformationTooltip';

type AccountContactRowProps = {
  orgId: string,
  contact: Contact,
  onSubmit: Function,
}

export const AccountContactRow = ({orgId, contact, onSubmit} : AccountContactRowProps) => {
  const [openedDeleteContactModal, setOpenedDeleteContactModal] = useState(false);
  const [isContactTooltipVisible, setIsContactTooltipVisible] = useState(false);
  const ref = useRef(null);
  const {spinnerVisibility, showSpinner, hideSpinner} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const api = useAPI();

  const toggleDeleteContactModal = () => {
    setOpenedDeleteContactModal(!openedDeleteContactModal);
  };

  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      // @ts-ignore
      if (isContactTooltipVisible && ref.current && !ref.current.contains(event.target)) {
        setIsContactTooltipVisible(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isContactTooltipVisible]);

  const onContactDelete = () => {
    showSpinner();
    api.deleteContact(orgId, contact.id, contact.accountId)
      .then(() => {
        onSubmit();
        successToast('Contact successfully deleted');
      })
      .catch(() => errorToast('Could not delete contact'))
      .finally(() => {
        hideSpinner();
      });
  };

  const onContactEdit = async () => {
    await onSubmit();
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/mouse-events-have-key-events */}
    <div
      className='account-contact-row'
      ref={ref}
      onClick={() => setIsContactTooltipVisible(oldState => !oldState)}
    >
      <FontAwesomeIcon size="1x" icon={faUser} className='account-contact-row__user-icon'/>
      <div className='account-contact-row__user-info'>
        <span className='account-contact-row__user-info__name' title={contact.name}>{contact.name}</span>
        {contact.title && <span className='account-contact-row__user-info__title' title={contact.title}>{contact.title}</span>}
      </div>
      <div className={`account-contact-row__contact-tooltip-container card card-fluid inventory-list-card ordo-card-border ordo-shadow ${isContactTooltipVisible? 'contact-row-tooltip-visible' : ''}`}>
        <ContactInformation contact={contact} organizationId={orgId} onContactEdit={onContactEdit} toggleDeleteContactModal={toggleDeleteContactModal}/>
      </div>
      <ConfirmationModal
        show={openedDeleteContactModal}
        onClose={toggleDeleteContactModal}
        onSubmit={onContactDelete}
        confirmationText={`are you sure you want to delete the contact ${contact.name}?`}
        actionText='delete'
      />
    </div>
  </OrdoSpinner>;
};
