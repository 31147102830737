import React, {useContext, useEffect, useState} from 'react';
import '../../../../scss/ordo/activitiesPage/activities-page.scss';
import {ActivityTemplatesManagerHeaders} from './ActivityTemplatesManagerHeaders';
import {ActivityTemplateItem} from './ActivityTemplateItem';
import OrdoButton from '../../../components/common/OrdoButton';
import {ActivityTemplate, ActivityTemplateWithCount} from '../../../../models/sales-activity/Activity';
import {useAPI} from '../../../../context/OrdoApiContext';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {ActivityTemplateCreation} from './ActivityTemplateCreation';

export const ActivityTemplatesManager = () => {
  const api = useAPI();
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const {userSession} = useContext(UserSessionContext);
  const [createActivityTemplateForm, setCreateActivityTemplateForm] = useState(false);
  const [activityTemplates, setActivityTemplates] = useState<ActivityTemplateWithCount[]>([]);
  const [activityTemplateToEdit, setActivityTemplateToEdit] = useState<{template: ActivityTemplate, index: number}| undefined>(undefined);

  useEffect(() => {
    showSpinner();
    api.getActivitiesTemplatesForOrg(userSession.currentOrganization!.id)
      .then((activitiesTemplates) => setActivityTemplates(activitiesTemplates))
      .finally(hideSpinner);
  }, []);


  const toggleCreateActivityTemplateModal = () => setCreateActivityTemplateForm(!createActivityTemplateForm);

  const addActivityType = (activityTemplate: ActivityTemplateWithCount) => {
    setActivityTemplates(activityTemplates.concat(activityTemplate));
    setCreateActivityTemplateForm(false);
  };

  const displayUpdateActivityTemplate = (index: number, templateToUpdate: ActivityTemplate) => {
    setActivityTemplateToEdit({template: templateToUpdate, index: index});
    setCreateActivityTemplateForm(true);
  };

  const deleteActivityTemplate = (activityTemplateId: string) => {
    const updatedActivitiesTemplates = activityTemplates.filter((template) => template.activityTemplate.id !== activityTemplateId);
    setActivityTemplates(updatedActivitiesTemplates);
  };

  const updateEditedActivityTemplate = (updatedActivityTemplate: ActivityTemplateWithCount) => {
    activityTemplates[activityTemplateToEdit!.index] = updatedActivityTemplate;
    setActivityTemplates(activityTemplates);
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="card card-fluid activities-types-card ordo-shadow ordo-card-border">
        {!createActivityTemplateForm &&
            <>
              <div className="add-activity-button">
                <OrdoButton disabled={false} text='create custom activity' category="primary"
                  onClick={() => toggleCreateActivityTemplateModal()} dataTestId=''/>
              </div>
              <div className="activities-types-table-container">
                <ActivityTemplatesManagerHeaders/>
                <div className="activities-types-table-list">
                  {activityTemplates.map((activityTemplate, index) => <ActivityTemplateItem
                    key={activityTemplate.activityTemplate.id}
                    activityTemplateWithCount={activityTemplate}
                    onDelete={deleteActivityTemplate}
                    index={index}
                    onActivityTemplateUpdateSelected={displayUpdateActivityTemplate}/>)}
                </div>
              </div>
            </>
        }
        {createActivityTemplateForm && <ActivityTemplateCreation organizationId={userSession.currentOrganization!.id}
          onCreate={(activityTemplate: ActivityTemplateWithCount) => addActivityType(activityTemplate)}
          onEdit={updateEditedActivityTemplate}
          onCancel={() => {
            setActivityTemplateToEdit(undefined);
            toggleCreateActivityTemplateModal();
          }}
          activityTemplateToEdit={activityTemplateToEdit ? activityTemplateToEdit.template : undefined}
        />
        }
      </div>
    </OrdoSpinner>
  );
};
