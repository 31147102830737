import React, {useState} from 'react';
import OrdoModal from '../../components/common/OrdoModal';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import {FlourishInventoryOrItemsRadioGroup} from './FlourishInventoryOrItemsRadioGroup';

type AddFlourishIntegrationModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: (flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string) => any,
}

const AddFlourishIntegrationModal = ({isOpen, onClose, onSubmit} : AddFlourishIntegrationModalProps) => {
  const [flourishAPIKey, setFlourishAPIKey] = useState('');
  const [flourishAPIUsername, setFlourishAPIUsername] = useState('');
  const [flourishSyncInventoryOrItems, setFlourishSyncInventoryOrItems] = useState('Both');

  return (
    <OrdoModal show={isOpen}
      onClose={onClose}
      title="setup flourish integration"
      actionText='save'
      disableCTA={!flourishAPIKey || !flourishAPIUsername}
      onSubmit={() => onSubmit(flourishAPIUsername, flourishAPIKey, flourishSyncInventoryOrItems)}>
      <div>
        <OrdoInput inputName="username" value={flourishAPIUsername} placeholder="flourish api username"
          label="API Username" inputSize="large" onChange={setFlourishAPIUsername}/>
        <OrdoInput inputName="key" value={flourishAPIKey} placeholder="flourish api key"
          label="API Key" inputSize="large" onChange={setFlourishAPIKey}/>

        <div className="form-group ">
          <p>Choose to sync Inventory or Items:</p>
          <div className="form-group mt-3 d-flex">
            <FlourishInventoryOrItemsRadioGroup selected={flourishSyncInventoryOrItems} onChange={setFlourishSyncInventoryOrItems} />
          </div>
        </div>
      </div>
    </OrdoModal>
  );
};

export default AddFlourishIntegrationModal;
