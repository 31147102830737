import React from 'react';
import {sortBy} from 'lodash';
import Product from '../../../models/Product';
import '../../../scss/ordo/inventoryPage/inventory-list-item.scss';
import ProductBatches from './ProductBatches';
import Batch from '../../../models/Batch';
import OrdoCardOptionsDropdown, {DropDownMenuAction} from '../common/OrdoCardOptionsDropdown';
import useModalToggle from '../../../hooks/useModalToggle';
import {ProductSize} from '../product/ProductSize';

type InventoryListItemProps = {
  product: Product,
  options: DropDownMenuAction[],
  onBatchesChanged: Function,
  filteredDistributorLicensesIds: string[]
}

const InventoryListItem = ({product, onBatchesChanged, options, filteredDistributorLicensesIds} : InventoryListItemProps) => {
  const {showModal, closeModal, openModal} = useModalToggle();

  const sortedBatches = (batches: Batch[]) => {
    return sortBy(batches, ['id']);
  };

  const productDescription = product.categoryAndGenetics();

  return (
    <tr className="inventory-list-item-row">
      <td>
        <div className="item-image-and-name d-flex">
          <img src={product.imageUrl} alt={product.name} className="product-image h6 mb-0 text-sm"/>
          <div className='item-name-and-category'>
            <span className="product-name">{product.name}</span>
            <span className="product-category" title={productDescription}>{productDescription}</span>
            <span className="product-category product-sku" title={product.customerSKU}>{product.customerSKU}</span>
          </div>
        </div>
      </td>
      <td className='column-text'>
        <span>{product.brand.name}</span>
      </td>
      <td>
        <ProductSize product={product} isUnit={product.unitSaleOnly}/>
      </td>
      <td className='column-text'>
        <span>{`$${product.price.toFixed(2)}`}</span>
      </td>
      <td className="batches">
        <ProductBatches product={product} batches={sortedBatches(product.batches)} onBatchesChanged={onBatchesChanged} filteredDistributorLicensesIds={filteredDistributorLicensesIds}
          showModal={showModal} closeModal={closeModal} openModal={openModal}/>
      </td>
      <td className="options">
        <OrdoCardOptionsDropdown options={options} size="2x"/>
      </td>
    </tr>);
};

export default InventoryListItem;
