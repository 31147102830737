import React from 'react';
import {CartItem, CartItemType} from '../../../../models/order-entry/CartItem';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {ProductWithAvailability} from '../../../../models/productWithAvailability';
import {AddToCart} from '../../../components/order-entry/AddToCart';
import { ItemInfo } from './ItemInfo';

type MobileOrderEntryCartItemProps = {
  cartItem: CartItem,
  orderEntryViewModel: OrderEntryViewModel,
  updateCart: (prod: ProductWithAvailability, quantity: number, type: CartItemType) => void,
}

export const MobileOrderEntryCartItem = ({cartItem, orderEntryViewModel, updateCart}: MobileOrderEntryCartItemProps) => {
  const {product} = cartItem;
  const amountOfCases =  orderEntryViewModel.amountOfProductFor(product.id, CartItemType.CASE);
  const amountOfUnits = orderEntryViewModel.amountOfProductFor(product.id, CartItemType.UNIT);
  const currentAvailableUnitsQuantity = product.availability - amountOfUnits - (amountOfCases * product.unitsPerCase);
  const currentAvailableCasesQuantity = Math.floor((product.availability - amountOfUnits)/product.unitsPerCase) - amountOfCases;

  const updateOrder = (newAmount: number) => {
    updateCart(product, newAmount, cartItem.type);
  };

  const removeOrder = () => {
    updateCart(product, 0, cartItem.type);
  };

  const maxAmountForItem = () => {
    if(cartItem.type === CartItemType.UNIT) {
      return product.availability - amountOfCases * product.unitsPerCase;
    } return (product.availability - amountOfUnits)/product.unitsPerCase;
  };

  return(
    <div className="product-line">
      <ItemInfo product={product}/>
      <div className="mobile-add-to-cart">

        {cartItem.type === CartItemType.UNIT &&
            <div className="price-per-size">
              <span className='price'>{`$${product.unitSaleOnly ? (product.price).toFixed(2) : (product.price/product.unitsPerCase).toFixed(2)}`}</span>
              <span className="size">{product.size ? product.sizeToString() : 'per unit'}</span>
            </div>
        }

        {cartItem.type === CartItemType.CASE &&
            <div className="price-per-size">
              <span className="price">{`$${(product.price).toFixed(2)}`}</span>
              <span className="size">{`${product.unitsPerCaseToStringMobile()}${product.isAccessories() ? ' per case' : ''}`}</span>
            </div>
        }

        <div className="product-availability">
          <span>{Math.max((cartItem.type === CartItemType.UNIT ? currentAvailableUnitsQuantity : currentAvailableCasesQuantity), 0)}</span>
        </div>
        <div className="cart">
          <AddToCart
            page='list'
            dataTestIdSuffix='order-entry-list-add-unit-to-cart-button'
            productId={product.id}
            productType={cartItem.type}
            productIsDeleted={product.isDeleted()}
            buttonText={cartItem.type === CartItemType.UNIT ? 'add unit' : 'add case'}
            inputLabel={cartItem.type === CartItemType.UNIT ? 'unit' : 'case'}
            displayArrows={false}
            displayInvalidMessage={false}
            updateOrder={updateOrder}
            removeOrder={removeOrder}
            emptyOrders={(cartItem.type === CartItemType.CASE ? amountOfCases : amountOfUnits) === 0}
            ordersAmount={cartItem.type === CartItemType.CASE ? amountOfCases : amountOfUnits}
            maxAmount={maxAmountForItem()}
            stylesForButton={{padding: '0.25em 0.5em', minWidth: '6em'}}
          />
        </div>
      </div>
    </div>
  );
};
