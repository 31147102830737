import firebase from 'firebase';
import 'firebase/auth';
import FirebaseErrorHandler from './firebase_error_handler';
import {appConfig} from '../lib/config';

export interface AuthService {
  login(email: string, password: string): Promise<string>;
  sendRecoveryMail(email: string): Promise<void>;
  verifyPasswordResetCode(oobCode: string): Promise<string>
  confirmPasswordReset(oobCode: string, password: string): Promise<void>
  sendVerificationMail(email: string): Promise<void>
  verifyEmailVerificationCode(email: string, url: string): Promise<void>
}

export default class FirebaseAuthService implements AuthService {
  private static authService: FirebaseAuthService;

  public static createService(firebaseApp: firebase.app.App): AuthService {
    if (!this.authService) {
      this.authService = new FirebaseAuthService(firebaseApp);
    }

    return this.authService;
  }

  private constructor(public firebaseApp: firebase.app.App) {}

  public async login(email: string, password: string): Promise<string> {
    try {
      const userCredential: firebase.auth.UserCredential = await this.firebaseApp.auth().signInWithEmailAndPassword(email, password);
      return userCredential.user!.getIdToken();
    } catch (error) {
      throw FirebaseErrorHandler.handleBusinessError(error);
    }
  }

  public async sendVerificationMail(email: string): Promise<void> {
    try {
      const url = appConfig.appUrl;
      await firebase.auth().sendSignInLinkToEmail(email, {url: url, handleCodeInApp: true});
    } catch (error) {
      throw FirebaseErrorHandler.handleError(error);
    }
  }

  public async sendRecoveryMail(email: string): Promise<void> {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {
      throw FirebaseErrorHandler.handleError(error);
    }
  }

  public async confirmPasswordReset(oobCode: string, password: string): Promise<void> {
    this.validateOobCode(oobCode);
    try {
      return await firebase.auth().confirmPasswordReset(oobCode, password);
    } catch (error) {
      throw FirebaseErrorHandler.handleError(error);
    }
  }

  /**
   * Verifies password reset code and returns user email if oobCode still valid.
   * Otherwise returns an error.
   * @param oobCode
   */
  public async verifyPasswordResetCode(oobCode: string): Promise<string> {
    this.validateOobCode(oobCode);
    try {
      return await firebase.auth().verifyPasswordResetCode(oobCode);
    } catch (error) {
      throw FirebaseErrorHandler.handleError(error);
    }
  }

  public async verifyEmailVerificationCode(email: string, url: string): Promise<void> {
    if (firebase.auth().isSignInWithEmailLink(url)) {
      try {
        // We don't care about the response because we don't want to allow users to signIn with an email link.
        await firebase.auth().signInWithEmailLink(email, url);
      } catch (error) {
        // If firebase throws an error it means that we shouldn't verify the email in the backend
        throw new Error('We couldn\'t verify your email. The verification code is invalid.');
      }
    }
  }

  private validateOobCode(oobCode: string) {
    if (oobCode === '') {
      throw Error('malformed code');
    }
  }
}
