import React, {useEffect, useState} from 'react';
import '../../../../scss/ordo/ordo-input.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/free-solid-svg-icons';
import {OrdoInputProps} from './OrdoInput';


const OrdoInputWithIcon = ({
  inputName,
  label,
  value,
  placeholder,
  inputSize,
  readOnly = false,
  isValid = true,
  invalidMessage= '',
  onChange,
  onBlur,
  inputTitle,
  inputType = 'text | number | email | password',
  min,
  step,
  style={},
  validate,
  bottomMargin = true,
  additionalClassName= '',
  icon
}: OrdoInputProps & {icon: IconDefinition}) => {
  const [inputValue, setInputValue] = useState(value);

  const size = `ordo-input-${inputSize}`;
  let errorMessage = invalidMessage;
  if (validate) {
    errorMessage = validate(inputValue);
  }
  const showErrorMessage = errorMessage.length > 0 || !isValid;

  useEffect(() => setInputValue(value), [value]);

  const marginBottom = bottomMargin ? 'mb-3' : '';

  return (
    <div className={`ordo-input input-group-sm ${marginBottom} ${size} ${additionalClassName || ''}`}>
      <label htmlFor={`input-${inputName}`}>
        {label}
        <span className='ordo-input__title'>{inputTitle}</span>
        <div className='ordo-input-container'>
          <FontAwesomeIcon icon={icon} className="ordo-input-icon"/>
          <input readOnly={readOnly}
            id={`input-${inputName}`}
            data-testid={`input-${inputName}`}
            type={inputType}
            min={min}
            step={step}
            className={`form-control no-spin ${showErrorMessage ? 'is-invalid' : ''} ordo-input__input`}
            placeholder={placeholder}
            value={inputValue}
            onChange={(event) => {
              setInputValue(event.target.value);
              if (onChange)
                onChange(event.target.value);
            }}
            onBlur={(event) => { if(onBlur) onBlur(event); }}
            onKeyPress={ (event) => {
              if(event.key === 'Enter' && onBlur) onBlur(event);
            }}
            style={style}/>
        </div>
      </label>
      {showErrorMessage && <div className="invalid-feedback" data-testid={`invalid-${inputName}-feedback`}>
        {errorMessage}
      </div>}
    </div>
  );
};

export default OrdoInputWithIcon;
