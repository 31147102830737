import React from 'react';
import OrdoButton from '../components/common/OrdoButton';
import {AccountIdAndName, ContactItemRow} from './ContactItemRow';
import {ContactWithAccountName} from './ContactsPage';

type ContactsListProps = {contactsWithAccountName: ContactWithAccountName[],
  openAddContactModal: () => void,
  onUpdateContact: (contact: ContactWithAccountName, index: number) => void,
  organizationId: string,
  displayableAccounts: AccountIdAndName[]
}

export const ContactsList = ({contactsWithAccountName, openAddContactModal, onUpdateContact, organizationId, displayableAccounts} : ContactsListProps) => {
  return(<div>
    <div className="add-activity-button">
      <OrdoButton disabled={false} text="add contact" category="primary" onClick={openAddContactModal} dataTestId=""/>
    </div>
    { contactsWithAccountName.length > 0 && <div className="activity-items">
      <div className="contacts-list-headers">
        <div className="contact-name">
          <span>Contact name</span>
        </div>
        <div className="role">
          <span>Role</span>
        </div>
        <div className="account-name">
          <span>Account</span>
        </div>
        <div className="phone">
          <span>Phone</span>
        </div>
        <div className="email">
          <span>Email</span>
        </div>
        <div className="edit-contact-header"/>
        <div className="delete-contact-header"/>
      </div>
      {
        contactsWithAccountName.map((contact: ContactWithAccountName, index: number) => <ContactItemRow
          key={contact.contact.id}
          contactWithAccountName={contact}
          displayableAccounts={displayableAccounts}
          onUpdateContact={(updatedContact: ContactWithAccountName) => onUpdateContact(updatedContact, index)} organizationId={organizationId}/>)
      }
    </div>}
  </div>);
};