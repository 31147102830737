import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter} from '@fortawesome/free-solid-svg-icons';
import '../../../../scss/ordo/mobileOrderEntry/mobile-order-entry-filters.scss';
import ProductFilterViewModel, {
  MultiFiltersKeys,
  UpdateSearchBarFilter
} from '../../../../application-models/ProductFilterViewModel';
import {ProductWithAvailability} from '../../../../models/productWithAvailability';
import OrdoInput from '../../../components/common/inputs/OrdoInput';
import MobileDisplayAsModal from '../../../components/common/MobileDisplayAsModal';
import { FilterProducts } from './FilterProducts';
import {UserSessionContext} from '../../../../context/UserSessionContext';

type MobileOrderEntryFiltersSectionProps = {
  updateFilteredProducts: (filteredProducts: ProductWithAvailability[]) => void,
  productsToFilter: ProductWithAvailability[],
  landscapeMode?: boolean
}

export const MobileOrderEntryFiltersSection = ({updateFilteredProducts, productsToFilter, landscapeMode = false}: MobileOrderEntryFiltersSectionProps) => {
  const [displayFiltersView, setDisplayFiltersView] = useState(false);
  const userSession = useContext(UserSessionContext);
  const [productFilterViewModel, setProductFilterViewModel] = useState<ProductFilterViewModel<ProductWithAvailability>>(ProductFilterViewModel.emptySearchBarViewModel(userSession.currentOrganization()?.licenses!));

  useEffect(() => {
    setProductFilterViewModel(productFilterViewModel.updateProducts(productsToFilter));
  }, [productsToFilter]);

  const applyFilters = (updateSearchBarFilter: UpdateSearchBarFilter) => {
    const updatedViewModel = productFilterViewModel.updateFilters(updateSearchBarFilter);
    updateFilteredProducts(updatedViewModel.filteredProducts);
  };

  const handleFilters = (filterPositionSelected: number, typeOfFilter: MultiFiltersKeys) => {
    applyFilters(() => productFilterViewModel.updateFilter(filterPositionSelected, typeOfFilter));
  };

  const updateSearchProduct = (searchProduct: string) => {
    applyFilters(() => productFilterViewModel.updateSearchProduct(searchProduct));
  };

  return (<div className="mobile-order-entry-header__selector">
    <OrdoInput
      additionalClassName={`search-by-product-name ${landscapeMode ? 'landscape' : ''}`}
      bottomMargin={false}
      inputName='search-by-product-input'
      value={productFilterViewModel.searchProduct}
      placeholder='search product'
      inputSize='large'
      onChange={(value) => updateSearchProduct(value)}
    />
    <FontAwesomeIcon
      className="filter-products-icon"
      size="2x"
      icon={faFilter}
      onClick={() => setDisplayFiltersView(true)}
    />
    {displayFiltersView && <MobileDisplayAsModal
      show={displayFiltersView}
      onClose={() => {}}
      dialogClassName="view-mobile-as-modal"
    >
      <FilterProducts
        updateFilters={handleFilters}
        searchBarViewModel={productFilterViewModel}
        onClose={() => {
          setDisplayFiltersView(false);
        }}
      />
    </MobileDisplayAsModal>}
  </div>);
};
