import React, {useContext, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {ActivitiesList} from './ActivitiesList';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import '../../../../scss/ordo/activitiesPage/activities-list.scss';
import { ActivitiesFilter } from '../../filter/ActivitiesFilter';
import {useAPI} from '../../../../context/OrdoApiContext';
import {ActivityTemplate} from '../../../../models/sales-activity/Activity';
import { ActivitiesStateFilters } from './ActivitiesStateFilters';
import OrdoMultiSelect, { toMultiSelectableOption } from '../../../components/common/OrdoMultiSelect';
import {fullName, User} from '../../../../models/User';
import {AdminChecker} from '../../../../routes/permissions';

export const ActivitiesPage = () => {
  const { spinnerVisibility} = useSpinnerToggle();
  const {userSession} = useContext(UserSessionContext);
  const api = useAPI();
  const [templates, setTemplates] = useState<ActivityTemplate[]>([]);
  const [filter, setFilter] = useState(ActivitiesFilter.empty());
  const adminChecker = new AdminChecker();
  const isAdmin = adminChecker.allow(userSession);
  const users = userSession.currentOrganization?.members.map(member => member.user) || [];

  useEffect(() => {
    api.getActivitiesTemplatesForOrg(userSession.currentOrganization!.id)
      .then((templatesResponse) => {
        setTemplates(templatesResponse.map(at => at.activityTemplate));
      });
  }, []);

  const changeSortingCriteria = () => setFilter(filter.changeSortingCriteria());

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='activity-page-filters'>
        <div className='account-and-type-filter'>
          <input
            id='search-by-account-name-input'
            data-testid='search-by-location-name-input'
            type='text'
            style={{width: '20%'}}
            className='search-by-account-input'
            placeholder='search account name'
            value={filter.getAccountValue()}
            onChange={(event) => setFilter(filter.updateAccountSearch(event.target.value))}
          />

          <OrdoMultiSelect options={templates.map((t: ActivityTemplate) => toMultiSelectableOption(t.name, t.id, t))}
            selectedOptions={filter.selectedActivityTemplates()} placeholder="activities: all" addedStyles={
              {
                container: {width: '26%'},
                control: {marginBottom: '0'},
                multiValue: {width: '45%'}
              }
            }
            onChangeSelectedOption={(option) => setFilter(filter.setActivityTypeFilter(option))}/>
          {isAdmin && <OrdoMultiSelect options={users.map((u: User) => toMultiSelectableOption(fullName(u), u.id, u))}
            selectedOptions={filter.selectedSalesReps()} placeholder="select sales rep"
            addedStyles={
              {
                container: {width: '26%'},
                control: {marginBottom: '0'},
                multiValue: {width: '45%'}
              }
            }
            onChangeSelectedOption={(option) => setFilter(filter.setSalesRepFilter(option))}/> }

          <div role="presentation" onClick={changeSortingCriteria} className="sorting-date-filter">
            <FontAwesomeIcon className="sort-icon" icon={faExchangeAlt}/>
            <span>{`Date: ${filter.sortingCriteriaText()}`}</span>
          </div>
        </div>
        <ActivitiesStateFilters filter={filter} onUpdateFilter={setFilter}/>
      </div>
      <div className="card card-fluid activities-types-card ordo-shadow ordo-card-border">
        <ActivitiesList organizationId={userSession.currentOrganization!.id} filter={filter}/>
      </div>
    </OrdoSpinner>
  );
};
