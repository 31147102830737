import React, {PropsWithChildren, ReactNode} from 'react';

type SalesActivityCardDetailsProps = {} & PropsWithChildren<ReactNode>;
export const SalesActivityCardDetails = ({children}: SalesActivityCardDetailsProps) => {

  return(
    <div className="d-flex flex-column">
      {children}
    </div>
  );
};
