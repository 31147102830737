import Fuse from 'fuse.js';
import {isEmpty} from 'lodash';
import Product from '../models/Product';
import clamp from '../utils/number-functions';
import {InMemoryPaginator, Paginator} from '../utils/pagination/InMemoryPaginator';
import {OrganizationLicense} from '../models/OrganizationLicense';

export enum FILTER_VALUES {
	BRANDS = 'brands',
	CATEGORIES = 'categories',
	STRAIN_TYPE = 'strainType',
	SIZE = 'size',
	DISTRIBUTORS = 'distributors'
}

export type MultiFilters = {
	brands: string[];
	categories: string[];
	strainType: string[];
	size: string[];
	distributors: string[];
}

export const filters = [
  {filterValue: FILTER_VALUES.DISTRIBUTORS, typeOfFilter: 'distributors'},
  {filterValue: FILTER_VALUES.BRANDS, typeOfFilter: 'brands'},
  {filterValue: FILTER_VALUES.CATEGORIES, typeOfFilter: 'categories'},
  {filterValue: FILTER_VALUES.STRAIN_TYPE, typeOfFilter: 'strainType'},
  {filterValue: FILTER_VALUES.SIZE, typeOfFilter: 'size'}
];

export type MultiFiltersKeys = 'brands' | 'categories' | 'strainType' | 'size' | 'distributors';

export type UpdateSearchBarFilter = () => void;

type OrganizationLicenseInternalAndExternalId = {distributorOrganizationLicenseId: string, distributorOrganizationExternalLicenseId: string};

export default class ProductFilterViewModel<T extends Product> {
	public searchProduct: string;
	public multiFilters: MultiFilters;
	public filteredProducts:T[];
	public products:T[];
	private stockOnly: boolean;
	private readonly distributorLicenses: OrganizationLicenseInternalAndExternalId[];
	private paginatorFilterFn?: ((p: T)=>boolean)
	public currentPage: number;
	private readonly paginator: Paginator<T>;
	public static PRODUCTS_PER_PAGE: number = 20;
	public static FIRST_PAGE_NUMBER: number = 1;

	constructor(searchProduct: string, multiFilters: MultiFilters, filteredProducts:T[], products:T[], stockOnly: boolean, distributorLicenses: OrganizationLicenseInternalAndExternalId[],  currentPage: number, paginator: Paginator<T>, paginatorFilterFn?: ((p: T)=>boolean)) {
	  this.searchProduct = searchProduct;
	  this.multiFilters = multiFilters;
	  this.filteredProducts = filteredProducts;
	  this.products = products;
	  this.stockOnly = stockOnly;
	  this.distributorLicenses = distributorLicenses;
	  this.paginator = paginator;
	  this.currentPage = clamp(currentPage, ProductFilterViewModel.FIRST_PAGE_NUMBER, this.paginator.amountOfPages(ProductFilterViewModel.PRODUCTS_PER_PAGE));
	  this.paginatorFilterFn = paginatorFilterFn;
	}

	public static emptySearchBarViewModel<U extends Product>(withLicenses: OrganizationLicense[], paginatorFilterFn?: ((p: U)=>boolean)): ProductFilterViewModel<U> {
	  const multiFilters = {
	    brands: [],
	    categories: [],
	    strainType: [],
	    size: [],
	    distributors: []
	  };
	  const distributorLicenses = withLicenses.map(d => {
	    return {
	      distributorOrganizationLicenseId: d.id,
	      distributorOrganizationExternalLicenseId: d.license.licenseNumber
	    };
	  });
	  return new ProductFilterViewModel('',multiFilters, [], [], false, distributorLicenses, 1, new InMemoryPaginator([], paginatorFilterFn), paginatorFilterFn );
	}

	public updateProducts(products: T[]): ProductFilterViewModel<T> {
	  this.products = products;
	  this.filteredProducts = products;

	  return this.applyFilters();
	}

	public updateSearchProduct(searchProduct: string) {
	  this.searchProduct = searchProduct;
	  return this;
	}

	public updateFilter(valuePosition: number, typeOfFilter: MultiFiltersKeys) {
	  const options = typeOfFilter === 'distributors' ? this.getDistributorFilterOptions() : this.getFilterTypeOptions(typeOfFilter);
	  const value = options[valuePosition];
	  const currentSelectedOptions = this.multiFilters[typeOfFilter];
	   this.multiFilters[typeOfFilter] = currentSelectedOptions.includes(value) ?
	    currentSelectedOptions.filter(val => val !== value) : currentSelectedOptions.concat(value);
	  return this;
	}


	public updateFilters(updateSearchBarFilter: UpdateSearchBarFilter) {
	  updateSearchBarFilter();
	  return this.applyFilters();
	};

	public applyFilters() {
	  this.filterProductsByName(this.products);
	  this.filterByStock();
	  this.filterByDistributors();
	  this.applyFilterTypeToProducts(this.multiFilters.brands, FILTER_VALUES.BRANDS);
	  this.applyFilterTypeToProducts(this.multiFilters.categories, FILTER_VALUES.CATEGORIES);
	  this.applyFilterTypeToProducts(this.multiFilters.strainType, FILTER_VALUES.STRAIN_TYPE);
	  this.applyFilterTypeToProducts(this.multiFilters.size, FILTER_VALUES.SIZE);
	  return new ProductFilterViewModel(this.searchProduct, this.multiFilters, this.filteredProducts, this.products, this.stockOnly,  this.distributorLicenses, 0, new InMemoryPaginator(this.filteredProducts, this.paginatorFilterFn), this.paginatorFilterFn);
	}

	public changePage( pageNumber: number) {
	  return new ProductFilterViewModel(this.searchProduct, this.multiFilters, this.filteredProducts, this.products, this.stockOnly, this.distributorLicenses, pageNumber, this.paginator, this.paginatorFilterFn);
	}

	public paginatedProducts(): T[] {
	  return this.paginator.getCurrentPageElements();
	}

	public getFilterTypeOptions(filter: string): string[] {
	  let results =  this.products.map((product) => this.getProductFilteredValue(product, filter));
	  results = results.reduce((unique: string[], item) => (unique.includes(item) || item === '' ? unique : [...unique, item]),
		  []);
	  return results;
	}

	public getFilterOptions(filter: string) : string[] {
	  return filter === FILTER_VALUES.DISTRIBUTORS ? this.getDistributorFilterOptions() : this.getFilterTypeOptions(filter);
	}

	private getDistributorFilterOptions(): string[] {
	  let results =  (this.products.map((product) => this.getProductBatchesDistributors(product))).flat();
	  results = results.reduce((unique: string[], item) => (unique.includes(item) || item === '' ? unique : [...unique, item]),
	    []);
	  return results;
	};

	public isEmptyFilter(filter: string) {
	  return isEmpty(this.getFilterTypeOptions(filter)) && isEmpty(this.getDistributorFilterOptions());
	}

	public numberOfFiltersSelected() {
	  return Object.keys(this.multiFilters).reduce((accum, actualKey) => this.multiFilters[actualKey as MultiFiltersKeys].length + accum, 0);
	}

	public selectedValuesFor(typeOfFilter: string) {
	  return this.multiFilters[typeOfFilter as MultiFiltersKeys];
	}

	private applyFilterTypeToProducts(selectedFilters: string[], filteredAttribute: string) {
	  this.filteredProducts =  this.filteredProducts.filter(product =>
	    isEmpty(selectedFilters) || selectedFilters.includes(this.getProductFilteredValue(product, filteredAttribute)));
	};

	private getProductFilteredValue(product:T, filter: string): string {
	  if (filter === FILTER_VALUES.BRANDS) return product.brand.name;
	  if (filter === FILTER_VALUES.CATEGORIES) return product.category.name;
	  if (filter === FILTER_VALUES.STRAIN_TYPE) return product.geneticsToString();
	  if (filter === FILTER_VALUES.SIZE) return product.sizeToString();
	  return '';
	}

	private filterProductsByName(products:T[]) {
	  if (this.searchProduct === '') {
	    this.filteredProducts = products;
	    return;
	  }
	  const searcher = new Fuse(products,
		  { keys: ['name', 'customerSKU'],
			  threshold: 0.0,
			  ignoreLocation: true,
			  findAllMatches: true,
		  });
	  this.filteredProducts = searcher.search(this.searchProduct).map(res => res.item);
	}

	public shouldDisplayStockOnly() {
	  return this.stockOnly;
	}

	public toggleStockOnly() {
	  this.stockOnly = !this.stockOnly;
	  return this;
	}

	public getBatchesOrganizationLicensesIds() : string[]{
	  return this.distributorLicenses.filter(dL => this.multiFilters.distributors.includes(dL.distributorOrganizationExternalLicenseId)).map(oL => oL.distributorOrganizationLicenseId);
	}

	public totalPages() {
	  return this.paginator.amountOfPages(ProductFilterViewModel.PRODUCTS_PER_PAGE);
	}

	public async paginate() {
	  return this.paginator.paginate(ProductFilterViewModel.PRODUCTS_PER_PAGE, this.currentPage);
	}

	private filterByStock() {
	  if(!this.stockOnly) return;

	  this.filteredProducts =  this.filteredProducts.filter(product =>
	    !isEmpty(product.batches) && product.batches.some(batch => batch.quantityOfUnits > 0));
	}

	private filterByDistributors() {
	  if (this.multiFilters.distributors.length > 0) {
	    const organizationLicenses = this.distributorLicenses.filter(dL => this.multiFilters.distributors.includes(dL.distributorOrganizationExternalLicenseId)).map(oL => oL.distributorOrganizationLicenseId);
	    this.filteredProducts = this.filteredProducts.filter(product =>
	      product.batches.some(batch => organizationLicenses.includes(batch.distributorOrganizationLicenseId)));
	  }
	}

	private getProductBatchesDistributors(product: T): string[] {
	  return product.batches.map(b => {
	    const externalLicense = this.distributorLicenses.find((dL: OrganizationLicenseInternalAndExternalId) => dL.distributorOrganizationLicenseId === b.distributorOrganizationLicenseId);
	    return externalLicense ? externalLicense.distributorOrganizationExternalLicenseId : '';
	  });
	}
}
