import React from 'react';
import {
  ActivityCompletionResponseProps,
  SingleValueActivityCompletionResponse
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import OrdoInput from '../../common/inputs/OrdoInput';

export const NumericResponse = ({
  response,
  updateResponse
}: ActivityCompletionResponseProps) => {
  const textResponse = response as SingleValueActivityCompletionResponse;
  const regExp = new RegExp(/[$%#,.0-9]+/g);

  const onChange = (value: string) => {
    textResponse.body = {value: value};
    updateResponse(textResponse);
  };

  return <OrdoInput maxLength={20} value={textResponse.body?.value || ''}
    inputName={`numeric-response-${response.index}`}
    inputSize="medium"
    placeholder=''
    onChange={onChange}
    regex={regExp}
  />;
};