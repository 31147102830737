export enum OrdoLicenseType {
  Unknown,
  Manufacturer,
  Distributor,
  Retailer,
  RetailerNonStoreFront,
  Microbusiness,
}

export type License = {
  id: string
  name: string
  licenseNumber: string
  ordoType: OrdoLicenseType
}

export type OrganizationLicense = {
  id: string,
  license: License
  status: string
  streetAddressLine1: string,
  streetAddressLine2: string,
  city: string,
  state: string,
  zipCode: string,
  createdAt: Date,
}

export function organizationLicenseAddress(orgLicense: OrganizationLicense) {
  const streetAddress = orgLicense.streetAddressLine2 ? `${orgLicense.streetAddressLine1} ${orgLicense.streetAddressLine2}` : `${orgLicense.streetAddressLine1}`;
  return `${streetAddress}, ${orgLicense.city} ${orgLicense.state} ${orgLicense.zipCode}`;
}

export const emptyOrganizationLicense = {
  id: '',
  license: {
    id: '',
    name: '',
    licenseNumber: '',
    ordoType: OrdoLicenseType.Unknown
  },
  status: 'Unknown',
  streetAddressLine1: 'line 1',
  streetAddressLine2: 'line 2',
  city: 'city',
  state: 'state',
  zipCode: 'zipCode',
  createdAt: new Date(),
};
