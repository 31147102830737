const ROUTE_NAMES = {
  LOGIN: '/login',
  LOGIN_AND_ACCEPT_INVITATION: '/login/:invitationTokenId',
  REGISTER: '/register',
  PROFILE: '/',
  ORGANIZATION: '/organization',
  ACCEPT_INVITATION: '/accept-invitation/:invitationTokenId',
  ACCEPT_INVITATION_AND_JOIN_ORDO: '/accept-invitation-join/:invitationTokenId',
  INVENTORY: '/inventory',
  ACCOUNTS: '/accounts',
  ORDER_ENTRY: '/order-entry',
  EDIT_ORDER: '/edit-order',

  ORDER_HISTORY: '/order-history',
  SALES_ACTIVITY: '/sales-activity',

  PASSWORD_RESET: '/password-reset',
  ACCOUNT_MANAGEMENT: '/account-management',
  ACTIVITY_MANAGER: '/activities',
  ACCOUNT_PAGE: '/accounts/:accountId',
  TIMELINE: '/timeline',
  CONTACTS_PAGE: '/contacts'
};

export const FullPageRoutes = [
  ROUTE_NAMES.LOGIN,
  ROUTE_NAMES.LOGIN_AND_ACCEPT_INVITATION,
  ROUTE_NAMES.ACCEPT_INVITATION,
  ROUTE_NAMES.ACCEPT_INVITATION_AND_JOIN_ORDO,
  ROUTE_NAMES.REGISTER,
  ROUTE_NAMES.PASSWORD_RESET,
  ROUTE_NAMES.ACCOUNT_MANAGEMENT,
];

export const isFullPageRoute = (path: string): boolean => {
  return path !== '/' &&
    FullPageRoutes.some(route => route.split('/')[1].includes(path.split('/')[1]));
};

export const RoutesWithoutOverlay = [
  ROUTE_NAMES.PROFILE,
];

export const WiderRoutes = [
  ROUTE_NAMES.SALES_ACTIVITY,
];


export default ROUTE_NAMES;
