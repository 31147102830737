import {v4} from 'uuid';
import {Money, SerializableMoney} from '../Money';
import {DiscountType} from './Order';
import {isValidDiscountPrice, isValidFractionDiscountPercentage, isValidPrice, isValidQuantity} from './OrderValidator';
import {OrderVersionLineItem} from './OrderVersionLineItem';
import {ProductWithAvailability} from '../productWithAvailability';
import {CartItemType} from './CartItem';

export type SerializableItemLine = {
  id: string,
  quantity: number,
  price: SerializableMoney,
  taxes: SerializableMoney,
  discountType: DiscountType,
  discountPercentage: number,
  discountCurrencyAmount: SerializableMoney,
  notes: string,
}

export class ItemLine {
  public readonly id: string;

  public static recreateFromOrder(orderVersionLineItem: OrderVersionLineItem) {
    const itemLine = new ItemLine(
      orderVersionLineItem.quantity,
      orderVersionLineItem.price,
      orderVersionLineItem.discountType,
      orderVersionLineItem.discountPercentage,
      orderVersionLineItem.discountCurrencyAmount,
      Money.FromDollarAmount(0),
      orderVersionLineItem.notes,
    );
    itemLine.setTaxes(orderVersionLineItem.exciseTax, orderVersionLineItem.salesTax);
    return itemLine;
  }

  public static recreateFromSerializable(storedLine: SerializableItemLine) {
    return new ItemLine(
      storedLine.quantity,
      Money.FromSerializable(storedLine.price),
      storedLine.discountType,
      storedLine.discountPercentage,
      Money.FromSerializable(storedLine.discountCurrencyAmount),
      Money.FromSerializable(storedLine.taxes),
      storedLine.notes
    );
  }

  public static newLineItem(quantity: number, itemPriceMoney: Money): ItemLine {
    return new ItemLine(
      quantity,
      itemPriceMoney,
      DiscountType.None,
      0,
      new Money(0),
    );
  }

  constructor (
    public quantity: number,
    private price: Money,
    private discountType: DiscountType,
    private discountPercentage: number,
    private discountCurrencyAmount: Money,
    private taxes: Money = new Money(0),
    private notes: string = '',
  ) {
    this.id = v4();
    this.quantity = quantity;
    this.price = price;
    this.discountType = discountType;
    this.discountPercentage = discountPercentage;
    this.discountCurrencyAmount = discountCurrencyAmount;
    this.taxes = taxes;
    this.notes = notes;
  }

  toJSON(): SerializableItemLine {
    return {
      id: this.id,
      quantity: this.quantity,
      price: this.price.toJSON(),
      taxes: this.taxes.toJSON(),
      discountType: this.discountType,
      discountCurrencyAmount: this.discountCurrencyAmount.toJSON(),
      discountPercentage: this.discountPercentage,
      notes: this.notes
    };
  }

  getQuantity() {
    return this.quantity;
  }

  getPrice() {
    return this.price;
  }

  getDiscountType(): DiscountType {
    return this.discountType;
  }

  getTaxes(): Money {
    return this.taxes;
  }

  getDiscountPercentage(): number {
    return this.discountPercentage;
  }

  getDiscountCurrencyAmount(): Money {
    return this.discountCurrencyAmount;
  }

  hasDiscounts(): boolean {
    return this.discountType !== DiscountType.None;
  }

  priceInputValue(): number {
    return this.price.toUnit();
  }

  getNotes(): string {
    return this.notes;
  }

  discountInputValue(): number {
    switch (this.discountType) {
    case DiscountType.Dollar:
      return this.discountCurrencyAmount.toUnit();
    case DiscountType.Percentage:
      return this.discountPercentage;
    default:
      return 0;
    }
  }

  totalWithoutDiscount(): Money {
    return this.price.multiply(this.quantity);
  }

  total(): Money {
    // TODO: GF - can this come from backend response?
    return this.totalWithoutDiscount().subtract(this.discountMoney());
  }

  discountExceedsPrice(): boolean {
    switch (this.discountType) {
    case DiscountType.Dollar:
      return this.discountCurrencyAmount.isGreaterThan(this.totalWithoutDiscount());
    case DiscountType.Percentage:
      return !isValidFractionDiscountPercentage(this.discountPercentage);
    default:
      return false;
    }
  }

  public setTaxes(exciseTax: Money, salesTax: Money) {
    this.taxes = exciseTax.add(salesTax);
  }

  public setPrice(price: number) {
    if (!isValidPrice(price)) return;
    this.price = Money.FromDollarAmount(price);
  }

  public setDiscountType(type: DiscountType) {
    this.discountType = type;
  }

  public setNotes(notes: string) {
    this.notes = notes;
  }

  public setDiscount(discount: number) {
    switch (this.discountType) {
    case DiscountType.Dollar:
      if (!isValidDiscountPrice(discount, this.totalWithoutDiscount())) return;
      this.discountCurrencyAmount = Money.FromDollarAmount(discount);
      break;
    case DiscountType.Percentage:
      if (!isValidFractionDiscountPercentage(discount)) return;
      this.discountPercentage = discount;
      break;
    default:
      // nothing to do
    }
    if (discount === 0)
      this.setDiscountType(DiscountType.None);
  }

  public setQuantity(quantity: number) {
    if (!isValidQuantity(quantity)) return;

    this.quantity = quantity;
  }

  private discountMoney(): Money {
    switch (this.discountType) {
    case DiscountType.Dollar:
      return this.discountCurrencyAmount;
    case DiscountType.Percentage:
      return this.totalWithoutDiscount().multiply(this.discountPercentage);
    default:
      return new Money(0);
    }
  }

  public updateProductPrice(product: ProductWithAvailability, type: CartItemType) {
    if(type === CartItemType.CASE) {
      this.price = Money.FromDollarAmount(product.unitPrice * product.unitsPerCase);
    }
    if (type === CartItemType.UNIT) {
      this.price = Money.FromDollarAmount(product.unitPrice);
    }
  }

  public priceInputValueForCase(divider: number) {
    return ((this.price.getAmount()/divider)/100).toFixed(2);
  }
}
