import {OrganizationLicense} from '../../models/OrganizationLicense';
import {API} from '../../lib/api/ordoApi';

export enum MetrcItemSyncStep {
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
}

export interface MetrcSyncStep {
  headerText() : string;
  headerSubtitle() : string;
  actionText() : string;
  secondaryActionText() : string;
  disableAction(selectedLicenses: OrganizationLicense[]) : boolean;
  nextStep() : MetrcSyncStep;
  previousStep() : MetrcSyncStep;
  submit(api: API, orgId: string, selectedLicences: OrganizationLicense[], callback: Function): void;
  step(): MetrcItemSyncStep;
}

