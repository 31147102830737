import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {PRIMARY_COLOR} from '../../../../constants';
import OrdoButton from '../OrdoButton';

export const ButtonOption = ({data}: { data: any }) => {
  return <div className={`add-item-option-container ${data.disabled ? 'option-disabled' : ''}`}>
    <OrdoButton disabled={!!data.disabled}
      text={data.addItemText}
      category="link"
      onClick={data.onAddItem}
      dataTestId="dropdown-add-item-button"
      firstChild={<FontAwesomeIcon color={`${PRIMARY_COLOR}`} size="lg" icon={faPlusCircle}/>}/>
  </div>;
};