import React, {useContext, useEffect, useState} from 'react';
import OrdoButton from '../common/OrdoButton';
import {Activity} from '../../../models/sales-activity/Activity';
import {OrdoPagination} from '../pagination/OrdoPagination';
import '../../../scss/ordo/activitiesPage/activity-complete-wizard.scss';
import {DateFormatTemplate, OrdoDate} from '../../../models/OrdoDate';
import {ActivityQuestionType} from '../../../models/sales-activity/ActivityQuestion';
import {
  ActivityCompletionResponse,
  createActivityCompletionResponse
} from '../../../models/activity/responses/ActivityCompletionResponse';
import Product from '../../../models/Product';
import {SelectableOption} from '../common/searchable-dropdown/selectableOption';
import {useAPI} from '../../../context/OrdoApiContext';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {TextResponse} from './wizard-responses/TextResponse';
import {NumericResponse} from './wizard-responses/NumericResponse';
import {DollarValueResponse} from './wizard-responses/DollarValueResponse';
import {ProductDropdownResponse} from './wizard-responses/ProductDropdownResponse';
import {FileUploadResponse} from './wizard-responses/FileUploadResponse';
import {CheckboxResponse} from './wizard-responses/CheckboxResponse';
import {MultipleChoiceResponse} from './wizard-responses/MultipleChoiceResponse';
import {DateTimeResponse} from './wizard-responses/DateTimeResponse';


function renderResponseInput(response: ActivityCompletionResponse, updateResponse: Function, products: SelectableOption<Product>[]) {
  switch(response.questionType) {
  case ActivityQuestionType.TextResponse:
    return <TextResponse response={response} updateResponse={updateResponse}/>;
  case ActivityQuestionType.NumericResponse:
    return <NumericResponse response={response} updateResponse={updateResponse}/>;
  case ActivityQuestionType.DollarValue:
    return <DollarValueResponse response={response} updateResponse={updateResponse}/>;
  case ActivityQuestionType.ProductDropdown:
    return <ProductDropdownResponse response={response} updateResponse={updateResponse} products={products}/>;
  case ActivityQuestionType.FileUpload:
    return <FileUploadResponse response={response} updateResponse={updateResponse}/>;
  case ActivityQuestionType.Checkboxes:
    return <CheckboxResponse response={response} updateResponse={updateResponse}/>;
  case ActivityQuestionType.MultipleChoice:
    return <MultipleChoiceResponse response={response} updateResponse={updateResponse}/>;
  case ActivityQuestionType.DateTime:
    return <DateTimeResponse response={response} updateResponse={updateResponse}/>;
  case ActivityQuestionType.NoTypeSelectedYet:
  default:
    return <span>Unhandled question type: {response.questionType}</span>;
  }
}

const wideOptions = [ActivityQuestionType.ProductDropdown];

type ActivityCompleteWizardProps = {
  activity: Activity,
  onSubmit: (responses: ActivityCompletionResponse[])=> void,
  onCancel: Function,
  setWideDisplay: (b: boolean) => void
};

export const ActivityCompleteWizard = ({activity, onSubmit, onCancel, setWideDisplay}: ActivityCompleteWizardProps) => {
  const template = activity.activityTemplate!;
  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const currentOrganization = userSession.currentOrganization();
  const {showSpinner, hideSpinner, spinnerVisibility} = useSpinnerToggle();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const totalQuestions = template.questions.length;
  const [responses, setResponses] = useState<ActivityCompletionResponse[]>(template.questions.map(question => {
    return createActivityCompletionResponse(question);
  }));
  const [productOptions, setProductsOptions] = useState<SelectableOption<Product>[]>([]);

  useEffect(()=>{
    if(responses.some(r => r.questionType === ActivityQuestionType.ProductDropdown)) {
      showSpinner();
      api.getProducts(currentOrganization!.id)
        .then((products) => {
          const selectableProducts = products.map(product => {
            return {label: product.name, value: product};
          });
          setProductsOptions(selectableProducts);
        })
        .finally(hideSpinner);
    }
  },[]);

  useEffect(()=>{
    setWideDisplay(wideOptions.includes(responses[currentQuestion].questionType));
  }, [currentQuestion]);

  const updateResponse = () => {
    setResponses([...responses]);
  };

  const canCompleteActivity = (currentQuestion === totalQuestions - 1) && responses.every(response =>response.isFulfilled());
  const response = responses[currentQuestion];
  return (
    <OrdoSpinner showSpinner={spinnerVisibility && (response.questionType === ActivityQuestionType.ProductDropdown)}>
      <div className='activity-complete-wizard'>
        <div className='top-section'>
          <div className='activity-details'>
            <span className='title'> {template.name}</span>
            {activity.date && <span className='subtitle'> {`${OrdoDate.from(activity.date).format(DateFormatTemplate.DAY_MONTH_AND_DATE)}${activity.time ? `, ${OrdoDate.from(activity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : ''}`}</span>}
          </div>
          <div className='button-side'>
            <OrdoButton disabled={!canCompleteActivity}
              text='complete activity'
              category='primary'
              onClick={()=>onSubmit(responses)}
              dataTestId='complete-activity-button'
            />
            <span className='required-note'>* indicates a required field</span>
          </div>

        </div>
        <div className='question-display' id={`${response.index}`}>
          <span className='response-count'>Response {currentQuestion+1} of {totalQuestions} </span>
          <span className={`question-title ${response.isRequired ? 'required' : ''}` }>
            {response.questionTitle}
          </span>
          {renderResponseInput(response, updateResponse, productOptions)}
        </div>
        <div className='bottom-section'>
          <OrdoPagination currentPage={currentQuestion + 1} totalOfPages={totalQuestions} onPageChange={((questionIndex: number) => setCurrentQuestion(questionIndex-1))}/>
          <OrdoButton disabled={false} text='cancel' category='cancel' onClick={()=>onCancel()} dataTestId=''/>
        </div>
      </div>
    </OrdoSpinner>
  );
};