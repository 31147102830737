import {User} from '../../models/User';
import {ORGANIZATION_STATUS} from '../../models/Organization';
import { Brand } from '../../models/Brand';
import {ConsumerUnitSize, Genetics} from '../../models/Product';
import {OrdoLicenseType} from '../../models/OrganizationLicense';
import {DiscountType, LineItemType, OrderStatus, SerializableLineItem} from '../../models/order-entry/Order';
import {Money, SerializableMoney} from '../../models/Money';
import {SerializableOrderSummary} from '../../models/order-entry/Summary';
import {ActualMemberInput} from '../../models/member/ActualMember';
import {NotMemberInput} from '../../models/member/NotMember';
import {Role} from '../../models/Roles';
import {SubscriptionStatus, SubscriptionType} from '../../models/Subscription';
import {AccountActivityType} from '../../models/AccountActivity';
import { MappingSourceName } from './request-types';
import {AccountPriority} from '../../pages/sales-activity/salesActivityTypes';
import {ActivityQuestionType} from '../../models/sales-activity/ActivityQuestion';

export type UserResponse = {
  email: string;
  id: string;
  lastName: string;
  phone: string;
  firstName: string;
  currentOrganizationId?: string | null;
}

export type UserSessionResponse = {
  organizations: OrganizationResponse[];
  pendingInvitations: PendingInvitationResponse[];
  user: UserResponse,
  role: Role,
  currentOrganization: OrganizationResponse,
  emailVerified: boolean, // TODO: GF - add this field to backend response.
}

export type UserExistsResponse = {
  exists: boolean,
  email: string,
}

export enum LicenseSource {
  Unknown = 'UNKNOWN',
  BCC = 'BCC',
  CDFA = 'CDFA',
  CDPH = 'CDPH'
}

export enum LicenseStatus {
  Unknown,
  Active,
  Inactive,
  Cancelled,
}

export enum OrganizationLicenseStatus {
  Unknown,
  Pending,
  Verified
}

export type License = {
  id: string
  type: string
  status: LicenseStatus
  legalName: string
  email: string
  phone: string
  expiration: Date
  source: LicenseSource
  dbaName: string
  externalLicenseId: string
  ordoType: OrdoLicenseType
  issuedAt: Date
}

export type OrganizationLicense = {
  license: License
  status: OrganizationLicenseStatus
}

export type OrganizationLicensesResponse = {
  licenses: OrganizationLicenseResponse[]
}

export type OrganizationLicenseResponse = {
  id: string,
  license: LicenseResponse
  status: string,
  streetAddressLine1: string,
  streetAddressLine2: string,
  city: string,
  state: string,
  zipCode: string,
  createdAt: Date,
}

export type LicenseResponse = {
  id: string
  type: string
  status: LicenseStatus
  legalName: string
  email: string
  phone: string
  expiration: Date
  source: LicenseSource
  dbaName: string
  externalLicenseId: string
  ordoType: number
}

export type AccountPriorityResponse = 1 | 2 | 3;
export type AccountStatusResponse = 0 | 1 | 2;

export type AccountResponse = {
  id: string;
  name: string;
  organizationId: string;
  locations: AccountLocationResponse[];
  allocations: AllocationResponse[];
  assignments: RepAssignmentResponse[];
  priority: AccountPriorityResponse;
  stalePeriod: number;
  value: number,
  conversion: number,
  contacts: ContactResponse[],
  notes: NoteResponse[],
  lastOrderDate?: Date,
  activities: ActivityResponse[],
  active: boolean,
  orderEntryEnabled: boolean,
  status: AccountStatusResponse
}

export type AccountWithContactsResponse = {
  accountId: string,
  name: string,
  contacts: ContactResponse[],
  assignments: UserResponse[]
}

export type ContactWithAccountNameResponse = {
  contact: ContactResponse,
  accountName: string
}

export type AccountLocationResponse = {
  location: LocationResponse,
  accountId: string,
  locationId: string,
  account?: AccountResponse,
  organizationId: string,
  county: {name: string, id: string},
  streetAddressLine1?: string,
  streetAddressLine2?: string,
  city?: string,
  state: string,
  zipCode: string,
}

export type AccountLocationsResponse = {
  accountLocations: AccountLocationResponse[],
}

export type AccountsResponse = {
  accounts: AccountResponse[]
}

export type AccountActivityResponse = {
    accountActivity: (ActivityResponse | ExternalEmailActivityResponse | NoteResponse | AccountRepAssignmentResponse | OrderWithCurrentVersionResponse),
    accountActivityType: AccountActivityType,
    sortingDate: Date
  }


export type BrandResponse = {
  brands: Brand[];
}

export type ProductWithAvailabilityResponse = {
  product: ProductResponse,
  availability: number,
  tierId?: string,
  unitPrice: number
}

export type BatchResponse = {
  id: string,
  productId: string,
  batchId: string,
  quantityOfUnits: number,
  createdAt: Date,
  advertisedTHC: number,
  coaDocumentURL?: string,
  advertisedCBD?: number,
  distributorOrganizationLicenseId: string

  packagedDate?: Date
}

export type ProductResponse = {
  id: string,
  name: string,
  brand: Brand,
  category: { id: string, name: string },
  batches: BatchResponse[],
  organizationId: string,
  imageUrl: string,
  price: number,
  unitsPerCase: number,
  unitSaleOnly: boolean,
  caseSaleOnly: boolean,
  size?: ConsumerUnitSize,
  genetics?: Genetics,
  advertisedTHC?: number,
  advertisedCBD?: number,
  preRolls?: number,
  customerSKU?: string,
  deletedAt?: Date
}

export type RepAssignmentResponse = {
  accountsIds: string[],
  disabledBrandIds: string[],
  userId: string,
  user: User,
  orgId: string,
}

export type AccountRepAssignmentResponse = {
  accountId: string,
  userId: string,
  user: UserResponse,
  createdAt: Date,
  role: Role
}

export type AllocationResponse = {
  productId: string,
  orgId: string,
  quantity: number,
  assignedToAccount: boolean,
  assignedToSalesRep: boolean,
  distributorOrganizationLicenseId: string,
  assignedTo: AccountResponse | User,
  id?: string,
}

export type ContactResponse = {
  id: string,
  name: string,
  email: string,
  organizationId: string,
  accountId: string,
  title?: string,
  phone: string,
}

export type OrderVersionLineItemResponse = {
  id: string
  productSnapshot: string
  productId: string
  product: ProductResponse
  quantity: number
  price: SerializableMoney
  discountPercentage: number
  discountCurrencyAmount: SerializableMoney
  discountType: DiscountType
  salesTax: SerializableMoney
  exciseTax: SerializableMoney
  type: LineItemType
  notes: string,
}

type OrderVersionBatchResponse = {
  id: string
  batchId: string
  quantity: number
}

export enum OrderStatusResponse {
  Unknown = 'unknown',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Confirmed = 'awaiting delivery',
  Delivered = 'delivered',
  Paid = 'paid',
}

export type OrderVersionResponse = {
  id: string
  effective: Date
  items: OrderVersionLineItemResponse[];
  batches: OrderVersionBatchResponse[];
  orderNotes: string
  status: OrderStatusResponse
  deliveryDay: Date
  discountType: DiscountType
  discountPercentage: number
  discountCurrencyAmount: SerializableMoney,
  summary: SerializableOrderSummary,
  invoiceUrl: string,
  termPeriod?: number,
  location: AccountLocationResponse,
  account: AccountResponse,
  contact: ContactResponse,
  distributor: OrganizationLicenseResponse,
  assignedSalesRep: UserResponse
}

export type OrderPaymentResponse = {
  id: string,
  orderId: string,
  amountPaid: SerializableMoney,
  amountOwedBeforePayment: SerializableMoney,
  amountOwedAfterPayment: SerializableMoney,
  createdAt: Date,
  systemPayment: boolean,
  userId?: string
}

export type PendingPaymentOrderResponse = {
  orderId: string,
  orderNumber: string,
  amountOwed: SerializableMoney,
  invoiceURL: string,
}

export type LocationResponse = {
  id: string;
  name: string;
  type: string;
  county: {name: string, id: string},
  license: License;
  streetAddressLine1: string;
  streetAddressLine2: string;
  city: string;
  state: string;
  zipCode: string;
}

export type OrderModelResponse = {
  id: string
  orgId: string
  createdBy: { user: UserResponse }
  versions: OrderVersionResponse[],
  orderNumber: string,
  createdAt: Date,
  isTracked: boolean,
  lastVersion: OrderVersionResponse
}

export type OrderWithCurrentVersionResponse = {
  order: { model: OrderModelResponse}
  lastVersion: OrderVersionResponse
}

export type OrderResponse = {
  model: OrderModelResponse,
  lastVersion: OrderVersionResponse
}

export type LineItemWithTaxResponse = SerializableLineItem & {
  exciseTax: SerializableMoney
  salesTax: SerializableMoney
}

export type OrderSummaryResponse = {
  lineItemsWithTaxes: LineItemWithTaxResponse[]
  summary: SerializableOrderSummary
}

export type SubscriptionResponse = {
  id: string,
  externalSubscriptionId: string,
  externalCustomerId: string,
  status: SubscriptionStatus,
  type: SubscriptionType,
  subscriptionEmail: string,
  subscriptionUserId: string,
}

export type ExternalApiConfigResponse = {
  id: string,
  configSourceName: MappingSourceName,
}

export type OrganizationResponse = BrandResponse & OrganizationLicensesResponse & {
  id: string,
  name: string,
  size: string,
  state: string,
  status: ORGANIZATION_STATUS,
  members: ActualMemberInput[],
  pendingInvitations: NotMemberInput[],
  owner: UserResponse,
  subscription: SubscriptionResponse,
  externalApiConfigs: ExternalApiConfigResponse[],
}

export type PendingInvitationResponse = {
   organizationId: string
   organizationName: string
   organizationOwnerName: string
   organizationOwnerEmail: string
   invitationTokenId: string
}

export type ProfileResponse = {
  user: UserResponse
  role: Role
  emailVerified: boolean
  organizations: OrganizationResponse[]
  currentOrganization: OrganizationResponse
  pendingInvitations: PendingInvitationResponse[]
}

export type PipelineDataResponse = {
  nonQualifiedAccounts: AccountResponse[],
  prospectAccounts: AccountResponse[],
  verifiedPurchasers: AccountResponse[],
  stalePurchasers: AccountResponse[],
  accounts: AccountResponse[],
  lostPurchasers: AccountResponse[],
  pendingOrdersCards?: OrderWithCurrentVersionResponse[],
  awaitingDeliveryCards?: OrderWithCurrentVersionResponse[],
  collectPaymentCards: OrderWithCurrentVersionResponse[],
  assignments: RepAssignmentResponse[],
}

export type NoteResponse = {
  id: string,
  subject: string,
  description: string,
  lastEdited: Date,
  createdBy: User,
  contacts: ContactResponse[]
}

export type ActivityResponse = {
  id: string
  subject: string
  description: string
  organizationId: string
  accountId: string
  date: Date,
  time?: Date,
  type: number
  createdBy?: User,
  contacts: ContactResponse[],
  activityTemplate: ActivityTemplateResponse,
  assignedSalesRep?: UserResponse,
  completed: boolean,
  responses: ActivityResponseResponse[]
}

export type EmailThreadActivityResponse = {
  id: string,
  date: Date,
  accountActivityId: string,
  emailId: string,
  threadId: string,
  to: string,
  from: string,
  body: string,
}

export type ExternalEmailActivityResponse = ActivityResponse & {emailThreadActivity: EmailThreadActivityResponse[]};

export type ActivityItemResponse = ActivityResponse & { accountName: string }

export type ActivityQuestionResponse = {
  index: number,
  isRequired: boolean,
  questionTitle: string,
  questionType: ActivityQuestionType,
  options?: string[]
}

export type ActivityResponseResponse = {
  index: number,
  isRequired: boolean,
  questionTitle: string,
  questionType: ActivityQuestionType,
  options?: string[],
  body?: any // note(dani): I wanted to do an union type here with all the options but the compiler does not like that
}

export type ActivityTemplateResponse = {
  id: string,
  name: string,
  iconName: string,
  questions: ActivityQuestionResponse[]
}

export type ActivityTemplateWithCountResponse = {
  activityTemplate: ActivityTemplateResponse,
  created: number,
  completed: number
}

export type SyncInventoryResponse = {
  summary: {
    productsFound: number
    totalQuantity: number
    unlinkedProducts: number
    outOfStock: number
    errors: string[]
  }
}


export type TimelineOrderResponse =  {
  orgId: string,
  id: string,
  orderNumber: string
  createdAt: Date
  isTracked: boolean
  effective: Date
  createdBy: string,
  orderNotes: string
  status: OrderStatus
  deliveryDay: Date
  discountType: DiscountType
  discountPercentage: number
  discountCurrencyAmount: Money
  invoiceURL: string
  currentVersionItems: OrderVersionLineItemResponse[]
  summary: SerializableOrderSummary
  location: LocationResponse
  contact?: ContactResponse
  distributor?: LicenseResponse
};

export type AccountTimelineResponse = {
  contacts: ContactResponse[];
  accountId: string,
  orderEntryEnabled: boolean,
  name: string,
  assignments: RepAssignmentResponse[]
  notes: NoteResponse[]
  activities: (ActivityResponse | ExternalEmailActivityResponse)[]
  orders: TimelineOrderResponse[]
};

export type ProductMappingsResponse = {
  mappings: ProductMappingResponse[]
};

export type ProductMappingResponse = {
  externalLicenseId: string // e.g. license number
  externalId: string // e.g. Metrc Item ID
  productId: string
  source: MappingSourceName
};


export enum MetrcQuantityType {
  COUNT_BASED = 'CountBased',
  WEIGHT_BASED = 'WeightBased',
  VOLUME_BASED = 'VolumeBased'
}

export type MetrcItemsResponse = {
  items: MetrcItemResponse[]
};

export type MetrcItemResponse = {
  Id: number
  QuantityType: MetrcQuantityType
  Name: string
  ProductCategoryName: string
  UnitWeight: number
  UnitWeightUnitOfMeasureName: string
};

export type ProductSKUTierResponse = {
  id: string,
  customerSKU: string,
  unitPrice: number
}
export type GroupPriceTierResponse = {
  id: string,
  unitPrice: number
  categoryId?: string,
  consumerUnitSize?: ConsumerUnitSize,
  genetics?: Genetics,
  brandId?: string,
}

export type TierResponse = {
  id: string,
  name: string,
  organizationId: string,
  productSKUTiers: ProductSKUTierResponse[],
  groupPriceTiers: GroupPriceTierResponse[],
  accountsIds: string[],
  allAccounts: boolean,
  priorities: AccountPriority[],
}
