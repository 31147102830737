import {ActivityTypeName} from './ActivityTypeName';
import {User} from '../User';
import Contact from '../order-entry/Contact';
import {ActivityQuestion} from './ActivityQuestion';
import { ActivityCompletionResponse } from '../activity/responses/ActivityCompletionResponse';
import {DateFormatTemplate, OrdoDate} from '../OrdoDate';

export type Activity = {
  assignedSalesRep: User | null
  id: string
  description?: string
  organizationId: string
  accountId: string
  date: Date
  time?: Date
  type: ActivityTypeName
  createdBy?: User;
  contacts: Contact[],
  activityTemplate: ActivityTemplate,
  completed: boolean,
  responses?: ActivityCompletionResponse[]
}

export type EmailThreadActivity = {
  id: string,
  date: Date,
  accountActivityId: string,
  emailId: string,
  threadId: string,
  to: string,
  from: string,
  body: string,
}

export type ExternalEmailActivity = Activity & {emailThreadActivity: EmailThreadActivity[]};

export type ActivityItem = Activity & {accountName: string}

export function activityAssignedTo(activity: Activity) {
  return activity.assignedSalesRep ? `${activity.assignedSalesRep.firstName} ${activity.assignedSalesRep.lastName}` : '';
}

export function activityDisplayDate(activity: Activity, dateFormat: DateFormatTemplate = DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY) {
  const date = OrdoDate.from(activity.date).format(dateFormat);
  const time = activity.time ? `, ${OrdoDate.from(activity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : '';
  return `${date}${time}`;
}

export class ActivityTemplate {
  public id: string;
  public name: string;
  public readonly questions: ActivityQuestion[];
  public readonly iconName: string;

  constructor(id: string, name: string, questions: ActivityQuestion[], iconName: string) {
    this.id = id;
    this.name = name;
    this.questions = questions;
    this.iconName = iconName;
  }
}

export type ActivityTemplateWithCount = {
  activityTemplate: ActivityTemplate,
  created: number,
  completed: number
}
