import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {sortBy} from 'lodash';
import {v4} from 'uuid';
import OrdoButton from '../../../components/common/OrdoButton';
import '../../../../scss/ordo/activitiesPage/activityManager/activity-template-creation.scss';
import {ActivityTemplateQuestion, SortingDirection} from './ActivityTemplateQuestion';
import {
  ActivityQuestion,
  ActivityQuestionType,
  initialQuestion
} from '../../../../models/sales-activity/ActivityQuestion';
import {useAPI} from '../../../../context/OrdoApiContext';
import useOrdoToasts from '../../../../hooks/useOrdoToasts';
import {ActivityTemplate, ActivityTemplateWithCount} from '../../../../models/sales-activity/Activity';
import OrdoSpinner from '../../../components/OrdoSpinner';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import {iconsOptions} from './iconsOptions';

type ActivityTemplateCreationProps = {
  onCancel: () => void,
  organizationId: string,
  onCreate: (activityTemplate: ActivityTemplateWithCount) => void,
  activityTemplateToEdit?: ActivityTemplate,
  onEdit: (activityTemplate: ActivityTemplateWithCount) => void
}

export const ActivityTemplateCreation = ({onCancel, organizationId, onCreate, activityTemplateToEdit, onEdit} : ActivityTemplateCreationProps) => {
  const [activityTemplateName, setActivityTemplateName] = useState(activityTemplateToEdit?.name || 'Custom Activity');
  const [questions, setQuestions] = useState<ActivityQuestion[]>(activityTemplateToEdit?.questions || initialQuestion());
  const api = useAPI();
  const {successToast, errorToast} = useOrdoToasts();
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const [selectedIconName, setSelectedIconName] = useState<string>(activityTemplateToEdit?.iconName || 'users');

  const updateQuestion = (questionIndex: number, updatedQuestion: ActivityQuestion) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex] = updatedQuestion;
    setQuestions(updatedQuestions);
  };

  const cloneQuestion = (question: ActivityQuestion) => {
    const newIndex = questions.length;
    const newQuestion = {id: v4(), index: newIndex, isRequired: question.isRequired, questionTitle: question.questionTitle, questionType: question.questionType, options: question.options};
    const updatedQuestions = questions.concat(newQuestion);
    setQuestions(updatedQuestions);
  };

  const deleteQuestion = (questionIndex: number) => {
    const updatedQuestions = questions.filter(q => q.index !== questionIndex);
    const updatedQuestionsWithUpdatedIndex = updatedQuestions.map((q, index) => {
      return {id: q.id, index: index, isRequired: q.isRequired, questionTitle: q.questionTitle, questionType: q.questionType};
    });
    setQuestions(updatedQuestionsWithUpdatedIndex);
  };


  const submitActivityTemplate = () => {
    showSpinner();
    if(activityTemplateToEdit) {
      api.updateActivityTemplate(organizationId, activityTemplateToEdit.id, {name: activityTemplateName, questions: questions, iconName: selectedIconName})
        .then((updatedActivityTemplate: ActivityTemplateWithCount) => {
          onEdit({activityTemplate: updatedActivityTemplate.activityTemplate, created: updatedActivityTemplate.created, completed: updatedActivityTemplate.completed});
          successToast('Activity updated');
        })
        .catch(() => errorToast('Could not update activity'))
        .finally(() => {
          hideSpinner();
          onCancel();
        });

    } else {
      api.createActivityTemplate(organizationId, activityTemplateName, questions, selectedIconName)
        .then((createdActivityType: ActivityTemplate) => {
          onCreate({activityTemplate: createdActivityType, created: 0, completed: 0});
          successToast('Activity created');
        })
        .catch(() => errorToast('Could not create activity'))
        .finally(() => {
          hideSpinner();
          onCancel();
        });
    }
  };

  const addNewQuestion = () => {
    const newQuestionIndex = questions.length;
    const newQuestion = {id: v4(), index: newQuestionIndex, isRequired: false, questionTitle: `Question ${newQuestionIndex + 1}`, questionType: ActivityQuestionType.NoTypeSelectedYet};
    const newQuestions = questions.concat(newQuestion);
    setQuestions(newQuestions);
  };

  const canSave = () => questions.every(question => question.questionType !== ActivityQuestionType.NoTypeSelectedYet);

  const moveQuestion = (direction: SortingDirection, index: number) => {
    let questionWithUpdatedIndexes: ActivityQuestion[];
    let updatedQuestions = [...questions];

    if(direction === SortingDirection.UP && index !== 0) {
      const previousElement = questions[index - 1];
      const currentElement = questions[index];
      updatedQuestions = [...questions];
      updatedQuestions[index] = previousElement;
      updatedQuestions[index - 1] = currentElement;
    }
    if(direction === SortingDirection.DOWN && index !== questions.length - 1)  {
      const nextElement = questions[index + 1];
      const currentElement = questions[index];
      updatedQuestions = [...questions];
      updatedQuestions[index] = nextElement;
      updatedQuestions[index + 1] = currentElement;
    }

    // eslint-disable-next-line prefer-const
    questionWithUpdatedIndexes = sortBy(updatedQuestions.map((q ,i) => {
      return {...q, index: i};
    }), ['index']);
    setQuestions(questionWithUpdatedIndexes);
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='activity-template-creation-container'>
        <div className='header'>
          <div className='title'>
            <input className="activity-template-name" value={activityTemplateName} key={organizationId}
              onChange={(event) => setActivityTemplateName(event.target.value)}
            />
          </div>
          <div className='buttons'>
            <OrdoButton disabled={!canSave()} text='save' category='primary' onClick={submitActivityTemplate} dataTestId=''/>
            <OrdoButton disabled={false} text='cancel' category='cancel' onClick={onCancel} dataTestId=''/>
          </div>
        </div>
        <div className='icon-selection'>
          <span className='title'>select an icon</span>
          <div className='icons'>
            {
              Object.entries(iconsOptions).map((k) => {
                return (<div key={k[0]} className={`icon ${selectedIconName === k[0] ? 'selected' : ''}`} onClick={() => setSelectedIconName(k[0])} role='presentation'>
                  <FontAwesomeIcon icon={k[1]} size='1x'/>
                </div>);
              })
            }
          </div>
        </div>
        <div className='questions'>
          {questions.map((activityQuestion: ActivityQuestion) => <ActivityTemplateQuestion
            key={activityQuestion.id}
            index={activityQuestion.index} question={activityQuestion}
            onQuestionUpdate={updateQuestion}
            onCloneQuestion={cloneQuestion}
            onMovingQuestion={moveQuestion}
            onDeleteQuestion={deleteQuestion}/>)}
        </div>
        <div className='add-activity-button'>
          <OrdoButton disabled={questions.length === 10} text='+ add a question' category='secondary' onClick={addNewQuestion} dataTestId='' style={{width: '100%',
            height: '4em',
            border: '1px solid #C0CCDA',
            borderRadius: '1em !important'}}/>
        </div>
      </div>
    </OrdoSpinner>);
};