import React, {CSSProperties} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPercentage} from '@fortawesome/free-solid-svg-icons';
import '../../../../scss/ordo/ordo-number-input.scss';
import OrdoMaskedInput from './OrdoMaskedInput';
import {withDecimalPlaces} from '../../../../utils/string';

export const PercentageIcon = () => <FontAwesomeIcon size="lg" icon={faPercentage} className='percentage-icon'/>;

export type OrdoPercentageInputProps = {
  value: number,
  onChange: (newAmount: number) => void,
  onBlur: Function,
  label?: string,
  min?: number,
  max?: number,
  step?: number,
  invalidMessage?: string,
  inputKey?: string,
  style?: CSSProperties,
};

const OrdoPercentageInput = ({
  label='',
  value,
  onChange,
  onBlur,
  step=1,
  min,
  max,
  invalidMessage='',
  inputKey,
  style={}
}: OrdoPercentageInputProps) => {
  return <OrdoMaskedInput
    label={label}
    value={value}
    onChange={
      (event) => {
        const newAmount: number = parseFloat(event.target.value);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(newAmount)) onChange(withDecimalPlaces(newAmount));
      }
    }
    onBlur={onBlur}
    customIcon={<PercentageIcon/>}
    step={step}
    min={min}
    max={max}
    invalidMessage={invalidMessage}
    inputKey={inputKey}
    style={style}
  />;
};

export default OrdoPercentageInput;
