import {OrdoFile} from './OrdoFile';

export type BatchInput = {
  id: string,
  productId: string,
  batchId: string,
  quantityOfUnits: number,
  coa?: OrdoFile,
  advertisedTHC: number,
  advertisedCBD?: number,
  packagedDate?: Date
};

export default class Batch {
  constructor(public id: string,
              public productId: string,
              public batchId: string,
              public quantityOfUnits: number,
              public createdAt: Date,
              public advertisedTHC: number,
              public distributorOrganizationLicenseId: string,
              public coaDocumentURL?: string,
              public advertisedCBD?: number,
              public coa?: OrdoFile,
              public packagedDate?: Date) {
  }

  public static empty(productId: string, distributorId: string) {
    const quantity = '' as unknown as number; // this is for the input to not be initialize with 0
    const coaFile = undefined as unknown as OrdoFile;
    return new Batch('', productId, '', quantity, new Date(), 0, distributorId,undefined, undefined, coaFile, undefined);
  }

  public isValidBatchId(): boolean {
    return !!this.batchId;
  }

  public isValidNumberOfUnits(): boolean {
    return !!this.quantityOfUnits && this.quantityOfUnits > 0;
  }

  public isValidCoaFile(): boolean {
    return !!this.coa;
  }

  public isValidBatch(): boolean {
    return this.isValidBatchId() && this.isValidNumberOfUnits() && !!this.productId && !!this.distributorOrganizationLicenseId;
  }

  public get batchCoaFileName() {
    return this.coa? this.coa.name : '';
  }

  public get coaFile(): File | undefined {
    return this.coa ? this.coa!.raw : undefined;
  }

  public getAdvertisedTHC() {
    return this.advertisedTHC;
  }

  public updateAdvertisedTHC(newAdvertisedTHC: number) {
    this.advertisedTHC = newAdvertisedTHC;
  }

  public updateAdvertisedCBD(newAdvertisedCBD: number) {
    this.advertisedCBD = newAdvertisedCBD;
  }

  public updateQuantityOfUnits(newQuantity: number) {
    this.quantityOfUnits = newQuantity;
  }

  public updateDistributorOrganizationLicenseId(id: string) {
    this.distributorOrganizationLicenseId = id;
  }

  public isValidDistributorOrganizationLicenseId() {
    return !!this.distributorOrganizationLicenseId;
  }

  public removeCOAFile() {
    this.coa = undefined;
    this.coaDocumentURL = undefined;
  }
}
