import React from 'react';
import OrderInformationViewModel from '../../../application-models/order-history/OrderInformationViewModel';
import OrderLineItems from './OrderLineItems';
import OrderDeliverySummary from './OrderDeliverySummary';
import OrderPaymentSummary from './OrderPaymentSummary';
import {PRIMARY_COLOR} from '../../../constants';
import {AllOrderLineColumns} from './OrderLineItemsColumns';

const headerStyles = {
  backgroundColor: PRIMARY_COLOR,
  color: 'white',
  height: '2em',
  paddingTop: '0',
  paddingBottom: '0'
};

const summaryStyles = {
  ...headerStyles,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

type OrderExpandedDetailsProps = {
  orderInformationViewModel: OrderInformationViewModel;
  onUpdateOrderStatus: Function
  onUpdateOrderDisplayStatus: Function
}

const NonTrackedOrderExpandedDetails = ({orderInformationViewModel, onUpdateOrderStatus, onUpdateOrderDisplayStatus} : OrderExpandedDetailsProps) => {
  return <div className="order-history-order-item-container__details">
    <div className="order-history-order-item-container__details__line_items">
      <OrderLineItems order={orderInformationViewModel.getOrder()} headerStyles={headerStyles} columns={AllOrderLineColumns}/>
    </div>
    <div className="order-history-order-item-container__details__summary">
      <div className="order-history-order-item-container__details__summary__delivery">
        <div className='delivery-summary__title' style={summaryStyles}>
          delivery summary
        </div>
        <OrderDeliverySummary showButtons order={orderInformationViewModel.getOrder()}
          onUpdateOrderStatus={onUpdateOrderStatus}
          onUpdateOrderDisplayStatus={onUpdateOrderDisplayStatus}/>
      </div>
      <div className="order-history-order-item-container__details__summary__payment">
        <div className='order-payment-summary__title' style={summaryStyles}>
          payment summary
        </div>
        <OrderPaymentSummary
          orderSummary={orderInformationViewModel.currentVersionSummary()}
          invoiceUrl={orderInformationViewModel.invoiceURL()}
        />
      </div>
    </div>
  </div>;
};

export default NonTrackedOrderExpandedDetails;
