import {Money} from '../Money';

export class OrderPayment {
  constructor(public readonly id: string,
              public readonly orderId: string,
              public amountPaid: Money,
              public amountOwedBeforePayment: Money,
              public amountOwedAfterPayment: Money,
              public createdAt: Date,
              public systemPayment: boolean,
              public userId?: string) {
  }
}