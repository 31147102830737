// TODO: convert this type to a class
import { AccountLocation } from './Account';
import {OrdoDate} from './OrdoDate';


export type Location = {
  id: string
  name: string
  type: string
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  zipCode: string
  licenseNumber: string
  issuedAt: OrdoDate,
  county: {name: string, id: string}
};

export function locationAddress(location: AccountLocation) {
  if(location.streetAddressLine1 || location.streetAddressLine2) {
    return `${location.streetAddressLine1 } ${location.streetAddressLine2 }, ${ location.city } ${ location.state } ${ location.zipCode}`;
  }
  return `${ location.city } ${ location.state } ${ location.zipCode}`;
}
