import React, {useContext, useEffect, useState} from 'react';
import {
  ActivityCompletionResponseDisplayProps,
  SingleValueActivityCompletionResponse
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import Product from '../../../../models/Product';
import {useAPI} from '../../../../context/OrdoApiContext';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../OrdoSpinner';

export const ProductDropdownResponseDisplay = ({
  response,
}: ActivityCompletionResponseDisplayProps) => {
  const productDropdownResponse = response as SingleValueActivityCompletionResponse;
  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const currentOrganization = userSession.currentOrganization();
  const {showSpinner, hideSpinner, spinnerVisibility} = useSpinnerToggle();
  const [product, setProduct] = useState<Product | null>(null);

  useEffect(() => {
    showSpinner();
    api.getProduct(currentOrganization!.id, productDropdownResponse.body.value)
      .then(p => setProduct(p))
      .finally(hideSpinner);
  }, []);

  return product ?
    <div className='product-response'>
      <div className="section info">
        <div className="title"> {product.name}</div>
        <div className="subtitle"> {product.customerSKU}</div>
      </div>
      <div className='section'>
        {product.brand.name}
      </div>
      <div className='section'>
        {product.category.name}
      </div>
      <div className='section'>
        {product.geneticsToString() || '-'}
      </div>
      <div className='section'>
        {product.unitsPerCaseToString() || '-'}
      </div>
    </div>
    : <OrdoSpinner showSpinner={spinnerVisibility}/>;
};