import React, {useState} from 'react';
import useModalToggle from '../../hooks/useModalToggle';
import '../../scss/ordo/accountsPage/edit-location-address.scss';
import OrdoInput from './common/inputs/OrdoInput';
import OrdoModal from './common/OrdoModal';
import {AccountLocation} from '../../models/Account';
import {useAPI} from '../../context/OrdoApiContext';
import OrdoSpinner from './OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../hooks/useOrdoToasts';


type EditLocationAddressModalProps = {
  isOpen: boolean,
  onClose: Function
  onAddressUpdated: Function
  accountLocation: AccountLocation
};
const EditLocationAddressModal = ({isOpen, accountLocation, onClose, onAddressUpdated} : EditLocationAddressModalProps) => {
  const api = useAPI();

  const {spinnerVisibility,showSpinner,hideSpinner} = useSpinnerToggle();
  const {successToast,errorToast} = useOrdoToasts();
  const [locationInfo, setLocationInfo] = useState<AccountLocation>(accountLocation);

  const onSubmit = ()=>{
    showSpinner();
    api.updateLocationAddress(accountLocation.organizationId, accountLocation.locationId, locationInfo)
      .then(()=> {
        successToast('Address updated successfully');
        onAddressUpdated(locationInfo);
        onClose();
      })
      .catch(()=> {
        errorToast('Could not update the location address');
      })
      .finally(hideSpinner);
  };

  const update = (newFields: any) => setLocationInfo({...locationInfo, ...newFields});

  return <OrdoSpinner showSpinner={spinnerVisibility}><OrdoModal
    show={isOpen} onClose={onClose}
    onSubmit={onSubmit} actionText="save"
    dataTestId="add-license"
  >
    <OrdoInput
      inputTitle="street address line 1"
      placeholder="street address line 1"
      onChange={(value) => update({streetAddressLine1: value})}
      inputName='addressLine1'
      onBlur={() => {}}
      value={locationInfo.streetAddressLine1 || ''}
      inputSize='medium'
    />
    <OrdoInput
      inputTitle="street address line 2"
      placeholder="street address line 2"
      onChange={(value) => update({streetAddressLine2: value})}
      inputName='addressLine2'
      onBlur={() => {}}
      value={locationInfo.streetAddressLine2 || ''}
      inputSize='medium'
    />
    <OrdoInput
      inputTitle="city"
      placeholder="city"
      onChange={(value) => update({city: value})}
      inputName='city'
      onBlur={() => {}}
      value={locationInfo.city || ''}
      inputSize='medium'
    />
    <OrdoInput
      placeholder="state"
      inputTitle="state"
      onChange={() => {}}
      inputName='state'
      onBlur={() => {}}
      readOnly
      value={locationInfo.state}
      inputSize='medium'
    />
    <OrdoInput
      placeholder="zip code"
      inputTitle="zip code"
      onChange={(value) => update({zipCode: value})}
      inputName='zipCode'
      onBlur={() => {}}
      value={locationInfo.zipCode}
      inputSize='medium'
    />
  </OrdoModal>
  </OrdoSpinner>;
};

export const EditLocationAddress = ({accountLocation, onAddressUpdated} : {accountLocation: AccountLocation, onAddressUpdated: (a: AccountLocation) => void}) => {
  const {closeModal, openModal, showModal} = useModalToggle();

  return <>
    <span className="edit-location-address" role='presentation' onClick={() => {
      openModal();
    }}>edit address</span>
    {showModal &&
      <EditLocationAddressModal
        accountLocation={accountLocation}
        isOpen={showModal}
        onAddressUpdated={onAddressUpdated}
        onClose={closeModal}
      />}
  </>;
};