import React, {FC, useState} from 'react';
import '../../../scss/ordo/pagetabs.scss';

export type OrdoPageTabButton = {
  text: string
  onClick: () => void
  dataTestId: string
}

export type OrdoPageTabsProps = {
  buttons: OrdoPageTabButton[]
}

type OrdoPageTabsState = {
  selectedIndex: number;
}

const renderButton = (button: OrdoPageTabButton, index: number, className: string, onClick: (state: OrdoPageTabsState) => void) => {
  return(
    <button
      key={button.text}
      type='button'
      data-testid={button.dataTestId}
      className={className}
      onClick={ () => {
        button.onClick();
        onClick({selectedIndex: index});
      }}
    >
      { button.text }
    </button>
  );
};

export const OrdoPageTabs: FC<OrdoPageTabsProps> = ({buttons}: OrdoPageTabsProps) => {
  const [tabState, setTabState] = useState<OrdoPageTabsState>({selectedIndex: 0});
  const classNames = 'ordo-page-tab-button btn btn-sm btn rounded-pill';
  const renderedButtons = buttons.map((button, index) => {
    let cn = classNames;
    if (index === tabState.selectedIndex) {
      cn += ' btn-primary current';
    } else {
      cn += ' btn-light ordo-cancel-button';
    }
    return renderButton(button, index, cn, setTabState);
  });
  return (
    <div className="ordo-page-tabs-container">
      { renderedButtons }
    </div>
  );
};
