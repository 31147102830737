import React, {useContext, useState} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import OrdoSpinner from '../components/OrdoSpinner';
import OrdoInput from '../components/common/inputs/OrdoInput';
import OrdoButton from '../components/common/OrdoButton';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {OrderEntryCartContext} from '../../context/OrderEntryCartContext';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import {useAuth} from '../../context/AuthContext';
import {AuthService} from '../../services/auth_service';
import {useAPI} from '../../context/OrdoApiContext';
import isValidEmail from '../../utils/string';
import {UserSessionContext} from '../../context/UserSessionContext';

const PasswordRecoveryPage = () => {
  const api = useAPI();
  const orderEntryCartContextData = useContext(OrderEntryCartContext);
  const userSessionContextData = useContext(UserSessionContext);
  const authService: AuthService = useAuth();
  const history = useHistory();
  const {errorToast} = useOrdoToasts();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  // NOTE: stolen from Logout.tsx
  const clearStorage = () => {
    orderEntryCartContextData.clearCart();
    userSessionContextData.clearSessionData();
  };

  // NOTE: stolen from Logout.tsx
  async function logout() {
    api.logout().then(() => clearStorage());
  }

  const returnToLogIn = () => history.push(ROUTE_NAMES.LOGIN);

  const isValidEmailAddress = () => email === '' || isValidEmail(email);

  const getEmailErrorMessage = () => isValidEmailAddress() ? '' : 'please provide a valid email';

  const sendRecoveryMail = async (event: any) => {
    event.preventDefault();
    showSpinner();
    authService.sendRecoveryMail(email)
      .then(() => {
        logout().then(() => setEmailSent(true));
      })
      .catch((error) => errorToast(error.message))
      .finally(hideSpinner);
  };

  return (<OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="row justify-content-center" style={{margin: 0}}>
      <div className="col-sm-8 col-lg-4">
        <div className="card shadow zindex-100 mb-0 login-card">
          {!emailSent && <div className="card-body px-md-5 py-5">
            <div className="mb-3">
              <h6 className="h3">Password recovery</h6>
              <p className="text-muted mb-0">We will send a recovery link to</p>
            </div>
            <span className="clearfix"/>
            <form>
              <div className="form-group">
                <OrdoInput inputType="email"
                  inputName="login-email-input"
                  value={email}
                  placeholder="type your email"
                  onChange={setEmail}
                  onBlur={() => {
                  }}
                  invalidMessage={getEmailErrorMessage()}
                  inputSize="large"/>
              </div>
              <div className="login-footer">
                <OrdoButton
                  additionalClassName="registration-button"
                  disabled={!isValidEmailAddress()}
                  text="send recovery link"
                  category="primary"
                  onClick={sendRecoveryMail}
                  dataTestId="login-button"/>
                <div>
                  <a href="#!" onClick={returnToLogIn} className="small font-weight-bold">return to log in</a>
                </div>
              </div>
            </form>
          </div>}
          {emailSent &&
              <div className="card-body px-md-5 py-5">
                <div className="mb-3">
                  <OrdoPageTitle centered title="Password recovery" buttons={[]}/>
                  <p className="text-center">We send you an email, please check your inbox</p>
                </div>
              </div>}
        </div>
      </div>
    </div>
  </OrdoSpinner>);
};

export default withRouter(PasswordRecoveryPage);
