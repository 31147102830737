import {API} from '../lib/api/ordoApi';
import FormError, {BatchFormFields} from '../errors/form_error';
import BusinessError from '../errors/domain_errors';
import Batch from '../models/Batch';
import {Organization} from '../models/Organization';
import Product from '../models/Product';
import {
  InformativeSelectableOption,
  toInformativeSelectableOption
} from '../pages/components/common/searchable-dropdown/InformativeOption';
import {OrganizationLicense} from '../models/OrganizationLicense';

export type AddBatchViewModelInput = {
  api: API
  batch: Batch
  error: FormError
  organization: Organization
  isValidNumberOfUnits: boolean
  isValidBatchId: boolean
  isValidCoa: boolean
  isValidDistributorOrganizationLicenseId: boolean
  creationInProgress: boolean
}

export default class AddBatchViewModel {
  private readonly COAFileURLCharactersToRemove = 122;
  private readonly api: API;
  private readonly error: FormError;
  private readonly organization: Organization;

  public batch: Batch;
  public isValidBatch: boolean;
  public isValidBatchId: boolean;
  public isValidCoa: boolean;
  public isValidNumberOfUnits: boolean;
  public isValidDistributorOrganizationLicenseId: boolean;
  private creationInProgress: boolean

  public static initialize(api: API, organization: Organization, product: Product, batch?: Batch): AddBatchViewModel {
    return new AddBatchViewModel({
      api: api,
      organization: organization,
      batch: this.initializeBatch(batch) || Batch.empty(product.id, organization.licenses.length > 0 ? organization.licenses[0].id : ''),
      error: FormError.withoutError(),
      isValidBatchId: true,
      isValidNumberOfUnits: true,
      isValidCoa: true,
      isValidDistributorOrganizationLicenseId: true,
      creationInProgress: false,
    });
  }

  private static initializeBatch(batch: Batch | undefined) {
    return batch ? new Batch(batch.id, batch.productId, batch.batchId, batch.quantityOfUnits, batch.createdAt, batch.advertisedTHC, batch.distributorOrganizationLicenseId, batch.coaDocumentURL, batch.advertisedCBD, batch.coa, batch.packagedDate) :
      undefined;
  }

  constructor(input: AddBatchViewModelInput) {
    this.api = input.api;
    this.organization = input.organization;
    this.error = input.error;
    this.batch = input.batch;
    this.isValidBatchId = input.isValidBatchId;
    this.isValidNumberOfUnits = input.isValidNumberOfUnits;
    this.isValidCoa = input.isValidCoa;
    this.isValidBatch = this.batch.isValidBatch();
    this.isValidDistributorOrganizationLicenseId = input.isValidDistributorOrganizationLicenseId;
    this.creationInProgress = input.creationInProgress;
  }

  public hasError() {
    return this.error.hasError();
  }

  public hasErrorFor(field: BatchFormFields) {
    return this.error.hasErrorForInput(field);
  }

  public errorMessageFor(field: BatchFormFields) {
    return this.error.errorMessage(field);
  }

  public errorMessage() {
    return this.error.errorMessage('unknown');
  }

  public async addBatch() {
    try {
      if(!this.isValidBatch) {
        this.validateBatchInformation();
      }
      await this.api.addBatch(this.organization, this.batch);
      return this;
    } catch (error) {
      let formError: FormError;
      if (error instanceof BusinessError) {
        formError = error.formError;
      } else {
        formError = FormError.unknown(error.message);
      }
      return new AddBatchViewModel({
        api: this.api,
        organization: this.organization,
        batch: this.batch,
        error: formError,
        isValidBatchId: this.isValidBatchId,
        isValidNumberOfUnits: this.isValidNumberOfUnits,
        isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
        isValidCoa: this.isValidCoa,
        creationInProgress: this.creationInProgress,
      });
    }
  }

  public async editBatch() : Promise<AddBatchViewModel> {
    if(!this.isValidBatch) {
      this.validateBatchInformation();
    }

    return this.api.editBatch(this.organization.id, this.batch)
      .then(() => {
        return new AddBatchViewModel({
          api: this.api,
          organization: this.organization,
          batch: this.batch,
          error: this.error,
          isValidBatchId: this.isValidBatchId,
          isValidNumberOfUnits: this.isValidNumberOfUnits,
          isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
          isValidCoa: this.isValidCoa,
          creationInProgress: this.creationInProgress,
        });
      })
      .catch((error) => {
        let formError: FormError;
        if (error instanceof BusinessError) {
          formError = error.formError;
        } else {
          formError = FormError.unknown(error.message);
        }
        return new AddBatchViewModel({
          api: this.api,
          organization: this.organization,
          batch: this.batch,
          error: formError,
          isValidBatchId: this.isValidBatchId,
          isValidNumberOfUnits: this.isValidNumberOfUnits,
          isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
          isValidCoa: this.isValidCoa,
          creationInProgress: this.creationInProgress,
        });
      });
  }

  private validateBatchInformation() {
    const isValidNumberOfUnits = this.batch.isValidNumberOfUnits();
    const isValidBatchId = this.batch.isValidBatchId();
    const isValidCoa = this.batch.isValidCoaFile();
    const isValidDistributorOrganizationLicenseId = this.batch.isValidDistributorOrganizationLicenseId();
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: isValidBatchId,
      isValidNumberOfUnits: isValidNumberOfUnits,
      isValidCoa: isValidCoa,
      isValidDistributorOrganizationLicenseId: isValidDistributorOrganizationLicenseId,
      creationInProgress: this.creationInProgress,
    });
  }

  public updateBatchId(batchId: string) {
    this.batch.batchId = batchId;
    this.error.removeErrorFor('batch-id');
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidCoa: this.isValidCoa,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      creationInProgress: this.creationInProgress,
    });
  }

  public updateBatchCoaFile(coaFile: any) {
    this.batch.coa = coaFile;
    this.error.removeErrorFor('coa');
    const isValidCoa = this.batch.isValidCoaFile();
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      isValidCoa: isValidCoa,
      creationInProgress: this.creationInProgress,
    });
  }

  public updateBatchQuantity(batchQuantity: number) {
    this.batch.quantityOfUnits = batchQuantity;
    this.error.removeErrorFor('number-of-units');
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      isValidCoa: this.isValidCoa,
      creationInProgress: this.creationInProgress,
    });
  }

  public validateBatchId() {
    const isValidBatchId = this.batch.isValidBatchId();
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      isValidCoa: this.isValidCoa,
      creationInProgress: this.creationInProgress,
    });
  }

  public validateNumberOfCases() {
    const isValidNumberOfUnits = this.batch.isValidNumberOfUnits();
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: isValidNumberOfUnits,
      isValidCoa: this.isValidCoa,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      creationInProgress: this.creationInProgress,
    });
  }

  public validateCoaFile() {
    const isValidCoa = this.batch.isValidCoaFile();
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidCoa: isValidCoa,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      creationInProgress: this.creationInProgress,
    });
  }

  public isValidNumberOfAdvertisedTHC() {
    return this.getAdvertisedTHC() >= 0;
  }

  private getAdvertisedTHC() {
    return this.batch.getAdvertisedTHC();
  }

  public setInProgress() {
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidCoa: this.isValidCoa,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      creationInProgress: true,
    });
  }

  public get batchCoaFileName() {
    return this.batch.batchCoaFileName || (this.batch.coaDocumentURL ? this.batch.coaDocumentURL.substring(this.COAFileURLCharactersToRemove) : '');
  }

  public get inProgress() {
    return this.creationInProgress;
  }

  public updateAdvertisedTHC(newAdvertisedTHC: number) {
    this.batch.updateAdvertisedTHC(newAdvertisedTHC);
    this.error.removeErrorFor('advertised-thc');
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidCoa: this.isValidCoa,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      creationInProgress: this.creationInProgress,
    });
  }

  updateAdvertisedCBD(newAdvertisedCBD: number) {
    this.batch.updateAdvertisedCBD(newAdvertisedCBD);
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidCoa: this.isValidCoa,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      creationInProgress: this.creationInProgress,
    });
  }

  getSelectableDistributorOrganizationLicenses() {
    const {licenses} = this.organization;
    return licenses.filter(license => license.id !== this.batch.distributorOrganizationLicenseId)
      .map(license => toInformativeSelectableOption(license.license.name, '', license.license.licenseNumber, license));
  }

  setDistributorOrganizationLicense(selectedOrganizationLicense: OrganizationLicense) {
    this.batch.updateDistributorOrganizationLicenseId(selectedOrganizationLicense.id);
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidCoa: this.isValidCoa,
      isValidDistributorOrganizationLicenseId: this.batch.isValidDistributorOrganizationLicenseId(),
      creationInProgress: this.creationInProgress,
    });
  }

  public getSelectedDistributorOrganizationLicense(): InformativeSelectableOption<OrganizationLicense> | null {
    if(this.batch.distributorOrganizationLicenseId) {
      const {licenses} = this.organization;
      const organizationLicense = licenses.find(l => l.id === this.batch.distributorOrganizationLicenseId);
      return organizationLicense ?
        toInformativeSelectableOption(
          organizationLicense.license.name,
          '',
          organizationLicense.license.licenseNumber,
          organizationLicense)
        : null;
    }
    return null;
  }

  public validateDistributorOrganizationLicenseId() {
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidDistributorOrganizationLicenseId: this.batch.isValidDistributorOrganizationLicenseId(),
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidCoa: this.isValidCoa,
      creationInProgress: this.creationInProgress,
    });
  }

  public hasMoreThanOneDistributor() : boolean {
    const {licenses} = this.organization;
    return licenses.length > 1;
  }

  public hasDistributors() : boolean{
    return this.organization.licenses.length > 0;
  }

  public removeCOAFile() {
    this.batch.removeCOAFile();
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidDistributorOrganizationLicenseId: this.batch.isValidDistributorOrganizationLicenseId(),
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidCoa: true,
      creationInProgress: this.creationInProgress,
    });
  }

  public batchCOAFileURL() {
    return this.batch.coaDocumentURL;
  }

  public updatePackagedDate(date: Date) {
    this.batch.packagedDate = date;

    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      isValidCoa: this.isValidCoa,
      creationInProgress: this.creationInProgress,
    });
  }

  getPackagedDate() {
    return this.batch.packagedDate;
  }

  removePackagedDate() {

    this.batch.packagedDate = undefined;
    return new AddBatchViewModel({
      api: this.api,
      organization: this.organization,
      batch: this.batch,
      error: this.error,
      isValidBatchId: this.isValidBatchId,
      isValidNumberOfUnits: this.isValidNumberOfUnits,
      isValidDistributorOrganizationLicenseId: this.isValidDistributorOrganizationLicenseId,
      isValidCoa: this.isValidCoa,
      creationInProgress: this.creationInProgress,
    });
  }
}

