import React, {PropsWithChildren, ReactNode, useContext} from 'react';
import {FeatureFlags} from '../lib/featureFlags';


const FeatureFlagsContext = React.createContext<FeatureFlags | null>(null);

export function useFeatureFlags(): FeatureFlags {
  const featureFlags = useContext(FeatureFlagsContext);
  if (!featureFlags) {
    throw new Error('Feature flags is null');
  }
  return featureFlags;
}

type FeatureFlagsProviderProps = {featureFlags: FeatureFlags} & PropsWithChildren<ReactNode>

const FeatureFlagsProvider = ({featureFlags, children}: FeatureFlagsProviderProps) => {
  return (<FeatureFlagsContext.Provider value={featureFlags}>
    {children}
  </FeatureFlagsContext.Provider>);
};

export default FeatureFlagsProvider;
