import React, {ReactNode} from 'react';
import OrdoModal from './OrdoModal';

export type ConfirmationModalProps = {
  show: boolean,
  onClose: Function,
  onSubmit: Function,
  actionText?: string,
  confirmationText: string,
  errorMessage?: ReactNode,
  disableCTA?: boolean
}

const ConfirmationModal = ({ show, onClose, onSubmit, confirmationText, actionText='confirm', errorMessage, disableCTA = false }: ConfirmationModalProps) => {

  return <OrdoModal
    size='sm'
    show={show}
    title='confirmation'
    onClose={onClose}
    onSubmit={onSubmit}
    actionText={actionText}
    disableCTA={disableCTA}
  >
    {errorMessage}
    <p className='text-dark'>
      {confirmationText}
    </p>
  </OrdoModal>;
};

export default ConfirmationModal;
