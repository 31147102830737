
import { datadogRum } from '@datadog/browser-rum';
import {appConfig} from '../config';

/**
 * DatadogRum manages our Datadog RUM configuration and ensures it is only initialized once.
 */
export class DatadogRum {
  private static initialized: boolean

  public static init() {
    if (!this.initialized) {
      datadogRum.init({
        applicationId: appConfig.datadog.appId,
        clientToken: appConfig.datadog.clientToken,
        site: 'datadoghq.com',
        service:'web',
        sampleRate: 100,
        trackInteractions: true,
        env: appConfig.env
      });
      this.initialized = true;
    }
    return datadogRum;
  }
};