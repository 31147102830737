import React from 'react';
import Select from 'react-select';
import {BORDER_RADIUS, CARD_BORDER_COLOR, PLACEHOLDER, PRIMARY_COLOR} from '../../../../constants';
import '../../../../scss/ordo/ordo-searchable-dropdown.scss';
import {ButtonOption} from './ButtonOption';

export type CustomFilterOption = (candidate: any, input: string, options: any[]) => boolean

export type CustomOptionComponentProps = {
  innerRef: any,
  innerProps: any,
  data: any
}

export type CustomOptionComponent = ({innerProps, data}: CustomOptionComponentProps) => JSX.Element

type OrdoSearchableDropdownProps = {
  options: any[],
  selectedOption: any,
  onChangeSelectedOption: (value: any, action: any) => void,
  placeholder?: string,
  onAddItem?: Function,
  addItemText?: string,
  label?: string,
  dataTestId?: string,
  selectedOptionCustomComponent?: CustomOptionComponent,
  menuOptionCustomComponent?: CustomOptionComponent,
  customFilterOption?: CustomFilterOption,
  singleRowHeight?: boolean,
  addedStyles?: any,
  preSelectedValue?: any,
  canEditValue?: boolean,
  onBlur?: Function,
  selectMultipleOptionsAtOnce?: boolean
}

const OrdoSearchableDropdown = ({
  options,
  selectedOption,
  onChangeSelectedOption,
  placeholder,
  onAddItem,
  addItemText,
  dataTestId,
  selectedOptionCustomComponent,
  menuOptionCustomComponent,
  customFilterOption,
  singleRowHeight=false,
  preSelectedValue=false,
  canEditValue=true,
  addedStyles= {},
  label,
  onBlur,
  selectMultipleOptionsAtOnce=false
}: OrdoSearchableDropdownProps) => {

  const customStyles = {
    container: (provided: any) => ({
      ...provided,
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      cursor: 'pointer',
      minHeight: `${singleRowHeight ? '1rem' : '3rem'}`,
      height: 'min-content',
      paddingLeft: '0.5rem',
      borderRadius: `${!state.menuIsOpen ? BORDER_RADIUS : `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`}`,
      border: `'1px solid ' ${CARD_BORDER_COLOR}`,
      boxShadow: `0 0 3px 1px ${CARD_BORDER_COLOR}`,
      borderColor: 'transparent',
      '&:hover': {
        borderColor: 'transparent',
        cursor: 'pointer',
      },
      ...(addedStyles.control || {})
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '0 0 1rem 1rem',
      backgroundColor: 'white',
      marginTop: 0,
      zIndex: 50,
      paddingBottom: '1em',
      ...(addedStyles.menu || {})
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: PLACEHOLDER
    }),
    valueContainer: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.valueContainer || {})
      };
    },
    indicatorsContainer: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.indicatorsContainer || {})
      };
    },
    indicatorContainer: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.indicatorsContainer || {})
      };
    },
    dropdownIndicator: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.indicatorContainer || {})
      };
    },
    input: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.input || {})
      };
    },
  };

  const getFilterOption = () => {
    return customFilterOption ?
      (candidate: any, input: string) => customFilterOption(candidate, input, options)
      : undefined;
  };

  const addButtonOptionToOptions = () => [...options, {addLastButtonOption: true, onAddItem: onAddItem, addItemText: addItemText}];

  const customButtonOption = (Component: CustomOptionComponent) =>
    ({innerRef, innerProps, data}: CustomOptionComponentProps) => {
      return data.addLastButtonOption ?
        <ButtonOption data={data}/> :
        <Component data={data} innerRef={innerRef} innerProps={innerProps}/>;
    };

  const getOption = () => onAddItem ? customButtonOption(menuOptionCustomComponent!) : menuOptionCustomComponent!;

  const getOptions = () => onAddItem ? addButtonOptionToOptions() : options;

  const getComponents = () => {
    let components = {};
    components = menuOptionCustomComponent ? {...components, Option: getOption()} : components;
    components = selectedOptionCustomComponent ? {...components, SingleValue: selectedOptionCustomComponent!} : components;
    return components;
  };

  const noFunction = () => {};

  return <div className="ordo-searchable-dropdown-container">
    {label && <div className="dropdown-label">
      {label}
    </div>}
    <Select
      dataTestId={dataTestId}
      styles={customStyles}
      theme={theme => ({
        ...theme,
        border: '1px solid #e9ecef',
        colors: {
          ...theme.colors,
          primary: PRIMARY_COLOR,
        },
      })}
      onBlur={onBlur ? () => onBlur() : noFunction}
      isDisabled={!canEditValue}
      defaultValue={preSelectedValue || selectedOption}
      options={getOptions()}
      onChange={onChangeSelectedOption}
      value={selectedOption || null}
      placeholder={placeholder}
      components={getComponents()}
      filterOption={getFilterOption()}
      closeMenuOnSelect={!selectMultipleOptionsAtOnce}
    />
  </div>;
};

export default OrdoSearchableDropdown;
