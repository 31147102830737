import React from 'react';
import '../../../../scss/ordo/inventoryPage/search-bar.scss';
import {MultiFiltersKeys} from '../../../../application-models/ProductFilterViewModel';

type FilterBoxProps = {
	title: string,
	typeOfFilter: string,
  filterValues: string[],
	selectedValues: string[],
	updateFilters: (selectedIndex: number, typeOfFilter: MultiFiltersKeys) => void,
}

const FilterBox = ({title, typeOfFilter, filterValues, updateFilters, selectedValues}: FilterBoxProps) => {
  const handleCheckFilter = (currentFilterValueIndex: number) => {
	  updateFilters(currentFilterValueIndex, typeOfFilter as MultiFiltersKeys);
  };

  const checkboxItems = filterValues.map((value, index) => {
    const id = `custom-check-${title}-${index}`;

	  const isSelected = (idx: number) => {
	    const val = filterValues[idx];
		  return selectedValues.includes(val);
	  };

    return (
      <div className="custom-control custom-checkbox" key={id}>
        <input
          type="checkbox"
          className="custom-control-input
          custom-control-input-green"
          id={id}
          onChange={() => handleCheckFilter(index)}
          checked={isSelected(index)}
        />
        <label className={`custom-control-label cursor-pointer ${!isSelected(index) ? 'unselected-text' : ''}`} htmlFor={id}>{value}</label>
      </div>);
  });

  return(
    <div className="filters">
      {title}
      <div className="filter-checkboxes">
	      {checkboxItems}
      </div>
    </div>);
};

export default FilterBox;
