export enum Resolution {
  DESKTOP,
  MOBILE,
}

// This widths are the same that Bootstrap uses for the Responsive breakpoints.
// From https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
const breakpoints = new Map<Resolution, number>([
  [Resolution.MOBILE , 926],
]);

export const getCurrentResolution: (viewPortResolution: number) => number = (viewPortResolution: number) => {
  if (viewPortResolution > breakpoints.get(Resolution.MOBILE)!) {
    return Resolution.DESKTOP;
  }
  return Resolution.MOBILE;
};

export const isMobileResolution = (resolutionKind: Resolution) => {
  return resolutionKind === Resolution.MOBILE;
};
