import {Payments, Card, Square} from '@square/web-sdk';
import {appConfig} from '../../lib/config';
import {PRIMARY_COLOR, WARNING_COLOR} from '../../constants';

export class OrdoPayments {
  private readonly locationId: string;
  private readonly applicationId: string;
  card: Card | null = null;

  constructor() {
    const {applicationId, locationId} = appConfig.squareConfig;
    this.applicationId = applicationId;
    this.locationId = locationId;
  }

  async cardToken() {
    const tokenResult = await this.card!.tokenize();
    if (tokenResult.status !== 'OK') {
      let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }
      throw new Error(errorMessage);
    }
    return tokenResult.token!;
  };

  private async initializeCard(payments: Payments, cardId: string) {
    const card = await payments.card({style: {
      '.input-container.is-focus': {
        borderColor: PRIMARY_COLOR,
      },
      '.input-container.is-error': {
        borderColor: WARNING_COLOR,
      },
      '.message-text.is-error': {
        color: WARNING_COLOR,
      },
      '.message-icon.is-error': {
        color: WARNING_COLOR,
      },
    }});

    await card.attach(`#${cardId}`);
    return card;
  }

  public async initialize(cardId: string) {
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }
    const square: Square = window.Square;
    const payments = square.payments(this.applicationId, this.locationId);

    try {
      if(!this.card) {
        this.card = await this.initializeCard(payments, cardId);
      }
    } catch (e) {
      throw Error('Initializing Card failed');
    }

    return this.card;
  };

  public reset() {
    this.card = null;
  }
}

export const OrdoPayment = new OrdoPayments();