import React from 'react';
import {useHistory} from 'react-router';
import {SalesActivityAccount} from './salesActivityTypes';
import '../../scss/ordo/salesActivity/account-details.scss';
import {AccountInfo} from './AccountInfo';
import { AccountPriorityButton } from './AccountPriorityButton';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {isMobileResolution, Resolution} from '../../context/resolutions';
import {useResolution} from '../../context/UseResolutions';

type AccountDetailsProps = {
  account: SalesActivityAccount,
  orgId: string,
  onPriorityChange: () => any,
  onStalePeriodChange: () => any,
  showValue: boolean
};

export const AccountHeaderDetails = ({account, orgId, onPriorityChange, onStalePeriodChange, showValue}: AccountDetailsProps) => {
  const history = useHistory();
  const resolution: Resolution = useResolution();
  const inMobileResolution = isMobileResolution(resolution);

  const goToAccountPage = () => history.push(ROUTE_NAMES.ACCOUNT_PAGE.replace(':accountId', account.id));

  return(
    <div className="account-header">
      <div className="account-header__account-personal-info">
        <div className="account-header__account-personal-info__name" onClick={inMobileResolution ? undefined : goToAccountPage} role="presentation"> {account.name}</div>
        <AccountInfo account={account} orgId={orgId} onInformationChange={onStalePeriodChange} showValue={showValue}/>
      </div>
      <AccountPriorityButton account={account} organizationId={orgId} onPriorityChange={onPriorityChange}/>
    </div>);
};
