import React, {PropsWithChildren, ReactNode, useContext} from 'react';
import '../../scss/ordo/verify-account-overlay.scss';
import '../../scss/purpose.scss';
import {useLocation} from 'react-router-dom';
import OrdoButton from './common/OrdoButton';
import {UserSessionContext, UserSessionContextData} from '../../context/UserSessionContext';
import {RoutesWithoutOverlay} from '../../routes/ROUTE_NAMES';
import {useAuth} from '../../context/AuthContext';
import useOrdoToasts from '../../hooks/useOrdoToasts';

type VerifyAccountOverlayProps = PropsWithChildren<ReactNode>;

const VerifyAccountOverlay = ({children}: VerifyAccountOverlayProps) => {
  const userSession: UserSessionContextData = useContext(UserSessionContext);
  const location = useLocation();
  const auth = useAuth();
  const currentLocation = location.pathname;
  const {errorToast, successToast} = useOrdoToasts();

  const sendVerificationEmail = async () => {
    const {email} = userSession.user();
    try {
      await auth.sendVerificationMail(email);
      successToast('Verification email sent, check your inbox');
    } catch (e) {
      errorToast('Could not send the verification mail send, try again later');
    }
  };

  const shouldShowOverlay = !RoutesWithoutOverlay.includes(currentLocation) && !userSession.userEmailIsVerified();

  const ShowOverlay = <>
    <div className="overlay-container">
      <div className="ordo-overlay">
        <div className="ordo-overlay__call-to-action">
          <h4>Verify your email to access your account</h4>
          <OrdoButton
            dataTestId="verify-account-button"
            disabled={false}
            text="resend verification email"
            category="primary"
            onClick={sendVerificationEmail}/>
        </div>
      </div>
    </div>
    <div className="overlay-content-container">
      {children}
    </div>
  </>;
  const HideOverlay = <>
    {children}
  </>;

  return (shouldShowOverlay ? ShowOverlay : HideOverlay);
};

export default VerifyAccountOverlay;
