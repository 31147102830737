import React, {FC, useContext, useEffect, useState} from 'react';
import {RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import CompanyRegistrationForm from './steps/CompanyRegistrationForm';
import {API} from '../../lib/api/ordoApi';
import RegistrationViewModel, {RegistrationStep} from '../../application-models/RegistrationViewModel';
import OrganizationRegistration from '../../models/organizationRegistration';
import UserRegistration from '../../models/userRegistration';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {UserSessionContext} from '../../context/UserSessionContext';
import {FormFields} from '../../errors/form_error';
import GeneralErrorMessage from '../components/common/GeneralErrorMessage';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import '../../scss/ordo/loginPage/login-page.scss';
import '../../scss/ordo/loginPage/registration.scss';
import SubscriptionRegistration from '../../models/subscriptionRegistration';
import SubscriptionRegistrationForm from './steps/SubscriptionForm';
import UserRegistrationForm from './steps/UserRegistrationForm';
import {OrdoPayment} from '../../models/payments/OrdoPayments';

const RegisterPage: FC<{ api: API } & RouteComponentProps>  = ({api}: { api: API }) => {
  const history = useHistory();
  const [viewModel, setViewModel] = useState(RegistrationViewModel.emptyRegistrationViewModel(api));
  const userSessionContextData = useContext(UserSessionContext);
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const redirectIfValid: (registrationViewModel: RegistrationViewModel) => void = (registrationViewModel: RegistrationViewModel) => {
    if (!registrationViewModel.hasError()) {
      const currentOrganizationId = registrationViewModel.registeredOrganization ? registrationViewModel.registeredOrganization.id : null;
      if (registrationViewModel.registeredUser) {
        userSessionContextData.update({user: {...registrationViewModel.registeredUser, currentOrganizationId: currentOrganizationId}, currentOrganization: registrationViewModel.registeredOrganization});
      }
      history.push(ROUTE_NAMES.PROFILE);
      OrdoPayment.reset();
    }
  };

  const checkPassword = () => {
    const newViewModel = viewModel.checkPasswords();
    setViewModel(newViewModel);
  };

  useEffect(() => {
    checkPassword();
  }, [viewModel.userRegistration.password, viewModel.userRegistration.passwordConfirmation]);

  const registerUser = async () => {
    showSpinner();
    const newViewModel = await viewModel.registerUser();
    hideSpinner();
    return newViewModel;
  };

  const doUserRegistration: () => Promise<void> = async () => {
    const registrationViewModel = await registerUser();
    setViewModel(registrationViewModel);
    redirectIfValid(registrationViewModel);
  };

  const registerUserAndCompany = async () => {
    showSpinner();
    const newViewModel = await viewModel.registerUserAndCompany();
    hideSpinner();
    return newViewModel;
  };

  const doUserAndCompanyRegistration: () => Promise<void> = async () => {
    const registrationViewModel = await registerUserAndCompany();
    setViewModel(registrationViewModel);
    redirectIfValid(registrationViewModel);
  };

  const doNextStep = () => {
    setViewModel(viewModel.nextStep());
  };

  const doStepBack = (companyRegistration: OrganizationRegistration) => {
    setViewModel(viewModel.stepBack(companyRegistration));
  };

  const updateUserRegistration = (fieldsToUpdate: UserRegistration) => {
    const userRegistration = new UserRegistration({
      ...viewModel.userRegistration,
      ...fieldsToUpdate
    });
    const newViewModel = viewModel.updateUserRegistration(userRegistration);
    setViewModel(newViewModel);
  };

  const updatePlanRegistration = (updatedRegistration: SubscriptionRegistration) => {
    const newViewModel = viewModel.updateSubscriptionRegistration(updatedRegistration);
    setViewModel(newViewModel);
  };


  const checkEmptyFieldError = (field: FormFields) => {
    if (viewModel.hasErrorFor(field)) {
      const newViewModel = viewModel.removeErrorFor(field);
      setViewModel(newViewModel);
    }
  };

  const updateCompanyRegistration = (fieldsToUpdate: OrganizationRegistration) => {
    const companyRegistration = new OrganizationRegistration({
      ...viewModel.companyRegistration,
      ...fieldsToUpdate,
    });
    const newViewModel = viewModel.updateCompanyRegistration(companyRegistration);
    setViewModel(newViewModel);
  };

  const RenderStep = () => {
    switch (viewModel.currentStep) {

    default:
      return (
        <UserRegistrationForm
          joinOrdoViewModel={viewModel}
          updateUserRegistration={updateUserRegistration}
          checkEmptyFieldError={checkEmptyFieldError}
          onCallToAction={doNextStep}
          callToAction='Next'
          readOnlyEmail={false}
        />
      );
    case RegistrationStep.RegisterCompany:
      return (
        <CompanyRegistrationForm
          registrationViewModel={viewModel}
          updateCompanyRegistration={updateCompanyRegistration}
          onCompanyRegistration={doNextStep}
          checkEmptyFieldError={checkEmptyFieldError}
          stepBack={doStepBack}
          onSkipCompanyRegistration={doUserRegistration}
        />
      );
    case RegistrationStep.RegisterSubscription:
      return (
        <SubscriptionRegistrationForm
          viewModel={viewModel}
          updatePlanRegistration={updatePlanRegistration}
          onStepBack={()=>doStepBack(viewModel.companyRegistration)}
          onCallToAction={doUserAndCompanyRegistration}
        />
      );
    }
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="row justify-content-center" style={{margin: 0}}>
        <div className={viewModel.currentStep === RegistrationStep.RegisterSubscription ? 'registration-page-wide-container-width' : 'registration-page-container-width'}>
          <GeneralErrorMessage viewModel={viewModel}/>
          <div className="card shadow zindex-100 mb-0 login-card">
            {RenderStep()}
          </div>
        </div>
      </div>
    </OrdoSpinner>
  );
};

export default withRouter(RegisterPage);
