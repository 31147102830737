import {API} from '../lib/api/ordoApi';
import OrganizationRegistration from '../models/organizationRegistration';
import BusinessError from '../errors/domain_errors';
import FormError, {FormFields} from '../errors/form_error';
import InvalidField from '../errors/invalid_field';
import {RegistrationStep} from './RegistrationViewModel';
import SubscriptionRegistration from '../models/subscriptionRegistration';

export type CreateOrganizationViewModelInput = {
  ordoAPI: API,
  companyRegistration: OrganizationRegistration,
  subscriptionRegistration: SubscriptionRegistration,
  error?: FormError,
  currentStep: RegistrationStep,
}

export default class CreateOrganizationViewModel {
  public currentStep: RegistrationStep;
  public companyRegistration: OrganizationRegistration;
  public subscriptionRegistration: SubscriptionRegistration;
  private readonly ordoAPI: API;
  private readonly error: FormError;

  constructor(viewModelInput: CreateOrganizationViewModelInput) {
    this.currentStep = viewModelInput.currentStep;
    this.companyRegistration = viewModelInput.companyRegistration;
    this.subscriptionRegistration = viewModelInput.subscriptionRegistration;
    this.ordoAPI = viewModelInput.ordoAPI;
    this.error = viewModelInput.error || FormError.withoutError();
  }

  public static empty(ordoAPI: API) {
    return new CreateOrganizationViewModel({
      ordoAPI: ordoAPI,
      companyRegistration: OrganizationRegistration.emptyCompanyRegistration(),
      subscriptionRegistration: SubscriptionRegistration.empty(),
      currentStep: RegistrationStep.RegisterCompany,
    });
  }

  public async registerCompany(): Promise<CreateOrganizationViewModel> {
    if (!this.companyRegistrationIsValid()) {
      const companyInvalidFields = this.companyRegistration.invalidFields();
      return this.updateErrors(companyInvalidFields);
    }
    try {
      await this.ordoAPI.registerCompany(this.companyRegistration, this.subscriptionRegistration);
      return this;
    } catch (error) {
      if (error instanceof BusinessError) {
        return new CreateOrganizationViewModel({
          ordoAPI: this.ordoAPI,
          companyRegistration: this.companyRegistration,
          subscriptionRegistration: this.subscriptionRegistration,
          error: error.formError,
          currentStep: this.currentStep,
        });
      }
      const unknownErrorForm = FormError.unknown(error.message);
      return new CreateOrganizationViewModel({
        ordoAPI: this.ordoAPI,
        companyRegistration: this.companyRegistration,
        subscriptionRegistration: this.subscriptionRegistration,
        error: unknownErrorForm,
        currentStep: this.currentStep,
      });
    }
  }

  public updateCompanyRegistration(companyRegistration: OrganizationRegistration) {
    return new CreateOrganizationViewModel({
      ordoAPI: this.ordoAPI,
      companyRegistration: companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
      currentStep: this.currentStep,
    });
  }

  public updateSubscriptionRegistration(subscriptionRegistration: SubscriptionRegistration) {
    return new CreateOrganizationViewModel({
      ordoAPI: this.ordoAPI,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: subscriptionRegistration,
      error: this.error,
      currentStep: this.currentStep,
    });
  }

  public updateErrors(invalidFields: InvalidField[]) {
    const updatedFormError = FormError.withErrors(invalidFields);
    return new CreateOrganizationViewModel({
      ordoAPI: this.ordoAPI,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: updatedFormError,
      currentStep: this.currentStep,
    });
  }

  public hasError() {
    return this.error.hasError();
  }

  public hasErrorFor(input: FormFields) {
    return this.error.hasErrorForInput(input);
  }

  public removeErrorFor(input: FormFields) {
    this.error.removeErrorFor(input);
    return new CreateOrganizationViewModel({
      ordoAPI: this.ordoAPI,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
      currentStep: this.currentStep,
    });
  }

  public errorMessage(input: FormFields): string {
    return this.error.errorMessage(input);
  }

  public companyRegistrationIsValid() {
    return this.companyRegistration.isValid();
  }

  stepBack() {
    return new CreateOrganizationViewModel({
      ordoAPI: this.ordoAPI,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
      currentStep: this.currentStep - 1,
    });
  }

  private hasNextStep(): boolean {
    return (this.currentStep + 1) in RegistrationStep;
  }

  public nextStep() {
    if (!this.hasNextStep()) return this;
    if (!this.companyRegistrationIsValid()  && this.currentStep === RegistrationStep.RegisterCompany) {
      const companyInvalidFields = this.companyRegistration.invalidFields();
      return this.updateErrors(companyInvalidFields);
    }
    return new CreateOrganizationViewModel({
      ordoAPI: this.ordoAPI,
      companyRegistration: this.companyRegistration,
      subscriptionRegistration: this.subscriptionRegistration,
      error: this.error,
      currentStep: this.currentStep + 1,
    });
  }
};
