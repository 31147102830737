import React, {useContext, useEffect, useState} from 'react';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ProductBatch from '../../inventory/ProductBatch';
import '../../../scss/ordo/inventoryPage/batches.scss';
import OrdoButton from '../common/OrdoButton';
import BatchModal from '../modals/BatchModal';
import Batch from '../../../models/Batch';
import Product from '../../../models/Product';
import {PRIMARY_COLOR} from '../../../constants';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {disableForNabisUserWithRestrictedAccess} from '../../../lib/featureFlags';
import {useFeatureFlags} from '../../../context/FeatureFlagsContext';

type ProductBatchesProps = {
  product: Product
  batches: Batch[],
  onBatchesChanged: Function,
  showModal: boolean,
  closeModal: Function,
  openModal: Function,
  filteredDistributorLicensesIds: string[]
}

const ProductBatches = ({batches, product, onBatchesChanged, showModal, closeModal, openModal, filteredDistributorLicensesIds}: ProductBatchesProps) => {
  const userSession = useContext(UserSessionContext);
  const featureFlags = useFeatureFlags();
  const [filterDistributorIds, setFilterDistributorIds] = useState(filteredDistributorLicensesIds);

  useEffect(() => setFilterDistributorIds(filteredDistributorLicensesIds), [filteredDistributorLicensesIds]);

  const getDistributorName = (distributorOrganizationLicenseId: string) : string => {
    const organizationLicense = userSession.currentOrganization()!.licenses.find(l => l.id === distributorOrganizationLicenseId);
    return organizationLicense ? organizationLicense.license.licenseNumber : '';
  };

  const closeAddBatchModal = () => {
    onBatchesChanged();
    closeModal();
  };

  const openAddBatchModal = () => openModal();

  const renderBatch = (batch: Batch) => {
    if(filterDistributorIds.length === 0 || filterDistributorIds.includes(batch.distributorOrganizationLicenseId)) {
      return <ProductBatch product={product} organizationId={product.organizationId} onBatchChange={onBatchesChanged} key={batch.id} batch={batch} distributorExternalLicenseId={getDistributorName(batch.distributorOrganizationLicenseId)}/>;
    } return <></>;
  };

  return (
    <div className="batches-container">
	  {!!batches && batches.map(renderBatch)}
      {!disableForNabisUserWithRestrictedAccess(userSession.userSession, featureFlags) && <div className="add-batch-container">
        <OrdoButton dataTestId='add-batch-button'
          disabled={false}
          text='add batch'
          category='link'
          onClick={openAddBatchModal}
          textStyle="add-batch-text"
          firstChild={<FontAwesomeIcon color={`${PRIMARY_COLOR}`} className='batch-button-size' size="lg" icon={faPlusCircle}/>}
        />
	    </div>}
      {showModal && <BatchModal isOpen={showModal} onClose={closeAddBatchModal} product={product} onBatchUpdate={() => {}}/>}
    </div>);
};

export default ProductBatches;
