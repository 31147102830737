import React from 'react';
import OrdoCardOptionsDropdown from '../common/OrdoCardOptionsDropdown';
import {Tier} from '../../../models/custom-pricing-tiers/Tier';

type CustomPricingTierRowProps = {
  tier: Tier,
  onDelete: (tier: Tier) => void
  onEdit: (tier: Tier) => void
}
export const CustomPricingTierRow = ({tier, onEdit, onDelete}: CustomPricingTierRowProps) => {
  const options = [
    {
      name: 'edit',
      action: () => onEdit(tier)
    },
    {
      name: 'delete',
      action: () => onDelete(tier)
    }
  ];
  return <div className="custom-pricing-tier-row">
    <span className="pricing-tier">{tier.name}</span>
    <span className="accounts">{tier.accountIds.length}</span>
    <span className="options"><OrdoCardOptionsDropdown options={options}/></span>
  </div>;
};