import React from 'react';
import OrdoTextArea from '../../common/OrdoTextArea';
import {ActivityCompletionResponseProps, SingleValueActivityCompletionResponse } from '../../../../models/activity/responses/ActivityCompletionResponse';
import { DARK_COLOR, PRIMARY_COLOR } from '../../../../constants';

export const TextResponse = ({
  response,
  updateResponse
}: ActivityCompletionResponseProps) => {
  const textResponse = response as SingleValueActivityCompletionResponse;
  return <OrdoTextArea maxValue={500} value={textResponse.body?.value || ''}
    placeholder='please provide a detailed response'
    handleChange={(event: any) => {
      textResponse.body = {value: event.target.value};
      updateResponse(textResponse);
    }}
    style={
      {
        width: '100%',
        height: '12em',
        color: DARK_COLOR,
        border: `1px solid ${PRIMARY_COLOR}`,
        boxShadow: 'none'
      }
    }/>;
};