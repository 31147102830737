import Login from '../models/login';
import {API} from '../lib/api/ordoApi';
import BusinessError from '../errors/domain_errors';
import FormError, {FormFields} from '../errors/form_error';
import { User } from '../models/User';
import InvalidField from '../errors/invalid_field';
import {UserSession} from '../models/UserSession';
import {LaunchDarklyFeatureFlagFetcher} from '../lib/featureFlags/launchDarkly';
import {toFeatureFlagUser} from '../lib/featureFlags';
import { DatadogRum } from '../lib/metrics/datadog';

export interface LoginViewModelInput {
  email: string,
  password: string,
  ordoAPI: API,
  error?: FormError,
}

export default class LoginViewModel {
  public login: Login;
  private readonly ordoAPI: API;
  private error: FormError;

  constructor(viewModelInput: LoginViewModelInput) {
    this.login = new Login(viewModelInput.email, viewModelInput.password);
    this.ordoAPI = viewModelInput.ordoAPI;
    this.error = viewModelInput.error || FormError.withoutError();
  }

  public static emptyLoginViewModel(ordoAPI: API) {
    return new LoginViewModel({email: '', password: '', ordoAPI: ordoAPI});
  }

  public get user(): User {
    return this.login.user;
  }

  public get email() {
    return this.login.email;
  }

  public get password() {
    return this.login.password;
  }

  public hasError() {
    return this.error.hasError();
  }

  public hasErrorFor(input: FormFields) {
    return this.error.hasErrorForInput(input);
  }

  public errorMessage(input: FormFields) {
    return this.error.errorMessage(input) === 'There is no user record corresponding to this identifier. The user may have been deleted.' ?
      'Invalid user' : this.error.errorMessage(input);
  }

  public removeErrorFor(input: FormFields) {
    this.error.removeErrorFor(input);
    return new LoginViewModel({email: this.email, password: this.password, ordoAPI: this.ordoAPI, error: this.error});
  }

  public cleanErrors() {
    return new LoginViewModel({email: this.email, password: this.password, ordoAPI: this.ordoAPI, error: FormError.withoutError()});
  }

  public isValid() {
    return this.login.isValid();
  }

  public updateViewModel(fieldsToUpdate: { email?: string, password?: string }) {
    const updateObject = {...this.login, ...fieldsToUpdate, ordoAPI: this.ordoAPI};
    return new LoginViewModel({
      email: updateObject.email,
      password: updateObject.password,
      ordoAPI: updateObject.ordoAPI,
      error: this.error,
    });
  }

  public async signInAndAcceptInvitation(invitationTokenId: string): Promise<LoginViewModel> {
    try {
      const self = await this.signIn();
      if(!self.hasError()) {
        await this.ordoAPI.acceptInvitation(invitationTokenId);
      }
      return self;
    } catch (error) {
      let formError: FormError;
      if (error instanceof BusinessError) {
        formError = error.formError;
      } else {
        formError = FormError.unknown(error.message);
      }
      return new LoginViewModel({email: this.email, password: this.password, ordoAPI: this.ordoAPI, error: formError});
    }
  }

  public async signIn(): Promise<LoginViewModel> {
    if (!this.isValid()) {
      const userInvalidFields = this.login.invalidFields();
      return this.updateErrors(userInvalidFields);
    }
    try {
      const userSession = await this.ordoAPI.login(this.email, this.password);
      this.login.setUserSession(userSession);
      LaunchDarklyFeatureFlagFetcher.updateSession(toFeatureFlagUser(userSession));
      const { user } = userSession;
      DatadogRum.init().setUser({
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });
      return this;
    } catch (error) {
      let formError: FormError;
      if (error instanceof BusinessError) {
        formError = error.formError;
      } else {
        formError = FormError.unknown(error.message);
      }
      return new LoginViewModel({email: this.email, password: this.password, ordoAPI: this.ordoAPI, error: formError});
    }
  }

  public updateErrors(invalidFields: InvalidField[]) {
    const updatedFormError = FormError.withErrors(invalidFields);
    return new LoginViewModel({email: this.email, password: this.password, ordoAPI: this.ordoAPI, error: updatedFormError});
  }

  public get userSession(): UserSession {
    return this.login.userSession;
  }
}
