import React, {FC, useContext, useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import '../../scss/purpose.scss';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import '../../scss/ordo/organizationPage/organization-page.scss';
import OrganizationPageViewModel from '../../application-models/organization-page-view-model';
import GeneralErrorMessage from '../components/common/GeneralErrorMessage';
import {UserSessionContext} from '../../context/UserSessionContext';
import {OrdoPageTabsProps} from '../components/common/OrdoPageTabs';
import OrganizationTab from './OrganizationTab';
import ComplianceTab from './compliance/ComplianceTab';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import {useAPI} from '../../context/OrdoApiContext';
import {Brand} from '../../models/Brand';
import {UserSessionRefresher} from '../../context/UserSessionRefresher';

enum OrganizationTabState {
  Company,
  Compliance
}

type OrganizationPageProps = {
  refresher: UserSessionRefresher,
};

const OrganizationPage: FC<RouteComponentProps & OrganizationPageProps >  = ({refresher}: OrganizationPageProps) => {
  const [tabState, setTabState] = useState<OrganizationTabState>(OrganizationTabState.Company);
  const api = useAPI();

  const userSessionContextData = useContext(UserSessionContext);
  const [viewModel, setViewModel] = useState(OrganizationPageViewModel.empty(api));

  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  useEffect(() => {
    showSpinner();
    Promise.all([
      OrganizationPageViewModel.initialize(api, userSessionContextData.currentOrganization()!.id).then(setViewModel),
    ]).finally(hideSpinner);
  }, []);

  const tabProps: OrdoPageTabsProps = {
    buttons: [
      {
        text: 'company',
        onClick: () => setTabState(OrganizationTabState.Company),
        dataTestId: 'organization-page-tab-button-organization'
      },
      {
        text: 'compliance',
        onClick: () => setTabState(OrganizationTabState.Compliance),
        dataTestId: 'organization-page-tab-button-compliance'
      }
    ]
  };

  const updateBrand = (brand: Brand) => {
    setViewModel(viewModel.updateBrand(brand));
  };

  const renderContent = (tab: OrganizationTabState) => {
    switch (tab) {
    case OrganizationTabState.Company:
      return (
        <OrganizationTab
          viewModel={viewModel}
          setViewModel={setViewModel}
          onBrandUpdate={updateBrand}
        />
      );
    default:
      return (
        <ComplianceTab
          refresher={refresher}
        />
      );
    }
  };


  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='organization-page'>
        <GeneralErrorMessage viewModel={viewModel}/>
        <OrdoPageTitle title='your organization' buttons={tabProps.buttons}/>
        <div className='page-content-body no-scroll-bar'>
          { renderContent(tabState) }
        </div>
      </div>
    </OrdoSpinner>
  );
};

export default withRouter(OrganizationPage);
