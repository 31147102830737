import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import OrdoModal from '../components/common/OrdoModal';
import {appConfig} from '../../lib/config';
import googleLogo from './googleLogo.png';

type GoogleAuthModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSuccessAuth: Function,
  onFailedAuth: Function
}

export const GoogleAuthModal = ({isOpen, onClose, onSuccessAuth, onFailedAuth} : GoogleAuthModalProps) => {

  const login = useGoogleLogin({
    onSuccess: codeResponse => onSuccessAuth(codeResponse),
    onError: errorResponse => onFailedAuth(errorResponse),
    flow: 'auth-code',
    scope: appConfig.googleLoginOauthScope
  });

  return(
    <OrdoModal
      title='Link your account'
      show={isOpen}
      onClose={onClose}
      showFooter={false}
      size='sm'
      className='google-auth-modal'
    >
      <div className='google-login-button-container'>
        <img className='google-icon' alt='google-icon' src={googleLogo}/>
        <div role="presentation" onClick={() => login()}>Sign in with Google</div>
      </div>
    </OrdoModal>
  );
};
