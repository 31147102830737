import BusinessError from '../errors/domain_errors';
import FormError from '../errors/form_error';
import { API } from '../lib/api/ordoApi';
import {OrganizationLicense} from '../models/OrganizationLicense';
import {CreateOrganizationLicenseInput} from './organization-page/CreateOrganizationLicenseViewModel';
import {MetrcIntegration} from '../pages/profile/compliance/MetrcIntegrationRow';
import {FlourishIntegration} from '../pages/profile/compliance/FlourishIntegrationRow';
import {NabisIntegration} from '../pages/profile/compliance/NabisIntegrationRow';

export default class ComplianceTabViewModel {
  public readonly api: API
  public readonly organizationLicenses: OrganizationLicense[]
  public readonly metrcIntegrations: MetrcIntegration[]
  public readonly flourishIntegrations: FlourishIntegration[]
  public readonly nabisIntegrations: NabisIntegration[]
  public error: FormError
  public readonly orgId: string

  constructor(
    api: API,
    organizationLicenses: OrganizationLicense[],
    metrcIntegrations: MetrcIntegration[],
    flourishIntegrations: FlourishIntegration[],
    nabisIntegrations: NabisIntegration[],
    error: FormError,
    orgId: string
  ) {
    this.api = api;
    this.organizationLicenses = organizationLicenses;
    this.metrcIntegrations = metrcIntegrations;
    this.flourishIntegrations = flourishIntegrations;
    this.nabisIntegrations = nabisIntegrations;
    this.error = error;
    this.orgId = orgId;
  }

  public static empty(api: API, orgId: string): ComplianceTabViewModel {
    return new ComplianceTabViewModel(
      api,
      [],
      [],
      [],
      [],
      FormError.withoutError(),
      orgId
    );
  }

  public static async initialize(api: API, currentOrgId: string): Promise<ComplianceTabViewModel> {
    const orgLicenses = await api.getOrganizationLicenses(currentOrgId);
    const metrcIntegrations = await api.getMetrcIntegrations(currentOrgId);
    const flourishIntegrations = await api.getFlourishIntegrations(currentOrgId);
    const nabisIntegrations = await api.getNabisIntegrations(currentOrgId);

    return new ComplianceTabViewModel(
      api,
      orgLicenses,
      metrcIntegrations,
      flourishIntegrations,
      nabisIntegrations,
      FormError.withoutError(),
      currentOrgId
    );
  }

  public async createOrganizationLicense(organizationLicenseInput: CreateOrganizationLicenseInput): Promise<ComplianceTabViewModel> {
    try {
      await this.api.createOrganizationLicense(this.orgId, organizationLicenseInput);
      return new ComplianceTabViewModel(
        this.api,
        this.organizationLicenses,
        this.metrcIntegrations,
        this.flourishIntegrations,
        this.nabisIntegrations,
        FormError.withoutError(),
        this.orgId
      );
    } catch (error) {
      if (error instanceof BusinessError) {
        this.error = error.formError;
      } else {
        this.error = FormError.unknown('unknown error');
      }
      return new ComplianceTabViewModel(
        this.api,
        this.organizationLicenses,
        this.metrcIntegrations,
        this.flourishIntegrations,
        this.nabisIntegrations,
        this.error,
        this.orgId
      );
    }
  }

  public getMetrcIntegrations() : MetrcIntegration[]{
    return this.metrcIntegrations;
  }

  public getFlourishIntegrations() : FlourishIntegration[]{
    return this.flourishIntegrations;
  }

  public getNabisIntegrations() : NabisIntegration[]{
    return this.nabisIntegrations;
  }

  public async addMetrcIntegration(metricAPIKey: string) : Promise<ComplianceTabViewModel> {
    const metrcIntegration = await this.api.createMetrcIntegration(this.orgId, metricAPIKey, this.organizationLicenses);
    if(metrcIntegration.licenseNumbers.length > 0) {
      this.metrcIntegrations.push(metrcIntegration);
      return new ComplianceTabViewModel(this.api,
        this.organizationLicenses,
        this.metrcIntegrations,
        this.flourishIntegrations,
        this.nabisIntegrations,
        this.error,
        this.orgId);
    }
    throw new Error('Could not create metrc integration. No licenses matched');
  }

  public async addFlourishIntegration(flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string) : Promise<ComplianceTabViewModel> {
    const flourishIntegration = await this.api.createFlourishIntegration(this.orgId, flourishAPIUsername, flourishAPIKey, syncInventoryOrItems);
    this.flourishIntegrations.push(flourishIntegration);
    return new ComplianceTabViewModel(this.api,
      this.organizationLicenses,
      this.metrcIntegrations,
      this.flourishIntegrations,
      this.nabisIntegrations,
      this.error,
      this.orgId);
  }

  public async addNabisIntegration(nabisAPIKey: string) : Promise<ComplianceTabViewModel> {
    const nabisIntegration = await this.api.createNabisIntegration(this.orgId, nabisAPIKey);
    this.nabisIntegrations.push(nabisIntegration);
    return new ComplianceTabViewModel(this.api,
      this.organizationLicenses,
      this.metrcIntegrations,
      this.flourishIntegrations,
      this.nabisIntegrations,
      this.error,
      this.orgId);
  }

  public async updateFlourishIntegration(id: string, flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string) : Promise<ComplianceTabViewModel> {
    const flourishIntegration = await this.api.updateFlourishIntegration(this.orgId, id, flourishAPIUsername, flourishAPIKey, syncInventoryOrItems);
    return new ComplianceTabViewModel(this.api,
      this.organizationLicenses,
      this.metrcIntegrations,
      [flourishIntegration],
      this.nabisIntegrations,
      this.error,
      this.orgId);
  }

  public async updateNabisIntegration(id: string, nabisAPIKey: string) : Promise<ComplianceTabViewModel> {
    const nabisIntegration = await this.api.updateNabisIntegration(this.orgId, id, nabisAPIKey);
    const index = this.nabisIntegrations.findIndex(ni => ni.id === nabisIntegration.id);
    this.nabisIntegrations.splice(index, 1, nabisIntegration);
    return new ComplianceTabViewModel(this.api,
      this.organizationLicenses,
      this.metrcIntegrations,
      this.flourishIntegrations,
      this.nabisIntegrations,
      this.error,
      this.orgId);
  }

  public async deleteMetrcIntegration(integration: MetrcIntegration): Promise<ComplianceTabViewModel> {
    return this.api.deleteMetrcIntegration(this.orgId, integration.apiKey)
      .then(() => {
        const integrations = this.metrcIntegrations.filter(i => i.apiKey !== integration.apiKey);
        return new ComplianceTabViewModel(this.api,
          this.organizationLicenses,
          integrations,
          this.flourishIntegrations,
          this.nabisIntegrations,
          this.error,
          this.orgId);
      })
      .catch(() => {throw new Error('Could not delete Metrc integration');});
  }

  public async deleteFlourishIntegration(integration: FlourishIntegration): Promise<ComplianceTabViewModel> {
    return this.api.deleteFlourishIntegration(this.orgId, integration.id)
      .then(() => {
        const integrations: FlourishIntegration[] = [];
        return new ComplianceTabViewModel(this.api,
          this.organizationLicenses,
          this.metrcIntegrations,
          integrations,
          this.nabisIntegrations,
          this.error,
          this.orgId);
      })
      .catch(() => {throw new Error('Could not delete Flourish integration');});
  }

  public async deleteNabisIntegration(integration: NabisIntegration): Promise<ComplianceTabViewModel> {
    return this.api.deleteNabisIntegration(this.orgId, integration.id)
      .then(() => {
        const integrations = this.nabisIntegrations.filter(i => i.id !== integration.id);
        return new ComplianceTabViewModel(this.api,
          this.organizationLicenses,
          this.metrcIntegrations,
          this.flourishIntegrations,
          integrations,
          this.error,
          this.orgId);
      })
      .catch(() => {throw new Error('Could not delete Nabis integration');});
  }

  public withoutErrors() : ComplianceTabViewModel {
    return new ComplianceTabViewModel(this.api,
      this.organizationLicenses,
      this.metrcIntegrations,
      this.flourishIntegrations,
      this.nabisIntegrations,
      FormError.withoutError(),
      this.orgId);
  }
}
