import {CartItem} from '../models/order-entry/CartItem';

export type InvalidOrderField = {
  message: string,
  field: string,
}

const DEFAULT_ERROR_MESSAGE = 'There was a problem with the order';

export class OrderError {
  public errors: Map<string, string>;

  private constructor() {
    this.errors = new Map();
  }


  public static withoutError() {
    return new OrderError();
  }

  public static withErrors(invalidProducts: InvalidOrderField[]) {
    const formError = new OrderError();
    invalidProducts.forEach((field: InvalidOrderField) => formError.addError(field));
    return formError;
  }

  public static unknown(errorMessage: string) {
    const unknownError = {field: 'unknown', message: errorMessage};
    return OrderError.withErrors([unknownError]);
  }

  private addError(invalidOrderProduct: InvalidOrderField) {
    this.errors.set(invalidOrderProduct.field, invalidOrderProduct.message);
  }

  public hasError() {
    return this.errors.size > 0;
  }

  public hasErrorForProduct(productId: string) {
    return !!this.errors.get(productId);
  }

  public errorMessage(items: CartItem[]): string {
    const amountOfErrors = this.errors.size;
    if(amountOfErrors === 1) {
      const [singleErrorField] = Array.from(this.errors.keys());
      const itemWithoutStock = items.find(item => item.product.id === singleErrorField);
      return itemWithoutStock ? `There is not enough stock for ${itemWithoutStock.product.name}` : (this.errors.get(singleErrorField) || DEFAULT_ERROR_MESSAGE);
    }
    if(amountOfErrors > 1) return 'Some products don\'t have enough stock to fulfill the order';
    return '';
  }
}