import React, {useContext, useEffect, useState} from 'react';
import '../../../scss/ordo/common.scss';
import '../../../scss/ordo/inventoryPage/order-entry-list.scss';
import {isEmpty, orderBy} from 'lodash';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons/faExchangeAlt';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import SearchBar from '../inventory/searchBar/SearchBar';
import OrderEntryListItem from './OrderEntryListItem';
import {OrderEntryViewModel} from '../../../application-models/order-entry/OrderEntryViewModel';
import {ProductWithAvailability} from '../../../models/productWithAvailability';
import { CartItemType } from '../../../models/order-entry/CartItem';
import OrdoInformativeSearchableDropdown from '../common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {AccountLocation} from '../../../models/Account';
import ProductFilterViewModel from '../../../application-models/ProductFilterViewModel';
import {OrganizationLicense} from '../../../models/OrganizationLicense';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import {UserSessionContext} from '../../../context/UserSessionContext';
import OrdoSpinner from '../OrdoSpinner';
import {OrdoPagination} from '../pagination/OrdoPagination';
import {SortingCriteria} from '../../../application-models/sales-activity/SalesActivityViewModel';

type OrderEntryListProps = {
  updateCart: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void;
  updateAccountLocation: (accountLocation: AccountLocation) => void;
  orderEntryViewModel: OrderEntryViewModel;
  updateViewModel: (viewModel: OrderEntryViewModel) => void
}

const OrderEntryList = ({updateCart, updateAccountLocation, orderEntryViewModel, updateViewModel}: OrderEntryListProps) => {
  const userSession = useContext(UserSessionContext);
  const [filterProductsViewModel, setFilterProductsViewModel] = useState(ProductFilterViewModel.emptySearchBarViewModel<ProductWithAvailability>(userSession.currentOrganization()?.licenses!, p => p.availability > 0));
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const organization = userSession.currentOrganization()!;
  const [sortingCriteria, setSortingCriteria] = useState<SortingCriteria>(SortingCriteria.NO_SORTING);

  useEffect(() => {
    const updatedViewModel = filterProductsViewModel.updateProducts(orderEntryViewModel.products);
    updatedViewModel.paginate().then(()=>setFilterProductsViewModel(updatedViewModel));
  },[orderEntryViewModel]);

  const onPageChange = (newPage: number)=> {
    const updatedViewModel = filterProductsViewModel.changePage(newPage);
    updatedViewModel.paginate().then(()=>setFilterProductsViewModel(updatedViewModel));
  };

  const getSortingCriteria = () => {
    switch (sortingCriteria) {
    case SortingCriteria.NO_SORTING:
      return '';
    case SortingCriteria.ASCENDING:
      return 'asc';
    case SortingCriteria.DESCENDING:
      return 'desc';
    default:
      return '';
    }
  };
  const sortedProducts = () => {
    const criteria = getSortingCriteria();
    return criteria ? orderBy(filterProductsViewModel.paginatedProducts(), ['availability'], [criteria]) : orderBy(filterProductsViewModel.paginatedProducts(), ['id']);
  };

  const noProductsToDisplay = () => {
    return isEmpty(filterProductsViewModel.filteredProducts) || filterProductsViewModel.filteredProducts.every(product => product.availability === 0);
  };

  const updateCurrentDistributor = async (selectableDistributor: OrganizationLicense) => {
    showSpinner();
    const updatedViewModel = await orderEntryViewModel.updateDistributor(organization.id, selectableDistributor);
    updateViewModel(updatedViewModel);
    hideSpinner();
  };

  const changeSortCriteria = () => {
    switch (sortingCriteria) {
    case SortingCriteria.NO_SORTING:
      setSortingCriteria(SortingCriteria.ASCENDING);
      break;
    case SortingCriteria.ASCENDING:
      setSortingCriteria(SortingCriteria.DESCENDING);
      break;
    case SortingCriteria.DESCENDING:
      setSortingCriteria(SortingCriteria.NO_SORTING);
      break;
    default:
      setSortingCriteria(SortingCriteria.NO_SORTING);
      break;
    }
  };

  const getSortingCriteriaText = () => {
    const criteria = getSortingCriteria();
    return criteria ? `: ${criteria}` : '';
  };
  const ProductsContent = () => {
    return (
      <div className="table-responsive order-entry-list">
        <table className="table align-items-center mb-lg-5" style={{width: '90%'}}>
          <thead>
            <tr className="table-headers with-border-bottom">
              <th scope="col" className="table-header">Product</th>
              <th scope="col" className="table-header">Brand</th>
              <th scope="col" className="table-header">Size</th>
              <th scope="col" className="table-header">Price</th>
              <th scope="col" className="table-header availability">
                Avail.
                <div role='presentation' onClick={changeSortCriteria} style={{display: 'flex', alignItems: 'center'}}>
                  <FontAwesomeIcon className="sort-icon" icon={faExchangeAlt} style={{}}/>
                  <span>{getSortingCriteriaText()}</span>
                </div>
              </th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th style={{width: '0%'}} scope="col"/>
            </tr>
          </thead>
          <tbody className="list inventory-list order-entry">
            {sortedProducts().map((item: ProductWithAvailability) =>
              <OrderEntryListItem
                orderEntryViewModel={orderEntryViewModel}
                product={item}
                key={item.id}
                onChange={updateCart}
              />)}
          </tbody>
        </table>
      </div>
    );
  };

  const NoProductsContent = () => {
    return (
      <div className='no-product-content'>No products available</div>
    );
  };

  const renderContent = () => {
    if (noProductsToDisplay()) {
      return <NoProductsContent/>;
    }
    return <>
      <ProductsContent/>
      <OrdoPagination currentPage={filterProductsViewModel.currentPage} totalOfPages={filterProductsViewModel.totalPages()} onPageChange={onPageChange}/>
    </>;
  };


  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='order-entry-list-container'>
        <div className='order-entry-list-dropdowns'>
          <OrdoInformativeSearchableDropdown
            dataTestId='select-location'
            selectedOption={orderEntryViewModel.getSelectedAccountLocation()}
            options={orderEntryViewModel.getSelectableAccountLocations()}
            onChangeSelectedOption={updateAccountLocation}
            placeholder='select a location'
            addedStyles={{
              width: '50%'
            }}
          />
          <OrdoInformativeSearchableDropdown
            selectedOption={orderEntryViewModel.getSelectedDistributor()}
            canEditValue={orderEntryViewModel.hasMoreThanOneDistributor()}
            options={orderEntryViewModel.getSelectableDistributors()}
            onChangeSelectedOption={(distributor) => updateCurrentDistributor(distributor)}
            placeholder='select a distributor'
          />
        </div>
        <div className="card card-fluid inventory-list-card ordo-shadow ordo-card-border">
          {!isEmpty(orderEntryViewModel.products) && <SearchBar viewModel={filterProductsViewModel} updateViewModel={setFilterProductsViewModel}/>}
          <div className={`inventory-list-card-content ${isEmpty(filterProductsViewModel.filteredProducts) ? 'no-product-width' : ''}`}>
            {renderContent()}
          </div>
        </div>
      </div>
    </OrdoSpinner>
  );
};

export default OrderEntryList;
