import React, {useEffect, useState} from 'react';
import '../../../scss/ordo/inventoryPage/product-form-modal.scss';
import '../../../scss/ordo/buttons.scss';
import '../../../scss/ordo/common.scss';
import OrdoModal from '../common/OrdoModal';
import {useAPI} from '../../../context/OrdoApiContext';
import OrdoInput from '../common/inputs/OrdoInput';
import OrdoPageSubTitle from '../common/OrdoPageSubTitle';
import OrdoDropdown from '../common/OrdoDropdown';
import OrdoFileInput from '../common/inputs/OrdoFileInput';
import {Brand} from '../../../models/Brand';
import {OrdoImage} from '../../../models/OrdoImage';
import ImagePreview from '../brands/ImagePreview';
import OrdoSelector from '../common/OrdoSelector';
import GeneralErrorMessage from '../common/GeneralErrorMessage';
import ProductCategory from '../../../models/ProductCategory';
import Product, {Genetics, ProductMeasureUnit} from '../../../models/Product';
import {
  ProductFormViewModel,
  ProductGenetics,
  ProductInformationInput,
  ProductUnits
} from '../../../application-models/ProductFormViewModel';
import AddProductViewModel from '../../../application-models/AddProductViewModel';
import EditProductViewModel from '../../../application-models/EditProductViewModel';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import {OrdoCheckbox} from '../OrdoCheckbox';

type ProductFormModalProps = {
  isOpen: boolean,
  onSubmit: Function,
  onClose: Function,
  brands: Brand[],
  categories: ProductCategory[],
  organizationId: string,
  editingProduct?: Product
};
const ProductFormModal = ({onClose, onSubmit, isOpen, brands, organizationId, categories, editingProduct}: ProductFormModalProps) => {
  const api = useAPI();
  const addProductViewModel = () => AddProductViewModel.initialize(api, brands[0], categories[0]);
  const [viewModel, setViewModel] = useState<ProductFormViewModel>(addProductViewModel());
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  useEffect(()=> {
    const vm = editingProduct ?
      EditProductViewModel.initialize(api, editingProduct) :
      addProductViewModel();
    setViewModel(vm);
  }, [editingProduct]);

  const updateUnitSaleOnly = () => {
    setViewModel(viewModel.updateUnitSaleOnly());
  };

  const updateCaseSaleOnly = () => {
    setViewModel(viewModel.updateCaseSaleOnly());
  };

  const updateProductInformation = (updatedField: ProductInformationInput) => {
    setViewModel(viewModel.updateProductInformation(
      {
        ...viewModel.productInformation,
        ...updatedField
      }));
  };
  const reset = () => {
    onClose();
    setViewModel(addProductViewModel());
  };
  const changeCategory = (category: ProductCategory) => {
    setViewModel(viewModel.changeCategory(category));
  };
  const submit = () => {
    showSpinner();
    setViewModel(viewModel.setInProgress());
    return viewModel.submit(organizationId).then((vm) => {
      if(!vm.hasErrors()) {
        onSubmit();
        reset();
      }
      else setViewModel(vm);
    }).finally(hideSpinner);
  };

  const formId = 'modal-form';

  const displaySizeUnit = (sizeUnit: ProductMeasureUnit | undefined) => {
    if (!sizeUnit || (sizeUnit.toLowerCase() === ProductMeasureUnit.UNKNOWN)) return ProductMeasureUnit.GRAMS;
    return sizeUnit;
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <OrdoModal
      formId={formId}
      onClose={reset}
      show={isOpen}
      actionText={viewModel.actionName()}
      dataTestId='add-product-modal'
      disableCTA={viewModel.inProgress}
    >
      <form id={formId} onSubmit={(event) => {
        event.preventDefault();
        submit();
      }}>
        <GeneralErrorMessage viewModel={viewModel}/>
        <OrdoPageSubTitle title='enter product name' bottomMargin={false} removeMargin/>
        <OrdoInput inputName="name"
          value={viewModel.productInformation.name}
          placeholder='product name' inputSize='large'
          invalidMessage={viewModel.errorMessage('name')}
          isValid={!viewModel.hasErrorFor('name')}
          onChange={(value: string) => updateProductInformation({name: value})} onBlur={()=>{}}
        />
        <div className='product-form-fields-box'>
          <div className='product-form-fields-box__field'>
            <OrdoPageSubTitle title='choose a category' bottomMargin={false} removeMargin/>
            <OrdoDropdown
              selectedOption={viewModel.productInformation.category}
              placeholder='select a category'
              onSelect={changeCategory}
              options={categories}
              displayName={(category)=>category.name}
              height='short'
            />
          </div>
          <div className='product-form-fields-box__image-and-preview'>
            <div className='product-form-fields-box__field__image'>
              <OrdoPageSubTitle title='image (optional)' bottomMargin={false} removeMargin/>
              <OrdoFileInput
                text='upload'
                onFileSelected={
                  (image: OrdoImage) => updateProductInformation({ image: image })
                }
                invalidMessage={viewModel.errorMessage('image')}
                isValid={!viewModel.hasErrorFor('image')}
              />
            </div>
            <div className='product-form-fields-box__field__image-preview'>
              {viewModel.imagePreview && (
                <ImagePreview url={viewModel.imagePreview} alt="image" size={80}/>
              )}
            </div>
          </div>
        </div>
        <div className='product-form-fields-box'>
          <div className='product-form-fields-box__field'>
            <OrdoPageSubTitle title='brand' bottomMargin={false} removeMargin/>
            <div className='product-form-fields-box__field__brands'>
              {
                brands.map((brand: Brand) => {
                  const selectedBrand = viewModel.productInformation.brand.id === brand.id;
                  return <button
                    type='button'
                    className='product-form-fields-box__field__brands__button'
                    key={brand.id}
                    onClick={()=>updateProductInformation({brand: brand})}>
                    <ImagePreview
                      url={brand.logoImageURL}
                      alt={brand.name}
                      size={50}
                      highlighted={selectedBrand}
                    />
                  </button>;
                })
              }
            </div>
          </div>
          {!viewModel.productIsAccessories() && <div className='product-form-fields-box__field'>
            <OrdoPageSubTitle title='genetics' bottomMargin={false} removeMargin/>
            <OrdoSelector
              width='75'
              size='sm'
              vertical
              selected={viewModel.productInformation.genetics}
              options={ProductGenetics}
              onChange={(genetics: Genetics) => updateProductInformation({genetics: genetics})}
              invalidMessage={viewModel.errorMessage('genetics')}
              isValid={!viewModel.hasErrorFor('genetics')}
            />
          </div>}
        </div>
        <div className='product-form-fields-box'>
          <div className='product-form-fields-box__field'>
            <OrdoPageSubTitle title='units per case' bottomMargin={false} removeMargin/>
            <OrdoInput
              inputType='number'
              inputName="unitsPerCase"
              value={viewModel.unitsPerCaseValue()}
              placeholder='0'
              inputSize='semi-large'
              onChange={(value: string) => updateProductInformation({unitsPerCase: parseFloat(value)})}
              onBlur={()=>{}}
              min={0}
              step={1}
              bottomMargin={false}
              readOnly={viewModel.productInformation.unitSaleOnly}
              invalidMessage={viewModel.errorMessage('unitsPerCase')}
              isValid={!viewModel.hasErrorFor('unitsPerCase')}
            />
            <OrdoCheckbox id='unit-only' checked={editingProduct?.unitSaleOnly || viewModel.productInformation.unitSaleOnly} onChange={updateUnitSaleOnly} label="unit sales only"/>
            <OrdoCheckbox id='case-only' checked={editingProduct?.caseSaleOnly || viewModel.productInformation.caseSaleOnly} onChange={updateCaseSaleOnly} label="case sales only"/>
          </div>
          <div className='product-form-fields-box__field'>
            <OrdoPageSubTitle title={viewModel.priceText()} bottomMargin={false} removeMargin/>
            <OrdoInput
              inputType='number'
              inputName="price"
              value={viewModel.productInformation.price.toString()}
              placeholder='$0.00'
              inputSize='semi-large'
              min={0}
              step={0.01}
              onChange={(value: string) => updateProductInformation({price: parseFloat(value)})}
              onBlur={()=>{}}
              invalidMessage={viewModel.errorMessage('price')}
              isValid={!viewModel.hasErrorFor('price')}
            />
          </div>
        </div>
        <div className='product-form-fields-box'>
          {
            !viewModel.productIsAccessories() &&
            <div className='product-form-fields-box__field'>
              <OrdoPageSubTitle title='consumer unit size' bottomMargin={false} removeMargin/>
              <div className='product-form-fields-box__field__consumer-unit-size'>
                <OrdoInput
                  inputType='number'
                  inputName="consumerUnitSizeAmount"
                  value={viewModel.productInformation.consumerUnitSizeAmount?.toString()||'0'}
                  placeholder='0.00'
                  inputSize='medium'
                  onChange={(value: string) => updateProductInformation({consumerUnitSizeAmount: parseFloat(value)})}
                  onBlur={()=>{}}
                  step={0.01}
                  invalidMessage={viewModel.errorMessage('consumerUnitSizeAmount')}
                  isValid={!viewModel.hasErrorFor('consumerUnitSizeAmount')}
                />
                <div className='product-form-fields-box__field__consumer-unit-size__dropdown'>
                  <OrdoDropdown
                    height='short'
                    selectedOption={displaySizeUnit(viewModel.productInformation.consumerUnitSizeUnit)}
                    placeholder='unit'
                    onSelect={(isUnit: ProductMeasureUnit) => updateProductInformation({consumerUnitSizeUnit: isUnit})}
                    options={ProductUnits}
                    invalidMessage={viewModel.errorMessage('consumerUnitSizeUnit')}
                    isValid={!viewModel.hasErrorFor('consumerUnitSizeUnit')}
                  />
                </div>
              </div>
              {viewModel.requirePreRolls() && <>
                <OrdoPageSubTitle title='pre-rolls' removeMargin/>

                <OrdoInput
                  inputType='number'
                  inputName="numberPreRolls"
                  value={viewModel.productInformation.preRolls?.toString()||'0'}
                  placeholder='0'
                  inputSize='semi-large'
                  min={0}
                  step={1}
                  onChange={(value: string) => updateProductInformation({preRolls: parseFloat(value)})} onBlur={()=>{}}
                  invalidMessage={viewModel.errorMessage('preRolls')}
                  isValid={!viewModel.hasErrorFor('preRolls')}
                />
              </>}
            </div>
          }
          <div className='product-form-fields-box__field'>
            <OrdoPageSubTitle title='product sku' bottomMargin={false} removeMargin/>
            <div className='product-form-fields-box__field__product-SKU'>
              <OrdoInput
                inputName="productSKU"
                value={viewModel.productInformation.customerSKU || ''}
                placeholder=''
                inputSize='semi-large'
                onChange={(value: string) => updateProductInformation({customerSKU: value})}
                invalidMessage={viewModel.errorMessage('customerSKU')}
                isValid={!viewModel.hasErrorFor('customerSKU')}
              />
            </div>
          </div>
        </div>
      </form>
    </OrdoModal>
  </OrdoSpinner>;
};

export default ProductFormModal;
