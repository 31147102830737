import React from 'react';
import OrdoModal from '../components/common/OrdoModal';
import OrdoButton from '../components/common/OrdoButton';
import '../../scss/ordo/salesActivity/verify-order-modal.scss';
import {SalesActivityAccount} from './salesActivityTypes';

type UpdateStaleAccountStatusModalProps = {
  isOpen: boolean,
  onClose: () => void,
  ctaText: string,
  callToAction: (order: SalesActivityAccount) => void,
  onLost: (order: SalesActivityAccount) => Promise<void>,
  account: SalesActivityAccount
}

const UpdateStaleAccountStatusModal = ({isOpen, onClose, callToAction, onLost, account, ctaText}: UpdateStaleAccountStatusModalProps) => {

  return (
    <OrdoModal title='Stale buyer'
      show={isOpen}
      size="sm"
      onClose={onClose}
      showFooter={false}>

      <div className="verify-delivery-buttons">
        {ctaText && <div className="verify-order-button">
          <OrdoButton disabled={false}
            text={ctaText}
            category="primary"
            onClick={() => {
              callToAction(account);
              onClose();
            }}
            dataTestId="verify-delivery-with-changes-button"/>
        </div>}
        <div className="verify-order-button">
          <OrdoButton disabled={false}
            text="lost"
            category="secondary"
            onClick={() => onLost(account)}
            dataTestId="verify-delivery-without-changes-button"/>
        </div>
        <div className="verify-order-button">
          <OrdoButton disabled={false}
            text="cancel"
            category="cancel"
            onClick={onClose}
            dataTestId="cancel-delivery-verification"/>
        </div>
      </div>
    </OrdoModal>
  );
};

export default UpdateStaleAccountStatusModal;
