import React from 'react';
import '../../../scss/ordo/clickable-tag.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

type ClickableTagProps = {
  data: { label: string, value: any },
  onClick: Function
};

export function ClickableTag({data, onClick}: ClickableTagProps) {
  return <div className='clickable-tag' id={data.value} key={data.value}>
    {data.label}
    <FontAwesomeIcon icon={faTimes} className="remove-icon" onClick={()=> onClick(data.value)}/>
  </div>;
}