import React, {useState} from 'react';
import '../../../scss/ordo/salesActivity/note-details.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {Note} from '../../../models/Note';
import {NoteForm} from '../../components/notes/NoteForm';
import {API} from '../../../lib/api/ordoApi';
import {useAPI} from '../../../context/OrdoApiContext';
import NoteViewModel from '../../../application-models/sales-activity/NoteViewModel';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import OrdoSpinner from '../../components/OrdoSpinner';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import MobileDisplayAsModal from '../../components/common/MobileDisplayAsModal';
import Contact from '../../../models/order-entry/Contact';

type NoteDetailsRowProps = {
  orgId: string,
  accountId: string,
  note: Note,
  onNoteUpdate: (note: Note) => void,
  onNoteDelete: (note: Note) => void,
  accountContacts: Contact[]
};
export const NoteDetailsRow = ({orgId, accountId, note, onNoteUpdate, onNoteDelete, accountContacts}: NoteDetailsRowProps) => {
  const api: API = useAPI();
  const {showSpinner, hideSpinner, spinnerVisibility} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const [noteViewModel, setNoteViewModel] = useState(new NoteViewModel(
    api, note.title, note.description, note.contacts));
  const [openEditNoteForm, setOpenEditNoteForm] = useState(false);
  const [openedDeleteNoteModal, setOpenedDeleteNoteModal] = useState(false);

  const openDeleteNoteModal = () => {
    setOpenedDeleteNoteModal(true);
  };

  const closeDeleteNoteModal = () => {
    setOpenedDeleteNoteModal(false);
  };

  const toggleEditNoteForm = () => setOpenEditNoteForm(!openEditNoteForm);

  const createdByName = () => {
    return `created by ${note.createdBy.firstName} ${note.createdBy.lastName}`;
  };

  const renderNoteDetails = () => {
    if (!openEditNoteForm) {
      return (
        <div>
          <div className="note-row__title-and-editing">
            <div>
              <div className='note-row__title-and-editing__title'>
                {note.title}
              </div>
              <div className="note-row__title-and-editing__info">
                <div className="note-row__title-and-editing__info__created-by">
                  {createdByName()}
                </div>
                <div className='note-row__title-and-editing__info__last-edited'>
                  <span className='note-row__title-and-editing__info__last-edited__title'>last edited</span>
                  {note.lastEdited}
                </div>
              </div>
            </div>
            <div className="note-row__title-and-editing__editing">
              <FontAwesomeIcon className="note-row__title-and-editing__editing__icon" size="lg" icon={faEdit} onClick={toggleEditNoteForm}/>
              <FontAwesomeIcon className="note-row__title-and-editing__editing__icon" size="lg" style={{marginLeft: '1em'}} icon={faTrashAlt} onClick={openDeleteNoteModal}/>
            </div>
          </div>
          <pre className='note-row__description'>
            {note.description}
          </pre>
        </div>
      );
    }
    return <span/>;
  };

  const updateNote = () => {
    showSpinner();
    noteViewModel.updateNote(orgId, accountId, note.id)
      .then((updatedNote: Note) => {
        hideSpinner();
        successToast('note successfully updated');
        setOpenEditNoteForm(false);
        onNoteUpdate(updatedNote);
      })
      .catch(() => {
        errorToast('could not update the note');
      })
      .finally(hideSpinner);
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="note-row">
      { renderNoteDetails() }
      {openEditNoteForm &&
      <MobileDisplayAsModal title="edit a note" onClose={()=>setOpenEditNoteForm(false)} show={openEditNoteForm}>
        <div className="edit-form-container">
          <NoteForm
            viewModel={noteViewModel}
            updateViewModel={setNoteViewModel}
            onSubmit={() => updateNote()}
            onCancel={() => setOpenEditNoteForm(false)}
            accountContacts={accountContacts}
          />
        </div>
      </MobileDisplayAsModal>
      }
      {openedDeleteNoteModal &&
      <ConfirmationModal
        show={openedDeleteNoteModal}
        onClose={closeDeleteNoteModal}
        onSubmit={onNoteDelete}
        confirmationText='are you sure you want to delete the note?'
        actionText='delete'
      />}
    </div>
  </OrdoSpinner>;
};
