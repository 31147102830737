import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {GroupPricePriceRule} from '../../../models/custom-pricing-tiers/Tier';
import {SelectableOption} from '../common/searchable-dropdown/selectableOption';
import {ConsumerUnitSize, Genetics} from '../../../models/Product';
import OrdoSearchableDropdown from '../common/searchable-dropdown/OrdoSearchableDropdown';
import OrdoNumberInput from '../common/inputs/OrdoNumberInput';
import { customPriceEditorDropdownStyles } from './CustomPriceEditor';

type GroupPriceEditorProps = {
  groupPrice: GroupPricePriceRule,
  brands: SelectableOption<string | undefined>[],
  categories: SelectableOption<string | undefined>[],
  genetics: SelectableOption<Genetics | undefined>[],
  sizes: SelectableOption<ConsumerUnitSize | undefined>[],
  onRemoveRow: (index: number) => void,
  index: number,
  onChange: (p: GroupPricePriceRule, index: number) => void
};
export const GroupPriceEditor = ({
  groupPrice,
  brands,
  categories,
  genetics,
  sizes,
  onRemoveRow,
  index,
  onChange
}: GroupPriceEditorProps) => {

  const selectedBrand = groupPrice.brandId && brands.find(brand => brand.value === groupPrice.brandId);
  const selectedCategory = groupPrice.categoryId && categories.find(category => category.value === groupPrice.categoryId);
  const selectedSize = groupPrice.consumerUnitSize && sizes.find(size => size.value?.amount === groupPrice.consumerUnitSize?.amount && size.value?.unit === groupPrice.consumerUnitSize?.unit);
  const selectedGenetics = groupPrice.genetics && genetics.find(genetic => genetic.value === groupPrice.genetics);
  const dropdownStyles = customPriceEditorDropdownStyles();

  return <div className="custom-price-editor group" id={groupPrice.id}>
    <OrdoSearchableDropdown label="brand" placeholder="all" selectedOption={selectedBrand}
      options={brands}
      onChangeSelectedOption={(option) => onChange({...groupPrice, brandId: option.value}, index)}
      addedStyles={dropdownStyles}/>
    <OrdoSearchableDropdown label="category" placeholder="all" selectedOption={selectedCategory}
      options={categories} onChangeSelectedOption={(option) => onChange({
        ...groupPrice,
        categoryId: option.value
      }, index)} addedStyles={dropdownStyles}/>
    <OrdoSearchableDropdown label="genetics" placeholder="all" selectedOption={selectedGenetics}
      options={genetics} onChangeSelectedOption={(option) => onChange({
        ...groupPrice,
        genetics: option.value
      }, index)} addedStyles={dropdownStyles}/>
    <OrdoSearchableDropdown label="unit size" placeholder="all" selectedOption={selectedSize}
      options={sizes} onChangeSelectedOption={(option) => onChange({
        ...groupPrice,
        consumerUnitSize: option.value
      }, index)} addedStyles={dropdownStyles}/>
    <OrdoNumberInput label="unit price" value={groupPrice.unitPrice}
      onChange={(newPrice) => onChange({...groupPrice, unitPrice: newPrice}, index)}
      min={0}
      displayArrows={false}
      isValid={groupPrice.unitPrice >= 0}
      invalidMessage=""
      inputKey=""
    />
    <FontAwesomeIcon icon={faMinusCircle} className="delete-row" onClick={() => onRemoveRow(index)}/>
  </div>;
};