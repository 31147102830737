import React, {useContext, useState, useEffect} from 'react';

import { MetrcItemResponse } from '../../../../lib/api/response-types';
import Product from '../../../../models/Product';
import OrdoModal from '../../common/OrdoModal';
import {useAPI} from '../../../../context/OrdoApiContext';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import metrcLogo from '../../../profile/metrc.png';
import '../../../../scss/ordo/metrc-product-link.scss';
import useOrdoToasts from '../../../../hooks/useOrdoToasts';
import OrdoSpinner from '../../OrdoSpinner';
import { MetrcProductLinkViewModel, MetrcProductLinkWizardStep } from '../../../../application-models/metrc-integration/MetrcLinkProduct';

type MetrcLinkProductWizardProps = {
  show: boolean
  product: Product
  onClose: () => void
  onSubmit: () => void
};


const renderSelectExternalItemStep = (vm: MetrcProductLinkViewModel, setViewModel: (vm: MetrcProductLinkViewModel) => void) => {
  const onRowClick = (item: MetrcItemResponse) => {
    setViewModel(vm.setSelectedMetrcItem(item));
  };

  const list = vm.metrcItems.map((item, index) => {
    let cn = 'metrc-select-item-row';
    if (vm.selectedMetrcItem?.Id === item.Id)
      cn += ' selected-item';
    return (
      <div
        role='button'
        className={cn}
        key={item.Id}
        onKeyPress={() => {}}
        tabIndex={index}
        onClick={() => {onRowClick(item);}}
      >
        <div className='metrc-item-description'>
          <div> {item.Name} </div>
          <div> {item.ProductCategoryName} </div>
          <div> {item.Id} </div>
        </div>
        <span className='metrc-item-quantity'> {item.UnitWeight} {item.UnitWeightUnitOfMeasureName} </span>
      </div>
    );
  });
  return (
    <div>
      { list }
    </div>
  );
};

const renderSelectLicenseStep = (vm: MetrcProductLinkViewModel, setViewModel: (vm: MetrcProductLinkViewModel) => void) => {
  const changeSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setViewModel(vm.setSelectedLicense(e.currentTarget.value));
  };

  return vm.licenses.map((license) => {
    const itemId = vm.productMappings.get(vm.selectedProduct.id, license)?.externalId;
    return (
      <div key={license} className='metrc-product-link-license-row'>
        <input
          type='radio'
          key={license}
          value={license}
          checked={vm.selectedLicenseNumber === license}
          onChange={changeSelected}
        />
        <div className='metrc-product-link-license'>
          <div className='metrc-product-link-license-number'>
            { license }
            &#10;&#13;
          </div>
          <div className='metrc-product-link-item-id'>
            item id: {itemId || 'none'}
          </div>
        </div>
      </div>
    );
  });
};

const renderContent = (viewModel: MetrcProductLinkViewModel, setViewModel: (vm: MetrcProductLinkViewModel) => void) => {
  switch (viewModel.step) {
  case MetrcProductLinkWizardStep.SelectExternalItem:
    return renderSelectExternalItemStep(viewModel, setViewModel);
  default:
    return renderSelectLicenseStep(viewModel, setViewModel);
  }
};

export function MetrcLinkProductWizard({
  product,
  show,
  onClose,
  onSubmit
}: MetrcLinkProductWizardProps) {
  const api = useAPI();
  const [viewModel, setViewModel] = useState(() => MetrcProductLinkViewModel.initialize(api, product));
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const {successToast, errorToast} = useOrdoToasts();

  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const fetchMetrcProductMappings = async () => {
    const results = await api.getMetrcProductMappings(organization.id, viewModel.selectedProduct.id);
    return results;
  };

  const  fetchMetrcIntegration = async () => {
    const mappings = await api.getMetrcIntegrations(organization.id);
    return mappings.flatMap((mapping) => mapping.licenseNumbers);
  };

  const onModalOpen = async () => {
    showSpinner();
    const [licenses, mappings] = await Promise.all([fetchMetrcIntegration(), fetchMetrcProductMappings()]);
    setViewModel(
      MetrcProductLinkViewModel.initialize(api, product).setLicenses(licenses).setProductMappings(mappings)
    );
    hideSpinner();
  };

  // At the moment, we fetch information everytime the modal is open... we can optimize this but will be a bit trickier to maintain.
  useEffect(() => {
    if (viewModel.error) {
      errorToast(viewModel.error.message);
      return;
    }

    if (show) {
      onModalOpen();
    } else {
      // reset the modal on close
      setViewModel(MetrcProductLinkViewModel.initialize(api, product));
    }

  }, [show, product, viewModel.error]);


  const primaryButtonClick = async (orgId: string) => {
    switch(viewModel.step) {
    case MetrcProductLinkWizardStep.SelectExternalItem:
      showSpinner();
      setViewModel(await viewModel.submitProductMapping(orgId));
      onSubmit();
      hideSpinner();
      successToast('The Metrc item was linked');
      return;
    default:
      showSpinner();
      const resp = await api.getMetrcActiveItems(organization.id, viewModel.selectedLicenseNumber);
      setViewModel(viewModel.setMetrcItems(resp).setSelectItemStep());
      hideSpinner();
    }
  };

  const modalHeader = (<>
    <div className="metrc-sync-button__logo">
      <img role="presentation" className="metrc-icon" alt="metrc-icon" src={metrcLogo}/>
    </div>
    <div className='metrc-sync-button__text'>Metrc Item Link</div>
  </>);

  const metrcIntegrationModalHeader = (
    <div>
      <div className="metrc-integration-header">
        {modalHeader}
      </div>

      <span className="metrc-integration-header-text">{viewModel.getSubtitle()}</span>
    </div>
  );

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoModal
        className='metrc-product-link-modal'
        size={viewModel.getModalSize()}
        actionText={viewModel.getPrimaryButtonText()}
        title={metrcIntegrationModalHeader}
        onClose={onClose}
        disableCTA={!viewModel.canProceed()}
        onSubmit={() => primaryButtonClick(organization.id)}
        show={show}>
        { renderContent(viewModel, setViewModel) }
      </OrdoModal>
    </OrdoSpinner>
  );
}

