import React, {useState} from 'react';
import {SelectableOption} from './searchable-dropdown/selectableOption';
import OrdoSearchableDropdown from './searchable-dropdown/OrdoSearchableDropdown';
import {ClickableTag} from './ClickableTag';
import {GRAY_400} from '../../../constants';
import '../../../scss/ordo/multi-selector-with-tags.scss';


const dropdownHeight = '2em';
const dropdownStyles = {
  control: {
    border: `1px solid ${GRAY_400}`,
    boxShadow: 'none',
    height: dropdownHeight,
    minHeight: dropdownHeight,
    '&:hover': {},
  },
  valueContainer: {
    height: dropdownHeight,
    minHeight: dropdownHeight,
    paddingTop: '0',
    lineHeight: 1
  },
  indicatorsContainer: {
    minHeight: dropdownHeight,
    height: dropdownHeight
  },
};

type MultiSelectorWithTagsProps<T> = {
  options: SelectableOption<T>[],
  selectedOptions: SelectableOption<T>[],
  onOptionSelected: (option: T) => void,
  onOptionRemoved: (option: T) => void,
  placeholder: string,
  displayAmount?: number
};
export const MultiSelectorWithTags = <T extends any>({
  options,
  selectedOptions,
  onOptionSelected,
  onOptionRemoved,
  placeholder,
  displayAmount
}: MultiSelectorWithTagsProps<T>) => {
  const [showAll, setShowAll] = useState(false);

  const displayedSelectedOptions = (displayAmount && !showAll) ? selectedOptions.slice(0, displayAmount) : selectedOptions;
  const showDisplayToggle = displayAmount && displayAmount < selectedOptions.length;
  return <div className='multi-selector-with-tags'>
    <OrdoSearchableDropdown placeholder={placeholder}
      options={options} selectedOption={null}
      onChangeSelectedOption={(selectedOption) => onOptionSelected(selectedOption.value)}
      addedStyles={dropdownStyles} selectMultipleOptionsAtOnce
    />
    <div className="multi-selector-with-tags__selected">
      {displayedSelectedOptions.map((selectedOption) => <ClickableTag data={selectedOption} key={selectedOption.label}
        onClick={() => onOptionRemoved(selectedOption.value)}/>)}
      {showDisplayToggle && <div className="show-toggle" role="presentation"
        onClick={() => setShowAll(!showAll)}> {showAll ? 'show less' : '... show more'}</div>}
    </div>
  </div>;
};