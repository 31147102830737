import React, { useContext, useState, useEffect } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import OrdoModal from '../common/OrdoModal';
import { API } from '../../../lib/api/ordoApi';
import {UserSessionContext} from '../../../context/UserSessionContext';
import '../../../scss/ordo/accountsPage/assignment.scss';
import '../../../scss/ordo/accountsPage/accounts-page.scss';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import { RepAssignment } from '../../../models/Assignment';
import EditRepAssignmentViewModel from '../../../application-models/EditRepAssignmentViewModel';
import OrdoButton from '../common/OrdoButton';
import {PRIMARY_COLOR} from '../../../constants';
import Account from '../../../models/Account';


export type RepAssignmentModalProps = {
  api: API
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  assignment: RepAssignment | undefined
};

const renderRep = (
  assignment: RepAssignment | undefined
) => {
  if (!assignment) return (<div/>);
  return (
    <div>
     Rep: { assignment.member?.user!.firstName } { assignment.member?.user!.lastName }
    </div>
  );
};

const renderSelectBrand = (
  vm: EditRepAssignmentViewModel,
  onClick: (brandId: string) => void
) => {

  const brands = vm.assignment?.accessibleBrands.map((brand, i) => {
    const className = !brand.disabled ?
      'brand-logo-container selected' : 'brand-logo-container';

    return (
      <div key={brand.id} className={className} tabIndex={i} role='button' onClick={ () => onClick(brand.id) } onKeyDown={ () => onClick(brand.id)} >
        <img alt='brand' src={brand.logoImageURL} className='rounded-lg'/>
      </div>
    );
  });
  return (
    <div>
      { brands }
      <p> New brands are enabled by default </p>
    </div>
  );
};

export const EditRepAssignmentModal = (props: RepAssignmentModalProps) => {
  const {currentOrganization } = useContext(UserSessionContext);
  const curOrgId = currentOrganization()?.id || '';
  const { api, isOpen, onClose, onSubmit, assignment } = props;
  const [viewModel, setViewModel] = useState(EditRepAssignmentViewModel.empty(api, curOrgId));

  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();


  const fetchData = async () => {
    const initializedViewModel = await EditRepAssignmentViewModel.initialize(api, curOrgId, assignment);
    setViewModel(initializedViewModel);
  };

  useEffect(() => {
    fetchData();
  }, [assignment]);

  const submit = async () => {
    showSpinner();
    if (viewModel.assignment) {
      const updatedViewModel = await viewModel.submitAccountAssignment();
      setViewModel(updatedViewModel);
    }

    hideSpinner();
    onSubmit();
  };

  const onBrandClick = (brandId: string) => {
    setViewModel(viewModel.toggleBrand(brandId));
  };

  const renderCardInfo = (accountCard: Account) => {
    const amountOfLicenses = accountCard.amountOfLicenses();
    const moreThanOneLicense = amountOfLicenses > 1;
    const firstLocation = accountCard.getLocations()[0];
    return (
      <div>
        {moreThanOneLicense &&
        <>
          <p className="account-address license text-limit">{`${amountOfLicenses} licenses`}</p>
          <p className="account-address license text-limit"><br/></p>
        </>
        }
        {!moreThanOneLicense &&
        <>
          <p className="account-address license text-limit">{firstLocation.location.licenseNumber}</p>
          <p className="account-address text-limit"> {firstLocation.streetAddressLine1} {firstLocation.streetAddressLine2}</p>
          <p className="account-address city text-limit"> {firstLocation.city ? `${firstLocation.city}, ${firstLocation.state} ${firstLocation.zipCode}` : `${firstLocation.state} ${firstLocation.zipCode}` }</p>
        </>
        }
      </div>
    );
  };

  const renderAssignedAccounts = () => {
    const accountsCards: any = [];
    if (!viewModel.assignment) return ( <div/> );

    const visibleAccounts = viewModel.updatedAccounts.filter((account) => !account.removed);
    visibleAccounts.forEach((account, i ) => {
      accountsCards.push((
        <div className='account-card-container card ordo-card-border ordo-shadow' key={account.id}>
          <FontAwesomeIcon className="mb-3 interectable-icon" size="lg" color="#8492A6" icon={faMinusCircle}
            onClick={() => setViewModel(viewModel.removeAssignedAccount(account.id))}/>
          <div className='account-card'>
            <span className="account-name text-limit" data-testid={`account-card-${i}-name`}>{account.name}</span>
            {renderCardInfo(account)}
          </div>
        </div>
      ));
    });

    return (
      <div className='row'>
        { accountsCards }
      </div>
    );
  };

  const renderModalContents = () => {
    const clearAllAssignments = () => setViewModel(viewModel.clearAssignedAccounts());
    return (
      <OrdoSpinner showSpinner={spinnerVisibility}>
        <form
          className='account-modal'
          id='edit-assignment-form'
          onSubmit={(event) => {
            event.preventDefault();
            submit();
          }}>
          { renderRep(viewModel.assignment) }
          <br/>
          <p> Edit Brand Access </p>
          { renderSelectBrand(viewModel, onBrandClick) }
          <p>
            Verify Account Assignments
            <OrdoButton dataTestId="clear-accounts-assignments" disabled={false} text='remove all assignments'
              category="primary" onClick={(event) => {
                // We need to prevent default otherwise the submit function also executes
                event.preventDefault();
                clearAllAssignments();
              }}
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                color: PRIMARY_COLOR,
              }}/>
          </p>

          <div className='account-list'>
            { renderAssignedAccounts() }
          </div>
        </form>
      </OrdoSpinner>
    );
  };

  return (
    <OrdoModal
      formId='edit-assignment-form'
      dialogClassName='assign-account-dialog'
      onClose={onClose}
      show={isOpen}
      actionText='save'
      dataTestId='edit-retail-location-assignment'
      disableCTA={false}
    >
      { renderModalContents() }
    </OrdoModal>
  );
};

export {};
