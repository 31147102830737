import { API } from '../lib/api/ordoApi';
import FormError, {FormFields} from '../errors/form_error';
import BusinessError from '../errors/domain_errors';
import {UserExistsResponse} from '../lib/api/response-types';
import CookieVerifier from '../utils/CookieVerifier';
import { User } from '../models/User';

export default class AcceptInvitationViewModel {
  constructor(
    private api: API,
    private invitationTokenId: string,
    private error: FormError = FormError.withoutError(),
  ) {
  }

  public hasError() {
    return this.error.hasError();
  }

  public hasErrorFor(field: FormFields) {
    return this.error.hasErrorForInput(field);
  }

  public errorMessage() {
    return this.error.errorMessage('unknown');
  }

  public async acceptInvitation() {
    try {
      await this.api.acceptInvitation(this.invitationTokenId);
      return this;
    } catch (error) {
      let formError: FormError;
      if (error instanceof BusinessError) {
        formError = error.formError;
      } else {
        formError = FormError.unknown(error.message);
      }
      return new AcceptInvitationViewModel(this.api, this.invitationTokenId, formError);
    }
  }

  public isLoggedIn(currentUser: User): boolean {
    const cookies = document.cookie;
    return CookieVerifier.isValidSession(cookies, Date.now(), currentUser);
  }

  public async userExists() {
    let user: UserExistsResponse = {exists: false, email: ''};
    try {
      user = await this.api.invitedUserExists(this.invitationTokenId);
    } catch (error) {
      let formError: FormError;
      if (error instanceof BusinessError) {
        formError = error.formError;
        this.error = formError;
      } else {
        this.error = FormError.unknown(error.message);
      }
    }
    return user;
  }
}
