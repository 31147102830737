import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {UserSession} from '../../../models/UserSession';
import Logout from '../Logout';
import { NavigationButtonData } from '../../../models/side-bar/NavigationButtonData';
import { buildSideBarButtons } from './SideBarButton';
import {BackgroundOverlay} from '../common/BackgroundOverlay';
import {OrdoLogo} from '../common/OrdoLogo';
import {appConfig} from '../../../lib/config';

export type ExpandedSideBarProps = {
  collapseSideBar: () => void,
  navButtonsData: NavigationButtonData[],
  userSession: UserSession,
  shouldOpen: boolean
};

const SideBarAnimations = {
  SLIDE_IN : {name: 'slidein', duration: '0.2s'},
  SLIDE_OUT :{name: 'slideout', duration: '0.2s'}
};


export const ExpandedSideBar = ({shouldOpen, collapseSideBar, navButtonsData}: ExpandedSideBarProps) => {
  const [animation, setAnimation] = useState(SideBarAnimations.SLIDE_IN);

  const close = () => {
    setAnimation(SideBarAnimations.SLIDE_OUT);
  };

  const onAnimationEnd = ({animationName}:{animationName: string}) => {
    if(animationName === SideBarAnimations.SLIDE_OUT.name) {
      collapseSideBar();
      setAnimation(SideBarAnimations.SLIDE_IN);
    }
  };

  return shouldOpen ? <>
    <BackgroundOverlay onClick={close}/>
    <div className="expanded-sidebar"
      style={{animationName: animation.name, animationDuration: animation.duration}}
      onAnimationEnd={onAnimationEnd}
    >
      {[
        <div className='sidebar-header' key='header'>
          <div className="sidebar-btn menu-toggle" role="presentation" onClick={close} key='closeMenu' title='close'>
            <FontAwesomeIcon icon={faArrowLeft}/>
          </div>
          <OrdoLogo/>
        </div>,
        ...buildSideBarButtons(navButtonsData, true, collapseSideBar),
        <Logout key='logout'/>,
        <div key='version' style={{display: 'none'}}>Ordo version: <a href={`https://github.com/ordohere/core/commit/${appConfig.ordoVersion}`}>{appConfig.ordoVersion}</a></div>
      ]}
    </div>
  </> :
    <></>;
};
