import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {CartItem, CartItemType} from '../../../../models/order-entry/CartItem';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {ProductWithAvailability} from '../../../../models/productWithAvailability';
import OrdoInput from '../../../components/common/inputs/OrdoInput';
import {MobileOrderEntryCartItem} from './MobileOrderEntryCartItem';

type MobileOrderEntryCartProps = {
  orderEntryViewModel: OrderEntryViewModel,
  onClose: () => void,
  updateCart: (product: ProductWithAvailability, quantity: number, type: CartItemType) => void,
}

export const MobileOrderEntryCart = ({orderEntryViewModel, onClose, updateCart}: MobileOrderEntryCartProps) => {
  const [filteredCartProducts, setFilteredCartProducts] = useState<CartItem[]>(orderEntryViewModel.cart.content());
  const [productSearchedText, setProductSearchedText] = useState('');

  const updateSearchProduct = (searchProduct: string) => {
    const searchText = searchProduct.toLowerCase();
    setProductSearchedText(searchProduct);
    const cartProducts = orderEntryViewModel.cart.content();
    const filteredProducts = searchText === '' ? cartProducts : cartProducts.filter(item => item.product.name.toLowerCase().includes(searchText));
    setFilteredCartProducts(filteredProducts);
  };

  return (<div className="mobile-cart">
    <div className="cart-header">
      <div className="cart-header__title">
        <span className="cart-header__title__text">{`${orderEntryViewModel.cart.amountOfItems()} items added to order`}</span>
        <FontAwesomeIcon
          className="cart-header__title__close-icon"
          icon={faTimesCircle}
          onClick={onClose}
          size="2x"
        />
      </div>
      <div className="cart-header__search-item">
        <OrdoInput
          additionalClassName="search-item-by-name"
          bottomMargin={false}
          inputName='search-item-by-name-input'
          value={productSearchedText}
          placeholder='search product'
          inputSize='large'
          onChange={(value) => updateSearchProduct(value)}
        />
      </div>
    </div>
    <div className="cart-items-container">
      <div className="cart-items-table-header">
        <div className="product-name">
          <span>product</span>
        </div>
        <div className="product-pricing">
          <span>pricing</span>
        </div>
        <div className="product-pricing">
          <span>available</span>
        </div>
      </div>
      <div className='cart-items-list'>
        {filteredCartProducts.filter(item => item.quantity > 0).map((cartItem) =>
          <MobileOrderEntryCartItem
            orderEntryViewModel={orderEntryViewModel}
            updateCart={updateCart}
            cartItem={cartItem}
            key={cartItem.product.id + cartItem.type}
          />)}
      </div>
    </div>
  </div>);
};
