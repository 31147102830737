import { SerializableMoney, Money } from '../Money';
import { LineItemWithTaxResponse } from '../../lib/api/response-types';
import {LineItemWithTax} from './Order';

export type OrderSummaryWithTaxes = {
  summary: OrderSummary,
  lineItemsWithTaxes: LineItemWithTax[],
}

export enum PaymentMethod {
  NONE = 'none',
  CASH_ON_DELIVERY = 'cash on delivery',
  NET_TERMS = 'net terms',
}

export class OrderSummary {
  constructor(// The order total before any discounts
    public total: Money,
    // The total after discounts, but before taxes
    public subtotal: Money,
    // The total including all discounts and taxes
    public grandTotal: Money,
    // taxes... nuff said
    public exciseTax: Money,
    public salesTax: Money,
    // The summation of all discounts on this order
    public discountTotal: Money,
    // The discount that is provided by the user that applies to the whole order
    public wholeOrderDiscount: Money,
    public paymentMethod: PaymentMethod,
    public termPeriod?: number,
    public distributorTaxesEnabled?: boolean) {
  }

  public totalTax(): Money {
    return this.distributorTaxesEnabled ? new Money(0) : this.exciseTax.add(this.salesTax);
  }

  public grandTotalWithTaxesCriteria() {
    return this.distributorTaxesEnabled ? this.subtotal : this.grandTotal;
  }
}

export type SerializableOrderSummary = {
  total: SerializableMoney;
  subtotal: SerializableMoney;
  grandTotal: SerializableMoney;
  exciseTax: SerializableMoney;
  salesTax: SerializableMoney;
  discountTotal: SerializableMoney;
  wholeOrderDiscount: SerializableMoney;
  paymentMethod: PaymentMethod;
  termPeriod?: number;
  distributorTaxesEnabled?: boolean;
}

export function OrderSummaryFrom(input: SerializableOrderSummary): OrderSummary {
  return new OrderSummary(
    new Money(input.total.amount),
    new Money(input.subtotal.amount),
    new Money(input.grandTotal.amount),
    new Money(input.exciseTax.amount),
    new Money(input.salesTax.amount),
    new Money(input.discountTotal.amount),
    new Money(input.wholeOrderDiscount.amount),
    input.paymentMethod,
    input.termPeriod,
    input.distributorTaxesEnabled,);
}

export function lineItemsWithTaxesFrom(lineItems: LineItemWithTaxResponse[]): LineItemWithTax[] {
  return lineItems.map((lineItem: LineItemWithTaxResponse) => {
    const exciseTax = Money.FromSerializable(lineItem.exciseTax);
    const salesTax = Money.FromSerializable(lineItem.salesTax);
    const discountCurrencyAmount = Money.FromSerializable(lineItem.discountCurrencyAmount);
    const price = Money.FromSerializable(lineItem.price);

    return ({
      id: lineItem.id!,
      productId: lineItem.productId,
      quantity: lineItem.quantity,
      discountPercentage: lineItem.discountPercentage,
      discountType: lineItem.discountType,
      sample: lineItem.sample,
      price: price,
      discountCurrencyAmount: discountCurrencyAmount,
      exciseTax: exciseTax,
      salesTax: salesTax,
      type: lineItem.type,
      notes: lineItem.notes || ''
    });
  });
}

export function paymentMethodToString(paymentMethod: string) {
  if (paymentMethod === PaymentMethod.NONE) return 'none';
  if (paymentMethod === PaymentMethod.NET_TERMS) return 'net terms';
  if (paymentMethod === PaymentMethod.CASH_ON_DELIVERY) return 'cash on delivery';
  return '';
}
