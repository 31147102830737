import React from 'react';
import '../../../../scss/ordo/ordo-searchable-dropdown.scss';
import Fuse from 'fuse.js';
import {CustomFilterOption, CustomOptionComponent, CustomOptionComponentProps} from './OrdoSearchableDropdown';
import {SelectableOption} from './selectableOption';

export const toInformativeSelectableOption = <T extends Object>(label: string, subTitle: string, info: string, value: T, disabled: boolean = false, disabledText: string = '') => {
  return {
    label: label,
    subTitle: subTitle,
    info: info,
    value: value,
    isSelected: false,
    disabled: disabled,
    disabledText: disabledText
  } as InformativeSelectableOption<T>;
};

// NOTE: we are not using next function because it takes a lot of time to process with options length > 100.
// We should fix it.
// That said, I want to leave this as an example on how to set customFilterOption to the OrdoSearchableDropdown
// it should be: customFilterOption={filterInformativeSelectableOption}
export const filterInformativeSelectableOption: CustomFilterOption =
  <T extends Object>(candidate: { data: InformativeSelectableOption<T> }, input: string, options: InformativeSelectableOption<T>[]) => {
    // Reference: https://react-select.com/advanced#custom-filter-logic
    if (input) {
      const searcher = new Fuse(options,
        { keys: ['title'],
          threshold: 0.0,
          ignoreLocation: true,
          findAllMatches: true,
        });
      const searchResults = searcher.search(input);
      const titles = searchResults.map(sr => sr.item.label);
      return titles.some(title =>
      // NOTE: It would be better to do kind of a fussy search here:
        title === candidate.data.label
      );
    }
    return true;
  };

export type InformativeSelectableOption<T> = {
  subTitle: string,
  info: string,
  disabled: boolean,
  disabledText: string,
} & SelectableOption<T>

type InformativeSelectableOptionProps<T> = {
  selectableOption: InformativeSelectableOption<T>,
  innerProps: any,
};

const InformativeOption = <T extends any>({selectableOption, innerProps, isSelected}: InformativeSelectableOptionProps<T> & { isSelected: boolean }) => {
  const props = {...innerProps};
  const optionDisabled = selectableOption.disabled;
  if(optionDisabled) props.onClick = ()=>{};

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...props} className={`option ${isSelected ? 'selected-option' : 'not-selected-option menu-option'} ${optionDisabled ? 'disabled-option' : ''}`}>
    <div className="option__title"> {selectableOption.label} {optionDisabled && <span className='option__title__disabled-text'>{selectableOption.disabledText}</span>}</div>
    <div className='option__info'>
      {selectableOption.subTitle && <div className="option__info__sub-title"> {selectableOption.subTitle}</div>}
      <div className="option__info__info"> {selectableOption.info} </div>
    </div>
  </div>;
};

export const InformativeMenuOption: CustomOptionComponent = ({innerProps, data}: CustomOptionComponentProps) =>
  <InformativeOption selectableOption={data} innerProps={innerProps} isSelected={false}/>;

export const InformativeSelectedOption: CustomOptionComponent = ({innerProps, data}: CustomOptionComponentProps) =>
  <InformativeOption selectableOption={data} innerProps={innerProps} isSelected/>;

