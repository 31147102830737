import React, {CSSProperties, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import OrdoButton from '../common/OrdoButton';
import OrdoNumberInput from '../common/inputs/OrdoNumberInput';
import '../../../scss/ordo/order-entry-page.scss';
import { CartItemType } from '../../../models/order-entry/CartItem';

type AddCartProps = {
  dataTestIdSuffix: string,
  buttonText: string,
  inputLabel: string,
  updateOrder: (newAmount: number) => void,
  emptyOrders: boolean,
  ordersAmount: number,
  removeOrder: () => void,
  maxAmount?: number,
  style?: CSSProperties,
  stylesForButton?: CSSProperties,
  page: 'list' | 'cart',
  productType: CartItemType,
  productId: string,
  displayArrows?: boolean,
  displayInvalidMessage?: boolean,
  productIsDeleted?: boolean
};

export function addToCartButtonDataTestId(inputKey: string, dataTestIdSuffix:string){
  return `${inputKey}-${dataTestIdSuffix}`;
}

export function addToCartUniqueKey(productId: string, page: 'list' | 'cart', productType: CartItemType) {
  return productType + productId + page;
}

export const AddToCart = ({
  dataTestIdSuffix,
  buttonText,
  inputLabel,
  updateOrder,
  emptyOrders,
  ordersAmount,
  removeOrder,
  maxAmount,
  style = {},
  productId,
  productType,
  page,
  stylesForButton = {},
  displayArrows = true,
  displayInvalidMessage = true,
  productIsDeleted = false
}: AddCartProps) => {
  const [value, setValue] = useState(ordersAmount);
  useEffect(()=> setValue(ordersAmount), [ordersAmount]);

  const validMaxAmount = !Number.isNaN(maxAmount);
  const uniqueKey = addToCartUniqueKey(productId, page, productType);

  const invalidMessage = () => {
    if(displayInvalidMessage) {
      if(productIsDeleted) {
        return 'product no longer available';
      }
      return 'exceeded stock';
    }
    return '';
  };

  return <div role='presentation' className="add-to-cart" style={style}
    onBlur={()=> {
      updateOrder(value);
    }}
    onKeyPress={ (event) => {
      if(event.key === 'Enter') updateOrder(value);
    }}
  >
    {emptyOrders ?
      <OrdoButton disabled={productIsDeleted}
        style={stylesForButton}
        text={buttonText}
        category='primary'
        onClick={() => {
          updateOrder(1);
        }}
        dataTestId={addToCartButtonDataTestId(uniqueKey, dataTestIdSuffix)}
      /> :
      <div className='add-to-cart__input'>
        <OrdoNumberInput label={inputLabel} value={value}
          onChange={setValue}
          onButtonClick={updateOrder}
          min={0}
          displayArrows={displayArrows}
          isValid={!(validMaxAmount && (value > maxAmount!)) && !productIsDeleted}
          invalidMessage={invalidMessage()}
          inputKey={uniqueKey}
        />
        <FontAwesomeIcon
          className='remove-order'
          data-testid={`remove-${addToCartButtonDataTestId(uniqueKey, dataTestIdSuffix)}`}
          size='lg'
          icon={faTrashAlt}
          onClick={removeOrder}
        />
      </div>
    }
  </div>;
};
