import axios, {AxiosResponse} from 'axios';
import {User} from '../../models/User';
import {AuthService} from '../../services/auth_service';
import BusinessError from '../../errors/domain_errors';
import OrganizationRegistration from '../../models/organizationRegistration';
import FormError, {OrganizationFormFields, ProductFormFields, UserFormFields} from '../../errors/form_error';
import UserRegistration, {UserRegistrationInput} from '../../models/userRegistration';
import {Registration} from '../../models/registration';
import {ProfileDataWithUser} from '../../models/ProfileData';
import {Organization} from '../../models/Organization';
import {Brand} from '../../models/Brand';
import {
  AccountLocationResponse,
  AccountLocationsResponse,
  AccountResponse,
  AccountsResponse,
  AccountTimelineResponse,
  AccountWithContactsResponse,
  ActivityItemResponse,
  ActivityResponse,
  ActivityTemplateWithCountResponse,
  AllocationResponse,
  BrandResponse,
  ContactResponse,
  MetrcItemResponse,
  OrderResponse,
  MetrcItemsResponse,
  NoteResponse,
  OrderSummaryResponse,
  OrganizationLicensesResponse,
  OrganizationResponse,
  PendingPaymentOrderResponse,
  PipelineDataResponse,
  ProductMappingResponse,
  ProductMappingsResponse,
  ProductResponse,
  ProfileResponse,
  RepAssignmentResponse,
  SyncInventoryResponse,
  TierResponse,
  UserExistsResponse,
  UserSessionResponse, UserResponse, AccountRepAssignmentResponse, ContactWithAccountNameResponse
} from './response-types';
import InvalidField from '../../errors/invalid_field';
import {Role} from '../../models/Roles';
import {
  CheckoutOrderSummaryRequest,
  ContactRequestInput,
  CreateAccountAssignmentRequest,
  MappingSourceName
} from './request-types';
import {OrdoLicenseType, OrganizationLicense} from '../../models/OrganizationLicense';
import Account, {AccountData, AccountInfo, AccountLocation} from '../../models/Account';
import {UserSession} from '../../models/UserSession';
import Product from '../../models/Product';
import ProductCategory from '../../models/ProductCategory';
import Batch, {BatchInput} from '../../models/Batch';
import {ProductFormInput} from '../../application-models/ProductFormViewModel';
import {AccessibleBrand, RepAssignment, RepAssignmentIds} from '../../models/Assignment';
import {AllocationCollection} from '../../models/allocation/allocationCollection';
import {Allocation} from '../../models/allocation/allocation';
import {ProductWithAvailability} from '../../models/productWithAvailability';
import {EditOrderInput, OrderInput, OrderStatus, SubmitTrackedOrderInput} from '../../models/order-entry/Order';
import Contact from '../../models/order-entry/Contact';
import {OrderError} from '../../errors/order_error';
import {BusinessOrderError} from '../../errors/domain_order_errors';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import {Money} from '../../models/Money';
import {lineItemsWithTaxesFrom, OrderSummaryFrom, OrderSummaryWithTaxes} from '../../models/order-entry/Summary';
import {Member} from '../../models/member/Member';
import {ActualMember} from '../../models/member/ActualMember';
import {CreateOrganizationLicenseInput} from '../../application-models/organization-page/CreateOrganizationLicenseViewModel';
import {
  AccountPriority,
  AccountStatus,
  AccountStatusAux,
  PipelineData, SalesActivityAccount, SalesActivityOrder,
} from '../../pages/sales-activity/salesActivityTypes';
import {Note, NoteInput} from '../../models/Note';
import {
  Activity,
  ActivityItem,
  ActivityTemplate,
  ActivityTemplateWithCount,
  ExternalEmailActivity
} from '../../models/sales-activity/Activity';
import {ActivityInput} from '../../application-models/sales-activity/ActivityViewModel';
import {ActivityTypeName, convertToBackendActivityType} from '../../models/sales-activity/ActivityTypeName';
import {MetrcIntegration} from '../../pages/profile/compliance/MetrcIntegrationRow';
import {SyncInventorySummary} from '../../application-models/metrc-integration/SyncInventorySummary';
import {appConfig} from '../config';
import SubscriptionRegistration from '../../models/subscriptionRegistration';
import {Subscription} from '../../models/Subscription';
import {AccountActivityEntity} from '../../models/AccountActivity';
import {
  AccountTimeline,
  ActivityTimelineItem,
  ExternalEmailActivityTimelineItem,
  NoteTimelineItem,
  OrderTimelineItem
} from '../../models/AccountTimeline';
import {FlourishIntegration} from '../../pages/profile/compliance/FlourishIntegrationRow';
import {Tier} from '../../models/custom-pricing-tiers/Tier';
import {OrderPayment} from '../../models/order-payment/OrderPayment';
import {ActivityQuestion} from '../../models/sales-activity/ActivityQuestion';
import {
  ACTIVITY_RESPONSE_FILE_IDENTIFIER,
  ActivityCompletionResponse
} from '../../models/activity/responses/ActivityCompletionResponse';
import {PendingPaymentOrder} from '../../models/order-payment/PendingPaymentOrder';
import {NabisIntegration} from '../../pages/profile/compliance/NabisIntegrationRow';
import {OrdoDomainConverter} from './domainConverters';
import {AccountWithContactsAndSalesReps} from '../../models/AccountWithContactsAndSalesReps';
import {AccountRepAssignment} from '../../models/AccountRepAssignment';
import {ContactWithAccountName} from '../../pages/contacts/ContactsPage';

const axiosInstance = axios.create({
  baseURL: appConfig.apiUrl,
  responseType: 'json',
  withCredentials: true,
  timeout: 60_000
});

export type UserWithPassword = {
  user: User,
  password: string,
}

export type UserInformation = {
  firstName: string,
  lastName: string,
  phone: string,
}

export type PaginationFilter = {
  amount: number,
  offset?: number,
  cursor?: string,
}

export type OrderFilter = {
  orderNumber?: string,
  orderAccountName?: string,
  accountIds?: string[],
  orderStatuses?: string[],
  salesReps?: string[]
}

export type ContactsFilter = {
  contactNameOrTitle?: string
  accountIds?: string[]
  salesReps?: string[]
}

export interface API {
  register(userInput: UserRegistrationInput, password: string): Promise<UserSession>;

  login(email: string, password: string): Promise<UserSession>;

  logout(): Promise<void>;

  registerCompany(companyRegistration: OrganizationRegistration, subscriptionRegistration: SubscriptionRegistration): Promise<Organization>;

  registerUserAndCompany(userRegistration: UserRegistration, companyRegistration: OrganizationRegistration, subscriptionRegistration: SubscriptionRegistration): Promise<Registration>;

  userProfile(): Promise<ProfileDataWithUser>;

  editUser(updatedUser: UserInformation, userId: string): Promise<void>;

  changeCurrentOrganization(organization: Organization): Promise<UserSession>;

  changeUserRole(organization: Organization, member: Member, role: Role): Promise<Organization>;

  createOrganizationLicense(orgId: string, organizationLicenseData: CreateOrganizationLicenseInput): Promise<void>;

  getOrganizationLicenses(orgId: string): Promise<OrganizationLicense[]>;

  getDistributorAndMicrobusinessLicenses(orgId: string): Promise<OrganizationLicense[]>;

  createBrand(orgId: string, name: string, logo: File): Promise<Brand>;

  getOrganization(orgId: string): Promise<Organization>;

  editOrganizationName(orgId: string, newName: string): Promise<Organization>;

  sendInvitations(organizationId: string, emails: Array<string>): Promise<any>;

  acceptInvitation(invitationTokenId: string, user?: UserWithPassword): Promise<UserSession>;

  declineInvitation(invitationTokenId: string): Promise<void>;

  invitedUserExists(invitationTokenId: string): Promise<UserExistsResponse>;

  joinInvitedUser(userRegistration: UserRegistration, invitationTokenId: string): Promise<UserSession>;

  getProducts(orgId: string): Promise<Product[]>;

  getProduct(orgId: string, productId: string): Promise<Product>;

  getBrandsForOrg(orgId: string): Promise<Brand[]>;

  getAccountsLocationsForUser(orgId: string, includeUnassigned?: boolean): Promise<AccountLocation[]>;

  getAllActiveAccountsWithLocations(orgId: string): Promise<Account[]>;

  getAssignedAccountsForDropdown(orgId: string): Promise<Account[]>;

  assignAccounts(orgId: string, req: CreateAccountAssignmentRequest): Promise<void>;

  replaceAssignments(orgId: string, req: CreateAccountAssignmentRequest): Promise<void>;

  getAssignments(org: Organization, accounts: Account[]): Promise<RepAssignment[]>;

  createProduct(orgId: string, productInformation: ProductFormInput, productImage?: File): Promise<Organization>;

  editProduct(orgId: string, productId: string, productInformation: ProductFormInput, productImage?: File): Promise<Organization>;

  deleteProduct(organizationId: string, productId: string): Promise<void>;

  getCategories(organizationId: string): Promise<ProductCategory[]>;

  updateBatch(organizationId: string, batch: BatchInput): Promise<Batch>;

  addBatch(organization: Organization, batch: Batch): Promise<void>;

  deleteBatch(organizationId: string, batch: Batch): Promise<void>;

  validateRoutePermissions(redirectRoute: string, userSession: UserSession): Promise<boolean>;

  getAllocations(orgId: string, product: Product, distributorOrganizationLicenseId: string): Promise<AllocationCollection>;

  bulkCreateOrUpdateAllocations(orgId: string, productId: string, allocations: AllocationCollection, distributorOrganizationLicenseId: string): Promise<void>;

  getProductsWithAvailability(orgId: string, distributorOrganizationLicenseId: string, accountId?: string): Promise<ProductWithAvailability[]>;

  getAccountContacts(organizationId: string, accountId: string): Promise<Contact[]>;

  getAccountNotes(organizationId: string, accountId: string): Promise<Note[]>;

  getAccountActivities(organizationId: string, accountId: string): Promise<(Activity | ExternalEmailActivity)[]>;

  submitOrder(orgId: string, orderInput: OrderInput): Promise<void>;

  submitTrackedOrder(orgId: string, orderInput: SubmitTrackedOrderInput, invoice: File | undefined): Promise<void>;

  editTrackedOrder(orgId: string, orderInput: SubmitTrackedOrderInput, orderId: string, invoice: File | undefined): Promise<void>;

  createContact(data: ContactRequestInput, organizationId?: string): Promise<Contact>;

  updateContact(data: ContactRequestInput, contactId: string, accountId: string, organizationId?: string): Promise<Contact>;

  getOrderSummary(orgId: string, req: CheckoutOrderSummaryRequest): Promise<OrderSummaryWithTaxes>;

  getCurrentUserOrders(orgId: string, page: number, ordersPerPage: number, filter: OrderFilter): Promise<{data: OrderWithCurrentVersion[], total: number}>;

  getOrganizationContacts(organizationId: string, page: number, contactsPerPage: number, filter: ContactsFilter): Promise<{data: ContactWithAccountName[], total: number}>;
  getCurrentUserAccountsWithOrders(orgId: string): Promise<any[]>;

  updateOrderStatus(orgId: string, orderId: string, status: OrderStatus): Promise<OrderWithCurrentVersion>;

  getSalesActivity(orgId: string): Promise<PipelineData>;

  getAssignmentsForSalesActivity(orgId: string): Promise<RepAssignmentIds[]>;

  getSalesActivityFilteredOrders(orgId: string, page: number, ordersPerPage: number, filter: OrderFilter): Promise<{data: SalesActivityOrder[], total: number}>;

  getSalesActivityNonQualifiedAccounts(orgId: string, page: number, ordersPerPage: number): Promise<{data: SalesActivityAccount[], total: number}>;

  getSalesActivityLostAccounts(orgId: string, page: number, ordersPerPage: number): Promise<{data: SalesActivityAccount[], total: number}>;

  getSalesActivityProspectAccounts(orgId: string, page: number, ordersPerPage: number): Promise<{data: SalesActivityAccount[], total: number}>;

  getSalesActivityVerifiedAndStaleAccounts(orgId: string, page: number, ordersPerPage: number): Promise<{data: { verifiedAccounts: SalesActivityAccount[], staleAccounts: SalesActivityAccount[]}, total: number}>;

  getOrder(orgId: string, orderId: string): Promise<OrderWithCurrentVersion>;

  editOrder(orgId: string, order: EditOrderInput, orderId: string): Promise<void>;

  updateAccountPriority(orgId: string, accountId: string, priority: number): Promise<void>;

  verifyOrderDelivery(organizationId: string, id: string): Promise<OrderWithCurrentVersion>;

  deleteContact(organizationId: string, contactId: string, accountId: string): Promise<void>;

  createNote(data: NoteInput, organizationId: string, accountId: string): Promise<Note>;

  createActivity(data: ActivityInput, orgId: string, accountId: string): Promise<Activity>;

  createActivitiesForAccounts(data: ActivityInput, orgId: string): Promise<Activity[]>;

  updateNote(noteInput: NoteInput, orgId: string, accountId: string, noteId: string): Promise<Note>;

  updateActivity(activityInput: ActivityInput, orgId: string, accountId: string, activityId: string): Promise<Activity>;

  deleteActivity(orgId: string, accountId: string, activityId: string): Promise<void>;

  deleteNote(orgId: string, accountId: string, noteId: string): Promise<void>;

  deleteInvitation(orgId: string, invitationTokenId: string): Promise<void>;

  deleteOrganizationMember(member: ActualMember, organizationId: string): Promise<void>;

  createMetrcIntegration(orgId: string, metricAPIKey: string, organizationLicenses: OrganizationLicense[]): Promise<MetrcIntegration>;

  createFlourishIntegration(orgId: string, flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string): Promise<FlourishIntegration>;

  createNabisIntegration(orgId: string, nabisAPIKey: string): Promise<NabisIntegration>;

  updateFlourishIntegration(orgId: string, id: string, flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string): Promise<FlourishIntegration>;

  updateNabisIntegration(orgId: string, id: string, nabisAPIKey: string): Promise<NabisIntegration>;

  getMetrcIntegrations(organizationId: string): Promise<MetrcIntegration[]>;

  getFlourishIntegrations(organizationId: string): Promise<FlourishIntegration[]>;

  getNabisIntegrations(organizationId: string): Promise<NabisIntegration[]>;

  deleteMetrcIntegration(orgId: string, apiKey: string): Promise<void>;

  deleteFlourishIntegration(orgId: string, id: string): Promise<void>;

  deleteNabisIntegration(orgId: string, id: string): Promise<void>;

  metrcSyncInventory(orgId:string ,selectedLicenses: OrganizationLicense[]): Promise<SyncInventorySummary>;

  getMetrcProductMappings(orgId: string, productId: string): Promise<ProductMappingResponse[]>;

  setMetrcProductMappings(orgId: string, productId: string, externalId: string, externalLicenseId: string): Promise<void>;

  getMetrcActiveItems(orgId: string, licenseNumber: string): Promise<MetrcItemResponse[]>;

  flourishSyncInventory(orgId:string): Promise<any>;

  verifyEmail(email: string): Promise<UserSession>;

  sendGoogleAuthCode(code: string, orgId: string): Promise<any>;

  removeGoogleAuth(orgId: string): Promise<any>;

  validateUserAuthSyncEnabled(provider: string, orgId: string): Promise<boolean>;

  updateAccountStatus(organizationId: string, accountId: string, status: AccountStatus): Promise<void>;

  updateAccountStatusV2(organizationId: string, accountId: string, status: AccountStatusAux): Promise<void>;

  updateAccountStalePeriod(orgId: string, accountId: string, stalePeriod: number): Promise<Account>;

  updateAccountConversion(organizationId: string, accountId: string, conversion: number): Promise<Account>;

  updateAccountValue(orgId: string, accountId: string, valueToNumber: number): Promise<Account>;

  editBrand(orgId: string, brandId: string, name: string, logoImageURL: string, logo?: File): Promise<Brand>;

  updateSubscription(orgId: string, subscriptionId: string, subscription: Partial<Subscription>): Promise<void>;

  paymentsHistory(orgId: string): Promise<any>;

  linkAccounts(orgId: string, parentAccountId: string, linkingLocationsIds: string[], linkedAccountsName: string): Promise<Account>;

  createActivityTemplate(orgId: string, activityTypeName: string, questions: ActivityQuestion[], iconName: string): Promise<ActivityTemplate>;

  getActivitiesTemplatesForOrg(organizationId: string): Promise<ActivityTemplateWithCount[]>;

  updateActivityTemplate(orgId: string, id: string, activityTemplateInput: Partial<ActivityTemplate>): Promise<ActivityTemplateWithCount>;

  deleteActivityTemplate(orgId: string, activityTemplateId: string): Promise<void>;

  getOrganizationActivities(orgId: string, includeExternalActivities?: boolean): Promise<ActivityItem[]>;

  getAccountsWithContactsAndSalesReps(orgId: string): Promise<AccountWithContactsAndSalesReps[]>;

  getAccount(orgId: string, accountId: string): Promise<Account>;

  updateAccount(organizationId: string, accountId: string, accountData: Partial<AccountData>): Promise<Account>;

  getAccountActivity(organizationId: string, accountId: string): Promise<AccountActivityEntity[]>;

  getAccountAssignments(organizationId: string, accountId: string): Promise<AccountRepAssignment[]>;

  getAccountsTimeline(orgId: string, paginationFilter?: PaginationFilter): Promise<AccountTimeline[]>;

  createCustomPricingTier(organizationId: string, tier: Tier): Promise<Tier>;

  updateCustomPricingTier(organizationId: string, tier: Tier): Promise<Tier>;

  deleteCustomPricingTier(organizationId: string, tier: Tier): Promise<void>;

  getCustomPricingTiers(organizationId: string): Promise<Tier[]>;

  logPayment(organizationId: string, orderId: string, amountPaid: Money): Promise<OrderPayment>;

  getOrderOwedAmount(organizationId: string, orderId: string): Promise<Money>;

  getAccountOwedOrders(organizationId: string, accountId: string): Promise<PendingPaymentOrder[]>;

  editBatch(organizationId: string, batch: Batch): Promise<void>;

  updateLocationAddress(orgId: string, locationId: string, newAddress: AccountLocation): Promise<void>;

  getAccountsThatHaveContacts(organizationId: string): Promise<AccountWithContactsAndSalesReps[]>;
}

export default class OrdoAPI implements API {
  private static ordoAPI: OrdoAPI;
  private authService: AuthService;
  private domainConverter: OrdoDomainConverter;

  public static createAPI(authService: AuthService): OrdoAPI {
    if (!this.ordoAPI) {
      this.ordoAPI = new OrdoAPI(authService);
    }
    return this.ordoAPI;
  }

  private constructor(authService: AuthService) {
    this.authService = authService;
    this.domainConverter = new OrdoDomainConverter();
  }

  public async getAccountsThatHaveContacts(organizationId: string): Promise<AccountWithContactsAndSalesReps[]> {
    const response =  await axiosInstance.get(`/organization/${organizationId}/accountsWithContacts`);
    return response.data.accounts.map((accountResponse: {id: string, name: string}) => {
      return {
        accountId: accountResponse.id,
        name: accountResponse.name,
        contacts: [],
        assignments: []
      };
    });

  }

  public async getAccountOwedOrders(organizationId: string, accountId: string): Promise<PendingPaymentOrder[]> {
    const response =  await axiosInstance.get(`/organization/${organizationId}/accounts/${accountId}/orders/owed`);
    return response.data.pendingPaymentOrders.map((order: PendingPaymentOrderResponse) => this.domainConverter.convertToPendingPaymentOrder(order));
  }

  public async logPayment(organizationId: string, orderId: string, amountPaid: Money): Promise<OrderPayment> {
    const response =  await axiosInstance.post(`/organization/${organizationId}/orders/${orderId}/payment`, {
      amountPaid: amountPaid
    });

    return this.domainConverter.convertToOrderPayment(response.data.payment);
  }

  public async getOrderOwedAmount(organizationId: string, orderId: string): Promise<Money> {
    const response =  await axiosInstance.get(`/organization/${organizationId}/orders/${orderId}/owed`);

    return Money.FromSerializable(response.data.owed);
  }

  public async getAccountActivity(organizationId: string, accountId: string): Promise<AccountActivityEntity[]> {
    const accountActivityResponse = await axiosInstance.get(`/organization/${organizationId}/accounts/${accountId}/activity`);
    return this.domainConverter.convertToAccountActivity(accountActivityResponse.data.accountActivity);
  }

  public async getAccountAssignments(organizationId: string, accountId: string): Promise<AccountRepAssignment[]> {
    const accountAssignmentsResponse = await axiosInstance.get(`/organization/${organizationId}/accounts/${accountId}/assignments`);
    return accountAssignmentsResponse.data.accountAssignments.map((accountAssignment: AccountRepAssignmentResponse) => this.domainConverter.convertToAccountRepAssignment(accountAssignment));
  }

  public async getAccount(orgId: string, accountId: string): Promise<Account> {
    const accountResponse = await axiosInstance.get(`/organization/${orgId}/accounts/${accountId}`);
    return this.domainConverter.convertToAccount(accountResponse.data.account);
  }

  public async getAccountsWithContactsAndSalesReps(orgId: string): Promise<AccountWithContactsAndSalesReps[]> {
    const accountsWithContacts = await axiosInstance.get(`/organization/${orgId}/accountsWithContactsAndSalesReps`);
    return accountsWithContacts.data.accounts.map((account: AccountWithContactsResponse) => {
      return {
        accountId: account.accountId,
        name: account.name,
        contacts: account.contacts.map((contact: ContactResponse) => this.domainConverter.convertToDomainContact(contact)),
        assignments: account.assignments.map((salesRep: UserResponse) => this.domainConverter.convertToDomainUser(salesRep))
      };
    });
  }

  public async deleteActivityTemplate(organizationId: string, activityTemplateId: string): Promise<void> {
    return axiosInstance.delete(`/organization/${organizationId}/activityTemplates/${activityTemplateId}`);
  }

  public async linkAccounts(orgId: string, parentAccountId: string, linkingLocationsIds: string[], linkedAccountsName: string): Promise<Account> {
    const response =  await axiosInstance.post(`/organization/${orgId}/accounts/link`, {
      parentAccountId: parentAccountId,
      linkingLocationsIds: linkingLocationsIds,
      accountName: linkedAccountsName
    });
    return this.domainConverter.convertToAccount(response.data.account);
  }

  public async updateAccountValue(orgId: string, accountId: string, value: number): Promise<Account> {
    return this.updateAccountInfo(orgId, accountId, {value: value});
  }

  public async updateAccountConversion(orgId: string, accountId: string, conversion: number): Promise<Account> {
    return this.updateAccountInfo(orgId, accountId, {conversion: conversion});
  }

  public async validateUserAuthSyncEnabled(provider: string, orgId: string): Promise<boolean> {
    const response = await axiosInstance.get('/user/auths', { params: { provider: provider, orgId: orgId } });
    return response.data.authSyncEnabled;
  }

  public async sendGoogleAuthCode(code: string, orgId: string): Promise<any> {
    return axiosInstance.post('/user/auth', {code: code, orgId: orgId}, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    });
  };

  public async removeGoogleAuth(orgId: string): Promise<any> {
    return axiosInstance.delete(`/user/auth/${orgId}`);
  };

  public async getMetrcIntegrations(organizationId: string): Promise<MetrcIntegration[]> {
    const source = MappingSourceName.METRC;
    const externalLicenseMappings = await axiosInstance.get(`/organization/${organizationId}/externalLicenseMapping?source=${source}`);
    return externalLicenseMappings.data.externalLicenseMappings;
  }

  public async getFlourishIntegrations(organizationId: string): Promise<FlourishIntegration[]> {
    const source = MappingSourceName.FLOURISH;
    const externalLicenseMappings = await axiosInstance.get(`/organization/${organizationId}/externalApiConfig?source=${source}`);
    return externalLicenseMappings.data.externalApiConfigs;
  }

  public async getNabisIntegrations(organizationId: string): Promise<NabisIntegration[]> {
    const source = MappingSourceName.NABIS;
    const externalLicenseMappings = await axiosInstance.get(`/organization/${organizationId}/externalApiConfig?source=${source}`);
    return externalLicenseMappings.data.externalApiConfigs;
  }

  public async createMetrcIntegration(orgId: string, metricAPIKey: string, organizationLicenses: OrganizationLicense[]): Promise<MetrcIntegration> {
    const licenseNumbers = organizationLicenses.map(orgLicense => orgLicense.license.licenseNumber);
    const body = {apiKey: metricAPIKey, licenseNumbers: licenseNumbers, source: MappingSourceName.METRC};
    const metrcIntegration = await axiosInstance.post(`/organization/${orgId}/externalLicenseMapping`, body);
    return metrcIntegration.data.externalLicenseMappings;
  }

  public async createFlourishIntegration(orgId: string, flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string): Promise<FlourishIntegration> {
    const body = {apiUsername: flourishAPIUsername, apiKey: flourishAPIKey, source: MappingSourceName.FLOURISH, syncInventoryOrItems: syncInventoryOrItems};
    const flourishIntegration = await axiosInstance.post(`/organization/${orgId}/externalApiConfig`, body);
    return flourishIntegration.data.externalApiConfigs;
  }

  public async createNabisIntegration(orgId: string, nabisAPIKey: string): Promise<NabisIntegration> {
    const body = {apiKey: nabisAPIKey, source: MappingSourceName.NABIS};
    const nabisIntegration = await axiosInstance.post(`/organization/${orgId}/externalApiConfig`, body);
    return nabisIntegration.data.externalApiConfigs;
  }

  public async updateFlourishIntegration(orgId: string, id: string, flourishAPIUsername: string, flourishAPIKey: string, syncInventoryOrItems: string): Promise<FlourishIntegration> {
    const body = {id: id, apiUsername: flourishAPIUsername, apiKey: flourishAPIKey, source: MappingSourceName.FLOURISH, syncInventoryOrItems: syncInventoryOrItems};
    const flourishIntegration = await axiosInstance.put(`/organization/${orgId}/externalApiConfig`, body);
    return flourishIntegration.data.externalApiConfigs;
  }

  public async updateNabisIntegration(orgId: string, id: string, nabisAPIKey: string): Promise<NabisIntegration> {
    const body = {id: id, apiKey: nabisAPIKey, source: MappingSourceName.NABIS};
    const nabisIntegration = await axiosInstance.put(`/organization/${orgId}/externalApiConfig`, body);
    return nabisIntegration.data.externalApiConfigs;
  }

  public async deleteOrganizationMember(member: ActualMember, organizationId: string): Promise<void> {
    return axiosInstance.delete(`/organization/${organizationId}/members/${member.getUserId()}`);
  };

  public async deleteContact(organizationId: string, contactId: string, accountId: string): Promise<void> {
    await axiosInstance.delete(`/organization/${organizationId}/account/${accountId}/contacts/${contactId}`);
  }

  public async verifyOrderDelivery(organizationId: string, id: string): Promise<OrderWithCurrentVersion> {
    const orderResponse = await axiosInstance.post(`/organization/${organizationId}/orders/${id}/verifyDelivery`);
    return this.domainConverter.convertToOrderWithCurrentVersion(orderResponse.data.order);
  }

  public async getCurrentUserOrders(orgId: string, page: number, ordersPerPage: number, filter: OrderFilter): Promise<{data: OrderWithCurrentVersion[], total: number}> {
    const filterAsJson = JSON.stringify(filter);
    const ordersResult = await axiosInstance.get(`/organization/${orgId}/orders?page=${page}&pageSize=${ordersPerPage}&filter=${filterAsJson}`);
    const orders = ordersResult.data.orders.map((order: OrderResponse) => this.domainConverter.convertToOrder(order));
    const {total} = ordersResult.data;
    return {data: orders, total: total};
  }

  public async getOrganizationContacts(organizationId: string, page: number, contactsPerPage: number, filter: ContactsFilter): Promise<{data: ContactWithAccountName[], total: number}> {
    const filterAsJson = JSON.stringify(filter);
    const contactsResponses = await axiosInstance.get(`/organization/${organizationId}/contacts?page=${page}&pageSize=${contactsPerPage}&filter=${filterAsJson}`);
    const contacts = contactsResponses.data.contacts.map((contactResponse: ContactWithAccountNameResponse) => this.domainConverter.convertToContactWithAccountName(contactResponse));
    const {total} = contactsResponses.data;
    return {data: contacts, total: total};
  }

  public async getCurrentUserAccountsWithOrders(orgId: string): Promise<any[]> {
    const orders = await axiosInstance.get(`/organization/${orgId}/orders/accounts`);
    return orders.data.accounts;
  }

  public async getAccountContacts(organizationId: string, accountId: string): Promise<Contact[]> {
    const contacts = await axiosInstance.get(`/organization/${organizationId}/account/${accountId}/contacts`);
    return contacts.data.contacts.map((contact: ContactResponse) => this.domainConverter.convertToDomainContact(contact));
  };

  public async getAccountNotes(organizationId: string, accountId: string): Promise<Note[]> {
    const notes = await axiosInstance.get(`/organization/${organizationId}/account/${accountId}/notes`);
    return notes.data.notes.map((note: NoteResponse) => this.domainConverter.convertToNote(note));
  };

  public async getAccountActivities(organizationId: string, accountId: string): Promise<(Activity | ExternalEmailActivity)[]> {
    const activities = await axiosInstance.get(`/organization/${organizationId}/account/${accountId}/activities`);
    return activities.data.activities.map((activity: ActivityResponse) => this.domainConverter.convertToActivity(activity));
  };

  public async addBatch(organization: Organization, batch: Batch): Promise<void> {
    try {
      const formData = new FormData();
      if(batch.coaFile) {
        formData.append('coaDocument', batch.coaFile);
      }
      formData.append('batchId', batch.batchId);
      formData.append('productId', batch.productId);
      formData.append('quantityOfUnits', batch.quantityOfUnits.toString());
      formData.append('distributorOrganizationLicenseId', batch.distributorOrganizationLicenseId);
      if(batch.advertisedTHC) {
        formData.append('advertisedTHC', batch.advertisedTHC.toString());
      }
      if(batch.advertisedCBD) {
        formData.append('advertisedCBD', batch.advertisedCBD.toString());
      }
      if(batch.packagedDate) {
        formData.append('packagedDate', batch.packagedDate.toString());
      }

      await axiosInstance.post(`/organization/${organization.id}/batch`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
    } catch (error) {
      const {field, message} = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async editBatch(organizationId: string, batch: Batch): Promise<void> {
    try {
      const formData = new FormData();
      if(batch.coaFile) {
        formData.append('coaDocument', batch.coaFile);
      }
      formData.append('batchId', batch.batchId);
      formData.append('productId', batch.productId);
      formData.append('quantityOfUnits', batch.quantityOfUnits.toString());
      formData.append('distributorOrganizationLicenseId', batch.distributorOrganizationLicenseId);
      formData.append('coaDocumentURL', batch.coaDocumentURL || '');
      if(batch.advertisedTHC) {
        formData.append('advertisedTHC', batch.advertisedTHC.toString());
      }
      if(batch.advertisedCBD) {
        formData.append('advertisedCBD', batch.advertisedCBD.toString());
      }
      if(batch.packagedDate) {
        formData.append('packagedDate', batch.packagedDate.toString());
      }
      await axiosInstance.put(`/organization/${organizationId}/batch/${batch.id}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
    } catch (error) {
      const {field, message} = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async acceptInvitation(invitationTokenId: string, user?: UserWithPassword): Promise<UserSession> {
    try {
      const userData = user ? {
        firstName: user.user.firstName,
        lastName: user.user.lastName,
        phone: user.user.phone,
        email: user.user.email,
        password: user.password
      } : undefined;
      const response = await axiosInstance.post(`/invite/${invitationTokenId}/accept`, userData);
      const userSessionResponse: UserSessionResponse = response.data;
      return this.domainConverter.convertToDomainUserSession(userSessionResponse);
    } catch (error) {
      const {field, message} = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async declineInvitation(invitationTokenId: string): Promise<void> {
    return axiosInstance.post(`/invite/${invitationTokenId}/decline`);
  }

  public async register(userInput: UserRegistrationInput, password: string): Promise<UserSession> {
    await this.authService.sendVerificationMail(userInput.email);
    await this.doBackendRegistration(userInput, password);
    return this.login(userInput.email, password);
  }

  public async login(email: string, password: string): Promise<UserSession> {
    const idToken = await this.authService.login(email, password);
    const userSessionResponse = await this.doBackendLogin(idToken);
    return this.domainConverter.convertToDomainUserSession(userSessionResponse);
  }

  public async verifyEmail(email: string): Promise<UserSession> {
    const response = await axiosInstance.post('/verifyEmail', {
      email: email,
    });
    const userSessionResponse = response.data;
    return this.domainConverter.convertToDomainUserSession(userSessionResponse);
  }

  public async logout(): Promise<void> {
    await axiosInstance.post('/logout');
  }

  public async userProfile(): Promise<ProfileDataWithUser> {
    const response = await axiosInstance.get('/userProfile');
    const profileResponse = response.data as ProfileResponse;
    return {
      user: this.domainConverter.convertToDomainUser(profileResponse.user),
      role: profileResponse.role,
      emailVerified: profileResponse.emailVerified,
      organizations: profileResponse.organizations.map((organization) => this.domainConverter.convertToDomainOrganization(organization)),
      pendingInvitations: profileResponse.pendingInvitations.map((pendingInvitationResponse) => this.domainConverter.convertToPendingInvitationResponse(pendingInvitationResponse))
    };
  }

  public async editUser(updatedUser: UserInformation) {
    try {
      await axiosInstance.put('/user', {user: updatedUser});
    } catch (error) {
      const {field, message}: { field: UserFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async changeCurrentOrganization(organization: Organization): Promise<UserSession> {
    const response = await axiosInstance.post('/changeCurrentOrganization', {currentOrganizationId: organization.id});
    return this.domainConverter.convertToDomainUserSession(response.data);
  }

  public async changeUserRole(organization: Organization, member: Member, role: Role): Promise<Organization> {
    const response = await axiosInstance.put(`/organization/${organization.id}/update-permission`, {
      userId: member.getUserId(),
      role: role
    });
    const {organization: updatedOrganization} = response.data;
    return updatedOrganization;
  }

  public async getOrganization(orgId: string): Promise<Organization> {
    const response = await axiosInstance.get(`/organization/${orgId}`);
    const {organization}: { organization: OrganizationResponse } = response.data;
    return this.domainConverter.convertToDomainOrganization(organization);
  }

  public async health(): Promise<any> {
    return axiosInstance.get('/health');
  }

  public async registerCompany(organizationRegistration: OrganizationRegistration, subscriptionRegistration: SubscriptionRegistration): Promise<Organization> {
    try {
      const response = await axiosInstance.post('/organization', {
        name: organizationRegistration.name,
        size: organizationRegistration.size,
        state: organizationRegistration.state,
        termsOfUseAccepted: organizationRegistration.termsOfUseAccepted,
        subscription: {
          plan: subscriptionRegistration.selectedPlan.type,
          paymentToken: subscriptionRegistration.paymentToken,
        },
      },{timeout: 60_000});
      return response.data.organization;
    } catch (error) {
      const {field, message}: { field: UserFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async registerUserAndCompany(userRegistration: UserRegistration, companyRegistration: OrganizationRegistration, subscriptionRegistration: SubscriptionRegistration): Promise<Registration> {
    await this.authService.sendVerificationMail(userRegistration.email);
    const registration = await this.doBackendRegisterUserAndCompany(userRegistration, companyRegistration, subscriptionRegistration);
    await this.login(userRegistration.email, userRegistration.password);
    return registration;
  }

  public async createOrganizationLicense(orgId: string, organizationLicenseInput: CreateOrganizationLicenseInput): Promise<void> {
    try {
      const reqBody: CreateOrganizationLicenseInput = organizationLicenseInput;
      await axiosInstance.post(`/organization/${orgId}/license`, reqBody);
    } catch (error) {
      const {field, message}: { field: OrganizationFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async getOrganizationLicenses(orgId: string): Promise<OrganizationLicense[]> {
    try {
      const response = await axiosInstance.get(`/organization/${orgId}/licenses`);
      const licenseResponse: OrganizationLicensesResponse = response.data;
      return licenseResponse.licenses.map((item) => this.domainConverter.convertToOrganizationLicense(item));
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw error;
    }
  }

  public async getDistributorAndMicrobusinessLicenses(orgId: string): Promise<OrganizationLicense[]> {
    try {
      const licenses = await this.getOrganizationLicenses(orgId);
      return licenses.filter((organizationLicense) => [OrdoLicenseType.Distributor, OrdoLicenseType.Microbusiness].includes(organizationLicense.license.ordoType));
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw error;
    }
  }

  public async createBrand(orgId: string, name: string, logo: File): Promise<Brand> {
    try {
      const formData = new FormData();
      formData.append('logo', logo);
      formData.append('name', name);
      const response = await axiosInstance.post(`/organization/${orgId}/brand`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      const {brand} = response.data;
      return brand;
    } catch (error) {
      const {field, message}: { field: OrganizationFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async editBrand(orgId: string, brandId: string, name: string, logoImageURL: string, logo?: File): Promise<Brand> {
    try {
      const formData = new FormData();
      if(logo) {
        formData.append('logo', logo);
      }

      formData.append('name', name);
      formData.append('logoImageURL', logoImageURL);

      const response = await axiosInstance.put(`/organization/${orgId}/brand/${brandId}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      const {brand} = response.data;
      return brand;
    } catch (error) {
      const {field, message}: { field: OrganizationFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async brandsForOrganization(orgId: string): Promise<Brand[]> {
    try {
      const response = await axiosInstance.get(`/organization/${orgId}/brands`);
      const {brands} = response.data as BrandResponse;
      return brands;
    } catch (error) {
      // TODO (mk): Handle error
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw error;
    }
  }

  public async editOrganizationName(orgId: string, newName: string) {
    try {
      const response = await axiosInstance.put(`/organization/${orgId}`, {
        organization: {
          name: newName
        }
      });
      return this.domainConverter.convertToDomainOrganization(response.data.organization);
    } catch (error) {
      const {message}: { message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField('unknown', message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async sendInvitations(orgId: string, emails: Array<string>): Promise<any> {
    return axiosInstance.post(`/organization/${orgId}/invite`, {
      emails: emails
    });
  }

  public async invitedUserExists(_invitationTokenId: string): Promise<UserExistsResponse> {
    const response = await axiosInstance.get(`/userExists/${_invitationTokenId}`);
    return response.data.user;
  }

  public async joinInvitedUser(userRegistration: UserRegistration, invitationTokenId: string): Promise<UserSession> {
    const user = {
      email: userRegistration.email,
      firstName: userRegistration.firstName,
      lastName: userRegistration.lastName,
      phone: userRegistration.phone,
    } as User;

    const userWithPassword = {user: user, password: userRegistration.password};
    return this.acceptInvitation(invitationTokenId, userWithPassword);
  }

  public async getProducts(orgId: string): Promise<Product[]> {
    const response = await axiosInstance.get(`/organization/${orgId}/products`);
    return response.data.products.map((product: ProductResponse) => this.domainConverter.convertToDomainProduct(product));
  }

  public async getProduct(orgId: string, productId: string): Promise<Product> {
    const response = await axiosInstance.get(`/organization/${orgId}/single-product/${productId}`);
    return this.domainConverter.convertToDomainProduct(response.data.product);
  }

  public async getAccountsLocationsForUser(orgId: string, includeUnassigned?: boolean): Promise<AccountLocation[]> {
    try {
      const query = includeUnassigned ? `?all=${includeUnassigned}` : '';
      const resp = await axiosInstance.get(`/organization/${orgId}/locations${query}`);
      const retResp: AccountLocationsResponse = resp.data;
      return retResp.accountLocations.map((accountResp: AccountLocationResponse) => this.domainConverter.convertToAccountLocation(accountResp));
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw new Error('unable to retrieve accounts');
    }
  }

  public async getAssignedAccountsForDropdown(orgId: string,): Promise<Account[]> {
    try {
      const url = `/organization/${orgId}/accounts-only-name-and-id`;
      const resp = await axiosInstance.get(url);
      const retResp: AccountsResponse = resp.data;
      return retResp.accounts.map((accountResp: AccountResponse) => this.domainConverter.convertToAccount(accountResp));
    } catch(error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw new Error('unable to retrieve Accounts');
    }
  }

  public async getAllActiveAccountsWithLocations(orgId: string): Promise<Account[]> {
    try {
      const url = `/organization/${orgId}/accounts-name-id-locations`;
      const resp = await axiosInstance.get(url);
      const retResp: AccountsResponse = resp.data;
      return retResp.accounts.map((accountResp: AccountResponse) => this.domainConverter.convertToAccount(accountResp));
    } catch(error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw new Error('unable to retrieve Accounts');
    }
  }

  public async assignAccounts(orgId: string, req: CreateAccountAssignmentRequest): Promise<void> {
    try {
      await axiosInstance.post(`/organization/${orgId}/assignments`, req);
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw error;
    }
  }

  public async replaceAssignments(orgId: string, req: CreateAccountAssignmentRequest): Promise<void> {
    try {
      await axiosInstance.put(`/organization/${orgId}/assignments`, req);
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw error;
    }
  }

  public async getAssignments(org: Organization, accounts: Account[]): Promise<RepAssignment[]> {
    try {
      const assnResp = await axiosInstance.get(`/organization/${org.id}/assignments`);
      const resp = assnResp.data;
      const repAssignments: RepAssignment[] = resp.assignments
        .filter((a: RepAssignmentResponse) => {
          return !!org.members.find((member) => member.hasUserId(a.userId));
        })
        .map((a: RepAssignmentResponse) => {
          const assignedAccounts = accounts.filter((account) => a.accountsIds.includes(account.id));
          const accessibleBrands: AccessibleBrand[] = org.brands.map((brand) => {
            return {
              ...brand,
              disabled: a.disabledBrandIds.includes(brand.id)
            };
          });
          return {
            accessibleBrands: accessibleBrands,
            accounts: assignedAccounts,
            member: org.members.find((member) => member.hasUserId(a.userId))!,
            orgId: a.orgId
          };
        });
      return repAssignments;
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.error(error);
      throw error;
    }
  }

  public async getCategories(orgId: string): Promise<ProductCategory[]> {
    const response = await axiosInstance.get(`/organization/${orgId}/product/categories`);
    return response.data.productCategories.map((c: { id: string, name: string }) => {
      return new ProductCategory(c.id, c.name);
    });
  }

  public async createProduct(orgId: string, productInformation: ProductFormInput, productImage?: File): Promise<Organization> {

    const formData = this.productForm(orgId, productInformation, productImage);
    try {
      const response = await axiosInstance.post(`/organization/${orgId}/product`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      return response.data.user;
    } catch (error) {
      const {field, message}: { field: ProductFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async editProduct(orgId: string, productId: string, productInformation: ProductFormInput, productImage?: File): Promise<Organization> {
    const formData = this.productForm(orgId, productInformation, productImage);
    try {
      const response = await axiosInstance.put(`/organization/${orgId}/product/${productId}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      return response.data.user;
    } catch (error) {
      const {field, message}: { field: ProductFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async deleteProduct(organizationId: string, productId: string): Promise<void> {
    await axiosInstance.delete(`/organization/${organizationId}/product/${productId}`);
  }

  public async getBrandsForOrg(orgId: string): Promise<Brand[]> {
    const response = await axiosInstance.get(`/organization/${orgId}/brands`);
    return response.data.brands;
  }

  public async updateBatch(organizationId: string, batch: BatchInput): Promise<Batch> {
    try {
      const response = await axiosInstance.put(`/organization/${organizationId}/batch/${batch.id}`, {
        batch: batch
      });
      return response.data.batch;
    } catch (error) {
      throw new Error(error.response.data.message || 'there was a problem updating the batch, please try again later');
    }
  }

  public async deleteBatch(organizationId: string, batch: Batch) {
    try {
      await axiosInstance.delete(`/organization/${organizationId}/batch/${batch.id}`);
    } catch (e) {
      throw new Error(e.response.data.message || 'there was a problem deleting the batch, please try again later');
    }
  }

  public async getAllocations(orgId: string, product: Product, distributorOrganizationLicenseId: string): Promise<AllocationCollection> {
    try {
      const resp = await axiosInstance.get(`/organization/${orgId}/license/${distributorOrganizationLicenseId}/product/${product.id}/allocations`);
      const allocations: Allocation[] = resp.data.allocations.map((allocationResp: AllocationResponse) => this.domainConverter.convertToAllocations(allocationResp));
      return new AllocationCollection(product, ...allocations);
    } catch (error) {
      throw new Error('unable to retrieve allocations');
    }
  }

  public async bulkCreateOrUpdateAllocations(orgId: string, productId: string, allocations: AllocationCollection, distributorOrganizationLicenseId: string): Promise<void> {
    try {
      const allocationsToSend = allocations.toDataTransferObject(orgId);
      await axiosInstance.post(`/organization/${orgId}/license/${distributorOrganizationLicenseId}/product/${productId}/allocations`, {allocations: allocationsToSend});
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  public async validateRoutePermissions(redirectRoute: string, userSession: UserSession): Promise<boolean> {
    const response = await axiosInstance.post('/validateRoute', {
      route: redirectRoute,
      userSession: userSession
    });
    return response.data.validated;
  }

  public async getProductsWithAvailability(orgId: string, distributorOrganizationLicenseId: string, accountId?: string): Promise<ProductWithAvailability[]> {
    const response = await axiosInstance.get(`/organization/${orgId}/license/${distributorOrganizationLicenseId}/products/availability`, {params: {accountId: accountId}});
    const products = response.data.productsWithAvailability;
    return this.domainConverter.convertToProductsWithAvailability(products);
  }

  public async submitOrder(orgId: string, orderInput: OrderInput): Promise<void> {
    try {
      await axiosInstance.post(`/organization/${orgId}/orders`, orderInput, {timeout: 180_000});
    } catch (error) {
      const {field, message}: { field: string, message: string } = error.response.data;
      let orderErrors;
      if (field === 'insufficientStock') {
        const productIds = message.split(';');
        const invalidProducts = productIds.map(pid => {
          return {field: pid, message: `insufficient quantity for product: ${pid}`};
        });
        orderErrors = OrderError.withErrors(invalidProducts);
      } else {
        orderErrors = OrderError.withErrors([{field: field, message: message}]);
      }
      throw new BusinessOrderError(orderErrors, message);
    }
  }

  public async submitTrackedOrder(orgId: string, orderInput: SubmitTrackedOrderInput, invoice: File): Promise<void> {
    const formData = this.trackedOrderForm(orderInput, invoice);

    try {
      await axiosInstance.post(`/organization/${orgId}/orders`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      await this.updateAccountStatus(orgId, orderInput.accountId, AccountStatus.VERIFIED);
    } catch (error) {
      const {field, message}: { field: ProductFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async editTrackedOrder(orgId: string, orderInput: SubmitTrackedOrderInput, orderId: string, invoice: File | undefined): Promise<void> {
    const formData = this.trackedOrderForm(orderInput, invoice);

    try {
      await axiosInstance.put(`/organization/${orgId}/orders/${orderId}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
    } catch (error) {
      const {field, message}: { field: ProductFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  public async getOrderSummary(orgId: string, orderInput: CheckoutOrderSummaryRequest): Promise<OrderSummaryWithTaxes> {
    try {
      const response = await axiosInstance.put(`/organization/${orgId}/orderSummary`, orderInput);
      const {summary, lineItemsWithTaxes} = response.data as OrderSummaryResponse;

      return {
        lineItemsWithTaxes: lineItemsWithTaxesFrom(lineItemsWithTaxes),
        summary: OrderSummaryFrom(summary)
      };
    } catch (err) {
      const {status} = err.response;
      if (status === 400) {
        throw new Error(err.response.data.message);
      } else {
        throw new Error('unknown error fetching the order summary');
      }
    }
  }

  public async createContact(data: ContactRequestInput, organizationId: string): Promise<Contact>{
    const response = await axiosInstance.post(`/organization/${organizationId}/account/${data.accountId}/contact`, data);
    return this.domainConverter.convertToDomainContact(response.data.contact);
  }

  public async updateContact(data: ContactRequestInput, contactId: string, accountId: string, organizationId: string): Promise<Contact>{
    const response = await axiosInstance.put(
      `/organization/${organizationId}/account/${accountId}/contacts/${contactId}`,
      {name: data.name, email: data.email, title: data.title, phone: data.phone, accountId: data.accountId});
    return this.domainConverter.convertToDomainContact(response.data.contact);
  }

  public async updateOrderStatus(orgId: string, orderId: string, status: OrderStatus): Promise<OrderWithCurrentVersion> {
    try {
      const response = await axiosInstance.post(`/organization/${orgId}/orders/${orderId}/status`, {
        status: status,
      });

      return this.domainConverter.convertToOrderWithCurrentVersion(response.data.orderWithLastVersion);
    } catch (e) {
      const {message}: { message: string } = e.response.data;
      throw Error(message);
    }
  }

  public async getSalesActivity(orgId: string): Promise<PipelineData> {
    const response = await axiosInstance.get(`/organization/${orgId}/salesActivity`);
    return this.domainConverter.convertToPipelineData(response.data.pipelineData as PipelineDataResponse);
  }

  public async getAssignmentsForSalesActivity(orgId: string): Promise<RepAssignmentIds[]> {
    const response = await axiosInstance.get(`/organization/${orgId}/assignments`);
    return response.data.assignments.map((assignment: RepAssignmentResponse) => this.domainConverter.convertToRepAssignmentsIds(assignment));
  }

  public async getSalesActivityFilteredOrders(orgId: string, page: number, ordersPerPage: number, filter: OrderFilter): Promise<{data: SalesActivityOrder[], total: number}> {
    const filterAsJson = JSON.stringify(filter);
    const response = await axiosInstance.get(`/organization/${orgId}/orders?page=${page}&pageSize=${ordersPerPage}&filter=${filterAsJson}`);
    const orders = response.data.orders.map((order: OrderResponse) => this.domainConverter.convertToSalesActivityOrder(order.lastVersion, order.model));
    const {total} = response.data;
    return {data: orders, total: total};
  }

  public async getSalesActivityNonQualifiedAccounts(orgId: string, page: number, ordersPerPage: number): Promise<{data: SalesActivityAccount[], total: number}> {
    const response = await axiosInstance.get(`/organization/${orgId}/salesActivity/nonQualifiedAccounts?page=${page}&pageSize=${ordersPerPage}`);
    const accounts = response.data.accounts.map((account: AccountResponse) => this.domainConverter.convertToSalesActivityAccount(account));
    const {total} = response.data;
    return {data: accounts, total: total};
  }

  public async getSalesActivityLostAccounts(orgId: string, page: number, ordersPerPage: number): Promise<{data: SalesActivityAccount[], total: number}> {
    const response = await axiosInstance.get(`/organization/${orgId}/salesActivity/lostAccounts?page=${page}&pageSize=${ordersPerPage}`);
    const accounts = response.data.accounts.map((account: AccountResponse) => this.domainConverter.convertToSalesActivityAccount(account));
    const {total} = response.data;
    return {data: accounts, total: total};
  }

  public async getSalesActivityProspectAccounts(orgId: string, page: number, ordersPerPage: number): Promise<{data: SalesActivityAccount[], total: number}> {
    const response = await axiosInstance.get(`/organization/${orgId}/salesActivity/prospectAccounts?page=${page}&pageSize=${ordersPerPage}`);
    const accounts = response.data.accounts.map((account: AccountResponse) => this.domainConverter.convertToSalesActivityAccount(account));
    const {total} = response.data;
    return {data: accounts, total: total};
  }

  public async getSalesActivityVerifiedAndStaleAccounts(orgId: string, page: number, ordersPerPage: number): Promise<{data: { verifiedAccounts: SalesActivityAccount[], staleAccounts: SalesActivityAccount[]}, total: number}> {
    const response = await axiosInstance.get(`/organization/${orgId}/salesActivity/verifiedAndStaleAccounts?page=${page}&pageSize=${ordersPerPage}`);
    const verifiedAccounts = response.data.verifiedAccounts.map((account: AccountResponse) => this.domainConverter.convertToSalesActivityAccount(account));
    const staleAccounts = response.data.staleAccounts.map((account: AccountResponse) => this.domainConverter.convertToSalesActivityAccount(account));
    const {total} = response.data;
    return {data: {verifiedAccounts: verifiedAccounts, staleAccounts: staleAccounts}, total: total};
  }

  public async getOrder(orgId: string, orderId: string): Promise<OrderWithCurrentVersion> {
    try {
      const response = await axiosInstance.get(`/organization/${orgId}/orders/${orderId}`);
      return this.domainConverter.convertToOrderWithCurrentVersion(response.data.order);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    }
  }

  public async editOrder(orgId: string, order: EditOrderInput, orderId: string): Promise<void> {
    try {
      await axiosInstance.put(`/organization/${orgId}/orders/${orderId}`, order);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    }
  }

  public async updateAccountPriority(orgId: string, accountId: string, priority: AccountPriority) {
    try {
      await axiosInstance.put(`/organization/${orgId}/accounts/${accountId}/priority`, {
        priority: priority
      });
    } catch (err) {
      throw new Error('unknown error updating the account priority');
    }
  }

  public async updateAccount(organizationId: string, accountId: string, accountData: AccountData): Promise<Account> {
    const updatedAccount = await axiosInstance.put(`/organization/${organizationId}/accounts/${accountId}`, {
      accountData: accountData
    });
    return this.domainConverter.convertToAccount(updatedAccount.data.account);
  }

  public async updateAccountStalePeriod(orgId: string, accountId: string, stalePeriod: number) : Promise<Account>{
    return this.updateAccountInfo(orgId, accountId, {stalePeriod: stalePeriod});
  }

  public async createNote(note: NoteInput, orgId: string, accountId: string) {
    try {
      const response = await axiosInstance.post(`/organization/${orgId}/account/${accountId}/notes`, {
        subject: note.title,
        description: note.description,
        contactsIds: note.contacts.map(c => c.id)
      });
      return this.domainConverter.convertToNote(response.data.note);
    } catch (err) {
      throw new Error('there was an error creating the note');
    }
  }

  public async updateNote(note: NoteInput, orgId: string, accountId: string, noteId: string) {
    try {
      const response = await axiosInstance.put(`/organization/${orgId}/account/${accountId}/notes/${noteId}`, {
        subject: note.title,
        description: note.description,
        contactsIds: note.contacts.map(c => c.id)
      });
      return this.domainConverter.convertToNote(response.data.note);
    } catch (err) {
      throw new Error('there was an error creating the note');
    }
  }

  public async createActivity(activity: ActivityInput, orgId: string, accountId: string) {
    try {
      let data : any = {
        description: activity.description,
        type: convertToBackendActivityType(activity.type!),
        date: activity.date,
        time: activity.time,
        contactsIds: activity.contacts.map(c => c.id),
        activityTemplateId: activity.activityTemplate!.id,
        completed: activity.completed,
        responses: activity.responses,
      };
      data = activity.assignedSalesRep ? {...data, assignedSalesRepId: activity.assignedSalesRep.id} : data;
      const formData = this.buildActivityFormData(data);

      const response = await axiosInstance.post(`/organization/${orgId}/account/${accountId}/activities`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      return this.domainConverter.convertToActivity(response.data.activity);
    } catch (err) {
      throw new Error('there was an error creating the activity');
    }
  }

  private buildActivityFormData(data: { date: Date | null;
    accountId?: string,
    accountIds?: string[],
    contactsIds: string[],
    description: string | undefined,
    responses: ActivityCompletionResponse[] | undefined,
    time: Date | undefined, completed: boolean,
    type: number,
    activityTemplateId: string ,
    assignedSalesRepId?: string }) {
    const formData = new FormData();
    this.addNullableFieldToFormData(formData, 'description', data.description);
    formData.append('date', JSON.stringify(data.date));
    this.addNullableFieldToFormData(formData, 'time', JSON.stringify(data.time));
    this.addNullableFieldToFormData(formData, 'type', data.type);
    this.addNullableFieldToFormData(formData, 'accountId', data.accountId);
    this.addNullableFieldToFormData(formData, 'assignedSalesRepId', data.assignedSalesRepId);
    formData.append('contactsIds', JSON.stringify(data.contactsIds));
    formData.append('activityTemplateId', data.activityTemplateId);
    formData.append('completed', data.completed.toString());
    this.addNullableFieldToFormData(formData, 'responses', JSON.stringify(data.responses?.map(r => r.asFormData())));
    this.addNullableFieldToFormData(formData, 'accountIds', JSON.stringify(data.accountIds));
    (data.responses || []).forEach((r => {
      if (r.hasFiles()) {
        r.getFiles().forEach((file: File) => {
          const name = `${ACTIVITY_RESPONSE_FILE_IDENTIFIER}_${r.index}`;
          formData.append(name, file);
        });
      }
    }));
    return formData;
  }

  public async createActivitiesForAccounts(activity: ActivityInput, orgId: string): Promise<Activity[]> {
    try {
      let data: any = {
        description: activity.description,
        type: convertToBackendActivityType(activity.type!),
        date: activity.date,
        time: activity.time,
        contactsIds: activity.contacts.map(c => c.id),
        activityTemplateId: activity.activityTemplate!.id,
        completed: activity.completed,
        accountIds: activity.accountIds,
        responses: activity.responses
      };

      data = activity.assignedSalesRep ? {...data, assignedSalesRepId: activity.assignedSalesRep.id} : data;
      const formData = this.buildActivityFormData(data);
      const response = await axiosInstance.post(`/organization/${orgId}/activities`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      return response.data.activities.map((activityResponse: ActivityResponse) => this.domainConverter.convertToActivity(activityResponse));
    } catch (err) {
      throw new Error('there was an error creating the activities');
    }
  }

  public async updateActivity(activityInput: ActivityInput, orgId: string, accountId: string, activityId: string): Promise<Activity> {
    try {
      const data = {
        description: activityInput.description,
        type: convertToBackendActivityType(activityInput.type!),
        date: activityInput.date,
        time: activityInput.time,
        contactsIds: activityInput.contacts.map(c => c.id),
        activityTemplateId: activityInput.activityTemplate!.id,
        completed: activityInput.completed,
        accountId: activityInput.accountId,
        responses: activityInput.responses,
        assignedSalesRepId: activityInput.assignedSalesRep?.id
      };
      const formData = this.buildActivityFormData(data);

      const response = await axiosInstance.put(`/organization/${orgId}/account/${accountId}/activities/${activityId}`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
      return this.domainConverter.convertToActivity(response.data.activity);
    } catch (err) {
      throw new Error('there was an error updating the activity');
    }
  }

  public async deleteActivity(orgId: string, accountId: string, activityId: string): Promise<void> {
    await axiosInstance.delete(`/organization/${orgId}/account/${accountId}/activities/${activityId}`);
  }

  public async getOrganizationActivities(orgId: string, includeExternalActivities?: boolean): Promise<ActivityItem[]> {
    const activitiesResponse = await axiosInstance.get(`/organization/${orgId}/activities?includeExternalActivities=${includeExternalActivities}`);
    return activitiesResponse.data.activities.map((activity: ActivityItemResponse) => this.domainConverter.convertToActivityItem(activity));
  }

  public async deleteNote(orgId: string, accountId: string, noteId: string): Promise<void> {
    await axiosInstance.delete(`/organization/${orgId}/account/${accountId}/notes/${noteId}`);
  }

  public async deleteInvitation(orgId: string, invitationTokenId: string): Promise<void> {
    return axiosInstance.delete(`/organization/${orgId}/invite/${invitationTokenId}`);
  }

  public async deleteMetrcIntegration(orgId: string, apiKey: string): Promise<void> {
    const source = MappingSourceName.METRC;
    return axiosInstance.delete(`/organization/${orgId}/externalLicenseMapping/${source}/${apiKey}`);
  };

  public async deleteFlourishIntegration(orgId: string, id: string): Promise<void> {
    const source = MappingSourceName.FLOURISH;
    return axiosInstance.delete(`/organization/${orgId}/externalApiConfig/${id}?source=${source}`);
  };

  public async deleteNabisIntegration(orgId: string, id: string): Promise<void> {
    const source = MappingSourceName.NABIS;
    return axiosInstance.delete(`/organization/${orgId}/externalApiConfig/${id}?source=${source}`);
  };

  public async metrcSyncInventory(orgId: string, selectedLicenses: OrganizationLicense[]): Promise<SyncInventorySummary> {
    return this.syncExternalInventory(orgId, selectedLicenses, MappingSourceName.METRC);
  }

  public async flourishSyncInventory(orgId:string): Promise<any> {
    return this.syncExternalInventory(orgId, [], MappingSourceName.FLOURISH);
  }

  private async syncExternalInventory(orgId: string, selectedLicenses: OrganizationLicense[], source: MappingSourceName): Promise<SyncInventorySummary> {
    try {
      const response = await axiosInstance.post<SyncInventoryResponse>(`/organization/${orgId}/inventory/${source}/sync`, {
        licenseNumbers: selectedLicenses.map(orgLicense => orgLicense.license.licenseNumber),
      }, {timeout: 300_000});
      const { summary } = response.data;
      return summary;
    } catch(err) {
      throw new Error('there was an error syncing inventory');
    }
  }

  public async getMetrcProductMappings(orgId: string, productId: string): Promise<ProductMappingResponse[]> {
    try {
      const response = await axiosInstance.get<ProductMappingsResponse>(`/organization/${orgId}/externalProductMapping/${productId}/metrc`);
      const { mappings } = response.data;
      return mappings;
    } catch (err) {
      throw new Error('there was an error fetching Metrc item ID mappings');
    }
  }

  public async setMetrcProductMappings(orgId: string, productId: string, externalId: string, externalLicenseId: string): Promise<void> {
    try {
      await axiosInstance.put<void>(`/organization/${orgId}/externalProductMapping`, {
        source: MappingSourceName.METRC,
        externalId: externalId,
        productId: productId,
        externalLicenseId: externalLicenseId,
      });
      return;
    } catch (err) {
      throw new Error('there was an error mapping this product');
    }
  }

  public async getMetrcActiveItems(orgId: string, licenseNumber: string): Promise<MetrcItemResponse[]> {
    try {
      const response = await axiosInstance.get<MetrcItemsResponse>(`/organization/${orgId}/activeItems/${licenseNumber}/metrc`);
      const { items } = response.data;
      return items;
    } catch (err) {
      throw new Error('there was an error fetching active Metrc items');
    }
  }

  public async updateAccountStatus(orgId: string, accountId: string, status: AccountStatus): Promise<void> {
    try {
      await axiosInstance.put(`/organization/${orgId}/accounts/${accountId}/status`, {
        status: status
      });
    } catch(err) {
      throw new Error('there was an error updating the account status');
    }
  }

  public async updateAccountStatusV2(orgId: string, accountId: string, status: AccountStatusAux): Promise<void> {
    try {
      await axiosInstance.put(`/organization/${orgId}/accounts/${accountId}/status-v2`, {
        status: status
      });
    } catch(err) {
      throw new Error(err.message);
    }
  }


  public async updateSubscription(orgId: string, subscriptionId: string, subscription: Partial<Subscription>): Promise<void> {
    try {
      await axiosInstance.put(`/organization/${orgId}/subscription/${subscriptionId}`, {
        subscription: subscription,
      });
    } catch(err) {
      const {message} = err.response.data;
      throw new Error(message || 'there was an error updating the subscription');
    }
  }

  public async paymentsHistory(orgId: string): Promise<any[]> {
    try {
      const response = await axiosInstance.get(`/organization/${orgId}/payments-history`);
      return response.data.paymentHistory;
    } catch(err) {
      throw new Error('there was an error updating the subscription');
    }
  }

  public async createActivityTemplate(orgId: string, activityTemplateName: string, questions: ActivityQuestion[], iconName: string): Promise<ActivityTemplate> {
    const activityTemplateResponse = await axiosInstance.post(`/organization/${orgId}/activityTemplates`, {
      name: activityTemplateName,
      iconName: iconName,
      questions: questions
    });

    return this.domainConverter.convertToActivityTemplate(activityTemplateResponse.data.activityTemplate);
  }

  public async getActivitiesTemplatesForOrg(organizationId: string): Promise<ActivityTemplateWithCount[]> {
    const activitiesTemplates = await axiosInstance.get(`/organization/${organizationId}/activityTemplates`);
    return activitiesTemplates.data.activitiesTemplates.map((activityTemplateResponse: ActivityTemplateWithCountResponse) => this.domainConverter.convertToActivityTemplateWithCount(activityTemplateResponse));
  }

  public async updateActivityTemplate(orgId: string, id: string, activityTemplateInput: Partial<ActivityTemplate>): Promise<ActivityTemplateWithCount> {
    const activityTemplateResponse = await axiosInstance.put(`/organization/${orgId}/activityTemplates/${id}`, activityTemplateInput);
    return this.domainConverter.convertToActivityTemplateWithCount(activityTemplateResponse.data.activityTemplate);
  }

  public async getAccountsTimeline(orgId: string, paginationFilter?: PaginationFilter): Promise<AccountTimeline[]> {
    const response = await axiosInstance.get(`/organization/${orgId}/accounts-timeline${paginationFilter ? this.buildPaginationParams(paginationFilter) : ''}`);
    return response.data.accounts.map((accountTimeline: AccountTimelineResponse) => {

      const activities = accountTimeline.activities.map( activity => this.domainConverter.convertToActivity(activity));
      const ordoActivities = activities.filter(act => act.type !== ActivityTypeName.EXTERNAL_EMAIL_ACTIVITY)
        .map(activity => new ActivityTimelineItem(activity));
      const externalEmailActivities = activities.filter(act => act.type === ActivityTypeName.EXTERNAL_EMAIL_ACTIVITY)
        .map(activity => new ExternalEmailActivityTimelineItem(activity as ExternalEmailActivity));
      const notes = accountTimeline.notes.map( note => new NoteTimelineItem(this.domainConverter.convertToNote(note)));
      const orders = accountTimeline.orders.map( order => new OrderTimelineItem({
        ...order,
        invoiceURL: order.invoiceURL,
        currentVersionItems: (order.currentVersionItems || []).map(oL => this.domainConverter.convertToOrderVersionLineItem(oL)),
        summary: OrderSummaryFrom(order.summary),
        location: this.domainConverter.convertToLocation(order.location),
        contact: order.contact ? this.domainConverter.convertToDomainContact(order.contact) : undefined,
        distributor: order.distributor ? this.domainConverter.convertToLicense(order.distributor) : undefined
      }));

      return {
        accountId: accountTimeline.accountId,
        orderEntryEnabled: accountTimeline.orderEntryEnabled,
        name: accountTimeline.name,
        assignments: accountTimeline.assignments,
        salesReps: accountTimeline.assignments.map(a => this.domainConverter.convertToDomainUser(a.user)),
        items: [...ordoActivities, ...externalEmailActivities, ...notes, ...orders],
        contacts: accountTimeline.contacts.map(contact => this.domainConverter.convertToDomainContact(contact))
      };
    });
  }

  public async createCustomPricingTier(organizationId: string, tier: Tier): Promise<Tier> {
    const response: AxiosResponse<{tier: TierResponse}> = await axiosInstance.post(`/organization/${organizationId}/tiers`, {
      name: tier.name,
      productSKUTiers: tier.productSKUPrices,
      groupPriceTiers: tier.groupPrices,
      accountsIds: tier.accountIds,
      allAccounts: tier.allAccounts,
      priorities: tier.priorities
    });
    const createdTier = response.data.tier;
    return this.domainConverter.convertToDomainCustomPriceTier(createdTier);
  }

  public async updateCustomPricingTier(organizationId: string, tier: Tier): Promise<Tier> {
    const response: AxiosResponse<{tier: TierResponse}> = await axiosInstance.put(`/organization/${organizationId}/tiers/${tier.id}`, {
      name: tier.name,
      productSKUTiers: tier.productSKUPrices,
      groupPriceTiers: tier.groupPrices,
      accountsIds: tier.accountIds,
      allAccounts: tier.allAccounts,
      priorities: tier.priorities
    });
    const createdTier = response.data.tier;
    return this.domainConverter.convertToDomainCustomPriceTier(createdTier);
  }

  public async deleteCustomPricingTier(organizationId: string, tier: Tier): Promise<void> {
    return axiosInstance.delete(`/organization/${organizationId}/tiers/${tier.id}`);
  }

  public async getCustomPricingTiers(organizationId: string): Promise<Tier[]> {
    const response: AxiosResponse<{tiers: TierResponse[]}> = await axiosInstance.get(`/organization/${organizationId}/tiers`);
    const {tiers} = response.data;
    return tiers.map((tier) => this.domainConverter.convertToDomainCustomPriceTier(tier));
  }

  public async updateLocationAddress(organizationId: string, locationId: string, newAddress: AccountLocation): Promise<void> {
    await axiosInstance.put(`/organization/${organizationId}/locations/${locationId}`, newAddress);
    return Promise.resolve();
  }

  private buildPaginationParams(pagination: PaginationFilter){
    const queryParams = `?amount=${pagination.amount}`;
    if(pagination.offset !== undefined) {
      return `${queryParams}&offset=${pagination.offset}`;
    }
    if (pagination.cursor) {
      return `${queryParams}&cursor=${pagination.cursor}`;
    }
    return queryParams;
  }

  // TODO: GF - extract common logic
  private async doBackendRegistration(userInput: UserRegistrationInput, password: string) {
    try {
      const response =  await axiosInstance.post('/user', {
        email: userInput.email,
        firstName: userInput.firstName,
        lastName: userInput.lastName,
        phone: userInput.phone,
        password: password
      });
      return this.domainConverter.convertToDomainUser(response.data.user);
    } catch (error) {
      const {field, message}: { field: UserFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  private async doBackendLogin(idToken: string): Promise<UserSessionResponse> {
    try {
      const response = await axiosInstance.post('/login', {
        idToken: idToken,
      });
      return response.data;
    } catch (error) {
      const {field, message}: { field: UserFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  private async doBackendRegisterUserAndCompany(userRegistration: UserRegistration, orgRegistration: OrganizationRegistration, subscriptionRegistration: SubscriptionRegistration): Promise<Registration> {
    try {
      const response = await axiosInstance.post('/register',
        {
          email: userRegistration.email,
          password: userRegistration.password,
          firstName: userRegistration.firstName,
          lastName: userRegistration.lastName,
          phone: userRegistration.phone,
          organization: {
            name: orgRegistration.name,
            state: orgRegistration.state,
            size: orgRegistration.size,
            termsOfUseAccepted: orgRegistration.termsOfUseAccepted,
            subscription: {
              plan: subscriptionRegistration.selectedPlan.type,
              paymentToken: subscriptionRegistration.paymentToken,
            }
          },
        },
        {timeout: 60_000});
      const {user} = response.data;
      const {organization} = response.data;
      return {user: this.domainConverter.convertToDomainUser(user), organization: this.domainConverter.convertToDomainOrganization(organization)};
    } catch (error) {
      const {field, message}: { field: UserFormFields, message: string } = error.response.data;
      const formError = FormError.withErrors([new InvalidField(field, message)]);
      throw new BusinessError(formError, message);
    }
  }

  private productForm(orgId: string, productInformation: ProductFormInput, productImage?: File) {
    const formData = new FormData();
    if (productImage) formData.append('productImage', productImage);
    formData.append('organizationId', orgId);
    formData.append('name', productInformation.name);
    formData.append('categoryId', productInformation.category.id);
    formData.append('brandId', productInformation.brand.id);
    formData.append('price', productInformation.price.toString());
    formData.append('unitsPerCase', productInformation.unitsPerCase.toString());
    formData.append('unitSaleOnly', productInformation.unitSaleOnly.toString());
    formData.append('caseSaleOnly', productInformation.caseSaleOnly.toString());
    this.addNullableFieldToFormData(formData, 'consumerUnitSizeAmount', productInformation.consumerUnitSizeAmount);
    this.addNullableFieldToFormData(formData, 'consumerUnitSizeUnit', productInformation.consumerUnitSizeUnit);
    this.addNullableFieldToFormData(formData, 'genetics', productInformation.genetics);
    this.addNullableFieldToFormData(formData, 'preRolls', productInformation.preRolls);
    this.addNullableFieldToFormData(formData, 'imageUrl', productInformation.imageUrl);
    this.addNullableFieldToFormData(formData, 'customerSKU', productInformation.customerSKU);
    return formData;
  }

  private trackedOrderForm(trackedOrderInput: SubmitTrackedOrderInput, invoice: File | undefined) {
    const formData = new FormData();
    if(invoice) formData.append( 'orderInvoice', invoice);
    formData.append('locationId', trackedOrderInput.locationId);
    formData.append('accountId', trackedOrderInput.accountId);
    formData.append('contactId', trackedOrderInput.contactId);
    formData.append('trackedTotal', trackedOrderInput.trackedTotal.getAmount().toString());
    formData.append('deliveryDay', trackedOrderInput.deliveryDay.toString());
    formData.append('isTracked', 'true');
    formData.append('paymentMethod', trackedOrderInput.paymentMethod);
    this.addNullableFieldToFormData(formData, 'termPeriod', trackedOrderInput.termPeriod);
    return formData;
  }

  private addNullableFieldToFormData(formData: FormData, fieldName: string, fieldValue: number | string | undefined) {
    if (fieldValue !== null && fieldValue !== undefined) {
      formData.append(fieldName, fieldValue.toString());
    }
  }

  private async updateAccountInfo(orgId: string, accountId: string, accountInfo: AccountInfo): Promise<Account> {
    const updatedAccount = await axiosInstance.put(`/organization/${orgId}/accounts/${accountId}/accountInfo`, accountInfo);
    return this.domainConverter.convertToAccount(updatedAccount.data.account);
  }

};
