import { OrderEntryStep } from '../OrderEntryViewModel';
import {OrderEntryMode} from './index';
import {API} from '../../../lib/api/ordoApi';
import {Cart, emptyDiscount, SerializableCart} from '../../../models/order-entry/Cart';
import {ProductWithAvailability} from '../../../models/productWithAvailability';
import {OrderSummary, PaymentMethod} from '../../../models/order-entry/Summary';
import Contact from '../../../models/order-entry/Contact';
import {OrganizationLicense} from '../../../models/OrganizationLicense';
import {OrderEntryCartContextData} from '../../../context/OrderEntryCartContext';
import {OrderInput} from '../../../models/order-entry/Order';
import ROUTE_NAMES from '../../../routes/ROUTE_NAMES';
import {AccountLocation, isSameAccountLocation} from '../../../models/Account';
import { User } from '../../../models/User';

export class Create implements OrderEntryMode {

  public assignedSalesRep(): User | undefined {
    return undefined;
  }

  public submitOrderCTAText(): string {
    return 'submit order';
  }

  public initialStep(): OrderEntryStep {
    return OrderEntryStep.GenerateOrder;
  }

  public orderNotes(): string {
    return '';
  }

  public async initializeAccountWithContacts(
    selectedAccountLocation: AccountLocation | undefined,
    accountLocations: AccountLocation[],
    api: API,
    orgId: string,
    accountId: string): Promise<{ selectedAccountLocation: AccountLocation | undefined; contacts: any[] }> {
    let selectedAccLocation;
    let contacts: Contact[] = [];
    if (selectedAccountLocation) {
      const foundAccountLocation = accountLocations.find((accountLocation) => isSameAccountLocation(accountLocation, selectedAccountLocation));
      if (foundAccountLocation) {
        selectedAccLocation = foundAccountLocation;
        contacts = await api.getAccountContacts(orgId, foundAccountLocation.accountId);
      }
    }
    if (accountId) {
      contacts = await api.getAccountContacts(orgId, accountId);
    }
    return {selectedAccountLocation: selectedAccLocation, contacts: contacts};
  }

  public initializeCart(
    storedCart: SerializableCart,
    selectedAccountLocation: AccountLocation | undefined,
    products: ProductWithAvailability[]): Cart {
    if (storedCart.items.length > 0) {
      try {
        return Cart.recreateFrom(storedCart, products);
      } catch (err) {
        /* eslint-disable no-console */
        console.warn('could not recreate cart from context data');
        console.warn(err);
        /* eslint-enable no-console */
      }
    }
    return new Cart([], emptyDiscount(), selectedAccountLocation);
  }

  public orderSummary(): OrderSummary | undefined {
    return undefined;
  }

  public selectedPaymentMethod(): PaymentMethod | undefined {
    return undefined;
  }

  public termPeriod(): number | undefined {
    return undefined;
  }

  public selectedDeliveryDay(): Date | undefined{
    return undefined;
  }

  public contact(): Contact | undefined {
    return undefined;
  }

  public selectedDistributor(): OrganizationLicense | undefined {
    return undefined;
  }

  public saveCart(cart: Cart, orderEntryCartContextData: OrderEntryCartContextData): void {
    orderEntryCartContextData.saveCart(cart);
  }

  public async submitOrder(api: API, orgId: string, order: OrderInput): Promise<void> {
    await api.submitOrder(orgId, order);
  }

  public availability(api: API, orgId: string, distributorOrganizationLicenseId: string, accountId: string): Promise<ProductWithAvailability[]> {
    return api.getProductsWithAvailability(orgId, distributorOrganizationLicenseId, accountId);
  }

  public getSuccessMessage() {
    return 'order submitted';
  }

  public nextRoute() {
    return ROUTE_NAMES.ORDER_HISTORY;
  }

  public nextMobileRoute() {
    return ROUTE_NAMES.SALES_ACTIVITY;
  }

  public distributorTaxesEnabled(): boolean {
    return false;
  }

  public isEdit(): boolean {
    return false;
  }
}
