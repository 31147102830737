import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCaretUp} from '@fortawesome/free-solid-svg-icons';
import '../../../../scss/ordo/ordo-number-input.scss';
import { withDecimalPlaces } from '../../../../utils/string';

export type OrdoNumberInputProps = {
  label: string,
  value: number,
  onChange: (newAmount: number) => void,
  onButtonClick?: (newAmount: number) => void,
  min?: number,
  max?: number,
  step?: number,
  isValid?: boolean,
  invalidMessage?: string,
  inputKey?: string,
  displayArrows?: boolean
};

const OrdoNumberInput = ({label, value, onChange, step = 1, min, max, isValid, invalidMessage, inputKey, onButtonClick, displayArrows = true}: OrdoNumberInputProps) => {
  const [inputValue, setInputValue] = useState<number | undefined>(value);

  useEffect(() => setInputValue(value), [value]);

  const buttonAction = (newAmount: number) => {
    if(onButtonClick) {
      onButtonClick(newAmount);
    } else {
      onChange(newAmount);
    }
  };

  return <div className={`ordo-number-input d-flex flex-column ${isValid ? '' : 'is-invalid'}`} title={isValid ? '' : invalidMessage}>
    <div className="input-label">
      {label}
    </div>
    <div className="d-flex">
      <input
        id={inputKey}
        className="number-input"
        type="number"
        step={step}
        min={min}
        max={max}
        value={inputValue}
        onChange={(event) => {
          const valueToNumber = parseFloat(event.target.value);
          setInputValue(valueToNumber);
          if(valueToNumber) onChange(withDecimalPlaces(valueToNumber));
        }}
        onBlur={() => {
          if (!inputValue) setInputValue(value);
        }}
      />
      {displayArrows && <div className="input-icons">
        <FontAwesomeIcon
          className="input-icon increase-amount"
          data-testid="increase-amount-button"
          size="lg"
          icon={faCaretUp}
          onClick={() => {
            if (inputValue) {
              buttonAction(withDecimalPlaces(inputValue + step));
              setInputValue(inputValue + step);
            }
          }}
          onBlur={() => {
            if (!inputValue) setInputValue(value);
          }}
        />
        <FontAwesomeIcon
          className="input-icon decrease-amount"
          data-testid="decrease-amount-button"
          size="lg"
          icon={faCaretDown}
          onClick={() => {
            if (inputValue) {
              buttonAction(withDecimalPlaces(inputValue - step));
              setInputValue(inputValue - step);
            }
          }}
          onBlur={() => {
            if (!inputValue) setInputValue(value);
          }}
        />
      </div>}
    </div>
    {!isValid && invalidMessage && <div className="invalid-feedback">
      {invalidMessage}
    </div>}
  </div>;
};

export default OrdoNumberInput;
