import React, {PropsWithChildren, ReactNode, useContext} from 'react';
import {AuthService} from '../services/auth_service';

const AuthContext = React.createContext<AuthService | null>(null);

export function useAuth(): AuthService {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error('Auth service is null');
  }
  return auth;
}

type AuthProviderProps = {authService: AuthService} & PropsWithChildren<ReactNode>

const AuthProvider = ({authService, children}: AuthProviderProps) => {
  return (<AuthContext.Provider value={authService}>
    {children}
  </AuthContext.Provider>);
};

export default AuthProvider;
