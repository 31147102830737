import React, {CSSProperties, FC, useEffect, useState} from 'react';
import '../../../../scss/ordo/ordo-input.scss';

type OrdoInputSize = 'large' | 'semi-large' | 'medium' | 'short' | 'extra-short'

export type OrdoInputProps = {
  inputName: string,
  label?: string,
  value: string | number,
  placeholder: string,
  inputSize: OrdoInputSize,
  readOnly?: boolean,
  isValid?: boolean,
  invalidMessage?: string,
  onChange?: (arg: any) => void,
  onBlur?: Function,
  inputTitle?: string,
  inputType?: string,
  min?: number,
  max?: number,
  step?: number,
  style?: CSSProperties,
  validate?: (inputValue: string | number) => string
  bottomMargin?: boolean
  additionalClassName?: string
  maxLength?: number,
  regex?: RegExp
}

const OrdoInput: FC<OrdoInputProps> = ({
  inputName,
  label,
  value,
  placeholder,
  inputSize,
  readOnly = false,
  isValid = true,
  invalidMessage= '',
  onChange,
  onBlur,
  inputTitle,
  inputType = 'text | number | email | password',
  min,
  max,
  step,
  style={},
  validate,
  bottomMargin = true,
  additionalClassName= '',
  maxLength,
  regex
}: OrdoInputProps) => {
  const [inputValue, setInputValue] = useState(value);

  const size = `ordo-input-${inputSize}`;
  let errorMessage = invalidMessage;
  if (validate) {
    errorMessage = validate(inputValue);
  }
  const showErrorMessage = errorMessage.length > 0 || !isValid;

  useEffect(() => setInputValue(value), [value]);

  const marginBottom = bottomMargin ? 'mb-3' : '';

  return (
    <div className={`ordo-input input-group-sm ${marginBottom} ${size} ${additionalClassName || ''} ${showErrorMessage ? 'is-invalid' : ''}`}>
      <label htmlFor={`input-${inputName}`}>
        {label}
        <span className='ordo-input__title'>{inputTitle}</span>
        <input readOnly={readOnly}
          maxLength={maxLength}
          id={`input-${inputName}`}
          data-testid={`input-${inputName}`}
          type={inputType}
          min={min}
          max={max}
          step={step}
          className='form-control no-spin ordo-input__input'
          placeholder={placeholder}
          value={inputValue}
          onChange={(event) => {
            let changedValue = event.target.value;
            if(regex) {
              const match = changedValue.match(regex);
              changedValue = match?.join('') || '';
            }
            setInputValue(changedValue);
            if (onChange)
              onChange(changedValue);
          }}
          onBlur={(event) => { if(onBlur) onBlur(event); }}
          onKeyPress={ (event) => {
            if(event.key === 'Enter' && onBlur) onBlur(event);
          }}
          style={style}
        />
        {showErrorMessage && errorMessage && <div className="invalid-feedback" data-testid={`invalid-${inputName}-feedback`}>
          {errorMessage}
        </div>}
      </label>
    </div>
  );
};

export default OrdoInput;
