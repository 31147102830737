import React, { useContext, useState, useEffect } from 'react';
import OrdoDropdown from '../common/OrdoDropdown';
import OrdoModal from '../common/OrdoModal';
import { API } from '../../../lib/api/ordoApi';
import RepAssignmentViewModel from '../../../application-models/RepAssignmentViewModel';
import {UserSessionContext} from '../../../context/UserSessionContext';
import '../../../scss/ordo/accountsPage/assignment.scss';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import {AccountCard} from '../../../models/AccountCard';
import {fullName} from '../../../models/User';
import {ActualMember} from '../../../models/member/ActualMember';

export type RepAssignmentModalProps = {
  api: API
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  selectedAccounts: AccountCard[]
};

const renderSelectedAccounts = (vm: RepAssignmentViewModel) => {
  const accountCards: any = [];
  vm.accounts.forEach((account) => {
    const amountOfLicenses = account.amountOfLicenses();
    const moreThanOneLicense = amountOfLicenses > 1;
    const firstLocation = account.getLocations()[0];

    accountCards.push((
      <div className='card selected-account-card' key={account.id}>
        <div className='card-body'>
          <span className="account-name text-limit">{account.name}</span>
          <div>
            {moreThanOneLicense && <p className="account-address license text-limit">{`${amountOfLicenses} licenses`}</p>}
            {!moreThanOneLicense && <p className="account-address text-limit"> {firstLocation.streetAddressLine1} {firstLocation.streetAddressLine2}</p>}
            {!moreThanOneLicense && <p className="account-address city text-limit"> {firstLocation.city ? `${firstLocation.city}, ${firstLocation.state} ${firstLocation.zipCode}` : `${firstLocation.state} ${firstLocation.zipCode}` }</p>}
          </div>
        </div>
      </div>
    ));
  });

  return (
    <div className='row'>
      { accountCards }
    </div>
  );
};

const renderSelectRep = (
  vm: RepAssignmentViewModel,
  onSelect: (m: ActualMember) => void
) => {
  const options = vm.currentOrg?.members.map((m) => {
    return m;
  }) || [];
  return (
    <div>
      1. Select the rep
      <OrdoDropdown
        displayName={(member: ActualMember) => {
          return fullName(member.user);
        }}
        selectedOption={vm.selectedMember}
        placeholder=''
        onSelect={onSelect}
        options={options}
      />
    </div>
  );
};

const renderSelectBrand = (
  vm: RepAssignmentViewModel,
  onClick: (brandId: string) => void
) => {

  const brands = vm.accessibleBrands.map((brand, i) => {
    const className = !brand.disabled ?
      'brand-logo-container selected' : 'brand-logo-container';

    return (
      <div key={brand.id} className={className} tabIndex={i} role='button' onClick={ () => onClick(brand.id) } onKeyDown={ () => onClick(brand.id)} >
        <img alt='brand' src={brand.logoImageURL} className='rounded-lg'/>
      </div>
    );
  });
  return (
    <div>
      { brands }
      <p> New brands are enabled by default </p>
    </div>
  );
};

export const RepAssignmentModal = (props: RepAssignmentModalProps) => {
  const {currentOrganization} = useContext(UserSessionContext);
  const curOrgId = currentOrganization()?.id || '';
  const { api, isOpen, onClose, onSubmit, selectedAccounts } = props;
  const [viewModel, setViewModel] = useState(RepAssignmentViewModel.empty(api));

  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();


  const fetchData = async () => {
    showSpinner();
    const initializedViewModel = await RepAssignmentViewModel.initialize(api, curOrgId, selectedAccounts);
    setViewModel(initializedViewModel);
    hideSpinner();
  };

  const onSelect = (salesRep: ActualMember) => {
    setViewModel(viewModel.selectMember(salesRep));
  };

  useEffect(() => {
    fetchData();
  }, [isOpen]);

  const submit = async () => {
    let updatedViewModel = viewModel.clearError();
    showSpinner();
    updatedViewModel = await updatedViewModel.submitAccountAssignment();
    setViewModel(updatedViewModel);
    hideSpinner();
    if(!updatedViewModel.error)
      onSubmit();
  };

  const onBrandClick = (brandId: string) => {
    setViewModel(viewModel.toggleBrand(brandId));
  };

  const disableAssignButton = () => {
    // Since new brands are enabled by default, you can still create an assignment with all brands disabled for the time being.
    return viewModel.currentOrg?.members.length === 1 &&
      !viewModel.selectedMember;
  };

  const renderError = () => {
    if (viewModel.error) {
      return (
        <div className="alert alert-danger" role="alert">
          { viewModel.error.message }
        </div>
      );
    }
    return <span/>;
  };

  const renderModalContents = () => {
    return (
      <OrdoSpinner showSpinner={spinnerVisibility}>
        <form
          className='account-modal'
          id='add-assignment-form'
          onSubmit={(event) => {
            event.preventDefault();
            submit();
          }}>
          { renderError() }
          { renderSelectRep(viewModel, onSelect) }
          <br/>
          <p> 2. Select Brand Access </p>
          { renderSelectBrand(viewModel, onBrandClick) }
          <p> 3. Verify Account Assignments </p>
          <div className='row'>
            { renderSelectedAccounts(viewModel) }
          </div>
        </form>
      </OrdoSpinner>
    );
  };

  return (
    <OrdoModal
      formId='add-assignment-form'
      dialogClassName='assign-account-dialog'
      onClose={onClose}
      show={isOpen}
      actionText='save'
      dataTestId='assign-retail-location'
      disableCTA={disableAssignButton()}
    >
      { renderModalContents() }
    </OrdoModal>
  );
};

export {};
