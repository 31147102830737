import React from 'react';
import '../../../scss/ordo/ordo-logo.scss';

export const OrdoLogo = () => {

  return <div className="general-ordo-logo">
      Ordo
  </div>;

};
