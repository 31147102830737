import React from 'react';
import RegistrationViewModel from '../../../application-models/RegistrationViewModel';
import UserRegistration from '../../../models/userRegistration';
import {FormFields, UserFormFields} from '../../../errors/form_error';
import AcceptInvitationAndJoinViewModel from '../../../application-models/AcceptInvitationAndJoinViewModel';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import OrdoButton from '../../components/common/OrdoButton';

export type JoinOrdoViewModel = AcceptInvitationAndJoinViewModel | RegistrationViewModel;

const UserRegistrationForm = ({
  joinOrdoViewModel,
  updateUserRegistration,
  checkEmptyFieldError,
  onCallToAction,
  callToAction,
  readOnlyEmail}: {
  joinOrdoViewModel: JoinOrdoViewModel,
  updateUserRegistration: (fieldsToUpdate: UserRegistration) => void,
  checkEmptyFieldError: (field: FormFields) => void,
  onCallToAction: () => void,
  callToAction: string,
  readOnlyEmail: boolean,
}) => {

  function errorMessageForInput(input: UserFormFields) {
    return joinOrdoViewModel.hasErrorFor(input) ? joinOrdoViewModel.errorMessage(input) : '';
  }

  const updateRegistrationField = (fieldName: string) => {
    return (newValue: string) => {
      updateUserRegistration({[fieldName]: newValue} as unknown as UserRegistration);
    };
  };

  return <div className="card-body px-md-5 py-5">
    <div className="registration-header">
      <h6 className="registration-header__title">Ordo Registration</h6>
      <p className="text-muted mb-0">Tell us who you are</p>
    </div>
    <span className="clearfix"/>
    <form>
      <div className="input-group">
        <OrdoInput inputName="first-name"
          label="first name"
          value={joinOrdoViewModel.userRegistration.firstName}
          placeholder="first name"
          onBlur={() => checkEmptyFieldError('firstName')}
          onChange={updateRegistrationField('firstName')}
          invalidMessage={errorMessageForInput('firstName')}
          inputSize="large"/>
      </div>
      <div className="input-group">
        <OrdoInput inputName="last-name"
          label="last name"
          value={joinOrdoViewModel.userRegistration.lastName}
          placeholder="last name"
          onBlur={() => checkEmptyFieldError('lastName')}
          onChange={updateRegistrationField('lastName')}
          invalidMessage={errorMessageForInput('lastName')}
          inputSize="large"/>
      </div>

      <div className="input-group">
        <OrdoInput inputName="phone"
          label="phone"
          value={joinOrdoViewModel.userRegistration.phone}
          placeholder="phone"
          onBlur={() => checkEmptyFieldError('phone')}
          onChange={updateRegistrationField('phone')}
          invalidMessage={errorMessageForInput('phone')}
          inputSize="large"/>
      </div>

      <div className="input-group">
        <OrdoInput inputName="email"
          label="email"
          value={joinOrdoViewModel.userRegistration.email}
          placeholder="email"
          onBlur={() => checkEmptyFieldError('email')}
          onChange={updateRegistrationField('email')}
          readOnly={readOnlyEmail}
          invalidMessage={errorMessageForInput('email')}
          inputType="email"
          inputSize="large"/>
      </div>


      <div className="input-group">
        <OrdoInput inputName="password"
          label="password"
          value={joinOrdoViewModel.userRegistration.password}
          placeholder="password"
          onBlur={() => checkEmptyFieldError('password')}
          onChange={updateRegistrationField('password')}
          invalidMessage={errorMessageForInput('password')}
          inputType="password"
          inputSize="large"/>
      </div>

      <div className="input-group">
        <OrdoInput inputName="password-confirmation"
          label="password confirmation"
          value={joinOrdoViewModel.userRegistration.passwordConfirmation}
          placeholder="confirm password"
          onBlur={() => checkEmptyFieldError('passwordConfirmation')}
          onChange={updateRegistrationField('passwordConfirmation')}
          invalidMessage={errorMessageForInput('passwordConfirmation')}
          inputType="password"
          inputSize="large"/>
      </div>
      <div className="login-footer">
        <OrdoButton disabled={false}
          text={callToAction}
          category="primary"
          onClick={(event) => {
            event.preventDefault();
            onCallToAction();
          }}
          dataTestId="register-user-next-button"
          additionalClassName='registration-button'
        />
        <div><small>Already have an account? </small>
          <a href="/" className="small font-weight-bold">Sign in</a>
        </div>
      </div>
    </form>
  </div>;
};

export default UserRegistrationForm;
