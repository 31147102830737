import {Money} from '../Money';
import {ProductWithAvailability} from '../productWithAvailability';
import {ItemLine, SerializableItemLine} from './ItemLine';
import {OrderVersionLineItem} from './OrderVersionLineItem';
import {LineItemType} from './Order';

export enum CartItemType {
  CASE = 'case',
  UNIT = 'unit',
  SAMPLE = 'sample'
}

export const NEW_LINE_DEFAULT_QUANTITY = 1;

export type SerializableCartItem = {
  productId: string,
  type: CartItemType,
  lines: SerializableItemLine[]
}

// CartItem represents an item within the cart that may have multiple lines
// Currently, a CartItem does not throw errors on invalid input, but instead ignores invalid input
// In the future, we should throw errors here and then have the caller handle them accordingly...
export class CartItem {
  public product: ProductWithAvailability;
  public type: CartItemType;
  public lines: ItemLine[];

  static create(product: ProductWithAvailability, quantity: number, type: CartItemType, price: number) {
    const itemPriceMoney = Money.FromDollarAmount(price);
    const line = ItemLine.newLineItem(quantity, itemPriceMoney);
    return new CartItem(product, type, [line]);
  }

  public static toCartItemType(type: LineItemType) {
    switch (type) {
    case LineItemType.Unit:
      return CartItemType.UNIT;
    case LineItemType.Case:
      return CartItemType.CASE;
    case LineItemType.Sample:
      return CartItemType.SAMPLE;
    default:
      return CartItemType.SAMPLE;
    }
  }

  public static recreateFromLineItems(lineItems: OrderVersionLineItem[], products: ProductWithAvailability[]) {
    const [firstLineItem] = lineItems;
    const productWithAvailability = products.find((p) => p.id === firstLineItem.product.id);
    if (!productWithAvailability) {
      throw new Error('must provide product availability');
    }
    const type = CartItem.toCartItemType(firstLineItem.type);
    const lines = lineItems.map(ItemLine.recreateFromOrder);
    return new CartItem(productWithAvailability, type, lines);
  }

  static recreateFrom(storedItem: SerializableCartItem, product: ProductWithAvailability) {
    const recreatedLines: ItemLine[] = storedItem.lines.map(ItemLine.recreateFromSerializable);
    return new CartItem(product, storedItem.type, recreatedLines);
  }

  private constructor(product: ProductWithAvailability, type: CartItemType, lines: ItemLine[]) {
    this.product = product;
    this.type = type;
    this.lines = lines;
  }

  public get quantity() {
    return this.lines.map(line => line.getQuantity()).reduce((accum,quantity) => accum + quantity, 0);
  }

  public lineItemPricePerUnit(lineNumber: number): string {
    const { unitsPerCase } = this.product;
    return this.lines[lineNumber].getPrice().divide(unitsPerCase).formatted();
  }

  public updateProduct(product: ProductWithAvailability) {
    this.product = product;
    this.lines.forEach(line => line.updateProductPrice(product, this.type));
    return this;
  }

  public newLine() {
    const price = this.product.calculatePricePerItemWithTier(this.type);
    const defaultItemPrice = Money.FromDollarAmount(price);
    this.lines.push(ItemLine.newLineItem(NEW_LINE_DEFAULT_QUANTITY, defaultItemPrice));
  }

  public removeLine(lineIndex: number) {
    this.lines = this.lines.filter((_line, index) => index !== lineIndex);
  }

  public latestNonZeroLineIndex() {
    const itemLines = [...this.lines];
    const firstNonZeroLine = itemLines.reverse().findIndex((line => line.getQuantity() > 0));
    return this.lines.length - 1 - firstNonZeroLine;
  }

  public updateProductQuantity(quantity: number) {
    if(this.quantity === 0) {
      this.lines[0].setQuantity(quantity);
      return;
    }
    const differenceWithExistingQuantity = quantity - this.quantity;
    if(differenceWithExistingQuantity > 0) {
      const latestNonZeroLine = this.lines[this.latestNonZeroLineIndex()];
      latestNonZeroLine.setQuantity(latestNonZeroLine.getQuantity() + differenceWithExistingQuantity);
    } else {
      let quantityToReduce = -differenceWithExistingQuantity;
      while (quantityToReduce !== 0) {
        const latestNonZeroLine = this.lines[this.latestNonZeroLineIndex()];
        const currentLineQuantity =  latestNonZeroLine.getQuantity();
        if(currentLineQuantity > quantityToReduce) {
          latestNonZeroLine.setQuantity(latestNonZeroLine.getQuantity() - quantityToReduce);
          quantityToReduce = 0;
        } else {
          quantityToReduce -= currentLineQuantity;
          latestNonZeroLine.setQuantity(0);
        }
      }
    }
  }

  // toJSON implements the method expected by JSON.stringify
  public toJSON(): SerializableCartItem {
    return {
      productId: this.product.id,
      type: this.type,
      lines: this.lines.map((line) => line.toJSON())
    };
  }

  public isUnit() {
    return this.type === CartItemType.UNIT;
  }

  public isOnlyUnitProduct() : boolean {
    return this.product.unitSaleOnly;
  }

  public noZeroQuantityLines() : boolean {
    return this.lines.every((line) => line.getQuantity() > 0);
  }
}
