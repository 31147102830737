import {OrderStatus} from '../../models/order-entry/Order';
import {Money} from '../../models/Money';
import {DateFormatTemplate, OrdoDate, OrdoDateException} from '../../models/OrdoDate';
import {RepAssignmentIds} from '../../models/Assignment';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import Account, {AccountLocation} from '../../models/Account';

export type HeaderColumns = { title: string, quantity: string, key: string }[];
export type SalesActivityColumns = {key: string, list: SalesActivityEntity[], renderWith: Function}[]
export type TrackOrderModalInput = { location?: AccountLocation, order?: OrderWithCurrentVersion, action?: Function };

export type PipelineData = {
  nonQualifiedAccounts: SalesActivityAccount[];
  prospectAccounts: SalesActivityAccount[];
  accounts: Account[];
  verifiedPurchasers: SalesActivityAccount[];
  stalePurchasers: SalesActivityAccount[];
  lostPurchasers: SalesActivityAccount[];
  pendingOrdersCards?:  SalesActivityOrder[];
  awaitingDeliveryCards?: SalesActivityOrder[];
  collectPaymentCards: SalesActivityOrder[];
  assignments: RepAssignmentIds[];
};

export type SalesActivityEntity = SalesActivityAccount | SalesActivityOrder;

export type AccountPriority = 1 | 2 | 3;

export enum AccountStatus {
  VERIFIED = 'Verified',
  LOST = 'Lost',
  UNKNOWN = 'Unknown',
}

export enum AccountStatusAux {
  NonQualified = 'NonQualified',
  Prospect = 'Prospect',
  ActiveBuyer = 'ActiveBuyer',
  Lost = 'Lost'
}

export type SalesActivityAccount = {
  id: string
  amountOfLocations: number,
  accountLocations: AccountLocation[];
  name: string
  address?: string,
  lastOrderDate: string,
  priority: AccountPriority,
  stalePeriod: number,
  conversion: number,
  value: number,
  activeAccount: boolean,
  orderEntryEnabled: boolean,
}

export type SalesActivityOrder = {
  datePlaced: OrdoDate,
  id: string,
  locationId: string,
  accountId: string,
  name: string,
  orderNumber: string,
  salesValue: Money,
  status: OrderStatus,
  deliveryDate: string,
  paymentDueDate: string,
  priority: AccountPriority,
  isTracked: boolean,
  createdBy: string,
  activeAccount: boolean,
  invoiceURL: string
}

export function accountDateGoingStale(account: SalesActivityAccount): string {
  const stalePeriodDate = OrdoDate.convertFromString(account.lastOrderDate).add(account.stalePeriod, 'day');
  return stalePeriodDate.format(DateFormatTemplate.LOCATION_ACTIVITY_CARDS_DATE);
}

const STALE_PERIOD_ALERT = 5;

export function isAccountActivityGoingStale(account: SalesActivityAccount): boolean {
  try {
    const stalePeriodDate = OrdoDate.from(new Date()).subtract(account.stalePeriod - STALE_PERIOD_ALERT, 'day');
    const lastOrderDate = OrdoDate.convertFromString(account.lastOrderDate);
    return lastOrderDate.beforeThan(stalePeriodDate);
  } catch (e) {
    if(e instanceof OrdoDateException) {
      return false;
    }
    throw e;
  }
}

export function orderActivityStatusWarningInfo(order: SalesActivityOrder): string {
  switch (order.status) {
  case OrderStatus.Delivered:
    return 'past due';
  case OrderStatus.Pending:
    return 'late delivery';
  case OrderStatus.Confirmed:
    return 'late delivery';
  default:
    return '';
  }
}

export function orderActivityStatusInfo(order: SalesActivityOrder): string {
  switch (order.status) {
  case OrderStatus.Delivered:
    return 'payment due';
  case OrderStatus.Pending:
    return 'delivery date';
  case OrderStatus.Confirmed:
    return 'delivery date';
  default:
    return '';
  }
}

export function orderActivityIsGoingStale(order: SalesActivityOrder): boolean {
  const today = OrdoDate.from(new Date());
  const deliveryDate = OrdoDate.convertFromString(order.deliveryDate);
  const paymentDueDate = OrdoDate.convertFromString(order.paymentDueDate);

  return order.status === OrderStatus.Delivered ?
    paymentDueDate.beforeThan(today) :
    deliveryDate.beforeThan(today);
}

export function orderActivityDateInfo(order: SalesActivityOrder): string {
  return order.status === OrderStatus.Delivered ? order.paymentDueDate : order.deliveryDate;
}
