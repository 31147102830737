import {notLoggedInUserSession, User} from './User';
import InvalidField from '../errors/invalid_field';
import {FormFields} from '../errors/form_error';
import {UserSession} from './UserSession';
import isValidEmail from '../utils/string';

export default class Login {
  public email: string;
  public password: string;
  public userSession: UserSession;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
    this.userSession = notLoggedInUserSession;
  }

  public invalidFields() {
    const invalidFields = [];
    if (!isValidEmail(this.email)) invalidFields.push(this.invalidField('email'));
    if (!this.passwordIsValid()) invalidFields.push(this.invalidField('password'));
    return invalidFields;
  }

  invalidField(field: string) {
    if (field === 'email' && !!this.email) return new InvalidField('email', 'The email is invalid');
    return new InvalidField(field as FormFields, 'Mandatory field');
  }

  isValid() {
    return isValidEmail(this.email) && this.passwordIsValid();
  }

  private passwordIsValid() {
    return this.password.length > 0;
  }

  public userId() {
    return this.user.id;
  }

  public get user() : User {
    return this.userSession.user;
  }

  public setUserSession(userSession: UserSession) {
    this.userSession = userSession;
  }
}
