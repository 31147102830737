import {OrganizationLicense} from '../../models/OrganizationLicense';
import {API} from '../../lib/api/ordoApi';
import {MetrcItemSyncStep, MetrcSyncStep} from './MetrcItemSyncStep';
import {SecondMetrcSyncStep} from './SecondMetrcSyncStep';

export class FirstMetrcSyncStep implements MetrcSyncStep {
  constructor() {
  }

  actionText(): string {
    return 'sync inventory';
  }

  secondaryActionText(): string {
    return '';
  }

  disableAction(selectedLicenses: OrganizationLicense[]): boolean {
    return selectedLicenses.length <= 0;
  }

  headerSubtitle(): string {
    return 'choose locations to sync inventory';
  }

  headerText(): string {
    return 'metrc sync';
  }

  nextStep(): MetrcSyncStep {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new SecondMetrcSyncStep();
  }

  previousStep(): MetrcSyncStep {
    throw new Error('no previous step');
  }

  submit(_api: API, _orgId: string, _selectedLicences: OrganizationLicense[], _callback: Function): void {
  }

  step(): MetrcItemSyncStep {
    return MetrcItemSyncStep.FIRST;
  }
}