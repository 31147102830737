import React, {FC, useContext, useEffect, useState} from 'react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import '../../scss/purpose.scss';
import {API} from '../../lib/api/ordoApi';
import OrdoInput from '../components/common/inputs/OrdoInput';
import OrdoPageSubTitle from '../components/common/OrdoPageSubTitle';
import '../../scss/ordo/organizationPage/organization-page.scss';
import '../../scss/ordo/user-information-tab.scss';
import {UserInformationViewModel, UserUpdateFields} from '../../application-models/UserInformationViewModel';
import {UserFormFields} from '../../errors/form_error';
import {UserSessionContext} from '../../context/UserSessionContext';
import GeneralErrorMessage from '../components/common/GeneralErrorMessage';
import OrdoButton from '../components/common/OrdoButton';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import {UserSessionRefresher} from '../../context/UserSessionRefresher';
import {GoogleAuthModal} from './GoogleAuthModal';
import gmailLogo from './gmailLogo.png';
import {displayGoogleIntegration} from '../../lib/featureFlags';
import {appConfig} from '../../lib/config';
import ConfirmationModal from '../components/common/ConfirmationModal';
import {useFeatureFlags} from '../../context/FeatureFlagsContext';

type UserInformationTabProps = { api: API, refresher: UserSessionRefresher}

const UserInformationTab: FC<UserInformationTabProps>  = ({api, refresher}: UserInformationTabProps) => {

  const {successToast, errorToast} = useOrdoToasts();
  const featureFlags = useFeatureFlags();
  const userSessionContextData = useContext(UserSessionContext);
  const [viewModel, setViewModel] = useState(UserInformationViewModel.empty(userSessionContextData.user(), api));
  const [showGoogleAuthModal, setShowGoogleAuthModal] = useState(false);
  const [deleteGoogleAuthModalOpen, setDeleteGoogleAuthModalOpen] = useState(false);

  useEffect(() => {
    UserInformationViewModel.initialize(userSessionContextData.user(), userSessionContextData.currentOrganization()?.id || null, api)
      .then((updatedViewModel) => setViewModel(updatedViewModel));
  }, []);

  const updateUserInformationField = (fieldToUpdate: UserUpdateFields) => {
    return async (value: string) => {
      const updatedUser = userSessionContextData.user();
      const orgId = userSessionContextData.currentOrganization()!.id;
      updatedUser[fieldToUpdate] = value;
      const newViewModel = await UserInformationViewModel.initialize(updatedUser, orgId, api);
      setViewModel(newViewModel);
    };
  };


  async function updateUserInformation() {
    const updatedViewModel = await viewModel.updateUserInformation(userSessionContextData.user().id);
    if (!updatedViewModel.hasErrors()) {
      await refresher.refresh();
      successToast('Updated profile information!');
    }
    else {
      errorToast('Something went wrong, could not update profile information');
    }
    setViewModel(updatedViewModel);
  }


  const isValid = (input: UserFormFields) => {
    return !viewModel.hasErrorFor(input);
  };

  function errorMessageForInput(input: UserFormFields) {
    return viewModel.hasErrorFor(input) ? viewModel.errorMessage(input) : '';
  }

  const toggleGoogleModal = () => setShowGoogleAuthModal(!showGoogleAuthModal);

  const openRemoveGoogleAuthModal = () => {
    setDeleteGoogleAuthModalOpen(true);
  };

  const closeRemoveGoogleAuthModal = () => {
    setDeleteGoogleAuthModalOpen(false);
  };

  const removeGoogleAuth = () => {
    api.removeGoogleAuth(userSessionContextData.currentOrganization()!.id)
      .then(() => {
        setViewModel(viewModel.updateGoogleSyncEnabled(false));
        successToast('Google disabled!');
      }).catch(error => {
        errorToast('Could not disable Google');
        // eslint-disable-next-line no-console
        console.log(error);
      }).finally(closeRemoveGoogleAuthModal);
  };

  const renderEmailSync = () => {
    if (displayGoogleIntegration(featureFlags)) {
      if(viewModel.getGoogleSyncEnabled()) {
        return (<div>
          <div className='gmail-sync enabled' >
            <img className='gmail-icon' alt='gmail-icon' src={gmailLogo}/>
            <span className='gmail-sync-title'>enabled</span>
            &nbsp;
            <OrdoButton disabled={false} text="Disable" category="danger" additionalClassName="disable-gmail-button" onClick={openRemoveGoogleAuthModal} dataTestId=""/>
          </div>
          {deleteGoogleAuthModalOpen && <ConfirmationModal show={deleteGoogleAuthModalOpen}
            onClose={closeRemoveGoogleAuthModal}
            onSubmit={removeGoogleAuth}
            actionText='disable'
            confirmationText="Are you sure you want to disable the Google integration?"
          />}
        </div>);
      }
      return (
        <div className='gmail-sync' role='presentation' onClick={() => setShowGoogleAuthModal(true)}>
          <span className='gmail-sync-title'>integrate your email</span>
        </div>
      );
    }
    return <div/>;
  };

  const onGoogleSuccessfulAuth = (response: any) => {
    api.sendGoogleAuthCode(response.code, userSessionContextData.currentOrganization()!.id)
      .then(() => {
        setViewModel(viewModel.updateGoogleSyncEnabled(true));
        successToast('Google sync successful!');
      }).catch(error => {
        errorToast('Could not Sign in into Google');
        // eslint-disable-next-line no-console
        console.log(error);
      }).finally(() => toggleGoogleModal());
  };

  const handleGoogleError = (response: any) => {
    if (response.error !== 'popup_closed_by_user') {
      errorToast('Could not Sign in into Google');
      // eslint-disable-next-line no-console
      console.log(response);
    }
  };

  const onGoogleFailedAuth = (response: any) => {
    handleGoogleError(response);
  };

  return (
    <>
      <div className="user-information-tab">
        <GeneralErrorMessage viewModel={viewModel}/>
        <div className='col mb-5'>
          <OrdoPageSubTitle title='first name'/>
          <OrdoInput
            inputName="org-first-name" value={viewModel.userInformationField('firstName')} placeholder="first name"
            isValid={isValid('firstName')}
            onChange={updateUserInformationField('firstName')}
            invalidMessage={errorMessageForInput('firstName')}
            onBlur={() => {}} inputSize="medium"/>
          <OrdoPageSubTitle title='last name'/>
          <OrdoInput
            inputName="org-last-name" value={viewModel.userInformationField('lastName')} placeholder="last name"
            isValid={isValid('lastName')}
            onChange={updateUserInformationField('lastName')}
            invalidMessage={errorMessageForInput('lastName')}
            onBlur={() => {}} inputSize="medium"/>
          <OrdoPageSubTitle title='phone'/>
          <OrdoInput
            inputName="org-phone" value={viewModel.userInformationField('phone')} placeholder="phone"
            isValid={isValid('phone')}
            onChange={updateUserInformationField('phone')}
            invalidMessage={errorMessageForInput('phone')}
            onBlur={() => {}} inputSize="medium"/>
          <div className='email-and-gmail-sync'>
            <OrdoPageSubTitle title='email'/>
            {renderEmailSync()}
          </div>
          <OrdoInput
            inputName="org-email" value={viewModel.userInformationField('email')} placeholder="email"
            isValid={isValid('email')}
            onChange={updateUserInformationField('email')}
            invalidMessage={errorMessageForInput('email')}
            onBlur={() => {}} inputSize="medium"/>
          <OrdoButton
            text='update profile information'
            onClick={updateUserInformation}
            category='primary'
            disabled={false}
            dataTestId='update-profile-information'
          />
        </div>
      </div>
      {showGoogleAuthModal &&
      <GoogleOAuthProvider clientId={appConfig.googleLoginClientId}>
        <GoogleAuthModal isOpen={showGoogleAuthModal}
          onClose={toggleGoogleModal}
          onSuccessAuth={onGoogleSuccessfulAuth}
          onFailedAuth={onGoogleFailedAuth}/>
      </GoogleOAuthProvider>}
    </>
  );
};

export default UserInformationTab;
