import React, {useContext, useState} from 'react';
import OrderWithCurrentVersion from '../../../models/order-entry/OrderWithCurrentVersion';
import OrderInformationViewModel from '../../../application-models/order-history/OrderInformationViewModel';
import NonTrackedOrderExpandedDetails from './NonTrackedOrderExpandedDetails';
import OrderGeneralInformation from './OrderGeneralInformation';
import {API} from '../../../lib/api/ordoApi';
import {useAPI} from '../../../context/OrdoApiContext';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {OrderStatus} from '../../../models/order-entry/Order';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import TrackedOrderExpandedDetails from './TrackedOrderExpandedDetails';
import OrdoSpinner from '../OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';

type OrderHistoryOrderItemProps = {
  order: OrderWithCurrentVersion,
  onUpdateOrder: Function,
}

const OrderHistoryOrderItem = ({order, onUpdateOrder} : OrderHistoryOrderItemProps) => {
  const api: API = useAPI();
  const {userSession} = useContext(UserSessionContext);
  const {successToast, errorToast} = useOrdoToasts();
  const [orderInformationViewModel, setOrderInformationViewModel] = useState(new OrderInformationViewModel(order, api));
  const [expanded, setExpanded] = useState(false);
  const rowGeneralColor = order.isPending() || order.isCancelled() ? 'greyed-out' : 'regular-color';
  const rowBorderColor = expanded ? 'expanded' : '';
  const {hideSpinner,showSpinner,spinnerVisibility} = useSpinnerToggle();

  const updateOrderStatus = async (status: OrderStatus) => {
    showSpinner();
    orderInformationViewModel.updateOrderStatus(order.id, userSession.currentOrganization!.id, status)
      .then((updatedViewModel) => {
        successToast('order status updated');
        setOrderInformationViewModel(updatedViewModel);
        onUpdateOrder(updatedViewModel.order);
      })
      .catch(()=> errorToast('could not update order status'))
      .finally(hideSpinner);
  };

  const updateOrderDisplayStatus = (status: OrderStatus) => {
    const updatedViewModel = orderInformationViewModel.updateOrderStatusDisplay(status);
    setOrderInformationViewModel(updatedViewModel);
    onUpdateOrder(updatedViewModel.order);
  };

  const renderExpandedDetails = () => {
    if (!orderInformationViewModel.isTracked()) {
      return <NonTrackedOrderExpandedDetails orderInformationViewModel={orderInformationViewModel}
        onUpdateOrderStatus={updateOrderStatus} onUpdateOrderDisplayStatus={updateOrderDisplayStatus}/>;
    }
    return <TrackedOrderExpandedDetails orderInformationViewModel={orderInformationViewModel} onUpdateOrderStatus={updateOrderStatus}/>;
  };

  return (<OrdoSpinner showSpinner={spinnerVisibility}><div
    className={`order-history-order-item-container  ${rowBorderColor} ordo-shadow ordo-card-border ${rowGeneralColor}`}>
    <OrderGeneralInformation orderInformationViewModel={orderInformationViewModel} onClick={() => setExpanded(!expanded)}/>
    {expanded && renderExpandedDetails()}
  </div></OrdoSpinner>);
};

export default OrderHistoryOrderItem;
