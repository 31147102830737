import React, {FC} from 'react';
import {FormFields} from '../../../errors/form_error';
import '../../../scss/ordo/ordo-error-message.scss';

export interface ErrorMessageViewModel {
  hasErrorFor(input: FormFields): boolean
  errorMessage(input: FormFields): string
}

const GeneralErrorMessage: FC<{viewModel: ErrorMessageViewModel}> = ({viewModel}: {viewModel: ErrorMessageViewModel}) => {
  if (viewModel.hasErrorFor('unknown')) {
    return <div className="alert alert-danger error-message" role="alert">
      {viewModel.errorMessage('unknown')}
    </div>;
  }
  return <></>;
};

export default GeneralErrorMessage;
