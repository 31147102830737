import React from 'react';
import {
  ActivityCompletionResponseProps,
  SingleValueActivityCompletionResponse
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import {SelectableOption} from '../../common/searchable-dropdown/selectableOption';
import Product from '../../../../models/Product';
import {customPriceEditorDropdownStyles} from '../../custom-pricing-tiers/CustomPriceEditor';
import {PRIMARY_COLOR, WARNING_COLOR} from '../../../../constants';

import OrdoSearchableDropdown from '../../common/searchable-dropdown/OrdoSearchableDropdown';
import {filterProductDropdownOption, ProductDropdownMenuView, ProductDropdownSelectedView } from '../../custom-pricing-tiers/ProductDropdownView';

export const ProductDropdownResponse = ({
  response,
  updateResponse,
  products
}: ActivityCompletionResponseProps & { products: SelectableOption<Product>[] }) => {
  const productDropdownResponse = response as SingleValueActivityCompletionResponse;

  const onChange = (productId: string) => {
    productDropdownResponse.body = {value: productId};
    updateResponse(productDropdownResponse);
  };

  const selectedOption = products.find(p => p.value.id === productDropdownResponse.body.value);

  return <OrdoSearchableDropdown
    label="product" placeholder="search a product by name or sku"
    selectedOption={selectedOption}
    options={products}
    onChangeSelectedOption={(option: SelectableOption<Product>) => onChange(option.value.id)}
    addedStyles={customPriceEditorDropdownStyles(2, response.isFulfilled() ? PRIMARY_COLOR : WARNING_COLOR, '2.5em')}
    selectedOptionCustomComponent={ProductDropdownSelectedView}
    menuOptionCustomComponent={ProductDropdownMenuView}
    customFilterOption={filterProductDropdownOption}
  />;
};