import React, {FC, useContext, useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {RouteProps} from 'react-router';
import CookieVerifier from '../utils/CookieVerifier';
import {UserSessionContextData} from '../context/UserSessionContext';
import useOrdoToasts from '../hooks/useOrdoToasts';
import { RoutePermissionChecker } from './permissions';
import {useFeatureFlags} from '../context/FeatureFlagsContext';

type StrictRouteProps = RouteProps & {
  redirectRoute: string,
  cookies: () => string,
  currentTime: () => number;
  userSessionContext: React.Context<UserSessionContextData>,
  path: string
}

const StrictRoute: FC<StrictRouteProps> = ({redirectRoute, cookies, currentTime, userSessionContext, path, ...routeProps}: StrictRouteProps) => {
  const currentContextData = useContext(userSessionContext);
  const featureFlags = useFeatureFlags();
  const userLoggedIn = CookieVerifier.isValidSession(cookies(), currentTime(), currentContextData.user());
  const { errorToast } = useOrdoToasts();
  const routePermissionChecker = new RoutePermissionChecker();
  const [userHasRoutePermissions, setUserHasPermissions] = useState(routePermissionChecker.allow(currentContextData.userSession, featureFlags, path));
  useEffect(() => {
    const allow = routePermissionChecker.allow(currentContextData.userSession, featureFlags, path);
    setUserHasPermissions(allow);
    if (!allow) {
      errorToast('You do not have permission to access this page');
    }
  }, [path]);

  if(userLoggedIn && userHasRoutePermissions) {
    // We need this eslint-disable to give the props to the Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Route to={path} {...routeProps}/>;
  }
  return <Redirect to={redirectRoute}/>;
};

export default StrictRoute;
