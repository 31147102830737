import React from 'react';
import {
  ActivityCompletionResponseProps,
  DateTimeActivityCompletionResponse,
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import OrdoDatepicker, {OrdoDatepickerDisplayMode, SelectableDates} from '../../common/OrdoDatepicker';
import {OrdoTimePicker} from '../../common/OrdoTimePicker';


export const DateTimeResponse = ({
  response,
  updateResponse
}: ActivityCompletionResponseProps) => {
  const dateTimeResponse = response as DateTimeActivityCompletionResponse;

  const updateDate = (date: Date) => {
    dateTimeResponse.body.date = date;
    updateResponse(dateTimeResponse);
  };
  const updateTime = (time: Date | undefined) => {
    if(!time) {
      dateTimeResponse.body.time = null;
    }
    else {
      const selectedDate = dateTimeResponse.body.date;
      if(selectedDate) {
        const dateCopy = new Date(selectedDate);
        dateCopy.setHours(time.getHours());
        dateCopy.setMinutes(time.getMinutes());
        dateTimeResponse.body.time = dateCopy;
      }
      dateTimeResponse.body.time = time;
    }
    updateResponse(dateTimeResponse);
  };

  return <div className='datetime-response'>
    <OrdoDatepicker
      placeholder="select a date"
      onChange={updateDate}
      displayTime={false}
      onlyTime={false}
      value={dateTimeResponse.body.date || undefined}
      displayMode={OrdoDatepickerDisplayMode.SINGLE_LINE}
      validDates={SelectableDates.ALL_TIME}
      timeConstraints={{
        minutes: {
          min: 0,
          max: 59,
          step: 15
        }
      }}
    />
    <OrdoTimePicker onChangeDate={updateTime} activityTime={dateTimeResponse.body.time || undefined}/>
  </div>;
};