import Dinero from 'dinero.js';

export interface SerializableMoney {
  amount: number
}

export class Money {
  // dinero represents the dinero instance that we manage in order to perform calculations.
  // The dinero field being readonly is a property we should maintain.
  // This allows us to chain calls together without modifying the values of any existing instances
  // Consider the following:
  // const a = new Money(100);
  // const b = new Money(100);
  // const c = a.add(b); // a.getAmount() === 100, b.getAmount() === 100, c.getAmount() === 200
  private readonly dinero: Dinero.Dinero;

  public static FromDollarAmount(dollars: number) {
    return new Money(Math.round(dollars * 100));
  }

  public static FromSerializable(serializableMoney: SerializableMoney): Money {
    return new Money(serializableMoney.amount);
  }

  constructor(cents: number) {
    this.dinero = Dinero({amount: cents, precision: 2, currency: 'USD'});
  }

  public getAmount(): number {
    return this.dinero.getAmount();
  }

  public formatted(): string {
    return this.dinero.toFormat('$0,0.00');
  }

  public isGreaterThan(otherMoney: Money): boolean {
    return this.dinero.greaterThan(otherMoney.dinero);
  }

  public isGreaterOrEqualThan(otherMoney: Money): boolean {
    return this.dinero.greaterThanOrEqual(otherMoney.dinero);
  }

  public add(input: Money): Money {
    const updatedDinero = this.dinero.add(input.dinero);
    return new Money(updatedDinero.getAmount());
  }

  public subtract(input: Money): Money {
    const updatedDinero = this.dinero.subtract(input.dinero);
    return new Money(updatedDinero.getAmount());
  }

  public multiply(multiplier: number): Money {
    const updatedDinero = this.dinero.multiply(multiplier);
    return new Money(updatedDinero.getAmount());
  }

  public divide(divisor: number): Money {
    const updatedDinero = this.dinero.divide(divisor);
    return new Money(updatedDinero.getAmount());
  }

  public toUnit(): number {
    return this.dinero.toUnit();
  }

  public toJSON(): SerializableMoney {
    return {
      amount: this.dinero.getAmount()
    };
  }

  public static FromDollarString(dollars: string): Money {
    return this.FromDollarAmount(parseFloat(dollars));
  }
}
