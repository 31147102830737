import {PipelineButtonText} from '../../../pages/sales-activity/salesActivityFunctions';
import {SalesActivityPipeline, SalesActivityTable} from './SalesActivityTable';
import {SalesActivityPipelineBuilder} from './SalesActivityPipelineBuilder';
import {API} from '../../../lib/api/ordoApi';

export class PremiumSalesActivityTable extends SalesActivityTable {

  public buildPipeline(): SalesActivityPipeline {
    return new SalesActivityPipelineBuilder(this)
      .withNonQualifiedLead()
      .withProspectBuyers()
      .withVerifiedPurchasers()
      .withStalePurchasers()
      .withPendingOrders()
      .withAwaitingDelivery()
      .withCollectPayment()
      .withLostPurchasers()
      .build();
  }

  public buyerAction() {
    return this.stageOrder;
  }

  public buyerActionText() {
    return PipelineButtonText.STAGE_ORDER;
  }

  fetchPendingOrders(api: API, id: string, setSalesActivityPendingOrders: Function, errorCallback: Function) {
    this.salesActivityViewModel.fetchPendingOrders(api, id, setSalesActivityPendingOrders, errorCallback);
  }

  fetchAwaitingDeliveryOrders(api: API, id: string, setSalesActivityConfirmedOrders: Function, errorCallback: Function) {
    this.salesActivityViewModel.fetchConfirmedOrders(api, id, setSalesActivityConfirmedOrders, errorCallback);
  }
}