import React, {FC, useContext, useEffect, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import OrderEntryList from '../components/order-entry/OrderEntryList';
import OrderEntryCart from '../components/order-entry/OrderEntryCart';
import '../../scss/ordo/order-entry-page.scss';
import {API} from '../../lib/api/ordoApi';
import {useAPI} from '../../context/OrdoApiContext';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import OrdoSpinner from '../components/OrdoSpinner';
import {UserSessionContext} from '../../context/UserSessionContext';
import {ProductWithAvailability} from '../../models/productWithAvailability';
import CheckoutPage from './checkout/CheckoutPage';
import {OrderEntryStep, OrderEntryViewModel} from '../../application-models/order-entry/OrderEntryViewModel';
import {CartItemType} from '../../models/order-entry/CartItem';
import {OrderEntryCartContext} from '../../context/OrderEntryCartContext';
import OrdoPageTitle from '../components/common/OrdoPageTitle';
import {OrderStatus} from '../../models/order-entry/Order';
import useOrganizationRedirect from '../../hooks/useOrganizationRedirect';
import {AccountLocation} from '../../models/Account';
import {isMobileResolution, Resolution} from '../../context/resolutions';
import {useResolution} from '../../context/UseResolutions';
import {MobileOrderEntry} from './mobile/MobileOrderEntry';
import {OrderPrefilledMode} from '../../application-models/order-entry/order-entry-mode';


type ExternalPageState = {
  state: {
    accountLocation: AccountLocation | undefined,
    orderId: string | undefined,
    newStatus?: OrderStatus,
    accountId?: string | undefined,
    orderPrefillMode: OrderPrefilledMode
  }
};
const OrderEntryPage: FC<RouteComponentProps>  = ({location}: RouteComponentProps) => {

  const api: API = useAPI();
  const {validateOrRedirect} = useOrganizationRedirect();
  const userSession = useContext(UserSessionContext);
  const orderEntryCartContextData = useContext(OrderEntryCartContext);
  const organization = userSession.currentOrganization()!;
  const [viewModel, setViewModel] = useState(OrderEntryViewModel.empty(api));
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const resolution: Resolution = useResolution();
  const inMobileResolution = isMobileResolution(resolution);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const organizationHasLicenses = organization.licenses.length > 0;

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }, [window.innerWidth, window.innerHeight]);

  const fetchData = async () => {

    // This prop comes from ReactRouter
    // when we stage an order in SalesActivityPage we set the state of it to be the selected location
    const {state}: ExternalPageState = location;
    const stagingOrderAccountLocation = state?.accountLocation;
    const accountLocation = stagingOrderAccountLocation || viewModel.selectedAccountLocation;
    const orderId = state?.orderId;
    const newStatus = state?.newStatus;
    const accountId = state?.accountId;
    const orderPrefillMode = state?.orderPrefillMode;

    if(organizationHasLicenses) {
      showSpinner();
      OrderEntryViewModel
        .initialize(api, organization, orderEntryCartContextData, accountLocation, orderId, newStatus, accountId, orderPrefillMode)
        .then(setViewModel)
        .finally(hideSpinner);
    }
  };

  useEffect(() => {
    validateOrRedirect(fetchData);
  }, [userSession.currentOrganization()]);

  const updateCart = (product: ProductWithAvailability, quantity: number, type: CartItemType) => {
    const updatedViewModel = viewModel.updateCart(product, quantity, type);
    setViewModel(updatedViewModel);
  };

  const updateAccountLocation = (accountLocation: AccountLocation) => {
    showSpinner();
    viewModel.updateSelectedAccountLocation(organization.id, accountLocation)
      .then( (initializedViewModel) => {
        setViewModel(initializedViewModel);
      }).finally(
        hideSpinner
      );
  };

  const goToVerifyOrder = async () => {
    showSpinner();
    const newVm = await viewModel.gotToVerifyOrder(organization.id);
    setViewModel(newVm);
    hideSpinner();
  };

  function renderMobileOrderEntry() {
    const landscapeMobile = screenWidth > screenHeight;
    return <MobileOrderEntry
      updateAccountLocation={updateAccountLocation}
      updateCart={updateCart}
      orderEntryViewModel={viewModel}
      updateViewModel={setViewModel}
      landscapeMode={landscapeMobile}
    />;
  }

  function RenderStep() {
    switch (viewModel.currentStep) {
    default:
      return (
        <div className="order-entry-page">
          <OrdoPageTitle title='order entry' buttons={[]}/>

          <div className="page-content-body">
            {organizationHasLicenses && <>
              <div className='order-entry-page-container'>
                <OrderEntryList
                  updateCart={updateCart}
                  updateAccountLocation={updateAccountLocation}
                  orderEntryViewModel={viewModel}
                  updateViewModel={setViewModel}
                />
                <OrderEntryCart
                  goToVerifyOrder={goToVerifyOrder}
                  orderEntryViewModel={viewModel}
                  updateCart={updateCart}
                />
              </div>
            </>}
            {!organizationHasLicenses && <span>Please, enable an organization license to create an order</span>}
          </div>
        </div>
      );
    case OrderEntryStep.VerifyOrder:
    case OrderEntryStep.DiscountsAndPricing:
      return (
        <CheckoutPage
          orderEntryViewModel={viewModel}
          updateAccountLocation={updateAccountLocation}
          updateViewModel={setViewModel}/>
      );
    }
  }

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      {inMobileResolution ? renderMobileOrderEntry() : RenderStep()}
    </OrdoSpinner>
  );
};

export default withRouter(OrderEntryPage);
