import FormError, {FormFields, ProductFormFields} from '../errors/form_error';
import InvalidField from '../errors/invalid_field';
import {API} from '../lib/api/ordoApi';
import {Brand} from '../models/Brand';
import {OrdoImage} from '../models/OrdoImage';
import ProductCategory, {CategoryNames} from '../models/ProductCategory';
import {Genetics, ProductMeasureUnit} from '../models/Product';
import BusinessError from '../errors/domain_errors';

export enum AdvertisedUnits {
  mg = 'mg',
  percentage = '%',
  NONE = ''
}

function nameAndValue(name: string, value: number) {
  return {name: name, value: value};
}
export const ProductUnits = [ProductMeasureUnit.GRAMS, ProductMeasureUnit.MILLIGRAMS];

export const ProductGenetics = [
  nameAndValue('Sativa', Genetics.SATIVA),
  nameAndValue('Indica', Genetics.INDICA),
  nameAndValue('Hybrid', Genetics.HYBRID),
];

export type ProductInformationInput = {
  name?: string
  brand?: Brand
  category?: ProductCategory,
  image?: OrdoImage | null
  price?: number,
  unitsPerCase?: number,
  consumerUnitSizeAmount?: number,
  consumerUnitSizeUnit?: ProductMeasureUnit,
  genetics?: Genetics,
  advertisedTHC?: number,
  advertisedCBD?: number,
  preRolls?: number,
  unitSaleOnly?: boolean
  customerSKU?: string,
}

export const CATEGORIES_MG = [
  CategoryNames.VAPORIZER_ALL_IN_ONE,
  CategoryNames.VAPORIZER_CARTRIDGE,
  CategoryNames.EDIBLES,
  CategoryNames.DRINKS,
  CategoryNames.TOPICALS_AND_TINCTURES,
];

export const CATEGORIES_PERCENTAGE = [
  CategoryNames.FLOWER,
  CategoryNames.PRE_GROUND_FLOWER,
  CategoryNames.LIVE_RESIN,
  CategoryNames.SAUCE,
  CategoryNames.CRUMBLE,
  CategoryNames.PRE_ROLL,
  CategoryNames.DIAMONDS,
];

export type ProductFormInput = {
  name: string,
  brand: Brand,
  category: ProductCategory,
  image: OrdoImage | null
  price: number,
  unitsPerCase: number,
  unitSaleOnly: boolean,
  caseSaleOnly: boolean,
  consumerUnitSizeAmount?: number,
  consumerUnitSizeUnit?: ProductMeasureUnit,
  genetics?: Genetics,
  preRolls?: number,
  imageUrl?: string,
  customerSKU?: string,
}

export class ProductFormViewModel {
  public productInformation: ProductFormInput;
  protected errors: FormError;
  protected readonly api: API;
  protected actionInProgress: boolean;

  constructor(viewModelInput: ProductFormInput, errors: FormError, api: API, creationInProgress: boolean) {
    this.productInformation = viewModelInput;
    this.errors = errors;
    this.api = api;
    this.actionInProgress = creationInProgress;
  }

  public hasErrorFor(input: ProductFormFields) {
    return this.errors.hasErrorForInput(input);
  }

  public errorMessage(input: ProductFormFields) {
    return this.errors.errorMessage(input);
  }

  public updateErrors(invalidFields: InvalidField[]) {
    const updatedFormError = FormError.withErrors(invalidFields);
    return this.new(this.productInformation, updatedFormError, this.api, false);
  }

  public get category() {
    return this.productInformation.category;
  }

  public get inProgress() {
    return this.actionInProgress;
  }

  public hasErrors() {
    return this.errors.hasError();
  }

  public invalidFields() {
    const invalidFields = [];
    const isRequired = !this.productIsAccessories();

    if (!this.productInformation.name) invalidFields.push(this.invalidField('name'));
    if (!this.productInformation.price) invalidFields.push(this.invalidField('price'));
    if (!this.productInformation.name) invalidFields.push(this.invalidField('customerSKU'));
    if (!this.productInformation.unitsPerCase && !this.productInformation.unitSaleOnly) invalidFields.push(this.invalidField('unitsPerCase'));
    if (!this.productInformation.genetics && isRequired) invalidFields.push(this.invalidField('genetics'));
    if (!this.productInformation.consumerUnitSizeUnit && isRequired) invalidFields.push(this.invalidField('consumerUnitSizeUnit'));
    if ((!this.productInformation.consumerUnitSizeAmount && this.productInformation.consumerUnitSizeAmount !== 0  || this.productInformation.consumerUnitSizeAmount < 0) && isRequired) invalidFields.push(this.invalidField('consumerUnitSizeAmount'));
    if (!this.productInformation.preRolls && this.requirePreRolls()) invalidFields.push(this.invalidField('preRolls'));
    if (this.productInformation.caseSaleOnly && this.productInformation.unitSaleOnly) invalidFields.push(this.invalidField('unit/case'));
    return invalidFields;
  }

  private invalidField(field: string) {
    let invalidFieldErrorMessage = '';
    switch (field) {
    case 'name':
      invalidFieldErrorMessage = 'A product should have a name';
      break;
    case 'image':
      invalidFieldErrorMessage = 'A product should have a picture';
      break;
    case 'price':
      invalidFieldErrorMessage = 'A product should have a price';
      break;
    case 'unitsPerCase':
      invalidFieldErrorMessage = 'A product should have units per case';
      break;
    case 'unit/case':
      invalidFieldErrorMessage = 'A product can not be sold as unit only and case only at the same time';
      return new InvalidField('unknown', invalidFieldErrorMessage);
    case 'customerSKU':
      invalidFieldErrorMessage = 'A product should have a SKU';
      break;
    case 'consumerUnitSizeAmount':
      invalidFieldErrorMessage = 'Unit size amount is invalid' ;
      break;
    default:
      invalidFieldErrorMessage = 'Required';
    }
    return new InvalidField(field as FormFields, invalidFieldErrorMessage);
  }

  public productIsAccessories() {
    return this.category.isAccessories();
  }

  public requirePreRolls() {
    return this.category.name === CategoryNames.PRE_ROLL;
  }

  public advertisedUnit() {
    const categoryName = this.category.name;
    if (CATEGORIES_MG.includes(categoryName as CategoryNames)) return AdvertisedUnits.mg;
    if (CATEGORIES_PERCENTAGE.includes(categoryName as CategoryNames)) return AdvertisedUnits.percentage;
    return AdvertisedUnits.NONE;
  }

  public setInProgress() {
    return this.new(this.productInformation, this.errors, this.api, true);
  }

  public async submit(orgId: string): Promise<ProductFormViewModel> {
    const invalidFields = this.invalidFields();
    if (invalidFields.length > 0) {
      return this.updateErrors(invalidFields);
    }
    try {
      await this.action(orgId);
      return this.new(this.productInformation, FormError.withoutError(), this.api, false);
    } catch (error) {
      const errorData = (error instanceof BusinessError) ? error.formError : FormError.unknown(error.message);
      return this.new(this.productInformation, errorData, this.api, false);
    }
  }

  public async action(_orgId: string): Promise<void> {
    throw new Error('Subclass responsibility');

  }

  public hasImage(): boolean {
    throw new Error('Subclass responsibility');
  }

  public new(_productInfo: ProductFormInput, _errors: FormError, _api: API, _inProgress: boolean): ProductFormViewModel {
    throw new Error('Subclass responsibility');
  }

  public updateProductInformation(productInfo: ProductFormInput): ProductFormViewModel {
    return this.new(productInfo, this.errors, this.api, this.actionInProgress);
  }

  public changeCategory(newCategory: ProductCategory) {
    const isAccesories = newCategory.name === CategoryNames.ACCESSORIES;
    const isPreRolls = newCategory.name === CategoryNames.PRE_ROLL;
    const productInput = this.productInformation;
    const resetIf = (value: any, shouldReset: boolean) => shouldReset ? undefined : value;
    const productInfo = {
      ...productInput,
      category: newCategory,
      consumerUnitSizeAmount: resetIf(productInput.consumerUnitSizeAmount, isAccesories),
      consumerUnitSizeUnit: resetIf(productInput.consumerUnitSizeUnit, isAccesories),
      genetics: resetIf(productInput.genetics, isAccesories),
      preRolls: resetIf(productInput.preRolls, !isPreRolls)
    };
    return this.updateProductInformation(productInfo);
  }

  public actionName(): string {
    throw new Error('Subclass responsibility');
  }

  public get imagePreview(): string {
    throw new Error('Subclass responsibility');
  }

  public isUnitSaleOnly() : boolean {
    throw new Error('Subclass responsibility');
  }

  public updateUnitSaleOnly(): ProductFormViewModel{
    this.productInformation.unitSaleOnly = !this.productInformation.unitSaleOnly;
    return this.new(this.productInformation, this.errors, this.api, this.inProgress);
  }

  public updateCaseSaleOnly(): ProductFormViewModel{
    this.productInformation.caseSaleOnly = !this.productInformation.caseSaleOnly;
    return this.new(this.productInformation, this.errors, this.api, this.inProgress);
  }

  public priceText(): string {
    return this.productInformation.unitSaleOnly ? 'unit price' : 'wholesale case price';
  }

  public unitsPerCaseValue(): string {
    return this.productInformation.unitSaleOnly ? '0' : this.productInformation.unitsPerCase.toString();
  }
}
