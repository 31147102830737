import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDotCircle} from '@fortawesome/free-solid-svg-icons/faDotCircle';
import {
  ActivityCompletionResponseProps,
  MultiValueActivityCompletionResponse,
} from '../../../../models/activity/responses/ActivityCompletionResponse';


export const MultipleChoiceResponse = ({
  response,
  updateResponse
}: ActivityCompletionResponseProps) => {
  const multipleChoiceResponse = response as MultiValueActivityCompletionResponse;

  const onOptionSelected = (option: number) => {
    const {selectedOptions} = multipleChoiceResponse.body;
    if(selectedOptions.includes(option)){
      multipleChoiceResponse.body.selectedOptions = [];
    } else {
      multipleChoiceResponse.body.selectedOptions = [option];
    }
    updateResponse(multipleChoiceResponse);
  };

  return <div className='checkbox-response'>
    {multipleChoiceResponse.options.map((option, index) => {
      const checked = multipleChoiceResponse.body.selectedOptions.includes(index);
      return <div className='checkbox-response__row' key={option}>
        <FontAwesomeIcon className={`option-icon ${checked ? 'checked' : ''}`} onClick={()=>onOptionSelected(index)} size="1x" icon={faDotCircle}/>
        <span>{option}</span>
      </div>;
    })}
  </div>;
};