import React, {useContext} from 'react';
import OrdoInformativeSearchableDropdown
  from '../../../components/common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {OrganizationLicense} from '../../../../models/OrganizationLicense';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import Contact from '../../../../models/order-entry/Contact';
import OrdoSpinner from '../../../components/OrdoSpinner';

type MobileOrderCheckoutContactAndDistributorProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateViewModel: (orderEntryViewModel: OrderEntryViewModel) => void,
}

export const MobileOrderCheckoutContactAndDistributor = ({orderEntryViewModel, updateViewModel} : MobileOrderCheckoutContactAndDistributorProps) => {
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const updateCurrentDistributor = async (selectableDistributor: OrganizationLicense) => {
    showSpinner();
    const updatedViewModel = await orderEntryViewModel.updateDistributor(organization.id, selectableDistributor);
    updateViewModel(updatedViewModel);
    hideSpinner();
  };

  const updateContact: (selectedContact: Contact) => void = (selectedContact: Contact) => {
    updateViewModel(orderEntryViewModel.updateSelectedContact(selectedContact));
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="mobile-order-entry-header__contact-and-distributor">
        <OrdoInformativeSearchableDropdown
          selectedOption={orderEntryViewModel.getSelectedContact()}
          options={orderEntryViewModel.getSelectableContacts()}
          onChangeSelectedOption={updateContact}
          placeholder='select a contact'
          addedStyles={{
            marginBottom: '0', valueContainer: {padding: '0'},
            control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
          }}
        />
        <OrdoInformativeSearchableDropdown
          selectedOption={orderEntryViewModel.getSelectedDistributor()}
          canEditValue={orderEntryViewModel.hasMoreThanOneDistributor()}
          options={orderEntryViewModel.getSelectableDistributors()}
          onChangeSelectedOption={(distributor) => updateCurrentDistributor(distributor)}
          placeholder='select a distributor'
          addedStyles={{
            marginBottom: '0', valueContainer: {padding: '0'},
            control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
          }}
        />
      </div>
    </OrdoSpinner>);
};
