import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt, faUser} from '@fortawesome/free-solid-svg-icons';
import {Activity} from '../../../../models/sales-activity/Activity';
import {DateFormatTemplate, OrdoDate} from '../../../../models/OrdoDate';
import {OrdoCheckbox} from '../../../components/OrdoCheckbox';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import {useAPI} from '../../../../context/OrdoApiContext';
import useOrdoToasts from '../../../../hooks/useOrdoToasts';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {ActivityModal} from '../../../components/modals/ActivityModal';
import Account from '../../../../models/Account';
import {AccountActivityType} from '../../../../models/AccountActivity';
import {ActivityCompleteModal} from '../../../components/modals/ActivityCompleteModal';
import { ActivityCompletionResponse } from '../../../../models/activity/responses/ActivityCompletionResponse';
import { iconsOptions } from '../../../activity-manager/components/activityTemplatesManager/iconsOptions';
import {ActivityResponsesDisplay} from '../../../components/activities/ActivityResponsesDisplay';

type ActivityForAccountProps = {
  activity: Activity,
  organizationId: string,
  account: Account,
  index: number,
  onDelete: Function,
  onEdit: Function,
}

export const ActivityForAccountActivity = ({activity, organizationId, account, index, onDelete, onEdit}: ActivityForAccountProps) => {
  const [expandedCard, setExpandedCard] = useState(false);
  const [activityInfo, setActivityInfo] = useState(activity);
  const [displayDeleteConfirmationModal, setDisplayDeleteConfirmationModal] = useState(false);
  const [displayEditActivityModal, setDisplayEditActivityModal] = useState(false);
  const [openCompleteActivityWizard, setOpenCompleteActivityWizard] = useState(false);
  const [openMarkActivityAsPending, setOpenMarkActivityAsPending] = useState(false);
  const api = useAPI();
  const {successToast, errorToast} = useOrdoToasts();
  const {spinnerVisibility, showSpinner, hideSpinner} = useSpinnerToggle();

  useEffect(() => setActivityInfo(activity), [index]);

  const deleteActivity = () => {
    showSpinner();
    api.deleteActivity(organizationId, activity.accountId, activity.id)
      .then(() => {
        onDelete(index);
        successToast('Activity deleted');
      })
      .catch(() => errorToast('Could not delete Activity'))
      .finally(() => {
        setDisplayDeleteConfirmationModal(!displayDeleteConfirmationModal);
        hideSpinner();
      });
  };

  const editActivity = (updatedActivity: Activity) => {
    setActivityInfo(updatedActivity);
    onEdit(index, updatedActivity, AccountActivityType.ACTIVITY, updatedActivity.date);
  };

  const renderContacts = () => {
    if(activity.contacts.length > 0) {
      return (
        <div>
          <FontAwesomeIcon className="contacts-icon" onClick={() => {}} size="sm" icon={faUser}/>
          <span>{`${activity.contacts[0].name} ${activity.contacts.length > 1 ? `+${activity.contacts.length - 1}` : ''}`}</span>
        </div>);
    }
    return <></>;
  };


  const closeCompleteActivityModal = () => setOpenCompleteActivityWizard(false);
  const closeMarkActivityAsPendingModal = () => setOpenMarkActivityAsPending(false);


  const updateActivity = (completed: boolean, responses: ActivityCompletionResponse[]) => {
    api.updateActivity({...activity, completed: completed, responses: responses}, organizationId, account.id, activity.id)
      .then((updatedActivity) => {
        setActivityInfo(updatedActivity);
        editActivity(updatedActivity);
        closeMarkActivityAsPendingModal();
        closeCompleteActivityModal();
      });
  };
  const toggleComplete = () => {
    if(!activity.completed) {
      if((activity.activityTemplate!.questions.length > 0)) {
        setOpenCompleteActivityWizard(true);
      } else {
        updateActivity(true, []);
      }
    }
    else setOpenMarkActivityAsPending(true);
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="account-activity-card" onClick={() => setExpandedCard(!expandedCard)} role="presentation">
        <div className='content'>
          <div className={`icon-container activities ${activity.completed ? '' : 'incomplete'}`}>
            <FontAwesomeIcon icon={iconsOptions[activity.activityTemplate.iconName]} size="2x" />
          </div>
          <div className="information">
            <div className="collapsed">
              <div>
                <div className="title-and-complete">
                  <OrdoCheckbox notToggleOnClick key={`activity-page-check-activity-${activityInfo.id}`} id={`activity-page-check-activity-${activityInfo.id}`} checked={activityInfo.completed} onChange={toggleComplete}/>
                  <div className="activity-title activity-check" title={activity.activityTemplate.name}>
                    <span >{activity.activityTemplate.name}</span>
                  </div>
                </div>
                <div className="note-information">
                  <span>{`${OrdoDate.from(activity.date).format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY)} ${activity.time ? `, ${OrdoDate.from(activity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : ''} ${activity.createdBy ? (`• ${activity.createdBy.firstName} ${activity.createdBy.lastName}`) : ''}`}</span>
                  {renderContacts()}
                </div>
              </div>
              <div className="actions">
                <FontAwesomeIcon className="icon" onClick={(event) => {
                  event.stopPropagation();
                  setDisplayEditActivityModal(!displayEditActivityModal);
                }} size="lg" icon={faEdit}/>

                <FontAwesomeIcon className="icon" onClick={(event) => {
                  event.stopPropagation();
                  setDisplayDeleteConfirmationModal(!displayDeleteConfirmationModal);
                }} size="lg" icon={faTrashAlt}/>
              </div>
            </div>
            {expandedCard &&
          <div className="description">
            <span>{activity.description}</span>
          </div>
            }
          </div>
          <ConfirmationModal show={displayDeleteConfirmationModal}
            onClose={() => setDisplayDeleteConfirmationModal(!displayDeleteConfirmationModal)}
            onSubmit={deleteActivity}
            confirmationText="are you sure you want to delete the activity?"/>
          {displayEditActivityModal && <ActivityModal canEditAccount={false}
            activity={{...activityInfo, accountName: account.name}}
            isOpen={displayEditActivityModal}
            onClose={() => setDisplayEditActivityModal(!displayEditActivityModal)}
            onSubmit={editActivity}
            organizationId={organizationId}/>}
          <ActivityCompleteModal activity={activityInfo} isOpen={openCompleteActivityWizard} onClose={closeCompleteActivityModal} onSubmit={(responses)=>updateActivity(true, responses)}/>
          {openMarkActivityAsPending && <ConfirmationModal
            show={openMarkActivityAsPending}
            onClose={closeMarkActivityAsPendingModal}
            onSubmit={()=>updateActivity(false, [])}
            confirmationText="marking the activity as incomplete will delete any responses, are you sure you want to continue?"
            actionText='mark incomplete'
          />}
        </div>
        <ActivityResponsesDisplay activity={activity} accountName={account.name}/>
      </div>
    </OrdoSpinner>);
};
