import React, {useState} from 'react';
import '../../../scss/ordo/buttons.scss';
import  OrdoModal from '../common/OrdoModal';
import {useAPI} from '../../../context/OrdoApiContext';
import EditUserPermissionViewModel from '../../../application-models/EditUserPermissionViewModel';
import OrdoSelector from '../common/OrdoSelector';
import {roleOption, Role} from '../../../models/Roles';
import { Organization } from '../../../models/Organization';
import GeneralErrorMessage from '../common/GeneralErrorMessage';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import { ActualMember } from '../../../models/member/ActualMember';


const EditUserPermissionModal = ({onClose, onSubmit, isOpen, member, organization}:
  { isOpen: boolean,
    onSubmit: Function,
    onClose: Function,
    member: ActualMember,
    organization: Organization,
  }) => {
  const api = useAPI();
  const [viewModel, setViewModel] = useState(new EditUserPermissionViewModel({ordoAPI: api, member: member, selectedRole: member.role, organization: organization}));
  const selectRole = (aRole: Role) => {
    setViewModel(viewModel.updateRole(aRole));
  };
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const changePermission = () => {
    if(!viewModel.shouldUpdate()) {
      onClose();
      return Promise.resolve();
    }
    showSpinner();
    return viewModel.editUserRole().then((vm)=> {
      if (!vm.hasError()) onSubmit();
      else setViewModel(vm);
    }).finally(hideSpinner);
  };

  const formId = 'modal-form';

  const options = () => {
    return [
      roleOption('Admin'),
      roleOption('Sales Rep'),
    ];
  };

  return member && <OrdoSpinner showSpinner={spinnerVisibility}>
    <OrdoModal
      formId={formId}
      onClose={onClose}
      show={isOpen}
      title={`Edit Permissions for ${member.getName()}`}
      actionText='Confirm'
      dataTestId='edit-user-permission-modal'
    >
      <GeneralErrorMessage viewModel={viewModel}/>
      <form id={formId}
        onSubmit={(event) => {
          event.preventDefault();
          changePermission();
        }}>
        <div className="form-group ">
          <p>Set the permission for this user</p>
          <div className="form-group mt-3 d-flex justify-content-center">
            <OrdoSelector selected={viewModel.selectedRole} size='sm' onChange={selectRole} options={options()}/>
          </div>
        </div>
      </form>
    </OrdoModal>
  </OrdoSpinner>;
};

export default EditUserPermissionModal;
