import React, {useEffect, useState} from 'react';
import {debounce} from 'lodash';
import OrdoSpinner from '../../components/OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrderHistoryViewModel, {SelectableFilter} from '../../../application-models/OrderHistoryViewModel';
import {MobileOrderHistoryOrderList} from './MobileOrderHistoryOrderList';
import '../../../scss/ordo/orderHistory/mobile/mobile-order-history.scss';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import {StateFilter} from '../../activity-manager/components/activitiesPage/ActivitiesStateFilters';
import {OrdoPagination} from '../../components/pagination/OrdoPagination';

type MobileOrderHistoryProps = {
  orderHistoryViewModel: OrderHistoryViewModel
  onPageUpdate: (page: number) => void
  onStatusFilterChange: (selectableStatuses: SelectableFilter[]) => Promise<void>
  updateOrderHistoryViewModel: (viewModel: OrderHistoryViewModel) => void
  onUpdateOrder: Function,
}
export const MobileOrderHistory = ({orderHistoryViewModel, updateOrderHistoryViewModel, onStatusFilterChange, onPageUpdate, onUpdateOrder}: MobileOrderHistoryProps) => {
  const { spinnerVisibility} = useSpinnerToggle();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }, [window.innerWidth, window.innerHeight]);

  const isActiveStatusFilter = (status: string) => orderHistoryViewModel.selectedStatuses.map((filter) => filter.value).includes(status);
  const toggleStatusFilter = async (status: string) => {
    if(orderHistoryViewModel.selectedStatuses.map(s => s.value).includes(status)) {
      await onStatusFilterChange([]);
    }
    else {
      await onStatusFilterChange([{label: status, value: status}]);
    }
  };

  const filterByOrderNumberOrAccountName = debounce(async (orderNumberOrAccountName: string) => {
    updateOrderHistoryViewModel(await orderHistoryViewModel.filterByOrderNumberOrAccountName(orderNumberOrAccountName));
  }, 500);

  return(<div>
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='order-history-page'>
        <div className="mobile-order-history-content-body">
          <div className='mobile-order-history-page-filters'>
            <div className="account-and-order-filter">
              <OrdoInput
                bottomMargin={false}
                inputName='search-order-input'
                value={orderHistoryViewModel.searchedOrderAccountName}
                placeholder='search order number or account name'
                inputSize='large'
                onChange={(value) => filterByOrderNumberOrAccountName(value)}
              />
            </div>
            <div className="order-status-filters">
              <StateFilter text="pending" checked={isActiveStatusFilter('pending')} onClick={() => toggleStatusFilter('pending')}/>
              <StateFilter text="await deliv" checked={isActiveStatusFilter('awaiting delivery')} onClick={() => toggleStatusFilter('awaiting delivery')}/>
              <StateFilter text="delivered" checked={isActiveStatusFilter('delivered')} onClick={() => toggleStatusFilter('delivered')}/>
              <StateFilter text="paid" checked={isActiveStatusFilter('paid')} onClick={() => toggleStatusFilter('paid')}/>
              <StateFilter text="cancelled" checked={isActiveStatusFilter('cancelled')} onClick={() => toggleStatusFilter('cancelled')}/>
            </div>
          </div>
          <div className='mobile-order-history-page-orders-container'>
            <MobileOrderHistoryOrderList orders={orderHistoryViewModel.filteredOrders} landscape={screenWidth > screenHeight} onUpdateOrder={onUpdateOrder}/>
          </div>
          <>
            {orderHistoryViewModel.filteredOrders.length > 0 && <div className='order-history-footer'>
              <OrdoPagination
                currentPage={orderHistoryViewModel.page}
                totalOfPages={orderHistoryViewModel.totalAmountOfPages()}
                onPageChange={onPageUpdate}/>
            </div>}
          </>
        </div>
      </div>
    </OrdoSpinner>
  </div>);
};