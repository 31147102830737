import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';
import {ActivityQuestionType} from '../../../../models/sales-activity/ActivityQuestion';

export const StaticQuestionTypePreview = ({questionType} : {questionType: ActivityQuestionType}) => {

  const isTextField = () => questionType === ActivityQuestionType.NumericResponse || questionType === ActivityQuestionType.TextResponse || questionType === ActivityQuestionType.DollarValue;
  const hiddenBorders = () => !isTextField();

  return(
    <>
      <div className={`response-preview ${hiddenBorders() ? 'hidden' : ''}`}>
        {isTextField() && <span>{questionType}</span>}
        {questionType === ActivityQuestionType.DateTime && <div className='date-preview'>
          <FontAwesomeIcon className="date-icon" onClick={() => {}} size="1x" icon={faCalendarAlt}/>
          <span>select a date</span>
        </div>
        }
        {questionType === ActivityQuestionType.FileUpload && <div className='upload-file-preview'>
          <span className='upload-file-preview-text'>upload a file</span>
        </div>
        }
        {questionType === ActivityQuestionType.ProductDropdown && <div className='product-dropdown-preview'>
          <span className='product-dropdown-text'>choose a product</span>
          <FontAwesomeIcon className='icon' icon={faCaretDown}/>
        </div>
        }
      </div>
    </>
  );
};