import React, {FC, useContext, useState} from 'react';
import {generatePath, RouteComponentProps, useHistory, withRouter} from 'react-router-dom';
import {API} from '../lib/api/ordoApi';
import OrdoButton from './components/common/OrdoButton';
import '../scss/ordo/accept-invitation-page.scss';
import OrdoPageTitle from './components/common/OrdoPageTitle';
import AcceptInvitationViewModel from '../application-models/accept-Invitation-view-model';
import GeneralErrorMessage from './components/common/GeneralErrorMessage';
import ROUTE_NAMES from '../routes/ROUTE_NAMES';
import {UserSessionContext} from '../context/UserSessionContext';
import useSpinnerToggle from '../hooks/useSpinnerToggle';
import OrdoSpinner from './components/OrdoSpinner';

type AcceptInvitationPageProps = {api: API} & RouteComponentProps<{invitationTokenId: string}>

const AcceptInvitationPage: FC<AcceptInvitationPageProps> = ({api, match}: AcceptInvitationPageProps) => {
  const history = useHistory();
  const [viewModel, setViewModel] = useState(
    new AcceptInvitationViewModel(api, match.params.invitationTokenId)
  );
  const {userSession} = useContext(UserSessionContext);
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const acceptInvitation = async () => {
    showSpinner();
    const user = await viewModel.userExists();
    const params = {invitationTokenId: match.params.invitationTokenId};

    if (!viewModel.hasError() && !user!.exists) {
      const state = {email: user.email};
      const to = generatePath(ROUTE_NAMES.ACCEPT_INVITATION_AND_JOIN_ORDO, params);
      history.push(to, state);
      hideSpinner();
      return;
    }

    if(!viewModel.isLoggedIn(userSession.user)) {
      const to = generatePath(ROUTE_NAMES.LOGIN_AND_ACCEPT_INVITATION, params);
      history.push(to);
      hideSpinner();
      return;
    }

    const newViewModel = await viewModel.acceptInvitation();
    setViewModel(newViewModel);

    if (!newViewModel.hasError()) {
      history.push(ROUTE_NAMES.PROFILE);
    }
    hideSpinner();
  };

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="accept-invitation-page">
        <GeneralErrorMessage viewModel={viewModel}/>
        <div className="accept-invitation-page__card card shadow">
          <OrdoPageTitle title="Welcome to Ordo!" centered buttons={[]}/>
          <div className="d-flex justify-content-center">
            <OrdoButton dataTestId="accept-invitation-button"
              disabled={false}
              text="Accept invitation"
              category="primary"
              onClick={acceptInvitation}/>
          </div>
        </div>
      </div>
    </OrdoSpinner>
  );
};

export default withRouter(AcceptInvitationPage);
