import FormError, { OrganizationLicenseFormFields} from '../../errors/form_error';
import InvalidField from '../../errors/invalid_field';


export type CreateOrganizationLicenseInput = {
  externalLicenseId: string,
  streetAddressLine2: string,
  streetAddressLine1: string,
  city: string,
  zipCode: string,
  state: string,

}
export type OrganizationLicenseFields = Partial<CreateOrganizationLicenseInput>;


export class CreateOrganizationLicenseViewModel {
  private errors: FormError;
  public organizationLicenseData: CreateOrganizationLicenseInput

  private constructor(orgLicenseInput: CreateOrganizationLicenseInput, errors?: FormError) {
    this.organizationLicenseData = orgLicenseInput;
    this.errors = errors || FormError.withoutError();
  }

  public static empty(organizationState: string) {
    return new CreateOrganizationLicenseViewModel({
      externalLicenseId: '',
      streetAddressLine2: '',
      streetAddressLine1: '',
      city: '',
      zipCode: '',
      state: organizationState,
    });
  }


  public updateInformation(fields: OrganizationLicenseFields) {
    return new CreateOrganizationLicenseViewModel({
      ...this.organizationLicenseData,
      ...fields
    }, this.errors);
  }

  public hasError() {
    return this.errors.hasError();
  }

  public hasErrorFor(input: OrganizationLicenseFormFields) {
    return this.errors.hasErrorForInput(input);
  }

  public errorMessage(input: OrganizationLicenseFormFields) {
    return this.errors.errorMessage(input);
  }

  public get licenseNumber() {
    return this.organizationLicenseData.externalLicenseId;
  };

  public get streetAddressLine2() {
    return this.organizationLicenseData.streetAddressLine2;
  };

  public get streetAddressLine1() {
    return this.organizationLicenseData.streetAddressLine1;
  };

  public get city() {
    return this.organizationLicenseData.city;
  };

  public get zipCode() {
    return this.organizationLicenseData.zipCode;
  };

  public get state() {
    return this.organizationLicenseData.state;
  };

  public validateFields() {
    if(!this.licenseNumber) this.errors.addError(new InvalidField('licenseNumber', 'field required'));
    if(!this.city)this.errors.addError(new InvalidField('city', 'field required'));
    if(!this.state) this.errors.addError(new InvalidField('state', 'field required'));
  }

  public shouldDisableButton() {
    return !this.licenseNumber ||
    !this.city ||
    !this.state;
  }

  public disabledButtonText() {
    if(!this.licenseNumber) return 'missing license number';
    if(!this.city)return 'missing city';
    if(!this.state) return 'missing state';
    return '';
  }
}