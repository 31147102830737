import React from 'react';
import '../../../scss/ordo/buttons.scss';
import OrdoButton, {OrdoButtonProps} from './OrdoButton';
import {isMobileResolution} from '../../../context/resolutions';
import OrdoLittleButton from './OrdoLittleButton';
import {useResolution} from '../../../context/UseResolutions';

const OrdoMobileFriendlyButton = ({disabled, text, category, onClick, dataTestId, firstChild, secondChild, formName, squared,
  forceLowerCase = true, style= {}, disabledText}: OrdoButtonProps) => {
  const resolution = useResolution();

  return <>{
    isMobileResolution(resolution)
      ? <OrdoLittleButton disabled={disabled} text={text} category={category} onClick={onClick}
        dataTestId={dataTestId} firstChild={firstChild} secondChild={secondChild}
        formName={formName} squared={squared} forceLowerCase={forceLowerCase}
        style={style} disabledText={disabledText}/>
      : <OrdoButton disabled={disabled} text={text} category={category} onClick={onClick}
        dataTestId={dataTestId} firstChild={firstChild} secondChild={secondChild}
        formName={formName} squared={squared} forceLowerCase={forceLowerCase}
        style={style} disabledText={disabledText}/>
  }
  </>;
};

export default OrdoMobileFriendlyButton;
