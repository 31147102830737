import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import SetNewPassoword from './SetNewPassoword';
import VerifyEmail from './VerifyEmail';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import useQueryParams from '../../hooks/useQueryParams';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import OrdoSpinner from '../components/OrdoSpinner';
import OrdoPageTitle from '../components/common/OrdoPageTitle';

const AccountManagementPage = () => {
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const query = useQueryParams();
  const {errorToast} = useOrdoToasts();
  const [children, setChildren] = useState(<div/>);

  const validateRedirectLink = (): { oobCode: string, mode: string } => {
    const code = query.get('oobCode');
    const mode = query.get('mode');
    if (code === '' || mode === '') {
      errorToast('Invalid redirect link url');
    }
    return {oobCode: code!, mode: mode!};
  };

  useEffect(() => {
    showSpinner();
    const {oobCode, mode} = validateRedirectLink();
    if (mode === 'resetPassword') {
      setChildren(<SetNewPassoword code={oobCode}/>);
    }
    if (mode === 'signIn') {
      setChildren(<VerifyEmail redirectLink={window.location.href}/>);
    }
    hideSpinner();
  }, []);


  return (<OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="row justify-content-center" style={{margin: 0}}>
      <div className="col-sm-8 col-lg-4">
        <div className="card shadow zindex-100 mb-0 login-card">
          <div className="card-body px-md-5 py-5">
            <div className="mb-3">
              <OrdoPageTitle centered title="Account Management" buttons={[]}/>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </OrdoSpinner>);
};

export default withRouter(AccountManagementPage);
