import React, {useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faStickyNote} from '@fortawesome/free-solid-svg-icons';
import '../../../scss/ordo/orderEntry/order-line-item-note.scss';
import {v4} from 'uuid';
import {OrdoPopup} from '../common/OrdoPopup';
import OrdoTextArea from '../common/OrdoTextArea';
import {OrderVersionLineItem} from '../../../models/order-entry/OrderVersionLineItem';

type LineItemNoteProps = {
  item: OrderVersionLineItem,
}


export const LineItemNote = ({item} :LineItemNoteProps) => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [{x, y}, setPopupPosition] = useState<{x: string, y: string}>({x: '0', y: '0'});
  const noteRef = useRef<HTMLInputElement>(null);
  const closePopup = ()=>{
    setDisplayPopup(false);
  };

  return <div className='order-line-item-note' ref={noteRef}>
    {
      item.notes
        ? <div className="options">
          <FontAwesomeIcon onClick={(event) => {
            event.stopPropagation();
            setDisplayPopup(!displayPopup);
            // the popup doesn't display correctly if its relative to something inside OrdoTable,
            // so we display it as top level popup and calculate the position where it should appear on screen
            const rect = document.body.getBoundingClientRect();
            const noteRect = noteRef.current?.getBoundingClientRect()!;
            setPopupPosition({x: `${rect.width-noteRect.x - 20}px`, y: `${noteRect.y+20}px`});
          }}
          className='edit' icon={faStickyNote}/>
        </div>
        : <></>
    }
    {displayPopup &&
    <OrdoPopup id={v4()} title='View Note' popupSide='right' sidePosition={x}
      popupVerticalPlacement='top'  verticalPosition={y} onClose={closePopup}>
      <div className='note-edit'>
        <OrdoTextArea
          style={{
            margin: '1em 0 .5em',
            height: '6em'
          }} handleChange={() => {}}
          disabled
          placeholder='' value={item.notes}
        />
      </div>
    </OrdoPopup>
    }
  </div>;
};