import React from 'react';
import '../../../scss/ordo/loginPage/registration.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';
import {SubscriptionPlanCard} from './SubscriptionPlanCard';
import {OrganizationSubscriptionPlan, SUBSCRIPTION_PLANS} from './SubscriptionPlan';
import SubscriptionRegistration from '../../../models/subscriptionRegistration';
import OrdoButton from '../../components/common/OrdoButton';
import '../../../scss/ordo/loginPage/subscription-form.scss';
import {SquarePaymentInput} from './SquarePaymentInput';

interface SubscriptionRegistrationViewModel {
  subscriptionRegistration: SubscriptionRegistration,
}

type SubscriptionRegistrationFormProps = {
  viewModel: SubscriptionRegistrationViewModel,
  updatePlanRegistration: (input: SubscriptionRegistration) => void,
  onStepBack: ()=>void,
  onCallToAction:()=>void,
  isModal?: boolean
}

const SubscriptionRegistrationForm = ({viewModel, updatePlanRegistration, isModal, onStepBack, onCallToAction}: SubscriptionRegistrationFormProps) => {
  const {subscriptionRegistration} = viewModel;
  const updatePlan = (plan: OrganizationSubscriptionPlan) => updatePlanRegistration( new SubscriptionRegistration(plan, subscriptionRegistration.paymentToken));
  const updatePaymentToken = (paymentToken: string) => {
    updatePlanRegistration( new SubscriptionRegistration(subscriptionRegistration.selectedPlan,paymentToken));
  };

  const classNames = isModal ? 'card-body px-0 py-0' : 'card-body px-md-5 py-5';

  return <div className={`subscription-step ${classNames}`}>
    {!isModal ? <div className="registration-header">
      <h6 className="registration-header__title">Ordo Registration</h6>
      <p>Choose a plan</p>
    </div> :
      <div className="registration-header">
        <h4>Choose a plan</h4>
      </div>
    }

    <div className={`subscription-plans ${isModal ? 'is-modal' : ''}`}>
      {
        [SUBSCRIPTION_PLANS.starter, SUBSCRIPTION_PLANS.lite, SUBSCRIPTION_PLANS.pro, SUBSCRIPTION_PLANS.enterprise]
          .map((plan: OrganizationSubscriptionPlan) =>
            <SubscriptionPlanCard
              key={plan.type}
              plan={plan}
              isSelected={plan.type === subscriptionRegistration.selectedPlan.type}
              onSelectPlan={() => updatePlan(plan)}
            />)
      }
    </div>
    <div className="registration-footer">
      <SquarePaymentInput onPaymentMethodCreated={updatePaymentToken}/>
      <OrdoButton
        additionalClassName='registration-button'
        disabled={!viewModel.subscriptionRegistration.isValid()}
        disabledText={viewModel.subscriptionRegistration.disabledReason()}
        text="register"
        category="primary"
        onClick={onCallToAction}
        dataTestId="submit-organization-button"
      />
      <div className="back-and-create-buttons">
        <OrdoButton
          additionalClassName='registration-button'
          disabled={false}
          firstChild={<FontAwesomeIcon icon={faLongArrowAltLeft}/>}
          text="back" category="cancel"
          onClick={onStepBack}
          dataTestId="back-to-company-registration-step-button"/>
      </div>
    </div>
  </div>;

};

export default SubscriptionRegistrationForm;
