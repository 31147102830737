import React, {useState} from 'react';
import Select, {InputActionMeta} from 'react-select';
import { uniq } from 'lodash';
import {CARD_BORDER_COLOR, DARK_COLOR, PLACEHOLDER, PRIMARY_COLOR} from '../../../constants';
import '../../../scss/ordo/ordo-multiselect.scss';
import {ButtonOption} from './searchable-dropdown/ButtonOption';
import {CustomOptionComponent, CustomOptionComponentProps} from './searchable-dropdown/OrdoSearchableDropdown';

export type MultiSelectableOption<T> = {
  label: string,
  value: string,
  data?: T
}
export function toMultiSelectableOption(label: string, value: string, object: any) {
  return {label: label, value: value, data: object};
}

export function toInformativeMultiSelectableOption(label: string, subTitle: string, info: string, data: any) {
  return {
    label: label,
    subTitle: subTitle,
    info: info,
    value: label,
    data: data,
    isSelected: false,
  };
}
type OrdoMultiSelectProps = {
  options: MultiSelectableOption<any>[],
  selectedOptions: MultiSelectableOption<any>[],
  onChangeSelectedOption: (value: any, action: any) => void,
  placeholder?: string,
  dataTestId?: string,
  addedStyles?: any,
  selectMultipleOptionsAtOnce?: boolean,
  onAddItem?: Function,
  addItemText?: string,
  addItemDisabled?:boolean
  menuOptionCustomComponent?: CustomOptionComponent,
  disabled?: boolean
}

const OrdoMultiSelect = ({options, selectedOptions, onChangeSelectedOption, menuOptionCustomComponent, placeholder, dataTestId, addedStyles = {}, selectMultipleOptionsAtOnce = false, addItemDisabled, addItemText, onAddItem, disabled = false}: OrdoMultiSelectProps) => {
  const [inputValue, setInputValue ] = useState('');
  const styles = {
    container: (provided: any) => {
      return {
        ...provided,
        minWidth: '12em',
        width: '30%',
        marginRight: '2em',
        ...(addedStyles?.container || {})
      };
    },
    control: (provided: any, state: any) => {
      return {
        ...provided,
        maxHeight: '2em',
        overflow: 'hidden',
        overflowY: 'hidden',
        marginBottom: '0.4em',
        paddingLeft: '0.5rem',
        borderRadius: `${!state.menuIsOpen ? '2em' : '2em 2em 0 0'}`,
        border: `'0.1em solid ' ${CARD_BORDER_COLOR}`,
        boxShadow: `0 0 0.4em 0.1em ${CARD_BORDER_COLOR}`,
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent'
        },
        fontSize: '0.9em',
        color: `${DARK_COLOR}`,
        ...(addedStyles?.control || {})
      };
    },
    placeholder: (provided: any) => {
      return {
        ...provided,
        color: `${PLACEHOLDER}`,
        ...(addedStyles?.placeholder || {})
      };
    },
    multiValue: (provided: any) => {
      return {
        ...provided,
        width: '46%',
        ...(addedStyles?.multiValue || {})
      };
    },
    valueContainer: (provided: any) => {
      return {
        ...provided,
        overflow: 'hidden',
        overflowY: 'scroll',
        maxHeight: '4.5em',
        paddingBottom: '0.5em',
        ...(addedStyles?.valueContainer || {})
      };
    },
    indicatorsContainer: (provided: any) => {
      return {
        ...provided,
        maxHeight: '4em',
        ...(addedStyles?.indicatorsContainer || {})
      };
    },
    dropdownIndicator: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.dropdownIndicator || {})
      };
    },
    clearIndicator: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.clearIndicator || {})
      };
    },
    multiValueLabel: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.multiValueLabel || {})
      };
    },
    multiValueRemove: (provided: any) => {
      return {
        ...provided,
        ...(addedStyles?.multiValueRemove || {})
      };
    },
    indicatorSeparator: (provided: any) => {
      return {
        ...provided,
        maxHeight: '4em',
        ...(addedStyles?.indicatorSeparator || {})
      };
    },
    menu: (provided: any) => {
      return {
        ...provided,
        borderRadius: '0 0 2em 2em',
        border: `'0.1em solid ' ${CARD_BORDER_COLOR}`,
        zIndex: 50,
        backgroundColor:'white',
        boxShadow: `0 0 0.4em 0.2em ${CARD_BORDER_COLOR}`,
        borderColor: 'transparent',
        '&:hover': {
          borderColor: 'transparent'
        },
        width: '100%',
        paddingBottom: '1.5em',
        ...(addedStyles?.menu || {})
      };
    },
    option: (provided: any, state: any) => {
      return {
        ...provided,
        color: 'gray',
        '&:hover': {
          color: `${DARK_COLOR}`
        },
        backgroundColor: `${state.isFocused ? 'whitesmoke' : 'white'}`,
        fontSize: '0.9em',
        width: '99%',
      };
    },
    input: (provided: any) => {
      return {
        ...provided,
        paddingTop: '0'
      };
    },
  };

  const filterOptionSeparatedByCommas = ({ label, value }: MultiSelectableOption<any>, search: string) => {
    if(search.trim() === '') return true;

    const separatedInput = search.split(/[, ]+/);
    return separatedInput.some((input) =>
      input !== '' && (label.includes(input) || value.includes(input))
    );
  };

  const handleInputChanged = (value:string, inputEvent: InputActionMeta) => {
    if (inputEvent.action === 'input-change') {
      setInputValue(value);
    }
  };

  const selectAllMatching = () => {
    const searchedValues = inputValue.split(',').map(input => input.trim());
    return options.filter(option =>
      searchedValues.some(searchValue => option.label === searchValue)
    );
  };

  const handleSelectedOption = (value: any, event: any) => {
    let currentlySelectedOptions = value as MultiSelectableOption<any>[];
    const separatedInput = inputValue.split(',');

    if(selectMultipleOptionsAtOnce && (event.action === 'select-option') && separatedInput.length > 1 ) {
      currentlySelectedOptions = uniq([...currentlySelectedOptions, ...selectAllMatching()]);
      onChangeSelectedOption(currentlySelectedOptions, event);
    } else {
      onChangeSelectedOption(value, event);
    }

    if(event.action === 'select-option') {
      const filteredInput = separatedInput.filter(input =>
        !currentlySelectedOptions.some(option => option.label === input.trim())
      );
      setInputValue(filteredInput.length > 0 ? filteredInput.join(', ') : '');
    }
  };

  const addButtonOptionToOptions = () => [...options, {label: addItemText!, value: addItemText!, addLastButtonOption: true, onAddItem: onAddItem, addItemText: addItemText, disabled: addItemDisabled} as MultiSelectableOption<any>];

  const customButtonOption = (Component: CustomOptionComponent) =>
    ({innerRef, innerProps, data}: CustomOptionComponentProps) => {
      return data.addLastButtonOption ?
        <ButtonOption data={data}/> :
        <Component data={data} innerRef={innerRef} innerProps={innerProps}/>;
    };

  const getOption = () => onAddItem ? customButtonOption(menuOptionCustomComponent!) : menuOptionCustomComponent!;

  const getOptions = () => onAddItem ? addButtonOptionToOptions() : options;

  const getComponents = () => {
    let components = {};
    components = menuOptionCustomComponent ? {...components, Option: getOption()} : components;
    return components;
  };

  return (
    <Select
      dataTestId={dataTestId}
      styles={styles}
      theme={theme => ({
        ...theme,
        border: `1px solid ${CARD_BORDER_COLOR}`,
        colors: {
          ...theme.colors,
          primary: PRIMARY_COLOR,
        },
      })}
      options={getOptions()}
      components={getComponents()}
      onChange={handleSelectedOption}
      value={selectedOptions || ''}
      placeholder={placeholder}
      isDisabled={disabled}
      isMulti
      filterOption={selectMultipleOptionsAtOnce ? filterOptionSeparatedByCommas : undefined}
      onInputChange={handleInputChanged}
      closeMenuOnSelect={!selectMultipleOptionsAtOnce}
      className="ordo-multi-select"
      inputValue={inputValue}
    />
  );
};

export default OrdoMultiSelect;
