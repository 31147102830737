import {API} from '../lib/api/ordoApi';
import BusinessError from '../errors/domain_errors';
import FormError, {FormFields} from '../errors/form_error';
import {Role} from '../models/Roles';
import {Organization} from '../models/Organization';
import { ActualMember } from '../models/member/ActualMember';

export type EditUserPermissionViewModelInput = {
  ordoAPI: API,
  member: ActualMember,
  selectedRole: Role,
  organization: Organization,
  error?: FormError
}

export default class EditUserPermissionViewModel {
  public selectedRole: Role
  private member: ActualMember
  private error: FormError
  private ordoAPI: API
  organization: Organization

  constructor(viewModelInput: EditUserPermissionViewModelInput) {
    this.member = viewModelInput.member;
    this.selectedRole = viewModelInput.selectedRole;
    this.ordoAPI = viewModelInput.ordoAPI;
    this.organization = viewModelInput.organization;
    this.error = viewModelInput.error || FormError.withoutError();
  }

  public async editUserRole(): Promise<EditUserPermissionViewModel> {
    try {
      await this.ordoAPI.changeUserRole(this.organization, this.member, this.selectedRole);
      return this;
    } catch (error) {
      return new EditUserPermissionViewModel({
        ordoAPI: this.ordoAPI,
        member: this.member,
        selectedRole: this.selectedRole,
        organization: this.organization,
        error: (error instanceof BusinessError) ? error.formError : FormError.unknown(error.message),
      });
    }
  }

  public updateRole(role: Role) {
    return new EditUserPermissionViewModel({
      ordoAPI: this.ordoAPI,
      member: this.member,
      selectedRole: role,
      organization: this.organization,
      error: this.error,
    });
  }

  public hasError() {
    return this.error.hasError();
  }

  public hasErrorFor(input: FormFields) {
    return this.error.hasErrorForInput(input);
  }

  errorMessage(input: FormFields): string {
    return this.error.errorMessage(input);
  }

  public shouldUpdate() {
    return this.member.role !== this.selectedRole;
  }
};
