import React from 'react';
import {RepAssignment} from '../../models/Assignment';
import '../../scss/ordo/accountsPage/rep-assignment-card.scss';
import '../../scss/ordo/accountsPage/list.scss';
import '../../scss/ordo/accountsPage/accounts-page.scss';

type RetailerAssignmentCardProps = {
  assignment: RepAssignment,
  index: number,
  onCardClick: Function
};

const RepAssignmentCard = ({assignment, index, onCardClick} : RetailerAssignmentCardProps) => {
  const renderOrganizationBrands = () => {
    if (assignment.accessibleBrands.length > 0) {
      return assignment.accessibleBrands.slice(0,4).map(brand => {
        return (<div key={brand.id} className='brand'>
          <img alt='brand' src={brand.logoImageURL} className='rounded-lg'/>
        </div>);
      });
    }
    return(
      <div className="no-brands-added">
        <p>No brands available</p>
      </div>
    );
  };

  const renderMoreBrandsText = () => {
    return assignment.accessibleBrands.length > 4 ?
      (
        <div className="more-brands-text">
          <span>{`+${assignment.accessibleBrands.length - 4} brand${assignment.accessibleBrands.length - 4 > 1 ? 's' : ''} `}</span>
        </div>
      ) : <div/>;
  };

  return (
    <div className='rep-assignment-card'
      tabIndex={index}
      role='button'
      onClick={(event) => onCardClick(event)}
      // Accessibility requirement
      onKeyDown={(event) => {
        event.preventDefault();
      }}
    >
      <div className="title">
        <span>{assignment.member.getName()}</span>
      </div>
      <div className="rep-assignment-card__body">
        <div className="rep-assignment-card__body__user-section">
          <span className="rep-assignment-card__body__owner_title">assigned accounts</span>
          <p className="text-muted mb-0 text-truncate rep-assignment-card__body__assignments-amount">
            {assignment.accounts.length }
          </p>
        </div>

        <div className="brands-title">
          <span className="rep-assignment-card__body__owner_title">brands</span>
        </div>
        <div className="rep-assignment-card__body__brands-container">
          <div className="brands">
            {renderOrganizationBrands()}
          </div>
          {renderMoreBrandsText()}
        </div>
      </div>
    </div>
  );
};

export default RepAssignmentCard;
