import React from 'react';
import '../../../../scss/ordo/ordo-searchable-dropdown.scss';
import OrdoSearchableDropdown from './OrdoSearchableDropdown';
import {
  InformativeMenuOption,
  InformativeSelectableOption,
  InformativeSelectedOption
} from './InformativeOption';

type OrdoInformativeSearchableDropdownProps<T> = {
  dataTestId?: string,
  selectedOption: InformativeSelectableOption<T> | null,
  options: InformativeSelectableOption<T>[],
  onChangeSelectedOption: (selectedOption: T) => void,
  placeholder: string,
  onAddItem?: Function,
  addItemText?: string,
  singleRowHeight?: boolean,
  addedStyles?: any,
  onBlur?: Function,
  canEditValue?: boolean
}

const OrdoInformativeSearchableDropdown = <T extends any>({
  dataTestId,
  selectedOption,
  options,
  onChangeSelectedOption,
  placeholder,
  onAddItem,
  addItemText,
  singleRowHeight,
  addedStyles = {},
  onBlur,
  canEditValue = true
}: OrdoInformativeSearchableDropdownProps<T>) => {

  return <OrdoSearchableDropdown
    dataTestId={dataTestId}
    canEditValue={canEditValue}
    selectedOption={selectedOption}
    options={options}
    onChangeSelectedOption={(selected: InformativeSelectableOption<T>) => onChangeSelectedOption(selected.value)}
    placeholder={placeholder}
    selectedOptionCustomComponent={InformativeSelectedOption}
    menuOptionCustomComponent={InformativeMenuOption}
    onAddItem={onAddItem}
    addItemText={addItemText}
    singleRowHeight={singleRowHeight}
    addedStyles={addedStyles}
    onBlur={onBlur}
  />;
};

export default OrdoInformativeSearchableDropdown;
