import React, {FC, useContext} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {RouteProps} from 'react-router';
import CookieVerifier from '../utils/CookieVerifier';
import {UserSessionContextData} from '../context/UserSessionContext';


type PrivateRouteProps = RouteProps & {
  redirectRoute: string,
  cookies: () => string,
  currentTime: () => number;
  userSessionContext: React.Context<UserSessionContextData>
}

const PrivateRoute: FC<PrivateRouteProps> = ({redirectRoute, cookies, currentTime, userSessionContext, ...routeProps}: PrivateRouteProps) => {
  const currentContextData = useContext(userSessionContext);
  const userLoggedIn = CookieVerifier.isValidSession(cookies(), currentTime(), currentContextData.user());
  // We need this eslint-disable to give the props to the Route
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (userLoggedIn ? <Route {...routeProps}/> : <Redirect to={redirectRoute}/>);
};

export default PrivateRoute;
