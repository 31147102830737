import React, {useEffect, useState} from 'react';
import OrdoModal from '../components/common/OrdoModal';
import OrdoPriceInput from '../components/common/inputs/OrdoPriceInput';
import '../../scss/ordo/salesActivity/track-order-modal.scss';
import {
  TrackedOrderViewModel
} from '../../application-models/sales-activity/TrackedOrderViewModel';
import OrdoFileInput from '../components/common/inputs/OrdoFileInput';
import {OrdoFile} from '../../models/OrdoFile';
import OrdoInformativeSearchableDropdown
  from '../components/common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import OrdoSpinner from '../components/OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import {useAPI} from '../../context/OrdoApiContext';
import OrdoButton from '../components/common/OrdoButton';
import OrdoDatepicker, {OrdoDatepickerDisplayMode, SelectableDates} from '../components/common/OrdoDatepicker';
import OrdoDropdown from '../components/common/OrdoDropdown';
import OrdoInput from '../components/common/inputs/OrdoInput';
import {validateTermPeriod} from '../../models/order-entry/OrderValidator';
import {PaymentMethod} from '../../models/order-entry/Summary';
import {Money} from '../../models/Money';
import { TrackedOrderInput } from '../../models/order-entry/Order';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import {AccountLocation} from '../../models/Account';

type TrackOrderModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: Function,
  selectedLocation?: AccountLocation,
  accountsLocations: AccountLocation[],
  organizationId: string,
  existingOrder?: OrderWithCurrentVersion
};

const TrackOrderModal = ({isOpen, onSubmit, onClose, selectedLocation, accountsLocations, organizationId, existingOrder}: TrackOrderModalProps) => {
  const {showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const api = useAPI();
  const [viewModel, setViewModel] = useState(TrackedOrderViewModel.empty(api, accountsLocations, existingOrder));
  const updateViewModel = (data: Partial<TrackedOrderInput>) => setViewModel(viewModel.update(data));
  const updateSelectedLocation = (newLocation: AccountLocation) => {
    showSpinner();
    viewModel.changeSelectedLocation(organizationId, newLocation)
      .then(setViewModel)
      .finally(hideSpinner);
  };

  useEffect(()=> {
    if(selectedLocation) updateSelectedLocation(selectedLocation);
  },[]);

  const formId = 'TrackOrder';

  const submitOrder = ()=> {
    showSpinner();
    viewModel.submitOrder(organizationId, existingOrder)
      .then(()=>{
        successToast(existingOrder ? 'Order edited successfully' :'Order created successfully');
        onSubmit();
      })
      .catch(()=>errorToast(existingOrder ? 'There was a problem editing the order' :'There was a problem creating the order'))
      .finally(hideSpinner);
  };
  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <OrdoModal
      show={isOpen} onClose={onClose} actionText='save'
      title="Log an Order" formId={formId}
      onSubmit={submitOrder}
      disableCTA={!viewModel.canSubmit()}
      disabledCTAText={viewModel.disableReason()}
    >
      <div className='track-order-form'>
        <div className='track-order-form__location'>
          <span className='field-label'>purchasing store</span>
          <OrdoInformativeSearchableDropdown
            dataTestId='select-location'
            selectedOption={viewModel.getSelectedLocation()}
            options={viewModel.getSelectableLocations()}
            onChangeSelectedOption={(selected) => updateSelectedLocation(selected)}
            placeholder='select a location'
            singleRowHeight
          />
        </div>
        <div className='track-order-form__value-and-invoice'>
          <div className='track-order-form__value-and-invoice__value'>
            <span className='field-label'>value</span>
            <OrdoPriceInput label='' value={viewModel.totalValue}
              onChange={(newValue) => updateViewModel({totalValue: Money.FromDollarAmount(newValue)})}
              onBlur={() => {}} invalidMessage={viewModel.validTotal() ? '': 'Please select a valid value'}
            />
          </div>
          <div className='track-order-form__value-and-invoice__invoice'>
            <span className='field-label'>invoice (optional)</span>
            <OrdoFileInput text="Attach invoice"
              onFileSelected={(file: any) => updateViewModel({invoice: (file as OrdoFile)})}
              isValid={viewModel.validInvoice()}
            />
            {viewModel.validInvoice() && viewModel.invoicePreview() && <OrdoButton disabled={false} text="view invoice" category="link" onClick={() => { window.open(viewModel.invoiceUrl(), '_blank'); }} dataTestId="track-order-view-invoice"/>}
          </div>
        </div>
        <div className='track-order-form__contact'>
          <span className='field-label'>contact</span>
          <OrdoInformativeSearchableDropdown
            dataTestId='select-contact'
            selectedOption={viewModel.getSelectedContact()}
            options={viewModel.getSelectableContacts()}
            onChangeSelectedOption={(selected) => updateViewModel({contact: selected})}
            placeholder='Select a contact'
            singleRowHeight
          />
        </div>
        <div className='track-order-form__delivery-day'>
          <span className="field-label">delivery date</span>
          <OrdoDatepicker
            value={viewModel.selectedDeliveryDate}
            placeholder="select a delivery day"
            onChange={(selectedDate: Date) => updateViewModel({deliveryDay: selectedDate})}
            displayMode={OrdoDatepickerDisplayMode.CARD}
            validDates={SelectableDates.ALL_TIME}
            height='3em'
          />
        </div>
        <div className='track-order-form__payment-method'>
          <span className='field-label'>payment type</span>
          <OrdoDropdown
            placeholder='select a payment method'
            selectedOption={viewModel.getSelectedPaymentMethod()}
            options={viewModel.getSelectablePaymentMethods()}
            onSelect={(paymentMethod: PaymentMethod) => updateViewModel({paymentMethod: paymentMethod})}
            height='short'
          />
          {viewModel.needsTermPeriod() &&
          <div className='track-order-form__payment-type__net_term_period'>
            <span className='field-label'>term period</span>
            <OrdoInput
              inputName='net-terms-input'
              value={viewModel.paymentTermPeriod}
              placeholder='7, 15, 30, etc'
              inputSize='large'
              inputType='number'
              onChange={(newTermPeriod) => updateViewModel({termPeriod: newTermPeriod})}
              validate={validateTermPeriod}
            />
          </div>}
        </div>
      </div>
    </OrdoModal>
  </OrdoSpinner>;
};

export default TrackOrderModal;
