import { OrderEntryStep } from '../OrderEntryViewModel';
import {API} from '../../../lib/api/ordoApi';
import { ProductWithAvailability } from '../../../models/productWithAvailability';
import {Cart, SerializableCart} from '../../../models/order-entry/Cart';
import {OrderSummary, PaymentMethod} from '../../../models/order-entry/Summary';
import Contact from '../../../models/order-entry/Contact';
import {OrganizationLicense} from '../../../models/OrganizationLicense';
import { OrderEntryCartContextData } from '../../../context/OrderEntryCartContext';
import {OrderInput} from '../../../models/order-entry/Order';
import { AccountLocation } from '../../../models/Account';
import {User} from '../../../models/User';

export enum OrderPrefilledMode {
  edit,
  duplicate
}

export interface OrderEntryMode {
  submitOrderCTAText(): string;

  initialStep(): OrderEntryStep;

  orderNotes(): string;

  initializeAccountWithContacts(
    selectedAccountLocation: AccountLocation | undefined,
    accountLocations: AccountLocation[],
    api: API,
    orgId: string,
    accountId?: string): Promise<{ selectedAccountLocation: AccountLocation | undefined; contacts: Contact[] }>;

  initializeCart(
    serializableCart: SerializableCart,
    selectedAccountLocation: AccountLocation | undefined,
    products: ProductWithAvailability[]): Cart;

  orderSummary(): OrderSummary | undefined;

  selectedPaymentMethod(): PaymentMethod | undefined;

  termPeriod(): number | undefined;

  selectedDeliveryDay(): Date | undefined;

  contact(): Contact | undefined;

  selectedDistributor(): OrganizationLicense | undefined;

  saveCart(cart: Cart, orderEntryCartContextData: OrderEntryCartContextData): void;

  submitOrder(api: API, orgId: string, order: OrderInput): Promise<void>;

  availability(api: API, orgId: string, distributorOrganizationLicenseId: string, accountId: string): Promise<ProductWithAvailability[]>;

  distributorTaxesEnabled(): boolean;

  getSuccessMessage(): string;

  nextRoute(): string;

  nextMobileRoute(): string;

  assignedSalesRep(): User | undefined;

  isEdit(): boolean;
}
