class Color {
  static fromHexCode(hexCode: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
    if (result) {
      return new Color(
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      );
    }
    return new Color(0,0,0);
  }

  private constructor(private red: number, private green: number, private blue: number) {
  }

  public toHexCode() {
    return `#${Color.colorValueToHexCode(this.red)}${Color.colorValueToHexCode(this.green)}${Color.colorValueToHexCode(this.blue)}`;
  }

  private static colorValueToHexCode(value: number) {
    const hex = value.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  }

  public gradientAt(top: Color, percent: number) {
    const resultRed = this.red + Math.floor(percent * (top.red - this.red));
    const resultGreen = this.green + Math.floor(percent * (top.green - this.green));
    const resultBlue = this.blue + Math.floor(percent * (top.blue - this.blue));
    return new Color(resultRed, resultGreen, resultBlue);
  }
}


export function colorInGradient(baseColor: string, fullColor: string, percent: number): string {
  const top = Color.fromHexCode(fullColor);
  const base = Color.fromHexCode(baseColor);
  const color = base.gradientAt(top, percent);
  return color.toHexCode();
}