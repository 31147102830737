import React, {useContext} from 'react';
import {useHistory} from 'react-router';
import OrderWithCurrentVersion from '../../../models/order-entry/OrderWithCurrentVersion';
import OrdoButton from '../common/OrdoButton';
import {OrderStatus} from '../../../models/order-entry/Order';
import {DateFormatTemplate, OrdoDate} from '../../../models/OrdoDate';
import {useResolution} from '../../../context/UseResolutions';
import {isMobileResolution} from '../../../context/resolutions';
import ConfirmationModal from '../common/ConfirmationModal';
import useModalToggle from '../../../hooks/useModalToggle';
import ROUTE_NAMES from '../../../routes/ROUTE_NAMES';
import {OrderPrefilledMode} from '../../../application-models/order-entry/order-entry-mode';
import {PipelineButtonText} from '../../sales-activity/salesActivityFunctions';
import VerifyOrderDeliveryModal from '../../sales-activity/VerifyOrderDeliveryModal';
import LogPaymentModal from '../../sales-activity/LogPaymentModal';
import {Money} from '../../../models/Money';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {accountLocationHasStreetAddress} from '../../../models/Account';

type OrderDeliverySummaryProps = {
  order: OrderWithCurrentVersion,
  onUpdateOrderStatus: Function,
  onUpdateOrderDisplayStatus: Function,
  showButtons: boolean
};

const OrderDeliverySummary = ({order, onUpdateOrderStatus, onUpdateOrderDisplayStatus, showButtons = true}: OrderDeliverySummaryProps) => {
  const resolution = useResolution();
  const history = useHistory();
  const userSession = useContext(UserSessionContext);
  const currentOrganization = userSession.currentOrganization();

  const {closeModal: closeCancel,openModal: openCancel, showModal: showCancel} = useModalToggle();
  const {closeModal: closeUnconfirm,openModal: openUnconfirm, showModal: showUnconfirm} = useModalToggle();
  const {closeModal: closeVerifyDelivery,openModal: openVerifyDelivery, showModal: showVerifyDelivery} = useModalToggle();
  const {closeModal: closeCollectPayment,openModal: openCollectPayment, showModal: showCollectPayment} = useModalToggle();

  const dateToString = (date: Date) => {
    return OrdoDate.from(date).format(DateFormatTemplate.DAY_OF_MONTH_DETAILED);
  };
  const navigateToDuplicateOrder = () => {
    history.push(ROUTE_NAMES.EDIT_ORDER, {orderId: order.id, orderPrefillMode: OrderPrefilledMode.duplicate});
  };

  const navigateToEditOrder = () => {
    history.push(ROUTE_NAMES.EDIT_ORDER, {orderId: order.id, orderPrefillMode: OrderPrefilledMode.edit});
  };

  return <div className='delivery-summary'>
    <div className='delivery-summary__data'>
      <div className='delivery-summary-details'>
        <div className='section'>
          <div className='section-title'>
            distributor
          </div>
          <span>
            {order.distributor.license.name}
          </span>
          <span>
            {`${order.distributor.license.licenseNumber}`}
          </span>
        </div>
        <div className='section'>
          <span className='section-title'>
            date placed
          </span>
          <span>
            {dateToString(order.datePlaced)}
          </span>
        </div>
        <div className='section'>
          <span className='section-title'>
            delivery date
          </span>
          <span>
            {dateToString(order.getDeliveryDate())}
          </span>
        </div>
        {!isMobileResolution(resolution) &&
            <>
              <div className='section address'>
                <span className='section-title'>
                delivery address
                </span>
                <p>
                  {accountLocationHasStreetAddress(order.accountLocation) ? `${order.accountLocation.streetAddressLine1} ${order.accountLocation.streetAddressLine2},` : ''}
                </p>
                <p>
                  {`${order.accountLocation.city } ${ order.accountLocation.state } ${ order.accountLocation.zipCode}`}
                </p>
              </div>
              {order.currentVersion.orderNotes && <div className='section mobile-order-notes'>
                <span className='section-title'>
            order notes
                </span>
                <p>
                  {order.currentVersion.orderNotes}
                </p>
              </div>}
            </>
        }
      </div>
      {isMobileResolution(resolution) &&
          <div className='mobile-order-notes-and-address'>
            <div className='section address'>
              <span className='section-title'>
                delivery address
              </span>
              <p>
                {`${order.accountLocation.location.addressLine1 } ${order.accountLocation.location.addressLine2},`}
              </p>
              <p>
                {`${order.accountLocation.location.city } ${ order.accountLocation.location.state } ${ order.accountLocation.location.zipCode}`}
              </p>
            </div>
            {order.currentVersion.orderNotes && <div className='section mobile-order-notes'>
              <span className='section-title'>
            order notes
              </span>
              <p>
                {order.currentVersion.orderNotes}
              </p>
            </div>}
          </div>
      }
      {showButtons && <div className='delivery-summary-action-buttons'>
        {
          order.isPending() && <>
            <OrdoButton
              disabled={false}
              text={PipelineButtonText.CONFIRM_ORDER}
              category='primary'
              onClick={()=>{
                onUpdateOrderStatus(OrderStatus.Confirmed);
              }}
              dataTestId='confirm-order-button'
            />
            <OrdoButton
              disabled={false}
              text={PipelineButtonText.EDIT_ORDER}
              category='primary'
              onClick={navigateToEditOrder}
              dataTestId='edit-order-button'
            />
          </>
        }
        {
          order.isAwaitingDelivery() && <>
            <OrdoButton
              disabled={false}
              text={PipelineButtonText.VERIFY_DELIVERY}
              category='primary'
              onClick={openVerifyDelivery}
              dataTestId='confirm-order-button'
            />
            <OrdoButton
              disabled={false}
              text={PipelineButtonText.UNCONFIRM_ORDER}
              category='primary'
              onClick={openUnconfirm}
              dataTestId='edit-order-button'
            />
          </>
        }
        {
          order.isDelivered() && <>
            <OrdoButton
              disabled={false}
              text={PipelineButtonText.LOG_PAYMENT}
              category='primary'
              onClick={openCollectPayment}
              dataTestId='confirm-order-button'
            />
          </>
        }

        <OrdoButton
          disabled={false}
          text='duplicate order'
          category='primary'
          onClick={navigateToDuplicateOrder}
          dataTestId='duplicate-order-button'
        />
        {
          order.isPending() && <>
            <div className='empty-space'/>
            <OrdoButton
              disabled={false}
              text='cancel order'
              category='danger'
              onClick={openCancel}
              dataTestId='cancel-order-button'
            />
          </>
        }

      </div>}
      {showCancel && <ConfirmationModal show={showCancel} onClose={closeCancel} onSubmit={() => {
        closeCancel();
        onUpdateOrderStatus(OrderStatus.Cancelled);
      }} confirmationText='Are you sure you want to cancel this order?'/>}
      {showUnconfirm && <ConfirmationModal show={showUnconfirm} onClose={closeUnconfirm} onSubmit={() => {
        closeCancel();
        onUpdateOrderStatus(OrderStatus.Pending);
      }} confirmationText={`Are you show you want to send order ${order.orderNumber} back to pending status?` }/>}
      {showUnconfirm && <ConfirmationModal show={showUnconfirm} onClose={closeUnconfirm} onSubmit={() => {
        closeCancel();
        onUpdateOrderStatus(OrderStatus.Pending);
      }} confirmationText={`Are you show you want to send order ${order.orderNumber} back to pending status?` }/>}
      {showVerifyDelivery && <VerifyOrderDeliveryModal isOpen={showVerifyDelivery}
        orderToVerifyDelivery={order} allowEdit={!order.isTracked}
        onClose={closeVerifyDelivery}
        onVerify={() => {
          onUpdateOrderStatus(OrderStatus.Delivered);
          closeVerifyDelivery();
          return Promise.resolve();
        }}
        editTrackedOrder={()=>{}}
      />}
      {showCollectPayment && <LogPaymentModal isOpen={showCollectPayment}
        onClose={closeCollectPayment}
        onSubmit={(amountOwedAfterPayment)=> {
          if(amountOwedAfterPayment?.getAmount() === 0) {
            onUpdateOrderDisplayStatus(OrderStatus.Paid);
          }
        }}
        organizationId={currentOrganization!.id}
        pendingPaymentOrder={{
          orderId: order.id ,
          orderNumber: order.orderNumber,
          amountOwed: Money.FromDollarAmount(0),
          invoiceURL: order.currentVersion.invoiceUrl,
        }}/>}
    </div>
  </div>;
};

export default OrderDeliverySummary;
