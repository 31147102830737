import React from 'react';
import OrdoButton from '../common/OrdoButton';
import {OrderSummary, PaymentMethod} from '../../../models/order-entry/Summary';

const OrderPaymentSummary = ({orderSummary, invoiceUrl} : {orderSummary: OrderSummary, invoiceUrl: string}) => {

  const discounts = [
    {
      discountName: 'subtotal',
      discountAmount: orderSummary.subtotal
    },
    {
      discountName: 'discounts',
      discountAmount: orderSummary.discountTotal
    },
    {
      discountName: 'order discounts',
      discountAmount: orderSummary.wholeOrderDiscount
    },
    {
      discountName: 'tax total',
      discountAmount: orderSummary.totalTax()
    },
    {
      discountName: 'grand total',
      discountAmount: orderSummary.grandTotalWithTaxesCriteria()
    }
  ];

  const paymentMethod = () => {
    return orderSummary.paymentMethod === PaymentMethod.CASH_ON_DELIVERY ? 'cash on delivery' : `net ${orderSummary.termPeriod}`;
  };

  return (
    <div className="order-payment-summary">
      <div className='order-payment-summary__data'>
        <div className="order-payment-summary__data__details">
          <div className="order-payment-summary__data__details__discount-names">
            {discounts.map(discount => {
              return (  <div className="order-payment-summary__data__details__discount-names__item" key={discount.discountName}>
                <span>{discount.discountName}</span>
                <div className="order-payment-summary__data__details__discount-names__item__line"/>
              </div>);
            })}
            <div className="order-payment-summary__data__details__discount-names__item">
              <span>payment method</span>
              <div className="order-payment-summary__data__details__discount-names__item__line"/>
            </div>
          </div>
          <div className="amounts">
            {discounts.map(discount => {
              return (
                <div key={discount.discountName}>
                  <span>{discount.discountAmount.formatted()}</span>
                </div>);
            })}
            <div>
              <span>{paymentMethod()}</span>
            </div>
          </div>
        </div>
        <div className="order-payment-summary__data__button">
          <OrdoButton disabled={false} text="view invoice" category="primary" onClick={() => { window.open(invoiceUrl, '_blank'); }} dataTestId="orde-details-view-invoice"/>
        </div>
      </div>
    </div>
  );
};

export default OrderPaymentSummary;
