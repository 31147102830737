import React from 'react';
import FilterBox from './FilterBox';
import '../../../../scss/ordo/inventoryPage/search-bar.scss';
import OrdoInput from '../../common/inputs/OrdoInput';
import Product from '../../../../models/Product';
import ProductFilterViewModel, {
  filters,
  MultiFiltersKeys,
  UpdateSearchBarFilter,
} from '../../../../application-models/ProductFilterViewModel';

type SearchBarProps<T extends Product> = {
  viewModel: ProductFilterViewModel<T>
  updateViewModel: (viewModel: ProductFilterViewModel<T>) => void,
  stockOnlyFilter?: boolean,
}
const SearchBar = <T extends Product>({viewModel, updateViewModel, stockOnlyFilter = false}: SearchBarProps<T>) => {

  const applyFilters = (updateSearchBarFilter: UpdateSearchBarFilter) => {
    const updatedViewModel = viewModel.updateFilters(updateSearchBarFilter);
    updatedViewModel.paginate().then(()=> updateViewModel(updatedViewModel));
  };

  const handleSearchProduct = (searchProduct: string) => {
    applyFilters(() => {
      const updatedViewModel = viewModel.updateSearchProduct(searchProduct);
      updateViewModel(updatedViewModel);
    });
  };

  const handleToggleStockOnly = () => {
    applyFilters(() => {
      const updatedViewModel = viewModel.toggleStockOnly();
      updateViewModel(updatedViewModel);
    });
  };

  const handleFilters = (valuePosition: number, typeOfFilter: MultiFiltersKeys) => {
    applyFilters(() => {
      const updatedViewModel = viewModel.updateFilter(valuePosition, typeOfFilter);
      updateViewModel(updatedViewModel);
    });
  };

  const filterBoxes = filters.map(filter =>
  {
    return(
      <div key={filter.typeOfFilter}>
        {!viewModel.isEmptyFilter(filter.filterValue) &&
      <FilterBox
        title={filter.filterValue.replace(/([A-Z]+)/g, ' $1').toLowerCase()}
        typeOfFilter={filter.typeOfFilter}
        filterValues={viewModel.getFilterOptions(filter.filterValue)}
        selectedValues={viewModel.selectedValuesFor(filter.typeOfFilter)}
        updateFilters={handleFilters}/>}
      </div>);
  });

  const stockOnlyId = 'stock-only-input';
  return (
    <div className="search-bar ordo-shadow ordo-card-border">
      <div className="search-product-input">
        <OrdoInput
          inputName="org-first-name" value={viewModel.searchProduct} placeholder="Search Product or SKU"
          onChange={handleSearchProduct} onBlur={() => {}} inputSize="large"/>
      </div>
      {stockOnlyFilter && <div className="stock-only-filter custom-control custom-checkbox" key={stockOnlyId}>
        <input
          type="checkbox"
          className="custom-control-input
          custom-control-input-green"
          id={stockOnlyId}
          onChange={handleToggleStockOnly}
          checked={viewModel.shouldDisplayStockOnly()}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className={`custom-control-label cursor-pointer ${!viewModel.shouldDisplayStockOnly() ? 'unselected-text' : ''}`} htmlFor={stockOnlyId}>Show In-stock Only</label>
      </div>}
      {filterBoxes}
    </div>
  );
};

export default SearchBar;
