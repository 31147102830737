import React, {PropsWithChildren, ReactNode} from 'react';
import {Spinner} from 'react-bootstrap';
import '../../scss/ordo/ordo-spinner.scss';
import '../../scss/purpose.scss';

type OrdoSpinnerProps = PropsWithChildren<ReactNode & { showSpinner: boolean }>;

const OrdoSpinner = ({showSpinner, children}: OrdoSpinnerProps) => {
  const ShowSpinner = <>
    <div className="spinner-container">
      <Spinner animation="border" className="ordo-spinner"/>
    </div>
    <div className="spinner-content-container">
      {children}
    </div>
  </>;
  const HideSpinner = <>
    {children}
  </>;

  return (showSpinner ? ShowSpinner : HideSpinner);
};

export default OrdoSpinner;
