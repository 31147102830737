import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import ProductFilterViewModel, {filters, MultiFiltersKeys} from '../../../../application-models/ProductFilterViewModel';
import FilterBox from '../../../components/inventory/searchBar/FilterBox';
import {ProductWithAvailability} from '../../../../models/productWithAvailability';

type FilterProductsViewProps = {
  updateFilters: (selectedValuePosition: number, typeOfFilter: MultiFiltersKeys) => void,
  searchBarViewModel: ProductFilterViewModel<ProductWithAvailability>,
  onClose: () => void,
}

export const FilterProducts = ({updateFilters, searchBarViewModel, onClose}: FilterProductsViewProps) => {
  return (<div>
    <div className="filters-header">
      <span className="filters-header__title">filters selected: {searchBarViewModel.numberOfFiltersSelected()}</span>
      <FontAwesomeIcon
        className="filters-header__close-icon"
        icon={faTimesCircle}
        onClick={onClose}
        size="2x"
      />
    </div>
    <div className="filters-content">
      {filters.map(filter =>
      {return(
        <div key={filter.typeOfFilter}>
          {!searchBarViewModel.isEmptyFilter(filter.filterValue) &&
              <FilterBox
                title={filter.filterValue.replace(/([A-Z]+)/g, ' $1').toLowerCase()}
                typeOfFilter={filter.typeOfFilter}
                filterValues={searchBarViewModel.getFilterTypeOptions(filter.filterValue)}
                updateFilters={updateFilters}
                selectedValues={searchBarViewModel.selectedValuesFor(filter.typeOfFilter)}
              />}
        </div>);
      })}
    </div>
  </div>);
};
