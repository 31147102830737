import {useContext} from 'react';
import {useHistory} from 'react-router';
import {ORGANIZATION_STATUS} from '../models/Organization';
import ROUTE_NAMES from '../routes/ROUTE_NAMES';
import {UserSessionContext} from '../context/UserSessionContext';
import useOrdoToasts from './useOrdoToasts';

export default function useOrganizationRedirect(){
  const userSession = useContext(UserSessionContext);
  const history = useHistory();
  const {errorToast} = useOrdoToasts();

  const validateOrRedirect = (next: Function) => {
    if(userSession.currentOrganization()!.status === ORGANIZATION_STATUS.PENDING) {
      errorToast('Forbidden access to page. Organization has not been verified');
      history.push(ROUTE_NAMES.PROFILE);
    } else {
      next();
    }
  };

  return {validateOrRedirect: validateOrRedirect};
}
