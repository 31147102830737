import React, {useState} from 'react';
import '../../../scss/ordo/thread-activity.scss';
import {EmailThreadActivity, ExternalEmailActivity} from '../../../models/sales-activity/Activity';

type ThreadActivityProps = {
  activity: ExternalEmailActivity,
};

export const ThreadActivity = ({activity}: ThreadActivityProps) => {
  const [showCompleteThread, setShowCompleteThread] = useState(false);
  const threadEmails = activity.emailThreadActivity;

  const contactEmail = (threadEmail: EmailThreadActivity) => {
    return threadEmail.to.includes(activity.createdBy!.email) ? threadEmail.from : threadEmail.to;
  };

  const contactIsRecipient = (threadEmail: EmailThreadActivity) => {
    return threadEmail.from === activity.createdBy?.email;
  };

  const ThreadEmail = (email: string, body: string, isRecipient: boolean) => {

    return (
      <div className="email">
        <div className="account-contact-email">{`${isRecipient? 'to':'from'}: ${email}`}</div>
        {/* eslint-disable-next-line react/no-danger */}
        <pre className="body" dangerouslySetInnerHTML={{ __html: body}}/>
      </div>
    );
  };

  return (
    <div>
      <div className={`emails ${showCompleteThread? '' : 'hide-thread'}`}>
        {threadEmails.map(email => ThreadEmail(contactEmail(email), email.body, contactIsRecipient(email)))}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={(event: any) => {
          event.stopPropagation();
          setShowCompleteThread(!showCompleteThread);}}
        className="thread-toggle">
        {showCompleteThread ? 'show less' : 'show more'}
      </div>
    </div>
  );
};
