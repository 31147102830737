import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import OrdoSpinner from '../components/OrdoSpinner';
import OrdoInput from '../components/common/inputs/OrdoInput';
import OrdoButton from '../components/common/OrdoButton';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import {AuthService} from '../../services/auth_service';
import {useAuth} from '../../context/AuthContext';

const SetNewPassoword = ({code}: {code: string}) => {
  const history = useHistory();
  const authService: AuthService = useAuth();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [userEmail, setEmail] = useState('');
  const [successfulPasswordSetting, setSuccessfulPasswordSetting] = useState(false);
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {errorToast} = useOrdoToasts();

  const goToLoginPage = () => history.push(ROUTE_NAMES.LOGIN);


  useEffect(() => {
    showSpinner();
    authService.verifyPasswordResetCode(code)
      .then((email: string) => setEmail(email))
      .catch((error: any) => {
        goToLoginPage();
        errorToast(error.message);
      })
      .finally(hideSpinner);
  }, []);


  const passwordsMatch = () => passwordConfirmation === password;
  const shouldDisplayError = () => (passwordConfirmation !== '' || password !== '') && !passwordsMatch();
  const passwordsNotMatchingErrorMessage = () => shouldDisplayError() ? 'passwords don\'t match' : '';

  const setNewPassword = (event: any) => {
    event.preventDefault();
    showSpinner();
    authService.confirmPasswordReset(code, password)
      .then(() => setSuccessfulPasswordSetting(true))
      .catch((error: any) => errorToast(error.message))
      .finally(hideSpinner);
  };

  return (<OrdoSpinner showSpinner={spinnerVisibility}>
    {!successfulPasswordSetting &&
      <div className="card-body px-md-5 py-5">
        <div className="mb-3">
          <h6 className="h3">Reset password</h6>
          <p className="text-muted mb-0">Enter a new password for {userEmail}</p>
        </div>
        <span className="clearfix"/>
        <form>
          <div className="form-group">
            <OrdoInput
              inputName="password-setting-input"
              value={password}
              placeholder="type your new password"
              onChange={setPassword}
              onBlur={() => {
              }}
              inputType="password"
              inputSize="large"/>
          </div>
          <div className="form-group">
            <OrdoInput
              inputName="password-setting-input"
              value={passwordConfirmation}
              placeholder="confirm your new password"
              onChange={setPasswordConfirmation}
              onBlur={() => {
              }}
              invalidMessage={passwordsNotMatchingErrorMessage()}
              inputType="password"
              inputSize="large"/>
          </div>
          <div className="login-footer">
            <OrdoButton
              additionalClassName="registration-button"
              disabled={shouldDisplayError() || (password === '') || userEmail === ''}
              text="set new password"
              category="primary"
              onClick={setNewPassword}
              dataTestId="login-button"/>
          </div>
        </form>
      </div>
    }
    {successfulPasswordSetting &&
      <div className="card-body px-md-5 py-5">
        <div className="mb-3">
          <h6 className="h3 text-center">Password successfully set</h6>
        </div>
        <span className="clearfix"/>
        <form>
          <div className="login-footer">
            <OrdoButton
              additionalClassName="registration-button"
              disabled={false}
              text="sign in"
              category="primary"
              onClick={goToLoginPage}
              dataTestId="login-button"/>
          </div>
        </form>
      </div>
    }
  </OrdoSpinner>);
};

export default SetNewPassoword;
