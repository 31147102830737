import React, {useEffect, useState} from 'react';
import '../../../scss/ordo/salesActivity/note-details.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt, faUser} from '@fortawesome/free-solid-svg-icons';
import {API} from '../../../lib/api/ordoApi';
import {useAPI} from '../../../context/OrdoApiContext';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import OrdoSpinner from '../../components/OrdoSpinner';
import {Activity, ActivityItem, ActivityTemplate} from '../../../models/sales-activity/Activity';
import ActivityViewModel from '../../../application-models/sales-activity/ActivityViewModel';
import {DateFormatTemplate, OrdoDate} from '../../../models/OrdoDate';
import {ActivityForm} from '../../components/activities/ActivityForm';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import MobileDisplayAsModal from '../../components/common/MobileDisplayAsModal';
import {OrdoCheckbox} from '../../components/OrdoCheckbox';
import {ActivityCompleteModal} from '../../components/modals/ActivityCompleteModal';
import { ActivityCompletionResponse } from '../../../models/activity/responses/ActivityCompletionResponse';
import {ActivityResponsesDisplay} from '../../components/activities/ActivityResponsesDisplay';
import {AccountWithContactsAndSalesReps} from '../../../models/AccountWithContactsAndSalesReps';

type ActivityDetailsRowProps = {
  orgId: string,
  accountId: string,
  accountName: string,
  activity: Activity | ActivityItem,
  onActivityUpdate: (activity: Activity | ActivityItem) => void,
  onActivityDelete: (activity: Activity | ActivityItem) => void,
  activityTemplates: ActivityTemplate[],
  displayName: string,
  activitySubtitle: string,
  accounts: AccountWithContactsAndSalesReps[]
};
export const ActivityDetailsRow = ({orgId, activitySubtitle, displayName ,accountId, accountName, activity, onActivityUpdate, onActivityDelete, activityTemplates, accounts}: ActivityDetailsRowProps) => {
  const api: API = useAPI();
  const {showSpinner, hideSpinner, spinnerVisibility} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const [activityViewModel, setActivityViewModel] = useState(new ActivityViewModel(api, activity, activityTemplates, [], [], undefined, undefined));
  const [openEditActivitiesForm, setOpenEditActivitiesForm] = useState(false);
  const [openedDeleteActivityModal, setOpenedDeleteActivityModal] = useState(false);
  const [openCompleteActivityWizard, setOpenCompleteActivityWizard] = useState(false);
  const [openMarkActivityAsPending, setOpenMarkActivityAsPending] = useState(false);

  useEffect(() => {
    ActivityViewModel.fromActivityWithAccounts(api, activity, activityTemplates, accounts)
      .then((vm) => setActivityViewModel(vm));
  }, [accounts]);

  const toggleEditActivitiesForm = () => setOpenEditActivitiesForm(!openEditActivitiesForm);
  const openDeleteActivityModal = () => {
    setOpenedDeleteActivityModal(true);
  };

  const closeDeleteActivityModal = () => setOpenedDeleteActivityModal(false);
  const closeCompleteActivityModal = () => setOpenCompleteActivityWizard(false);
  const closeMarkActivityAsPendingModal = () => setOpenMarkActivityAsPending(false);

  const updateActivity = () => {
    showSpinner();
    activityViewModel.updateActivity(orgId, activity).then( (updatedActivity) => {
      hideSpinner();
      successToast('activity successfully updated');
      setActivityViewModel(new ActivityViewModel(api, updatedActivity, activityTemplates, [], [],activityViewModel.selectedAccountWithContactsAndSalesReps, activityViewModel.selectedAccounts));
      setOpenEditActivitiesForm(false);
      onActivityUpdate(updatedActivity);
    })
      .catch(() => {
        errorToast('could not update the activity');
      })
      .finally(hideSpinner);
  };

  const contactsInfo = () => {
    return (activity.contacts.length > 1) ? `${activity.contacts.length}` : `${activity.contacts[0].name}`;
  };

  const updateCompletedValueForActivity = (completed: boolean, responses: ActivityCompletionResponse[]) => {
    const vm = activityViewModel.update({completed: completed, responses: responses});
    vm.updateActivity(orgId, activity).then( (updatedActivity) => {
      setActivityViewModel(new ActivityViewModel(api, updatedActivity as Activity, activityTemplates, [], [],vm.selectedAccountWithContactsAndSalesReps, vm.selectedAccounts));
      onActivityUpdate(updatedActivity);
      closeCompleteActivityModal();
      closeMarkActivityAsPendingModal();
      successToast(completed ? 'activity marked as completed' : 'activity marked as pending');
    })
      .catch(() => {
        errorToast('could not update the activity');
      });
  };

  const openActivityCompletionModal = () => {
    if(!activityViewModel.completed) {
      if((activityViewModel.activity.activityTemplate!.questions.length) > 0) {
        setOpenCompleteActivityWizard(true);
      } else {
        updateCompletedValueForActivity(true, []);
      }
    }
    else setOpenMarkActivityAsPending(true);
  };

  const renderContacts = () => {
    if (activity.contacts.length > 0) {
      return (<div className="contacts-info">
        <FontAwesomeIcon icon={faUser} size="xs"/>
        <div className="number-of-contacts">{contactsInfo()}</div>
      </div>);
    }
    return <></>;
  };

  const renderActivityDetails = () => {
    if (!openEditActivitiesForm) {
      return (
        <div>
          <div className="activity-row__header">
            <div className="activity-row__header__info">
              <div className="activity-row__header__info__checked">
                <OrdoCheckbox  notToggleOnClick id={activity.id} checked={activityViewModel.completed} onChange={openActivityCompletionModal}/>
                <div className="activity-row__header__info__subject" title={displayName}>{displayName}</div>
              </div>
              <div className="activity-row__header__info__subtitle-info">
                <div className="activity-row__header__info__subtitle-info__date">{`${OrdoDate.from(activity.date).format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY)}${activity.time ? `, ${OrdoDate.from(activity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : ''}`}</div>
                <div>•</div>
                <div className="activity-row__header__info__subtitle-info__subtitle">
                  <div className="activity-row__header__info__subtitle-info__subtitle__type">{activitySubtitle}</div>
                  {renderContacts()}
                </div>
              </div>
            </div>
            <div className="activity-row__header__editing">
              <FontAwesomeIcon className="activity-row__header__editing__icon" size="lg" icon={faEdit} onClick={toggleEditActivitiesForm}/>
              <FontAwesomeIcon className="activity-row__header__editing__icon" size="lg" style={{marginLeft: '1em'}} icon={faTrashAlt} onClick={openDeleteActivityModal}/>
            </div>
          </div>
          <pre className="activity-row__description">{activity.description}</pre>
        </div>
      );
    }
    return <span/>;
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <div className="activity-row">
      { renderActivityDetails() }
      <ActivityResponsesDisplay activity={activity} accountName={accountName}/>
      {openEditActivitiesForm &&
      <MobileDisplayAsModal title="edit an activity" onClose={() => setOpenEditActivitiesForm(false)} show={openEditActivitiesForm}>
        <div className="edit-form-container">
          <ActivityForm
            selectedAccountId={accountId}
            accountsWithContacts={activityViewModel.accountsWithContactsAndSalesReps()}
            activityId={activity.id}
            viewModel={activityViewModel}
            canEditSalesRep={false}
            updateViewModel={setActivityViewModel}
            onSubmit={updateActivity}
            onCancel={() => setOpenEditActivitiesForm(false)}
            accountContacts={activityViewModel.contactsFromSelectedAccount()}
          />
        </div>
      </MobileDisplayAsModal>}
      {openedDeleteActivityModal &&
      <ConfirmationModal
        show={openedDeleteActivityModal}
        onClose={closeDeleteActivityModal}
        onSubmit={onActivityDelete}
        confirmationText='are you sure you want to delete the activity?'
        actionText='delete'
      />}
      <ActivityCompleteModal activity={activityViewModel.toActivity(orgId, activity.id)} isOpen={openCompleteActivityWizard} onClose={closeCompleteActivityModal} onSubmit={(responses)=>updateCompletedValueForActivity(true, responses)}/>
      {openMarkActivityAsPending && <ConfirmationModal
        show={openMarkActivityAsPending}
        onClose={closeMarkActivityAsPendingModal}
        onSubmit={()=>updateCompletedValueForActivity(false, [])}
        confirmationText="marking the activity as incomplete will delete any responses, are you sure you want to continue?"
        actionText='mark incomplete'
      />}
    </div>
  </OrdoSpinner>;
};
