import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDollarSign, faUser} from '@fortawesome/free-solid-svg-icons';
import OrderWithCurrentVersion from '../../../../models/order-entry/OrderWithCurrentVersion';
import {DateFormatTemplate, OrdoDate} from '../../../../models/OrdoDate';
import { AccountActivityOrderExpandedProducts } from './AccountActivityOrderExpandedProducts';

export const AccountActivityOrder = ({order} : {order: OrderWithCurrentVersion}) => {
  const [expandedCard, setExpandedCard] = useState(false);
  const renderContact = () => {
    return (
      <div>
        <FontAwesomeIcon className="contacts-icon" onClick={() => {}} size="sm" icon={faUser}/>
        <span>{order.contact.name}</span>
      </div>);
  };

  return(<div className="account-activity-card" onClick={() => setExpandedCard(!expandedCard)} role='presentation'>
    <div className='content'>

      <div className="icon-container order">
        <FontAwesomeIcon icon={faDollarSign} size="2x" />
      </div>
      <div className="information">
        <div className="collapsed">
          <div>
            <div className="activity-title">
              <span >{`Order #${order.orderNumber} ${order.statusToString()}`}</span>
            </div>
            <div className="note-information">
              <span>{`${OrdoDate.from(order.datePlaced).format(DateFormatTemplate.MONTH_DAY_YEAR_HOUR_IN_12)} • ${order.salesRepName}`}</span>
              {renderContact()}
            </div>
          </div>
          {!expandedCard && <div className="actions">
            <span className="total">{order.currentVersion.summary.grandTotal.formatted()}</span>
          </div>}
        </div>

        {expandedCard &&
            <div className="expanded-order-container">
              <AccountActivityOrderExpandedProducts items={order.currentVersion.items} invoiceURL={order.currentVersion.invoiceUrl} grandTotal={order.currentVersion.summary.grandTotal}/>
            </div>
        }
      </div>
    </div>
  </div>);
};
