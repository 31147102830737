import dayjs from 'dayjs';

export class OrdoDateException implements Error {
  constructor(
    public message: string,
    public name: string = 'OrdoDateException',
  ) {}
}

export enum DateFormatTemplate {
  MONTH_NAME_ABBREVIATED = 'MMM',
  MONTH_NAME = 'MMMM',
  WEEKDAY_NAME = 'dddd',
  WEEKDAY_NAME_ABBREVIATED = 'ddd',
  DAY_OF_MONTH = 'D',
  FULL_DATE = 'MMMM D, YYYY',
  DAY_OF_MONTH_DETAILED = 'ddd MMMM D',
  DAY_OF_MONTH_DETAILED_WITH_YEAR = 'ddd MMMM D, YYYY',
  FULLDAY_OF_MONTH_DETAILED = 'dddd MMMM D',
  LOCATION_ACTIVITY_CARDS_DATE = 'MMM D, YYYY',
  MONTH_NAME_ABBREVIATED_AND_DAY = 'MMM D',
  DAY_DATE_TIME = 'dddd, MMMM D, HH:mm',
  MONTH_DAY_YEAR_HOUR_IN_12 = 'MMM D, YYYY h:mm A',
  MONTH_DAY_HOUR_IN_12 = 'MMM D, h:mm A',
  MONTH_NAME_AND_DAY = 'MMMM D',
  HOURS_AND_MINUTES = 'h:mm A',
  WEEKDAY_NAME_AND_MONTH_YEAR = 'dddd MMMM D, YYYY',
  DAY_MONTH_AND_DATE = 'dddd MMM D',
  MONTH_YEAR = 'MM-YYYY'
}

export type OrdoTimeUnit = 'year' | 'month' | 'day' | 'week'

export class OrdoDate {

  constructor(public date: dayjs.Dayjs) {
  }

  public static from(date: Date) {
    return new OrdoDate(dayjs(date));
  }

  public static convertFromString(date: string) {
    const fromDate = dayjs(date);
    if(!fromDate.isValid()) throw new OrdoDateException(`Couldn't create OrdoDate from: ${date}`);
    return new OrdoDate(fromDate);
  }

  public static yesterday() {
    return dayjs().subtract( 1, 'day' );
  }

  public toDate() {
    return this.date.toDate();
  }

  public format(template: DateFormatTemplate) {
    return this.date.format(template);
  }


  public addDays(days: number) {
    return this.date.add(days, 'day');
  }

  public subtract(amount: number, unit: OrdoTimeUnit): OrdoDate {
    return new OrdoDate(this.date.subtract(amount, unit));
  }


  public add(amount: number, unit: OrdoTimeUnit) {
    return new OrdoDate(this.date.add(amount, unit));
  }

  public greaterThan(date: OrdoDate): number {
    return this.date > date.date ? -1 : 1;
  }

  public beforeThan(date: OrdoDate): boolean {
    return this.date.isBefore(date.toDate());
  }

  public isAfter(date: OrdoDate): boolean {
    return this.date.isAfter(date.toDate());
  }

  public static isDate(dateString: string) {
    return !Number.isNaN(Date.parse(dateString));
  }

  public static isToday(date: Date) {
    const fromDate = dayjs(date);
    const today = dayjs();
    return fromDate.year() === today.year() && fromDate.month() === today.month() && fromDate.date() === today.date();
  };

  public static isDueDate(date: Date) {
    const dateToCompare = OrdoDate.from(date);
    const today = OrdoDate.from(new Date());
    return dateToCompare.beforeThan(today) && !OrdoDate.isToday(date);
  }

  public difference(from: OrdoDate, unit: OrdoTimeUnit ) {
    return this.date.diff(from.date, unit);
  }

  public month() {
    return this.date.month();
  }

  public isSameDate(date: OrdoDate) {
    return this.date.isSame(date.date, 'day');
  }
}
