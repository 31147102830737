import React from 'react';
import '../../../scss/ordo/buttons.scss';
import  OrdoModal from '../common/OrdoModal';
import { ActualMember } from '../../../models/member/ActualMember';
import OrdoButton from '../common/OrdoButton';


const DeleteMemberConfirmationModal = ({onClose, isOpen, member, onDeleteMember}:
                                   { isOpen: boolean,
                                     onClose: Function,
                                     member: ActualMember,
                                     onDeleteMember: Function
                                   }) => {

  return member &&
    <OrdoModal
      onClose={onClose}
      onSubmit={onDeleteMember}
      show={isOpen}
      showFooter={false}
      title={`Are you sure you want to remove ${member.getName()}?`}
      actionText='remove'
      size='sm'
      dataTestId='edit-user-permission-modal'
    >
      <div className="verify-delivery-buttons">
        <div className="verify-order-button">
          <OrdoButton disabled={false} text="remove" category="primary" onClick={() => onDeleteMember(member)} dataTestId="confirm-contact-deletion-button"/>
        </div>
        <div className="verify-order-button">
          <OrdoButton disabled={false} text="cancel" category="cancel" onClick={() => onClose()} dataTestId="cancel-contact-deletion-button"/>
        </div>
      </div>
    </OrdoModal>;
};

export default DeleteMemberConfirmationModal;
