import React, {FC, useContext, useEffect, useState} from 'react';
import '../../../scss/ordo/common.scss';
import '../../../scss/ordo/inventoryPage/inventory-list.scss';
import {isEmpty, orderBy} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExchangeAlt} from '@fortawesome/free-solid-svg-icons';
import InventoryListItem from './InventoryListItem';
import {useAPI} from '../../../context/OrdoApiContext';
import {UserSessionContext} from '../../../context/UserSessionContext';
import Product from '../../../models/Product';
import SearchBar from './searchBar/SearchBar';
import OrdoButton from '../common/OrdoButton';
import useModalToggle from '../../../hooks/useModalToggle';
import ProductFormModal from '../modals/ProductFormModal';
import ProductCategory from '../../../models/ProductCategory';
import OrdoSpinner from '../OrdoSpinner';
import ProductDeleteModal from '../modals/ProductDeleteModal';
import AllocationsModal from './AllocationsModal';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {MetrcItemsSync} from './metrc-integration-sync/MetrcItemsSync';
import {DropDownMenuAction} from '../common/OrdoCardOptionsDropdown';
import {MetrcLinkProductWizard} from './metrc-integration-link/MetrcLinkProductWizard';
import {
  disableForNabisUserWithRestrictedAccess,
  displayFlourishIntegration,
  displayMetrcIntegration
} from '../../../lib/featureFlags';
import {FlourishItemsSync} from './flourish-integration-sync/FlourishItemsSync';
import ProductFilterViewModel from '../../../application-models/ProductFilterViewModel';
import {useFeatureFlags} from '../../../context/FeatureFlagsContext';
import {Brand} from '../../../models/Brand';
import {OrdoPagination} from '../pagination/OrdoPagination';
import {SortingCriteria} from '../../../application-models/sales-activity/SalesActivityViewModel';

type InventoryListProps = {
  isVisible: boolean,
  loading: boolean,
  brands: Brand[],
  categories: ProductCategory[],
  products: Product[],
  refreshProducts: () => Promise<void>
};
const InventoryList: FC<InventoryListProps> = ({isVisible, loading, brands, categories, products, refreshProducts}: InventoryListProps) => {
  const api = useAPI();
  const userSession = useContext(UserSessionContext);
  const featureFlags = useFeatureFlags();
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>(undefined);
  const {showModal: showProductModal, closeModal: closeProductModal, openModal: openProductModal } = useModalToggle();
  const {showModal: showAllocationsModal, closeModal: closeAllocationsModal, openModal: openAllocationsModal } = useModalToggle();
  const {showModal: showMetrcProductLinkWizard, closeModal: closeMetrcProductLinkWizard, openModal: openMetrcProductLinkWizard } = useModalToggle();
  const [toBeDeletedProduct, setToBeDeletedProduct] = useState<Product | undefined>(undefined);
  const {showModal, closeModal, openModal } = useModalToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const [filterProductsViewModel, setFilterProductsViewModel] = useState(ProductFilterViewModel.emptySearchBarViewModel<Product>(userSession.currentOrganization()?.licenses!));
  const [sortingCriteria, setSortingCriteria] = useState<SortingCriteria>(SortingCriteria.NO_SORTING);

  const linkProduct = (product: Product) => {
    setSelectedProduct(product);
    openMetrcProductLinkWizard();
  };

  const editProduct = (product: Product) => {
    setSelectedProduct(product);
    openProductModal();
  };

  const allocateProduct = (product: Product) => {
    setSelectedProduct(product);
    openAllocationsModal();
  };

  const closeAllocateProduct = () => {
    setSelectedProduct(undefined);
    closeAllocationsModal();
  };

  const deleteProduct = (product: Product) => {
    setToBeDeletedProduct(product);
    openModal();
  };

  const closeProductForm = () => {
    setSelectedProduct(undefined);
    closeProductModal();
  };

  const closeProductDeleteModal = () => {
    setToBeDeletedProduct(undefined);
    closeModal();
  };

  const confirmDeleteProduct = () => {
    return api.deleteProduct(toBeDeletedProduct!.organizationId, toBeDeletedProduct!.id)
      .then( () => {
        successToast('Product deleted successfully!');
        refreshProducts();
      }).catch( (error) => {
        errorToast(error.response.data.message);
      })
    ;
  };

  const onPageChange = (newPage: number)=> {
    const updatedViewModel = filterProductsViewModel.changePage(newPage);
    updatedViewModel.paginate().then(()=>setFilterProductsViewModel(updatedViewModel));
  };

  useEffect(() => {
    const updatedViewModel = filterProductsViewModel.updateProducts(products);
    updatedViewModel.paginate().then(()=>setFilterProductsViewModel(updatedViewModel));
  }, [products]);

  const getSortingCriteria = () => {
    switch (sortingCriteria) {
    case SortingCriteria.NO_SORTING:
      return '';
    case SortingCriteria.ASCENDING:
      return 'asc';
    case SortingCriteria.DESCENDING:
      return 'desc';
    default:
      return '';
    }
  };

  const sortedProducts = () => {
    const criteria = getSortingCriteria();
    return criteria ? orderBy(filterProductsViewModel.paginatedProducts(), ['name'], [criteria]) : orderBy(filterProductsViewModel.paginatedProducts(), ['id']);
  };

  const changeSortCriteria = () => {
    switch (sortingCriteria) {
    case SortingCriteria.NO_SORTING:
      setSortingCriteria(SortingCriteria.ASCENDING);
      break;
    case SortingCriteria.ASCENDING:
      setSortingCriteria(SortingCriteria.DESCENDING);
      break;
    case SortingCriteria.DESCENDING:
      setSortingCriteria(SortingCriteria.NO_SORTING);
      break;
    default:
      setSortingCriteria(SortingCriteria.NO_SORTING);
      break;
    }
  };

  const getSortingCriteriaText = () => {
    const criteria = getSortingCriteria();
    return criteria ? `: ${criteria}` : '';
  };

  const productOptions = (product: Product): DropDownMenuAction[] => {
    const options: DropDownMenuAction[] = [];

    if (displayMetrcIntegration(featureFlags))
      options.push({
        name: 'Metric Item ID',
        action: () => linkProduct(product)
      });

    if (!disableForNabisUserWithRestrictedAccess(userSession.userSession, featureFlags))
      options.push({
        name: 'Allocations',
        action: () => allocateProduct(product)
      });

    options.push(
      {
        name: 'Edit product',
        action: () => editProduct(product)
      },
      {
        name: 'Delete Entry',
        action: () => deleteProduct(product)
      }
    );

    return options;
  };

  const ProductsContent = () => {
    return (
      <div className="table-responsive">
        <table className="table align-items-center mb-lg-5">
          <thead>
            <tr className="table-headers with-border-bottom">
              <th scope="col" className='name-column'>
                <span>Product</span>
                <div role='presentation' onClick={changeSortCriteria} style={{display: 'flex', alignItems: 'center'}}>
                  <FontAwesomeIcon className="sort-icon" icon={faExchangeAlt}/>
                  <span>{getSortingCriteriaText()}</span>
                </div>
              </th>
              <th scope="col" style={{width: '15%'}}>Brand</th>
              <th scope="col" style={{width: '15%'}}>Size</th>
              <th scope="col" style={{width: '10%'}}>Price</th>
              <th scope="col" style={{width: '35%'}}>Available Batches</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th scope="col" style={{width: '0'}}/>
            </tr>
          </thead>
          <tbody className="list inventory-list">
            {sortedProducts().map((item: Product) =>
              <InventoryListItem
                filteredDistributorLicensesIds={filterProductsViewModel.getBatchesOrganizationLicensesIds()}
                product={item}
                key={item.id}
                onBatchesChanged={refreshProducts}
                options={productOptions(item)}
              />)}
          </tbody>
        </table>
      </div>
    );
  };

  const NoProductsContent = () => {
    return (
      <div className='no-product-content'>No products available</div>
    );
  };
  const renderContent = () => {
    if (isEmpty(filterProductsViewModel.filteredProducts)) {
      return <NoProductsContent/>;
    }
    return <>
      <ProductsContent/>
      <OrdoPagination currentPage={filterProductsViewModel.currentPage} totalOfPages={filterProductsViewModel.totalPages()} onPageChange={onPageChange}/>
    </>;
  };
  const organization = userSession.currentOrganization();
  const organizationHasBrands = organization && !isEmpty(brands);
  const canCreateProduct = organizationHasBrands && !isEmpty(categories);
  const canOpenEditProductModal = (canCreateProduct || !!selectedProduct) && !toBeDeletedProduct;

  return (
    <OrdoSpinner showSpinner={loading && isVisible}>
      <div className={`card card-fluid inventory-list-card ordo-shadow ordo-card-border ${isVisible ? '' : 'ordo-hide'}`}>
        {!isEmpty(products) && <SearchBar stockOnlyFilter viewModel={filterProductsViewModel} updateViewModel={setFilterProductsViewModel}/>}
        <div className={`inventory-list-card-content ${isEmpty(filterProductsViewModel.filteredProducts) ? 'no-product-width' : ''}`}>
          <div className="inventory-list-button-header">
            <OrdoButton text='Add Product'
              onClick={openProductModal}
              category='primary'
              disabled={!canCreateProduct}
              disabledText={!organizationHasBrands ? 'This organization has no brands. Please create one.' : undefined}
              dataTestId='add-product-button'
              squared
            />
            {displayMetrcIntegration(featureFlags) && <MetrcItemsSync/>}
            {displayFlourishIntegration(featureFlags) && <FlourishItemsSync onSuccess={refreshProducts}/>}
          </div>

          {renderContent()}
          {
            (canOpenEditProductModal || !!selectedProduct) && <ProductFormModal
              isOpen={showProductModal}
              onSubmit={refreshProducts}
              onClose={closeProductForm}
              categories={categories}
              brands={brands || []}
              organizationId={organization?.id || ''}
              editingProduct={selectedProduct}
            />
          }
          {
            !!toBeDeletedProduct && <ProductDeleteModal
              isOpen={showModal}
              onSubmit={confirmDeleteProduct}
              onClose={closeProductDeleteModal}
              product={toBeDeletedProduct}
            />
          }
        </div>
        {
          selectedProduct && <AllocationsModal
            isOpen={showAllocationsModal}
            onClose={closeAllocateProduct}
            product={selectedProduct}
            api={api}
            organization={userSession.currentOrganization()!}
          />
        }
        {
          displayMetrcIntegration(featureFlags) && selectedProduct && <MetrcLinkProductWizard
            show={showMetrcProductLinkWizard}
            onClose={closeMetrcProductLinkWizard}
            product={selectedProduct}
            onSubmit={closeMetrcProductLinkWizard}
          />
        }
      </div>
    </OrdoSpinner>
  );
};

export default InventoryList;
