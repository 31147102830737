import React, {useEffect, useState} from 'react';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../OrdoSpinner';
import OrdoModal from '../common/OrdoModal';
import {ActivityForm} from '../activities/ActivityForm';
import ActivityViewModel from '../../../application-models/sales-activity/ActivityViewModel';
import {useAPI} from '../../../context/OrdoApiContext';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {Activity, ActivityItem} from '../../../models/sales-activity/Activity';

type ActivityModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: Function,
  onSubmitMany?: Function,
  organizationId: string,
  activity?: ActivityItem,
  canEditAccount?: boolean,
  customClassName?: string,
  canEditSalesRep?: boolean,
}

export const ActivityModal = ({isOpen, onClose, organizationId, onSubmit, onSubmitMany, activity, canEditAccount = true,  canEditSalesRep = false, customClassName} : ActivityModalProps) => {
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const api = useAPI();
  const [activityViewModel, setActivityViewModel] = useState(ActivityViewModel.empty(api));
  const {errorToast, successToast} = useOrdoToasts();

  useEffect(() => {
    showSpinner();
    const activityAccountId = activity ? activity.accountId : undefined;
    if(activity) {
      ActivityViewModel.initializeForEdit(api, organizationId, activity, activity.accountId)
        .then((vm) => {
          setActivityViewModel(vm);
          hideSpinner();
        });
    } else {
      ActivityViewModel.initialize(api, organizationId, activityAccountId)
        .then((vm) => {
          setActivityViewModel(vm);
          hideSpinner();
        });
    }
  }, []);

  const createActivity = () => {
    if((activityViewModel.selectedAccounts!.length) > 1) {
      return activityViewModel.createActivityForAccounts(organizationId);
    }
    return activityViewModel.createActivity(activityViewModel.selectedAccountId()!, organizationId);

  };

  const editActivity = () => {
    return activityViewModel.updateActivity(organizationId, activity!);
  };

  const submitAction = (action: () => Promise<Activity | Activity[]>, errorText: string, successText: string) => {
    showSpinner();
    action()
      .then((result) => {
        if(Array.isArray(result)) {
          if(onSubmitMany) onSubmitMany(result.map(newActivity => {
            const account = activityViewModel.selectedAccounts?.find(selectedAccount => selectedAccount.accountId === newActivity.accountId);
            return {...newActivity, accountName: account?.name || ''};
          }));
          else throw new Error('creating activity for multiple accounts not supported here');
        } else {
          onSubmit({...result, accountName: activityViewModel.singleSelectedAccount()?.name});
        }
        onClose();
        successToast(successText);
        setActivityViewModel(ActivityViewModel.empty(api));
      })
      .catch((_error) => {
        errorToast(errorText);
      })
      .finally(hideSpinner);
  };
  const title = activity ? 'edit activity': 'create activity';
  const submit = activity ? () => submitAction(editActivity, 'Could not edit activity', 'Activity Updated') : () => submitAction(createActivity, 'could not create activity', 'Activity Created');
  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoModal show={isOpen}
        title={title}
        onClose={() => onClose()}
        onSubmit={() => {}}
        showFooter={false}
        actionText='create'
        className={customClassName}
      >
        <ActivityForm viewModel={activityViewModel}
          accountsWithContacts={activityViewModel.accountsWithContactsAndSalesReps()}
          updateViewModel={setActivityViewModel}
          onSubmit={submit}
          canEditSalesRep={canEditSalesRep}
          onCancel={() => onClose()}
          selectedAccountId={activity ? activity.accountId : undefined}
          canEditAccount={canEditAccount}
          accountContacts={activityViewModel.contactsFromSelectedAccount()}
        />
      </OrdoModal>
    </OrdoSpinner>
  );
};
