import {sortBy} from 'lodash';
import {API} from '../lib/api/ordoApi';
import {emptyOrganization, Organization} from '../models/Organization';
import FormError, {FormFields} from '../errors/form_error';
import BusinessError from '../errors/domain_errors';
import InvalidField from '../errors/invalid_field';
import {Member} from '../models/member/Member';
import {ActualMember} from '../models/member/ActualMember';
import {Brand} from '../models/Brand';

export default class OrganizationPageViewModel {

  static empty(api: API) {
    return new OrganizationPageViewModel(api,
      emptyOrganization,
      '',
      FormError.withoutError());
  }

  static async initialize(api: API, currentOrganizationId: string) {
    const currentOrganization = await api.getOrganization(currentOrganizationId);
    return new OrganizationPageViewModel(api,
      currentOrganization,
      currentOrganization.name,
      FormError.withoutError());
  }

  constructor(
    private readonly api: API,
    public readonly currentOrganization: Organization,
    public newOrganizationName: string,
    private error: FormError
  ) {
  }

  public currentOrganizationName() {
    return this.newOrganizationName;
  }

  public async updateOrganization() {
    const updatedOrganization = await this.api.getOrganization(this.currentOrganization.id);
    return new OrganizationPageViewModel(this.api,
      updatedOrganization,
      this.currentOrganization.name,
      FormError.withoutError());
  }

  public internalUpdateOrganizationName(newName: string) {
    return new OrganizationPageViewModel(this.api,
      this.currentOrganization,
      newName,
      FormError.withoutError());
  }

  public async updateOrganizationName() {
    if (this.newOrganizationName === this.currentOrganization.name || !this.newOrganizationName) {
      return this;
    }
    try {
      const organization = await this.api.editOrganizationName(this.currentOrganization.id, this.newOrganizationName);
      return new OrganizationPageViewModel(this.api,
        organization,
        organization.name,
        FormError.withoutError());
    } catch (error) {
      const formError: FormError = error instanceof BusinessError ?
        error.formError : FormError.withErrors([new InvalidField('unknown', error.message)]);

      return new OrganizationPageViewModel(this.api,
        this.currentOrganization,
        this.newOrganizationName,
        formError);
    }
  }

  public hasErrorFor(input: FormFields) {
    return this.error.hasErrorForInput(input);
  }

  public errorMessage(input: FormFields) {
    return this.error.errorMessage(input);
  }

  public getCurrentOrganizationMembers(): Member[] {
    return sortBy(
      [...this.currentOrganization.members, ...this.currentOrganization.pendingInvitations],
      ['email']
    );
  }

  public locallyRemovePendingInvite(selectedMember: Member): OrganizationPageViewModel {
    const {pendingInvitations} = this.currentOrganization;
    const index = pendingInvitations.findIndex(pendingInvite => pendingInvite.id === selectedMember.id);
    pendingInvitations.splice(index, 1);
    return new OrganizationPageViewModel(
      this.api,
      this.currentOrganization,
      this.currentOrganization.name,
      FormError.withoutError()
    );
  }

  public currentOrganizationId() {
    return this.currentOrganization.id;
  }

  public deleteMember(member: ActualMember): OrganizationPageViewModel{
    this.currentOrganization.members = this.currentOrganization.members.filter(m => !m.hasUserId(member.getUserId()));
    return new OrganizationPageViewModel(this.api, this.currentOrganization, this.newOrganizationName, this.error);
  }

  public updateBrand(brand: Brand): OrganizationPageViewModel {
    // TODO: do not change the brands order;
    const organizationBrands = this.currentOrganization.brands.filter(b => b.id !== brand.id);
    organizationBrands.push(brand);
    this.currentOrganization.brands = organizationBrands;
    return new OrganizationPageViewModel(this.api, this.currentOrganization, this.newOrganizationName, this.error);
  }
}
