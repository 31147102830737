import {groupBy} from 'lodash';
import {DateFormatTemplate, OrdoDate, OrdoTimeUnit} from './OrdoDate';
import {PastTimeSlot} from './TimeSlot';


function sortByMonthAndYear(monthYear1: string, monthYear2: string) {

  // expected date format = month-year
  const [month1, year1] = monthYear1.split('-').map(v => parseInt(v, 10));
  const [month2, year2] = monthYear2.split('-').map(v => parseInt(v, 10));
  if (year1 > year2) return 1;
  if (year1 < year2) return -1;
  return month1 > month2 ? 1 : -1;
}

export class TimelineSection {
  constructor(public readonly slots: PastTimeSlot[], private unit: OrdoTimeUnit, private total: number) {
  }

  public description() {
    switch (this.unit) {
    case 'week':
      return `${this.slots[0].start.format(DateFormatTemplate.MONTH_NAME_AND_DAY)  } - ${this.slots[this.slots.length-1].end.subtract(1,'day').format(DateFormatTemplate.MONTH_NAME_AND_DAY)}`;
    case 'month':
      return this.slots[this.slots.length-1].end.subtract(1,'day').format(DateFormatTemplate.MONTH_NAME);
    default:
      return '';
    }
  }

  public width() {
    return this.slots.length/(this.total);
  }
}
interface TimelineSeparator {
  separate(timelineSlots: PastTimeSlot[], referencePoint: OrdoDate): TimelineSection[];
}

class SeparateByMonth implements  TimelineSeparator {
  public separate(timelineSlots: PastTimeSlot[], _referencePoint: OrdoDate) {
    const slotsGroupedByMonthAndYear = groupBy(timelineSlots, (slot) => slot.end.subtract(1,'day').format(DateFormatTemplate.MONTH_YEAR));
    return Object.keys(slotsGroupedByMonthAndYear)
      .sort(sortByMonthAndYear)
      .map(k => new TimelineSection(slotsGroupedByMonthAndYear[k], 'month', timelineSlots.length));
  }
}

const WITHIN_A_WEEK = 1;
const MORE_THAN_A_WEEK = 2;
const DAYS_IN_A_WEEK = 7;

class SeparateByWeek implements  TimelineSeparator {

  public separate(timelineSlots: PastTimeSlot[], referencePoint: OrdoDate) {

    const slotsGroupedByWeek = groupBy(timelineSlots, (slot) => {
      const difference = referencePoint.difference(slot.end, 'day');
      if(difference <= DAYS_IN_A_WEEK) return WITHIN_A_WEEK;
      return MORE_THAN_A_WEEK;
    });
    return Object.keys(slotsGroupedByWeek).sort((a, b) => a <= b ? 1 : -1).map(k => new TimelineSection(slotsGroupedByWeek[k], 'week', timelineSlots.length));
  }
}

export class TimePeriod {
  public readonly slots: number;
  public readonly days: number;
  public readonly slotSize: number;
  public readonly separator: TimelineSeparator;
  public readonly sections: TimelineSection[];
  public readonly periodEnd: OrdoDate;

  public static days90() {
    return new TimePeriod(90, 30, 3, new SeparateByMonth());
  }

  public static days30() {
    return new TimePeriod(30, 30, 1, new SeparateByMonth());
  }

  public static days15() {
    return new TimePeriod(15, 15, 1, new SeparateByWeek());
  }

  private constructor(days: number, slots: number, slotSize: number, separator: TimelineSeparator) {
    this.slots = slots;
    this.days = days;
    this.slotSize = slotSize;
    this.separator = separator;
    const today = new Date();
    today.setHours(0,0,0,0);
    this.periodEnd = OrdoDate.from(today).add(1,'day');
    const since = this.periodEnd.subtract(this.days, 'day');

    const timelineSlots = Array.from(Array(this.slots).keys()).map((_, index) => {
      const start = since.add(index * this.slotSize, 'day');
      let end = start.add(this.slotSize, 'day');
      if (end.isAfter(this.periodEnd)) end = this.periodEnd;

      return new PastTimeSlot(start, end, index);
    });

    this.sections = this.separator.separate(timelineSlots, this.periodEnd);
  }

  public end() {
    return this.periodEnd;
  }

  public start() {
    return this.periodEnd.subtract(this.days, 'day');
  }
}
