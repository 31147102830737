import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  ActivityCompletionResponseProps, FileUploadActivityCompletionResponse,
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import OrdoFileInput from '../../common/inputs/OrdoFileInput';
import {OrdoFile} from '../../../../models/OrdoFile';

const MAX_FILES = 3;

export const FileUploadResponse = ({
  response,
  updateResponse
}: ActivityCompletionResponseProps) => {
  const fileUploadResponse = response as FileUploadActivityCompletionResponse;
  const acceptedExtensions = ['.pdf','.png','.jpeg','.jpg','.docx'];

  const onFileSelected = (ordoFile: OrdoFile) => {
    const splitElement = ordoFile.name.split('.');
    const fileExtension = splitElement[splitElement.length-1];
    if(acceptedExtensions.includes(`.${fileExtension}`)){
      fileUploadResponse.body.files.push(ordoFile);
      updateResponse(fileUploadResponse);
    }
  };

  const removeFile = (index: number) => {
    fileUploadResponse.body.files.splice(index, 1);
    updateResponse(fileUploadResponse);
  };
  const supportedFileFormats = acceptedExtensions.join(', ');

  return <div className='file-upload-response'>
    <div>
      <OrdoFileInput disabled={fileUploadResponse.body.files.length >= MAX_FILES} text='upload file' onFileSelected={onFileSelected} supportedFormats={supportedFileFormats}/>
      <span>max. 3 files.</span>
    </div>
    <span>expected formats: {supportedFileFormats}</span>
    {fileUploadResponse.hasFiles() && <div className='uploaded-files'>
      <span className='title'>uploaded files:</span>
      {fileUploadResponse.body.files.map((file, index) =>
        <div key={file.preview} className='file-row' role='presentation' onClick={()=>removeFile(index)}><span>{file.name}</span> <FontAwesomeIcon icon={faTimes} className="icon"/> </div>)}
    </div>}
  </div>;
};