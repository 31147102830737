import Account, {AccountData} from './Account';
import {OrdoDate} from './OrdoDate';

export class AccountCard extends Account {
  public assigned: boolean;
  public selected: boolean;
  public visible: boolean;

  constructor(data: AccountData,
    assigned: boolean,
    selected: boolean,
    visible: boolean
  ) {
    super(data);
    this.assigned = assigned;
    this.selected = selected;
    this.visible = visible;
  }

  public static withSelected(selected: boolean, account: AccountCard) {
    return new AccountCard({
      id: account.id,
      name: account.name,
      organizationId: account.organizationId,
      locations: account.locations,
      allocations: account.allocations,
      assignments: account.assignments,
      contacts: account.contacts,
      notes: account.notes,
      activities: account.activities,
      stalePeriod: account.stalePeriod,
      priority: account.priority,
      value: account.value,
      conversion: account.conversion,
      active: account.active,
      orderEntryEnabled: account.orderEntryEnabled,
      status: account.status
    }, account.assigned, selected, account.visible);
  }

  public setVisible(visible: boolean) {
    this.visible = visible;
  }

  public setSelected(selected: boolean) {
    this.selected = selected;
  }

  public hasAssignments(): boolean {
    return this.assignments.length > 0;
  }

  public getAssignmentText() {
    if(this.assignments.length > 1 ){
      return `${this.assignments.length}`;
    }
    const firstAssignment = this.assignments[0];
    return `${firstAssignment.member.memberName().charAt(0).toUpperCase()}`;
  }

  // The current criteria for an account to be labeled as new is that its newest license is no older than one month.
  public isNewAccount() {
    const oneMonthAgo = OrdoDate.from(new Date()).subtract(30,'day');
    return !this.locations.every(location => location.location.issuedAt.beforeThan(oneMonthAgo));
  }
}
