import React from 'react';
import {
  ActivityCompletionResponseDisplayProps,
  FileURLActivityCompletionResponse
} from '../../../../models/activity/responses/ActivityCompletionResponse';

export const FileUploadResponseDisplay = ({
  response,
}: ActivityCompletionResponseDisplayProps) => {
  const fileUploadResponse = response as FileURLActivityCompletionResponse;

  const {filenames, fileURLs} = fileUploadResponse.body;
  return <div className='file-upload-response'>
    <div className='uploaded-files'>
      {fileURLs.map((url, index) => {
        const splitURL = url.split('/');
        const uploadedFilename = splitURL[splitURL.length - 1];
        return <div key={url} className="file-row" role="presentation" onClick={() => {
          window.open(url, '_blank');
        }}>
          <span>{filenames[index] || uploadedFilename}</span>
        </div>;
      })}
    </div>
  </div>;
};