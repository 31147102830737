import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {ProductSKUPriceRule} from '../../../models/custom-pricing-tiers/Tier';
import {SelectableOption} from '../common/searchable-dropdown/selectableOption';
import Product from '../../../models/Product';
import OrdoSearchableDropdown from '../common/searchable-dropdown/OrdoSearchableDropdown';
import {filterProductDropdownOption, ProductDropdownMenuView, ProductDropdownSelectedView} from './ProductDropdownView';
import OrdoNumberInput from '../common/inputs/OrdoNumberInput';
import {customPriceEditorDropdownStyles} from './CustomPriceEditor';
import {PRIMARY_COLOR, WARNING_COLOR} from '../../../constants';

type ProductSKUPriceEditorProps = {
  skuPrice: ProductSKUPriceRule,
  products: SelectableOption<Product>[]
  onRemoveRow: (index: number) => void,
  index: number
  onChange: (p: ProductSKUPriceRule, index: number) => void
};
export const ProductSKUPriceEditor = ({
  skuPrice,
  products,
  onRemoveRow,
  index,
  onChange
}: ProductSKUPriceEditorProps) => {
  const selectedOption = products.find(product => product.value.customerSKU === skuPrice.customerSKU);
  return <div className="custom-price-editor product-sku" id={skuPrice.id}>
    <OrdoSearchableDropdown
      label="product SKU" placeholder="search a product by name or sku"
      selectedOption={selectedOption}
      options={products}
      onChangeSelectedOption={(option: SelectableOption<Product>) => onChange({
        ...skuPrice,
        customerSKU: option.value.customerSKU!
      }, index)}
      addedStyles={customPriceEditorDropdownStyles(1, skuPrice.customerSKU ? PRIMARY_COLOR : WARNING_COLOR)}
      selectedOptionCustomComponent={ProductDropdownSelectedView}
      menuOptionCustomComponent={ProductDropdownMenuView}
      customFilterOption={filterProductDropdownOption}
    />
    <OrdoNumberInput label="unit price" value={skuPrice.unitPrice}
      onChange={(newPrice) => onChange({...skuPrice, unitPrice: newPrice}, index)}
      min={0}
      displayArrows={false}
      isValid={skuPrice.unitPrice >= 0}
      invalidMessage=""
      inputKey=""
    />
    <FontAwesomeIcon icon={faMinusCircle} className="delete-row" onClick={() => onRemoveRow(index)}/>
  </div>;
};