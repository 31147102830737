import React, {useEffect, useState} from 'react';
import {AccountActivityEntity, AccountActivityType} from '../../../../../models/AccountActivity';
import {AccountActivityNote} from '../AccountActivityNote';
import {Note} from '../../../../../models/Note';
import {AccountActivityAssignment} from '../AccountActivityAssignment';
import {AccountRepAssignment} from '../../../../../models/AccountRepAssignment';
import {AccountActivityOrder} from '../AccountActivityOrder';
import OrderWithCurrentVersion from '../../../../../models/order-entry/OrderWithCurrentVersion';
import {ActivityForAccountActivity} from '../ActivityForAccountActivity';
import {Activity, ExternalEmailActivity} from '../../../../../models/sales-activity/Activity';
import Account from '../../../../../models/Account';
import {AccountActivityFilter} from './AccountActivityFilter';
import {ActivityTypeName} from '../../../../../models/sales-activity/ActivityTypeName';
import {ExternalEmailActivityForAccountActivity} from '../../ExternalEmailActivityForAccountActivity';

type AccountActivityListProps = {
  account: Account,
  accountActivity: AccountActivityEntity[],
  filter: AccountActivityFilter,
  updateEntity: Function,
  deleteEntity: Function
}

export const AccountActivityList = ({account, accountActivity, filter, updateEntity, deleteEntity}: AccountActivityListProps) => {
  const [filteredAccountActivity, setFilteredAccountActivity] = useState<AccountActivityEntity[]>([]);

  useEffect(() => {
    setFilteredAccountActivity(filter.filterAccountActivity(accountActivity));
  }, [filter, accountActivity]);

  return(
    <div className="activity">
      {
        filteredAccountActivity.map((accountActivityEntity: AccountActivityEntity, index : number) => {
          const entity = accountActivityEntity.accountActivity;
          const amountOfActivities = filteredAccountActivity.length;
          switch (accountActivityEntity.accountActivityType) {
          case AccountActivityType.NOTE:
            return (
              <div key={(entity as Note).id}>
                <AccountActivityNote accountId={account.id}
                  organizationId={account.organizationId}
                  index={index}
                  onEdit={updateEntity}
                  onDelete={deleteEntity}
                  accountContacts={account.contacts}
                  note={entity as Note}/>
                {!(amountOfActivities - 1 === index) && <div className="activity-divider"/>}
              </div>
            );
          case AccountActivityType.ASSIGNMENT:
            const assignment = accountActivityEntity.accountActivity as AccountRepAssignment;
            const id = `${assignment.accountId}-${assignment.user.id}`;
            return (
              <div key={id}>
                <AccountActivityAssignment assignment={accountActivityEntity.accountActivity as AccountRepAssignment}/>
                {!(amountOfActivities - 1 === index) && <div className="activity-divider"/>}
              </div>
            );
          case AccountActivityType.ORDER:
            return (
              <div key={(accountActivityEntity.accountActivity as OrderWithCurrentVersion).id}>
                <AccountActivityOrder  order={accountActivityEntity.accountActivity as OrderWithCurrentVersion}/>
                {!(amountOfActivities - 1 === index) && <div className="activity-divider"/>}
              </div>
            );
          case AccountActivityType.ACTIVITY:
            return (
              <div key={(accountActivityEntity.accountActivity as Activity).id}>
                {(accountActivityEntity.accountActivity as (Activity | ExternalEmailActivity)).type === ActivityTypeName.EXTERNAL_EMAIL_ACTIVITY ?
                  <ExternalEmailActivityForAccountActivity
                    activity={accountActivityEntity.accountActivity as unknown as ExternalEmailActivity}
                  /> :
                  <ActivityForAccountActivity account={account}
                    organizationId={account.organizationId}
                    index={index}
                    activity={accountActivityEntity.accountActivity as Activity}
                    onDelete={deleteEntity}
                    onEdit={updateEntity}/>
                }
                {!(amountOfActivities - 1 === index) && <div className="activity-divider"/>}
              </div>
            );
          default:
            return <ActivityForAccountActivity
              account={account}
              organizationId={account.organizationId}
              index={index}
              onDelete={deleteEntity}
              onEdit={updateEntity}
              key={(accountActivityEntity.accountActivity as Activity).id}
              activity={accountActivityEntity.accountActivity as Activity}/>;
          }
        })}
    </div>
  );
};
