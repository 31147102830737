import React, {useState} from 'react';
import {API} from '../../lib/api/ordoApi';
import {useAPI} from '../../context/OrdoApiContext';
import {SalesActivityAccount} from './salesActivityTypes';
import rankOne from './rankone_icon.png';
import rankTwo from './ranktwo_icon.png';
import rankThree from './rankthree_icon.png';
import Account from '../../models/Account';

type AccountPriorityProps = {
  onPriorityChange: () => any,
  account: SalesActivityAccount | Account,
  organizationId: string
}

export const AccountPriorityButton = ({account, organizationId, onPriorityChange} : AccountPriorityProps) => {
  const [accountPriority, setAccountPriority] = useState(account.priority);
  const api: API = useAPI();

  const onAccountPriorityClick = async () => {
    const getUpdatedPriority = (currentPriority: number) => {
      switch (currentPriority) {
      case 1: return 2;
      case 2: return 3;
      case 3: return 1;
      default: return 3;
      }
    };
    const updatedPriority = getUpdatedPriority(accountPriority);
    setAccountPriority(updatedPriority);
    await api.updateAccountPriority(organizationId, account.id, updatedPriority).then(onPriorityChange);
  };

  const srcFromPriority = () => {
    switch (accountPriority) {
    case 1: return rankOne;
    case 2: return rankTwo;
    case 3: return rankThree;
    default: return rankThree;
    }
  };

  return(
    <img role='presentation' className="account-header__priority-icon" alt={accountPriority.toString()}
      src={srcFromPriority()}
      onClick={onAccountPriorityClick}
    />
  );
};
