import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDotCircle} from '@fortawesome/free-solid-svg-icons/faDotCircle';
import {
  ActivityCompletionResponseDisplayProps,
  MultiValueActivityCompletionResponse,
} from '../../../../models/activity/responses/ActivityCompletionResponse';


export const MultipleChoiceResponseDisplay = ({
  response,
}: ActivityCompletionResponseDisplayProps) => {
  const multipleChoiceResponse = response as MultiValueActivityCompletionResponse;

  return <div className='checkbox-response'>
    {multipleChoiceResponse.options.map((option, index) => {
      const checked = multipleChoiceResponse.body.selectedOptions.includes(index);
      return <div className='checkbox-response__row centered' key={option}>
        <FontAwesomeIcon className={`option-icon ${checked ? 'checked' : ''}`} size="1x" icon={faDotCircle}/>
        <span className={`${checked ? 'checked' : ''}`}>{option}</span>
      </div>;
    })}
  </div>;
};