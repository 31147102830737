import React, {FC, useState} from 'react';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import '../../scss/purpose.scss';
import BrandCard from '../components/brands/BrandCard';
import {Brand} from '../../models/Brand';
import CreateBrandModal from '../components/brands/CreateBrandModal';
import OrdoInput from '../components/common/inputs/OrdoInput';
import OrdoPageSubTitle from '../components/common/OrdoPageSubTitle';
import '../../scss/ordo/organizationPage/organization-page.scss';
import InviteUsersModal from '../components/modals/InviteUsersModal';
import OrganizationPageViewModel from '../../application-models/organization-page-view-model';
import MemberCard from '../components/members/MemberCard';
import EditUserPermissionModal from '../components/modals/EditUserPermissionModal';
import {Member} from '../../models/member/Member';
import {ActualMember} from '../../models/member/ActualMember';
import GeneralErrorMessage from '../components/common/GeneralErrorMessage';
import ConfirmationModal from '../components/common/ConfirmationModal';
import {useAPI} from '../../context/OrdoApiContext';
import useOrdoToasts from '../../hooks/useOrdoToasts';
import {NotMember} from '../../models/member/NotMember';
import {Role} from '../../models/Roles';
import OrdoSpinner from '../components/OrdoSpinner';
import useSpinnerToggle from '../../hooks/useSpinnerToggle';
import DeleteMemberConfirmationModal from '../components/modals/DeleteMemberConfirmationModal';
import {EditBrandModal} from '../components/brands/EditBrandModal';


type OrganizationTabProps = RouteComponentProps & {
  viewModel: OrganizationPageViewModel,
  setViewModel: (vm: OrganizationPageViewModel) => void,
  onBrandUpdate: Function
}

const OrganizationTab: FC<OrganizationTabProps>  = ({viewModel, setViewModel, onBrandUpdate}: OrganizationTabProps) => {
  const api = useAPI();
  const {errorToast, successToast} = useOrdoToasts();
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const [showAddBrandModal, setShowAddBrandModal] = useState(false);
  const [showEditBrandModal, setShowEditBrandModal] = useState<Brand | undefined>(undefined);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(new NotMember(Role.Unknown, '', '') as Member);
  const [editUserPermissionModalData, setEeditUserPermissionModalData] = useState<{show: boolean, member:Member|null}>({show: false, member: null});
  const [deleteMemberConfirmationModal, setDeleteMemberConfirmationModal] = useState<{show: boolean, member:Member|null}>({show: false, member: null});

  const toggleCreateBrandModal = () => setShowAddBrandModal(!showAddBrandModal);
  const closeCreateBrandModal = () => setShowAddBrandModal(false);

  const toggleInviteUsersModal = () => setShowInviteUserModal(!showInviteUserModal);
  const closeInviteUserModal = () => setShowInviteUserModal(false);

  const toggleEditUserPermissionModal = (member: Member) => {
    setEeditUserPermissionModalData({show: !editUserPermissionModalData.show, member: member});
  };

  const toggleDeleteOrganizationMemberModal = (member: Member) => {
    setDeleteMemberConfirmationModal({show: !deleteMemberConfirmationModal.show, member: member});
  };

  const closeEditUserPermissionModal = () => setEeditUserPermissionModalData({show: false, member: null});
  const closeDeleteMemberConfirmationModal = () => setDeleteMemberConfirmationModal({show: false, member: null});

  const updateOrganization = () => {
    closeCreateBrandModal();
    closeInviteUserModal();
    closeEditUserPermissionModal();
    return viewModel.updateOrganization().then(setViewModel);
  };

  const internalUpdateOrganizationName = (newName: string) => {
    const newViewModel = viewModel.internalUpdateOrganizationName(newName);
    setViewModel(newViewModel);
  };

  const updateOrganizationName = async (_: any) => {
    viewModel.updateOrganizationName().then(setViewModel);
  };

  const currentOrganizationName = viewModel.currentOrganizationName();

  const deleteInvitation = () => {
    showSpinner();
    api.deleteInvitation(viewModel.currentOrganization.id, selectedMember.id).then(() => {
      setViewModel(viewModel.locallyRemovePendingInvite(selectedMember));
      successToast('Pending invite deleted successfully');
    }).catch(() =>
      errorToast('Couldn\'t delete that Pending invite')
    ).finally(hideSpinner);
  };

  const deleteMember = (member: ActualMember) => {
    showSpinner();
    api.deleteOrganizationMember(member, viewModel.currentOrganizationId())
      .then(() => {
        setViewModel(viewModel.deleteMember(member));
        successToast('Organization member successfully deleted');
      })
      .catch(() => errorToast('Could not delete organization member'))
      .finally(() => {
        closeDeleteMemberConfirmationModal();
        hideSpinner();
      });
  };

  const updateBrand = (brand: Brand) => {
    onBrandUpdate(brand);
  };

  const closeEditBrandModal = () => setShowEditBrandModal(undefined);

  return (
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className='col mb-3'>
        <OrdoPageSubTitle title="organization name"/>
        <OrdoInput
          inputName="org-name" value={currentOrganizationName} placeholder="company name"
          onChange={internalUpdateOrganizationName} onBlur={updateOrganizationName} inputSize="medium"/>
      </div>

      <div className='col mb-3'>
        <div className='d-flex flex-row justify-content-start align-items-center'>
          <OrdoPageSubTitle title="brands"/>
          <FontAwesomeIcon className='ml-3 add-icon' icon={faPlusCircle} onClick={toggleCreateBrandModal}/>
        </div>
        <div className='d-flex flex-row flex-wrap'>
          {viewModel.currentOrganization.brands?.map((brand: Brand) =>
            <BrandCard
              key={brand.id}
              brand={brand}
              onClick={setShowEditBrandModal}
            />
          )}
        </div>
      </div>

      <div className='col'>
        <div className='d-flex flex-row justify-content-start align-items-center'>
          <OrdoPageSubTitle title="members"/>
          <FontAwesomeIcon className='ml-3 add-icon' icon={faPlusCircle} onClick={toggleInviteUsersModal}/>
        </div>
        <div className='d-flex flex-row flex-wrap'>
          {viewModel.getCurrentOrganizationMembers().map((member: Member) =>
            <MemberCard
              key={member.email}
              member={member}
              onEditRole={toggleEditUserPermissionModal}
              onDeleteInvitation={() => {
                setShowConfirmationModal(true);
                setSelectedMember(member);
              }}
              onDeleteMember={toggleDeleteOrganizationMemberModal}
            />
          )}
        </div>
      </div>

      <CreateBrandModal isOpen={showAddBrandModal} onClose={closeCreateBrandModal} onSubmit={updateOrganization} api={api}
        organization={viewModel.currentOrganization}/>

      <InviteUsersModal isOpen={showInviteUserModal} api={api} onClose={closeInviteUserModal} onSubmit={updateOrganization} organization={viewModel.currentOrganization}/>

      <ConfirmationModal
        show={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        onSubmit={() => {
          deleteInvitation();
          setShowConfirmationModal(false);
        }}
        actionText="delete invitation"
        confirmationText={`Are you sure you want to delete the invitation for ${selectedMember.email}?`}
        errorMessage={<GeneralErrorMessage viewModel={viewModel}/>}
      />

      {showEditBrandModal && <EditBrandModal isOpen={!!showEditBrandModal} onClose={closeEditBrandModal} brand={showEditBrandModal} onBrandUpdate={updateBrand}/>}

      {
        editUserPermissionModalData.member && <EditUserPermissionModal
          isOpen={editUserPermissionModalData.show}
          onClose={closeEditUserPermissionModal}
          onSubmit={updateOrganization}
          member={editUserPermissionModalData.member as ActualMember}
          organization={viewModel.currentOrganization}
        />
      }
      {
        deleteMemberConfirmationModal.show && <DeleteMemberConfirmationModal
          isOpen={deleteMemberConfirmationModal.show}
          onDeleteMember={deleteMember}
          member={deleteMemberConfirmationModal.member as ActualMember}
          onClose={closeDeleteMemberConfirmationModal}
        />
      }
    </OrdoSpinner>
  );
};

export default withRouter(OrganizationTab);
