import React from 'react';
import OrdoLittleButton from '../components/common/OrdoLittleButton';
import '../../scss/ordo/salesActivity/sales-activity-page.scss';
import rankOne from './rankone_icon.png';
import rankTwo from './ranktwo_icon.png';
import rankThree from './rankthree_icon.png';
import {AccountPriority} from './salesActivityTypes';
import {WARNING_COLOR} from '../../constants';
import { NON_ACTIVE_ACCOUNT_TEXT } from '../../models/Account';
import { PipelineButtonText } from './salesActivityFunctions';

type PipelineCardBottomSectionTitleProps = { defaultText: string, redColoredText: string, redColored: boolean }
export const PipelineCardBottomSectionTitle =
  ({defaultText, redColoredText, redColored}: PipelineCardBottomSectionTitleProps) => {
    return <span className={`name text-limit ${redColored ? 'make-text-red' : ''}`}>
      {redColored ? redColoredText : defaultText}
    </span>;
  };


type SalesActivityCardProps = {
  accountName: string,
  priority: AccountPriority,
  subtitle: string,
  bottomSectionTitle: React.ReactNode,
  bottomSectionSubtitle: string,
  buttonText: string,
  onCallToAction: ()=> any,
  onCardClick: () => any,
  onPriorityButtonClick: () => void;
  emMinWidth: number;
  activeAccount: boolean,
  orderEntryEnabled: boolean,
  stale?: boolean,
}

export const SalesActivityCard = ({
  accountName,
  priority,
  subtitle,
  bottomSectionTitle,
  bottomSectionSubtitle,
  buttonText,
  onCallToAction,
  onCardClick,
  onPriorityButtonClick,
  emMinWidth,
  activeAccount,
  orderEntryEnabled,
  stale=false,
}: SalesActivityCardProps) => {

  const srcFromPriority = () => {
    switch (priority) {
    case 1: return rankOne;
    case 2: return rankTwo;
    case 3: return rankThree;
    default: return rankThree;
    }
  };

  const disabledAction = () => {
    return (PipelineButtonText.STAGE_ORDER === buttonText && !orderEntryEnabled) || ([PipelineButtonText.STAGE_ORDER, PipelineButtonText.CONFIRM_ORDER, PipelineButtonText.TRACK_ORDER].includes(buttonText as PipelineButtonText)
      && !activeAccount);
  };

  const borderColor = stale ? {borderColor: `${WARNING_COLOR}`} : {};
  const style = {minWidth: `${emMinWidth}em`, ...borderColor};

  return (
    <div role="presentation"
      className="pipeline-card ordo-shadow ordo-card-border"
      onClick={onCardClick}
      style={style}
    >
      <div className="pipeline-card-upper">
        <div className="name-and-address">
          <span className="name text-limit">{accountName}</span>
          <span className="address text-limit">{subtitle}</span>
        </div>
        <img role='presentation' className="priority-icon" alt={priority.toString()} src={srcFromPriority()} onClick={(event)=> {
          event.stopPropagation();
          onPriorityButtonClick();
        }} onKeyDown={() => {}}/>
      </div>
      <div className="pipeline-card-bottom">
        <div className="text-section">
          {bottomSectionTitle}
          <span className="address text-limit">{bottomSectionSubtitle}</span>
        </div>
        {buttonText && <OrdoLittleButton disabled={disabledAction()}
          text={buttonText}
          category="primary"
          onClick={(event) => {
            event.stopPropagation();
            onCallToAction();
          }}
          dataTestId=""
          disabledText={NON_ACTIVE_ACCOUNT_TEXT}
        />}
      </div>
    </div>
  );
};

