import React, {useState} from 'react';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import Batch from '../../../models/Batch';
import OrdoSpinner from '../OrdoSpinner';
import {useAPI} from '../../../context/OrdoApiContext';
import DeleteProductBatchViewModel from '../../../application-models/DeleteProductBatchViewModel';
import GeneralErrorMessage from '../common/GeneralErrorMessage';
import ConfirmationModal from '../common/ConfirmationModal';
import useOrdoToasts from '../../../hooks/useOrdoToasts';

type DeleteBatchConfirmationModalProps = {
  show: boolean,
  onClose: Function,
  onDeleteConfirmed: Function,
  batch: Batch,
  organizationId: string
}

const DeleteBatchConfirmationModal = ({ show, onClose, batch, onDeleteConfirmed, organizationId }: DeleteBatchConfirmationModalProps) => {
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const api = useAPI();

  const [viewModel, setViewModel] = useState(DeleteProductBatchViewModel.initialize(batch, api));

  const deleteBatch = async () => {
    showSpinner();
    viewModel.deleteBatch(organizationId).then((vm)=> {
      setViewModel(vm);
      if(!vm.hasError()){
        successToast('batch succesfully discontinued');
        onDeleteConfirmed();
        onClose();
      } else {
        errorToast(vm.errorMessage());
      }
    }).finally(hideSpinner);
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <ConfirmationModal
      show={show}
      onClose={onClose}
      onSubmit={deleteBatch}
      actionText="discontinue"
      disableCTA={spinnerVisibility}
      confirmationText={`are you sure you want to discontinue batch ${batch.batchId}?`}
      errorMessage={<GeneralErrorMessage viewModel={viewModel}/>}
    />
  </OrdoSpinner>;
};

export default DeleteBatchConfirmationModal;
