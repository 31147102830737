import {
  faBriefcase,
  faCalendarAlt, faCannabis, faClipboardList, faCoffee,
  faEnvelope,
  faGift, faHamburger,
  faHandshake, faJoint, faMoneyBill,
  faPhoneAlt,
  faStoreAlt, faThumbtack,
  faUsers, IconDefinition
} from '@fortawesome/free-solid-svg-icons';

export const iconsOptions : {[key: string] : IconDefinition} = {
  'users' : faUsers,
  'phone': faPhoneAlt,
  'envelope': faEnvelope,
  'calendar': faCalendarAlt,
  'gift': faGift,
  'shop': faStoreAlt,
  'handshake': faHandshake,
  'food': faHamburger,
  'thumbtack': faThumbtack,
  'coffee': faCoffee,
  'clipboard': faClipboardList,
  'money': faMoneyBill,
  'briefcase': faBriefcase,
  'cannabis': faCannabis,
  'joint': faJoint
};