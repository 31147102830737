import React, {useContext} from 'react';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import ROUTE_NAMES from '../../routes/ROUTE_NAMES';
import {OrderEntryCartContext} from '../../context/OrderEntryCartContext';
import {useAPI} from '../../context/OrdoApiContext';
import { SideBarButtonExpanded } from './sidebar/SideBarButton';
import {UserSessionContext} from '../../context/UserSessionContext';
import { DatadogRum } from '../../lib/metrics/datadog';

const Logout = () => {
  const orderEntryCartContextData = useContext(OrderEntryCartContext);
  const userSessionContextData = useContext(UserSessionContext);
  const api = useAPI();

  const clearStorage = () => {
    orderEntryCartContextData.clearCart();
    userSessionContextData.clearSessionData();
    DatadogRum.init().removeUser();
  };

  async function logout() {
    api.logout().then(() => clearStorage());
  }

  return <SideBarButtonExpanded key={ROUTE_NAMES.LOGIN} route={ROUTE_NAMES.LOGIN} icon={faSignOutAlt} title='logout' onClick={logout}/>;
};
export default Logout;
