import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';
import agreementTerms from './ordoAgreementForm.pdf';
import OrganizationRegistration from '../../../models/organizationRegistration';
import {FormFields, OrganizationFormFields} from '../../../errors/form_error';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import OrdoButton from '../../components/common/OrdoButton';
import '../../../scss/ordo/loginPage/registration.scss';
import {US_STATES} from '../../../lib/constants/US_states';
import OrdoSearchableDropdown from '../../components/common/searchable-dropdown/OrdoSearchableDropdown';
import {OrdoCheckbox} from '../../components/OrdoCheckbox';

interface CompanyRegistration {
  companyRegistration: OrganizationRegistration,
  hasErrorFor: (input: FormFields) => boolean,
  errorMessage: (input: FormFields) => string,
}

type CompanyRegistrationFormProps = {
  registrationViewModel: CompanyRegistration,
  updateCompanyRegistration: (companyRegistration: OrganizationRegistration) => void,
  onCompanyRegistration: (compRegistration: OrganizationRegistration) => void,
  checkEmptyFieldError: (field: FormFields) => void,
  stepBack?: (companyRegistration: OrganizationRegistration) => void,
  onSkipCompanyRegistration?: () => Promise<void>,
  isModal?: boolean
}

const CompanyRegistrationForm = ({registrationViewModel, updateCompanyRegistration, onCompanyRegistration, checkEmptyFieldError, stepBack, onSkipCompanyRegistration, isModal}: CompanyRegistrationFormProps) => {
  function errorMessageForInput(input: OrganizationFormFields) {
    return registrationViewModel.hasErrorFor(input) ? registrationViewModel.errorMessage(input) : '';
  }

  const classNames = isModal ? 'card-body px-0 py-0' : 'card-body px-md-5 py-5';

  const updateRegistrationField = (fieldName: string) => {
    return (newValue: string) => {
      updateCompanyRegistration({[fieldName]: newValue} as unknown as OrganizationRegistration);
    };
  };

  const updateTermsOfUseAccepted = () => {
    updateCompanyRegistration({termsOfUseAccepted: !registrationViewModel.companyRegistration.termsOfUseAccepted} as unknown as OrganizationRegistration);
  };

  return <div className={classNames}>
    {!isModal && <div className="registration-header">
      <h6 className="registration-header__title">Ordo Registration</h6>
      <h6 className="h5">Company and compliance</h6>
      <p>open you company account or skip for now and be added by your company admin</p>
    </div>}
    <span className="clearfix"/>
    <form>

      <div className="input-group">
        <OrdoInput inputName="company-name"
          label="company name"
          value={registrationViewModel.companyRegistration.name}
          placeholder="company name"
          onBlur={() => checkEmptyFieldError('name')}
          onChange={updateRegistrationField('name')}
          invalidMessage={errorMessageForInput('name')}
          inputSize="large"/>
      </div>


      <div className="input-group state-input">
        <span>company size</span>
        <OrdoSearchableDropdown placeholder="company size"
          dataTestId='select-size'
          selectedOption={registrationViewModel.companyRegistration.size ? {value: registrationViewModel.companyRegistration.size, label: registrationViewModel.companyRegistration.size}: undefined}
          options={['1-5', '6-10', '11-20', '21-50','51-100', '101+'].map(size =>( {value: size, label: size}))}
          onChangeSelectedOption={(size: {value: string}) => updateRegistrationField('size')(size.value)}
          singleRowHeight
        />
      </div>


      <div className="input-group state-input">
        <span>state of operation</span>
        <OrdoSearchableDropdown placeholder="state"
          dataTestId='select-state'
          selectedOption={{value: registrationViewModel.companyRegistration.state, label: registrationViewModel.companyRegistration.state}}
          options={US_STATES.map(state =>( {value: state, label: state}))}
          onChangeSelectedOption={(state: {value: string}) => updateRegistrationField('state')(state.value)}
          singleRowHeight
        />
      </div>

      <OrdoCheckbox
        checked={registrationViewModel.companyRegistration.termsOfUseAccepted}
        onChange={()=> {
          updateTermsOfUseAccepted();
        }}
        onMouseLeave={() => checkEmptyFieldError('termsOfUse')}
        invalidMessage={errorMessageForInput('termsOfUse')}
        style={{display: 'flex', justifyContent: 'flex-start', margin: '0.5em 0 1em 1em'}}
        dataTestId='check-terms-of-use'
      >
        <span className='agreement-terms'>I agree to <a href={agreementTerms} target="_blank" rel='noreferrer'>Ordo terms of use & service</a></span>
      </OrdoCheckbox>


      <div className="registration-footer">
        <OrdoButton additionalClassName='registration-button' disabled={false}
          text="continue to subscription selection" category="primary"
          onClick={(event: any) => {
            event.preventDefault();
            onCompanyRegistration(registrationViewModel.companyRegistration);
          }} dataTestId="create-org-button"/>
        <div className="back-and-create-buttons">
          {stepBack && <OrdoButton additionalClassName='registration-button' disabled={false} firstChild={<FontAwesomeIcon icon={faLongArrowAltLeft}/>} text="back" category="cancel" onClick={() => stepBack(registrationViewModel.companyRegistration)} dataTestId="back-to-first-registration-step-button"/>}
          {onSkipCompanyRegistration && <OrdoButton disabled={false} text="skip and signup" category="secondary" onClick={onSkipCompanyRegistration} dataTestId="register-company-skip-button" additionalClassName='registration-button'/>}
        </div>
      </div>
    </form>
  </div>;
};

export default CompanyRegistrationForm;
