import {groupBy, isEmpty } from 'lodash';
import { API } from '../lib/api/ordoApi';
import { RepAssignment } from '../models/Assignment';
import {InMemoryPaginator, Paginator, PaginatorFactory} from '../utils/pagination/InMemoryPaginator';
import Account, { AccountLocation } from '../models/Account';
import { AccountCard } from '../models/AccountCard';
import {ActualMember} from '../models/member/ActualMember';
import {CreateAccountAssignmentRequest} from '../lib/api/request-types';
import { OrdoDate } from '../models/OrdoDate';

export type AccountFilter = {
  retailers: boolean
  distributors: boolean
  nonstorefrontRetailers: boolean
  microbusinesses: boolean
  cultivators: boolean
  manufacturers: boolean
  selectedCounties: string[]
}

const defaultFilter: AccountFilter = {
  retailers: true,
  distributors: true,
  nonstorefrontRetailers: true,
  microbusinesses: true,
  cultivators: false,
  manufacturers: false,
  selectedCounties: []
};

const FIRST_PAGE = 1;


export type DrawerState = {
  shouldPlay: boolean,
  expand: boolean
};

const defaultDrawerState = {shouldPlay: false, expand: false};

export const RETAILER_LICENSE_PREFIX = 'C10-';
export const NONSTOREFRONT_RETAILER_LICENSE_PREFIX = 'C9-';
export const DISTRIBUTORS_LICENSE_PREFIX = 'C11-';
export const MICROBUSINESS_LICENSE_PREFIX = 'C12-';
export const CULTIVATORS_LICENSE_PREFIX = 'CCL';
export const MANUFACTURERS_LICENSE_PREFIX = 'CDPH-';

export default class AccountsViewModel {
  private searchInputByName: string
  private searchInputByZipCode: string[]
  private searchInputBySalesRep: ActualMember[]
  private  filter: AccountFilter
  public accountCards: AccountCard[]
  public readonly api: API
  public readonly fetchError: Error | undefined
  public readonly assignmentModalOpen: boolean;
  public readonly editAssignmentModalOpen: boolean;
  private readonly paginator: Paginator<AccountCard>;
  public drawerState: DrawerState;
  public readonly assignments: RepAssignment[]
  public readonly salesReps: ActualMember[]
  public readonly selectedAssignment: RepAssignment | undefined;
  public selectedParentAccount: AccountCard | undefined;
  public static ACCOUNTS_PER_PAGE: number = 16;
  readonly page: number;
  // This prop will change every time you filter the original retailer list.
  // We store here all the paginated elements, they can be the original retailer list or a subset of it.
  // Maybe a better name would be filteredAccounts
  public allPaginatedAccounts: AccountCard[];
  private readonly state: string;

  public get actualAccountsPage() {
    return this.paginator.getCurrentPageElements();
  }


  constructor(
    api: API,
    page: number,
    paginator: Paginator<AccountCard>,
    searchInputByName: string,
    searchInputByZipCode: string[],
    searchInputBySalesRep: ActualMember[],
    filter: AccountFilter,
    accountCards: AccountCard[], // all accounts
    allPaginatedAccounts: AccountCard[],
    drawerState: DrawerState,
    assignmentModalOpen = false,
    editAssignmentModalOpen = false,
    assignments: RepAssignment[] = [],
    salesReps: ActualMember[] = [],
    selectedAssignment: RepAssignment | undefined = undefined,
    selectedParentAccount: AccountCard | undefined,
    state: string,
    fetchError?: Error) {
    this.api = api;
    this.searchInputByName = searchInputByName;
    this.searchInputByZipCode = searchInputByZipCode;
    this.searchInputBySalesRep = searchInputBySalesRep;
    this.filter = filter;
    this.accountCards = accountCards;
    this.assignmentModalOpen = assignmentModalOpen;
    this.editAssignmentModalOpen = editAssignmentModalOpen;
    this.assignments = assignments;
    this.salesReps = salesReps;
    this.selectedAssignment = selectedAssignment;
    this.page = page;
    this.fetchError = fetchError;
    this.paginator = paginator;
    this.drawerState = drawerState;
    this.allPaginatedAccounts = allPaginatedAccounts;
    this.selectedParentAccount = selectedParentAccount;
    this.state = state;
  }

  public static empty(api: API): AccountsViewModel {
    return new AccountsViewModel(
      api,
      FIRST_PAGE,
      new InMemoryPaginator<AccountCard>([]),
      '',
      [],
      [],
      defaultFilter,
      [],
      [],
      defaultDrawerState,
      false,
      false,
      [],
      undefined,
      undefined,
      undefined,
      ''
    );
  }

  public static async initialize(api: API, orgId: string, page: number, state: string): Promise<AccountsViewModel> {
    try {
      const [org, accounts] = await Promise.all([api.getOrganization(orgId), api.getAllActiveAccountsWithLocations(orgId)]);
      const repAssignments = await api.getAssignments(org, accounts);
      const assignments = new Set<string>();
      repAssignments.forEach((repAssignment) => {
        repAssignment.accounts.forEach((account) => {
          assignments.add(account.id);
          account.addAssignment(repAssignment);
        });
      });
      const accountCards: AccountCard[]  = accounts.map((account: Account) => {
        const visible = !account.hasLicenseStartingWith(MANUFACTURERS_LICENSE_PREFIX.toLowerCase())
          && !account.hasLicenseStartingWith(CULTIVATORS_LICENSE_PREFIX.toLowerCase());
        return new AccountCard({
          id: account.id,
          name: account.name,
          organizationId: account.organizationId,
          locations: account.locations,
          allocations: account.allocations,
          assignments: account.assignments,
          contacts: account.contacts,
          notes: account.notes,
          activities: account.activities,
          status: account.status,
          stalePeriod: account.stalePeriod,
          priority: account.priority,
          value: account.value,
          conversion: account.conversion,
          active: account.active,
          orderEntryEnabled: account.orderEntryEnabled,
        },
        assignments.has(account.id),
        false,
        visible,
        );
      });
      const filteredAccounts: AccountCard[] = accountCards.filter(r => r.visible);
      const paginator = await PaginatorFactory.inMemory(filteredAccounts, page, AccountsViewModel.ACCOUNTS_PER_PAGE);

      return new AccountsViewModel(
        api,
        page,
        paginator,
        '',
        [],
        [],
        defaultFilter,
        accountCards,
        filteredAccounts,
        defaultDrawerState,
        false,
        false,
        repAssignments,
        org.members,
        undefined,
        undefined,
        state
      );

    } catch (_error) {
      // TODO (mk): At some point, it would make sense to have some better error messages
      const err: Error = new Error('Unable to get retail locations and assignments. Please try again later.');
      return new AccountsViewModel(
        api,
        page,
        new InMemoryPaginator([]),
        '',
        [],
        [],
        defaultFilter,
        [],
        [],
        defaultDrawerState,
        false,
        false,
        [],
        [],
        undefined,
        undefined,
        '',
        err,
      );
    }
  }

  public async refresh(api: API, orgId: string, page: number, state: string): Promise<AccountsViewModel> {
    try {
      const [org, accounts] = await Promise.all([api.getOrganization(orgId), api.getAllActiveAccountsWithLocations(orgId)]);
      const repAssignments = await api.getAssignments(org, accounts);
      const assignments = new Set<string>();
      repAssignments.forEach((repAssignment) => {
        repAssignment.accounts.forEach((account) => {
          assignments.add(account.id);
          account.addAssignment(repAssignment);
        });
      });
      const accountCards: AccountCard[]  = accounts.map((account: Account) => {
        return new AccountCard({
          id: account.id,
          name: account.name,
          organizationId: account.organizationId,
          locations: account.locations,
          allocations: account.allocations,
          assignments: account.assignments,
          contacts: account.contacts,
          notes: account.notes,
          activities: account.activities,
          status: account.status,
          stalePeriod: account.stalePeriod,
          priority: account.priority,
          value: account.value,
          conversion: account.conversion,
          active: account.active,
          orderEntryEnabled: account.orderEntryEnabled,
        },
        assignments.has(account.id),
        false,
        false,
        );
      });
      this.accountCards = accountCards;
      this.applySearchAndFilters();
      const filteredAccounts: AccountCard[] = this.accountCards.filter(r => r.visible);
      const paginator = await PaginatorFactory.inMemory(filteredAccounts, page, AccountsViewModel.ACCOUNTS_PER_PAGE);

      return new AccountsViewModel(
        api,
        page,
        paginator,
        this.searchInputByName,
        this.searchInputByZipCode,
        this.searchInputBySalesRep,
        this.filter,
        accountCards,
        filteredAccounts,
        this.drawerState,
        false,
        false,
        repAssignments,
        org.members,
        undefined,
        undefined,
        state
      );

    } catch (_error) {
      // TODO (mk): At some point, it would make sense to have some better error messages
      const err: Error = new Error('Unable to get retail locations and assignments. Please try again later.');
      return new AccountsViewModel(
        api,
        page,
        new InMemoryPaginator([]),
        '',
        [],
        [],
        defaultFilter,
        [],
        [],
        defaultDrawerState,
        false,
        false,
        [],
        [],
        undefined,
        undefined,
        '',
        err,
      );
    }
  }

  public async refreshRepAssignments(orgId: string) {
    return this.refresh(this.api, orgId, this.page, this.state);
  }

  public async withFilter(filter: AccountFilter) {
    this.filter = filter;
    this.applySearchAndFilters();
    const filteredAccounts: AccountCard[] = this.accountCards.filter(r => r.visible);
    const paginator = await PaginatorFactory.inMemory(filteredAccounts, FIRST_PAGE, AccountsViewModel.ACCOUNTS_PER_PAGE);
    return new AccountsViewModel(
      this.api,
      FIRST_PAGE,
      paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      filter,
      this.accountCards,
      filteredAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError);
  }

  public async withSearchInputByName(searchInput: string) {
    this.searchInputByName = searchInput;
    this.applySearchAndFilters();
    const filteredAccounts = this.accountCards.filter(r => r.visible);
    const paginator = await PaginatorFactory.inMemory(filteredAccounts, FIRST_PAGE, AccountsViewModel.ACCOUNTS_PER_PAGE);
    return new AccountsViewModel(
      this.api,
      FIRST_PAGE,
      paginator,
      searchInput,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      this.accountCards,
      filteredAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError);
  }

  public async withSearchInputByZipCode(searchInput: string[]) {
    this.searchInputByZipCode = searchInput;
    this.applySearchAndFilters();
    const filteredAccounts = this.accountCards.filter(r => r.visible);
    const paginator = await PaginatorFactory.inMemory(filteredAccounts, FIRST_PAGE, AccountsViewModel.ACCOUNTS_PER_PAGE);
    return new AccountsViewModel(
      this.api,
      FIRST_PAGE,
      paginator,
      this.searchInputByName,
      searchInput,
      this.searchInputBySalesRep,
      this.filter,
      this.accountCards,
      filteredAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError
    );
  }

  public async withSearchInputBySalesRep(searchInput: ActualMember[]) {
    this.searchInputBySalesRep = searchInput;
    this.applySearchAndFilters();
    const filteredAccounts = this.accountCards.filter(r => r.visible);
    const paginator = await PaginatorFactory.inMemory(filteredAccounts, FIRST_PAGE, AccountsViewModel.ACCOUNTS_PER_PAGE);
    return new AccountsViewModel(
      this.api,
      FIRST_PAGE,
      paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      searchInput,
      this.filter,
      this.accountCards,
      filteredAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError
    );
  }

  public applySearchAndFilters(){
    return this.accountCards.forEach((ac) => {
      ac.setVisible(this.visibilityFilter(ac));
    });
  }

  public withRepAssignmentModalOpen(open: boolean) {
    return new AccountsViewModel(
      this.api,
      this.page,
      this.paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      this.accountCards,
      this.allPaginatedAccounts,
      this.drawerState,
      open,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError);
  }

  public withEditAssignmentsModalOpen(open: boolean) {
    return new AccountsViewModel(
      this.api,
      this.page,
      this.paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      this.accountCards,
      this.allPaginatedAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      open,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError);
  }

  public withAssignment(assignment: RepAssignment) {
    return new AccountsViewModel(
      this.api,
      this.page,
      this.paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      this.accountCards,
      this.allPaginatedAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      assignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError);
  }

  public numRetailersSelected(): number {
    return this.accountCards.filter((accountCard) => accountCard.selected).length;
  }

  public accountsSelected(): AccountCard[] {
    return this.accountCards.filter((accountCard) => accountCard.selected);
  }

  public locationsFromAccountsSelected(member: ActualMember): AccountLocation[] {
    if(member.isSalesRep()) {
      return this.getSelectedAccounts()
        .filter(accountCard => accountCard.assignments.length < 2)
        .flatMap(accountCard => accountCard.getLocations());
    }
    return this.getSelectedAccounts().flatMap(accountCard => accountCard.getLocations());
  }

  public noAccountCardsSelected(): boolean {
    return this.accountCards.filter((accountCard) => accountCard.selected).length === 0;
  }

  /**
   * selectAllVisibleRetailers selects all pages of the filtered sub set of accounts.
   */
  public async selectAllVisibleRetailers() {
    const updatedAllPaginatedRetailers = this.allPaginatedAccounts.map((rc) => {
      let selected = false;
      if(!rc.assigned && rc.visible) {
        selected = true;
      }

      return AccountCard.withSelected(selected, rc);
    });

    const visibleRetailers = updatedAllPaginatedRetailers.map(r => r.id);
    const updatedRetailers = this.accountCards.map((rc) => {
      const updatedAccountCard = rc;
      let selected;
      if (visibleRetailers.includes(rc.id)) {
        selected = !rc.assigned && rc.visible;
      }
      return AccountCard.withSelected(!!selected, updatedAccountCard);
    });

    const paginator = await PaginatorFactory.inMemory(updatedAllPaginatedRetailers, this.page, AccountsViewModel.ACCOUNTS_PER_PAGE);

    const parentAccount = this.selectedParentAccount || updatedRetailers[0];
    return new AccountsViewModel(
      this.api,
      this.page,
      paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      updatedRetailers,
      updatedAllPaginatedRetailers,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      parentAccount,
      this.state,
      this.fetchError);
  }

  public async toggleSelectedAccount(id: string) {
    const updatedAllPaginatedAccounts = this.allPaginatedAccounts.map((rc) => {
      const retCopy = {...rc};
      let {selected} = retCopy;
      if (retCopy.id === id) {
        selected = !selected;
      }
      return AccountCard.withSelected(selected, rc);
    });

    const accountCards: AccountCard[] = this.accountCards.map((r) => {
      let {selected} = r;
      if (r.id === id) {
        selected = !selected;
      }
      return AccountCard.withSelected(selected, r);
    });
    const paginator = await PaginatorFactory.inMemory(updatedAllPaginatedAccounts, this.page, AccountsViewModel.ACCOUNTS_PER_PAGE);

    let parentAccount = this.selectedParentAccount;
    const card = accountCards.find(account => account.id === id);

    if(this.selectedParentAccount) {
      if(card!.id === this.selectedParentAccount.id) {
        parentAccount = accountCards.find(a => a.selected);
      }
    } else {
      parentAccount = accountCards.find(account => account.id === id);
    }

    return new AccountsViewModel(
      this.api,
      this.page,
      paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      accountCards,
      updatedAllPaginatedAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      parentAccount,
      this.state,
      this.fetchError);
  }

  public async clearAllSelectedAccounts() {
    const updatedAllPaginatedAccounts = this.allPaginatedAccounts.map((ac) => {
      return AccountCard.withSelected(false, ac);
    });

    const accounts = this.accountCards.map((r) => {
      return AccountCard.withSelected(false, r);
    });
    const paginator = await PaginatorFactory.inMemory(updatedAllPaginatedAccounts, this.page, AccountsViewModel.ACCOUNTS_PER_PAGE);

    return new AccountsViewModel(
      this.api,
      this.page,
      paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      accounts,
      updatedAllPaginatedAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      undefined,
      this.state,
      this.fetchError);
  }

  public getFilter() {
    return this.filter;
  }

  public getSearchByNameInput() {
    return this.searchInputByName;
  }

  public visibilityFilter(accountCard: AccountCard): boolean {
    if (accountCard.selected) {
      return true;
    }

    const searchAppliesToCard = this.cardIsInSearch(accountCard);
    const isOfSelectedCounty = this.filter.selectedCounties.length > 0 ? accountCard.hasLocationFromCounties(this.filter.selectedCounties) : true;
    return searchAppliesToCard && isOfSelectedCounty && this.applyAccountTypeFilter(accountCard);
  }

  private applyAccountTypeFilter(accountCard: AccountCard) {
    return this.state !== 'CA' || (this.filter.distributors && this.cardIsLicense(accountCard, DISTRIBUTORS_LICENSE_PREFIX))
      || (this.filter.nonstorefrontRetailers && this.cardIsLicense(accountCard, NONSTOREFRONT_RETAILER_LICENSE_PREFIX))
      || (this.filter.retailers && this.cardIsLicense(accountCard, RETAILER_LICENSE_PREFIX))
      || (this.filter.microbusinesses && this.cardIsLicense(accountCard, MICROBUSINESS_LICENSE_PREFIX))
      || (this.filter.manufacturers && this.cardIsLicense(accountCard, MANUFACTURERS_LICENSE_PREFIX))
      || (this.filter.cultivators && this.cardIsLicense(accountCard, CULTIVATORS_LICENSE_PREFIX));
  }

  public getSearchByZipCodeInput() {
    return this.searchInputByZipCode.map(zipCode => ({label: zipCode, value: zipCode}));
  }

  public getSearchBySalesRepInput() {
    return this.searchInputBySalesRep.map(salesRep => {
      const memberName = salesRep.memberName();
      return {label: memberName, value: memberName, data: salesRep};
    });
  }

  public selectableZipCodes() {
    const zipCodeOptions: string[] = [];

    this.accountCards.forEach((ac) => {
      const accountZipCodes: string[] = ac.allAccountZipCodes();
      accountZipCodes.forEach(accountZipCode => {
        if(!zipCodeOptions.includes(accountZipCode)) zipCodeOptions.push(accountZipCode);
      });
    });

    return zipCodeOptions.map(zipCode => ({label: zipCode, value: zipCode}));
  }

  public selectableSalesReps() {
    return this.salesReps.map(salesRep => {
      const memberName = salesRep.memberName();
      return {label: memberName, value: memberName, data: salesRep};
    });
  }

  public amountOfAccounts() {
    return this.allPaginatedAccounts.length;
  }

  public amountOfAssignedAccounts() {
    return this.allPaginatedAccounts.filter(accountCard => accountCard.assigned).length;
  }

  public amountOfNewLicenses() {
    return this.allPaginatedAccounts.reduce((accum, account) => accum + this.newLicensesForAccount(account), 0);
  }

  public amountOfUnassignedAccounts() {
    return this.allPaginatedAccounts.filter(accountCard => !accountCard.assigned).length;
  }

  public totalAmountOfPages() {
    return this.paginator.amountOfPages(AccountsViewModel.ACCOUNTS_PER_PAGE);
  }

  public async updatePage(page: number) {
    await this.paginator.paginate(AccountsViewModel.ACCOUNTS_PER_PAGE, page);

    return new AccountsViewModel(
      this.api,
      page,
      this.paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      this.accountCards,
      this.allPaginatedAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError
    );
  }

  public updateDrawerState(drawerState: DrawerState) : AccountsViewModel{
    return new AccountsViewModel(
      this.api,
      this.page,
      this.paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      this.accountCards,
      this.allPaginatedAccounts,
      drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError);
  }

  public getDrawerState(): DrawerState {
    return this.drawerState;
  }

  public getAmountOfLocationsInCounty(countyName: string): number {
    const locations = this.accountCards.flatMap(account => account.getLocations());
    return locations.filter(location => location.county.name === countyName).length;
  }

  public maxLocationsInCounty(): number {
    const groupedByCounty = groupBy(this.accountCards.flatMap(account => account.getLocations()), (location)=>location.county.name);
    let max = 0;
    Object.keys(groupedByCounty).forEach(county => {
      const locationsInCounty = groupedByCounty[county]?.length || 0;
      max = Math.max(max, locationsInCounty);
    });
    return max;
  }

  public toggleCountyInFilter(countyName: string) {
    const {filter} = this;
    if(filter.selectedCounties.includes(countyName)) {
      filter.selectedCounties = filter.selectedCounties.filter(county => county !== countyName);
    } else {
      filter.selectedCounties.push(countyName);
    }

    return this.withFilter(filter);
  }

  public isFilteringByCounty(countyName: string) {
    return this.filter.selectedCounties.includes(countyName);
  }

  public clearAllCountiesFromFilter() {
    this.filter.selectedCounties = [];
    return this.withFilter(this.filter);
  }

  public canLinkAccounts(member: ActualMember) {
    return this.locationsFromAccountsSelected(member).length > 0;
  }

  public getAllLocations(member: ActualMember): AccountLocation[] {
    if(member.isSalesRep()) {
      return this.accountCards
        .filter(accountCard => isEmpty(accountCard.assignments) || (accountCard.assignments.length === 1 && accountCard.hasAssigned(member)))
        .flatMap(accountCard => accountCard.getLocations());
    }
    return this.accountCards.flatMap(accountCard => accountCard.getLocations());
  }

  public getSelectedAccounts(): Account[] {
    return this.accountCards.filter(account => account.selected);
  }

  public getParentAccountSelected(): AccountCard | undefined {
    return this.selectedParentAccount;
  }

  public async unassignAccountFromUser(member: ActualMember, account: AccountCard, orgId: string) {
    const userAssignments = this.assignments.find(assignment => assignment.member.hasUserId(member.getUserId()));
    const updatedAssignedAccountsIds = userAssignments!.accounts.map(acc => acc.id)
      .filter(accId => accId !== account.id);
    const req: CreateAccountAssignmentRequest = {
      userId: member.getUserId(),
      accountsIds: updatedAssignedAccountsIds,
      disabledBrandIds: [],
    };
    await this.api.replaceAssignments(orgId, req);
  }

  public ableToSelectAccount(member: ActualMember, account: Account) {
    return !member.isSalesRep() || isEmpty(account.assignments) ||
      account.hasAssigned(member);
  }

  public async assignAccountsToSalesRep(memberId: string, orgId: string) {
    const req: CreateAccountAssignmentRequest = {
      userId: memberId,
      accountsIds: this.accountsSelected()
        .map((account) => account.id),
      disabledBrandIds: [],
    };
    await this.api.assignAccounts(orgId, req);
  }

  private newLicensesForAccount(account: Account) {
    const oneMonthAgo = OrdoDate.from(new Date()).subtract(30,'day');
    const newLicenses = account.locations.filter(location =>
      (location.location.licenseNumber.toLowerCase().startsWith(RETAILER_LICENSE_PREFIX.toLowerCase()) ||
      location.location.licenseNumber.toLowerCase().startsWith(NONSTOREFRONT_RETAILER_LICENSE_PREFIX.toLowerCase()) ||
      location.location.licenseNumber.toLowerCase().startsWith(MICROBUSINESS_LICENSE_PREFIX.toLowerCase()))
      && !location.location.issuedAt.beforeThan(oneMonthAgo));
    return newLicenses.length;
  }

  private cardIsLicense(accountCard: AccountCard, licensePrefix: string) {
    return accountCard.hasLicenseStartingWith(licensePrefix.toLowerCase());
  }

  private cardIsInSearch(accountCard: AccountCard): boolean {

    const searchInputByName = this.searchInputByName.toLowerCase();
    const searchedZipCodes = this.searchInputByZipCode;
    const searchedSalesReps = this.searchInputBySalesRep;

    const matchesNameSearch = !searchInputByName || accountCard.name.toLowerCase().includes(searchInputByName) || accountCard.matchesLicenseSearch(searchInputByName);
    const matchesZipCodeSearch = isEmpty(searchedZipCodes) || accountCard.hasLocationWithZipCode(searchedZipCodes);
    const matchesSalesByRepSearch = isEmpty(searchedSalesReps) || searchedSalesReps.some(salesRep => accountCard.hasAssigned(salesRep));
    return matchesNameSearch && matchesZipCodeSearch && matchesSalesByRepSearch;
  }

  public updatedAddress(updatedLocation: AccountLocation) {
    const accountCard = this.accountCards.find(a => a.id === updatedLocation.accountId);
    const location = accountCard!.locations.find(al => al.locationId === updatedLocation.locationId)!;
    location.streetAddressLine1 = updatedLocation.streetAddressLine1;
    location.streetAddressLine2 = updatedLocation.streetAddressLine2;
    location.state = updatedLocation.state;
    location.zipCode = updatedLocation.zipCode;
    location.city = updatedLocation.city;
    location.county = updatedLocation.county;

    return new AccountsViewModel(
      this.api,
      this.page,
      this.paginator,
      this.searchInputByName,
      this.searchInputByZipCode,
      this.searchInputBySalesRep,
      this.filter,
      this.accountCards,
      this.allPaginatedAccounts,
      this.drawerState,
      this.assignmentModalOpen,
      this.editAssignmentModalOpen,
      this.assignments,
      this.salesReps,
      this.selectedAssignment,
      this.selectedParentAccount,
      this.state,
      this.fetchError);
  }
}
