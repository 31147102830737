import React from 'react';
import OrdoModal from '../components/common/OrdoModal';
import OrdoButton from '../components/common/OrdoButton';
import '../../scss/ordo/salesActivity/verify-order-modal.scss';
import {SalesActivityAccount} from './salesActivityTypes';

type WonLostModalProps = {
  isOpen: boolean,
  onClose: () => void,
  onWon: (order: SalesActivityAccount) => Promise<void>,
  onLost: (order: SalesActivityAccount) => Promise<void>,
  account: SalesActivityAccount
}

const WonLostModal = ({isOpen, onClose, onWon, onLost, account}: WonLostModalProps) => {

  return (
    <OrdoModal title='Prospect Retailer'
      show={isOpen}
      size="sm"
      onClose={onClose}
      showFooter={false}>

      <div className="verify-delivery-buttons">
        <div className="verify-order-button">
          <OrdoButton disabled={false}
            text="won"
            category="primary"
            onClick={() => onWon(account)}
            dataTestId="verify-delivery-with-changes-button"/>
        </div>
        <div className="verify-order-button">
          <OrdoButton disabled={false}
            text="lost"
            category="secondary"
            onClick={() => onLost(account)}
            dataTestId="verify-delivery-without-changes-button"/>
        </div>
        <div className="verify-order-button">
          <OrdoButton disabled={false}
            text="cancel"
            category="cancel"
            onClick={onClose}
            dataTestId="cancel-delivery-verification"/>
        </div>
      </div>
    </OrdoModal>
  );
};

export default WonLostModal;
