import { User } from '../models/User';

export default class CookieVerifier {
  public static CHECK_USER_LOGGED_IN_COOKIE = 'sessionDuration';

  public static isValidSession(cookies: string, currentTime: number, currentUser: User): boolean {
    const cookie = this.getCookie(cookies, CookieVerifier.CHECK_USER_LOGGED_IN_COOKIE);
    const isExpired = this.isExpired(cookie, currentTime);
    const isOfUser = this.isOfUser(cookie, currentUser.id);

    return !!cookie && !isExpired && isOfUser;
  }


  private static isExpired(sessionCookie: string, currentTime: number): boolean {
    if (!sessionCookie) {
      return false;
    }
    const {expiresAt} = this.getValueFrom(sessionCookie);
    return currentTime > expiresAt;
  }

  private static isOfUser(sessionCookie: string, userUID: string): boolean {
    if (!sessionCookie) {
      return false;
    }
    const {userId} = this.getValueFrom(sessionCookie);
    return userUID === userId;
  }

  private static getCookie(cookies: string, searchString: string): string {
    return cookies.split('; ').find(cookie => cookie.startsWith(searchString)) || '';
  }

  private static getValueFrom(cookie: string): { expiresAt: number, userId: string } {
    return JSON.parse(cookie.split('=')[1]);
  }
}


