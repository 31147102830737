import React from 'react';
import {AccountHeaderDetails} from './AccountHeaderDetails';
import {SalesActivityAccount} from './salesActivityTypes';
import '../../scss/ordo/salesActivity/account-details.scss';
import {AccountContactsDetails} from './contacts/AccountContactsDetails';
import {AccountNotesDetails} from './notes/AccountNotesDetails';
import {AccountActivitiesDetails} from './activities/AccountActivitiesDetails';
import {AccountDetailsInfo} from '../../application-models/sales-activity/SalesActivityViewModel';

type AccountDetailsProps = {
  account: SalesActivityAccount,
  orgId: string,
  onSubmit: () => any,
  onPriorityChange: () => any
  onStalePeriodChange: () => any,
  showValue: boolean
} & AccountDetailsInfo;

export const AccountDetails = ({account, orgId, onSubmit, onPriorityChange, onStalePeriodChange, contacts, notes, activities, showValue}: AccountDetailsProps) => {


  return(
    <div className='verified-account'>
      <AccountHeaderDetails
        account={account}
        orgId={orgId}
        onPriorityChange={onPriorityChange}
        onStalePeriodChange={onStalePeriodChange}
        showValue={showValue}
      />
      <AccountContactsDetails
        onSubmit={onSubmit}
        orgId={orgId}
        accountId={account.id}
        contacts={contacts}
        activeAccount={account.activeAccount}
      />
      <AccountNotesDetails
        notes={notes}
        orgId={orgId!}
        accountId={account.id}
        accountContacts={contacts}
        activeAccount={account.activeAccount}
      />
      <AccountActivitiesDetails
        activities={activities}
        orgId={orgId!}
        accountId={account.id}
        accountName={account.name}
        activeAccount={account.activeAccount}
      />
    </div>
  );};
