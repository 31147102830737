import {every, uniqBy} from 'lodash';
import {Activity, ActivityItem, ActivityTemplate} from '../../models/sales-activity/Activity';
import {API} from '../../lib/api/ordoApi';
import { ActivityTypeName } from '../../models/sales-activity/ActivityTypeName';
import Contact from '../../models/order-entry/Contact';
import { ActivityCompletionResponse } from '../../models/activity/responses/ActivityCompletionResponse';
import {User} from '../../models/User';
import { AccountWithContactsAndSalesReps } from '../../models/AccountWithContactsAndSalesReps';

export type ActivityInput = {
  type: ActivityTypeName | null,
  description?: string,
  date: Date | null,
  time?: Date,
  contacts: Contact[],
  completed: boolean,
  assignedSalesRep: User | null,
  activityTemplate?: ActivityTemplate | null,
  accountId?: string
  accountIds?: string[],
  responses?: ActivityCompletionResponse[]
};

export default class ActivityViewModel {
  public constructor(
    private readonly api: API,
    public readonly activity: ActivityInput,
    public activityTemplates : ActivityTemplate[],
    public accounts: AccountWithContactsAndSalesReps[],
    public salesReps: User[],
    public selectedAccountWithContactsAndSalesReps: (AccountWithContactsAndSalesReps | undefined),
    public selectedAccounts: AccountWithContactsAndSalesReps[] | undefined
  ) {}

  public static empty(api: API) {
    return new ActivityViewModel(api, {description: '', date: null, type: null, contacts: [], completed: false, activityTemplate: null, assignedSalesRep: null}, [], [], [], undefined, []);
  }

  public static async initialize(api: API, orgId: string, accountId? : string) {
    const activityTemplatesWithCounts = await api.getActivitiesTemplatesForOrg(orgId);
    const activityTemplates: ActivityTemplate[] = activityTemplatesWithCounts.map(activityTemplateWithCount => activityTemplateWithCount.activityTemplate);
    const accountsWithContactsAndSalesReps : AccountWithContactsAndSalesReps[] = await api.getAccountsWithContactsAndSalesReps(orgId);
    const account = accountId ? accountsWithContactsAndSalesReps.find(a => a.accountId === accountId) : undefined;
    const selectedAccounts = account ? [account] : [];
    const selectedAccountsIds = selectedAccounts.map(a => a.accountId);
    const salesReps : User[] = uniqBy(accountsWithContactsAndSalesReps.filter(a => selectedAccountsIds.includes(a.accountId)).flatMap(accountWithContact => accountWithContact.assignments), 'id');
    return new ActivityViewModel(api, {description: '', date: null, type: null, contacts: [], completed: false, activityTemplate: null, assignedSalesRep: null}, activityTemplates, accountsWithContactsAndSalesReps, salesReps,undefined, selectedAccounts);
  }

  public static async initializeForEdit(api: API, orgId: string, activity: Activity, accountId : string) {
    const activityTemplatesWithCounts = await api.getActivitiesTemplatesForOrg(orgId);
    const activityTemplates: ActivityTemplate[] = activityTemplatesWithCounts.map(activityTemplateWithCount => activityTemplateWithCount.activityTemplate);
    const accountWithContactsAndSalesReps : AccountWithContactsAndSalesReps[] = await api.getAccountsWithContactsAndSalesReps(orgId);
    const account = accountWithContactsAndSalesReps.find(a => a.accountId === accountId);
    const salesReps : User[] = account ? account.assignments : [];

    return new ActivityViewModel(api, {accountId: activity.accountId, description: activity.description, date: activity.date, time: activity.time, type: activity.type, contacts: activity.contacts, completed: activity.completed, activityTemplate: activity.activityTemplate, assignedSalesRep: activity.assignedSalesRep, responses: activity.responses}, activityTemplates, accountWithContactsAndSalesReps,salesReps, account, undefined);
  }

  public update(updatedActivityField: Partial<ActivityInput>) {
    return new ActivityViewModel(this.api, {...this.activity, ...updatedActivityField}, this.activityTemplates, this.accounts, this.salesReps, this.selectedAccountWithContactsAndSalesReps, this.selectedAccounts);
  }

  public updateSelectedAccount(selectedAccount: any) {
    const accountId = selectedAccount.value;
    const account = this.accountsWithContactsAndSalesReps().find((a: AccountWithContactsAndSalesReps) => a.accountId === accountId);
    const salesReps = account ? account.assignments : [];
    const updatedAssignedSalesRep = (this.activity.assignedSalesRep && salesReps.map(u => u.id).includes(this.activity.assignedSalesRep?.id)) ? this.activity.assignedSalesRep : null;
    return new ActivityViewModel(this.api, {...this.activity, contacts: [], assignedSalesRep: updatedAssignedSalesRep} , this.activityTemplates, this.accounts, salesReps, account, undefined);
  }

  public addAccount(accountId: string) {
    const account = this.accountsWithContactsAndSalesReps().find((a: AccountWithContactsAndSalesReps) => a.accountId === accountId);
    let {selectedAccounts} = this;
    if(account) {
      selectedAccounts = this.selectedAccounts ? [...this.selectedAccounts, account] : [account];
    }
    const updatedSalesReps = uniqBy(this.salesReps.concat(account ? account.assignments : []), 'id');

    const filteredSalesReps : User[] = this.filterOutSalesRepsForAccounts(updatedSalesReps, selectedAccounts || []);

    const updatedSalesRep = (this.selectedSalesRep && filteredSalesReps.map(u => u.id).includes(this.selectedSalesRep.id)) ? this.selectedSalesRep : null;

    return new ActivityViewModel(this.api, {...this.activity, contacts: [], assignedSalesRep: updatedSalesRep} , this.activityTemplates, this.accounts, filteredSalesReps,undefined, selectedAccounts);
  }

  public removeAccount(accountId: any) {
    const selectedAccounts = this.selectedAccounts && this.selectedAccounts.filter(account => account.accountId !== accountId);
    const updatedSalesReps = selectedAccounts ? uniqBy(selectedAccounts.flatMap(account => account.assignments), 'id') : [];
    const filteredSalesReps = this.filterOutSalesRepsForAccounts(updatedSalesReps, selectedAccounts || []);
    return new ActivityViewModel(this.api, {...this.activity, contacts: [], assignedSalesRep: filteredSalesReps.length > 0 ? this.activity.assignedSalesRep : null} , this.activityTemplates, this.accounts, filteredSalesReps,undefined, selectedAccounts);
  }

  public async createActivity(accountId: string, orgId: string): Promise<Activity> {
    this.sanitizeTimeForActivity();
    this.activity.accountIds = this.selectedAccounts!.map(account => account.accountId);

    return this.api.createActivity(this.activity, orgId, accountId);
  }

  public async createActivityForAccounts(orgId: string): Promise<Activity[]> {
    this.sanitizeTimeForActivity();
    this.activity.accountIds = this.selectedAccounts!.map(account => account.accountId);
    return this.api.createActivitiesForAccounts(this.activity, orgId);
  }

  public async updateActivity(orgId: string, activity: Activity | ActivityItem): Promise<ActivityItem> {
    const activityInput= {description: this.activity.description, date: this.activity.date, time: this.activity.time,
      type: this.activity.type,
      contacts: this.activity.contacts,
      completed: this.activity.completed,
      responses: this.activity.responses,
      activityTemplate: this.activity.activityTemplate,
      assignedSalesRep: this.activity.assignedSalesRep,
      accountId: (this.selectedAccountWithContactsAndSalesReps ? this.selectedAccountWithContactsAndSalesReps.accountId : activity.accountId)
    };
    const updatedActivity = await this.api.updateActivity(activityInput, orgId, activity.accountId, activity.id);
    return {...updatedActivity, accountName: (activity as ActivityItem).accountName || ''};
  }

  public async deleteActivity(orgId: string, accountId: string, activityId: string) {
    await this.api.deleteActivity(orgId, accountId, activityId);
  }

  public get selectedSalesRep() {
    return this.activity.assignedSalesRep;
  };

  public salesRepsForAccount(): User[] {
    return this.salesReps;
  }

  public canSubmit() {
    return (!!this.selectedAccountWithContactsAndSalesReps || (!!this.selectedAccounts && this.selectedAccounts.length > 0)) && !!this.activity.date && !!this.activity.activityTemplate;
  }

  public get date() {
    return this.activity.date;
  }

  public get description() {
    return this.activity.description;
  }

  public get eventType() {
    return this.activity.activityTemplate || null;
  }

  public get contacts() {
    return this.activity.contacts;
  }

  public getActivityTemplateOptions(): ActivityTemplate[] {
    return this.activityTemplates;
  }

  public get completed() {
    return this.activity.completed;
  }

  public accountsWithContactsAndSalesReps() : AccountWithContactsAndSalesReps[]{
    return this.accounts;
  }

  public contactsFromSelectedAccount() {
    const selectedAccount = this.singleSelectedAccount();
    return selectedAccount?.contacts || [];
  }

  selectedAccount() {
    const {selectedAccountWithContactsAndSalesReps} = this;
    return selectedAccountWithContactsAndSalesReps ? {
      label: selectedAccountWithContactsAndSalesReps.name,
      value: selectedAccountWithContactsAndSalesReps.accountId
    } : null;
  }

  static async fromActivityWithAccounts(api: API, activity: Activity, activityTemplates: ActivityTemplate[], accounts: AccountWithContactsAndSalesReps[] ) {
    const selectedAccount = accounts.find(account => account.accountId === activity.accountId);
    const salesReps = selectedAccount ? selectedAccount.assignments : [];
    return new ActivityViewModel(api, activity, activityTemplates, accounts, salesReps,selectedAccount, undefined);  }

  selectedAccountId() {
    const selectedAccount = this.singleSelectedAccount();
    return selectedAccount?.accountId || '';
  }

  timeForSelectedDate(newDate: Date): Date {
    if(this.activity.date) {
      const dateCopy = new Date(this.activity.date);
      dateCopy.setHours(newDate.getHours());
      dateCopy.setMinutes(newDate.getMinutes());
      return dateCopy;
    }
    return newDate;
  }

  sanitizeTimeForActivity() {
    if(this.activity.time) {
      const hours = this.activity.time.getHours();
      const minutes = this.activity.time.getMinutes();
      const correctDateForTime = new Date(this.activity.date!);
      correctDateForTime.setHours(hours);
      correctDateForTime.setMinutes(minutes);

      this.activity.time = correctDateForTime;
    }

  }

  public getTime() {
    return this.activity.time;
  }

  public clearActivity() {
    return new ActivityViewModel(this.api, {description: '', date: null, type: null, contacts: [], completed: false, activityTemplate: null, assignedSalesRep: null}, this.activityTemplates, this.accounts, this.salesReps, this.selectedAccountWithContactsAndSalesReps, this.selectedAccounts);
  }

  public async withTemplatesAndAccount(orgId: string, templates: ActivityTemplate[], account: AccountWithContactsAndSalesReps) : Promise<ActivityViewModel> {
    const accountWithContactsAndSalesReps : AccountWithContactsAndSalesReps[] = await this.api.getAccountsWithContactsAndSalesReps(orgId);
    const selectedAccount = account.accountId ? accountWithContactsAndSalesReps.find(a => a.accountId === account.accountId) : undefined;
    const selectedAccounts = selectedAccount ? [selectedAccount] : [];
    const selectedAccountsIds = selectedAccounts.map(a => a.accountId);
    const salesReps : User[] = uniqBy(accountWithContactsAndSalesReps.filter(a => selectedAccountsIds.includes(a.accountId)).flatMap(accountWithContact => accountWithContact.assignments), 'id');
    return new ActivityViewModel(this.api, this.activity, templates, this.accounts, salesReps, account, selectedAccounts);
  }

  public withTemplatesAndAccountContactsAndSalesReps(templates: ActivityTemplate[], account: AccountWithContactsAndSalesReps, salesReps: User[]) : ActivityViewModel {
    return new ActivityViewModel(this.api, this.activity, templates, this.accounts, salesReps, account, [account]);
  }

  public singleSelectedAccount() {
    const onlySelectedAccount = this.selectedAccounts?.length === 1 ? this.selectedAccounts[0] : null;
    return this.selectedAccountWithContactsAndSalesReps || onlySelectedAccount;  }

  public toActivity(orgId: string, activityId: string): Activity {
    return {...this.activity,
      id: activityId,
      organizationId: orgId,
      accountId: this.activity.accountId || '',
      date: this.activity.date!,
      type: this.activity.type!,
      activityTemplate: this.activity.activityTemplate!,
      assignedSalesRep: this.activity.assignedSalesRep!
    };
  }

  public disabledSalesRepSelection(): boolean {
    return this.accounts.length > 0 && !!this.selectedAccounts && this.selectedAccounts.length === 0 ;
  }

  private filterOutSalesRepsForAccounts(salesReps: User[], selectedAccounts: AccountWithContactsAndSalesReps[]) {
    const filteredSalesReps : User[] = [];

    salesReps.forEach((salesRep: User) => {
      if(!!selectedAccounts && selectedAccounts.length > 0) {
        if(every(selectedAccounts, (selectedAccount) => selectedAccount.assignments.map(u => u.id).includes(salesRep.id))){
          filteredSalesReps.push(salesRep);
        }
      }
    });

    return filteredSalesReps;
  }
}
