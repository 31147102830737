import React, {CSSProperties} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDollarSign} from '@fortawesome/free-solid-svg-icons';
import '../../../../scss/ordo/ordo-number-input.scss';
import OrdoMaskedInput from './OrdoMaskedInput';
import {withDecimalPlaces} from '../../../../utils/string';

export const DollarSignIcon = () => <FontAwesomeIcon size="lg" icon={faDollarSign} className='input-icon'/>;

export type OrdoPriceInputProps = {
  value: number,
  onChange: (newAmount: number) => void,
  onBlur?: Function,
  label?: string,
  max?: number,
  step?: number,
  invalidMessage?: string,
  inputKey?: string,
  style?: CSSProperties,
};

const OrdoPriceInput = ({
  label='',
  value,
  onChange,
  onBlur,
  step=1,
  max,
  invalidMessage='',
  inputKey,
  style={},
}: OrdoPriceInputProps) => {

  const parseNewValueAndChange = (event: any) => {
    const newAmount: number = parseFloat(event.target.value);
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(newAmount)) onChange(withDecimalPlaces(newAmount));
  };

  return <OrdoMaskedInput
    label={label}
    value={value}
    onChange={
      parseNewValueAndChange
    }
    onBlur={onBlur || parseNewValueAndChange}
    customIcon={<DollarSignIcon/>}
    step={step}
    min={0}
    max={max}
    invalidMessage={invalidMessage}
    inputKey={inputKey}
    style={style}
  />;
};

export default OrdoPriceInput;
