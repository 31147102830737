import {useEffect, useState} from 'react';
import {getCurrentResolution, Resolution} from './resolutions';

export function useResolution(): Resolution {
  const [viewPortResolution, setViewPortResolution] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setViewPortResolution(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return getCurrentResolution(viewPortResolution);
}
