import {PaymentMethod, paymentMethodToString} from '../../models/order-entry/Summary';
import {locationAddress} from '../../models/Location';
import Contact from '../../models/order-entry/Contact';
import {
  InformativeSelectableOption,
  toInformativeSelectableOption
} from '../../pages/components/common/searchable-dropdown/InformativeOption';
import {API} from '../../lib/api/ordoApi';
import {SubmitTrackedOrderInput, TrackedOrderInput} from '../../models/order-entry/Order';
import {Money} from '../../models/Money';
import OrderWithCurrentVersion from '../../models/order-entry/OrderWithCurrentVersion';
import {OrdoFile} from '../../models/OrdoFile';
import {validateTermPeriod} from '../../models/order-entry/OrderValidator';
import {AccountLocation} from '../../models/Account';

export class TrackedOrderViewModel {

  public static empty(api: API, accountsLocations: AccountLocation[], existingOrder?: OrderWithCurrentVersion) {
    const trackedOrder = existingOrder ? {
      totalValue: existingOrder.summary.total,
      location: existingOrder.accountLocation,
      contact: existingOrder.contact,
      accountId: existingOrder.accountLocation.accountId,
      deliveryDay: existingOrder.currentVersion.deliveryDay,
      paymentMethod: existingOrder.summary.paymentMethod,
      termPeriod: existingOrder.summary.termPeriod,
      invoice: {preview: existingOrder.currentVersion.invoiceUrl } as OrdoFile
    } : {
      totalValue: Money.FromDollarAmount(0),
      paymentMethod: PaymentMethod.NONE,
    };
    return new TrackedOrderViewModel(trackedOrder, accountsLocations, [], api);
  }

  private constructor(private trackedOrder: Partial<TrackedOrderInput>, private accountsLocations: AccountLocation[], private contacts: Contact[], private api: API ) {
  }

  public update(newData: Partial<TrackedOrderInput>) {
    return new TrackedOrderViewModel({...this.trackedOrder, ...newData}, this.accountsLocations, this.contacts, this.api);
  }

  public async changeSelectedLocation(orgId:string, newLocation: AccountLocation) {
    return this.api.getAccountContacts(orgId, newLocation.accountId).then(contacts => {
      this.contacts = contacts;
      const contact = newLocation.location.id !== this.trackedOrder.location?.location.id ? undefined : this.trackedOrder.contact;
      return this.update({location: newLocation, contact: contact, accountId: newLocation.accountId});
    });
  }

  public validInvoice() {
    return !!this.trackedOrder.invoice;
  }

  public validTotal() {
    const value = this.trackedOrder.totalValue;
    return !!value && (this.totalValue >= 0);
  }

  public hasCompletePaymentMethod() {
    return (this.trackedOrder.paymentMethod === PaymentMethod.CASH_ON_DELIVERY ) ||
      (this.trackedOrder.paymentMethod === PaymentMethod.NET_TERMS && !!this.trackedOrder.termPeriod && !validateTermPeriod(this.trackedOrder.termPeriod));
  }

  public getSelectedLocation(): InformativeSelectableOption<AccountLocation> | null {
    const selectedLocation = this.trackedOrder.location;
    return selectedLocation ?
      toInformativeSelectableOption(
        selectedLocation!.account?.name || 'N/A',
        selectedLocation!.location.licenseNumber,
        locationAddress(selectedLocation!),
        selectedLocation)
      : null;
  }

  public getSelectableLocations(): InformativeSelectableOption<AccountLocation>[] {
    return this.accountsLocations.filter(accountLocation => !!accountLocation.account).map(accountLocation => toInformativeSelectableOption(accountLocation.account?.name || 'N/A', accountLocation.location.licenseNumber, locationAddress(accountLocation), accountLocation));
  }

  public getSelectedContact(): InformativeSelectableOption<Contact> | null {
    const selectedContact = this.trackedOrder.contact;
    return selectedContact ?
      toInformativeSelectableOption(
        selectedContact!.name,
        '',
        selectedContact!.email,
        selectedContact)
      : null;
  }

  public getSelectableContacts(): InformativeSelectableOption<Contact>[] {
    return this.contacts.map(contact => toInformativeSelectableOption(contact.name, '', contact.email, contact));
  }

  public get selectedDeliveryDate() {
    return this.trackedOrder.deliveryDay;
  }

  public getSelectedPaymentMethod() {
    return paymentMethodToString(this.trackedOrder.paymentMethod!);
  }

  public getSelectablePaymentMethods() {
    return [paymentMethodToString(PaymentMethod.CASH_ON_DELIVERY), paymentMethodToString(PaymentMethod.NET_TERMS)];
  }

  public invoiceUrl() {
    return this.trackedOrder.invoice?.preview || '';
  }

  public needsTermPeriod() {
    return this.trackedOrder.paymentMethod === PaymentMethod.NET_TERMS;
  }

  public get paymentTermPeriod() {
    return this.trackedOrder.termPeriod || '';
  }

  public get totalValue() {
    return (this.trackedOrder.totalValue?.toUnit() || 0);
  }

  public canSubmit() {
    const {trackedOrder} = this;
    return !!trackedOrder.location &&
      !!trackedOrder.deliveryDay && !!trackedOrder.contact &&
      this.validTotal() && this.hasCompletePaymentMethod() ;
  }

  public disableReason() {
    const {trackedOrder} = this;

    if(!trackedOrder.location) return 'No location selected';
    if(!trackedOrder.deliveryDay) return 'No delivery date selected';
    if(!trackedOrder.contact) return 'No contact selected';
    if(!this.validTotal()) return 'Total value is invalid';
    if(!this.hasCompletePaymentMethod()) return 'No payment method specified';
    return '';
  }

  public submitOrder(organizationId: string, existingOrder?: OrderWithCurrentVersion) {
    if(!this.canSubmit()) throw Error('Can not submit order, fields are missing');
    if(existingOrder) {
      return this.editOrder(organizationId, existingOrder.id);
    }
    return this.createOrder(organizationId);
  }

  public createOrder(organizationId: string) {
    const invoice = this.trackedOrder.invoice? this.trackedOrder.invoice!.raw! : undefined;
    return this.api.submitTrackedOrder(organizationId, this.orderData(), invoice);
  }

  public editOrder(organizationId: string, orderId: string) {
    const invoice = this.trackedOrder.invoice? this.trackedOrder.invoice!.raw! : undefined;
    return this.api.editTrackedOrder(organizationId, this.orderData(), orderId, invoice);
  }

  public invoicePreview() {
    return this.trackedOrder.invoice?.preview;
  }

  private orderData(): SubmitTrackedOrderInput {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {contact, location, totalValue, invoice, ...rest} = this.trackedOrder as TrackedOrderInput;
    return {
      ...rest,
      locationId: location.location.id,
      contactId: contact.id,
      trackedTotal: totalValue,
      termPeriod: rest.paymentMethod === PaymentMethod.NET_TERMS ? rest.termPeriod : undefined
    };
  }
}
