import {SUBSCRIPTION_PLANS, SubscriptionPlan} from '../pages/registration/steps/SubscriptionPlan';



export default class SubscriptionRegistration {
  public selectedPlan: SubscriptionPlan = SUBSCRIPTION_PLANS.none;
  public paymentToken?: string;

  static empty() {
    return new SubscriptionRegistration(SUBSCRIPTION_PLANS.none);
  }

  constructor(selectedPlan: SubscriptionPlan, paymentToken: string = '') {
    this.selectedPlan = selectedPlan;
    this.paymentToken = paymentToken;
  }

  public isValid(): boolean {
    return !!this.paymentToken;
  }

  public disabledReason(): string {
    return !this.paymentToken ? 'No credit card selected' : '';
  }
}
