import {isEmpty} from 'lodash';
import {API} from '../../lib/api/ordoApi';
import {ProductWithAvailability} from '../../models/productWithAvailability';
import {Cart, emptyDiscount, ProductLine} from '../../models/order-entry/Cart';
import {OrganizationLicense, organizationLicenseAddress} from '../../models/OrganizationLicense';
import Contact from '../../models/order-entry/Contact';
import {defaultOrderEntryCartContextData, OrderEntryCartContextData,} from '../../context/OrderEntryCartContext';
import {DiscountType, OrderInput, OrderStatus} from '../../models/order-entry/Order';
import {OrderError} from '../../errors/order_error';
import {BusinessOrderError} from '../../errors/domain_order_errors';
import {locationAddress} from '../../models/Location';
import {
  InformativeSelectableOption,
  toInformativeSelectableOption
} from '../../pages/components/common/searchable-dropdown/InformativeOption';
import {CartItem, CartItemType} from '../../models/order-entry/CartItem';
import {Money} from '../../models/Money';
import {OrderSummary, PaymentMethod, paymentMethodToString} from '../../models/order-entry/Summary';
import {ItemLine} from '../../models/order-entry/ItemLine';
import {OrdoDate} from '../../models/OrdoDate';
import {Edit} from './order-entry-mode/edit';
import {Create} from './order-entry-mode/create';
import {OrderEntryMode, OrderPrefilledMode} from './order-entry-mode';
import {AccountLocation} from '../../models/Account';
import {AccountStatus} from '../../pages/sales-activity/salesActivityTypes';
import {DISTRIBUTORS_LICENSE_PREFIX, MICROBUSINESS_LICENSE_PREFIX} from '../AccountsPageViewModel';
import {User} from '../../models/User';
import {Organization} from '../../models/Organization';
import {Duplicate} from './order-entry-mode/duplicate';

export enum OrderEntryStep {
  ChooseAccountAndDistributor = 0,
  GenerateOrder = 1,
  DiscountsAndPricing = 2,
  VerifyOrder = 3,
}

export class OrderEntryViewModel {
  public static empty(api: API) {
    const defaultMode = new Create();
    return new OrderEntryViewModel(api,
      [],
      [],
      [],
      [],
      new Cart([],
        emptyDiscount()),
      OrderEntryStep.GenerateOrder,
      '',
      defaultOrderEntryCartContextData,
      defaultMode,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,);
  }

  public static async initialize(
    api: API,
    org: Organization,
    orderEntryCartContextData: OrderEntryCartContextData,
    accountLocation?: AccountLocation,
    orderId?: string,
    orderStatus?: OrderStatus,
    accountId?: string,
    orderPrefillMode?: OrderPrefilledMode
  ): Promise<OrderEntryViewModel> {
    const orgId = org.id;
    const selectedAccLocation = accountLocation || orderEntryCartContextData.storedCart.accountLocation;
    const mode: OrderEntryMode = await this.initializeMode(orgId, api, orderId, orderStatus, orderPrefillMode);

    const distributors = await api.getDistributorAndMicrobusinessLicenses(orgId);
    const preSelectedDistributor = mode.selectedDistributor() || (distributors
      .sort((firstLic,secLic) => firstLic.createdAt.getTime() - secLic.createdAt.getTime()))[0];

    const distributorOrganizationLicenseId = preSelectedDistributor.id;
    const [accountLocations, products] = await this.fetchAccountLocationsAndProducts(api, orgId, distributorOrganizationLicenseId, mode, selectedAccLocation);
    const {selectedAccountLocation, contacts} = await mode.initializeAccountWithContacts(
      selectedAccLocation,
      accountLocations,
      api,
      org.id,
      accountId,
    );
    const cart = mode.initializeCart(orderEntryCartContextData.storedCart, selectedAccountLocation, products);
    const step = mode.isEdit() ? OrderEntryStep.DiscountsAndPricing : OrderEntryStep.ChooseAccountAndDistributor;

    return new OrderEntryViewModel(
      api,
      products,
      accountLocations,
      contacts,
      distributors,
      cart,
      step,
      mode.orderNotes(),
      orderEntryCartContextData,
      mode,
      mode.distributorTaxesEnabled(),
      mode.orderSummary(),
      selectedAccountLocation,
      mode.contact(),
      preSelectedDistributor || org.licenses[0],
      mode.selectedDeliveryDay(),
      mode.selectedPaymentMethod(),
      mode.termPeriod(),
      undefined,
      undefined,
      accountId,
      mode.assignedSalesRep());
  }

  private static async fetchAccountLocationsAndProducts(api: API, orgId: string, distributorOrganizationLicenseId: string, mode: OrderEntryMode, accountLocation?: AccountLocation) {
    return Promise.all([
      api.getAccountsLocationsForUser(orgId),
      mode.availability(api, orgId, distributorOrganizationLicenseId, accountLocation ? accountLocation.accountId : '')
    ]);
  }

  private static async initializeMode(orgId: string, api: API, orderId: string = '', status?: OrderStatus, orderPrefillMode?: OrderPrefilledMode) {
    if (orderId) {
      const order = await api.getOrder(orgId, orderId);
      if(orderPrefillMode === OrderPrefilledMode.duplicate) {
        return new Duplicate(order);
      }
      return new Edit(order, status);
    }
    return new Create();
  }

  private constructor(
    public api: API,
    public products: ProductWithAvailability[],
    public accountLocations: AccountLocation[],
    public contacts: Contact[],
    public distributors: OrganizationLicense[],
    public cart: Cart,
    public currentStep: OrderEntryStep,
    public deliveryDetailsNotes: string,
    private readonly orderEntryCartContextData: OrderEntryCartContextData,
    private readonly mode: OrderEntryMode,
    public distributorTaxesEnabled: boolean,
    private readonly orderSummary?: OrderSummary,
    public selectedAccountLocation?: AccountLocation,
    public selectedContact?: Contact,
    public selectedDistributor?: OrganizationLicense,
    public selectedDeliveryDay?: Date,
    public selectedPaymentMethod?: PaymentMethod,
    public termPeriod?: number,
    private readonly errors: OrderError = OrderError.withoutError(),
    public readonly summaryError?: Error,
    public accountId?: string,
    public assignedSalesRep?: User) {
  }


  public async initializeCheckoutPage(id: string): Promise<OrderEntryViewModel> {
    const distributors = await this.api.getDistributorAndMicrobusinessLicenses(id);
    const preSelectedContact = isEmpty(this.contacts) ? undefined : this.contacts[0];
    const preSelectedDistributor = (distributors
      .sort((firstLic,secLic) => firstLic.createdAt.getTime() - secLic.createdAt.getTime()))[0];
    return new OrderEntryViewModel(
      this.api,
      this.products,
      this.accountLocations,
      this.contacts,
      distributors,
      this.cart,
      this.currentStep,
      this.deliveryDetailsNotes,
      this.orderEntryCartContextData,
      this.mode,
      this.distributorTaxesEnabled,
      this.orderSummary,
      this.selectedAccountLocation,
      this.selectedContact ? this.selectedContact : preSelectedContact,
      this.selectedDistributor ? this.selectedDistributor : preSelectedDistributor,
      this.selectedDeliveryDay,
      this.selectedPaymentMethod,
      this.termPeriod,
      undefined,
      undefined,
      this.accountId,
      this.assignedSalesRep
    );
  }

  public updateCart(product: ProductWithAvailability, quantity: number, type: CartItemType) {
    this.cart.updateItems(product, quantity, type);
    this.saveCart();
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
  }

  public amountOfProductFor(productId: string, type: CartItemType) {
    return this.cart.amountOfItemTypeForProduct(productId, type);
  }

  public get cartItems() {
    return this.cart.items;
  }

  public cartCaseItems(): ProductLine[] {
    return this.cart.caseItems();
  }

  public subtotal() {
    if (this.orderSummary) {
      return this.orderSummary.subtotal.formatted();
    }
    return this.cart.calculatedSubtotal().formatted();
  }

  public grandTotal() {
    return (this.orderSummary ? this.orderSummary.grandTotalWithTaxesCriteria() : this.cart.calculatedSubtotal()).formatted();
  }

  public discountTotal() {
    if (this.orderSummary) {
      return this.orderSummary.discountTotal.formatted();
    }
    return new Money(0).formatted();
  }

  public exciseTax() {
    if (this.orderSummary) {
      return this.orderSummary.exciseTax;
    }
    return new Money(0);
  }

  public salesTax() {
    if (this.orderSummary) {
      return this.orderSummary.salesTax;
    }
    return new Money(0);
  }

  public totalTax() {
    return (this.orderSummary ? this.orderSummary.totalTax() : new Money(0)).formatted();
  }

  public cartUnitItems(): ProductLine[] {
    return this.cart.unitItems();
  }

  public async updateItemQuantity(orgId: string, itemLine: ItemLine, value: number) {
    itemLine.setQuantity(value);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public async updateItemPrice(orgId: string, itemLine: ItemLine, newPrice: number) {
    itemLine.setPrice(newPrice);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public async updateItemDiscount(orgId: string, itemLine: ItemLine, newDiscount: number) {
    itemLine.setDiscount(newDiscount);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public async updateItemDiscountType(orgId: string, itemLine: ItemLine, newDiscountType: DiscountType) {
    itemLine.setDiscountType(newDiscountType);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public async updateItemNotes(_orgId: string, itemLine: ItemLine, notes: string) {
    itemLine.setNotes(notes);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return Promise.resolve(updatedVm);
  }

  public async updateWholeOrderDiscount(orgId: string, newDiscount: number) {
    this.cart.setDiscount(newDiscount);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public async updateWholeOrderDiscountType(orgId: string, newDiscount: DiscountType) {
    this.cart.setDiscountType(newDiscount);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public async updateDistributor(orgId: string, distributor: OrganizationLicense): Promise<OrderEntryViewModel> {
    const productsWithAvailability = await this.mode.availability(this.api, orgId, distributor.id, this.accountId || '');
    this.cart.availabilityChanged(productsWithAvailability);

    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api,
      productsWithAvailability,
      this.accountLocations,
      this.contacts,
      this.distributors,
      this.cart,
      this.currentStep,
      this.deliveryDetailsNotes,
      this.orderEntryCartContextData,
      this.mode,
      this.distributorTaxesEnabled,
      this.orderSummary,
      this.selectedAccountLocation,
      this.selectedContact,
      distributor,
      this.selectedDeliveryDay,
      this.selectedPaymentMethod,
      this.termPeriod,
      this.errors,
      this.summaryError,
      this.accountId,
      this.assignedSalesRep);

    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public updateContacts(newContacts: Contact[]) {
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, newContacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, undefined, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
  };

  public updateSelectedContact(selectedContact: Contact) {
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
  };

  public updateOrderNotes(orderNotes: string) {
    if (orderNotes.length <= 250) {
      return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, orderNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    }
    return this;
  }

  public async updateSelectedAccountLocation(orgId: string, selectedAccountLocation: AccountLocation) {
    const {accountId} = selectedAccountLocation;
    const products = await this.mode.availability(this.api, orgId, this.getSelectedDistributor()!.value.id, accountId);
    const contacts = await this.api.getAccountContacts(orgId, selectedAccountLocation.accountId);
    this.cart.accountLocationChanged(selectedAccountLocation, products);
    this.saveCart();
    return new OrderEntryViewModel(this.api, products, this.accountLocations, contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, selectedAccountLocation, undefined, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, accountId, this.assignedSalesRep);
  }

  public getSelectedContact(): InformativeSelectableOption<Contact> | null {
    return this.selectedContact ?
      toInformativeSelectableOption(
        this.selectedContact!.name,
        '',
        this.selectedContact!.email,
        this.selectedContact)
      : null;
  }

  public getSelectableContacts(): InformativeSelectableOption<Contact>[] {
    return this.contacts.map(contact => toInformativeSelectableOption(contact.name, '', contact.email, contact));
  }

  public getSelectedDistributor(): InformativeSelectableOption<OrganizationLicense> | null {
    return this.selectedDistributor ?
      toInformativeSelectableOption(
        this.selectedDistributor!.license.name,
        this.selectedDistributor!.license.licenseNumber,
        this.selectedDistributor!.streetAddressLine1 ? organizationLicenseAddress(this.selectedDistributor!) : '',
        this.selectedDistributor)
      : null;
  }

  public getSelectableDistributors(): InformativeSelectableOption<OrganizationLicense>[] {
    return this.distributors
      .filter(license => license.id !== this.selectedDistributor?.id)
      .map(distributor => toInformativeSelectableOption(
        distributor.license.name,
        distributor.license.licenseNumber,
        distributor.streetAddressLine1 ? organizationLicenseAddress(distributor) : '',
        distributor)
      );
  }

  public updateDeliveryDay(date: OrdoDate) {
    const selectedDeliveryDay = date.toDate();
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
  }

  // TODO: (OR-817) display the selected option and the options according to ticket.
  public getSelectedAccountLocation(): InformativeSelectableOption<AccountLocation> | null {
    if (this.selectedAccountLocation) {
      return toInformativeSelectableOption(
      this.selectedAccountLocation!.account!.name,
      this.selectedAccountLocation!.location.licenseNumber,
      locationAddress(this.selectedAccountLocation),
      this.selectedAccountLocation);
    }

    if(this.accountId) {
      this.selectedAccountLocation = this.accountLocations.find(accountLocation => accountLocation.accountId === this.accountId);
      return toInformativeSelectableOption(
        this.selectedAccountLocation!.account!.name,
        this.selectedAccountLocation!.location.licenseNumber,
        locationAddress(this.selectedAccountLocation!),
        this.selectedAccountLocation!);
    }

    return null;
  }

  public getSelectableAccountLocations(): InformativeSelectableOption<AccountLocation>[] {
    return this.accountLocations
      .filter(accountLocation => accountLocation.accountId !== this.selectedAccountLocation?.accountId)
      .map(accountLocation => toInformativeSelectableOption(accountLocation.account!.name, accountLocation.location.licenseNumber, locationAddress(accountLocation), accountLocation, !accountLocation.account!.orderEntryEnabled, 'order entry on hold for this account'));
  }

  public getOrderInput(): OrderInput {
    return {
      lineItems: this.cart.generateLineItems(),
      discountType: this.cart.getDiscountType(),
      discountPercentage: this.cart.getDiscountPercentage(),
      discountCurrencyAmount: this.cart.getDiscountCurrencyAmount(),
      deliveryDay: this.selectedDeliveryDay!,
      orderNotes: this.deliveryDetailsNotes,
      locationId: this.selectedAccountLocation!.location.id,
      accountId: this.selectedAccountLocation!.accountId,
      contactId: this.selectedContact!.id,
      distributorExternalLicenseId: this.selectedDistributor!.license.licenseNumber,
      paymentMethod: this.selectedPaymentMethod!,
      termPeriod: this.termPeriod,
      distributorTaxesEnabled: this.distributorTaxesEnabled,
      assignedSalesRepId: this.assignedSalesRep?.id
    };
  }

  public async fetchContacts(orgId: string,) {
    const contacts = await this.api.getAccountContacts(orgId, this.selectedAccountLocation!.accountId);
    if (contacts.length === 1) {
      [this.selectedContact] = contacts;
    }
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
  }

  public async submitOrder(orgId: string) {
    const order = this.getOrderInput();
    try {
      await this.mode.submitOrder(this.api, orgId, order);
      await this.api.updateAccountStatus(orgId, order.accountId, AccountStatus.VERIFIED);
      this.orderEntryCartContextData.clearCart();
      // We need to preserve the OrderEntryMode and we will be redirecting the user anyways, so no need to instantiate a new OrderEntryViewModel
      return this;
    } catch (error) {
      const errorData = (error instanceof BusinessOrderError) ? error.orderError : OrderError.unknown(error.message);
      return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, errorData, this.summaryError, this.accountId, this.assignedSalesRep);
    }
  }

  public async addLine(orgId: string, item: CartItem) {
    this.cart.newLine(item);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public async removeLine(orgId: string, item: CartItem, line: number) {
    item.removeLine(line);
    this.saveCart();
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public updateCurrentPaymentMethod(paymentMethod: string) {
    const paymentMethodFromString = paymentMethod === 'net terms' ? PaymentMethod.NET_TERMS : PaymentMethod.CASH_ON_DELIVERY;
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, paymentMethodFromString, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
  }

  public getSelectedPaymentMethod() {
    return this.selectedPaymentMethod ? paymentMethodToString(this.selectedPaymentMethod) : null;
  }

  public getSelectablePaymentMethods() {
    return [paymentMethodToString(PaymentMethod.CASH_ON_DELIVERY), paymentMethodToString(PaymentMethod.NET_TERMS)];
  }

  public updateTermPeriod(termPeriod: number) {
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
  }

  public total() {
    if (this.orderSummary) {
      return this.orderSummary.total.formatted();
    }
    return this.cart.totalWithoutDiscount().formatted();
  }

  public submitOrderCTAText(): string {
    return this.mode.submitOrderCTAText();
  }

  public getTermPeriod() {
    return this.termPeriod || '';
  }

  public getSuccessMessage(): string {
    return this.mode.getSuccessMessage();
  }

  public hasErrorFor(productId: string) {
    return this.errors.hasErrorForProduct(productId);
  }

  public hasError() {
    return this.errors.hasError();
  }

  public errorMessage(): string {
    return this.errors.errorMessage(this.cartItems);
  }

  public goToGenerateOrder() {
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, OrderEntryStep.GenerateOrder, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
  }

  public async gotToVerifyOrder(orgId: string) {
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, OrderEntryStep.VerifyOrder, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public nextRoute(): string {
    return this.mode.nextRoute();
  }

  public nextMobileRoute(): string {
    return this.mode.nextMobileRoute();
  }

  public updateDistributorTaxesEnabled(orgId: string) {
    const updatedVm = new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, !this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, this.assignedSalesRep);
    return updatedVm.fetchNewOrderSummary(orgId);
  }

  public canEnableDistributorTaxes() {
    const licenseNumber = this.selectedAccountLocation? this.selectedAccountLocation.location.licenseNumber : undefined;
    return this.selectedAccountLocation &&
      (licenseNumber?.toLocaleLowerCase().startsWith(DISTRIBUTORS_LICENSE_PREFIX.toLowerCase()) || licenseNumber?.toLocaleLowerCase().startsWith(MICROBUSINESS_LICENSE_PREFIX.toLowerCase()));
  }

  public canCompleteOrder() {
    return this.cart.canCompleteOrder() && !!this.selectedAccountLocation && this.cart.hasNoDeletedProducts();
  }

  public validOrderSummary() {
    if (this.orderSummary !== undefined) {
      return this.orderSummary.total.isGreaterOrEqualThan(this.orderSummary.discountTotal);
    }
    return true;
  }

  public canGoToMobileSummary() {
    return !!this.selectedDeliveryDay && !!this.selectedAccountLocation
      && !!this.selectedContact && this.cart.canCompleteOrder();
  }

  public canSubmitOrder() {
    return this.cart.canCompleteOrder() &&
      this.cart.hasValidDiscounts() &&
      this.validOrderSummary() &&
      this.cart.noLinesLeftAtZero() &&
      !!this.selectedAccountLocation &&
      !!this.selectedDistributor &&
      !!this.selectedContact &&
      !!this.selectedDeliveryDay &&
      !!this.selectedPaymentMethod &&
      (this.selectedPaymentMethod === PaymentMethod.CASH_ON_DELIVERY || (!!this.termPeriod && this.termPeriod > 0));
  }

  public completeOrderInvalidText() {
    if (!this.selectedAccountLocation) {
      return 'No location selected';
    }
    if (this.cart.amountOfItems() <= 0) {
      return 'Cart is empty';
    }
    if (!this.cart.validOrderQuantity()) {
      return 'Stock exceeded. Please remove some items';
    }
    return '';
  }

  public submitOrderInvalidText() {
    if (!this.selectedAccountLocation) {
      return 'No location selected';
    }
    if (!this.selectedDistributor) {
      return 'No distributor selected';
    }
    if (!this.selectedContact) {
      return 'No contact selected';
    }
    if (!this.selectedDeliveryDay) {
      return 'No delivery date selected';
    }
    if (!this.selectedPaymentMethod) {
      return 'No payment method selected';
    }
    if (this.selectedPaymentMethod === PaymentMethod.NET_TERMS && (!this.termPeriod || this.termPeriod <= 0)) {
      return 'Invalid term period';
    }
    if (this.cart.amountOfItems() <= 0) {
      return 'The order does not have items';
    }
    if (!this.cart.noLinesLeftAtZero()) {
      return 'Some product lines don\'t have any quantity, please remove them';
    }
    if (!this.cart.validOrderQuantity()) {
      return 'Stock exceeded. Please remove some items';
    }
    if (!this.validOrderSummary()) {
      return 'Total must be greater than discounts';
    }
    return '';
  }

  public goToMobileSummaryInvalidText() {
    if (!this.selectedAccountLocation) {
      return 'No location selected';
    }
    if (!this.selectedContact) {
      return 'No contact selected';
    }
    if (!this.selectedDeliveryDay) {
      return 'No delivery date selected';
    }
    if (this.cart.amountOfItems() <= 0) {
      return 'The order does not have items';
    }
    if (!this.cart.noLinesLeftAtZero()) {
      return 'Some product lines don\'t have any quantity, please remove them';
    }
    if (!this.cart.validOrderQuantity()) {
      return 'Stock exceeded. Please remove some items';
    }
    return '';
  }

  public inventoryIsEnoughForProduct(product: ProductWithAvailability) {
    const productQuantityFromCaseItems = this.cart.allCaseLinesFromProduct(product).reduce((accumulatedQuantity, line) => accumulatedQuantity + (line.quantity * product.unitsPerCase) , 0);
    const productQuantityFromUnitItems = this.cart.allUnitLinesFromProduct(product).reduce((accumulatedQuantity, line) => accumulatedQuantity + line.quantity , 0);
    return product.availability >= productQuantityFromCaseItems + productQuantityFromUnitItems;
  }

  private saveCart() {
    this.mode.saveCart(this.cart, this.orderEntryCartContextData);
  }

  private async fetchNewOrderSummary(orgId: string): Promise<OrderEntryViewModel> {
    try {
      const summaryResp = await this.api.getOrderSummary(orgId, {
        deliveryDate: this.selectedDeliveryDay || new Date(),
        lineItems: this.cart.generateLineItems(),
        distributorExternalLicenseId: this.selectedDistributor?.license.licenseNumber,
        discountType: this.cart.getDiscountType(),
        discountCurrencyAmount: this.cart.getDiscountCurrencyAmount().toJSON(),
        discountPercentage: this.cart.getDiscountPercentage(),
        distributorTaxesEnabled: this.distributorTaxesEnabled,
      });
      this.cart.updateLineItemTax(summaryResp.lineItemsWithTaxes);
      // explicitly clear the current summary error, if there is one
      return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, summaryResp.summary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, undefined, this.accountId, this.assignedSalesRep);
    } catch (err) {
      return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, err, this.accountId, this.assignedSalesRep);
    }
  }

  public getSelectedSalesRep() {
    return this.assignedSalesRep || null;
  }

  public updateAssignedSalesRep(salesRep: User) {
    return new OrderEntryViewModel(this.api, this.products, this.accountLocations, this.contacts, this.distributors, this.cart, this.currentStep, this.deliveryDetailsNotes, this.orderEntryCartContextData, this.mode, this.distributorTaxesEnabled, this.orderSummary, this.selectedAccountLocation, this.selectedContact, this.selectedDistributor, this.selectedDeliveryDay, this.selectedPaymentMethod, this.termPeriod, this.errors, this.summaryError, this.accountId, salesRep);
  }

  public hasMoreThanOneDistributor() : boolean {
    return this.distributors.length > 1;
  }

  public hasDistributors() : boolean {
    return this.distributors.length > 0;
  }

  public isEditMode(): boolean {
    return this.mode.isEdit();
  }

  public toStep(newStep: OrderEntryStep) {
    return new OrderEntryViewModel(this.api,
      this.products,
      this.accountLocations,
      this.contacts,
      this.distributors,
      this.cart,
      newStep,
      this.deliveryDetailsNotes,
      this.orderEntryCartContextData,
      this.mode,
      this.distributorTaxesEnabled,
      this.orderSummary,
      this.selectedAccountLocation,
      this.selectedContact,
      this.selectedDistributor,
      this.selectedDeliveryDay,
      this.selectedPaymentMethod,
      this.termPeriod,
      this.errors,
      this.summaryError,
      this.accountId,
      this.assignedSalesRep
    );
  }
}
