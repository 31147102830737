import React, {useState} from 'react';
import '../../../scss/ordo/accountsPage/accountPage/account-page.scss';
import Account, {AccountLocation} from '../../../models/Account';
import {useAPI} from '../../../context/OrdoApiContext';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import OrdoSpinner from '../../components/OrdoSpinner';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import OrdoButton from '../../components/common/OrdoButton';
import {LinkAccountsModal} from '../../components/modals/LinkAccountsModal';
import {EditLocationAddress} from '../../components/EditLocationAddress';

type AccountLocationsProps = {
  account: Account,
  onLinkingAccounts: Function
  onAddressUpdated: (a: AccountLocation) => void
  availableLocations: AccountLocation[]
}

export const AccountLocations = ({account, onLinkingAccounts, onAddressUpdated, availableLocations}: AccountLocationsProps) => {
  const amountOfLocations = account.amountOfLicenses();
  const [unlinkedLocation, setUnlinkedLocation] = useState<AccountLocation | undefined>(undefined);
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const {errorToast, successToast} = useOrdoToasts();
  const [linkLicensesModal, setLinkLicensesModal] = useState(false);
  const api = useAPI();

  const toggleUnlinkConfirmationModal = (location: AccountLocation | undefined) => setUnlinkedLocation(location);
  const toggleLinkLicensesModal = () => setLinkLicensesModal(!linkLicensesModal);

  const unlinkLicense = (accountLocation: AccountLocation) => {
    const finalLocations: AccountLocation[] = account.getLocations().filter(l => l.locationId !== accountLocation.locationId);
    showSpinner();
    api.linkAccounts(account.organizationId, account.id, finalLocations.map(l => l.locationId),account.name)
      .then((updatedAccount) => {
        successToast(`Successfully unlinked license ${accountLocation.location.licenseNumber} from account`);
        onLinkingAccounts(updatedAccount);
        toggleUnlinkConfirmationModal(undefined);
      })
      .catch(() => errorToast('Could not unlink license from account'))
      .finally(hideSpinner);
  };

  const renderLocation = (location: AccountLocation, index: number) => {
    return(
      <OrdoSpinner showSpinner={spinnerVisibility} key={index}>
        <div className="location">
          <div className="location-column">
            <span className="title">License</span>
            <span className="title">Address</span>
            <EditLocationAddress accountLocation={location} onAddressUpdated={onAddressUpdated}/>
          </div>
          <div className="location-column">
            <span>{location.location.licenseNumber}</span>
            <span className="location-column__address">{`${location.streetAddressLine1} ${location.streetAddressLine2}`}</span>
            {amountOfLocations > 1 && <span className="unlink" onClick={() => toggleUnlinkConfirmationModal(location)} role="presentation">unlink address</span>}
          </div>
        </div>
      </OrdoSpinner>
    );
  };

  return(<div className="locations-info card card-fluid inventory-list-card ordo-shadow ordo-card-border">
    <div className="locations-title">
      <span className="section-title">location details</span>
      <OrdoButton disabled={false} style={{padding: '0.2em 1em', height: '2em'}} text="link location" category="primary" onClick={toggleLinkLicensesModal} dataTestId="" additionalClassName="locations-link-account-button"/>
    </div>
    <div className="locations">
      {account.locations.map((location, index) => renderLocation(location, index))}
    </div>
    {unlinkedLocation && <ConfirmationModal show={!!unlinkedLocation}
      onClose={toggleUnlinkConfirmationModal}
      onSubmit={() => unlinkLicense(unlinkedLocation)}
      confirmationText={`are you sure you want to remove location with license ${unlinkedLocation.location.licenseNumber} form account ${account.name}?`}
    />}
    {linkLicensesModal && <LinkAccountsModal
      parentAccount={account}
      locations={availableLocations}
      selectedLocations={account.getLocations()}
      isOpen={linkLicensesModal}
      onClose={toggleLinkLicensesModal}
      orgId={account.organizationId}
      onLocationAddressUpdated={onAddressUpdated}
      onSubmit={(updatedAccount: Account) => onLinkingAccounts(updatedAccount)}/>}
  </div>);
};
