import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {DateFormatTemplate, OrdoDate} from '../../../../models/OrdoDate';
import {AccountRepAssignment} from '../../../../models/AccountRepAssignment';

export const AccountActivityAssignment = ({assignment} : {assignment: AccountRepAssignment}) => {
  return(<div className="account-activity-card">
    <div className='content'>

      <div className="icon-container notes">
        <FontAwesomeIcon icon={faCircle} size="2x" />
      </div>
      <div className="information">
        <div className="collapsed">
          <div>
            <div className="activity-title">
              <span >{`Account assigned to ${assignment.getName()}`}</span>
            </div>
            <div className="note-information">
              <span>{`${assignment.createdAt ? OrdoDate.from(assignment.createdAt).format(DateFormatTemplate.MONTH_DAY_YEAR_HOUR_IN_12) : ''}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>);
};
