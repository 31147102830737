import React, {FC} from 'react';
import '../../../scss/ordo/common.scss';

type SubTitleProps = {
  title: string,
  removeMargin?: boolean
  centered?: boolean,
  bottomMargin?: boolean
};

const OrdoPageSubTitle: FC<SubTitleProps> = ({title, removeMargin, centered, bottomMargin = true}: SubTitleProps) => (
  <div className={`row ${ bottomMargin  ? 'mb-3' : ''}  ${ removeMargin  ? 'no-side-margin' : ''} `}>
    <h5 className={`ordo-page-sub-title ${ centered  ? 'w-100 text-center' : ''}`} data-testid={`ordo-page-sub-title-${title}`}>
      {title}
    </h5>
  </div>
);

export default OrdoPageSubTitle;
