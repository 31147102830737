import React, {CSSProperties, ReactNode} from 'react';
import { v4 } from 'uuid';
import '../../../scss/ordo/ordo-table.scss';

type TableCell = {
  element: ReactNode,
  style: CSSProperties
}
type TableRow = { tableCells: TableCell[] }
type OrdoTableProps = {
  tableTitles: string[],
  tableRows: TableRow[],
  tableStyles?: CSSProperties,
  headerStyles?: CSSProperties,
  removeTableBorders?: boolean
}

export const createTableCell = (element: ReactNode, style: CSSProperties = {}): TableCell => ({
  element: element,
  style: style,
});

const OrdoTable = ({
  tableTitles,
  tableRows,
  tableStyles = {},
  headerStyles = {},
  removeTableBorders = false}: OrdoTableProps) => {
  const tableBordersStyle = `${removeTableBorders ? 'remove-borders' : ''}`;
  const headerBorderStyle = `${removeTableBorders ? '' : 'with-border-bottom'}`;

  return <table className={`ordo-table ${tableBordersStyle}`} style={tableStyles}>
    <thead>
      <tr className={`ordo-table__headers ${headerBorderStyle}`}>
        {tableTitles.map(headerTitle =>
          <th scope="col" className='ordo-table__headers__header-title' style={headerStyles} key={headerTitle}>
            {headerTitle}
          </th>)}
      </tr>
    </thead>
    <tbody>
      {tableRows.map(row =>
        <tr key={v4()}>
          {row.tableCells.map(cell =>
            <td className="ordo-table__cell" style={cell.style} key={v4()}>
              {cell.element}
            </td>)}
        </tr>)}
    </tbody>
  </table>;
};
export default OrdoTable;
