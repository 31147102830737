import React from 'react';
import '../../../scss/ordo/brand-card.scss';

type ImagePreviewProps = {
  url: string,
  alt: string,
  size: number,
  highlighted?: boolean,
};
const ImagePreview = ({url, alt, size, highlighted = false}: ImagePreviewProps) => {
  return <div className={`image-preview--container ordo-shadow rounded-lg ${highlighted ? 'selected' : ''}`}>
    <img src={url} alt={alt} width={size} height={size} className='rounded-lg image-preview-container__image'/>
  </div>;
};

export default ImagePreview;
