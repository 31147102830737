import React, {useContext, useEffect, useState} from 'react';
import {orderBy} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import OrdoSpinner from '../../components/OrdoSpinner';
import '../../../scss/ordo/salesActivity/sales-activity-card-drawer-details.scss';
import '../../../scss/ordo/salesActivity/activity-details.scss';
import {ActivityForm} from '../../components/activities/ActivityForm';
import {
  Activity,
  activityAssignedTo,
  ExternalEmailActivity
} from '../../../models/sales-activity/Activity';
import ActivityViewModel from '../../../application-models/sales-activity/ActivityViewModel';
import {useAPI} from '../../../context/OrdoApiContext';
import OrdoMobileFriendlyButton from '../../components/common/OrdoMobileFriendlyButton';
import MobileDisplayAsModal from '../../components/common/MobileDisplayAsModal';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {NON_ACTIVE_ACCOUNT_TEXT} from '../../../models/Account';
import {PipelineButtonText} from '../salesActivityFunctions';
import {ActivityTypeName} from '../../../models/sales-activity/ActivityTypeName';
import { ActivityDetailsRow } from './ActivityDetailsRow';
import {ExternalEmailActivityDetailsRow} from './ExternalEmailActivityDetailsRow';

type AccountActivitiesDetailsProps = {
  activities: (Activity | ExternalEmailActivity)[],
  orgId: string,
  accountId: string,
  accountName: string,
  activeAccount: boolean,
}

export const AccountActivitiesDetails = ({activities, orgId, accountId, accountName, activeAccount} : AccountActivitiesDetailsProps) => {
  const api = useAPI();
  const [displayActivities, setDisplayActivities] = useState(true);
  const [openedActivityForm, setOpenedActivityForm] = useState(false);
  const [activityViewModel, setActivityViewModel] = useState(ActivityViewModel.empty(api));
  const closeActivityForm = () => setOpenedActivityForm(false);
  const openActivityForm = () => setOpenedActivityForm(true);
  const {spinnerVisibility, showSpinner, hideSpinner} = useSpinnerToggle();
  const {successToast, errorToast} = useOrdoToasts();
  const [accountActivities, setAccountActivities] = useState<Activity[]>(activities);
  const {userSession} = useContext(UserSessionContext);

  useEffect(() => {
    ActivityViewModel.initialize(api, userSession.currentOrganization!.id, accountId)
      .then((vm) => setActivityViewModel(vm));
  }, []);

  const toggleActivities = () => setDisplayActivities(!displayActivities);

  const createActivity = () => {
    showSpinner();
    activityViewModel.createActivity(accountId, orgId)
      .then((activity) => {
        successToast('activity successfully created');
        activities.push(activity);
        const sortedActivities = orderBy(activities, 'date', 'desc');
        setAccountActivities(sortedActivities);
        setDisplayActivities(true);
        setOpenedActivityForm(false);
        const clearedViewModel =  activityViewModel.clearActivity();
        setActivityViewModel(clearedViewModel);
      })
      .catch(() => {
        errorToast('could not create the activity');
      })
      .finally(hideSpinner);
  };

  const updateActivity = (updatedActivity: Activity) => {
    const updatedActivities = accountActivities.map((activity) => {
      if (activity.id === updatedActivity.id) {
        return updatedActivity;
      }
      return activity;
    });
    setAccountActivities(updatedActivities);
  };

  const deleteActivity = (toDeleteActivity: Activity) => {
    showSpinner();
    activityViewModel.deleteActivity(orgId, accountId, toDeleteActivity.id)
      .then(() => {
        setAccountActivities(accountActivities.filter(activity => activity.id !== toDeleteActivity.id));
        successToast('Activity successfully deleted');
      })
      .catch(() => errorToast('Could not delete activity'))
      .finally(hideSpinner);
  };

  const showBottomSection = () => {
    return openedActivityForm || displayActivities;
  };

  return <OrdoSpinner showSpinner={spinnerVisibility}>
    <div className={`order-details-section ${showBottomSection() ? 'section-expanded' : 'section-collapsed'}`}>
      <div className="upper-section">
        <div className={`caret-and-title ${displayActivities ? 'opened' : ''}`} onClick={toggleActivities} role="presentation">
          <FontAwesomeIcon className={`caret-icon ${displayActivities ? 'opened' : 'closed'}`} size="lg"
            icon={faAngleRight}/>
          <span
            className="details-title">{`Activities (${accountActivities.length})`} </span>
        </div>
        <OrdoMobileFriendlyButton
          disabled={!activeAccount}
          text={PipelineButtonText.ADD_ACTIVITY}
          category="primary"
          onClick={openActivityForm}
          dataTestId="sales-activity-order-details-add-activity-button"
          disabledText={NON_ACTIVE_ACCOUNT_TEXT}
        />
      </div>

      {showBottomSection() &&
      <div className="bottom-section">
        {openedActivityForm && <MobileDisplayAsModal title="add an activity" onClose={closeActivityForm} show={openedActivityForm}>
          <div className="add-form-container">
            <div className="add-form">
              <ActivityForm
                selectedAccountId={accountId}
                accountsWithContacts={activityViewModel.accountsWithContactsAndSalesReps()}
                viewModel={activityViewModel}
                updateViewModel={setActivityViewModel}
                onSubmit={createActivity}
                onCancel={closeActivityForm}
                canEditSalesRep
                accountContacts={activityViewModel.contactsFromSelectedAccount()}
              />
            </div>
          </div>
        </MobileDisplayAsModal>
        }
        {displayActivities && (accountActivities).map((activity) =>
          activity.type === ActivityTypeName.EXTERNAL_EMAIL_ACTIVITY ?
            <ExternalEmailActivityDetailsRow
              activity={activity as ExternalEmailActivity}
            /> :
            <ActivityDetailsRow
              accounts={activityViewModel.accountsWithContactsAndSalesReps()}
              displayName={activity.activityTemplate.name}
              activitySubtitle={activityAssignedTo(activity)}
              activityTemplates={activityViewModel.getActivityTemplateOptions()}
              key={activity.id}
              orgId={orgId}
              accountId={accountId}
              accountName={accountName}
              activity={activity}
              onActivityUpdate={updateActivity}
              onActivityDelete={() => deleteActivity(activity)}
            />
        )}
      </div>}
    </div>
  </OrdoSpinner>;
};
