import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {
  Activity,
  activityAssignedTo,
  activityDisplayDate
} from '../../../models/sales-activity/Activity';
import {DateFormatTemplate} from '../../../models/OrdoDate';
import OrdoModal from '../common/OrdoModal';
import '../../../scss/ordo/activitiesPage/activity-responses-display.scss';
import {ActivityCompletionResponse} from '../../../models/activity/responses/ActivityCompletionResponse';
import {ActivityQuestionType} from '../../../models/sales-activity/ActivityQuestion';
import {DollarValueResponseDisplay} from './responses-display/DollarValueResponseDisplay';
import {TextResponseDisplay} from './responses-display/TextResponseDisplay';
import {FileUploadResponseDisplay} from './responses-display/FileUploadResponseDisplay';
import {ProductDropdownResponseDisplay} from './responses-display/ProductDropdownResponseDisplay';
import {MultipleChoiceResponseDisplay} from './responses-display/MultipleChoiceResponseDisplay';
import {NumericResponseDisplay} from './responses-display/NumericResponseDisplay';
import {DateTimeResponseDisplay} from './responses-display/DateTimeResponseDisplay';
import {CheckboxResponseDisplay} from './responses-display/CheckboxResponseDisplay';
import {useResolution} from '../../../context/UseResolutions';
import {isMobileResolution} from '../../../context/resolutions';


function renderResponse(response: ActivityCompletionResponse) {
  switch(response.questionType) {
  case ActivityQuestionType.TextResponse:
    return <TextResponseDisplay response={response}/>;
  case ActivityQuestionType.NumericResponse:
    return <NumericResponseDisplay response={response}/>;
  case ActivityQuestionType.DollarValue:
    return <DollarValueResponseDisplay response={response}/>;
  case ActivityQuestionType.ProductDropdown:
    return <ProductDropdownResponseDisplay response={response}/>;
  case ActivityQuestionType.FileUpload:
    return <FileUploadResponseDisplay response={response}/>;
  case ActivityQuestionType.Checkboxes:
    return <CheckboxResponseDisplay response={response}/>;
  case ActivityQuestionType.MultipleChoice:
    return <MultipleChoiceResponseDisplay response={response}/>;
  case ActivityQuestionType.DateTime:
    return <DateTimeResponseDisplay response={response}/>;
  case ActivityQuestionType.NoTypeSelectedYet:
  default:
    return <span>Unhandled question type: {response.questionType}</span>;
  }
}

type ActivityResponsesDisplayProps = {
  activity: Activity,
  accountName: string,
  onClose?: Function,
  onOpen?: Function
  centered?: boolean,
};

export const ActivityResponsesDisplay = ({activity, accountName, onClose, onOpen, centered = true}: ActivityResponsesDisplayProps) => {
  const [open, setOpen] = useState(false);
  const resolution = useResolution();
  const {contacts} = activity;

  const isMobile = isMobileResolution(resolution);

  return ((activity.completed && activity.responses && activity.responses.length > 0) ?
    <div className={`activity-responses-display ${centered ? 'centered' : ''}`}>
      <div className='activity-responses-display__toggle' onClick={(event)=> {
        event.stopPropagation();
        setOpen(true);
        if(onOpen) onOpen();
      }} role='presentation'>view responses</div>
      {open && <OrdoModal
        show={open}
        onClose={()=> {
          setOpen(false);
          if(onClose) onClose();
        }}
        className={isMobile ? 'mobile-modal-wrapper' : 'wide-modal'}
        showFooter={isMobile}
        secondaryButtonText='close'
        title={<div className='activity-responses-display-header'>
          <span className='title'>{accountName} · {activity.activityTemplate.name}</span>
          <span className='subtitle'>
            {`${activityDisplayDate(activity, DateFormatTemplate.WEEKDAY_NAME_AND_MONTH_YEAR)} • ${activityAssignedTo(activity)}`}
            {contacts && contacts.length > 0 &&
          <div className='contacts'>
            <FontAwesomeIcon className="contacts-icon" onClick={() => {}} size="sm" icon={faUser}/>
            <span>{`${contacts[0].name} ${contacts.length > 1 ? `+${contacts.length - 1}` : ''}`}</span>
          </div>
            }
          </span>
        </div>
        }
      >
        <div className='activity-responses-display-modal'>
          {
            activity.responses.map((response) => {
              return <div className='question-display' key={response.questionType + response.index} id={`${response.index}`}>
                <span className={`question-title ${response.isRequired ? 'required' : ''}` }>
                  {response.questionTitle}
                </span>
                {renderResponse(response)}
              </div>;
            })
          }
        </div>
      </OrdoModal>}
    </div> : <></>
  );
};
