import React from 'react';
import {
  ActivityCompletionResponseDisplayProps,
  SingleValueActivityCompletionResponse
} from '../../../../models/activity/responses/ActivityCompletionResponse';

export const DollarValueResponseDisplay = ({
  response,
}: ActivityCompletionResponseDisplayProps) => {
  const dollarValueResponse = response as SingleValueActivityCompletionResponse;

  const amount = parseFloat(dollarValueResponse.body.value);

  return <p className='text-response'> ${amount.toFixed(2)}</p>;
};