import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import '../../../scss/ordo/ordo-pagination.scss';

type OrdoPaginationProps = {
  currentPage: number,
  totalOfPages: number,
  onPageChange: Function
};

export const OrdoPagination = ({currentPage, totalOfPages, onPageChange}: OrdoPaginationProps) => {
  return(
    <div className="pagination-buttons-container">
      <div className="pagination-buttons">
        <div
          role="presentation"
          className={`move-to-page-container back ${currentPage > 1  ? '' : 'disabled'}`}
          onClick={() => {
            if(currentPage > 1) {
              onPageChange(currentPage - 1);
            }
          }}>
          <span><FontAwesomeIcon icon={faChevronLeft}/> Prev</span>
        </div>
        <div className="current-page-input">
          <input value={currentPage} onChange={(event) => {
            if(event.target.value.length > 0){
              const newPage = parseInt(event.target.value, 10);
              onPageChange(newPage > totalOfPages ? totalOfPages : newPage);
            }
          }}/>
          <span>{` of ${totalOfPages}`}</span>
        </div>
        <div
          role="presentation"
          className={`move-to-page-container ${ currentPage < totalOfPages  ? '' : 'disabled'}`}
          onClick={() => {
            if( currentPage < totalOfPages ) {
              onPageChange(currentPage + 1);
            }
          }}>
          <span>Next <FontAwesomeIcon icon={faChevronRight}/></span>
        </div>
      </div>
    </div>
  );
};
