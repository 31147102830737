import Account, {AccountData} from './Account';

export class RemovableAccount extends Account {
  public removed: boolean;

  constructor(data: AccountData, removed: boolean) {
    super(data);
    this.removed = removed;
  }

  static fromAccount(account: Account, removed: boolean) {
    return new RemovableAccount(
      {
        id: account.id,
        name: account.name,
        organizationId: account.organizationId,
        locations: account.locations,
        allocations: account.allocations,
        assignments: account.assignments,
        contacts: account.contacts,
        notes: account.notes,
        activities: account.activities,
        status: account.status,
        stalePeriod: account.stalePeriod,
        priority: account.priority,
        value: account.value,
        conversion: account.conversion,
        active: account.active,
        orderEntryEnabled: account.orderEntryEnabled,
      },
      removed
    );
  }
}
