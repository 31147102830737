import React from 'react';
import {Dropdown} from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisV} from '@fortawesome/free-solid-svg-icons';
import '../../../scss/ordo/card-dropdown.scss';
import {SizeProp} from '@fortawesome/fontawesome-svg-core';

export type DropDownMenuAction = {
  name: string,
  action: Function,
  disabled?: boolean
}

type OrdoCardDropdownProps = {
  options: DropDownMenuAction[],
  size?: SizeProp
}

const OrdoCardOptionsDropdown = ({options, size} : OrdoCardDropdownProps) => {
  return <Dropdown
    alignRight
    data-testid='ordo-card-options'>
    <DropdownToggle className='action-item card-menu remove-arrow'>
      <FontAwesomeIcon icon={faEllipsisV} size={size}/>
    </DropdownToggle>
    <Dropdown.Menu >
      {
        options.map(option =>
          <Dropdown.Item as='button'
            key={option.name}
            disabled={option.disabled}
            onSelect={()=>option.action()}
            data-testid={option.name}
            className="font-weight-bold dropdown-item">
            {option.name}
          </Dropdown.Item>
        )
      }
    </Dropdown.Menu>
  </Dropdown>;
};

export default OrdoCardOptionsDropdown;
