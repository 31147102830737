import {fullName, User} from '../User';
import Contact from './Contact';
import {Money, SerializableMoney} from '../Money';
import {OrderSummary, PaymentMethod} from './Summary';
import {DateFormatTemplate, OrdoDate} from '../OrdoDate';
import { OrderVersionLineItem } from './OrderVersionLineItem';
import { OrdoFile } from '../OrdoFile';
import {ProductMeasureUnit} from '../Product';
import Account, { AccountLocation } from '../Account';
import { OrganizationLicense } from '../OrganizationLicense';

export enum DiscountType {
  None,
  Dollar,
  Percentage
}

export enum LineItemType {
  Sample,
  Unit,
  Case
}

export type LineItem = {
  id?: string
  productId: string
  quantity: number
  price: Money
  discountPercentage: number
  discountCurrencyAmount: Money
  discountType: DiscountType
  sample: boolean,
  type: LineItemType,
  caseSize?: number,
  consumerUnitSizeAmount?: number,
  consumerUnitSizeUnit?: ProductMeasureUnit,
  tierId?: string,
  tierPriceApplied?: Money
  notes: string,
}

export type LineItemWithTax = LineItem & {
  exciseTax: Money
  salesTax: Money
}

export type SerializableLineItem = {
  id?: string
  productId: string
  quantity: number
  discountPercentage: number
  discountType: DiscountType
  sample: boolean
  price: SerializableMoney
  discountCurrencyAmount: SerializableMoney,
  type: LineItemType,
  caseSize?: number,
  consumerUnitSizeAmount?: number,
  consumerUnitSizeUnit?: ProductMeasureUnit,
  notes: string
}

export type OrderInput = {
  lineItems: LineItem[]
  discountPercentage: number
  discountCurrencyAmount: Money
  discountType: DiscountType
  deliveryDay: Date
  orderNotes: string
  locationId: string
  accountId: string,
  contactId: string
  distributorExternalLicenseId: string
  paymentMethod: PaymentMethod
  termPeriod: number | undefined
  distributorTaxesEnabled?: boolean,
  assignedSalesRepId?: string
}

export type TrackedOrderInput = {
  location: AccountLocation,
  accountId: string,
  totalValue: Money,
  contact: Contact,
  deliveryDay: Date,
  paymentMethod: PaymentMethod,
  termPeriod: number | undefined,
  invoice: OrdoFile
}

export type SubmitTrackedOrderInput = {
  locationId: string,
  accountId: string,
  trackedTotal: Money,
  contactId: string,
  deliveryDay: Date,
  paymentMethod: PaymentMethod,
  termPeriod: number | undefined,
}

export type EditTrackedOrderInvoiceData = {
  file: File | undefined,
  url: string
}


export type EditOrderInput = OrderInput & { status?: OrderStatus }

export enum OrderStatus {
  Unknown = 'unknown',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Confirmed = 'awaiting delivery',
  Paid = 'paid',
  Delivered = 'delivered'
}

type OrderVersionBatch = {
  id: string
  batchId: string
  quantity: number
}

export type OrderVersion = {
  id: string
  effective: Date
  items: OrderVersionLineItem[];
  batches: OrderVersionBatch[];
  orderNotes: string
  status: OrderStatus
  deliveryDay: Date
  discountType: DiscountType,
  account: Account,
  discountPercentage: number
  discountCurrencyAmount: Money
  summary: OrderSummary
  invoiceUrl: string,
  accountLocation: AccountLocation,
  contact: Contact,
  distributor: OrganizationLicense,
  assignedSalesRep: User
}

export default class Order {
  constructor(public id: string,
              public salesRep: User,
              public status: OrderStatus,
              public datePlaced: Date,
              public salesValue: Money,
              public orderNumber: string) {}

  public get orderId() {
    return this.id;
  };

  public get salesRepName() {
    return fullName(this.salesRep);
  }

  public statusToString() : string {
    return this.status;
  }

  public formattedDate() : string {
    const date = OrdoDate.from(this.datePlaced);
    const month = date.format(DateFormatTemplate.MONTH_NAME_ABBREVIATED);
    const day = date.format(DateFormatTemplate.DAY_OF_MONTH);
    return `${month.toLocaleLowerCase()} ${day}`;
  }

  public isPending() : boolean {
    return this.status === OrderStatus.Pending;
  }

  public isAwaitingDelivery() : boolean {
    return this.status === OrderStatus.Confirmed;
  }

  public isDelivered() : boolean {
    return this.status === OrderStatus.Delivered;
  }

  public isCancelled() {
    return  this.status === OrderStatus.Cancelled;
  }
}
