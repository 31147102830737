import React, {ChangeEvent, CSSProperties, useState} from 'react';
import '../../../../scss/ordo/ordo-masked-input.scss';

export type OrdoMaskedInputProps = {
  label: string,
  value: number | string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  onBlur: Function,
  customIcon: React.ReactNode,
  onClickIcon?: (event: any) => void,
  min?: number,
  max?: number,
  step?: number,
  validate?: (inputValue: string) => string,
  invalidMessage?: string,
  inputKey?: string,
  style?: CSSProperties,
  iconsStyle?: CSSProperties,
  invalidWithoutMessage?: boolean,
  displayValue?: (n: number) => string
};

const OrdoMaskedInput = ({
  label,
  value,
  onChange,
  onBlur,
  step = 1,
  customIcon,
  onClickIcon = () => {},
  min,
  max,
  validate,
  invalidMessage = '',
  inputKey,
  style={},
  iconsStyle={},
  invalidWithoutMessage = false,
}: OrdoMaskedInputProps) => {
  const [inputValue, setInputValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState(invalidWithoutMessage ? '' : invalidMessage);
  const showErrorMessage = errorMessage.length > 0;
  return <div className={`masked-input-container ${(showErrorMessage) ? 'is-invalid' : ''}`} title={showErrorMessage ? '' : invalidMessage}>
    {label && <div className={`masked-input-container__label ${!showErrorMessage ? '' : 'is-invalid'}`}>
      {label}
    </div>}
    <div className="masked-input-container__input">
      <input
        id={inputKey}
        className="number-input"
        type='number'
        step={step}
        min={min}
        max={max}
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value);
          if (validate) {
            setErrorMessage(validate(event.target.value));
          }
          onChange(event);
        }}
        onBlur={(event) => onBlur(event)}
        style={style}
        onKeyPress={ (event) => {
          if(event.key === 'Enter') onBlur(event);
        }}
      />
      <div className={`masked-input-container__input__icons ${!showErrorMessage ? '' : 'is-invalid'}`}
        role="button"
        onClick={onClickIcon}
        onKeyPress={() => {}}
        tabIndex={0}
        style={iconsStyle}>
        {customIcon}
      </div>
    </div>
    {showErrorMessage && !invalidWithoutMessage && <div className="masked-input-container__invalid-feedback invalid-feedback">
      {errorMessage}
    </div>}
  </div>;
};

export default OrdoMaskedInput;
