import React, {useContext} from 'react';
import OrdoPageSubTitle from '../../components/common/OrdoPageSubTitle';
import {WholeOrderDiscountButton} from '../WholeOrderDiscountButton';
import {OrdoCheckbox} from '../../components/OrdoCheckbox';
import OrdoDropdown from '../../components/common/OrdoDropdown';
import OrdoInput from '../../components/common/inputs/OrdoInput';
import {validateTermPeriod} from '../../../models/order-entry/OrderValidator';
import OrdoTextArea from '../../components/common/OrdoTextArea';
import OrdoButton from '../../components/common/OrdoButton';
import {OrderEntryViewModel} from '../../../application-models/order-entry/OrderEntryViewModel';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import {DiscountType} from '../../../models/order-entry/Order';
import {AdminChecker} from '../../../routes/permissions';
import {UserSessionContext} from '../../../context/UserSessionContext';
import {Organization} from '../../../models/Organization';
import {fullName, User} from '../../../models/User';

type OrderSummaryProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateViewModel: (viewModel: OrderEntryViewModel) => void,
  organization: Organization,
  onSubmit: (event: any) => void
}

export const OrderSummary = ({orderEntryViewModel, updateViewModel, organization, onSubmit} : OrderSummaryProps) => {
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();
  const adminChecker = new AdminChecker();
  const userSession = useContext(UserSessionContext);
  const selectableSalesReps = organization.members.map(member => member.user);

  const updateWholeOrderDiscount = () => {
    return async (value: number) => {
      showSpinner();
      const newVm = await orderEntryViewModel.updateWholeOrderDiscount(organization.id, value);
      updateViewModel(newVm);
      hideSpinner();
    };
  };

  const updateCurrentPaymentMethod = (paymentMethod: string) => {
    const updatedViewModel = orderEntryViewModel.updateCurrentPaymentMethod(paymentMethod);
    updateViewModel(updatedViewModel);
  };

  const updateAssignedSalesRep= (salesRep: User) => {
    const updatedViewModel = orderEntryViewModel.updateAssignedSalesRep(salesRep);
    updateViewModel(updatedViewModel);
  };

  const updateWholeOrderDiscountType = () => {
    return async (value: DiscountType) => {
      showSpinner();
      const newVM = await orderEntryViewModel.updateWholeOrderDiscountType(organization.id, value);
      updateViewModel(newVM);
      hideSpinner();
    };
  };

  const updateDistributorTaxesEnabled = async () => {
    showSpinner();
    const vm = await orderEntryViewModel.updateDistributorTaxesEnabled(organization.id);
    updateViewModel(vm);
    hideSpinner();
  };

  const updateTermPeriod = (termPeriod: number) => {
    const updatedViewModel = orderEntryViewModel.updateTermPeriod(termPeriod);
    updateViewModel(updatedViewModel);
  };

  return (
    <>
      <div className="order-entry-second-step-container__body__order_summary ordo-shadow ordo-card-border">
        <OrdoPageSubTitle title="order summary"/>
        <div className="order-entry-second-step-container__body__order_summary__data">
          <div className="order-entry-second-step-container__body__order_summary__data__sales_rep">
            {adminChecker.allow(userSession.userSession) && <OrdoDropdown
              placeholder='assign a sales rep'
              selectedOption={orderEntryViewModel.getSelectedSalesRep()}
              options={selectableSalesReps}
              onSelect={(salesRep: User) => updateAssignedSalesRep(salesRep)}
              displayName={(member: User) => fullName(member)}
              height='short'
            />}
          </div>
          <div className="order-entry-second-step-container__body__order_summary__data__row">
            <span className="order-entry-second-step-container__body__order_summary__data__row__line"> total </span>
            <span>{orderEntryViewModel.total()}</span>
          </div>
          <div className="order-entry-second-step-container__body__order_summary__data__row">
            <span className="order-entry-second-step-container__body__order_summary__data__row__line"> discounts </span>
            <span>{orderEntryViewModel.discountTotal()}</span>
          </div>
          <WholeOrderDiscountButton
            label='whole order discount'
            item={orderEntryViewModel.cart}
            onChange={() => {}}
            onSwitch={updateWholeOrderDiscountType()}
            onBlur={updateWholeOrderDiscount()}
            discountButtonStyle={{width: '160px'}}
            iconsStyle={{width: '2em', height: '2em'}}
          />
          <div className="order-entry-second-step-container__body__order_summary__data__row">
            <span className="order-entry-second-step-container__body__order_summary__data__row__line"> subtotal </span>
            <span>{orderEntryViewModel.subtotal()}</span>
          </div>
          <div className="order-entry-second-step-container__body__order_summary__data__row">
            <span className="order-entry-second-step-container__body__order_summary__data__row__line"> tax total </span>
            <span>{orderEntryViewModel.totalTax()}</span>
          </div>
          {orderEntryViewModel.canEnableDistributorTaxes() && <OrdoCheckbox
            checked={orderEntryViewModel.distributorTaxesEnabled}
            onChange={updateDistributorTaxesEnabled}
            label='distributor will remit taxes'
            id='calculate-taxes'
            style={{marginRight: '1em'}}
          />}
          <div className="order-entry-second-step-container__body__order_summary__data__row">
            <span className="order-entry-second-step-container__body__order_summary__data__row__line"> grand total </span>
            <span className="order-entry-second-step-container__body__order_summary__data__row__line">{orderEntryViewModel.grandTotal()}</span>
          </div>
          <div className="order-entry-second-step-container__body__order_summary__data__payment__method">
            <OrdoDropdown
              placeholder='select a payment method'
              selectedOption={orderEntryViewModel.getSelectedPaymentMethod()}
              options={orderEntryViewModel.getSelectablePaymentMethods()}
              onSelect={(paymentMethod: string) => updateCurrentPaymentMethod(paymentMethod)}
              height='short'
            />
            {orderEntryViewModel.selectedPaymentMethod === 'net terms' &&
                  <div className='order-entry-second-step-container__body__order_summary__data__payment__method__net_term_period'>
                    <div>Term Period</div>
                    <OrdoInput
                      inputName='net-terms-input'
                      value={orderEntryViewModel.getTermPeriod()}
                      placeholder='7, 15, 30, etc'
                      inputSize='large'
                      inputType='number'
                      onChange={updateTermPeriod}
                      validate={validateTermPeriod}
                    />
                  </div>}
          </div>
        </div>
        <div className="order-entry-second-step-container__body__order_summary__delivery_notes">
          <span className="order-entry-second-step_text"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Max length 250 characters">
              order notes
          </span>
          <OrdoTextArea style={{height: '10em'}}
            value={orderEntryViewModel.deliveryDetailsNotes}
            handleChange={(event: any) => updateViewModel(orderEntryViewModel.updateOrderNotes(event.target.value))}/>
        </div>
        <OrdoButton
          disabled={!orderEntryViewModel.canSubmitOrder() && !spinnerVisibility}
          disabledText={orderEntryViewModel.submitOrderInvalidText()}
          text={orderEntryViewModel.submitOrderCTAText()}
          category="primary"
          onClick={onSubmit}
          dataTestId="submit-order"
          additionalClassName='submit-order-button'/>
      </div>
    </>
  );
};