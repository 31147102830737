export enum SubscriptionStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  CANCELED = 'Canceled',
  DEACTIVATED = 'Deactivated',
  PAUSED = 'Paused'
}

export enum SubscriptionType {
  STARTER = 'Starter',
  LITE = 'Lite',
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
  NONE = 'None',
}

export type Subscription = {
  id: string,
  externalSubscriptionId: string,
  externalCustomerId: string,
  status: SubscriptionStatus,
  type: SubscriptionType,
  subscriptionEmail: string,
  subscriptionUserId: string,
}

export const NoSubscription: Subscription = {
  id: '',
  externalSubscriptionId: '',
  externalCustomerId: '',
  status: SubscriptionStatus.PENDING,
  type: SubscriptionType.STARTER,
  subscriptionEmail: '',
  subscriptionUserId: '',
};
