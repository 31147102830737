import {API} from '../lib/api/ordoApi';
import FormError from '../errors/form_error';
import { OrdoImage } from '../models/OrdoImage';


type CreateBrandViewModelInput = {
  name: string,
  logo: OrdoImage | null,
  ordoAPI: API,
  error?: FormError,
  loading: boolean
}

export default class CreateBrandViewModel {
  public name: string
  public logo: OrdoImage | null
  private readonly ordoAPI: API
  private error: FormError
  private readonly loading: boolean

  constructor(viewModelInput: CreateBrandViewModelInput) {
    this.name = viewModelInput.name;
    this.logo = viewModelInput.logo;
    this.ordoAPI = viewModelInput.ordoAPI;
    this.error = viewModelInput.error || FormError.withoutError();
    this.loading = viewModelInput.loading;
  }

  public static empty(ordoAPI: API) {
    return new CreateBrandViewModel({name: '', logo: null, ordoAPI: ordoAPI, loading: false});
  }

  public updateViewModel(fieldsToUpdate: { name?: string, logo?: OrdoImage }) {
    const updatedFields = {name: this.name, logo: this.logo, ...fieldsToUpdate, ordoAPI: this.ordoAPI, loading: false};
    return new CreateBrandViewModel(updatedFields);
  }

  public submit(orgId: string) {
    return this.ordoAPI.createBrand(orgId, this.name, this.logo!.raw!);
  }

  public canUpload() {
    return this.name && !!this.logo && !this.loading;
  }

  public setUploading() {
    return new CreateBrandViewModel({
      name: this.name,
      logo: this.logo,
      ordoAPI: this.ordoAPI,
      loading: true,
    });
  }
}
