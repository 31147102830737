import React, {useContext} from 'react';
import {OrderEntryViewModel} from '../../../../application-models/order-entry/OrderEntryViewModel';
import {AccountLocation} from '../../../../models/Account';
import '../../../../scss/ordo/mobileOrderEntry/mobile-order-entry.scss';
import OrdoInformativeSearchableDropdown
  from '../../../components/common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {OrganizationLicense} from '../../../../models/OrganizationLicense';
import {UserSessionContext} from '../../../../context/UserSessionContext';
import OrdoButton from '../../../components/common/OrdoButton';
import OrdoSpinner from '../../../components/OrdoSpinner';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';

type MobileOrderEntryFirstStepProps = {
  orderEntryViewModel: OrderEntryViewModel,
  updateViewModel: (viewModel: OrderEntryViewModel) => void,
  updateAccountLocation: (accountLocation: AccountLocation) => void,
  goToSecondStep: () => void,
  landscapeMode: boolean
}

export const MobileOrderEntryFirstStep = ({orderEntryViewModel, updateViewModel, updateAccountLocation, goToSecondStep, landscapeMode}: MobileOrderEntryFirstStepProps) => {
  const userSession = useContext(UserSessionContext);
  const organization = userSession.currentOrganization()!;
  const {hideSpinner, showSpinner, spinnerVisibility} = useSpinnerToggle();

  const updateCurrentDistributor = async (selectableDistributor: OrganizationLicense) => {
    showSpinner();
    const updatedViewModel = await orderEntryViewModel.updateDistributor(organization.id, selectableDistributor);
    updateViewModel(updatedViewModel);
    hideSpinner();
  };

  return(
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <>
        {
          !orderEntryViewModel.hasDistributors() && <div>
            <span>Please, enable an organization license to create an order</span>
          </div>
        }
        {
          orderEntryViewModel.hasDistributors() &&
          <div className={`first-step-container ${landscapeMode ? 'landscape' : ''}`}>
            <span className='first-step-title'>Place an order</span>
            <div className='first-step-selector'>
              <span className='first-step-selector'>select a buyer</span>
              <OrdoInformativeSearchableDropdown
                dataTestId='select-location'
                selectedOption={orderEntryViewModel.getSelectedAccountLocation()}
                options={orderEntryViewModel.getSelectableAccountLocations()}
                onChangeSelectedOption={updateAccountLocation}
                singleRowHeight
                placeholder='select a location'
                addedStyles={{
                  marginBottom: '1em', valueContainer: {padding: '0'},
                  control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
                }}
              />
            </div>
            <div className='first-step-selector'>
              <span className='first-step-selector'>select fulfillment center</span>
              <OrdoInformativeSearchableDropdown
                selectedOption={orderEntryViewModel.getSelectedDistributor()}
                options={orderEntryViewModel.getSelectableDistributors()}
                onChangeSelectedOption={(distributor) => updateCurrentDistributor(distributor)}
                placeholder='select a Distributor'
                singleRowHeight
                canEditValue={orderEntryViewModel.hasMoreThanOneDistributor()}
                addedStyles={{
                  marginBottom: '1em', valueContainer: {padding: '0'},
                  control: {minHeight: 'min-content'}, indicatorContainer: {padding: '0.1em'}
                }}
              />
            </div>
            <div className='go-to-create-order'>
              <OrdoButton
                disabled={false}
                disabledText={orderEntryViewModel.completeOrderInvalidText()}
                text="continue to product selection"
                category="primary"
                onClick={() => goToSecondStep()}
                dataTestId="review prices and discounts-button"
                style={{padding: '0.25em 1em', width:'100%'}}
              />
            </div>

          </div>
        }
      </>
    </OrdoSpinner>
  );
};