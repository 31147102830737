import React, {useState} from 'react';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import OrdoSpinner from '../OrdoSpinner';
import OrdoModal from '../common/OrdoModal';
import useSpinnerToggle from '../../../hooks/useSpinnerToggle';
import {useAPI} from '../../../context/OrdoApiContext';
import '../../../scss/ordo/accountsPage/assignment.scss';
import '../../../scss/ordo/accountsPage/linkAccounts/link-accounts-modal.scss';
import OrdoInput from '../common/inputs/OrdoInput';
import OrdoPageSubTitle from '../common/OrdoPageSubTitle';
import Account, {AccountLocation, accountLocationHasStreetAddress} from '../../../models/Account';
import OrdoInformativeSearchableDropdown from '../common/searchable-dropdown/OrdoInformativeSearchableDropdown';
import {toInformativeSelectableOption} from '../common/searchable-dropdown/InformativeOption';
import useOrdoToasts from '../../../hooks/useOrdoToasts';
import {locationAddress} from '../../../models/Location';
import {EditLocationAddress} from '../EditLocationAddress';

type LinkAccountsModalProps = {
  isOpen: boolean,
  onClose: Function,
  onSubmit: Function,
  onLocationAddressUpdated: (a: AccountLocation) => void,
  orgId: string,
  parentAccount: Account,
  locations: AccountLocation[],
  selectedLocations: AccountLocation[]
}

export const LocationCard = ({onClick, index, accountLocation, onLocationAddressUpdated}: { onClick: () => void, index: number, accountLocation: AccountLocation, onLocationAddressUpdated: (a: AccountLocation) => void }) => {
  return <div className="account-card-container card ordo-card-border ordo-shadow">
    <FontAwesomeIcon className="mb-3 interectable-icon" size="lg" color="#8492A6" icon={faMinusCircle}
      onClick={onClick}/>
    <div className="account-card">
      <span className="account-name text-limit"
        data-testid={`location-card-${index}-name`}>{accountLocation.location.licenseNumber}</span>
      <div>
        {
          accountLocationHasStreetAddress(accountLocation) && <p className="account-address text-limit"> {accountLocation.streetAddressLine1} {accountLocation.streetAddressLine2}</p>
        }
        <p className="account-address city text-limit"> {accountLocation.city} {accountLocation.state} {accountLocation.zipCode}</p>
      </div>
      <EditLocationAddress accountLocation={accountLocation} onAddressUpdated={onLocationAddressUpdated}/>
    </div>
  </div>;
};

export const LinkAccountsModal = ({isOpen, onClose, onSubmit, orgId, parentAccount, locations, selectedLocations, onLocationAddressUpdated}: LinkAccountsModalProps) => {
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const { errorToast, successToast } = useOrdoToasts();
  const api = useAPI();
  const [linkedAccountsName, setLinkedAccountName] = useState<string>(parentAccount.name);
  const [toBeMergedLicenses, setToBeMergedLicenses] = useState(selectedLocations);

  const getSelectableLocations = () => {
    const selectableLocations = locations.filter(location => !selectedLocations.some(l => l.locationId === location.locationId));
    return selectableLocations.map(location => toInformativeSelectableOption(location.location.licenseNumber, '', locationAddress(location), location));
  };

  const addSelectedLocation = (selectedLocation: AccountLocation) => {
    setToBeMergedLicenses(toBeMergedLicenses.concat([selectedLocation]));
  };

  const removedSelectedLocation = (selectedLocation: AccountLocation) => {
    setToBeMergedLicenses(toBeMergedLicenses.filter(location => location.locationId !== selectedLocation.locationId));
  };

  const linkAccounts = () => {
    const mergingLocationsIds = toBeMergedLicenses.map(location => location.locationId);
    showSpinner();
    api.linkAccounts(orgId, parentAccount.id, mergingLocationsIds, linkedAccountsName)
      .then((updatedAccount) => {
        onSubmit(updatedAccount);
        successToast('Successfully linked accounts');
      })
      .catch(() => {
        errorToast('Could not link accounts');
        onSubmit(parentAccount);
      })
      .finally(() => {
        onClose();
        hideSpinner();
      });
  };

  return (<div>
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <OrdoModal show={isOpen}
        title='link accounts information'
        onClose={() => onClose()}
        onSubmit={() => linkAccounts()}
        size="sm"
        disableCTA={toBeMergedLicenses.length < 1}
        showFooter
        actionText='save'
      >
        <div className="link-accounts-container">
          <div>
            <OrdoPageSubTitle title='account name' bottomMargin={false} removeMargin/>
            <OrdoInput inputName="linked-accounts-name"
              value={linkedAccountsName}
              placeholder='account name'
              inputSize='large'
              onChange={setLinkedAccountName}
            />
          </div>

          <div className="selected-accounts">
            <OrdoPageSubTitle title='licenses' bottomMargin={false} removeMargin/>
            <OrdoInformativeSearchableDropdown
              dataTestId='select-account'
              selectedOption={null}
              options={getSelectableLocations()}
              onChangeSelectedOption={(selected) => addSelectedLocation(selected)}
              placeholder='select a license'
              singleRowHeight
            />
            <div className="account-list-container">
              <div className='account-list account-modal'>
                {toBeMergedLicenses.map((location, i) => {
                  return (
                    <LocationCard
                      key={location.locationId}
                      onClick={() => removedSelectedLocation(location)}
                      index={i}
                      accountLocation={location}
                      onLocationAddressUpdated={onLocationAddressUpdated}
                    />);
                })}
              </div>
            </div>
          </div>
        </div>
      </OrdoModal>
    </OrdoSpinner>
  </div>);
};
