import {User} from '../User';
import Account from '../Account';

export abstract class Allocation {
  protected constructor(
    public quantity: number,
    public productId: string,
    public assignedTo: User | Account,
    public id?: string) {
  }

  public abstract isOrgMemberAllocation(): boolean
  public abstract isAccountAllocation(): boolean
  public abstract name(): string

  public assignedToId(): string {
    return this.assignedTo.id;
  }

  public updateQuantity(quantity: number) {
    if (quantity < 0) {
      return;
    }
    this.quantity = quantity;
  }

  public toDataTransferObject(orgId: string) {
    return {
      accountId: this.isAccountAllocation() ? this.assignedToId() : undefined,
      salesRepId: this.isOrgMemberAllocation() ? this.assignedToId() : undefined,
      productId: this.productId,
      quantity: this.quantity,
      id: this.id,
      orgId: orgId,
    };
  }

  public isAssignedToUser(user: User): boolean {
    return this.assignedToId() === user.id;
  }

  public hasSameAssignee(allocation: Allocation) {
    return this.assignedToId() === allocation.assignedToId() && allocation.isAccountAllocation() === this.isAccountAllocation();
  }

  public hasDifferentAccountAssigned(account: Account) {
    return this.isAccountAllocation() && account.id !== this.assignedToId();
  }
}
