import React from 'react';
import {TimeSlot} from '../../models/TimeSlot';
import {TimelineItem} from '../../models/AccountTimeline';
import {Note} from '../../models/Note';
import {Activity} from '../../models/sales-activity/Activity';
import {TimelineSlotContent} from './TimelineSlotContent';

type TimelineSlotProps = {
  width: number, slot: TimeSlot, items: TimelineItem[],
  popupSide: 'left' | 'right', popupVerticalPlacement: 'top' | 'bottom',
  onEditNote: (note: Note) => void
  onEditActivity: (activity: Activity) => void
  onActivityCheckboxClicked: (activity: Activity) => void
  accountName: string
};
export const TimelineSlot = ({
  width,
  slot,
  items,
  popupSide,
  popupVerticalPlacement,
  onActivityCheckboxClicked,
  onEditNote,
  onEditActivity,
  accountName
}: TimelineSlotProps) => {

  return <div className="timeline-slot" style={{width: `${width}%`}}>
    <div className="content">
      <TimelineSlotContent content={slot.slotContent(items)}
        popupSide={popupSide} popupVerticalPlacement={popupVerticalPlacement}
        onEditNote={onEditNote} onEditActivity={onEditActivity}
        onActivityCheckboxClicked={onActivityCheckboxClicked}
        accountName={accountName}
      />
    </div>
  </div>;
};