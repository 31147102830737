import {faClock} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import '../../../scss/ordo/ordo-timepicker.scss';
import Datetime  from 'react-datetime';
import {Moment} from 'moment';

const initializeRoundTto15Minutes = () => {
  const now = new Date();
  const round = 60000 * 15;
  return new Date(Math.ceil(now.getTime() / round) * round);
};

export const OrdoTimePicker = ({onChangeDate, activityTime} : {onChangeDate: Function, activityTime?: Date}) => {
  const [showTimePicker, setShowTimePicker] = useState<boolean>(!!activityTime);
  const [timeHasBeenSelected, setTimeHasBeenSelected] = useState(false);
  const [time, setTime] = useState(activityTime ? new Date(activityTime) : initializeRoundTto15Minutes());

  const onDateChange = (moment: Moment|string) => {
    if(typeof moment === 'string') throw Error(`Invalid date: ${moment}`);
    const newDate = moment.toDate();
    setTime(newDate);
    onChangeDate(newDate);
    if(!timeHasBeenSelected) setTimeHasBeenSelected(true);
  };

  const toggleTimePicker = () => {
    if(showTimePicker) {
      if(!timeHasBeenSelected) {
        onChangeDate(undefined);
        setShowTimePicker(false);
      }
    } else { setShowTimePicker(true);}
  };

  return(
    <div className='time-picker-container'>
      {!showTimePicker
        ?
        <div tabIndex={0} onClick={() => setShowTimePicker(!showTimePicker)} role='button' onKeyDown={(event) => {
          event.preventDefault();
        }}>
          <FontAwesomeIcon className="clock-icon" size="1x" icon={faClock}/>
          {!showTimePicker &&<span>select a time</span>}
        </div>
        :
        <FontAwesomeIcon className="clock-icon" size="1x" icon={faClock} onClick={() => {
          onChangeDate(time);
          setShowTimePicker(!showTimePicker);
        }}/>
      }
      {showTimePicker && <div className="time-container">
        <Datetime dateFormat={false} timeFormat value={time} closeOnSelect onChange={onDateChange} onClose={toggleTimePicker}
          timeConstraints={{
            minutes: {
              min: 0,
              max: 59,
              step: 15
            }
          }}/>
      </div>}
    </div>
  );

};
