import React, {useEffect, useRef, useState} from 'react';
import '../../../../scss/ordo/activitiesPage/activities-list.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router';
import {Activity, ActivityItem} from '../../../../models/sales-activity/Activity';
import {OrdoCheckbox} from '../../../components/OrdoCheckbox';
import {ActivityContacts} from './ActivityContacts';
import {DateFormatTemplate, OrdoDate} from '../../../../models/OrdoDate';
import {useAPI} from '../../../../context/OrdoApiContext';
import {ActivityInput} from '../../../../application-models/sales-activity/ActivityViewModel';
import useOrdoToasts from '../../../../hooks/useOrdoToasts';
import ConfirmationModal from '../../../components/common/ConfirmationModal';
import useSpinnerToggle from '../../../../hooks/useSpinnerToggle';
import OrdoSpinner from '../../../components/OrdoSpinner';
import {ActivityModal} from '../../../components/modals/ActivityModal';
import {ActivityCompleteModal} from '../../../components/modals/ActivityCompleteModal';
import { ActivityCompletionResponse } from '../../../../models/activity/responses/ActivityCompletionResponse';
import ROUTE_NAMES from '../../../../routes/ROUTE_NAMES';
import {ActivityResponsesDisplay} from '../../../components/activities/ActivityResponsesDisplay';
import {fullName} from '../../../../models/User';
import {MultiContactInformationTooltip} from '../../../accounts/account/MultiContactInformationTooltip';
import Contact from '../../../../models/order-entry/Contact';


export type ActivityItemProps = {
  activityItem: ActivityItem,
  orgId: string,
  onDeleteActivity: Function,
  onActivityEdit: Function,
  key: string
}

export const ActivityItemRow = ({activityItem, orgId, onDeleteActivity, onActivityEdit, key}: ActivityItemProps) => {
  const [activity, setActivity] = useState<ActivityItem>(activityItem);
  const api = useAPI();
  const {successToast, errorToast} = useOrdoToasts();
  const [deleteActivityConfirmationModal, setDeleteActivityConfirmationModal] = useState(false);
  const [editActivityModal, setEditActivityModal] = useState(false);
  const { showSpinner, spinnerVisibility, hideSpinner} = useSpinnerToggle();
  const [openCompleteActivityWizard, setOpenCompleteActivityWizard] = useState(false);
  const [openMarkActivityAsPending, setOpenMarkActivityAsPending] = useState(false);
  const history = useHistory();
  const ref = useRef(null);
  const [isContactTooltipVisible, setIsContactTooltipVisible] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (event: any) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      // @ts-ignore
      if (isContactTooltipVisible && ref.current && !ref.current.contains(event.target)) {
        setIsContactTooltipVisible(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isContactTooltipVisible]);

  const goToAccountPage = () => {
    history.push(ROUTE_NAMES.ACCOUNT_PAGE.replace(':accountId', activity.accountId));
  };

  useEffect(() => {setActivity(activityItem);}, [activityItem]);

  const closeCompleteActivityModal = () => setOpenCompleteActivityWizard(false);
  const closeMarkActivityAsPendingModal = () => setOpenMarkActivityAsPending(false);

  const updateActivityCompleted = (completed: boolean, responses: ActivityCompletionResponse[]) => {
    const input : ActivityInput = {...activityItem, completed: completed, responses: responses};
    api.updateActivity(input, orgId, activity.accountId, activity.id)
      .then((updatedActivity: Activity) => {
        setActivity({...updatedActivity, accountName: activityItem.accountName});
        onActivityEdit({...updatedActivity, accountName: activityItem.accountName});
      });
    closeCompleteActivityModal();
    closeMarkActivityAsPendingModal();
  };
  const toggleActivityCompleted = () => {
    if(!activity.completed) {
      if((activity.activityTemplate!.questions.length) > 0) {
        setOpenCompleteActivityWizard(true);
      } else {
        updateActivityCompleted(true, []);
      }
    }
    else setOpenMarkActivityAsPending(true);
  };

  const toggleDeleteActivityConfirmationModal = () => setDeleteActivityConfirmationModal(!deleteActivityConfirmationModal);

  const toggleEditActivityModal = () => setEditActivityModal(!editActivityModal);

  const deleteActivity = () => {
    showSpinner();
    api.deleteActivity(orgId, activity.accountId, activity.id)
      .then(() => {
        onDeleteActivity(activity.id);
        toggleDeleteActivityConfirmationModal();
        successToast('Activity deleted');
      })
      .catch(() => errorToast('Could not delete activity'))
      .finally(hideSpinner);
  };

  const onEdit = (updatedActivityItem: ActivityItem) => {
    setActivity(updatedActivityItem);
    onActivityEdit(updatedActivityItem);
  };

  const updateContact = (contact: Contact) => {
    const contacts = activity.contacts.filter(c => c.id !== contact.id);
    const activityWithUpdatedContacts = {...activity, contacts: [...contacts, contact]};
    setActivity(activityWithUpdatedContacts);
  };

  return(<div className="activity-item">
    <OrdoSpinner showSpinner={spinnerVisibility}>
      <div className="activity-item-status">
        <OrdoCheckbox notToggleOnClick id={`activity-page-check-activity-${activity.id}`} checked={activity.completed} onChange={toggleActivityCompleted}/>
      </div>
      <div className="activity-item-account-name">
        <span onClick={()=> goToAccountPage()} role='presentation'>{activity.accountName}</span>
        <ActivityResponsesDisplay activity={activity} centered={false}  accountName={activity.accountName}/>
      </div>
      <div className="activity-item-template-name">
        <span>{activity.activityTemplate.name}</span>
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/mouse-events-have-key-events */}
      <div className="activity-item-contacts"
        key={key}
        ref={ref}
        onClick={() => setIsContactTooltipVisible(oldState => !oldState)}>
        <ActivityContacts activityContacts={activity.contacts}/>
        <MultiContactInformationTooltip isContactTooltipVisible={isContactTooltipVisible} contacts={activity.contacts} organizationId={orgId} onContactEdit={updateContact}/>
      </div>
      <div className="activity-item-date">
        <span>{`${OrdoDate.from(activity.date).format(DateFormatTemplate.MONTH_NAME_ABBREVIATED_AND_DAY)}${activity.time ? `, ${OrdoDate.from(activity.time).format(DateFormatTemplate.HOURS_AND_MINUTES)}` : ''}`}</span>
      </div>
      <div className="activity-item-description">
        <span>{activity.description}</span>
      </div>
      <div className="activity-item-assigned">
        <span>{`${activity.assignedSalesRep ? fullName(activity.assignedSalesRep) : ''}`}</span>
      </div>
      <div className="activity-item-edit-icon">
        <FontAwesomeIcon size="lg" icon={faEdit} onClick={() => toggleEditActivityModal()}/>
      </div>
      <div className="activity-item-delete-icon">
        <FontAwesomeIcon size="lg" icon={faTrashAlt} onClick={() => toggleDeleteActivityConfirmationModal()}/>
      </div>
      {deleteActivityConfirmationModal && <ConfirmationModal
        show={deleteActivityConfirmationModal}
        onClose={toggleDeleteActivityConfirmationModal}
        onSubmit={deleteActivity}
        confirmationText='are you sure you want to delete the activity?'
        actionText='delete'
      />}
      {editActivityModal && <ActivityModal isOpen={editActivityModal} canEditSalesRep
        onClose={toggleEditActivityModal} onSubmit={onEdit} activity={activity} organizationId={orgId}/>}
      <ActivityCompleteModal activity={activity} isOpen={openCompleteActivityWizard} onClose={closeCompleteActivityModal} onSubmit={(responses)=> updateActivityCompleted(true, responses)}/>
      {openMarkActivityAsPending && <ConfirmationModal
        show={openMarkActivityAsPending}
        onClose={closeMarkActivityAsPendingModal}
        onSubmit={()=>updateActivityCompleted(false, [])}
        confirmationText="marking the activity as incomplete will delete any responses, are you sure you want to continue?"
        actionText='mark incomplete'
      />}
    </OrdoSpinner>
  </div>);
};
