import React from 'react';
import {
  ActivityCompletionResponseDisplayProps,
  MultiValueActivityCompletionResponse,
} from '../../../../models/activity/responses/ActivityCompletionResponse';
import {OrdoCheckbox} from '../../OrdoCheckbox';


export const CheckboxResponseDisplay = ({
  response,
}: ActivityCompletionResponseDisplayProps) => {
  const checkboxResponse = response as MultiValueActivityCompletionResponse;

  return <div className='checkbox-response'>
    {checkboxResponse.options.map((option, index) => {
      const checked = checkboxResponse.body.selectedOptions.includes(index);
      return <div className='checkbox-response__row' key={option}>
        <OrdoCheckbox id={option+index+response.index} checked={checked} disabled onChange={()=>{}}/>
        <span className={`${checked ? 'checked' : ''}`}>{option}</span>
      </div>;
    })}
  </div>;
};